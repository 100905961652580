import { APIUrlConstants } from "../../../util/apiUrl.constant";
import { APIService } from "../../../util/api.service";

export class ForgotPasswordService {
    static sendOtp(mailid) {
        const url = APIUrlConstants.getApiUrl("sendOtp");
        return APIService.doPost(url, mailid, {}, false)
            .then((resp) => {
                if (resp) {
                    return resp;
                }
                throw resp;
            });
    }

    static validateOtp(otpInput) {
        const url = APIUrlConstants.getApiUrl("validateOtp");
        return APIService.doPost(url, otpInput, {}, false)
            .then((resp) => {
                if (resp) {
                    return resp;
                }
                throw resp;
            });
    }

    static resetPassword(resetInput) {
        const url = APIUrlConstants.getApiUrl("resetPassword");
        return APIService.doPost(url, resetInput, {}, false)
            .then((resp) => {
                if (resp) {
                    return resp;
                }
                throw resp;
            });
    }
}