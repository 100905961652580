import * as types from "../actions/ActionsTypes";

const initialState = {
    allPropertyList: [],
    isLoading: false,
    isAdded: false,
    isUpdated: false

}


const lookupReducer = (state = initialState, action = {}) => {
    switch (action.type) {

        // ========== Get Category ========== //
        case types.GET_ALL_LOOKUP:
            state = {
                ...state,
                isLoading: true,
                allPropertyList: []
            };
            return state;
        case types.GET_ALL_LOOKUP_SUCCESS:
            state = {
                ...state,
                isLoading: false,
                allPropertyList: action.response
            }
            return state;
        case types.GET_ALL_LOOKUP_FAILURE:
            state = {
                ...state,
                isLoading: false,
                allPropertyList: []
            }
            return state;

        case types.CREATE_LOOKUP_REQUEST:
            state = {
                ...state,
                isAdded: false,
                isLoading: true,

            };
            return state;
        case types.CREATE_LOOKUP_SUCCESS:
            state = {
                ...state,
                isAdded: true,
                isLoading: false,
            }
            return state;
        case types.CREATE_LOOKUP_FAILURE:
            state = {
                ...state,
                isAdded: false,
                isLoading: false,
            }
            return state;
        case types.UPDATE_LOOKUP_REQUEST:
            state = {
                ...state,
                isUpdated: false,
                isLoading: true,

            };
            return state;
        case types.UPDATE_LOOKUP_SUCCESS:
            state = {
                ...state,
                isUpdated: true,
                isLoading: false,
            }
            return state;
        case types.UPDATE_LOOKUP_FAILURE:
            state = {
                ...state,
                isUpdated: false,
                isLoading: false,
            }
            return state;
        default:
            return state;

    }
}

export default lookupReducer;
