import { APIUrlConstants } from "../../util/apiUrl.constant";
import { APIService } from "../../util/api.service";
import { Util } from "../../util/util";

export class ManageSellerService {
    static getSellersList(sellerdata) {
        let url = APIUrlConstants.getApiUrl("getSellersList");
        return APIService.doPost(url, sellerdata).then(resp => {
            if (resp) {
                return resp;
            }
            throw resp;
        });
    }
    static getProductList(productdata) {
        let url = APIUrlConstants.getApiUrl("getProductList");
        return APIService.doPost(url, productdata).then(resp => {
            if (resp) {
                return resp;
            }
            throw resp;
        });
    }
    static blockSeller(sellerid) {
        let url = APIUrlConstants.getApiUrl("blockSeller");
        url = Util.beautifyUrl(url, [sellerid]);
        return APIService.doPut(url).then(resp => {
            if (resp) {
                return resp;
            }
            throw resp;
        });
    }
    static unblockSeller(sellerid) {
        let url = APIUrlConstants.getApiUrl("unblockSeller");
        url = Util.beautifyUrl(url, [sellerid]);
        return APIService.doPut(url).then(resp => {
            if (resp) {
                return resp;
            }
            throw resp;
        });
    }
    static getSellerDetails(sellerid) {
        let url = APIUrlConstants.getApiUrl("getSellerDetails");
        url = Util.beautifyUrl(url, [sellerid]);
        return APIService.doGet(url).then(resp => {
            if (resp) {
                return resp;
            }
            throw resp;
        });
    }

    static getOwnedArtWorks(productdata) {
        let url = APIUrlConstants.getApiUrl("getOwnedProducts");
        return APIService.doPost(url, productdata).then(resp => {
            if (resp) {
                return resp;
            }
            throw resp;
        });
    }

    static getGalleryListedInventory(productdata) {
        let url = APIUrlConstants.getApiUrl("getGalleryListedInventory") + "?pageNum=" + productdata.PageNum + "&pageSize=" + productdata.PageSize + "&ArtistId=" + productdata.UserId;
        return APIService.doGet(url).then(resp => {
            if (resp) {
                return resp;
            }
            throw resp;
        });
    }
}
