import React, { Component } from "react";
import { connect } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import { Pagination, Table, Popover, Tooltip } from "antd";
//Files
import { getCountryTaxListAction, toggleAddCountryTaxDialog, getAllCountryListAction, deleteCountryTaxAction } from "../../redux/actions/CountriesTaxAction";
import Root from "../Root/Root";
import Topbar from "../../components/Topbar/Topbar";
import { Util } from "../../util/util";
// SCSS
import "./CountriesTax.scss";
import ConfirmationMessageDialog from "../../components/ConfirmationMessageDialog/ConfirmationMessageDialog";
import AddCountryTaxDialog from "./AddCountryTaxDialog/AddCountryTaxDialog";

class CountriesTax extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deleteconfirmationDialog: false,
            searchValue: "",
            pageNumValue: 1,
            taxId: "",
            selectedRecordToPerformAction: null,
        };
    }

    componentDidMount() {
        // List Sellers by fetching details from URL - When Page Loads
        this.setSearchParams();
        this.props.getAllCountryListAction();
    }

    componentDidUpdate(prevProps) {
        // List Sellers by fetching details from URL - When URL change occur
        const locationChanged = this.props.location !== prevProps.location;
        if (locationChanged) {
            this.setSearchParams();
        }
    }

    render() {
        const options = { year: "numeric", month: "long", day: "numeric" };
        const columns = [
            {
                title: "Name of Countries",
                dataIndex: "country",
                key: "country",
                render: (text) => (
                    <div className="colDiv">
                        <Tooltip title={text ? text : "Nil"}>
                            <p className="colText">{text ? text : "Nil"}</p>
                        </Tooltip>
                    </div>
                )
            },
            {
                title: "Tax %",
                dataIndex: "taxPercentage",
                key: "taxPercentage",
                render: text => <p className="textBlack">{text}&nbsp;&nbsp;&nbsp;&#37;</p>
            },
            {
                title: "Customs %",
                key: "customstaxPercentage",
                render: (record) => record.importTax ? <p className="textBlack">&nbsp;&nbsp;&nbsp;{record.importTax.customsPercentage}&nbsp;&nbsp;&nbsp;&#37;</p> : <p>&nbsp;&nbsp;&nbsp;Nil</p>
            },
            {
                title: "Other Tax %",
                key: "othertaxPercentage",
                render: (record) => record.importTax ? <p className="textBlack">&nbsp;&nbsp;&nbsp;{record.importTax.otherTaxpercentage}&nbsp;&nbsp;&nbsp;&#37;</p> : <p>&nbsp;&nbsp;&nbsp;Nil</p>
            },
            {
                title: "VAT %",
                key: "vattaxPercentage",
                render: (record) => record.importTax ? <p className="textBlack">&nbsp;&nbsp;&nbsp;{record.importTax.vatPercentage}&nbsp;&nbsp;&nbsp;&#37;</p> : <p>&nbsp;&nbsp;&nbsp;Nil</p>
            },
            {
                title: "Last updated date",
                dataIndex: "updatedDate",
                key: "updatedDate",
                render: text => <p className="blackText">{new Date(text).toLocaleDateString("en-IN", options)}</p>
            },
            {
                title: "Action",
                key: "action",
                render: record =>
                    <Popover
                        placement="bottom"
                        content={content(record)}
                        overlayClassName="countries-taxes-action-popover"
                    // onVisibleChange={this.onVisibleChangeOfCoutryTaxRecord.bind(this, record)}
                    >
                        <img className="action-image" src={require("../../assets/images/common/artworksActionIcon.svg")} alt="action" />
                    </Popover>
            }
        ];
        const content = record => (
            <div>
                <div className="action-span" onClick={this.onClickEditCountryTax.bind(this, true, record)}>
                    <img src={require("../../assets/images/common/Edit_New.svg")} alt="edit" />
                    <p className="pop-item">Edit</p>
                </div>
                <span className="action-span" onClick={this.toggleDeleteConfirmationDialog.bind(this, record)}>
                    <img src={require("../../assets/images/common/delete.svg")} alt="delete" />
                    <p className="pop-item">Delete</p>
                </span>
            </div>
        );
        const { countryTaxListLoader, countryTaxList, countryTaxListCount, addCountryTaxDialog } = this.props.countriesTaxReducer;

        return (
            <Root>
                <Topbar title={"Countries & Taxes"} showSearch searchValue={this.state.searchValue} onChangeSearch={this.onChangeSearch.bind(this)} />
                <div className="countriestaxes-wrapper">
                    <div className="sub-wrapper">
                        {countryTaxList && countryTaxList.length !== 0 ? (
                            <Table rowKey={record => record._id} columns={columns} dataSource={countryTaxList} pagination={false} />
                        ) : countryTaxListLoader ? (
                            <CircularProgress className="circular-loader" size={30} />
                        ) : (
                                    <div className="empty-table">No Countries Taxes Found.</div>
                                )}
                        {!countryTaxListLoader && (
                            <Pagination
                                current={parseInt(this.state.pageNumValue)}
                                total={countryTaxListCount}
                                pageSize={10}
                                onChange={this.onSelectPageNumber(this)}
                            />
                        )}
                    </div>
                    <div className="add-button-box">
                        <Tooltip placement="top" title={"Add Country Tax"}>
                            <div className="add-btn" onClick={this.toggleAddCountryTaxDialog.bind(this, true)}>
                                + Add Country Tax
								</div>
                        </Tooltip>
                    </div>
                </div>
                {/* Create Category */}
                <AddCountryTaxDialog
                    open={addCountryTaxDialog}
                    updateData={this.state.selectedRecordToPerformAction}
                    handleClose={this.toggleAddCountryTaxDialog.bind(this, false)}
                />
                <ConfirmationMessageDialog
                    open={this.state.deleteconfirmationDialog}
                    handleClose={this.toggleDeleteConfirmationDialog.bind(this)}
                    confirmMessage={"Are you sure you want to delete this tax?"}
                    onConfirm={this.onDeleteArtwork.bind(this)}
                />
            </Root>
        );
    }

    // ------------------------ When Page loads/ URL changes ------------------------- //
    // Call API by fetching details from URL
    setSearchParams() {
        const { location } = this.props;

        // When filter/Search params exist
        if (location.search) {
            let parsedQuery = Util.queryStringHandling(location.search, "PARSE");
            this.setState(
                {
                    searchValue: parsedQuery.Search,
                    pageNumValue: this.props.match.params.pageNum
                },
                () => {
                    this.doSearchFilterSellers(parsedQuery);
                }
            );
        }

        // When there is no filter/Search params
        else {
            this.setState({ pageNumValue: this.props.match.params.pageNum }, () => {
                this.doSearchFilterSellers();
            });
        }
    }

    // --------------------- When filter/search/pagination events trigger --------------------- //

    //Pagination
    onSelectPageNumber = name => event => {
        this.setState({ pageNumValue: event }, () => {
            this.doChangeUrlWithQuery();
        });
    };

    // Search
    onChangeSearch(search) {
        this.setState(
            {
                searchValue: search,
                pageNumValue: 1
            },
            () => {
                this.doChangeUrlWithQuery();
            }
        );
    }

    // Change URL based on filter/search/Page values
    doChangeUrlWithQuery() {
        let searchCriteria = {
            Search: this.state.searchValue
        };
        const queryString = Util.queryStringHandling(searchCriteria, "STR");
        this.props.history.push("/countries-taxes/" + this.state.pageNumValue + "?" + queryString);
    }

    // ----------------------- API - Fetch Sellers ----------------------------- //
    doSearchFilterSellers() {
        this.props.getCountryTaxListAction(this.state.pageNumValue, 10, this.state.searchValue);

    }

    // ====================== CREATE COUNTRY TAX [TOGGLE] ====================== //
    async toggleAddCountryTaxDialog(value) {
        // Toggle dialog
        this.setState({
            selectedRecordToPerformAction: null
        }, () => {
            // Toggle dialog
            this.props.toggleAddCountryTaxDialog(value);
        })
    }

    //-------------Dialog for delete tax---------------------//
    toggleDeleteConfirmationDialog(item) {
        this.setState(
            {
                deleteconfirmationDialog: !this.state.deleteconfirmationDialog
            },
            () => {
                this.setState({
                    taxId: item._id
                });
            }
        );
    }

    //-------------Delete Artwork Confirm---------------------//
    async onDeleteArtwork() {
        await this.props.deleteCountryTaxAction(this.state.taxId);
        await this.doSearchFilterSellers();
        await this.handleReset();
    }

    //Reset fields
    handleReset() {
        this.setState({
            ...this.state,
            deleteconfirmationDialog: false,
            taxId: "",
        });
    }

    // ----------------------- Edit Artwork ------------------------ //
    onVisibleChangeOfCoutryTaxRecord = (data, visibility) => {
        this.setState({
            selectedRecordToPerformAction: visibility ? data : null
        });
    };

    onClickEditCountryTax = (value, item) => {
        this.setState({
            selectedRecordToPerformAction: item
        }, () => {
            // Toggle dialog
            this.props.toggleAddCountryTaxDialog(value);
        })
    };
}

const mapStateToProps = state => {
    return {
        countriesTaxReducer: state.countriesTaxReducer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getAllCountryListAction: () => {
            dispatch(getAllCountryListAction());
        },
        getCountryTaxListAction: (pageNum, pageSize, countryName) => {
            dispatch(getCountryTaxListAction(pageNum, pageSize, countryName));
        },
        deleteCountryTaxAction: taxId => {
            dispatch(deleteCountryTaxAction(taxId));
        },
        toggleAddCountryTaxDialog: value => {
            dispatch(toggleAddCountryTaxDialog(value));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CountriesTax);
