import React, { Component } from 'react';
import { connect } from "react-redux";
import { CircularProgress } from '@material-ui/core';

import { popToast, Toast } from '../../../components/Toast/Toast';
import { validateOtpAction, sendOtpAction } from '../../../redux/actions/ForgotPasswordAction';
import './OtpVerification.scss';

class OtpVerification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            otpValue: "",
            mailId: this.props.match.params.id
        };
    }

    render() {
        return (
            <div className="otp-wrapper">
                <Toast />
                <div className="app-logo">
                    <img src={require("../../../assets/images/common/Logo.svg")} alt="logo" />
                </div>
                <div className="otp-contents">
                <p className="page-title">OTP Verification</p>
                <p className="message">Please Check Your Email For OTP</p>
                    <div className="form-control">
                        <label className="form-label">Enter OTP</label>
                        <input
                            type="number"
                            autoFocus
                            className="form-input"
                            value={this.state.otpValue}
                            onChange={this.onHandleInput('otpValue')}
                        />
                    </div>
                    <div className="end-btn">
                        <button className="btn-orange"
                            onClick={this.onsubmitOtp.bind(this)}
                            disabled={this.props.forgotPasswordReducer.sendOtpLoader}
                        >SUBMIT
                        {this.props.forgotPasswordReducer.sendOtpLoader && <CircularProgress size={24} className="otp-progress" />}
                        </button>
                        <p onClick={this.resendOtp.bind(this)}>Resend OTP</p>
                    </div>
                </div>
            </div>
        )
    }

    onHandleInput = (name) => (event) => {
        this.setState({
            [name]: event.target.value
        });
    }

    onsubmitOtp() {
        if (this.state.otpValue) {
            let validInput = {
                Email: this.state.mailId,
                Otp: this.state.otpValue
            }
            this.props.validateOtpAction(validInput);
        }
        else {
            if (this.state.otpValue === "") {
                popToast.show("Please Enter OTP");
            }
        }
    }
    resendOtp() {
        let mailInput = {
            Email: this.state.mailId
        }
        this.props.sendOtpAction(mailInput);
    }
}
// --------------------------react-redux connection-------------------------
//mapStateToProps is used to update the component if react's store is changed
const mapStateToProps = state => {
    return {
        forgotPasswordReducer: state.forgotPasswordReducer
    };
};

//mapDispatchToProps is used to inject dispatch function within the component
const mapDispatchToProps = dispatch => {
    return {
        validateOtpAction: (otpInput) => {
            dispatch(validateOtpAction(otpInput));
        },
        sendOtpAction: (mailId) => {
            dispatch(sendOtpAction(mailId))
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OtpVerification);

