import React, { useEffect } from "react";
import Root from "../Root/Root";
import Topbar from "../../components/Topbar/Topbar";
import { Util } from "../../util/util";
import { Pagination, Table, Popover, Tooltip } from "antd";
import useSurveyForm from "./useSurveyForm";
import ConfirmationMessageDialog from "../../components/ConfirmationMessageDialog/ConfirmationMessageDialog";
import propertiesList from "../../properties.json";

function SurveyForms({ history }) {
	const {
		//Function
		getAllSurvey,
		setConfirmDeleteSurvey,
		deleteSurvey,
		selectedSurveyForAction,
		handlePageChange,
		//Variables
		allSurvey,
		confirmDeleteSurvey,
		pageNo,
	} = useSurveyForm();

	useEffect(() => {
		getAllSurvey();
	}, []);
	const content = (record) => (
		<div>
			<div className="action-span" onClick={() => window.open(`${propertiesList.fontEndUrl}form/${record.slugId}`, "_blank")}>
				<img src={require("../../assets/images/common/Viewa&Edit.svg")} alt="edit" />
				<p className="pop-item">Preview Survey</p>
			</div>
			<div className="action-span" onClick={() => history.push(`/survey-answers/${record._id}/1`)}>
				<img src={require("../../assets/images/common/Viewa&Edit.svg")} alt="edit" />
				<p className="pop-item">View Answers</p>
			</div>

			{/* <div className="action-span">
				<img src={require("../../assets/images/common/Edit_New.svg")} alt="edit" />
				<p className="pop-item">Edit</p>
			</div> */}

			<div className="action-span" onClick={() => selectedSurveyForAction(record._id, "delete")}>
				<img src={require("../../assets/images/common/delete_active.svg")} alt="delete" />
				<p className="pop-item">Delete</p>
			</div>
		</div>
	);
	const columns = [
		{
			title: "Survey Title",

			render: (text) => (
				<div className="colDiv">
					<p>
						<b>{text.surveyTitle}</b>
					</p>
					<span>{text.surveySubTitle}</span>
				</div>
			),
		},

		{
			title: "Survey Title",

			render: (text) => (
				<div className="colDiv">
					<span>{text.surveySubTitle}</span>
				</div>
			),
		},
		{
			title: "Survey Date",
			// dataIndex: "createdDate",
			// key: "createdDate",
			render: (text) => <div className="colDiv">{text.createdDate}</div>,
		},
		{
			title: "Action",
			key: "action",
			render: (record) => (
				<Popover placement="bottom" content={content(record)} overlayClassName="manage-artworks-action-popover">
					<img className="action-image" src={require("../../assets/images/common/artworksActionIcon.svg")} alt="action" />
				</Popover>
			),
		},
	];

	return (
		<Root>
			<Topbar title={"Survey Forms"} />
			<div className="survey-main-wrapper">
				<Table rowKey={(record) => record._id} columns={columns} dataSource={allSurvey} pagination={{ pageSize: 5 }} scroll={{ y: 1000 }} />
				<div className="add-button-box">
					<Tooltip placement="top" title={"Add New Survey"}>
						<div className="add-btn" onClick={() => history.push("/new-survey")}>
							+ Add New Survey Form
						</div>
					</Tooltip>
				</div>
			</div>

			<ConfirmationMessageDialog
				open={confirmDeleteSurvey}
				handleClose={() => setConfirmDeleteSurvey(false)}
				confirmMessage={"Are you sure you want to delete this survey?"}
				onConfirm={() => deleteSurvey()}
			/>
		</Root>
	);
}

export default SurveyForms;
