import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import { DialogTitle, DialogContent, CircularProgress, Slide } from "@material-ui/core";

// Components
import { popToast } from "../../../components/Toast/Toast";
// Redux
import { connect } from "react-redux";
// SCSS
import "./AddRuleDialog.scss";
import { find, get } from "lodash";
import { createCategoryRulesAction, updateCategoryRulesAction } from "../../../redux/actions/CategoryAction";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
class AddRuleDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            suggestionList: [],
            filters: this.generateInitialFilter(),
            ruleId: "",
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevProps.open && this.props.open) {
            const updateRuleData = this.props.updateData;
            const rulesList = get(updateRuleData, "categoryRules");

            let filtersSet = (rulesList && rulesList.length !== 0) ? rulesList : this.generateInitialFilter();

            // Adding index value to all filters
            filtersSet.map((filter, index) => {
                filter["propertyId"] = filter.propertyId.toString();
                filter["value"] = filter.value.toString();
            });
            const { categoryPropertyList } = this.props.categoryReducer;
            rulesList && rulesList.length !== 0 && rulesList.map((item, index) => {
                if (get(find(categoryPropertyList, function (o) { return o._id === item.propertyId; }), "dataType") === 3) {
                    this.setState({
                        ['suggestionList' + index]: ["true", "false"]
                    });
                }
                else {
                    this.setState({
                        ['suggestionList' + index]: get(find(categoryPropertyList, function (o) { return o._id === item.propertyId; }), "propertySuggestions")
                    });
                }
            });
            this.setState({
                filters: filtersSet,
                ruleId: updateRuleData && updateRuleData._id
            });
        }
    }

    render() {
        const { categoryPropertyList, createRuleLoader, updateRuleLoader } = this.props.categoryReducer;
        const filterForm = this.state.filters.map((filterBody, index) => {
            return (
                <div key={index} className="add-rule-tax-body">
                    <div className="form-control">
                        <label className="form-label">Property Name</label>
                        <select
                            className="form-input"
                            value={filterBody.propertyId}
                            disabled={createRuleLoader || updateRuleLoader}
                            onChange={this.onUpdateText.bind(this, index, "propertyId")}
                        >
                            <option value="" disabled>
                                Select
							        </option>
                            {categoryPropertyList && categoryPropertyList.length !== 0 &&
                                categoryPropertyList.map((item, index) => (
                                    <option key={index} value={item._id}>
                                        {item.propertyName}
                                    </option>
                                ))}
                        </select>
                    </div>
                    <div className="form-control-new">
                        <label className="form-label">Property Value</label>
                        <input
                            type="text"
                            className="form-input"
                            value={filterBody.value}
                            list={`suggestion${index}`}
                            disabled={createRuleLoader || updateRuleLoader}
                            onChange={this.onHandleInput("value", index)}
                        />
                        {this.state['suggestionList' + index] && this.state['suggestionList' + index].length !== 0 &&
                            <datalist id={`suggestion${index}`}>
                                {this.state['suggestionList' + index].map((item, listIndex) => (
                                    <option key={listIndex} value={item}>{item}</option>
                                ))}
                            </datalist>
                        }
                    </div>
                    <img
                        className="filter-close-btn"
                        onClick={this.onClickAddFilter.bind(this, index, "POP")}
                        src={require("../../../assets/images/common/Close_h.svg")}
                        alt=""
                    />
                </div>
            );
        });

        return (
            <Dialog
                fullScreen
                open={this.props.open}
                onClose={this.props.handleClose}
                TransitionComponent={Transition}
                BackdropProps={{ classes: { root: "dialog-backdrop" } }}
                className="add-rule-tax-dialog"
            >
                <img className="close-btn" onClick={this.props.handleClose} src={require("../../../assets/images/common/Close_h.svg")} alt="" />
                <DialogTitle className="dialog-title">Add Rule</DialogTitle>
                <DialogContent className="add-rule-tax-content">

                    <div className="dialog-sub-title">
                        <span></span>
                        <button className="add-filter-button" onClick={this.onClickAddFilter.bind(this, null, "PUSH")}>
                            Add
						</button>
                    </div>
                    {filterForm}
                    <div className="add-rule-tax-action">
                        <button className="btn-cancel" onClick={this.onClickCancel.bind(this)}>
                            Cancel
						</button>
                        <button className="btn-orange" onClick={this.onClickCreate.bind(this)}
                            disabled={createRuleLoader || updateRuleLoader}
                        >
                            {this.props.updateData ? "Update" : "Save"}
                            {(createRuleLoader || updateRuleLoader) && <CircularProgress size={24} className="circular-loader modal-button-loader" />}
                        </button>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }
    // Handle inputs
    onHandleInput = (name, id) => event => {
        let demoFilter = this.state.filters;
        demoFilter.filter((obj, index) => {
            if (index === id) {
                obj[name] = event.target.value;
            }
        });
        this.setState({
            filters: demoFilter
        });
    };

    //Handle Inputs
    onUpdateText(id, inputField, event) {
        let demoFilter = this.state.filters;
        const { categoryPropertyList } = this.props.categoryReducer;
        let propid = event.target.value;
        demoFilter.filter((obj, index) => {
            if (index === id) {
                obj[inputField] = event.target.value;
                obj["value"] = "";
            }
        });
        if (get(find(categoryPropertyList, function (o) { return o._id === propid; }), "dataType") === 3) {
            this.setState({
                filters: demoFilter,
                ['suggestionList' + id]: ["true", "false"]
            });
        }
        else {
            this.setState({
                filters: demoFilter,
                ['suggestionList' + id]: get(find(categoryPropertyList, function (o) { return o._id === propid; }), "propertySuggestions")
            });
        }
    }

    generateInitialFilter() {
        return [
            {
                propertyId: "",
                value: ""
            }
        ];
    }
    // Add/Remove rule
    onClickAddFilter(index, task) {
        if (task === "PUSH") {
            let newFilter = {
                propertyId: "",
                value: ""
            };

            this.setState({
                filters: [...this.state.filters, newFilter]
            });
        } else if (task === "POP") {
            this.setState({
                filters: this.state.filters.filter((filterData, i) => i !== index)
            });
        }
    }

    isFormValid() {
        if (this.state.filters.length > 0) {
            let found = this.state.filters.find(function (element) {
                if (!element.propertyId.trim()) {
                    return element;
                } else if (!element.value.trim()) {
                    return element;
                }
            });
            if (found) {
                popToast.show("Please make sure all rule values are valid!");
                return false;
            } else return true;
        }
        return true;
    }

    async onClickCreate() {
        // For main category, parentId is hardcoded
        if (this.isFormValid()) {
            let { parentId, _id } = this.props.catgoryData;
            let request = {
                SubcategoryId: _id,
                MainCategoryId: parentId,
                CategoryRules: this.state.filters
            }
            if (this.props.updateData) {
                await this.props.updateCategoryRulesAction(this.state.ruleId, request);
                await this.handleReset();
            }
            else {
                await this.props.createCategoryRulesAction(request);
                await this.handleReset();
            }

        }
    }
    handleReset() {
        this.setState({
            ...this.state,
            ruleId: "",
            filters: this.generateInitialFilter()
        });
    }
    onClickCancel() {
        this.props.handleClose();
    }
}

const mapStateToProps = state => {
    return {
        categoryReducer: state.categoryReducer

    };
};

const mapDispatchToProps = dispatch => {
    return {
        createCategoryRulesAction: (request) => {
            dispatch(createCategoryRulesAction(request));
        },
        updateCategoryRulesAction: (ruleId, request) => {
            dispatch(updateCategoryRulesAction(ruleId, request));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddRuleDialog);
