import * as types from "../actions/ActionsTypes";

const initialState = {
    allFilterRules: [],
    singleFilter: [],
    isLoading: false,
    isAdded: false,
    isUpdated: false

}


const filterRuleReducer = (state = initialState, action = {}) => {
    switch (action.type) {

        // ========== Get All Filter ========== //
        case types.GET_ALL_FILTER_RULE:
            state = {
                ...state,
                isLoading: true,
                allFilterRules: []
            };
            return state;
        case types.GET_ALL_FILTER_RULE_SUCCESS:
            state = {
                ...state,
                isLoading: false,
                allFilterRules: action.response.result
            }
            return state;
        case types.GET_ALL_FILTER_RULE_FAILURE:
            state = {
                ...state,
                isLoading: false,
                allFilterRules: []
            }
            return state;
        // ========== Get Filter By Id ========== //
        case types.GET_FILTER_RULE_BY_ID:
            state = {
                ...state,
                isLoading: true,
                singleFilter: []
            };
            return state;
        case types.GET_FILTER_RULE_BY_ID_SUCCESS:
            state = {
                ...state,
                isLoading: false,
                singleFilter: action.response.result
            }
            return state;
        case types.GET_FILTER_RULE_BY_ID_FAILURE:
            state = {
                ...state,
                isLoading: false,
                singleFilter: []
            }
            return state;
        case types.DELETE_FILTER_RULE_REQUEST:
            state = {
                ...state,
                isLoading: true,

            };
            return state;
        case types.DELETE_FILTER_RULE_SUCCESS:
            state = {
                ...state,
                isLoading: false,

            }
            return state;
        case types.DELETE_FILTER_RULE_FAILURE:
            state = {
                ...state,
                isLoading: false,

            }
            return state;
        case types.CREATE_FILTER_RULE_REQUEST:
            state = {
                ...state,
                isLoading: true,
                isAdded: false

            };
            return state;
        case types.CREATE_FILTER_RULE_SUCCESS:
            state = {
                ...state,
                isLoading: false,
                isAdded: true

            }
            return state;
        case types.CREATE_FILTER_RULE_FAILURE:
            state = {
                ...state,
                isLoading: false,
                isAdded: false

            }
            return state;

        case types.UPDATE_FILTER_RULE_REQUEST:
            state = {
                ...state,
                isLoading: true,
                isUpdated: false

            };
            return state;
        case types.UPDATE_FILTER_RULE_SUCCESS:
            state = {
                ...state,
                isLoading: false,
                isUpdated: true

            }
            return state;
        case types.UPDATE_FILTER_RULE_FAILURE:
            state = {
                ...state,
                isLoading: false,
                isUpdated: false

            }
            return state;


        default:
            return state;

    }
}

export default filterRuleReducer;
