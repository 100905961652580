import React, { Component } from "react";
import { CircularProgress } from "@material-ui/core";
import { Tooltip, Icon } from "antd";
// Redux
import { connect } from "react-redux";
import { getHomeBannerListAction, deleteHomeBannerAction, toggleAddBannerDialog } from "../../redux/actions/ManageHomeAction";
// Components
import Root from "../Root/Root";
import Topbar from "../../components/Topbar/Topbar";
import AddSliderDialog from "./AddSliderDialog/AddSliderDialog";
import ConfirmationMessageDialog from "../../components/ConfirmationMessageDialog/ConfirmationMessageDialog";
// SCSS
import "./ManageHome.scss";

class ManageHome extends Component {
	constructor(props) {
		super(props);
		this.state = {
			deleteSliderDialog: false,
			bannerItem: {},
			selectedRecordToPerformAction: null
		};
	}

	componentDidMount() {
		this.props.getHomeBannerListAction();
	}

	render() {
		const { homeBannerListLoader, homeBannerList, deleteBannerLoader, addBannerDialog } = this.props.manageHomeReducer;
		// {/* ---------------- Slider List ---------------- */}
		const homeListRight =
			homeBannerList &&
			homeBannerList.map((listItem, listIndex) => {
				return (
					<li key={listIndex} className="manage-home-table-body">
						<div className="banner-mob-box">
							<div className="image-wrap">
								{listItem.mobileBannerImage ? (
									<img src={listItem.mobileBannerImage} alt="Mobile View" />
								) : (
									<div className="plain-image-placeholder">Image Unavailable</div>
								)}
							</div>
						</div>
						<div className="banner-web-box">
							<div className="image-wrap">
								{listItem.webBannerImage ? (
									<img src={listItem.webBannerImage} alt="Website view" />
								) : (
									<div className="plain-image-placeholder">Image Unavailable</div>
								)}
							</div>
						</div>
						<div className="actions-box">
							{/* Hover/Active */}
							<div className="edit-options">
								<Tooltip placement="top" title={"Edit Slider"}>
									<div className="image-wrapper" onClick={this.toggleEditSliderDialog.bind(this, true, listItem)}>
										<img className="edit-img" src={require("../../assets/images/common/Edit.svg")} alt="Edit" />
									</div>
								</Tooltip>
								<Tooltip placement="top" title={"Delete Slider"}>
									<div className="image-wrapper" onClick={this.toggleDeleteSliderDialog.bind(this, listItem)}>
										<img src={require("../../assets/images/common/delete.svg")} alt="Delete" />
									</div>
								</Tooltip>
							</div>
						</div>
					</li>
				);
			});

		return (
			<Root>
				<Topbar title={"Manage Home"} />

				<div className="manage-home-wrapper">
					{/* ---------------- Home List ---------------- */}
					<div className="manage-home-list">
						{/* {homeList.length > 0 ? ( */}
						<ul className="manage-home-list-wrapper">
							<li className="head-list-item">
								<div className="list-item-count">S.No</div>
								<div className="list-item-content">Name</div>
							</li>
							<hr />
							<li className="active">
								<div className="list-item-count">1</div>
								<div className="list-item-content">Banner List</div>
							</li>
						</ul>
					</div>

					{/* ---------------- Slider List ---------------- */}
					{homeBannerList.length !== 0 ? (
						<div className="manage-home-view-list">
							<ul>
								<li className="manage-home-table-heads">
									<div className="banner-mob-box">Banner Mob</div>
									<div className="banner-web-box">Banner web</div>
									<div className="actions-box">Actions</div>
								</li>
								<hr />

								{homeListRight}
							</ul>
							<div className="add-button-box" onClick={this.toggleAddSliderDialog.bind(this, true)}>
								<Tooltip placement="top" title={"Add Slider"}>
									<Icon type="plus" />
								</Tooltip>
							</div>
						</div>
					) : homeBannerListLoader ? (
						<div className="manage-home-view-list">
							<CircularProgress className="circular-loader" size={30} />
						</div>
					) : (
						<div className="manage-home-view-list">
							<div className="empty-box">No items found</div>
							<div className="add-button-box" onClick={this.toggleAddSliderDialog.bind(this, true)}>
								<Tooltip placement="top" title={"Add Slider"}>
									<Icon type="plus" />
								</Tooltip>
							</div>
						</div>
					)}

					{/* Add Home Banner Item Dialog */}
					<AddSliderDialog
						open={addBannerDialog}
						updateData={this.state.selectedRecordToPerformAction}
						handleClose={this.toggleAddSliderDialog.bind(this, false)}
					/>

					{/* Delete Home Banner item */}
					<ConfirmationMessageDialog
						open={this.state.deleteSliderDialog}
						handleClose={this.toggleDeleteSliderDialog.bind(this)}
						confirmMessage={"Are you sure you want to delete this slider ?"}
						confirmButton={"Delete"}
						loader={deleteBannerLoader}
						onConfirm={this.onDeleteSlider.bind(this)}
					/>
				</div>
			</Root>
		);
	}

	// ============ Add Banner ============ //
	toggleAddSliderDialog(value) {
		this.setState({
			selectedRecordToPerformAction: null
		}, () => {
			// Toggle dialog
			this.props.toggleAddBannerDialog(value);
		})
	}

	// ============= Edit Slider ================//
	toggleEditSliderDialog(value, item) {
		this.setState({
			selectedRecordToPerformAction: item
		}, () => {
			// Toggle dialog
			this.props.toggleAddBannerDialog(value);
		})
	};

	// ============ Delete Slider ============ //
	toggleDeleteSliderDialog(item) {
		this.setState(
			{
				deleteSliderDialog: !this.state.deleteSliderDialog
			},
			() => {
				if (this.state.deleteSliderDialog) {
					// set id of rule only when dialog opens
					this.setState({
						bannerItem: item
					});
				}
			}
		);
	}
	// Delete Slider
	onDeleteSlider() {
		this.props.deleteHomeBannerAction(this.state.bannerItem);
		this.handleReset();
	}

	handleReset() {
		this.setState({
			...this.state,
			deleteSliderDialog: false,
			bannerItem: {},
			selectedRecordToPerformAction: null
		});
	}
}

const mapStateToProps = state => {
	return {
		manageHomeReducer: state.manageHomeReducer
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getHomeBannerListAction: () => {
			dispatch(getHomeBannerListAction());
		},
		deleteHomeBannerAction: bannerItem => {
			dispatch(deleteHomeBannerAction(bannerItem));
		},
		toggleAddBannerDialog: value => {
			dispatch(toggleAddBannerDialog(value));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageHome);
