import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import { DialogTitle, DialogContent } from "@material-ui/core";
// Redux
import { connect } from "react-redux";
import { adminDownloadKYCAction } from "../../../redux/actions/ManageusersAction";

// SCSS
import "./KycDocumentDialog.scss";
import { includes, isEmpty, toLower } from "lodash";
import Viewer from 'react-viewer';
class KycDocumentDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            kycState: '',
            isImageViewerOpen: false,
            imageUrl: null

        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevProps.open && this.props.open) {

        }
    }

    render() {
        const { kycDocumentResult, kycDocumentLoader } = this.props.manageUsersReducer;

        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.handleClose}
                BackdropProps={{ classes: { root: "dialog-backdrop" } }}
                className="add-kyc-dialog"
            >
                <img className="close-btn" onClick={this.props.handleClose} src={require("../../../assets/images/common/Close_h.svg")} alt="" />
                <DialogTitle className="dialog-title">
                    KYC Documents
                </DialogTitle>
                <DialogContent className="add-kyc-content">
                    {kycDocumentResult && !isEmpty(kycDocumentResult) ?
                        <div className="add-kyc-featured-box">
                            <div className="section">
                                <h6>Full Name</h6>
                                <h4>{kycDocumentResult.fullName}</h4>
                            </div>
                            <div className="hr-line"></div>
                            <div className="section-image">
                                <div className="image-container" >
                                    <h4>Identity proof with photo</h4>
                                    {includes(toLower(kycDocumentResult.idProofFileName), ".pdf") ?
                                        <div className="viewpdf" onClick={this.onClickPdf.bind(this, kycDocumentResult.idProof)}>View PDF</div>
                                        // <img
                                        //     src={require("../../../assets/images/common/down-arrow.svg")} alt="kycimage"
                                        //     onClick={this.onClickPdf.bind(this, kycDocumentResult.idProof)} />
                                        :
                                        <img
                                            src={kycDocumentResult.idProof ? `data:image/jpg;base64,${kycDocumentResult.idProof}` : require("../../../assets/images/common/No_Image.png")} alt="kycimage"
                                            onClick={this.onClickViewImageFullscreen.bind(this, kycDocumentResult.idProof)}
                                        />
                                    }
                                </div>
                                <div className="image-container" >
                                    <h4>Address Proof (Bill)</h4>
                                    {includes(toLower(kycDocumentResult.addressProofFileName), ".pdf") ?
                                        <div className="viewpdf" onClick={this.onClickPdf.bind(this, kycDocumentResult.addressProof)}>View PDF</div>
                                        // <img
                                        //     src={require("../../../assets/images/common/down-arrow.svg")} alt="kycimage"
                                        //     onClick={this.onClickPdf.bind(this, kycDocumentResult.addressProof)}

                                        // />
                                        :
                                        <img
                                            src={kycDocumentResult.addressProof ? `data:image/jpg;base64,${kycDocumentResult.addressProof}` : require("../../../assets/images/common/No_Image.png")} alt="kycimage"
                                            onClick={this.onClickViewImageFullscreen.bind(this, kycDocumentResult.addressProof)}
                                        />
                                    }
                                </div>
                            </div>

                            {/* <div className="section">
                                <h6>Comments</h6>
                                <h5>{kycDocumentResult.comments}</h5>
                            </div> */}
                        </div>
                        :
                        <div className="add-kyc-featured-box">
                            <h1 className="no-record">{kycDocumentLoader ? 'Loading...' : 'No Record Found'}</h1>
                        </div>

                    }

                    <div className="add-kyc-action">
                        <button className="btn-cancel" onClick={this.onClickCancel.bind(this)}>
                            Cancel
                        </button>
                        {kycDocumentResult && !isEmpty(kycDocumentResult) && kycDocumentResult.status !== 2 &&
                            <button className="btn-orange" onClick={this.onClickCreate.bind(this, 'Reject')} disabled={false}>
                                Reject
                            </button>
                        }
                        {kycDocumentResult && !isEmpty(kycDocumentResult) && kycDocumentResult.status !== 2 &&
                            <button className="btn-orange" onClick={this.onClickCreate.bind(this, 'Approve')} disabled={false}>
                                Approve
                            </button>
                        }
                    </div>
                    {kycDocumentResult && kycDocumentResult.idProof && kycDocumentResult.addressProof && (
                        <Viewer
                            visible={this.state.isImageViewerOpen}
                            zIndex={2000}
                            onClose={() => {
                                this.setState({
                                    isImageViewerOpen: false,
                                    imageUrl: null
                                })
                            }}
                            images={[{ src: this.state.imageUrl, alt: 'kycimage' }]}
                        />
                    )}
                </DialogContent>
            </Dialog>
        );
    }

    onClickPdf(data) {
        const raw = window.atob(data);
        const rawLength = raw.length;
        const blobArray = new Uint8Array(new ArrayBuffer(rawLength));

        for (let i = 0; i < rawLength; i++) {
            blobArray[i] = raw.charCodeAt(i);
        }

        const blob = new Blob([blobArray], { type: 'application/pdf' });

        let url = window.URL.createObjectURL(blob);
        window.open(url, "_blank");
    }

    onClickCreate(type) {
        let isType = type === 'Reject' ? false : true;
        this.setState({
            kycState: type
        })
        const { kycDocumentResult } = this.props.manageUsersReducer;
        if (kycDocumentResult && !isEmpty(kycDocumentResult)) {
            this.props.approveKYC(kycDocumentResult.userId, isType);
        }
    }

    onClickCancel() {
        this.props.handleClose();
    }

    onClickViewImageFullscreen(value) {
        this.setState({
            isImageViewerOpen: true,
            imageUrl: `data:image/jpg;base64,${value}`
        });
    }

    onClickDownloadID() {
        const { kycDocumentResult } = this.props.manageUsersReducer;
        this.props.adminDownloadKYCAction(kycDocumentResult.userId, 0, kycDocumentResult.idProof);
    }

    onClickDownloadProof() {
        const { kycDocumentResult } = this.props.manageUsersReducer;
        this.props.adminDownloadKYCAction(kycDocumentResult.userId, 1, kycDocumentResult.addressProof);
    }
}

const mapStateToProps = state => {
    return {
        manageUsersReducer: state.manageUsersReducer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        adminDownloadKYCAction: (userId, type, fileName) => {
            dispatch(adminDownloadKYCAction(userId, type, fileName))
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(KycDocumentDialog);
