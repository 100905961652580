// Language
export const SET_LANGUAGE = "SET_LANGUAGE";

// Login
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

// Two Factor
export const TWO_FACTOR_REQUEST = "TWO_FACTOR_REQUEST";
export const TWO_FACTOR_SUCCESS = "TWO_FACTOR_SUCCESS";
export const TWO_FACTOR_FAILURE = "TWO_FACTOR_FAILURE";

//Forgot Password
export const SEND_OTP_REQUEST = "SEND_OTP_REQUEST";
export const SEND_OTP_SUCCESS = "SEND_OTP_SUCCESS";
export const SEND_OTP_FAILURE = "SEND_OTP_FAILURE";

export const VALIDATE_OTP_REQUEST = "VALIDATE_OTP_REQUEST";
export const VALIDATE_OTP_SUCCESS = "VALIDATE_OTP_SUCCESS";
export const VALIDATE_OTP_FAILURE = "VALIDATE_OTP_FAILURE";

export const PASSWORD_CONFIRM_REQUEST = "PASSWORD_CONFIRM_REQUEST";
export const PASSWORD_CONFIRM_SUCCESS = "PASSWORD_CONFIRM_SUCCESS";
export const PASSWORD_CONFIRM_FAILURE = "PASSWORD_CONFIRM_FAILURE";

//Dashboard
export const GET_DASHBOARD_DETAILS_REQUEST = "GET_DASHBOARD_DETAILS_REQUEST";
export const GET_DASHBOARD_DETAILS_SUCCESS = "GET_DASHBOARD_DETAILS_SUCCESS";
export const GET_DASHBOARD_DETAILS_FAILURE = "GET_DASHBOARD_DETAILS_FAILURE";

//Original Artwork
export const GET_ORIGINAL_ARTWORKS_REQUEST = "GET_ORIGINAL_ARTWORKS_REQUEST";
export const GET_ORIGINAL_ARTWORKS_SUCCESS = "GET_ORIGINAL_ARTWORKS_SUCCESS";
export const GET_ORIGINAL_ARTWORKS_FAILURE = "GET_ORIGINAL_ARTWORKS_FAILURE";

// Category Management
export const GET_CATEGORY_REQUEST = "GET_CATEGORY_REQUEST";
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS";
export const GET_CATEGORY_FAILURE = "GET_CATEGORY_FAILURE";

export const CREATE_CATEGORY_REQUEST = "CREATE_CATEGORY_REQUEST";
export const CREATE_CATEGORY_SUCCESS = "CREATE_CATEGORY_SUCCESS";
export const CREATE_CATEGORY_FAILURE = "CREATE_CATEGORY_FAILURE";

export const DELETE_CATEGORY_REQUEST = "DELETE_CATEGORY_REQUEST";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_FAILURE = "DELETE_CATEGORY_FAILURE";

export const UPDATE_CATEGORY_REQUEST = "UPDATE_CATEGORY_REQUEST";
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY_FAILURE = "UPDATE_CATEGORY_FAILURE";

export const CREATE_PROPERTY_REQUEST = "CREATE_PROPERTY_REQUEST";
export const CREATE_PROPERTY_SUCCESS = "CREATE_PROPERTY_SUCCESS";
export const CREATE_PROPERTY_FAILURE = "CREATE_PROPERTY_FAILURE";

export const DELETE_PROPERTY_REQUEST = "DELETE_PROPERTY_REQUEST";
export const DELETE_PROPERTY_SUCCESS = "DELETE_PROPERTY_SUCCESS";
export const DELETE_PROPERTY_FAILURE = "DELETE_PROPERTY_FAILURE";

export const SET_BREADCRUMBS_SUCCESS = "SET_BREADCRUMBS_SUCCESS";
export const SET_BREADCRUMBS_FAILURE = "SET_BREADCRUMBS_FAILURE";

export const SET_CATEGORY_REDUCER_STATES = "SET_CATEGORY_REDUCER_STATES";
export const TOGGLE_ADDCATEGORY_DIALOG = "TOGGLE_ADDCATEGORY_DIALOG";
export const TOGGLE_ADDPROPERTY_DIALOG = "TOGGLE_ADDPROPERTY_DIALOG";
export const TOGGLE_DELETEMAINCATEGORY_DIALOG = "TOGGLE_DELETEMAINCATEGORY_DIALOG";
export const TOGGLE_EDITVIEWCATEGORY_DIALOG = "TOGGLE_EDITVIEWCATEGORY_DIALOG";
export const TOGGLE_DELETEPROPERTY_DIALOG = "TOGGLE_DELETEPROPERTY_DIALOG";
export const TOGGLE_ADD_RULE_DIALOG = "TOGGLE_ADD_RULE_DIALOG";
export const TOGGLE_LIST_RULE_DIALOG = "TOGGLE_LIST_RULE_DIALOG";

export const GET_PROPERTY_REQUEST = "GET_PROPERTY_REQUEST";
export const GET_PROPERTY_SUCCESS = "GET_PROPERTY_SUCCESS";
export const GET_PROPERTY_FAILURE = "GET_PROPERTY_FAILURE";

export const GET_CATEGORY_RULES_REQUEST = "GET_CATEGORY_RULES_REQUEST";
export const GET_CATEGORY_RULES_SUCCESS = "GET_CATEGORY_RULES_SUCCESS";
export const GET_CATEGORY_RULES_FAILURE = "GET_CATEGORY_RULES_FAILURE";

export const CREATE_CATEGORY_RULES_REQUEST = "CREATE_CATEGORY_RULES_REQUEST";
export const CREATE_CATEGORY_RULES_SUCCESS = "CREATE_CATEGORY_RULES_SUCCESS";
export const CREATE_CATEGORY_RULES_FAILURE = "CREATE_CATEGORY_RULES_FAILURE";

export const UPDATE_CATEGORY_RULES_REQUEST = "UPDATE_CATEGORY_RULES_REQUEST";
export const UPDATE_CATEGORY_RULES_SUCCESS = "UPDATE_CATEGORY_RULES_SUCCESS";
export const UPDATE_CATEGORY_RULES_FAILURE = "UPDATE_CATEGORY_RULES_FAILURE";

export const DELETE_CATEGORY_RULES_REQUEST = "DELETE_CATEGORY_RULES_REQUEST";
export const DELETE_CATEGORY_RULES_SUCCESS = "DELETE_CATEGORY_RULES_SUCCESS";
export const DELETE_CATEGORY_RULES_FAILURE = "DELETE_CATEGORY_RULES_FAILURE";

// Home Screen
export const GET_HOME_BANNER_LIST_REQUEST = "GET_HOME_BANNER_LIST_REQUEST";
export const GET_HOME_BANNER_LIST_SUCCESS = "GET_HOME_BANNER_LIST_SUCCESS";
export const GET_HOME_BANNER_LIST_FAILURE = "GET_HOME_BANNER_LIST_FAILURE";

export const CREATE_HOME_BANNER_REQUEST = "CREATE_HOME_BANNER_REQUEST";
export const CREATE_HOME_BANNER_SUCCESS = "CREATE_HOME_BANNER_SUCCESS";
export const CREATE_HOME_BANNER_FAILURE = "CREATE_HOME_BANNER_FAILURE";

export const UPDATE_HOME_BANNER_REQUEST = "UPDATE_HOME_BANNER_REQUEST";
export const UPDATE_HOME_BANNER_SUCCESS = "UPDATE_HOME_BANNER_SUCCESS";
export const UPDATE_HOME_BANNER_FAILURE = "UPDATE_HOME_BANNER_FAILURE";

export const DELETE_HOME_BANNER_REQUEST = "DELETE_HOME_BANNER_REQUEST";
export const DELETE_HOME_BANNER_SUCCESS = "DELETE_HOME_BANNER_SUCCESS";
export const DELETE_HOME_BANNER_FAILURE = "DELETE_HOME_BANNER_FAILURE";
export const TOGGLE_ADDBANNER_DIALOG = "TOGGLE_ADDBANNER_DIALOG";

// Logout
export const TOGGLE_LOGOUT_DIALOG = "TOGGLE_LOGOUT_DIALOG";
export const LOGOUT = "LOGOUT";

//Manage Sellers
export const GET_SELLERSLIST_REQUEST = "GET_SELLERSLIST_REQUEST";
export const GET_SELLERSLIST_SUCCESS = "GET_SELLERSLIST_SUCCESS";
export const GET_SELLERSLIST_FAILURE = "GET_SELLERSLIST_FAILURE";
export const GET_PRODUCT_LIST_REQUEST = "GET_PRODUCT_LIST_REQUEST";
export const GET_PRODUCT_LIST_SUCCESS = "GET_PRODUCT_LIST_SUCCESS";
export const GET_PRODUCT_LIST_FAILURE = "GET_PRODUCT_LIST_FAILURE";
export const BLOCK_SELLER_REQUEST = "BLOCK_SELLER_REQUEST";
export const BLOCK_SELLER_SUCCESS = "BLOCK_SELLER_SUCCESS";
export const BLOCK_SELLER_FAILURE = "BLOCK_SELLER_FAILURE";
export const UNBLOCK_SELLER_REQUEST = "UNBLOCK_SELLER_REQUEST";
export const UNBLOCK_SELLER_SUCCESS = "UNBLOCK_SELLER_SUCCESS";
export const UNBLOCK_SELLER_FAILURE = "UNBLOCK_SELLER_FAILURE";
export const GET_SELLER_DETAILS_REQUEST = "GET_SELLER_DETAILS_REQUEST";
export const GET_SELLER_DETAILS_SUCCESS = "GET_SELLER_DETAILS_SUCCESS";
export const GET_SELLER_DETAILS_FAILURE = "GET_SELLER_DETAILS_FAILURE";
export const SELLER_PROFILE_DETAILS = "SELLER_PROFILE_DETAILS";
export const SET_MANAGESELLER_REDUCER_STATES = "SET_MANAGESELLER_REDUCER_STATES";

// Manage Artworks
export const GET_ARTWORKSLIST_REQUEST = "GET_ARTWORKSLIST_REQUEST";
export const GET_ARTWORKSLIST_SUCCESS = "GET_ARTWORKSLIST_SUCCESS";
export const GET_ARTWORKSLIST_FAILURE = "GET_ARTWORKSLIST_FAILURE";

export const UPDATE_PRODUCT_STATUS_REQUEST = "UPDATE_PRODUCT_STATUS_REQUEST";
export const UPDATE_PRODUCT_STATUS_SUCCESS = "UPDATE_PRODUCT_STATUS_SUCCESS";
export const UPDATE_PRODUCT_STATUS_FAILURE = "UPDATE_PRODUCT_STATUS_FAILURE";

export const UNHOLD_ARTWORK_REQUEST = "UNHOLD_ARTWORK_REQUEST";
export const UNHOLD_ARTWORK_SUCCESS = "UNHOLD_ARTWORK_SUCCESS";
export const UNHOLD_ARTWORK_FAILURE = "UNHOLD_ARTWORK_FAILURE";

export const DELETE_ARTWORK_REQUEST = "DELETE_ARTWORK_REQUEST";
export const DELETE_ARTWORK_SUCCESS = "DELETE_ARTWORK_SUCCESS";
export const DELETE_ARTWORK_FAILURE = "DELETE_ARTWORK_FAILURE";

export const GET_ARTWORKS_DOWNLOAD_REQUEST = "GET_ARTWORKS_DOWNLOAD_REQUEST";
export const GET_ARTWORKS_DOWNLOAD_SUCCESS = "GET_ARTWORKS_DOWNLOAD_SUCCESS";
export const GET_ARTWORKS_DOWNLOAD_FAILURE = "GET_ARTWORKS_DOWNLOAD_FAILURE";

export const SET_ARTWORK_FEATURED_REQUEST = "SET_ARTWORK_FEATURED_REQUEST";
export const SET_ARTWORK_FEATURED_SUCCESS = "SET_ARTWORK_FEATURED_SUCCESS";
export const SET_ARTWORK_FEATURED_FAILURE = "SET_ARTWORK_FEATURED_FAILURE";

export const TOGGLE_CHANGE_PRODUCT_STATUS = "TOGGLE_CHANGE_PRODUCT_STATUS";

//Manage Users
export const GET_USERSLIST_REQUEST = "GET_USERSLIST_REQUEST";
export const GET_USERSLIST_SUCCESS = "GET_USERSLIST_SUCCESS";
export const GET_USERSLIST_FAILURE = "GET_USERSLIST_FAILURE";
export const ORDER_HISTORY_REQUEST = "ORDER_HISTORY_REQUEST";
export const ORDER_HISTORY_SUCCESS = "ORDER_HISTORY_SUCCESS";
export const ORDER_HISTORY_FAILURE = "ORDER_HISTORY_FAILURE";
export const USER_PROFILE_DETAILS = "USER_PROFILE_DETAILS";
export const GET_USERS_DETAILS_REQUEST = "GET_USERS_DETAILS_REQUEST";
export const GET_USERS_DETAILS_SUCCESS = "GET_USERS_DETAILS_SUCCESS";
export const GET_USERS_DETAILS_FAILURE = "GET_USERS_DETAILS_FAILURE";
export const BLOCK_USER_REQUEST = "BLOCK_USER_REQUEST";
export const BLOCK_USER_SUCCESS = "BLOCK_USER_SUCCESS";
export const BLOCK_USER_FAILURE = "BLOCK_USER_FAILURE";
export const UNBLOCK_USER_REQUEST = "UNBLOCK_USER_REQUEST";
export const UNBLOCK_USER_SUCCESS = "UNBLOCK_USER_SUCCESS";
export const UNBLOCK_USER_FAILURE = "UNBLOCK_USER_FAILURE";
export const GET_SHIPPING_ADDRESS_REQUEST = "GET_SHIPPING_ADDRESS_REQUEST";
export const GET_SHIPPING_ADDRESS_SUCCESS = "GET_SHIPPING_ADDRESS_SUCCESS";
export const GET_SHIPPING_ADDRESS_FAILURE = "GET_SHIPPING_ADDRESS_FAILURE";

export const GET_ROLE_CHANGE_LIST_REQUEST = "GET_ROLE_CHANGE_LIST_REQUEST";
export const GET_ROLE_CHANGE_LIST_SUCCESS = "GET_ROLE_CHANGE_LIST_SUCCESS";
export const GET_ROLE_CHANGE_LIST_FAILURE = "GET_ROLE_CHANGE_LIST_FAILURE";

export const UPDATE_ROLE_CHANGE_REQUEST = "UPDATE_ROLE_CHANGE_REQUEST";
export const UPDATE_ROLE_CHANGE_SUCCESS = "UPDATE_ROLE_CHANGE_SUCCESS";
export const UPDATE_ROLE_CHANGE_FAILURE = "UPDATE_ROLE_CHANGE_FAILURE";

export const TOGGLE_KYC_DIALOG = "TOGGLE_KYC_DIALOG";

export const GET_EXPORTUSERSLIST_REQUEST = "GET_EXPORTUSERSLIST_REQUEST";
export const GET_EXPORTUSERSLIST_SUCCESS = "GET_EXPORTUSERSLIST_SUCCESS";
export const GET_EXPORTUSERSLIST_FAILURE = "GET_EXPORTUSERSLIST_FAILURE";

//Mange Artists
export const GET_ARTISTSLIST_REQUEST = "GET_ARTISTSLIST_REQUEST";
export const GET_ARTISTSLIST_SUCCESS = "GET_ARTISTSLIST_SUCCESS";
export const GET_ARTISTSLIST_FAILURE = "GET_ARTISTSLIST_FAILURE";

export const GET_ARTIST_PROFILE_REQUEST = "GET_ARTIST_PROFILE_REQUEST";
export const GET_ARTIST_PROFILE_SUCCESS = "GET_ARTIST_PROFILE_SUCCESS";
export const GET_ARTIST_PROFILE_FAILURE = "GET_ARTIST_PROFILE_FAILURE";

export const SET_ARTIST_FEATURED_REQUEST = "SET_ARTIST_FEATURED_REQUEST";
export const SET_ARTIST_FEATURED_SUCCESS = "SET_ARTIST_FEATURED_SUCCESS";
export const SET_ARTIST_FEATURED_FAILURE = "SET_ARTIST_FEATURED_FAILURE";

export const GET_ARTIST_DOWNLOAD_REQUEST = "GET_ARTIST_DOWNLOAD_REQUEST";
export const GET_ARTIST_DOWNLOAD_SUCCESS = "GET_ARTIST_DOWNLOAD_SUCCESS";
export const GET_ARTIST_DOWNLOAD_FAILURE = "GET_ARTIST_DOWNLOAD_FAILURE";

export const UPDATE_ARTIST_INFO_REQUEST = "UPDATE_ARTIST_INFO_REQUEST";
export const UPDATE_ARTIST_INFO_SUCCESS = "UPDATE_ARTIST_INFO_SUCCESS";
export const UPDATE_ARTIST_INFO_FAILURE = "UPDATE_ARTIST_INFO_FAILURE";

export const TOGGLE_ARTIST_INFO_DIALOG = "TOGGLE_ARTIST_INFO_DIALOG";
//Manage Gallery
export const GET_GALLERYLIST_REQUEST = "GET_GALLERYLIST_REQUEST";
export const GET_GALLERYLIST_SUCCESS = "GET_GALLERYLIST_SUCCESS";
export const GET_GALLERYLIST_FAILURE = "GET_GALLERYLIST_FAILURE";

export const GET_GALLERY_PROFILE_REQUEST = "GET_GALLERY_PROFILE_REQUEST";
export const GET_GALLERY_PROFILE_SUCCESS = "GET_GALLERY_PROFILE_SUCCESS";
export const GET_GALLERY_PROFILE_FAILURE = "GET_GALLERY_PROFILE_FAILURE";

export const SET_GALLERY_FEATURED_REQUEST = "SET_GALLERY_FEATURED_REQUEST";
export const SET_GALLERY_FEATURED_SUCCESS = "SET_GALLERY_FEATURED_SUCCESS";
export const SET_GALLERY_FEATURED_FAILURE = "SET_GALLERY_FEATURED_FAILURE";

//Collections
export const GET_COLLECTIONS_LIST_REQUEST = "GET_COLLECTIONS_LIST_REQUEST";
export const GET_COLLECTIONS_LIST_SUCCESS = "GET_COLLECTIONS_LIST_SUCCESS";
export const GET_COLLECTIONS_LIST_FAILURE = "GET_COLLECTIONS_LIST_FAILURE";
export const GET_COLLECTIONS_LIST_BY_ID_REQUEST = "GET_COLLECTIONS_LIST_BY_ID_REQUEST";
export const GET_COLLECTIONS_LIST_BY_ID_SUCCESS = "GET_COLLECTIONS_LIST_BY_ID_SUCCESS";
export const GET_COLLECTIONS_LIST_BY_ID_FAILURE = "GET_COLLECTIONS_LIST_BY_ID_FAILURE";
export const DELETE_COLLECTION_REQUEST = "DELETE_COLLECTION_REQUEST";
export const DELETE_COLLECTION_SUCCESS = "DELETE_COLLECTION_SUCCESS";
export const DELETE_COLLECTION_FAILURE = "DELETE_COLLECTION_FAILURE";

export const CASCADE_MENU_REQUEST = "CASCADE_MENU_REQUEST";
export const CASCADE_MENU_SUCCESS = "CASCADE_MENU_SUCCESS";
export const CASCADE_MENU_FAILURE = "CASCADE_MENU_FAILURE";
export const SET_COLLECTIONS_REDUCER_STATES = "SET_COLLECTIONS_REDUCER_STATES";
export const SET_COLLECTIONS_INITIALIZE_FILTER_STATES = "SET_COLLECTIONS_INITIALIZE_FILTER_STATES";
export const GET_ARTISTS_REQUEST = "GET_ARTISTS_REQUEST";
export const GET_ARTISTS_SUCCESS = "GET_ARTISTS_SUCCESS";
export const GET_ARTISTS_FAILURE = "GET_ARTISTS_FAILURE";
export const GET_FILTER_CATEGORY_PROPERTIES_REQUEST = "GET_FILTER_CATEGORY_PROPERTIES_REQUEST";
export const GET_FILTER_CATEGORY_PROPERTIES_SUCCESS = "GET_FILTER_CATEGORY_PROPERTIES_SUCCESS";
export const GET_FILTER_CATEGORY_PROPERTIES_FAILURE = "GET_FILTER_CATEGORY_PROPERTIES_FAILURE";
export const SEARCH_ARTWORKS_REQUEST = "SEARCH_ARTWORKS_REQUEST";
export const SEARCH_ARTWORKS_SUCCESS = "SEARCH_ARTWORKS_SUCCESS";
export const SEARCH_ARTWORKS_FAILURE = "SEARCH_ARTWORKS_FAILURE";
export const ADD_COLLECTION_REQUEST = "ADD_COLLECTION_REQUEST";
export const ADD_COLLECTION_SUCCESS = "ADD_COLLECTION_SUCCESS";
export const ADD_COLLECTION_FAILURE = "ADD_COLLECTION_FAILURE";
export const DELETE_COLLECTION_ITEM_REQUEST = "DELETE_COLLECTION_ITEM_REQUEST";
export const DELETE_COLLECTION_ITEM_SUCCESS = "DELETE_COLLECTION_ITEM_SUCCESS";
export const DELETE_COLLECTION_ITEM_FAILURE = "DELETE_COLLECTION_ITEM_FAILURE";

//Orders
export const GET_ORDERS_LIST_REQUEST = "GET_ORDERS_LIST_REQUEST";
export const GET_ORDERS_LIST_SUCCESS = "GET_ORDERS_LIST_SUCCESS";
export const GET_ORDERS_LIST_FAILURE = "GET_ORDERS_LIST_FAILURE";

export const GET_ORDER_DETAILS_REQUEST = "GET_ORDER_DETAILS_REQUEST";
export const GET_ORDER_DETAILS_SUCCESS = "GET_ORDER_DETAILS_SUCCESS";
export const GET_ORDER_DETAILS_FAILURE = "GET_ORDER_DETAILS_FAILURE";

export const UPDATE_ORDER_STATUS_REQUEST = "UPDATE_ORDER_STATUS_REQUEST";
export const UPDATE_ORDER_STATUS_SUCCESS = "UPDATE_ORDER_STATUS_SUCCESS";
export const UPDATE_ORDER_STATUS_FAILURE = "UPDATE_ORDER_STATUS_FAILURE";

export const GET_ORDER_INVOICE_REQUEST = "GET_ORDER_INVOICE_REQUEST";
export const GET_ORDER_INVOICE_SUCCESS = "GET_ORDER_INVOICE_SUCCESS";
export const GET_ORDER_INVOICE_FAILURE = "GET_ORDER_INVOICE_FAILURE";

export const REFUND_ORDER_AMOUNT_REQUEST = "REFUND_ORDER_AMOUNT_REQUEST";
export const REFUND_ORDER_AMOUNT_SUCCESS = "REFUND_ORDER_AMOUNT_SUCCESS";
export const REFUND_ORDER_AMOUNT_FAILURE = "REFUND_ORDER_AMOUNT_FAILURE";

export const GET_ORDER_WAYBILL_REQUEST = "GET_ORDER_WAYBILL_REQUEST";
export const GET_ORDER_WAYBILL_SUCCESS = "GET_ORDER_WAYBILL_SUCCESS";
export const GET_ORDER_WAYBILL_FAILURE = "GET_ORDER_WAYBILL_FAILURE";

export const GET_PICKUP_DETAILS_REQUEST = "GET_PICKUP_DETAILS_REQUEST";
export const GET_PICKUP_DETAILS_SUCCESS = "GET_PICKUP_DETAILS_SUCCESS";
export const GET_PICKUP_DETAILS_FAILURE = "GET_PICKUP_DETAILS_FAILURE";

//Notification
export const GET_NOTIFICATION_LIST_REQUEST = "GET_NOTIFICATION_LIST_REQUEST";
export const GET_NOTIFICATION_LIST_SUCCESS = "GET_NOTIFICATION_LIST_SUCCESS";
export const GET_NOTIFICATION_LIST_FAILURE = "GET_NOTIFICATION_LIST_FAILURE";

// Add Brands
export const SET_ADD_BRAND_REDUCER_STATES = "SET_ADD_BRAND_REDUCER_STATES";

export const GET_BRANDS_REQUEST = "GET_BRANDS_REQUEST";
export const GET_BRANDS_SUCCESS = "GET_BRANDS_SUCCESS";
export const GET_BRANDS_FAILURE = "GET_BRANDS_FAILURE";

export const CREATE_BRAND_REQUEST = "CREATE_BRAND_REQUEST";
export const CREATE_BRAND_SUCCESS = "CREATE_BRAND_SUCCESS";
export const CREATE_BRAND_FAILURE = "CREATE_BRAND_FAILURE";

export const DELETE_BRAND_REQUEST = "DELETE_BRAND_REQUEST";
export const DELETE_BRAND_SUCCESS = "DELETE_BRAND_SUCCESS";
export const DELETE_BRAND_FAILURE = "DELETE_BRAND_FAILURE";

// Blogs
export const GET_BLOGS_REQUEST = "GET_BLOGS_REQUEST";
export const GET_BLOGS_SUCCESS = "GET_BLOGS_SUCCESS";
export const GET_BLOGS_FAILURE = "GET_BLOGS_FAILURE";

export const UPDATE_BLOGS_REQUEST = "UPDATE_BLOGS_REQUEST";
export const UPDATE_BLOGS_SUCCESS = "UPDATE_BLOGS_SUCCESS";
export const UPDATE_BLOGS_FAILURE = "UPDATE_BLOGS_FAILURE";

export const DELETE_BLOGS_REQUEST = "DELETE_BLOGS_REQUEST";
export const DELETE_BLOGS_SUCCESS = "DELETE_BLOGS_SUCCESS";
export const DELETE_BLOGS_FAILURE = "DELETE_BLOGS_FAILURE";

export const ADD_BLOGS_REQUEST = "ADD_BLOGS_REQUEST";
export const ADD_BLOGS_SUCCESS = "ADD_BLOGS_SUCCESS";
export const ADD_BLOGS_FAILURE = "ADD_BLOGS_FAILURE";

// News
export const GET_NEWS_REQUEST = "GET_NEWS_REQUEST";
export const GET_NEWS_SUCCESS = "GET_NEWS_SUCCESS";
export const GET_NEWS_FAILURE = "GET_NEWS_FAILURE";

export const UPDATE_NEWS_REQUEST = "UPDATE_NEWS_REQUEST";
export const UPDATE_NEWS_SUCCESS = "UPDATE_NEWS_SUCCESS";
export const UPDATE_NEWS_FAILURE = "UPDATE_NEWS_FAILURE";

export const DELETE_NEWS_REQUEST = "DELETE_NEWS_REQUEST";
export const DELETE_NEWS_SUCCESS = "DELETE_NEWS_SUCCESS";
export const DELETE_NEWS_FAILURE = "DELETE_NEWS_FAILURE";

export const ADD_NEWS_REQUEST = "ADD_NEWS_REQUEST";
export const ADD_NEWS_SUCCESS = "ADD_NEWS_SUCCESS";
export const ADD_NEWS_FAILURE = "ADD_NEWS_FAILURE";

//Owned Products
export const GET_OWNED_PRODUCTS_REQUEST = "GET_OWNED_PRODUCTS_REQUEST";
export const GET_OWNED_PRODUCTS_SUCCESS = "GET_OWNED_PRODUCTS_SUCCESS";
export const GET_OWNED_PRODUCTS_FAILURE = "GET_OWNED_PRODUCTS_FAILURE";

//Gallery Listed Inventory
export const GET_GALLERY_LISTED_INVENTORY_REQUEST = "GET_GALLERY_LISTED_INVENTORY_REQUEST";
export const GET_GALLERY_LISTED_INVENTORY_SUCCESS = "GET_GALLERY_LISTED_INVENTORY_SUCCESS";
export const GET_GALLERY_LISTED_INVENTORY_FAILURE = "GET_GALLERY_LISTED_INVENTORY_FAILURE";

// Edit Product
export const GET_PRODUCT_REQUEST = "GET_PRODUCT_REQUEST";
export const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS";
export const GET_PRODUCT_FAILURE = "GET_PRODUCT_FAILURE";

export const GET_PARENTS_BY_CATEGORY_REQUEST = "GET_PARENTS_BY_CATEGORY_REQUEST";
export const GET_PARENTS_BY_CATEGORY_SUCCESS = "GET_PARENTS_BY_CATEGORY_SUCCESS";
export const GET_PARENTS_BY_CATEGORY_FAILURE = "GET_PARENTS_BY_CATEGORY_FAILURE";

export const GET_CATEGORY_PROPERTIES_AND_MAP_REQUEST = "GET_CATEGORY_PROPERTIES_AND_MAP_REQUEST";
export const GET_CATEGORY_PROPERTIES_AND_MAP_SUCCESS = "GET_CATEGORY_PROPERTIES_AND_MAP_SUCCESS";
export const GET_CATEGORY_PROPERTIES_AND_MAP_FAILURE = "GET_CATEGORY_PROPERTIES_AND_MAP_FAILURE";

export const UPDATE_PRODUCT_REQUEST = "UPDATE_PRODUCT_REQUEST";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_FAILURE = "UPDATE_PRODUCT_FAILURE";

export const DOWNLOAD_DISPLAY_PICTURE_REQUEST = "DOWNLOAD_DISPLAY_PICTURE_REQUEST";
export const DOWNLOAD_DISPLAY_PICTURE_SUCCESS = "DOWNLOAD_DISPLAY_PICTURE_SUCCESS";
export const DOWNLOAD_DISPLAY_PICTURE_FAILURE = "DOWNLOAD_DISPLAY_PICTURE_FAILURE";

export const SET_PRODUCT_DETAILS = "SET_PRODUCT_DETAILS";
export const SET_IMAGE_DETAILS = "SET_IMAGE_DETAILS";
export const CLEAR_UPDATE_PRODUCT_REDUCER_STATES = "CLEAR_UPDATE_PRODUCT_REDUCER_STATES";
export const CLEAR_TOASTS = "CLEAR_TOASTS";

//Countries & Taxs
export const GET_ALL_COUNTRY_LIST_REQUEST = "GET_ALL_COUNTRY_LIST_REQUEST";
export const GET_ALL_COUNTRY_LIST_SUCCESS = "GET_ALL_COUNTRY_LIST_SUCCESS";
export const GET_ALL_COUNTRY_LIST_FAILURE = "GET_ALL_COUNTRY_LIST_FAILURE";

export const GET_COUNTRY_TAX_LIST_REQUEST = "GET_COUNTRY_TAX_LIST_REQUEST";
export const GET_COUNTRY_TAX_LIST_SUCCESS = "GET_COUNTRY_TAX_LIST_SUCCESS";
export const GET_COUNTRY_TAX_LIST_FAILURE = "GET_COUNTRY_TAX_LIST_FAILURE";

export const ADD_COUNTRY_TAX_REQUEST = "ADD_COUNTRY_TAX_REQUEST";
export const ADD_COUNTRY_TAX_SUCCESS = "ADD_COUNTRY_TAX_SUCCESS";
export const ADD_COUNTRY_TAX_FAILURE = "ADD_COUNTRY_TAX_FAILURE";

export const UPDATE_COUNTRY_TAX_REQUEST = "UPDATE_COUNTRY_TAX_REQUEST";
export const UPDATE_COUNTRY_TAX_SUCCESS = "UPDATE_COUNTRY_TAX_SUCCESS";
export const UPDATE_COUNTRY_TAX_FAILURE = "UPDATE_COUNTRY_TAX_FAILURE";

export const DELETE_COUNTRY_TAX_REQUEST = "DELETE_COUNTRY_TAX_REQUEST";
export const DELETE_COUNTRY_TAX_SUCCESS = "DELETE_COUNTRY_TAX_SUCCESS";
export const DELETE_COUNTRY_TAX_FAILURE = "DELETE_COUNTRY_TAX_FAILURE";

export const TOGGLE_ADD_COUNTRY_TAX_DIALOG = "TOGGLE_ADD_COUNTRY_TAX_DIALOG";

//Quotes
export const GET_ALL_QUOTES_LIST_REQUEST = "GET_ALL_QUOTES_LIST_REQUEST";
export const GET_ALL_QUOTES_LIST_SUCCESS = "GET_ALL_QUOTES_LIST_SUCCESS";
export const GET_ALL_QUOTES_LIST_FAILURE = "GET_ALL_QUOTES_LIST_FAILURE";

export const UPDATE_QUOTE_PRICE_STATUS_REQUEST = "UPDATE_QUOTE_PRICE_STATUS_REQUEST";
export const UPDATE_QUOTE_PRICE_STATUS_SUCCESS = "UPDATE_QUOTE_PRICE_STATUS_SUCCESS";
export const UPDATE_QUOTE_PRICE_STATUS_FAILURE = "UPDATE_QUOTE_PRICE_STATUS_FAILURE";

//Shipment Provider
export const GET_SHIPMENT_PROVIDER_LIST_REQUEST = "GET_SHIPMENT_PROVIDER_LIST_REQUEST";
export const GET_SHIPMENT_PROVIDER_LIST_SUCCESS = "GET_SHIPMENT_PROVIDER_LIST_SUCCESS";
export const GET_SHIPMENT_PROVIDER_LIST_FAILURE = "GET_SHIPMENT_PROVIDER_LIST_FAILURE";

export const ADD_SHIPMENT_PROVIDER_REQUEST = "ADD_SHIPMENT_PROVIDER_REQUEST";
export const ADD_SHIPMENT_PROVIDER_SUCCESS = "ADD_SHIPMENT_PROVIDER_SUCCESS";
export const ADD_SHIPMENT_PROVIDER_FAILURE = "ADD_SHIPMENT_PROVIDER_FAILURE";

export const UPDATE_SHIPMENT_PROVIDER_REQUEST = "UPDATE_SHIPMENT_PROVIDER_REQUEST";
export const UPDATE_SHIPMENT_PROVIDER_SUCCESS = "UPDATE_SHIPMENT_PROVIDER_SUCCESS";
export const UPDATE_SHIPMENT_PROVIDER_FAILURE = "UPDATE_SHIPMENT_PROVIDER_FAILURE";

export const DELETE_SHIPMENT_PROVIDER_REQUEST = "DELETE_SHIPMENT_PROVIDER_REQUEST";
export const DELETE_SHIPMENT_PROVIDER_SUCCESS = "DELETE_SHIPMENT_PROVIDER_SUCCESS";
export const DELETE_SHIPMENT_PROVIDER_FAILURE = "DELETE_SHIPMENT_PROVIDER_FAILURE";

export const TOGGLE_ADD_SHIPMENT_PROVIDER_DIALOG = "TOGGLE_ADD_SHIPMENT_PROVIDER_DIALOG";

//Shipping Charges
export const GET_SHIPPING_CHARGES_LIST_REQUEST = "GET_SHIPPING_CHARGES_LIST_REQUEST";
export const GET_SHIPPING_CHARGES_LIST_SUCCESS = "GET_SHIPPING_CHARGES_LIST_SUCCESS";
export const GET_SHIPPING_CHARGES_LIST_FAILURE = "GET_SHIPPING_CHARGES_LIST_FAILURE";

export const ADD_SHIPPING_CHARGES_REQUEST = "ADD_SHIPPING_CHARGES_REQUEST";
export const ADD_SHIPPING_CHARGES_SUCCESS = "ADD_SHIPPING_CHARGES_SUCCESS";
export const ADD_SHIPPING_CHARGES_FAILURE = "ADD_SHIPPING_CHARGES_FAILURE";

export const UPDATE_SHIPPING_CHARGES_REQUEST = "UPDATE_SHIPPING_CHARGES_REQUEST";
export const UPDATE_SHIPPING_CHARGES_SUCCESS = "UPDATE_SHIPPING_CHARGES_SUCCESS";
export const UPDATE_SHIPPING_CHARGES_FAILURE = "UPDATE_SHIPPING_CHARGES_FAILURE";

export const DELETE_SHIPPING_CHARGES_REQUEST = "DELETE_SHIPPING_CHARGES_REQUEST";
export const DELETE_SHIPPING_CHARGES_SUCCESS = "DELETE_SHIPPING_CHARGES_SUCCESS";
export const DELETE_SHIPPING_CHARGES_FAILURE = "DELETE_SHIPPING_CHARGES_FAILURE";

export const TOGGLE_ADD_SHIPPING_CHARGES_DIALOG = "TOGGLE_ADD_SHIPPING_CHARGES_DIALOG";

export const GET_USER_KYC_DOC_REQUEST = "GET_USER_KYC_DOC_REQUEST";
export const GET_USER_KYC_DOC_SUCCESS = "GET_USER_KYC_DOC_SUCCESS";
export const GET_USER_KYC_DOC_FAILURE = "GET_USER_KYC_DOC_FAILURE";

export const KYC_APPROVE_REQUEST = "KYC_APPROVE_REQUEST";
export const KYC_APPROVE_SUCCESS = "KYC_APPROVE_SUCCESS";
export const KYC_APPROVE_FAILURE = "KYC_APPROVE_FAILURE";

export const GET_KYC_REQUEST = "GET_KYC_REQUEST";
export const GET_KYC_SUCCESS = "GET_KYC_SUCCESS";
export const GET_KYC_FAILURE = "GET_KYC_FAILURE";

//Lookup
export const GET_ALL_LOOKUP = "GET_ALL_LOOKUP";
export const GET_ALL_LOOKUP_SUCCESS = "GET_ALL_LOOKUP_SUCCESS";
export const GET_ALL_LOOKUP_FAILURE = "GET_ALL_LOOKUP_FAILURE";

export const CREATE_LOOKUP_REQUEST = "CREATE_LOOKUP_REQUEST";
export const CREATE_LOOKUP_SUCCESS = "CREATE_LOOKUP_SUCCESS";
export const CREATE_LOOKUP_FAILURE = "CREATE_LOOKUP_FAILURE";

export const UPDATE_LOOKUP_REQUEST = "UPDATE_LOOKUP_REQUEST";
export const UPDATE_LOOKUP_SUCCESS = "UPDATE_LOOKUP_SUCCESS";
export const UPDATE_LOOKUP_FAILURE = "UPDATE_LOOKUP_FAILURE";

export const DELETE_LOOKUP_REQUEST = "DELETE_LOOKUP_REQUEST";
export const DELETE_LOOKUP_SUCCESS = "DELETE_LOOKUP_SUCCESS";
export const DELETE_LOOKUP_FAILURE = "DELETE_LOOKUP_FAILURE";

//FILTER RULES
export const GET_ALL_FILTER_RULE = "GET_ALL_FILTER_RULE";
export const GET_ALL_FILTER_RULE_SUCCESS = "GET_ALL_FILTER_RULE_SUCCESS";
export const GET_ALL_FILTER_RULE_FAILURE = "GET_ALL_FILTER_RULE_FAILURE";

export const GET_FILTER_RULE_BY_ID = "GET_FILTER_RULE_BY_ID";
export const GET_FILTER_RULE_BY_ID_SUCCESS = "GET_FILTER_RULE_BY_ID_SUCCESS";
export const GET_FILTER_RULE_BY_ID_FAILURE = "GET_FILTER_RULE_BY_ID_FAILURE";

export const DELETE_FILTER_RULE_REQUEST = "DELETE_FILTER_RULE_REQUEST";
export const DELETE_FILTER_RULE_SUCCESS = "DELETE_FILTER_RULE_SUCCESS";
export const DELETE_FILTER_RULE_FAILURE = "DELETE_FILTER_RULE_FAILURE";

export const CREATE_FILTER_RULE_REQUEST = "CREATE_FILTER_RULE_REQUEST";
export const CREATE_FILTER_RULE_SUCCESS = "CREATE_FILTER_RULE_SUCCESS";
export const CREATE_FILTER_RULE_FAILURE = "CREATE_FILTER_RULE_FAILURE";

export const UPDATE_FILTER_RULE_REQUEST = "UPDATE_FILTER_RULE_REQUEST";
export const UPDATE_FILTER_RULE_SUCCESS = "UPDATE_FILTER_RULE_SUCCESS";
export const UPDATE_FILTER_RULE_FAILURE = "UPDATE_FILTER_RULE_FAILURE";

export const BULK_ARTWORK_UPLOAD = "BULK_ARTWORK_UPLOAD";
export const BULK_ARTWORK_UPLOAD_SUCCESS = "BULK_ARTWORK_UPLOAD_SUCCESS";
export const BULK_ARTWORK_UPLOAD_FAILURE = "BULK_ARTWORK_UPLOAD_FAILURE";

export const BLOCK_ARTIST = "BLOCK_ARTIST";
export const BLOCK_ARTIST_SUCCESS = "BLOCK_ARTIST_SUCCESS";
export const BLOCK_ARTIST_FAILURE = "BLOCK_ARTIST_FAILURE";

export const META_DATA_ARTWORK_UPDATE = "META_DATA_ARTWORK_UPDATE";
export const META_DATA_ARTWORK_UPDATE_SUCCESS = "META_DATA_ARTWORK_UPDATE_SUCCESS";
export const META_DATA_ARTWORK_UPDATE_FAILURE = "META_DATA_ARTWORK_UPDATE_FAILURE";

//Manage coupons
export const GET_ALL_COUPONS_REQUEST = "GET_ALL_COUPONS_REQUEST";
export const GET_ALL_COUPONS_SUCCESS = "GET_ALL_COUPONS_SUCCESS";
export const GET_ALL_COUPONS_FAILURE = "GET_ALL_COUPONS_FAILURE";

export const ADD_COUPONS_REQUEST = "ADD_COUPONS_REQUEST";
export const ADD_COUPONS_SUCCESS = "ADD_COUPONS_SUCCESS";
export const ADD_COUPONS_FAILURE = "ADD_COUPONS_FAILURE";

export const UPDATE_COUPONS_REQUEST = "UPDATE_COUPONS_REQUEST";
export const UPDATE_COUPONS_SUCCESS = "UPDATE_COUPONS_SUCCESS";
export const UPDATE_COUPONS_FAILURE = "UPDATE_COUPONS_FAILURE";

export const DELETE_COUPONS_REQUEST = "DELETE_COUPONS_REQUEST";
export const DELETE_COUPONS_SUCCESS = "DELETE_COUPONS_SUCCESS";
export const DELETE_COUPONS_FAILURE = "DELETE_COUPONS_FAILURE";

export const OPEN_ADDCOUPON_DIALOG = "OPEN_ADDCOUPON_DIALOG";
export const OPEN_DELETECOUPON_CONFIRMATION_DIALOG = "OPEN_DELETECOUPON_CONFIRMATION_DIALOG";

/* Auctions */

export const GET_ALL_AUCTION_REQUEST = "GET_ALL_AUCTION_REQUEST";
export const GET_ALL_AUCTION_SUCCESS = "GET_ALL_AUCTION_SUCCESS";
export const GET_ALL_AUCTION_FAILURE = "GET_ALL_AUCTION_FAILURE";

export const ADD_AUCTION_REQUEST = "ADD_AUCTION_REQUEST";
export const ADD_AUCTION_SUCCESS = "ADD_AUCTION_SUCCESS";
export const ADD_AUCTION_FAILURE = "ADD_AUCTION_FAILURE";

export const UPDATE_AUCTION_REQUEST = "UPDATE_AUCTION_REQUEST";
export const UPDATE_AUCTION_SUCCESS = "UPDATE_AUCTION_SUCCESS";
export const UPDATE_AUCTION_FAILURE = "UPDATE_AUCTION_FAILURE";

export const DELETE_AUCTION_REQUEST = "DELETE_AUCTION_REQUEST";
export const DELETE_AUCTION_SUCCESS = "DELETE_AUCTION_SUCCESS";
export const DELETE_AUCTION_FAILURE = "DELETE_AUCTION_FAILURE";

/* auction lot */
export const GET_ALL_AUCTIONLOT_REQUEST = "GET_ALL_AUCTIONLOT_REQUEST";
export const GET_ALL_AUCTIONLOT_SUCCESS = "GET_ALL_AUCTIONLOT_SUCCESS";
export const GET_ALL_AUCTIONLOT_FAILURE = "GET_ALL_AUCTIONLOT_FAILURE";

export const ADD_AUCTIONLOT_REQUEST = "ADD_AUCTIONLOT_REQUEST";
export const ADD_AUCTIONLOT_SUCCESS = "ADD_AUCTIONLOT_SUCCESS";
export const ADD_AUCTIONLOT_FAILURE = "ADD_AUCTIONLOT_FAILURE";

export const UPDATE_AUCTIONLOT_REQUEST = "UPDATE_AUCTIONLOT_REQUEST";
export const UPDATE_AUCTIONLOT_SUCCESS = "UPDATE_AUCTIONLOT_SUCCESS";
export const UPDATE_AUCTIONLOT_FAILURE = "UPDATE_AUCTIONLOT_FAILURE";

export const DELETE_AUCTIONLOT_REQUEST = "DELETE_AUCTIONLOT_REQUEST";
export const DELETE_AUCTIONLOT_SUCCESS = "DELETE_AUCTIONLOT_SUCCESS";
export const DELETE_AUCTIONLOT_FAILURE = "DELETE_AUCTIONLOT_FAILURE";