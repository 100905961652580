import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import { DialogTitle, DialogContent, CircularProgress, Slide } from "@material-ui/core";

// Redux
import { connect } from "react-redux";
// SCSS
import "./ListRuleDialog.scss";
import { get, isEmpty } from "lodash";
import { deleteCategoryRulesAction, getCategoryRulesAction, toggleAddRuleDialog } from "../../../redux/actions/CategoryAction";
import ConfirmationMessageDialog from "../../../components/ConfirmationMessageDialog/ConfirmationMessageDialog";
import AddRuleDialog from "../AddRuleDialog/AddRuleDialog";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
class ListRuleDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ruleId: "",
            deleteconfirmationDialog: false,
            selectedRecordToPerformAction: null,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevProps.open && this.props.open) {
            const updateData = this.props.updateData;
            this.props.getCategoryRulesAction(updateData._id);
        }
    }

    render() {
        const { addRuleDialog, getRuleLoader, categoryRulesList } = this.props.categoryReducer;
        const rulesList = get(categoryRulesList, "categoryRules");

        return (
            <Dialog
                fullScreen
                open={this.props.open}
                onClose={this.props.handleClose}
                TransitionComponent={Transition}
                BackdropProps={{ classes: { root: "dialog-backdrop" } }}
                className="list-rule-tax-dialog"
            >
                <img className="close-btn" onClick={this.props.handleClose} src={require("../../../assets/images/common/Close_h.svg")} alt="" />
                <DialogTitle className="dialog-title">Rules</DialogTitle>
                <DialogContent className="list-rule-tax-content">
                    {rulesList && rulesList.length !== 0 ? (
                        <div className="table-wrapper">
                            <div className="head-wrapper">
                                <span className="head-item">Property Name</span>
                                <span className="head-item">Property Value</span>
                                <span className="head-item">Action</span>
                            </div>
                            <div className="main-content-wrapper">
                                <div className="table-wrapper">
                                    {rulesList.map((item, index) => (
                                        <div key={index} className="content-wrapper">
                                            <span className="content-item">{item.proertyName}</span>
                                            <span className="content-item">{item.value}</span>
                                        </div>
                                    ))}
                                </div>
                                <div className="action-wrapper" >
                                    <div className="action-span" onClick={this.toggleAddRuleDialog.bind(this, true, categoryRulesList)}>
                                        <img src={require("../../../assets/images/common/Edit_New.svg")} alt="edit" />
                                    </div>
                                    <span className="action-span" onClick={this.toggleDeleteConfirmationDialog.bind(this)}>
                                        <img src={require("../../../assets/images/common/delete.svg")} alt="delete" />
                                    </span>
                                </div>
                            </div>
                        </div>
                    ) : getRuleLoader ? (
                        <CircularProgress className="circular-loader" size={30} />
                    ) : (
                        <div className="empty-table">No Rules Found.</div>
                    )}
                    <br />
                    <br />
                    <hr />
                    {isEmpty(categoryRulesList) &&
                        <div className="dialog-sub-title">
                            <button className="add-filter-button" onClick={this.toggleAddRuleDialog.bind(this, true, null)}>
                                Add
						</button>
                        </div>
                    }
                </DialogContent>
                <AddRuleDialog
                    open={addRuleDialog}
                    catgoryData={this.props.updateData}
                    updateData={this.state.selectedRecordToPerformAction}
                    handleClose={this.toggleAddRuleDialog.bind(this, false, null)}
                />
                <ConfirmationMessageDialog
                    open={this.state.deleteconfirmationDialog}
                    handleClose={this.toggleDeleteConfirmationDialog.bind(this)}
                    confirmMessage={"Are you sure you want to delete this rule?"}
                    onConfirm={this.onDeleteRule.bind(this)}
                />
            </Dialog>
        );
    }



    // ====================== CREATE Rule [TOGGLE] ====================== //
    async toggleAddRuleDialog(value, item) {
        // Toggle dialog
        this.setState({
            selectedRecordToPerformAction: item,
        }, async () => {
            // Toggle dialog
            await this.props.toggleAddRuleDialog(value);
        })
    }

    //-------------Dialog for delete rule---------------------//
    toggleDeleteConfirmationDialog() {
        const { categoryRulesList } = this.props.categoryReducer;
        this.setState(
            {
                deleteconfirmationDialog: !this.state.deleteconfirmationDialog
            },
            () => {
                this.setState({
                    ruleId: categoryRulesList._id
                });
            }
        );
    }

    //-------------Delete Rule Confirm---------------------//
    async onDeleteRule() {
        let { _id } = this.props.updateData;
        await this.props.deleteCategoryRulesAction(this.state.ruleId, _id);
        await this.handleReset();
    }


    handleReset() {
        this.setState({
            ...this.state,
            ruleId: "",
            deleteconfirmationDialog: false

        });
    }

}

const mapStateToProps = state => {
    return {
        categoryReducer: state.categoryReducer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getCategoryRulesAction: (id) => {
            dispatch(getCategoryRulesAction(id));
        },
        deleteCategoryRulesAction: (ruleId, subcategoryId) => {
            dispatch(deleteCategoryRulesAction(ruleId, subcategoryId));
        },
        toggleAddRuleDialog: value => {
            dispatch(toggleAddRuleDialog(value));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListRuleDialog);
