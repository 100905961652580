import propertiesList from "../properties.json";

let dashboardUrl = "/eCommerce/api/Reports/";
let categoryUrl = "CategoryManagement/api/CategoryManagement/";
let homeUrl = "eCommerce/api/Home/";
let collectionUrl = "eCommerce/api/Collection/";
let artistsUrl = "UserManagment/api/Artists/";
let categoryPropertyUrl = "CategoryManagement/api/CategoryManagement/Property/";
let authenticationUrl = "AuthServer/api/auth/";
let userManagementUrl = "UserManagment/api/UserManagement/";
let productManagementUrl = "ProductManagement/api/ProductManagement/";
let orderManagementurl = "OrderManagement/api/OrderManagement/";
let productsBrandUrl = "ProductManagement/api/Brands/";
let shippingManagementurl = "OrderManagement/api/Shipping/";
let artistManagementUrl = "UserManagment/api/Artists/";
let galleryManagementUrl = "UserManagment/api/Gallery/";
let blogManagmentUrl = "eCommerce/api/Blogs";
let newsManagementUrl = "eCommerce/api/news";
let paymentUrl = "OrderManagement/api/Payment/";
let taxManagementurl = "OrderManagement/api/Tax/";
let shipmentManagementurl = "OrderManagement/api/Shipment/";
let gerneralUrl = "eCommerce/api/General/";
let bulkUploadUrl = "ProductManagement/api/ProductBulkUpload/";
let LookUpUrl = "eCommerce/api/Lookup/";
let blockChainUrl = "/eCommerce/api/Blockchain/";
let couponUrl = "OrderManagement/api/Coupon";
let auctionUrl = "OrderManagement/api/Auction";
let auctionLotUrl = "OrderManagement/api/AuctionLot";
let auctionBiddingHistory = "OrderManagement/api/Bid/List";
let AbandonedCartUrl = "OrderManagement/api/Cart/";
let SurveyUrl = "eCommerce/api/Survey/";
let AnalyticsUrl = "eCommerce/api/Analytics/";
let ReelsVideoBaseUrl = "eCommerce/api/Reels";

export class APIUrlConstants {
	static BASE_WEB_URL = propertiesList.apiWebServer;

	static API_URLS = {
		login: userManagementUrl + "Login",
		validate2FAOTP: userManagementUrl + "Validate2FAOTP",

		// Forgot Password
		sendOtp: authenticationUrl + "SendOtpToEmail",
		validateOtp: authenticationUrl + "ValidateOTP",
		resetPassword: authenticationUrl + "ResetPassword",

		//Dashboard
		getDashboardDetails: dashboardUrl + "GetAdminDashboard",

		// Categories
		getCategory: categoryUrl + "GetCategoryListAdmin",
		getProperty: categoryUrl + "Property/GetCategoryProperties?CategoryId=",
		createCategory: categoryUrl + "CreateCategory",
		deleteCategory: categoryUrl + "DeleteCategory?",
		updateCategory: categoryUrl + "EditCategory",
		createProperty: categoryUrl + "Property/CreateProperty",
		updateProperty: categoryUrl + "Property/UpdateProperty/",
		deleteProperty: categoryUrl + "Property/DeleteCategoryProperty?CategoryPropertyId=",
		createCategoryRules: categoryUrl + "Property/CreateCategoryRules",
		getCategoryRules: categoryUrl + "Property/CategoryRules?SubCategoryId=",
		updateCategoryRules: categoryUrl + "Property/UpdateCategoryRules/",
		deleteCategoryRules: categoryUrl + "Property/DeleteCategoryRule?CategoryRuleId=",

		// Manage Home
		getHomeScreenBannerList: homeUrl + "GetHomeScreenBanner",
		createHomeScreenBanner: homeUrl + "AddHomeScreenBanner",
		updateHomeScreenBanner: homeUrl + "UpdateHomeScreenBanner?BannerId=",
		deleteHomeScreenBanner: homeUrl + "DeleteHomeScreenBanner?BannerId=",
		getParentsByCategoryId: categoryUrl + "getParantsByCategoryId?categoryId=",

		// Manage Artworks
		getArtworksList: productManagementUrl + "ArtworkList",
		updateProductStatus: productManagementUrl + "UpdateProductStatus",
		deleteProduct: productManagementUrl + "DeleteProduct?stockId={?}",
		unHoldProduct: productManagementUrl + "UnHoldArtwork",
		adminDownloadProduct: productManagementUrl + "AdminDownloadProduct",
		featureArtworks: productManagementUrl + "FeatureArtworks",
		updateMetaDataStatus: productManagementUrl + "ArtworkEditStatus",
		submitArtworktoBlockchain: blockChainUrl + "SubmitArtworktoBlockchain",
		modifyArtworkState: productManagementUrl + "ModifyArtworkState",
		getArtworkStockListByArtworkId: productManagementUrl + "StockList",
		blockArtworkOriginal: productManagementUrl + "BlockArtwork",

		//Manage Sellers
		getSellersList: userManagementUrl + "SearchSeller",
		getSellerDetails: userManagementUrl + "getSellerInfo?userid={?}",
		getProductList: productManagementUrl + "SearchProduct",
		blockSeller: userManagementUrl + "LockUser?userId={?}",
		unblockSeller: userManagementUrl + "UnlockUser?userId={?}",

		//Manage Users
		getUsersList: userManagementUrl + "SearchUser",
		getOrderHistory: orderManagementurl + "GetUserOrderHistory",
		getUserDetails: userManagementUrl + "getBuyerInfo?userid={?}",
		blockUser: userManagementUrl + "BlockUser?UserId={?}",
		unblockUser: userManagementUrl + "UnlockSellerprofile?UserId={?}",
		getShipAddress: shippingManagementurl + "GetUserShippingAdd?userid={?}",
		getRoleChangeRequests: userManagementUrl + "FilterRoleChangeRequests",
		updateRoleChangeStatus: userManagementUrl + "UpdateRoleChangeStatus",
		blockArtist: userManagementUrl + "SuspendSeller",
		exportToExcelUserList: userManagementUrl + "DownloadUser",

		//Manage Artists
		getArtistsList: artistManagementUrl + "FilterArtistsAdmin",
		setArtistFeatured: artistManagementUrl + "FeatureArtist",
		getArtistById: artistManagementUrl + "GetArtistProfileById",
		downloadArtistList: artistManagementUrl + "DownloadArtistLit",
		updateArtistProfileByAdmin: artistManagementUrl + "UpdateArtistProfileByAdmin",
		updateArtistRank: artistManagementUrl + "ArtistPopularity",
		getArtistInterests: artistManagementUrl + "GetArtistInterestsByUser",
		getArtistTypes: artistManagementUrl + "GetArtistTypes",
		getCategoryList: categoryUrl + "GetCategoryList",

		//Manage Gallery
		getGalleryList: galleryManagementUrl + "List",
		setGalleryFeatured: galleryManagementUrl + "FeatureGallery",
		getGalleryById: galleryManagementUrl + "GetGalleryProfileById",
		getOwnedProducts: productManagementUrl + "GetOwnedProducts",
		getGalleryListedInventory: productManagementUrl + "GetGalleryListedInventory",
		//Collections
		getSelectedCollections: collectionUrl + "GetAdminCollections",
		getCollectionById: collectionUrl + "GetCollectionById",
		deleteCollection: collectionUrl + "deleteCollection",
		getArtists: artistsUrl + "GetArtists",
		getArtistsNames: artistsUrl + "GetArtistsNames",
		getCategoryProperty: categoryPropertyUrl + "GetCategoryProperties",
		searchArtwork: productManagementUrl + "SearchProductAdmin",
		addCollection: collectionUrl + "AddCollection",
		deleteItemFromCollection: collectionUrl + "DeleteItemFromCollection",
		updateCollection: collectionUrl + "UpdateCollection",
		updateCollectionIsFeatured: collectionUrl + "ChangeFeaturedCollection",
		//Orders
		getOrderList: orderManagementurl + "GetAllPlacedOrders",
		getOrderDetails: orderManagementurl + "GetOrderDetails_V2",
		updateOrderStatus: orderManagementurl + "UpdateOrderStatus",
		getOrderInvoice: dashboardUrl + "GetInvoice_V3",
		amountRefund: paymentUrl + "CancelPayment",
		getWaybillNumber: dashboardUrl + "GetWaybillNumber",
		pickupDetails: orderManagementurl + "PickupDetails",

		//Notifications
		getNotifications: userManagementUrl + "getNotifications",

		// Add Brands
		getCategoryDetails: categoryUrl + "GetCategoryById?categoryId=",
		getBrands: productsBrandUrl + "GetBrand?categoryId={?}&Status={?}&brandPriority={?}&BrandName={?}",
		createBrand: productsBrandUrl + "AddBrand",
		deleteBrand: productsBrandUrl + "DeleteBrand/",
		updateBrand: productsBrandUrl + "UpdateBrand/",

		//Blog URL
		getBlog: blogManagmentUrl + "/GetBlogs",
		updateBlog: blogManagmentUrl + "/UpdateBlogs",
		deleteBlog: blogManagmentUrl + "/DeleteBlogs",
		addBlog: blogManagmentUrl + "/AddBlogs",
		createImageGallery: gerneralUrl + "CreateImageGallery",
		authorSearch: blogManagmentUrl + "/AuthorSearch",

		addBlogTopic: blogManagmentUrl + "/AddBlogTopic",
		updateBlogTopic: blogManagmentUrl + "/UpdateBlogTopic",
		deleteBlogTopic: blogManagmentUrl + "/DeleteBlogTopic",
		getBlogTopics: blogManagmentUrl + "/GetBlogTopics",

		addBlogBanner: blogManagmentUrl + "/AddBlogBanner",
		updateBlogBanner: blogManagmentUrl + "/UpdateBlogBanner",
		deleteBlogBanner: blogManagmentUrl + "/DeleteBlogBanner",
		getBlogBanner: blogManagmentUrl + "/GetBlogBanners",

		//News URL
		getNews: newsManagementUrl + "/GetNews",
		updateNews: newsManagementUrl + "/UpdateNews",
		deleteNews: newsManagementUrl + "/DeleteNews",
		addNews: newsManagementUrl + "/AddNews",

		// Edit Product
		getProductBySlugId: productManagementUrl + "GetProductBySlug",
		updateProduct: productManagementUrl + "UpdateProduct",
		uploadProductImages: productManagementUrl + "UpdateProductImages",

		//Countries & taxes
		getCountriesList: userManagementUrl + "CountryList",
		addTax: taxManagementurl + "AddTaxData",
		getAllTaxList: taxManagementurl + "GetTaxList",
		updateTax: taxManagementurl + "UpdateTaxData",
		deleteTax: taxManagementurl + "DeleteTax",

		//Quotes
		filterQuotes: productManagementUrl + "FIlterQuotes",
		updateQuotePriceStatusByAdmin: productManagementUrl + "UpdateQuotePriceStatusByAdmin",
		downloadDisplayPicture: productManagementUrl + "DownloadProductImage?productId=",

		//Original Artworks
		getOriginalArtworks: productManagementUrl + "GetOriginalArtworks",

		//Shipment Provider
		addShipmentProvider: shipmentManagementurl + "AddShipmentProvider",
		getAllShipmentProviders: shipmentManagementurl + "GetAllShipmentProviders",
		updateShipmentProvider: shipmentManagementurl + "UpdateShipmentProvider",
		deleteShipmentProvider: shipmentManagementurl + "DeleteShipmentProvider",

		//Shipping Charges
		addShippingCharge: shipmentManagementurl + "AddShippingCharge",
		getAllShippingCharges: shipmentManagementurl + "GetAllShippingCharges",
		updateShippingCharge: shipmentManagementurl + "UpdateShippingCharge",
		deleteShippingCharge: shipmentManagementurl + "DeleteShippingCharge",

		//KYC DOC
		getKYC: userManagementUrl + "getKYC",
		approveKyc: userManagementUrl + "Approvekyc",
		adminDownloadKYC: userManagementUrl + "AdminDownloadKYC",

		//Lookup
		getAllLookUp: productManagementUrl + "ArtworkPropertyValues",
		insertSubject: productManagementUrl + "Subject",
		insertStyle: productManagementUrl + "Style",
		insertMaterials: productManagementUrl + "Material",
		insertMedium: productManagementUrl + "Medium",
		lookUpCollectionCategory: LookUpUrl + "CollectionCategory",
		updateSubject: productManagementUrl + "Subject",
		updateStyle: productManagementUrl + "Style",
		updateMaterials: productManagementUrl + "Material",
		updateMedium: productManagementUrl + "Medium",
		deleteSubject: productManagementUrl + "Subject",
		deleteStyle: productManagementUrl + "Style",
		deleteMaterials: productManagementUrl + "Material",
		deleteMedium: productManagementUrl + "Medium",

		//Filter Rules
		filterPreset: productManagementUrl + "filterPreset",
		getFilterRuleById: productManagementUrl + "filterPresetById",

		//Bulk
		bulkUpload: bulkUploadUrl + "ReadFromExcel",

		//Coupon
		getAllCoupons: couponUrl + "/List",
		addCoupon: couponUrl,
		updateCoupon: couponUrl,
		deleteCoupon: couponUrl,

		//Auction
		getAuctionList: auctionUrl + "/AdminList",
		auction: auctionUrl,

		//Auction lot
		auctionLotList: auctionLotUrl + "/List",
		auctionLot: auctionLotUrl,
		auctionBiddingHistory: auctionBiddingHistory,
		auctionCreate: auctionLotUrl + "/CreateMultiple",
		getAuctionLotIds: auctionLotUrl + "/ListIds",

		smartContractUrl: blockChainUrl + "GetSmartContracts",
		createSmartContract: blockChainUrl + "CreateSmartContract",

		abandonedCarts: AbandonedCartUrl + "AbandonedCart",
		downLoadAbandonedCarts: AbandonedCartUrl + "DownloadCart",

		keyWordSearches: dashboardUrl + "KeywordSearches",
		downLoadSearchHistory: dashboardUrl + "DownloadSearchHistory",

		downloadBulkuploadFormUrl: bulkUploadUrl + "/Template",

		//Survey Form
		addSurveyQuestion: SurveyUrl + "AddQuestion",
		deleteSurveyQuestion: SurveyUrl + "DeleteQuestion",
		addSurvey: SurveyUrl + "AddSurvey",
		getAllSurveys: SurveyUrl + "GetSurveys",
		deleteSurvey: SurveyUrl + "DeleteSurvey",
		getSurveyById: SurveyUrl + "GetSurveyById",
		getSurveyAnswer: SurveyUrl + "GetUserAnswers",

		//Analytics
		getAnalytics: AnalyticsUrl + "GetPageViews",

		reelsVideos: ReelsVideoBaseUrl,
		getReelsVideos: ReelsVideoBaseUrl + "/GetAdminReels",
		searchProductV2: productManagementUrl + "SearchProductV2",

		onMarkNFTRedeemable: productManagementUrl + "MarkNFTRedeemable",
		onApproveNFTRedeemable: productManagementUrl + "RedeemNFT",
	};

	static getApiUrl(key) {
		return this.BASE_WEB_URL + this.API_URLS[key];
	}
}
