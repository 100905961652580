import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  TOGGLE_LOGOUT_DIALOG,
  TWO_FACTOR_REQUEST,
  TWO_FACTOR_SUCCESS,
  TWO_FACTOR_FAILURE
} from "./ActionsTypes";
import { LoginService } from "../../containers/Login/Login.service";
import { Util } from "../../util/util";
import { popToast } from "../../components/Toast/Toast";

// -------------- Login -------------- //
export const login = credentials => {
  return dispatch => {
    dispatch(request());
    LoginService.onLogin(credentials).then(
      response => {
        if (response && response.result) {
          if (response.result === 200) {
            // Check if user is admin -> Only admin can bypass login
            if (
              response.data &&
              response.data.roles &&
              response.data.roles.indexOf("ADMIN") > -1
            ) {
              Util.setLoginResponse(response);
              dispatch(success(response));
            }
            else if (response.data && response.data.twoFactorEnabled) {
              window.location.href = "/#/two-factor/" + credentials.UserName;

            }

            // If not admin , Show toast
            else {
              dispatch(failure(response));
              popToast.show("Invalid admin credentials. Please try again!");
            }
          } else {
            dispatch(failure(response));
            let errorMessage =
              "Login unsuccessful. Verify your credentials and try again!";
            popToast.show((response && response.message) || errorMessage);
          }
        }
      },
      error => {
        if (error && error.status_message) {
          popToast.show(error.status_message);
        } else {
          popToast.show(
            "Login unsuccessful. Verify your credentials and try again!"
          );
        }
        console.log("error dispatched for API error!", error);
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: LOGIN_REQUEST };
  }
  function success(data) {
    return { type: LOGIN_SUCCESS, data };
  }
  function failure(error) {
    return { type: LOGIN_FAILURE, error };
  }
};

// -------------- Two factor authentication -------------- //
export const validate2FAOTPAction = credentials => {
  return dispatch => {
    dispatch(request());
    LoginService.validate2FAOTP(credentials).then(
      response => {
        if (response && response.result) {
          if (response.result === 200) {
            // Check if user is admin -> Only admin can bypass login
            if (
              response.data &&
              response.data.roles &&
              response.data.roles.indexOf("ADMIN") > -1
            ) {
              Util.setLoginResponse(response);
              dispatch(success(response));
            }
            // If not admin , Show toast
            else {
              dispatch(failure(response));
              popToast.show("Invalid admin credentials. Please try again!");
            }
          } else {
            dispatch(failure(response));
            let errorMessage =
              "Login unsuccessful. Verify your credentials and try again!";
            popToast.show((response && response.message) || errorMessage);
          }
        }
      },
      error => {
        if (error && error.status_message) {
          popToast.show(error.status_message);
        } else {
          popToast.show(
            "Login unsuccessful. Verify your credentials and try again!"
          );
        }
        console.log("error dispatched for API error!", error);
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: TWO_FACTOR_REQUEST };
  }
  function success(data) {
    return { type: TWO_FACTOR_SUCCESS, data };
  }
  function failure(error) {
    return { type: TWO_FACTOR_FAILURE, error };
  }
};

// -------------- Logout -------------- //
export const toggleLogoutDialog = (value = false) => {
  return dispatch => {
    dispatch({ type: TOGGLE_LOGOUT_DIALOG, value });
  };
};

export const logout = () => {
  Util.removeAuthToken();
  window.location.href = "#/login";
  return {
    type: LOGOUT
  };
};
