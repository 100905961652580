import styled from "styled-components";

export const YearEventTableWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding-top: 1rem;
`;

export const YearEventTableHead = styled.ul`
	display: flex;
	list-style-type: none;
	border-bottom: 1px solid #efefef;
	margin-left: 12%;
	margin-right: 12%;
	margin-bottom: 0;
	padding: 7px 0 7px 40px;

	@media (max-width: 576px) {
		margin-left: 10%;
		margin-right: 10%;
		padding-left: 0;
	}
`;

export const YearEventTableHeadItem = styled.li`
	color: #000000;
	font-size: 14px;
	font-weight: 500;
	margin-left: ${props => {
		return props.marginleft;
	}};
`;

export const YearEventTableBody = styled.ul`
	display: flex;
	list-style-type: none;
	margin-left: 12%;
	margin-right: 12%;
	position: relative;
	min-height: 40px;
	margin-bottom: 0;
	padding: 7px 0 7px 40px;
	cursor: pointer;
	align-items: center;
	&:hover {
		background-color: #efefef;
	}

	@media (max-width: 576px) {
		margin-left: 10%;
		margin-right: 10%;
		padding-left: 0;
	}
`;

export const YearEventTableBodyItem = styled.li`
	color: #727272;
	font-size: 14px;
	text-align: justify;
	margin-left: ${props => {
		return props.marginleft;
	}};
	margin-right: ${props => {
		return props.marginright;
	}};
`;

export const YearEventTableActionPill = styled.div`
	position: absolute;
	right: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #ffffff;
	z-index: 1;
	width: 73px;
	padding: 5px 10px;
	box-shadow: 0px 2px 4px 1px #d9d9d9;
	border-radius: 6rem;
	cursor: pointer;
	&:hover {
		box-shadow: 0px 2px 4px 1px #bfbfbf;
	}
	img {
		width: 17px;
	}
`;
