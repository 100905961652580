import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
// Redux
import { Provider } from 'react-redux';
import store from './redux/store/store';
import { localeSet } from './redux/actions/ActionLocale';
// Files
import { Util } from './util/util';

// To check if localstorage has any previously saved language value
// Else take the language from browser language settings
if (Util.getLanguage()) {
    store.dispatch(localeSet(Util.getLanguage()));
}
else {
    const browserLanguage = navigator.language.split(/[-_]/)[0];
    store.dispatch(localeSet(browserLanguage));
}

// Store is loaded
// Provider makes the redux store available to any nested components
// that have been wrapped in the connect()
// You cant use a connected component unless it is nested inside a <Provider>

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
);

serviceWorker.unregister();
