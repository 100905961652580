import {
    GET_GALLERYLIST_REQUEST,
    GET_GALLERYLIST_SUCCESS,
    GET_GALLERYLIST_FAILURE,
    GET_GALLERY_PROFILE_REQUEST,
    GET_GALLERY_PROFILE_SUCCESS,
    GET_GALLERY_PROFILE_FAILURE,
    SET_GALLERY_FEATURED_REQUEST,
    SET_GALLERY_FEATURED_SUCCESS,
    SET_GALLERY_FEATURED_FAILURE
} from "./ActionsTypes";
import { ManageGalleryService } from "../../containers/ManageGallery/ManageGallery.service";
import { popToast } from "../../components/Toast/Toast";
import { UserProfileService } from "../../containers/ManageUsers/UserProfile/UserProfile.service";

export function getGalleryList(gallerydata, isSort) {
    return dispatch => {
        dispatch(request());
        ManageGalleryService.getGalleryList(gallerydata, isSort).then(
            response => {
                if (response && response.message === "Success") {
                    dispatch(success(response.data));
                } else if (response && response.message === "Failure") {
                    dispatch(failure(response));
                    popToast.show("Get gallery list Error : " + response.Message || "Something went wrong while loading gallery list!");
                }
            },
            error => {
                if (error && error.status_message) {
                    popToast.show(error.status_message);
                } else {
                    popToast.show("Something went wrong while loading gallery list!");
                }
                console.log("error dispatched for API error!", error);
                dispatch(failure(error));
            }
        );
    };

    function request() {
        return { type: GET_GALLERYLIST_REQUEST };
    }
    function success(data) {
        return { type: GET_GALLERYLIST_SUCCESS, data };
    }
    function failure(error) {
        return { type: GET_GALLERYLIST_FAILURE, error };
    }
}

export function getGalleryByIdAction(userId) {
    return dispatch => {
        dispatch(request());
        UserProfileService.getGalleryProfile(userId).then(
            response => {
                if (response && response.message === "Success") {
                    dispatch(success(response.data));
                } else if (response && response.message === "Failure") {
                    dispatch(failure(response));
                    popToast.show("Get artists list Error : " + response.Message || "Something went wrong while loading artists list!");
                }
            },
            error => {
                if (error && error.status_message) {
                    popToast.show(error.status_message);
                } else {
                    popToast.show("Something went wrong while loading artists list!");
                }
                console.log("error dispatched for API error!", error);
                dispatch(failure(error));
            }
        );
    };

    function request() {
        return { type: GET_GALLERY_PROFILE_REQUEST };
    }
    function success(data) {
        return { type: GET_GALLERY_PROFILE_SUCCESS, data };
    }
    function failure(error) {
        return { type: GET_GALLERY_PROFILE_FAILURE, error };
    }
}

export function setGalleryFeaturedAction(featureData, gallerydata, userData) {
    return dispatch => {
        dispatch(request());
        ManageGalleryService.setGalleryFeatured(featureData).then(
            response => {
                if (response && response.message === "Success") {
                    dispatch(success(response.data));
                    if (gallerydata) {
                        dispatch(getGalleryList(gallerydata));
                    } else {
                        dispatch(getGalleryByIdAction(userData));
                    }
                } else if (response && response.message === "Failure") {
                    dispatch(failure(response));
                    popToast.show(
                        "Set Gallery Featured/Unfeatured Error : " + response.Message || "Something went wrong while set gallery featured/unfeatured!"
                    );
                }
            },
            error => {
                if (error && error.status_message) {
                    popToast.show(error.status_message);
                } else {
                    popToast.show("Something went wrong while set gallery featured/unfeatured!");
                }
                console.log("error dispatched for API error!", error);
                dispatch(failure(error));
            }
        );
    };

    function request() {
        return { type: SET_GALLERY_FEATURED_REQUEST };
    }
    function success(data) {
        return { type: SET_GALLERY_FEATURED_SUCCESS, data };
    }
    function failure(error) {
        return { type: SET_GALLERY_FEATURED_FAILURE, error };
    }
}
