import * as types from './ActionsTypes';
import { ManageCouponService } from '../../containers/ManageCoupon/Coupon.service';
import { popToast } from '../../components/Toast/Toast';

export const getAllCoupons = () => {
    return dispatch => {
        dispatch(request(types.GET_ALL_COUPONS_REQUEST));
        ManageCouponService.getAllCoupons().then(
            response => dispatch(SuccessResponse(response, types.GET_ALL_COUPONS_SUCCESS, types.GET_ALL_COUPONS_FAILURE, "Something went wrong while getting coupons!")),
            error => dispatch(ErrorResponse(error, types.GET_ALL_COUPONS_FAILURE, "Something went wrong while getting coupons!"))
        )
    }
}

export const addNewCoupon = (couponObject) => {
    return dispatch => {
        dispatch(request(types.ADD_COUPONS_REQUEST));
        ManageCouponService.addNewCoupons(couponObject).then(
            response => dispatch(SuccessResponse(response, types.ADD_COUPONS_SUCCESS, types.ADD_COUPONS_FAILURE, "Something went wrong while adding coupon!", "New coupon added. ", getAllCoupons)),
            error => dispatch(ErrorResponse(error, types.ADD_COUPONS_FAILURE, "Something went wrong while adding coupon!"))
        )
    }
}

export const updateCoupon = (couponObject, couponId) => {
    return dispatch => {
        dispatch(request(types.UPDATE_COUPONS_REQUEST));
        ManageCouponService.updateCoupons(couponObject, couponId).then(
            response => dispatch(SuccessResponse(response, types.UPDATE_COUPONS_SUCCESS, types.UPDATE_COUPONS_FAILURE, "Something went wrong while updating coupon!", "Coupon updated. ", getAllCoupons)),
            error => dispatch(ErrorResponse(error, types.UPDATE_COUPONS_FAILURE, "Something went wrong while updating coupon!"))
        )
    }
}

export const deleteCoupon = (couponId) => {
    return dispatch => {
        dispatch(request(types.DELETE_COUPONS_REQUEST));
        ManageCouponService.deleteCoupons(couponId).then(
            response => dispatch(SuccessResponse(response, types.DELETE_COUPONS_SUCCESS, types.DELETE_COUPONS_FAILURE, "Something went wrong while deleting coupon!", "Coupon deleted. ", getAllCoupons)),
            error => dispatch(ErrorResponse(error, types.DELETE_COUPONS_FAILURE, "Something went wrong while deleting coupon!"))
        )
    }
}

export const toggleOpenAddCoupenDailoge = (value) => {
    return dispatch => {
        dispatch(success(types.OPEN_ADDCOUPON_DIALOG, value))
    }
}

export const toggleDeleteCoupenConfirmationDailoge = (value) => {
    return dispatch => {
        dispatch(success(types.OPEN_DELETECOUPON_CONFIRMATION_DIALOG, value))
    }
}

/* Success and Error response */
export const SuccessResponse = (response, success_type, failure_type, errorMessage, success_message = "", callbackfn = undefined) => {
    return dispatch => {
        if (response && response.result === 200) {
            dispatch(success(success_type, response.data));
            if (callbackfn && typeof callbackfn === "function") {
                dispatch(callbackfn())
            }
            if (success_message !== "") {
                popToast.show(success_message);
            }
        } else {
            dispatch(failure(failure_type, response));

            if (response && response.message) {
                switch (response.message) {
                    default:
                        errorMessage = response.message;
                        break;
                }
            }
            popToast.show(errorMessage);
        }
    }
}

export const ErrorResponse = (error, type, message) => {
    return dispatch => {
        if (error && error.status_message) {
            popToast.show(error.status_message);
        } else {
            popToast.show(message);
        }
        console.log(message, error);
        dispatch(failure(type, error));
    }
}

function request(type) {
    return { type };
}
function success(type, data) {
    return { type, data };
}
function failure(type, error) {
    return { type, error };
}

/* Success and Error response */