import {
    GET_SELLERSLIST_SUCCESS,
    GET_SELLERSLIST_FAILURE,
    GET_SELLERSLIST_REQUEST,
    GET_PRODUCT_LIST_REQUEST,
    GET_PRODUCT_LIST_SUCCESS,
    GET_PRODUCT_LIST_FAILURE,
    BLOCK_SELLER_REQUEST,
    BLOCK_SELLER_SUCCESS,
    BLOCK_SELLER_FAILURE,
    UNBLOCK_SELLER_REQUEST,
    UNBLOCK_SELLER_SUCCESS,
    UNBLOCK_SELLER_FAILURE,
    GET_SELLER_DETAILS_REQUEST,
    GET_SELLER_DETAILS_SUCCESS,
    GET_SELLER_DETAILS_FAILURE,
    SELLER_PROFILE_DETAILS,
    SET_MANAGESELLER_REDUCER_STATES,
    GET_OWNED_PRODUCTS_REQUEST,
    GET_OWNED_PRODUCTS_SUCCESS,
    GET_OWNED_PRODUCTS_FAILURE,
    GET_GALLERY_LISTED_INVENTORY_REQUEST,
    GET_GALLERY_LISTED_INVENTORY_SUCCESS,
    GET_GALLERY_LISTED_INVENTORY_FAILURE
} from "../actions/ActionsTypes";


const initialState = {
    savedSellersListCount: 0,
    savedSellersList: [],
    sellersListLoader: false,
    productListLoader: false,
    savedProductsList: [],
    blockSellerLoader: false,
    blockSellerResult: {},
    unblockSellerLoader: false,
    unblockSellerResult: {},
    sellerdetailsLoader: false,
    sellerDetails: {},
    getOwnedProduct: [],
    getOwnedProductLoader: false,
    getGalleryListedInventoryLoader: false,
    getGalleryListInventory: [],
}

const manageSellersReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case GET_SELLERSLIST_REQUEST:
            return {
                ...state,
                sellersListLoader: true,
                savedSellersList: []
            };

        case GET_SELLERSLIST_SUCCESS:
            state = {
                ...state,
                sellersListLoader: false,
                savedSellersListCount: action.data.count,
                savedSellersList: action.data.sellerList
            };
            return state;

        case GET_SELLERSLIST_FAILURE:
            return {
                ...state,
                savedSellersListCount: 0,
                sellersListLoader: false,
                savedSellersList: []
            };

        case GET_PRODUCT_LIST_REQUEST:
            return {
                ...state,
                productListLoader: true,
                savedProductsList: []
            };

        case GET_PRODUCT_LIST_SUCCESS:
            state = {
                ...state,
                productListLoader: false,
                savedProductsList: action.data || []
            };
            return state;

        case GET_PRODUCT_LIST_FAILURE:
            return {
                ...state,
                productListLoader: false,
                savedProductsList: []
            };

        case BLOCK_SELLER_REQUEST:
            return {
                ...state,
                blockSellerLoader: true
            };

        case BLOCK_SELLER_SUCCESS:
            state = {
                ...state,
                blockSellerLoader: false,
                blockSellerResult: action.data
            };
            return state;

        case BLOCK_SELLER_FAILURE:
            return {
                ...state,
                blockSellerLoader: false
            };

        case UNBLOCK_SELLER_REQUEST:
            return {
                ...state,
                unblockSellerLoader: true
            };

        case UNBLOCK_SELLER_SUCCESS:
            state = {
                ...state,
                unblockSellerLoader: false,
                unblockSellerResult: action.data
            };
            return state;

        case UNBLOCK_SELLER_FAILURE:
            return {
                ...state,
                unblockSellerLoader: false
            };

        case GET_SELLER_DETAILS_REQUEST:
            return {
                ...state,
                sellerdetailsLoader: true
            };

        case GET_SELLER_DETAILS_SUCCESS:
            state = {
                ...state,
                sellerdetailsLoader: false,
                sellerDetails: action.data.data
            };
            return state;

        case GET_SELLER_DETAILS_FAILURE:
            return {
                ...state,
                sellerdetailsLoader: false
            };
        case GET_OWNED_PRODUCTS_REQUEST:
            return {
                ...state,
                getOwnedProductLoader: true
            };

        case GET_OWNED_PRODUCTS_SUCCESS:
            state = {
                ...state,
                getOwnedProductLoader: false,
                getOwnedProduct: action.data.data
            };
            return state;

        case GET_GALLERY_LISTED_INVENTORY_REQUEST:
            return {
                ...state,
                getGalleryListedInventoryLoader: false
            };

        case GET_GALLERY_LISTED_INVENTORY_FAILURE:
            return {
                ...state,
                getOwnedProductLoader: false
            };

        case GET_GALLERY_LISTED_INVENTORY_SUCCESS:
            state = {
                ...state,
                getGalleryListedInventoryLoader: false,
                getGalleryListInventory: action.data.products
            };
            return state;

        case GET_OWNED_PRODUCTS_FAILURE:
            return {
                ...state,
                getOwnedProductLoader: false
            };

        case SELLER_PROFILE_DETAILS:
            state = {
                ...state,
                sellerDetails: action.data
            };
            return state;

        // ========== Access reducer states ========== //
        // Accessing reducer states through a single action
        case SET_MANAGESELLER_REDUCER_STATES:
            state = {
                ...state,
                [action.data.state]: action.data.value
            };
            return state;

        default:
            return state;
    }
}

export default manageSellersReducer;
