import {
    GET_DASHBOARD_DETAILS_REQUEST, GET_DASHBOARD_DETAILS_SUCCESS,
    GET_DASHBOARD_DETAILS_FAILURE
} from "../actions/ActionsTypes";


const initialState = {

    dashboardDetailsLoader: false,
    dashboardDetails: {}
}

const dashboardReducer = (state = initialState, action = {}) => {
    switch (action.type) {

        case GET_DASHBOARD_DETAILS_REQUEST:
            return {
                ...state,
                dashboardDetailsLoader: true
            }

        case GET_DASHBOARD_DETAILS_SUCCESS:
            state = {
                ...state,
                dashboardDetailsLoader: false,
                dashboardDetails: action.data.data
            }
            return state;

        case GET_DASHBOARD_DETAILS_FAILURE:
            return {
                ...state,
                dashboardDetailsLoader: false
            };

        default:
            return state;
    }
}

export default dashboardReducer;