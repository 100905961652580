import * as types from "./ActionsTypes";
import { reverse } from "lodash";
import { CategorySevice } from "../../containers/Categories/Categories.service";
import { popToast } from "../../components/Toast/Toast";
import { ManageHomeService } from "../../containers/ManageHome/ManageHome.service";

// ============================= Get All Categories ============================= //
// isNewListing value only passed when expanding a category to view its subcategories
export const getAllCategories = (parentId, isNewListing = false) => {
	return (dispatch, getState) => {
		dispatch(request());
		const preSelectedCategory = getState().categoryReducer.selectedCategoryId;
		CategorySevice.getCategory(parentId).then(
			response => {
				if (response && response.result === 200) {
					dispatch(success(response.data));

					// DEAD END ERROR HANDLING : There can be categories with no sub categories
					// So to prevent that error condition - check if there is sub cat and pass ID
					let categoryFirstItemID = (response.data && response.data.length > 0 && response.data[0]._id) || null;

					// PARENT ID [REFRESH]
					// Set parentId value as currenly opened category's ID (for breadcrumb)
					dispatch(setCategoryReducerStates("currentParentId", parentId));

					if (isNewListing) {
						dispatch(setCategoryReducerStates("selectedCategoryId", categoryFirstItemID));
						// dispatch(getCategoryProperties(categoryFirstItemID));
					} else {
						// CATEGORY ID [SELECTION]
						// IF pre selected ID is passed, reassign it,
						// ELSE Assign first data from response as selectedCategoryId (to preselect)
						dispatch(setCategoryReducerStates("selectedCategoryId", preSelectedCategory || categoryFirstItemID));

						// GET PROPERTIES [Currently selected category's]
						// Call all properties of selectedCategoryId (to preselect)
						// dispatch(getCategoryProperties(preSelectedCategory || categoryFirstItemID));
					}
				} else {
					dispatch(failure(response));

					let errorMessage = "Something went wrong while getting categories!";
					if (response && response.message) {
						switch (response.message) {
							default:
								errorMessage = "Something went wrong while getting categories! " + response.message;
								break;
						}
					}
					popToast.show(errorMessage);
				}
			},
			error => {
				if (error && error.status_message) {
					popToast.show(error.status_message);
				} else {
					popToast.show("Something went wrong while getting categories!");
				}
				console.log("error dispatched for API error!", error);
				dispatch(failure(error));
			}
		);
	};

	function request() {
		return { type: types.GET_CATEGORY_REQUEST };
	}
	function success(response) {
		return { type: types.GET_CATEGORY_SUCCESS, response };
	}
	function failure(error) {
		return { type: types.GET_CATEGORY_FAILURE, error };
	}
};

// ============================= Access Reducer states through this action ============================= //
export const setCategoryReducerStates = (stateName, value) => {
	return dispatch => {
		let data = {
			state: stateName,
			value: value
		};
		dispatch({ type: types.SET_CATEGORY_REDUCER_STATES, data });
	};
};

// ============================= Breadcrumb List ============================= //
export const setBreadcrumbs = (id, task) => {
	return (dispatch, getState) => {
		if (id) {
			ManageHomeService.getParentsByCategoryId(id).then(
				response => {
					if (response && response.message === "Success") {
						reverse(response.data);
						response.data = [{ index: 0, categoryName: "Categories" }, ...response.data];
						dispatch(success(response.data));
					} else if (response && response.message === "Failure") {
						dispatch(failure(response));
					}
				},
				error => {
					if (error && error.status_message) {
						popToast.show(error.status_message);
					} else {
						popToast.show("Something went wrong while loading breadcrumbs!");
					}
					console.log("error dispatched for API error!", error);
					dispatch(failure(error));
				}
			);
		} else dispatch(setCategoryReducerStates("breadCrumbList", [{ index: 0, categoryName: "Categories" }]));

		function success(data) {
			return { type: types.SET_BREADCRUMBS_SUCCESS, data };
		}
		function failure(error) {
			return { type: types.SET_BREADCRUMBS_FAILURE, error };
		}
	};
};

// ============================= Get Property List ============================= //
export const getCategoryProperties = parentId => {
	return dispatch => {
		dispatch(request());
		// Dead end check
		// parentId can be passed as null when there are no categories inside a subcategory
		if (parentId) {
			CategorySevice.getCategoryProperties(parentId).then(
				response => {
					if (response && response.result === 200) {
						dispatch(success(response.data));
					} else {
						dispatch(failure());

						let errorMessage = "Something went wrong while getting properties!";
						if (response && response.message) {
							switch (response.message) {
								default:
									errorMessage = "Something went wrong while getting properties! " + response.message;
									break;
							}
						}
						// popToast.show(errorMessage);
					}
				},
				error => {
					if (error && error.status_message) {
						popToast.show(error.status_message);
					} else {
						popToast.show("Something went wrong while getting properties!");
					}
					console.log("error dispatched for API error!", error);
					dispatch(failure(error));
				}
			);
		} else dispatch(failure());
	};

	function request() {
		return { type: types.GET_PROPERTY_REQUEST };
	}
	function success(data) {
		return { type: types.GET_PROPERTY_SUCCESS, data };
	}
	function failure(error) {
		return { type: types.GET_PROPERTY_FAILURE, error };
	}
};

// ============================= Toggle Add Category Dialog ============================= //
export const toggleAddCategoryDialog = data => ({
	type: types.TOGGLE_ADDCATEGORY_DIALOG,
	data
});

// ============================= Toggle Add Property Dialog ============================= //
export const toggleAddPropertyDialog = data => ({
	type: types.TOGGLE_ADDPROPERTY_DIALOG,
	data
});

// ============================= Create Category ============================= //
export const createCategory = (currentParentId, requestBody) => {
	return dispatch => {
		dispatch(request());
		CategorySevice.createCategory(requestBody).then(
			response => {
				if (response && response.result === 200) {
					// Success : Close add category modal
					dispatch(success(response.data));

					// Refresh the current list
					dispatch(getAllCategories(currentParentId));
					popToast.show("Category created successfully!");
				} else {
					dispatch(failure(response));

					let errorMessage = "Something went wrong while creating category!";
					if (response && response.message) {
						switch (response.message) {
							default:
								errorMessage = response.message;
								break;
						}
					}
					popToast.show(errorMessage);
				}
			},
			error => {
				if (error && error.status_message) {
					popToast.show(error.status_message);
				} else {
					popToast.show("Something went wrong while creating category!");
				}
				console.log("error dispatched for API error!", error);
				dispatch(failure(error));
			}
		);
	};

	function request() {
		return { type: types.CREATE_CATEGORY_REQUEST };
	}
	function success(data) {
		return { type: types.CREATE_CATEGORY_SUCCESS, data };
	}
	function failure(error) {
		return { type: types.CREATE_CATEGORY_FAILURE, error };
	}
};

// ============================= Create Property ============================= //
export const createCategoryProperty = (isUpdatingProperty, propertyId, currentParentId, requestBody) => {
	return dispatch => {
		dispatch(request());
		CategorySevice.createProperty(isUpdatingProperty, propertyId, requestBody).then(
			response => {
				if (response && response.result === 200) {
					// Success : Close create property dialog
					dispatch(success(response.data));

					// To refresh the list
					dispatch(getAllCategories(currentParentId));
				} else {
					dispatch(failure(response));

					let errorMessage = "Something went wrong while creating property!";
					if (response && response.message) {
						switch (response.message) {
							default:
								errorMessage = response.message;
								break;
						}
					}
					popToast.show(errorMessage);
				}
			},
			error => {
				if (error && error.status_message) {
					popToast.show(error.status_message);
				} else {
					popToast.show("Something went wrong while creating property!");
				}
				console.log("error dispatched for API error!", error);
				dispatch(failure(error));
			}
		);
	};

	function request() {
		return { type: types.CREATE_PROPERTY_REQUEST };
	}
	function success(data) {
		return { type: types.CREATE_PROPERTY_SUCCESS, data };
	}
	function failure(error) {
		return { type: types.CREATE_PROPERTY_FAILURE, error };
	}
};

// ============================= Toggle Delete Category Dialog ============================= //
export const toggleDeleteMainCategoryDialog = data => ({
	type: types.TOGGLE_DELETEMAINCATEGORY_DIALOG,
	data
});

// ============================= Delete Category Dialog ============================= //
export const deleteCategory = (currentParentId, isForceDelete, data) => {
	return (dispatch, getState) => {
		dispatch(request());
		CategorySevice.deleteCategory(isForceDelete, data).then(
			response => {
				if (response && response.result === 200) {
					// To close the delete modal
					// Clear the current selected category ID
					// Else get property will hit error
					dispatch(success(response));

					// To refresh the list
					dispatch(getAllCategories(currentParentId));

					popToast.show("Category deleted successfully!");
				} else {
					dispatch(failure(response));

					let errorMessage = "Something went wrong while deleting category!";
					if (response && response.message) {
						switch (response.message) {
							default:
								errorMessage = response.message;
								break;
						}
					}
					popToast.show(errorMessage);
				}
			},
			error => {
				if (error && error.status_message) {
					popToast.show(error.status_message);
				} else {
					popToast.show("Something went wrong while deleting category!");
				}
				console.log("error dispatched for API error!", error);
				dispatch(failure(error));
			}
		);
	};

	function request() {
		return { type: types.DELETE_CATEGORY_REQUEST };
	}
	function success(data) {
		return { type: types.DELETE_CATEGORY_SUCCESS, data };
	}
	function failure(error) {
		return { type: types.DELETE_CATEGORY_FAILURE, error };
	}
};

// ============================= Toggle Edit/View Category Dialog ============================= //
export const toggleEditViewDialog = data => ({
	type: types.TOGGLE_EDITVIEWCATEGORY_DIALOG,
	data
});

// ============================= Update Category ============================= //
export const updateCategoryData = (currentParentId, requestBody) => {
	return dispatch => {
		dispatch(request());
		CategorySevice.updateCategory(requestBody).then(
			response => {
				if (response && response.result === 200) {
					// Success : Close update category modal
					dispatch(success(response.data));

					// Refresh the current list
					dispatch(getAllCategories(currentParentId));
					popToast.show("Category updated successfully!");
				} else {
					dispatch(failure(response));

					let errorMessage = "Something went wrong while creating category!";
					if (response && response.message) {
						switch (response.message) {
							default:
								errorMessage = response.message;
								break;
						}
					}
					popToast.show(errorMessage);
				}
			},
			error => {
				if (error && error.status_message) {
					popToast.show(error.status_message);
				} else {
					popToast.show("Something went wrong while creating category!");
				}
				console.log("error dispatched for API error!", error);
				dispatch(failure(error));
			}
		);
	};

	function request() {
		return { type: types.UPDATE_CATEGORY_REQUEST };
	}
	function success(data) {
		return { type: types.UPDATE_CATEGORY_SUCCESS, data };
	}
	function failure(error) {
		return { type: types.UPDATE_CATEGORY_FAILURE, error };
	}
};

// ============================= Delete Property ============================= //
export const deleteProperty = (currentParentId, requestBody) => {
	return dispatch => {
		dispatch(request());
		CategorySevice.deleteProperty(requestBody).then(
			response => {
				if (response && response.result === 200) {
					// Success : Close delete property dialog
					dispatch(success(response.data));

					// To refresh the list
					dispatch(getAllCategories(currentParentId));

					popToast.show("Property deleted successfully!");
				} else {
					dispatch(failure(response));

					let errorMessage = "Something went wrong while creating category!";
					if (response && response.message) {
						switch (response.message) {
							default:
								errorMessage = response.message;
								break;
						}
					}
					popToast.show(errorMessage);
				}
			},
			error => {
				if (error && error.status_message) {
					popToast.show(error.status_message);
				} else {
					popToast.show("Something went wrong while creating category!");
				}
				console.log("error dispatched for API error!", error);
				dispatch(failure(error));
			}
		);
	};

	function request() {
		return { type: types.DELETE_PROPERTY_REQUEST };
	}
	function success(data) {
		return { type: types.DELETE_PROPERTY_SUCCESS, data };
	}
	function failure(error) {
		return { type: types.DELETE_PROPERTY_FAILURE, error };
	}
};

// ============================= get Category Rules ============================= //
export function getCategoryRulesAction(subCategoryId) {
	return dispatch => {
		dispatch(request());
		CategorySevice.getCategoryRules(subCategoryId).then(
			response => {
				if (response && response.message === "Success") {
					dispatch(success(response.data));
				} else if (response && response.message === "Failure") {
					dispatch(failure(response));
					popToast.show("Get category rules list Error : " + response.Message || "Something went wrong while loading category rules list!");
				}
			},
			error => {
				if (error && error.status_message) {
					popToast.show(error.status_message);
				} else {
					popToast.show("Something went wrong while loading category rules list!");
				}
				console.log("error dispatched for API error!", error);
				dispatch(failure(error));
			}
		);
	};

	function request() {
		return { type: types.GET_CATEGORY_RULES_REQUEST };
	}
	function success(data) {
		return { type: types.GET_CATEGORY_RULES_SUCCESS, data };
	}
	function failure(error) {
		return { type: types.GET_CATEGORY_RULES_FAILURE, error };
	}
};

// ============================= Create Category Rules ============================= //
export function createCategoryRulesAction(data) {
	return dispatch => {
		dispatch(request());
		CategorySevice.createCategoryRules(data).then(
			response => {
				if (response && response.result === 200 && response.message === "Success") {
					dispatch(success(response.data));
					dispatch(getCategoryRulesAction(data.SubcategoryId));
				} else if (response) {
					dispatch(failure(response));
					popToast.show(response.message || "Something went wrong while creating category rules!");
				}
			},
			error => {
				if (error && error.status_message) {
					popToast.show(error.status_message);
				} else {
					popToast.show("Something went wrong while creating category rules!");
				}
				console.log("error dispatched for API error!", error);
				dispatch(failure(error));
			}
		);
	};

	function request() {
		return { type: types.CREATE_CATEGORY_RULES_REQUEST };
	}
	function success(data) {
		return { type: types.CREATE_CATEGORY_RULES_SUCCESS, data };
	}
	function failure(error) {
		return { type: types.CREATE_CATEGORY_RULES_FAILURE, error };
	}
};

// ============================= Update Category Rules ============================= //
export function updateCategoryRulesAction(ruleId, data) {
	return dispatch => {
		dispatch(request());
		CategorySevice.updateCategoryRules(ruleId, data).then(
			response => {
				if (response && response.result === 200 && response.message === "Success") {
					dispatch(success(response.data));
					dispatch(getCategoryRulesAction(data.SubcategoryId));
				} else if (response) {
					dispatch(failure(response));
					popToast.show(response.message || "Something went wrong while updating category rules!");
				}
			},
			error => {
				if (error && error.status_message) {
					popToast.show(error.status_message);
				} else {
					popToast.show("Something went wrong while updating category rules!");
				}
				console.log("error dispatched for API error!", error);
				dispatch(failure(error));
			}
		);
	};

	function request() {
		return { type: types.UPDATE_CATEGORY_RULES_REQUEST };
	}
	function success(data) {
		return { type: types.UPDATE_CATEGORY_RULES_SUCCESS, data };
	}
	function failure(error) {
		return { type: types.UPDATE_CATEGORY_RULES_FAILURE, error };
	}
};

// ============================= Delete Category Rules ============================= //
export function deleteCategoryRulesAction(ruleId, subcategoryId) {
	return dispatch => {
		dispatch(request());
		CategorySevice.deleteCategoryRules(ruleId).then(
			response => {
				if (response && response.result === 200 && response.message === "Success") {
					dispatch(success(response.data));
					dispatch(getCategoryRulesAction(subcategoryId));
				} else if (response) {
					dispatch(failure(response));
					popToast.show(response.message || "Something went wrong while deleting category rules!");
				}
			},
			error => {
				if (error && error.status_message) {
					popToast.show(error.status_message);
				} else {
					popToast.show("Something went wrong while deleting category rules!");
				}
				console.log("error dispatched for API error!", error);
				dispatch(failure(error));
			}
		);
	};

	function request() {
		return { type: types.DELETE_CATEGORY_RULES_REQUEST };
	}
	function success(data) {
		return { type: types.DELETE_CATEGORY_RULES_SUCCESS, data };
	}
	function failure(error) {
		return { type: types.DELETE_CATEGORY_RULES_FAILURE, error };
	}
};
// ============================= Delete Property Dialog ============================= //
export const toggleDeletePropertyDialog = data => ({
	type: types.TOGGLE_DELETEPROPERTY_DIALOG,
	data
});
// ============================= Add Rule Dialog ============================= //
export const toggleAddRuleDialog = data => ({
	type: types.TOGGLE_ADD_RULE_DIALOG,
	data
});
// ============================= Add Rule Dialog ============================= //
export const toggleListRuleDialog = data => ({
	type: types.TOGGLE_LIST_RULE_DIALOG,
	data
});
