import { Button, Select } from "antd";
import styled from "styled-components";

export const NftAuctionCollection = styled.div` 
  background: #ffffff;
  box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.18);
  border-radius: 8px;
  padding: 1rem 5rem;
  margin: 3rem 2rem;
`;

export const NftCollectionWrapper = styled.div` 
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
   
`;

export const NftDropdwnWrapper = styled.div`
    display: flex;
    flex-flow: row;
    gap: .5rem;
    
`;

export const NftButtonWrapper = styled.div` 
    display: flex;
    flex-direction: row;
    justify-content: right;
    margin-top:1.5rem;
    padding: 1.5rem 0;
    align-items: center;
    gap:1rem;
    width:100%;
    border-top: 2px solid #000000;
`;

export const SelectNftCollection = styled(Select)` 
   
`;

export const CreateCollectionButton = styled(Button)` 
    background-color: #000000 !important;
    color: #ffffff !important;

    &:hover, &:focus {
        background-color: #000000 !important;
        color: #ffffff !important;
    }
`;

export const SaveButtonWrapper = styled.div` 
    display: flex;
    flex-direction: row;
    justify-content: right;
    margin: 1.5rem 0;
    align-items: center;
    gap:1rem;
`;

export const CancelButton = styled.span` 
   width: 5rem;
   cursor: pointer;
`;

export const NextButton = styled(Button)` 
    width: 8rem;
    background-color: #000000 !important;
    color: #ffffff !important;

    &:hover, &:focus {
        background-color: #000000 !important;
        color: #ffffff !important;
    }
`;

export const AddNftCollectionWrapper = styled.div` 
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    h3{
        text-align: center;
    }
`;

export const AddNftInputWrapper = styled.div` 
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    border-bottom: 2px solid #000000;

     .form-control {
      width: 100%;
      display: flex;
      flex-direction: column;
      input {
        width: 100%;
      }
    }
`;

export const ContractInputWrapperDiv = styled.div` 
    display: flex;
    flex-flow: row wrap;
    gap: .3rem;
    align-items: center;

    img {
        margin-bottom: 8px;
    }
`