import * as types from "../actions/ActionsTypes";

const initialState = {
    categoryList: [],

    brandsListCount: 0,
    brandsList: [],

    // Dialog
    addBrandsDialog: false,
    deleteBrandsDialog: false,

    categoryLoader: false,
    modalLoader: false
};

const addBrandsReducer = (state = initialState, action = {}) => {
    switch (action.type) {

        // ========== Get Category ========== //
        case types.GET_CATEGORY_REQUEST:
            console.log('GET_CATEGORY_REQUEST');
            state = {
                ...state,
                categoryList: [],
                categoryLoader: true
            }
            return state;
        case types.GET_CATEGORY_SUCCESS:
            console.log('GET_CATEGORY_SUCCESS');
            state = {
                ...state,
                categoryList: action.response,
                categoryLoader: false
            }
            return state;
        case types.GET_CATEGORY_FAILURE:
            console.log('GET_CATEGORY_FAILURE');
            state = {
                ...state,
                categoryList: [],
                categoryLoader: false
            }
            return state;

        // ========== Get Brands ========== //
        case types.GET_BRANDS_REQUEST:
            console.log('GET_BRANDS_REQUEST');
            state = {
                ...state,
                brandsListCount: 0,
                brandsList: []
            }
            return state;
        case types.GET_BRANDS_SUCCESS:
            console.log('GET_BRANDS_SUCCESS');
            state = {
                ...state,
                brandsListCount: action.data.count,
                brandsList: action.data.brandsList
            }
            return state;
        case types.GET_BRANDS_FAILURE:
            console.log('GET_BRANDS_FAILURE');
            state = {
                ...state,
                brandsListCount: 0,
                brandsList: []
            }
            return state;

        // ========== Add Brand ========== //
        case types.CREATE_BRAND_REQUEST:
            console.log('CREATE_BRAND_REQUEST');
            state = {
                ...state,
                modalLoader: true
            }
            return state;
        case types.CREATE_BRAND_SUCCESS:
            console.log('CREATE_BRAND_SUCCESS');
            state = {
                ...state,
                modalLoader: false,
                addBrandsDialog: false
            }
            return state;
        case types.CREATE_BRAND_FAILURE:
            console.log('CREATE_BRAND_FAILURE');
            state = {
                ...state,
                modalLoader: false
            }
            return state;

        // ========== Delete Brand ========== //
        case types.DELETE_BRAND_REQUEST:
            console.log('DELETE_BRAND_REQUEST');
            state = {
                ...state,
                modalLoader: true
            }
            return state;
        case types.DELETE_BRAND_SUCCESS:
            console.log('DELETE_BRAND_SUCCESS');
            state = {
                ...state,
                modalLoader: false,
                deleteBrandsDialog: false
            }
            return state;
        case types.DELETE_BRAND_FAILURE:
            console.log('DELETE_BRAND_FAILURE');
            state = {
                ...state,
                modalLoader: false
            }
            return state;

        // ========== Access reducer states ========== //
        // Accessing reducer states through a single action
        case types.SET_ADD_BRAND_REDUCER_STATES:
            console.log('SET_ADD_BRAND_REDUCER_STATES');
            state = {
                ...state,
                [action.data.state]: action.data.value
            }
            return state

        default:
            return state;
    }
};

export default addBrandsReducer;