import React, { useEffect } from "react";
import { useState } from "react";
import {
	CancelButton,
	CreateButton,
	CreateNewGalleryArtistContainer,
	FormGroup,
	Header,
	MediumWorksDiv,
	NextButtonSection,
	SelectInput,
} from "./CreateNewArtist.styled";
import { useHistory } from "react-router";
import { isEmpty } from "lodash";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { Modal, Typography } from "antd";
import "./ManageArtist.style.scss";
import ButtonSet from "../../../components/ButtonSet/ButtonSet";
import { ManageArtistsService } from "../ManageArtists.service";
import { useDispatch, useSelector } from "react-redux";
import { getAllCountryListAction } from "../../../redux/actions/CountriesTaxAction";
import Root from "../../Root/Root";
import Topbar from "../../../components/Topbar/Topbar";
import { authorizePostFormData, postFormData } from "../../../util/apiConfig";
import { popToast } from "../../../components/Toast/Toast";
import ConfirmationMessageDialog from "../../../components/ConfirmationMessageDialog/ConfirmationMessageDialog";

const CreateNewArtist = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const livingValidationSchema = {
		name: Yup.string().required("Please enter the artist name"),
		email: Yup.string().required("Please enter the email"),
		phone: Yup.string(),
		country: Yup.string().required("Please select the country"),
		birthYear: Yup.number()
			.positive("please enter positive values")
			.integer()
	};

	const { countriesList } = useSelector(state => state.countriesTaxReducer);

	const [isLoading, setIsLoading] = useState(false);
	const [mediumButtonTypes, setMediumButtonTypes] = useState([]);
	const [buttonListMedium, setButtonListMedium] = useState([]);
	const [showSuccessModal, setShowSuccessModal] = useState(false);


	useEffect(() => {
		onLoadData();
	}, []);



	const onLoadData = () => {
		dispatch(getAllCountryListAction());
		let body = {
			PropertyNames: ["MEDIUM"],
		};
		ManageArtistsService.getArtworkPrototypes(body).then(resp => {
			if (resp && resp.data) {
				let buttons = [];
				if (resp.data && !isEmpty(resp.data.artworkMedium)) {
					resp.data.artworkMedium.map((item) => {
						let obj = {
							label: item.name,
							value: item._id,
						};
						buttons.push(obj);
					});
				}
				setMediumButtonTypes(buttons);
			}
		})
	}

	const selectedMediumList = (buttonListMedium) => {
		setButtonListMedium(buttonListMedium);
	};

	const handleSubmitArtist = async (valueObj) => {
		setIsLoading(true);
		var formData = new FormData();

		formData.append("Name", valueObj.name);
		formData.append("Description", valueObj.description);
		formData.append("Place", valueObj.country);
		formData.append("BirthYear", valueObj.birthYear);
		formData.append("OverView", valueObj.overView);
		formData.append("Email", valueObj.email);
		buttonListMedium &&
			buttonListMedium.map((artMedia) => {
				formData.append("ArtMedium", artMedia);
			});

		let userToken = localStorage.getItem("authToken");
		postFormData(`/UserManagment/api/UserManagement/V2/Admin/CreateArtist?Email=${valueObj.email}&PhoneNumber=${valueObj.phone}&Country=${valueObj.country}`,
			formData,
			userToken
		).then(resp => {
			if (resp && resp.response && resp.response.data) {
				setShowSuccessModal(true);
			} else if (resp && resp.error) {
				popToast.show(resp.error.message);
			}
			setIsLoading(false);
		})
	};

	const onClickOkSuccess = () => {
		setShowSuccessModal(false);
		history.push('/manage-artists/1')
	}

	return (
		<Root>
			<Topbar title={"Send Artist Invitation"} />
			<CreateNewGalleryArtistContainer className="create-new-gallery-artist-page">
				<Header>Create New Artist Account</Header>
				<Formik
					initialValues={{
						name: '',
						description: "",
						birthYear: "",
						email: "",
						phone: "",
						message: "",
						country: "",
						isExternallyManaged: false,
						overView: '',
					}}
					validationSchema={Yup.object().shape(livingValidationSchema)}
					onSubmit={(values, { resetForm }) => {
						handleSubmitArtist(values);
					}}
				>
					{({ errors, touched, handleSubmit, isSubmitting, setFieldValue }) => {
						return (
							<Form noValidate className="gallery-form-wrapper-container">
								<div className="gallery-form-flex-row">
									<div className="form-wrapper-first-row">
										<FormGroup>
											<label>Artist Name *</label>
											<Field component="input" name="name" className="form-control" />
											{errors.name && touched.name && (
												<Typography.Text type="danger">{errors.name}</Typography.Text>
											)}
										</FormGroup>

										<FormGroup>
											<label>Country *</label>
											<Field
												name="country"
												render={(field) => (
													<SelectInput
														{...field}
														style={{ marginRight: "5px", width: "100%" }}
														showSearch
														onChange={(value) => setFieldValue("country", value)}
													>
														{countriesList.map((data, index) => {
															return (
																<option key={`country${index}`} value={data.name}>
																	{data.name}
																</option>
															);
														})}
													</SelectInput>
												)}
											/>
											{errors.country && touched.country && (
												<Typography.Text type="danger">{errors.country}</Typography.Text>
											)}
										</FormGroup>
										<FormGroup>
											<label>Description</label>
											<Field component="textarea" rows={4} name="description" className="form-control" />
										</FormGroup>
										<FormGroup>
											<label>Select medium of work</label>
											<MediumWorksDiv>
												<ButtonSet
													buttons={mediumButtonTypes}
													selectedButtons={selectedMediumList}
													list={""}
													allowedSelectionNo={4}
												/>
											</MediumWorksDiv>
										</FormGroup>

										<div className="gallery-living-artist-wrapper">
											<FormGroup>
												<label>Year of birth *</label>
												<Field component="input" name="birthYear" className="form-control" />
												{errors.birthYear && touched.birthYear && (
													<Typography.Text type="danger">{errors.birthYear}</Typography.Text>
												)}
											</FormGroup>
											<FormGroup>
												<label>Email *</label>
												<Field component="input" name="email" className="form-control" />
												{errors.email && touched.email && <Typography.Text type="danger">{errors.email}</Typography.Text>}
											</FormGroup>
											<FormGroup>
												<label>Phone *</label>
												<Field component="input" name="phone" className="form-control" />
												{errors.phone && touched.phone && <Typography.Text type="danger">{errors.phone}</Typography.Text>}
											</FormGroup>

											<FormGroup>
												<label>Quote</label>
												<Field component="textarea" rows={4} name="overView" className="form-control" />
											</FormGroup>
										</div>
									</div>
								</div>

								<NextButtonSection>
									<CancelButton onClick={() => history.push('manage-artists/1')}>Cancel</CancelButton>
									<CreateButton
										variant="dark"
										onClick={() => {
											handleSubmit();
										}}
									>
										{isLoading ? "Saving..." : "Save Profile"}
									</CreateButton>
								</NextButtonSection>
							</Form>
						);
					}}
				</Formik>
			</CreateNewGalleryArtistContainer>
			<ConfirmationMessageDialog
				open={showSuccessModal}
				confirmTitle={"Success"}
				confirmMessage={"Artist account created successfully.Invitation has been sent to artist Email address"}
				confirmButton={"Ok"}
				onConfirm={onClickOkSuccess}
				handleClose={onClickOkSuccess}
			/>
		</Root>
	);
};

export default CreateNewArtist;
