import { Button, Select } from "antd";
import styled from "styled-components";

export const CreateNewGalleryArtistContainer = styled.div`
	padding: 2% 3%;
	width: 100%;
	height: 100%;
	overflow: auto;	
`;

export const Header = styled.h2`
	font-weight: 700 !important;
	font-size: 14px !important;
	line-height: 127.69%;
	color: #000000;
	margin-bottom: 15px;
`;

export const MediumWorksDiv = styled.div`
	display: flex;
	flex-flow: row wrap;
	gap: 0.5rem;
`;

export const MediumWorkItems = styled.div`
	border: 0.5px solid rgba(0, 0, 0, 0.6);
	font-weight: 400;
	font-size: 12px;
	line-height: 120%;
	text-transform: uppercase;
	color: #000000;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 10px;
	height: 30px;
`;

export const FormGroup = styled.div`
	margin-bottom: 1rem;
	display: flex;
	flex-direction: column;
	
	.form-control {
		border-radius: 2px;
		min-height: 30px;
		border: 1px solid #000;
	}

	.css-13cymwt-control {
		border-radius: 0;
	}
`;

export const CreateButton = styled(Button)`
	background-color: #000000 !important;
    color: #ffffff !important;
	border-radius: 0 !important;

    &:hover, &:focus {
        background-color: #000000 !important;
        color: #ffffff !important;
    }
`;

export const NextButtonSection = styled.div`
	width: 55%;
	display: flex;
	justify-content: flex-end;
	border-top: 1px solid rgba(0, 0, 0, 0.6);
	padding-top: 1rem;
	margin: 3rem 0;
`;

export const CancelButton = styled.div`
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
	gap: 0.5rem !important;

	border-radius: 0 !important;
	font-size: 16px !important;
	height: 30px !important;
	width: 130px;
	cursor: pointer;
`;

export const SelectInput = styled(Select)`
	&& {
		.ant-select-selection {
			border: none;
			color: #58514b;
		}
		.ant-select-selection__placeholder {
			display: block !important;
			font-weight: normal !important;
			font-family: "Lato" !important;
			line-height: 28px !important;
			color: #858585;
		}

		.ant-select-selection--single .ant-select-selection__rendered {
			/* margin-right: 2.5px;
			margin-left: 2.5px; */
		}
		.ant-select-selection:focus,
		.ant-select-selection:active {
			/* border-color: #d9d9d9; */
			/* box-shadow: 0 0 0 2px #d9d9d9; */
			border: none !important;
			box-shadow: none;
		}
		.ant-select-selection:hover {
			border: none !important;
			box-shadow: none;
			/* border-color: #d9d9d9; */
			/* box-shadow: 0 0 0 2px #d9d9d9; */
		}
		.ant-select-arrow {
			right: 4.5px;
		}
		.ant-select-selection-selected-value {
			/* margin-right: 1rem; */
		}
		.ant-select-dropdown-menu-item {
			text-align: left !important;
		}

		.ant-select-selector {
			border: none;
			color: #58514b;
		}
		font-size: 15px;
		font-weight: 500;
		color: #58514b;
		position: relative;
		/* margin: 2.5px 10px 2.5px; */
		border: 1px solid #e5e5e5;
		background: #ffffff 0% 0% no-repeat padding-box;
		/* border:antiquewhite solid 1px; */
		outline: none;
		&:focus,
		&:active {
			/* border-color: 0px solid rgb(0, 156, 38); */
			/* -webkit-box-shadow: 0 0 0 2px rgba(255, 0, 0, 0); */
			/* box-shadow: 0 0 0 2px rgba(255, 0, 0, 0); */
			/* outline: 0; */
		}
	}
`;
