import React, { Component } from 'react';
import { Cascader, Tooltip } from 'antd';
import './ScrollHorizontalList.scss';

class ScrollHorizontalList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cascadeMenus: [
                {
                    index: this.generateKey(),
                    CategoryId: ""
                }
            ]
        }
    }

    // --------------- Refer Docs : https://ant.design/components/cascader/ --------------- //
    render() {
        return (
            <div className="scroll-horizontal">

                {this.props.loader ?
                    <div className="cascade-wrapper disabled">
                        <p>Fetching categories..</p>
                        <img src={require('../../assets/images/common/delete.svg')} alt="Delete" />
                    </div>
                    :
                    this.state.cascadeMenus.map((item, index) => (
                        <div className={"cascade-wrapper" + (this.props.onlySelectCategory ? " onlySelect" : "")} key={item.index}>
                            <Cascader
                                className="scroll-cascader"
                                popupClassName={"scroll-cascader-menu" + (this.props.onlySelectCategory ? 'onlySelect' : '')}
                                options={this.props.scrollList}
                                onChange={this.onChangeCascaderValue.bind(this, item)}
                                notFoundContent="No subcategories found"
                                fieldNames={{ label: 'categoryName', value: '_id', children: 'subCategories' }}
                                placeholder="Please select" />
                            {this.props.onlySelectCategory ? '' :
                                <Tooltip placement="top" title={'Remove category'}>
                                    <img src={require('../../assets/images/common/delete.svg')} alt="Delete" onClick={this.onClickAddFilter.bind(this, item, 'POP')} />
                                </Tooltip>
                            }

                        </div>
                    ))
                }
                {/* -------- Add category -------- */}
                {this.props.onlySelectCategory ? '' :
                    <div className="add-button-box">
                        <Tooltip placement="top" title={'Add category'}>
                            <img src={require('../../assets/images/common/Add.svg')} alt="Add" onClick={this.onClickAddFilter.bind(this, null, 'PUSH')} />
                        </Tooltip>
                    </div>
                }

            </div>
        );
    }

    onChangeCascaderValue(itemDetails, categoryArray) {
        
        // On handle change event for inputs inside filter array
        let changingItem = this.state.cascadeMenus.find(function (item) {
            return item.index === itemDetails.index
        });
        changingItem['CategoryId'] = categoryArray && categoryArray.length > 0 && categoryArray[categoryArray.length - 1];

        // State changed using setState - to update the component
        this.setState({ cascadeMenus: this.state.cascadeMenus }
            , () => {
                this.props.onReturningCascadeMenuData(this.state.cascadeMenus);
            });

    }

    // Generate Unique Key
    generateKey = (pre = 'scroll') => {
        return `${pre}_${new Date().getTime()}`;
    }

    // Add/Remove Menu
    onClickAddFilter(removingData, task) {

        // Do push/pop only if loader is false 
        // ie [There is no outgoing request happening]
        if (this.props.loader === false) {
            if (task === 'PUSH') {

                let newItem = {
                    index: this.generateKey(),
                    CategoryId: ''
                }

                this.setState({
                    cascadeMenus: [...this.state.cascadeMenus, newItem]
                }, () => {
                    this.props.onReturningCascadeMenuData(this.state.cascadeMenus);
                });

            }
            else if (task === 'POP') {
                this.setState({ cascadeMenus: this.state.cascadeMenus.filter(data => data.index !== removingData.index) },
                    () => { this.props.onReturningCascadeMenuData(this.state.cascadeMenus); });
            }
        }
    }

}

export default ScrollHorizontalList;