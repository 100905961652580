import { APIUrlConstants } from "../../util/apiUrl.constant";
import { APIService } from "../../util/api.service";

export class FilterRuleService {

    static getAllFilterRules(searchKey) {

        let url = `${APIUrlConstants.getApiUrl("filterPreset")}?FIlterName=${searchKey}&PageNum=1&PageSize=100`;

        return APIService.doGet(url).then(resp => {
            if (resp) {
                return resp;
            }
            throw resp;
        });
    }
    static getFilterRuleById(id) {
        let url = `${APIUrlConstants.getApiUrl("getFilterRuleById")}?FilterId=${id}`;

        return APIService.doGet(url).then(resp => {
            if (resp) {
                return resp;
            }
            throw resp;
        });
    }

    static createFilterRule(body) {

        let url = APIUrlConstants.getApiUrl('filterPreset');
        return APIService.doPost(url, body).then(resp => {
            if (resp) {
                return resp;
            }
            throw resp;
        });

    }
    static updateFilterRule(body, id) {

        let url = `${APIUrlConstants.getApiUrl('filterPreset')}/${id}`;
        return APIService.doPut(url, body).then(resp => {
            if (resp) {
                return resp;
            }
            throw resp;
        });

    }


    static deleteFilterRule(id) {
        let url = `${APIUrlConstants.getApiUrl('filterPreset')}/${id}`;
        return APIService.doDelete(url).then(resp => {
            if (resp) {
                return resp;
            }
            throw resp;
        });

    }

}