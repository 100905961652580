import {
	GET_ARTWORKSLIST_REQUEST,
	GET_ARTWORKSLIST_SUCCESS,
	GET_ARTWORKSLIST_FAILURE,
	UPDATE_PRODUCT_STATUS_REQUEST,
	UPDATE_PRODUCT_STATUS_SUCCESS,
	UPDATE_PRODUCT_STATUS_FAILURE,
	DELETE_ARTWORK_REQUEST,
	DELETE_ARTWORK_SUCCESS,
	DELETE_ARTWORK_FAILURE,
	TOGGLE_CHANGE_PRODUCT_STATUS,
	GET_ARTWORKS_DOWNLOAD_REQUEST,
	GET_ARTWORKS_DOWNLOAD_SUCCESS,
	GET_ARTWORKS_DOWNLOAD_FAILURE,
	SET_ARTWORK_FEATURED_REQUEST,
	SET_ARTWORK_FEATURED_SUCCESS,
	SET_ARTWORK_FEATURED_FAILURE,
	BULK_ARTWORK_UPLOAD,
	BULK_ARTWORK_UPLOAD_SUCCESS,
	BULK_ARTWORK_UPLOAD_FAILURE,
	UNHOLD_ARTWORK_SUCCESS,
	UNHOLD_ARTWORK_FAILURE,
	UNHOLD_ARTWORK_REQUEST,
} from "./ActionsTypes";
import { ManageArtworkService } from "../../containers/ManageArtworks/ManageArtworks.service";
import { popToast } from "../../components/Toast/Toast";
import { reject } from "lodash";

export function getArtworksListAction(inputData) {
	localStorage.setItem("artworkFilterOptions", JSON.stringify(inputData));
	return (dispatch) => {
		dispatch(request());
		ManageArtworkService.getArtworksList(inputData).then(
			(response) => {
				if (response && response.message === "Success") {
					dispatch(success(response.data));
				} else if (response && response.message === "Failure") {
					dispatch(failure(response));
					popToast.show("Get artworks list Error : " + response.Message || "Something went wrong while loading artworks list!");
				}
			},
			(error) => {
				if (error && error.status_message) {
					popToast.show(error.status_message);
				} else {
					popToast.show("Something went wrong while loading artworks list!");
				}
				console.log("error dispatched for API error!", error);
				dispatch(failure(error));
			}
		);
	};

	function request() {
		return { type: GET_ARTWORKSLIST_REQUEST };
	}
	function success(data) {
		return { type: GET_ARTWORKSLIST_SUCCESS, data };
	}
	function failure(error) {
		return { type: GET_ARTWORKSLIST_FAILURE, error };
	}
}

export function modifyArtworkStateAction(updateData) {
	return ManageArtworkService.modifyArtworkState(updateData).then(
		(response) => {
			if (response && response.result === 200 && response.message === "Success") {
				popToast.show("Artwork status update successfully ");

			} else if (response) {
				popToast.show(response.message || "Something went wrong while Update status!");
			}
			return;
		},
		(error) => {
			if (error && error.status_message) {
				popToast.show(error.status_message);
			} else {
				popToast.show("Something went wrong while Update status!");
			}
			console.log("error dispatched for API error!", error);
			return;
		}
	);
}


export function updateProductStatusAction(updateData, inputData) {
	return (dispatch) => {
		dispatch(request());
		ManageArtworkService.updateProductStatus(updateData).then(
			(response) => {
				if (response && response.result === 200 && response.message === "Success") {
					dispatch(success(response));
					dispatch(toggleChangeProductStatus(inputData, updateData.status));
					popToast.show("Artwork status update successfully ");
				} else if (response) {
					dispatch(failure(response));
					popToast.show(response.message || "Something went wrong while Update status!");
				}
			},
			(error) => {
				if (error && error.status_message) {
					popToast.show(error.status_message);
				} else {
					popToast.show("Something went wrong while Update status!");
				}
				console.log("error dispatched for API error!", error);
				dispatch(failure(error));
			}
		);
	};

	function request() {
		return { type: UPDATE_PRODUCT_STATUS_REQUEST };
	}
	function success(data) {
		return { type: UPDATE_PRODUCT_STATUS_SUCCESS, data };
	}
	function failure(error) {
		return { type: UPDATE_PRODUCT_STATUS_FAILURE, error };
	}
}

export function unHoldArtworkAction(artworkId, stockId) {
	return new Promise((resolve, reject) => {

		ManageArtworkService.unHoldProduct(stockId, artworkId).then(
			(response) => {
				if (response && response.message === "Success") {
					popToast.show("Artwork Update Successfully");
				} else if (response && response.message === "Failure") {
					popToast.show("Update Artwork Error : " + response.Message || "Something went wrong while deleting artwork!");
				}
				resolve();
			},
			(error) => {
				if (error && error.status_message) {
					popToast.show(error.status_message);
				} else {
					popToast.show("Something went wrong while deleting artwork!");
				}
				reject();
			}
		);
	})
};


export function deleteArtworkAction(stockId) {
	return (dispatch) => {
		dispatch(request());
		ManageArtworkService.deleteProduct(stockId).then(
			(response) => {
				if (response && response.message === "Success") {
					dispatch(success(response.data));
					let inputData = {
						PageNum: 1,
						PageSize: 10,
						ProductName: "",
					};
					dispatch(success(getArtworksListAction(inputData)));
					popToast.show("Artwork Deleted Successfully");
				} else if (response && response.message === "Failure") {
					dispatch(failure(response));
					popToast.show("Delete Artwork Error : " + response.Message || "Something went wrong while deleting artwork!");
				}
			},
			(error) => {
				if (error && error.status_message) {
					popToast.show(error.status_message);
				} else {
					popToast.show("Something went wrong while deleting artwork!");
				}
				console.log("error dispatched for API error!", error);
				dispatch(failure(error));
			}
		);
	};

	function request() {
		return { type: DELETE_ARTWORK_REQUEST };
	}
	function success(data) {
		return { type: DELETE_ARTWORK_SUCCESS, data };
	}
	function failure(error) {
		return { type: DELETE_ARTWORK_FAILURE, error };
	}
}

//======================Download Artworks===========================//
export function adminDownloadProductAction(body) {
	return (dispatch) => {
		dispatch(request());
		ManageArtworkService.adminDownloadProduct(body).then(
			(response) => {
				if (response) {
					const url = window.URL.createObjectURL(new Blob([response]));
					const link = document.createElement("a");
					link.href = url;
					link.download = `RtistIQ Artworks_${new Date().toLocaleString()}.xlsx`;
					// link.setAttribute("download", "file.pdf");
					document.body.appendChild(link); // we need to append the element to the dom -> otherwise it will not work in firefox
					link.click();
					link.remove(); //afterwards we remove the element again
					dispatch(success(response.data));
				} else if (response && response.message === "Failure") {
					dispatch(failure(response));
					popToast.show("Get order invoice Error : " + response.Message || "Something went wrong while downloading order invoice!");
				}
			},
			(error) => {
				if (error && error.status_message) {
					popToast.show(error.status_message);
				} else {
					popToast.show("Something went wrong while downloading order invoice!");
				}
				console.log("error dispatched for API error!", error);
				dispatch(failure(error));
			}
		);
	};

	function request() {
		return { type: GET_ARTWORKS_DOWNLOAD_REQUEST };
	}
	function success(data) {
		return { type: GET_ARTWORKS_DOWNLOAD_SUCCESS, data };
	}
	function failure(error) {
		return { type: GET_ARTWORKS_DOWNLOAD_FAILURE, error };
	}
}

export function setArtworkFeaturedAction(artworkId) {
	return (dispatch) => {
		dispatch(request());
		ManageArtworkService.setArtworkFeatured(artworkId).then(
			(response) => {
				if (response && response.result === 200 && response.message === "Success") {
					dispatch(success(response.data));
				} else if (response) {
					dispatch(failure(response));
					popToast.show(response.message || "Something went wrong while set artwork featured/unfeatured!");
				}
			},
			(error) => {
				if (error && error.status_message) {
					popToast.show(error.status_message);
				} else {
					popToast.show("Something went wrong while set artwork featured/unfeatured!");
				}
				console.log("error dispatched for API error!", error);
				dispatch(failure(error));
			}
		);
	};

	function request() {
		return { type: SET_ARTWORK_FEATURED_REQUEST };
	}
	function success(data) {
		return { type: SET_ARTWORK_FEATURED_SUCCESS, data };
	}
	function failure(error) {
		return { type: SET_ARTWORK_FEATURED_FAILURE, error };
	}
}

export function uploadBulkArtwork(body) {
	console.log("🚀 ~ file: ManageArtworksAction.js ~ line 219 ~ uploadBulkArtwork ~ body", body);
	return (dispatch) => {
		dispatch(request());
		ManageArtworkService.uploadArtwork(body).then(
			(response) => {
				console.log("🚀 ~ file: ManageArtworksAction.js ~ line 223 ~ uploadBulkArtwork ~ response", response);

				if (response && response.message === "Success") {
					popToast.show("Bulk upload success");

					dispatch(success(response.data));
				} else if (response && response.result === 400) {
					dispatch(failure(response));
					popToast.show(response.Message || "Something went wrong while loading artworks list!");
				}
			},
			(error) => {
				if (error && error.status_message) {
					popToast.show(error.status_message);
				} else {
					popToast.show("Something went wrong while loading artworks list!");
				}
				console.log("error dispatched for API error!", error);
				dispatch(failure(error));
			}
		);
	};

	function request() {
		return { type: BULK_ARTWORK_UPLOAD };
	}
	function success(data) {
		return { type: BULK_ARTWORK_UPLOAD_SUCCESS, data };
	}
	function failure(error) {
		console.log("🚀 ~ file: ManageArtworksAction.js ~ line 254 ~ failure ~ error", error);
		return { type: BULK_ARTWORK_UPLOAD_FAILURE, error };
	}
}

// ============================= Toggle Change Product Status ============================= //
export const toggleChangeProductStatus = (data, status) => ({
	type: TOGGLE_CHANGE_PRODUCT_STATUS,
	data,
	status,
});

export function submitArtworktoBlockchain(artworkId, stockId) {
	return new Promise((resolve, reject) => {
		ManageArtworkService.submitArtworktoBlockchain(artworkId, stockId).then(
			(response) => {
				if (response && response.result === 200 && response.message === "Success") {
					popToast.show("Artwork status update successfully ");
				} else if (response) {
					popToast.show(response.message || "Something went wrong while minting token!");
				}
				resolve();
			},
			(error) => {
				if (error && error.status_message) {
					popToast.show(error.status_message);
				} else {
					popToast.show("Something went wrong while minting token!");
				}
				console.log("error dispatched for API error!", error);
				reject(error);
			}
		);
	});
}

export function onMarkNFTRedeemable(stockId) {
	return new Promise((resolve, reject) => {
		ManageArtworkService.onMarkNFTRedeemable(stockId).then(
			(response) => {
				if (response && response.result === 200 && response.message === "Success") {
					popToast.show("Artwork marked as nft redeemable");
				} else if (response) {
					popToast.show(response.message || "Something went wrong while mark as nft redemable!");
				}
				resolve();
			},
			(error) => {
				if (error && error.status_message) {
					popToast.show(error.status_message);
				} else {
					popToast.show("Something went wrong while mark as nft redemable!");
				}
				console.log("error dispatched for API error!", error);
				reject(error);
			}
		);
	});
}

export function onApproveNFTRedeemable(stockId) {
	return new Promise((resolve, reject) => {
		ManageArtworkService.onApproveNFTRedeemable(stockId).then(
			(response) => {
				if (response && response.result === 200 && response.message === "Success") {
					popToast.show("Nft redemption approved ");
				} else if (response) {
					popToast.show(response.message || "Something went wrong while nft redemption approval!");
				}
				resolve();
			},
			(error) => {
				if (error && error.status_message) {
					popToast.show(error.status_message);
				} else {
					popToast.show("Something went wrong while approving nft redemption approval!");
				}
				console.log("error dispatched for API error!", error);
				reject(error);
			}
		);
	});
}

export function downloadBulkuploadForm() {
	return new Promise((resolve, reject) => {
		ManageArtworkService.downloadBulkuploadForm().then(
			(downLoadresponse) => {
				if (downLoadresponse) {
					const url = window.URL.createObjectURL(new Blob([downLoadresponse]));
					const link = document.createElement("a");
					link.href = url;
					link.download = `UserSearchHistory_${new Date().toLocaleString()}.xlsx`;
					// link.setAttribute("download", "file.pdf");
					document.body.appendChild(link); // we need to append the element to the dom -> otherwise it will not work in firefox
					link.click();
					link.remove(); //afterwards we remove the element again

				}
				resolve();
			},
			(error) => {
				if (error && error.status_message) {
					popToast.show(error.status_message);
				} else {
					popToast.show("Something went wrong while downloading Bulk upload form!");
				}
				console.log("error dispatched for API error!", error);
				reject(error);
			}
		);
	});
}

export function blockArtworkOriginal(artworkId, stockId) {
	return new Promise((resolve, reject) => {
		ManageArtworkService.blockArtworkOriginal(artworkId, stockId).then(
			(response) => {
				if (response && response.result === 200 && response.message === "Success") {
					popToast.show("Artwork status update successfully ");
				} else if (response) {
					popToast.show(response.message || "Something went wrong while minting token!");
				}
				resolve();
			},
			(error) => {
				if (error && error.status_message) {
					popToast.show(error.status_message);
				} else {
					popToast.show("Something went wrong while minting token!");
				}
				console.log("error dispatched for API error!", error);
				reject(error);
			}
		);
	});
}

export function getArtistNamesAction() {
	return ManageArtworkService.getArtistsNames().then(
		(response) => {
			return response;
		},
		(error) => {
			return;
		}
	);
}
