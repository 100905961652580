import React, { Component } from 'react';
import Root from '../Root/Root';
import Topbar from '../../components/Topbar/Topbar';

import './Reports.scss';
class Reports extends Component {
    render() {
        return (
            <Root>
                <Topbar title={'Reports'} />
                <div className="report-wrapper">
                    <button class="download-button">Download</button>
                </div>
            </Root>
        );
    }
}

export default Reports;