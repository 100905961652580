import { Button } from "antd";
import styled from "styled-components";

const BlogBannerContainer = styled.div` 
    padding: 1rem;
    display: flex;
    flex-direction: column;
`;

const BlogBannerButtonContainer = styled.div` 
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
    margin-bottom: .5rem;
`;

const AddBannerButton = styled(Button)`
    background-color: #000000 !important;
    color: #ffffff !important;
    height: 2rem !important;
    font-size: 11px;
    width: 8.06rem;

    &:focus, &:hover {
        background-color: #000000 !important;
        color: #ffffff !important;
    }

    span {        
        font-size: .7rem;
        font-weight: 600 !important;
    }
`;

const IsActiveCheckBoxWrapper = styled.div` 
    display: flex;

`


export {
  BlogBannerButtonContainer,
  BlogBannerContainer,
  AddBannerButton,
  IsActiveCheckBoxWrapper
}