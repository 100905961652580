import * as types from "../actions/ActionsTypes";

const initialState = {
    categoryList: [],
    categoryPropertyList: [],

    currentParentId: '',
    selectedCategoryId: '',
    selectedPropertyId: '',

    addCategoryDialog: false,
    addPropertyDialog: false,

    deleteMainCategoryDialog: false,
    deletePropertyDialog: false,
    editViewCategoryDialog: false,
    addRuleDialog: false,
    listRuleDialog: false,

    breadCrumbList: [
        {
            categoryName: 'Categories',
            index: 0
        }
    ],

    categoryLoader: false,
    propertyLoader: false,
    modalLoader: false,
    getRuleLoader: false,
    categoryRulesList: null,
    createRuleLoader: false,
    createRuleData: null,
    updateRuleLoader: false,
    updateRuleData: null,
    deleteRuleLoader: false,
    deleteRuleData: null
}

const categoryReducer = (state = initialState, action = {}) => {
    switch (action.type) {

        // ========== Get Category ========== //
        case types.GET_CATEGORY_REQUEST:
            state = {
                ...state,
                categoryList: [],
                categoryLoader: true
            }
            return state;
        case types.GET_CATEGORY_SUCCESS:
            state = {
                ...state,
                categoryList: action.response,
                categoryLoader: false
            }
            return state;
        case types.GET_CATEGORY_FAILURE:
            state = {
                ...state,
                categoryList: [],
                categoryLoader: false
            }
            return state;

        // ========== Access reducer states ========== //
        // Accessing reducer states through a single action
        case types.SET_CATEGORY_REDUCER_STATES:
            state = {
                ...state,
                [action.data.state]: action.data.value
            }
            return state;

        // ========== BreadCrumb ========== //
        case types.SET_BREADCRUMBS_SUCCESS:
            state = {
                ...state,
                breadCrumbList: action.data
            }
            return state;
        case types.SET_BREADCRUMBS_FAILURE:
            return state;

        // ========== Get Property ========== //
        case types.GET_PROPERTY_REQUEST:
            state = {
                ...state,
                categoryPropertyList: [],
                propertyLoader: true
            }
            return state;
        case types.GET_PROPERTY_SUCCESS:
            state = {
                ...state,
                categoryPropertyList: action.data,
                propertyLoader: false
            }
            return state;
        case types.GET_PROPERTY_FAILURE:
            state = {
                ...state,
                categoryPropertyList: [],
                propertyLoader: false
            }
            return state;

        // ========== Create Category ========== //
        case types.CREATE_CATEGORY_REQUEST:
            state = {
                ...state,
                modalLoader: true
            }
            return state;
        case types.CREATE_CATEGORY_SUCCESS:
            state = {
                ...state,
                modalLoader: false,
                addCategoryDialog: false
            }
            return state;
        case types.CREATE_CATEGORY_FAILURE:
            state = {
                ...state,
                modalLoader: false,
                addCategoryDialog: true
            }
            return state;

        // ========== Create Property ========== //
        case types.CREATE_PROPERTY_REQUEST:
            state = {
                ...state,
                modalLoader: true
            }
            return state;
        case types.CREATE_PROPERTY_SUCCESS:
            state = {
                ...state,
                modalLoader: false,
                addPropertyDialog: false
            }
            return state;
        case types.CREATE_PROPERTY_FAILURE:
            state = {
                ...state,
                modalLoader: false,
                addPropertyDialog: true
            }
            return state;

        // ========== Delete Category ========== //
        case types.DELETE_CATEGORY_REQUEST:
            state = {
                ...state,
                modalLoader: true
            }
            return state;
        case types.DELETE_CATEGORY_SUCCESS:
            state = {
                ...state,
                modalLoader: false,
                deleteMainCategoryDialog: false,
                selectedCategoryId: ''
            }
            return state;
        case types.DELETE_CATEGORY_FAILURE:
            state = {
                ...state,
                modalLoader: false
            }
            return state;

        // ========== Update Category ========== //
        case types.UPDATE_CATEGORY_REQUEST:
            state = {
                ...state,
                modalLoader: true
            }
            return state;
        case types.UPDATE_CATEGORY_SUCCESS:
            state = {
                ...state,
                modalLoader: false,
                editViewCategoryDialog: false
            }
            return state;
        case types.UPDATE_CATEGORY_FAILURE:
            state = {
                ...state,
                modalLoader: false
            }
            return state;

        // ========== Delete Property ========== //
        case types.DELETE_PROPERTY_REQUEST:
            state = {
                ...state,
                modalLoader: true
            }
            return state;
        case types.DELETE_PROPERTY_SUCCESS:
            state = {
                ...state,
                modalLoader: false,
                deletePropertyDialog: false
            }
            return state;
        case types.DELETE_PROPERTY_FAILURE:
            state = {
                ...state,
                modalLoader: false
            }
            return state;

        // ========== Get Category Rules ========== //
        case types.GET_CATEGORY_RULES_REQUEST:
            state = {
                ...state,
                getRuleLoader: true,
                categoryRulesList: null
            }
            return state;
        case types.GET_CATEGORY_RULES_SUCCESS:
            state = {
                ...state,
                getRuleLoader: false,
                categoryRulesList: action.data
            }
            return state;
        case types.GET_CATEGORY_RULES_FAILURE:
            state = {
                ...state,
                getRuleLoader: false,
                categoryRulesList: null
            }
            return state;

        // ========== Create Category Rules ========== //
        case types.CREATE_CATEGORY_RULES_REQUEST:
            state = {
                ...state,
                createRuleLoader: true,
                createRuleData: null
            }
            return state;
        case types.CREATE_CATEGORY_RULES_SUCCESS:
            state = {
                ...state,
                createRuleLoader: false,
                addRuleDialog: false,
                createRuleData: action.data
            }
            return state;
        case types.CREATE_CATEGORY_RULES_FAILURE:
            state = {
                ...state,
                createRuleLoader: false,
                createRuleData: null
            }
            return state;

        // ========== Update Category Rules ========== //
        case types.UPDATE_CATEGORY_RULES_REQUEST:
            state = {
                ...state,
                updateRuleLoader: true,
                updateRuleData: null
            }
            return state;
        case types.UPDATE_CATEGORY_RULES_SUCCESS:
            state = {
                ...state,
                updateRuleLoader: false,
                addRuleDialog: false,
                updateRuleData: action.data
            }
            return state;
        case types.UPDATE_CATEGORY_RULES_FAILURE:
            state = {
                ...state,
                updateRuleLoader: false,
                updateRuleData: null
            }
            return state;

        // ========== Delete Category Rules ========== //
        case types.DELETE_CATEGORY_RULES_REQUEST:
            state = {
                ...state,
                deleteRuleLoader: true,
                deleteRuleData: null
            }
            return state;
        case types.DELETE_CATEGORY_RULES_SUCCESS:
            state = {
                ...state,
                deleteRuleLoader: false,
                deleteRuleData: action.data
            }
            return state;
        case types.DELETE_CATEGORY_RULES_FAILURE:
            state = {
                ...state,
                deleteRuleLoader: false,
                deleteRuleData: null
            }
            return state;

        // --------- Toggle Dialog's --------- //
        case types.TOGGLE_ADDCATEGORY_DIALOG:
            state = {
                ...state,
                addCategoryDialog: action.data
            }
            return state;

        case types.TOGGLE_ADDPROPERTY_DIALOG:
            state = {
                ...state,
                addPropertyDialog: action.data
            }
            return state;

        case types.TOGGLE_DELETEMAINCATEGORY_DIALOG:
            state = {
                ...state,
                deleteMainCategoryDialog: action.data
            }
            return state;

        case types.TOGGLE_EDITVIEWCATEGORY_DIALOG:
            state = {
                ...state,
                editViewCategoryDialog: action.data
            }
            return state;

        case types.TOGGLE_DELETEPROPERTY_DIALOG:
            state = {
                ...state,
                deletePropertyDialog: action.data
            }
            return state;
        case types.TOGGLE_ADD_RULE_DIALOG:
            state = {
                ...state,
                addRuleDialog: action.data
            }
        case types.TOGGLE_LIST_RULE_DIALOG:
            state = {
                ...state,
                listRuleDialog: action.data
            }
            return state;


        default:
            return state;
    }
}

export default categoryReducer;
