import * as types from "../actions/ActionsTypes";

const initialState = {
    newsList: [],
    getNewsLoader: false,
    upadteNewsResult: [],
    updateNewsLoader: false,
    deleteNewsResult: [],
    deleteNewsLoader: false,
    addNewsResult: [],
    addNewsLoader: false
};

const newsReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case types.GET_NEWS_REQUEST:
            state = {
                ...state,
                newsList: [],
                getNewsLoader: true
            };
            return state;
        case types.GET_NEWS_SUCCESS:
            state = {
                ...state,
                newsList: action.data,
                getNewsLoader: false
            };
            return state;
        case types.GET_NEWS_FAILURE:
            state = {
                ...state,
                newsList: [],
                getNewsLoader: false
            };
            return state;

        case types.UPDATE_NEWS_REQUEST:
            state = {
                ...state,
                upadteNewsResult: [],
                updateNewsLoader: true
            };
            return state;
        case types.UPDATE_NEWS_SUCCESS:
            state = {
                ...state,
                upadteNewsResult: action.response,
                updateNewsLoader: false
            };
            return state;
        case types.UPDATE_NEWS_FAILURE:
            state = {
                ...state,
                upadteNewsResult: [],
                updateNewsLoader: false
            };
            return state;

        case types.DELETE_NEWS_REQUEST:
            state = {
                ...state,
                deleteNewsResult: [],
                deleteNewsLoader: true
            };
            return state;
        case types.DELETE_NEWS_SUCCESS:
            state = {
                ...state,
                deleteNewsResult: action.response,
                deleteNewsLoader: false
            };
            return state;
        case types.DELETE_NEWS_FAILURE:
            state = {
                ...state,
                deleteNewsResult: [],
                deleteNewsLoader: false
            };
            return state;

        case types.ADD_NEWS_REQUEST:
            state = {
                ...state,
                addNewsResult: [],
                addNewsLoader: true
            };
            return state;
        case types.ADD_NEWS_SUCCESS:
            state = {
                ...state,
                addNewsResult: action.response,
                addNewsLoader: false
            };
            return state;
        case types.ADD_NEWS_FAILURE:
            state = {
                ...state,
                addNewsResult: [],
                addNewsLoader: false
            };
            return state;

        default:
            return state;
    }
};
export default newsReducer;
