import {
    GET_ALL_COUNTRY_LIST_REQUEST, GET_ALL_COUNTRY_LIST_SUCCESS, GET_ALL_COUNTRY_LIST_FAILURE,
    GET_COUNTRY_TAX_LIST_REQUEST, GET_COUNTRY_TAX_LIST_SUCCESS, GET_COUNTRY_TAX_LIST_FAILURE,
    ADD_COUNTRY_TAX_REQUEST, ADD_COUNTRY_TAX_SUCCESS, ADD_COUNTRY_TAX_FAILURE,
    UPDATE_COUNTRY_TAX_REQUEST, UPDATE_COUNTRY_TAX_SUCCESS, UPDATE_COUNTRY_TAX_FAILURE,
    DELETE_COUNTRY_TAX_REQUEST, DELETE_COUNTRY_TAX_SUCCESS, DELETE_COUNTRY_TAX_FAILURE, TOGGLE_ADD_COUNTRY_TAX_DIALOG
} from "./ActionsTypes";

import { popToast } from "../../components/Toast/Toast";
import { CountriesTaxService } from "../../containers/CountriesTax/CountriesTax.service";

// ============================= Get All Countries List ============================= //
export const getAllCountryListAction = () => {
    return dispatch => {
        dispatch(request());
        CountriesTaxService.getAllCountryList().then(
            response => {
                if (response && response.message === "Success") {
                    dispatch(success(response.data));
                } else if (response && response.message === "Failure") {
                    dispatch(failure(response));
                    popToast.show("Get all countries list Error : " + response.Message || "Something went wrong while loading countries list!");
                }
            },
            error => {
                if (error && error.status_message) {
                    popToast.show(error.status_message);
                } else {
                    popToast.show("Something went wrong while loading countries list!");
                }
                console.log("error dispatched for API error!", error);
                dispatch(failure(error));
            }
        );
    };

    function request() {
        return { type: GET_ALL_COUNTRY_LIST_REQUEST };
    }
    function success(data) {
        return { type: GET_ALL_COUNTRY_LIST_SUCCESS, data };
    }
    function failure(error) {
        return { type: GET_ALL_COUNTRY_LIST_FAILURE, error };
    }
};

// ============================= Get Country Tax List ============================= //
export const getCountryTaxListAction = (pageNum, pageSize, countryName) => {
    return dispatch => {
        dispatch(request());
        CountriesTaxService.getCountryTaxList(pageNum, pageSize, countryName).then(
            response => {
                if (response && response.message === "Success") {
                    dispatch(success(response.data));
                } else if (response && response.message === "Failure") {
                    dispatch(failure(response));
                    popToast.show("Get country tax list Error : " + response.Message || "Something went wrong while loading country tax list!");
                }
            },
            error => {
                if (error && error.status_message) {
                    popToast.show(error.status_message);
                } else {
                    popToast.show("Something went wrong while loading country tax list!");
                }
                console.log("error dispatched for API error!", error);
                dispatch(failure(error));
            }
        );
    };

    function request() {
        return { type: GET_COUNTRY_TAX_LIST_REQUEST };
    }
    function success(data) {
        return { type: GET_COUNTRY_TAX_LIST_SUCCESS, data };
    }
    function failure(error) {
        return { type: GET_COUNTRY_TAX_LIST_FAILURE, error };
    }
};

// ============================= Add Country Tax ============================= //
export const addCountryTaxAction = (taxData) => {
    return dispatch => {
        dispatch(request());
        CountriesTaxService.addCountryTax(taxData).then(
            response => {
                if (response && response.result === 200 && response.message === "Success") {
                    dispatch(success(response.data));
                    popToast.show("Added Successfully");
                    dispatch(getCountryTaxListAction());
                } else if (response && response.message) {
                    dispatch(failure(response));
                    popToast.show(response.message || "Something went wrong while adding tax!");
                }
            },
            error => {
                if (error && error.status_message) {
                    popToast.show(error.status_message);
                } else {
                    popToast.show("Something went wrong while adding tax!");
                }
                console.log("error dispatched for API error!", error);
                dispatch(failure(error));
            }
        );
    };

    function request() {
        return { type: ADD_COUNTRY_TAX_REQUEST };
    }
    function success(data) {
        return { type: ADD_COUNTRY_TAX_SUCCESS, data };
    }
    function failure(error) {
        return { type: ADD_COUNTRY_TAX_FAILURE, error };
    }
};

// ============================= Update Country Tax ============================= //
export const updateCountryTaxAction = (taxData, taxId) => {
    return dispatch => {
        dispatch(request());
        CountriesTaxService.updateCountryTax(taxData, taxId).then(
            response => {
                if (response && response.message === "Success") {
                    dispatch(success(response.data));
                    popToast.show("Updated Successfully");
                    dispatch(getCountryTaxListAction());
                } else if (response && response.message === "Failure") {
                    dispatch(failure(response));
                    popToast.show("Update Tax Error : " + response.Message || "Something went wrong while updating tax!");
                }
            },
            error => {
                if (error && error.status_message) {
                    popToast.show(error.status_message);
                } else {
                    popToast.show("Something went wrong while updating tax!");
                }
                console.log("error dispatched for API error!", error);
                dispatch(failure(error));
            }
        );
    };

    function request() {
        return { type: UPDATE_COUNTRY_TAX_REQUEST };
    }
    function success(data) {
        return { type: UPDATE_COUNTRY_TAX_SUCCESS, data };
    }
    function failure(error) {
        return { type: UPDATE_COUNTRY_TAX_FAILURE, error };
    }
};

// ============================= Delete Country Tax ============================= //
export const deleteCountryTaxAction = taxId => {
    return dispatch => {
        dispatch(request());
        CountriesTaxService.deleteCountryTax(taxId).then(
            response => {
                if (response && response.message === "Success") {
                    dispatch(success(response.data));
                    popToast.show("Deleted Successfully");
                    dispatch(getCountryTaxListAction());
                } else if (response && response.message === "Failure") {
                    dispatch(failure(response));
                    popToast.show("Delete Country Tax Error : " + response.Message || "Something went wrong while deleting country tax!");
                }
            },
            error => {
                if (error && error.status_message) {
                    popToast.show(error.status_message);
                } else {
                    popToast.show("Something went wrong while deleting country tax!");
                }
                console.log("error dispatched for API error!", error);
                dispatch(failure(error));
            }
        );
    };

    function request() {
        return { type: DELETE_COUNTRY_TAX_REQUEST };
    }
    function success(data) {
        return { type: DELETE_COUNTRY_TAX_SUCCESS, data };
    }
    function failure(error) {
        return { type: DELETE_COUNTRY_TAX_FAILURE, error };
    }
};

// ============================= Toggle Add Country Tax Dialog ============================= //
export const toggleAddCountryTaxDialog = data => ({
    type: TOGGLE_ADD_COUNTRY_TAX_DIALOG,
    data
});
