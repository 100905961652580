import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { popToast } from "../../../../components/Toast/Toast";
import history from "../../../../util/history";
import { AuctionService } from "../../AuctionService";

const useAuctionNftCollection = (match) => {
    const [contractObj, setContractObj] = useState()
    const [contracts, setContracts] = useState([]);

    useEffect(() => {
        getAllNftContracts();
        setContractObj({
            AuctionId: match.params.auctionId,
            ContractName: "",
            ContractSymbol: "",
            ContractAddress: match.params.contractAddress ? undefined : match.params.contractAddress,
            IsNewContract: false
        })
    }, []);

    const getAllNftContracts = async () => {
        AuctionService.getSmartContracts(match.params.type).then(resp => {
            if (resp && resp.result === 200) {
                setContracts(resp.data);

            } else if (resp && resp.result === 400) {
                popToast.show(resp.message);
            }
        })
    };

    const onClickSubmit = (isNew) => {
        if (isValidContractObject(isNew)) {
            let requestObj = contractObj;
            if (isNew === true) {
                requestObj.IsNewContract = true;
                requestObj.ContractAddress = undefined;
            }
            AuctionService.createSmartContract(requestObj).then(resp => {
                if (resp && resp.result === 200) {
                    history.push(`/auctions/summary/${match.params.auctionId}`);

                } else if (resp && resp.result === 400) {
                    popToast.show(resp.message);
                }
            })
        }
    };

    const isValidContractObject = (isNew) => {
        if (isNew === true) {
            if (isEmpty(contractObj.ContractName)) {
                popToast.show("Please enter Contract Name");
                return false;
            }
            else if (isEmpty(contractObj.ContractSymbol)) {
                popToast.show("Please enter Contract Symbol");
                return false;
            }
        }
        else {
            if (isEmpty(contractObj.ContractAddress)) {
                return false;
            }
        }
        return true;
    };

    const onSelectContractDropdown = (value) => {
        setContractObj(s => ({ ...s, ContractAddress: value }));
    }

    const onGoBack = () => {
        history.push(`/auctions/details/${match.params.auctionId}`)
    }

    const onHandleInputChange = (e) => {
        const { name, value } = e.target;
        setContractObj((s) => ({ ...s, [name]: value }));
    };


    return {
        contracts,
        contractObj,
        onClickSubmit,
        onSelectContractDropdown,
        onGoBack,
        onHandleInputChange
    }

};

export default useAuctionNftCollection;