
import React, { useMemo, useState } from "react";
import { ButtonContainer, CloseIcon, ColumFlexDiv, ConfirmationButtonWrapperDiv, ConfirmationContainerDiv, ConfirmationMessage, CountFlexDiv, DateDiv, DeleteButton, EditButton, LinksDiv, ReelsDescription, ReelsSummaryContainer, ReelsTitle, SecondPart, TagsDiv, UpperPart, ViewCountContainer } from "./AddReelsVideos.styled";
import moment from "moment";
import { ReelsVideoService } from "../ReelsVideoService";
import { Dialog, DialogContent, DialogTitle, Slide } from "@material-ui/core";
import { popToast } from "../../../components/Toast/Toast";
import propertiesList from "../../../properties.json";
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ViewReelsSummary = ({ show, handleClose, data, editReels, setShowViewSummary }) => {
    const fontEndUrl = propertiesList.fontEndUrl;
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);

    const deleteReels = () => {
        try {
            setDeleteLoading(true);
            ReelsVideoService.deleteReelsVideo(data._id).then(deleteResp => {
                if (deleteResp && deleteResp.result === 200) {
                    handleClose();
                    setShowConfirmation(false);
                }
                else if (deleteResp && deleteResp.result === 400) {
                    popToast.show(deleteResp.message);
                }

                setDeleteLoading(false);
            })

        } catch (error) {
            setDeleteLoading(false);
        }

    }

    const body = useMemo(() => {

        return (
            <>
                {data && <ReelsSummaryContainer>

                    <UpperPart>
                        <ReelsTitle>{data.title}</ReelsTitle>
                        <ReelsDescription>{data.description}</ReelsDescription>
                        <DateDiv>
                            <ColumFlexDiv>
                                <label>Duration</label>
                                <span>{data.videoDuration} Sec</span>
                            </ColumFlexDiv>
                            <ColumFlexDiv>
                                <label>Uploaded on</label>
                                <span>{moment(data.createdDate).format("DD MMM YYYY")}</span>
                            </ColumFlexDiv>
                        </DateDiv>
                        <LinksDiv>
                            <ColumFlexDiv>
                                <label>Links</label>
                                <span>{data.redirectionPageName === "ARTIST_PROFILE"
                                    ? `${fontEndUrl}artist/${data.redirectionSlugId}`
                                    : `${fontEndUrl}art/${data.redirectionSlugId}`}</span>
                            </ColumFlexDiv>
                        </LinksDiv>
                        <TagsDiv>
                            <ColumFlexDiv>
                                <label>Tags</label>
                                <span>{data.tags}</span>
                            </ColumFlexDiv>
                        </TagsDiv>
                    </UpperPart>
                    <SecondPart>
                        <ButtonContainer>
                            <EditButton variant="outline-dark" onClick={() => editReels(data)}>
                                EDIT
                            </EditButton>
                            <DeleteButton disabled={deleteLoading} variant="outline-dark" onClick={() => {
                                setShowConfirmation(true);
                                setShowViewSummary(false)
                            }}>
                                DELETE
                            </DeleteButton>
                        </ButtonContainer>
                        <ViewCountContainer>
                            <CountFlexDiv>
                                <label>Views</label>
                                <span>{data.viewCount}</span>
                            </CountFlexDiv>
                            <CountFlexDiv>
                                <label>Likes</label>
                                <span>{data.likesCount}</span>
                            </CountFlexDiv>
                            {/* <ColumFlexDiv>
                                <label>Comments</label>
                                <span>{data.commentCount}</span>
                            </ColumFlexDiv> */}
                            {/* <ColumFlexDiv>
                                <label>Shares</label>
                                <span>{data.shareCount}</span>
                            </ColumFlexDiv> */}
                        </ViewCountContainer>
                    </SecondPart>

                </ReelsSummaryContainer>}
            </>
        )
    }, [data])

    const DeleteVideoConfirmation = () => {
        return (
            <ConfirmationContainerDiv>
                <ConfirmationMessage>
                    Are you sure want to delete “{data && data.title}”?
                </ConfirmationMessage>
                <ConfirmationButtonWrapperDiv>
                    <EditButton variant="outline-dark" onClick={() => {
                        handleClose()
                        setShowConfirmation(false);
                    }}>
                        CANCEL
                    </EditButton>
                    <DeleteButton loading={deleteLoading} onClick={() => { deleteReels() }}>
                        YES
                    </DeleteButton>
                </ConfirmationButtonWrapperDiv>
            </ConfirmationContainerDiv>
        )
    }

    return (
        <>
            <Dialog
                fullWidth
                open={show}
                onClose={handleClose}
                TransitionComponent={Transition}
                BackdropProps={{ classes: { root: "dialog-backdrop" } }}
                className="add-coupon-dialog"
            >
                <img className="close-btn" onClick={() => handleClose(false)} src={require("../../../assets/images/common/Close_h.svg")} alt="" />

                <DialogContent className="add-coupon-content">
                    {body}
                </DialogContent>
            </Dialog>


            <Dialog
                fullWidth
                open={showConfirmation}
                onClose={handleClose}
                TransitionComponent={Transition}
                BackdropProps={{ classes: { root: "dialog-backdrop" } }}
                className="add-coupon-dialog"
            >
                <img className="close-btn" onClick={() => handleClose(false)} src={require("../../../assets/images/common/Close_h.svg")} alt="" />

                <DialogContent className="add-coupon-content">
                    {DeleteVideoConfirmation()}
                </DialogContent>
            </Dialog>

        </>
    )
}

export default ViewReelsSummary;