import {
	GET_ARTWORKSLIST_SUCCESS,
	GET_ARTWORKSLIST_FAILURE,
	GET_ARTWORKSLIST_REQUEST,
	UPDATE_PRODUCT_STATUS_REQUEST,
	UPDATE_PRODUCT_STATUS_SUCCESS,
	UPDATE_PRODUCT_STATUS_FAILURE,
	DELETE_ARTWORK_REQUEST,
	DELETE_ARTWORK_SUCCESS,
	DELETE_ARTWORK_FAILURE,
	TOGGLE_CHANGE_PRODUCT_STATUS,
	GET_ARTWORKS_DOWNLOAD_REQUEST,
	GET_ARTWORKS_DOWNLOAD_SUCCESS,
	GET_ARTWORKS_DOWNLOAD_FAILURE,
	SET_ARTWORK_FEATURED_REQUEST,
	SET_ARTWORK_FEATURED_SUCCESS,
	SET_ARTWORK_FEATURED_FAILURE,
	BULK_ARTWORK_UPLOAD,
	BULK_ARTWORK_UPLOAD_SUCCESS,
	BULK_ARTWORK_UPLOAD_FAILURE
} from "../actions/ActionsTypes";
import { isEmpty } from "lodash";

const initialState = {
	artworksListLoader: false,
	savedArtworksList: [],
	savedArtworkListCount: 0,
	updateStatusLoader: false,
	updateStatusData: null,
	deleteArtworkLoader: false,
	deleteArtworkData: null,
	artworksDownloadLoader: false,
	artworksDownloadData: null,
	featureLoader: false,
	featureData: null,
	bulkUploadData: [],
	isUploadProgress: false
};

const manageArtworksReducer = (state = initialState, action = {}) => {
	switch (action.type) {
		case GET_ARTWORKSLIST_REQUEST:
			return {
				...state,
				artworksListLoader: true,
			};

		case GET_ARTWORKSLIST_SUCCESS:
			state = {
				...state,
				artworksListLoader: false,
				savedArtworksList: action.data.productList,
				savedArtworkListCount: action.data.count
			};
			return state;

		case GET_ARTWORKSLIST_FAILURE:
			return {
				...state,
				artworksListLoader: false,
				savedArtworkListCount: 0,
				savedArtworksList: []
			};

		case UPDATE_PRODUCT_STATUS_REQUEST:
			return {
				...state,
				updateStatusLoader: true,
				updateStatusData: null
			};

		case UPDATE_PRODUCT_STATUS_SUCCESS:
			state = {
				...state,
				updateStatusLoader: false,
				updateStatusData: action.data
			};
			return state;

		case UPDATE_PRODUCT_STATUS_FAILURE:
			return {
				...state,
				updateStatusLoader: false,
				updateStatusData: null
			};

		case DELETE_ARTWORK_REQUEST:
			return {
				...state,
				deleteArtworkLoader: true,
				deleteArtworkData: null
			};

		case DELETE_ARTWORK_SUCCESS:
			state = {
				...state,
				deleteArtworkLoader: false,
				deleteArtworkData: action.data
			};
			return state;

		case DELETE_ARTWORK_FAILURE:
			return {
				...state,
				deleteArtworkLoader: false,
				deleteArtworkData: null
			};

		case GET_ARTWORKS_DOWNLOAD_REQUEST:
			return {
				...state,
				artworksDownloadLoader: true
			};

		case GET_ARTWORKS_DOWNLOAD_SUCCESS:
			state = {
				...state,
				artworksDownloadLoader: false,
				artworksDownloadData: action.data
			};
			return state;

		case GET_ARTWORKS_DOWNLOAD_FAILURE:
			return {
				...state,
				artworksDownloadLoader: false,
				artworksDownloadData: null
			};

		case SET_ARTWORK_FEATURED_REQUEST:
			return {
				...state,
				featureLoader: true
			};

		case SET_ARTWORK_FEATURED_SUCCESS:
			state = {
				...state,
				featureLoader: false,
				featureData: action.data
			};
			return state;

		case SET_ARTWORK_FEATURED_FAILURE:
			return {
				...state,
				featureLoader: false,
				featureData: null
			};
		case BULK_ARTWORK_UPLOAD:
			return {
				...state,
				isUploadProgress: true,

			};
		case BULK_ARTWORK_UPLOAD_SUCCESS:
			return {
				...state,
				isUploadProgress: false,
				bulkUploadData: action.data
			};
		case BULK_ARTWORK_UPLOAD_FAILURE:
			console.log("🚀 ~ file: ManageArtworksReducer.js ~ line 168 ~ manageArtworksReducer ~ action", action)

			return {
				...state,
				isUploadProgress: false,
				bulkUploadData: action.error.data
			}

		// --------- Toggle Change Product Status --------- //
		case TOGGLE_CHANGE_PRODUCT_STATUS:
			// console.log('action.data :', action.data);
			// console.log('action.status :', action.status);
			if (state.savedArtworksList && !isEmpty(action.data)) {
				let updatedItems = state.savedArtworksList.map(item => {
					if (item.rfid === action.data.rfid) {
						if (action.status === 6 || action.status === 2) {
							action.data["status"] = action.status
							action.data["isApproved"] = true
						}
						else if (action.status === 10 || action.status === 8) {
							action.data["status"] = action.status
							action.data["isRejected"] = true
						}
						// console.log('data55 :', action.data);
						return action.data;
					}
					else
						return item;
				});
				// console.log('updatedItems :', updatedItems);
				state = {
					...state,
					savedArtworksList: updatedItems
				};
			}
			return state;

		default:
			return state;
	}
};

export default manageArtworksReducer;
