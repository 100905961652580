import { Checkbox, Popover } from "antd";
import React, { useEffect } from "react";
import { useFilters, useTable } from "react-table";
import { AddBannerButton, BlogBannerButtonContainer } from "../BlogBanner/BlogBanner.styled";
import ReactTable from "../ReactTable/ReactTable";
import AddBlogArticles from "./AddBlogArticles/AddBlogArticles";
import { BlogArticleTableWrapper, IsActiveCheckBoxWrapper } from "./BlogArticles.styled";
import useBlogArticles from "./useBlogArticles";
import moment from "moment";

const BlogArticles = ({ location, history }) => {
    const { blogList, pageCount, pageIndex, pageSize, openAddArticleModal, editRecord, filterState, onClickAddBanner,
        setPageSize, fetchData, nextPage, onClickCancelAddArticle, onClickAddArticelButton, onClickEditArticelButton,
        loading, getBlogs, DeleteArticle, UpdateArticle, updateFilterState,
        BoolenColumnFilter, DateColumnFilter } = useBlogArticles(location, history);



    const content = (record) => (
        <div>
            <div className="action-span" onClick={() => onClickEditArticelButton(record)}>
                <img src={require("../../../../assets/images/common/Edit_New.svg")} alt="edit" />
                <p className="pop-item">Edit</p>
            </div>
            <div className="action-span" onClick={() => DeleteArticle(record)} >
                <img src={require("../../../../assets/images/common/delete_active.svg")} alt="edit" />
                <p className="pop-item">Delete</p>
            </div>
        </div>
    );

    const columns = React.useMemo(
        () => [

            {
                Header: 'IMAGE',
                accessor: 'thumbnail',
                maxWidth: 70,
                minWidth: 70,
                filterable: false,
                Cell: ({ cell: { value } }) => (
                    <img
                        src={value}

                        style={{ minWidth: "60px", width: "100%", height: "70px", objectFit: "cover" }}

                    />
                ),

            },
            {
                Header: 'TOPIC',
                accessor: 'topicInfo',
                // Use our custom `fuzzyText` filter on this column
                filter: 'topicSearch',
                filterable: true,
                Cell: ({ cell: { value } }) => value && value.topic,
            },
            {
                Header: 'TITLE',
                accessor: 'title',
                // Use our custom `fuzzyText` filter on this column
                filter: 'fuzzyText',
                filterable: true,
            },
            {
                Header: 'DATE',
                accessor: 'blogDate',
                filterable: true,
                Filter: DateColumnFilter,
                filter: 'date',
                Cell: ({ cell: { value, row } }) => <span>{moment(value).format("DD MMM YYYY h:mm:ss a")}</span>
            },
            {
                Header: 'AUTHOR',
                accessor: 'bloggerName',
                // Use our custom `fuzzyText` filter on this column
                filter: 'fuzzyText',
                filterable: true,
            },
            {
                Header: 'FEATURED ARTICLE',
                accessor: 'isFeatured',
                filterable: true,
                Filter: BoolenColumnFilter,
                Cell: ({ cell: { value, row } }) => (
                    <IsActiveCheckBoxWrapper>
                        <Checkbox checked={value} onChange={() => UpdateArticle(!value, row.original)} />
                    </IsActiveCheckBoxWrapper>
                )
            },
            {
                Header: 'ACTION',
                filterable: false,
                Cell: ({ cell: { row } }) => (
                    < Popover placement="bottom" content={content(row && row.original)} overlayClassName="manage-artworks-action-popover" >
                        <img className="action-image" src={require("../../../../assets/images/common/artworksActionIcon.svg")} alt="action" />
                    </Popover >
                )
            },
        ],
        [filterState]
    );

    return (
        <>
            <BlogArticleTableWrapper >
                <BlogBannerButtonContainer>
                    <AddBannerButton onClick={onClickAddBanner}>ADD ARTICLE</AddBannerButton>
                </BlogBannerButtonContainer>
                {loading === false &&
                    <ReactTable
                        columns={columns}
                        data={blogList}
                        filterState={filterState}
                        updateFilterState={updateFilterState}
                    />}
            </BlogArticleTableWrapper>

            {openAddArticleModal === true && <AddBlogArticles getBlogs={getBlogs} openAddArticleModal={openAddArticleModal} onClickCancelAddArticle={onClickCancelAddArticle} editRecord={editRecord} pageIndex={pageIndex} pageSize={pageSize} />}
        </>
    )
}

export default BlogArticles;