import {
    GET_SHIPMENT_PROVIDER_LIST_REQUEST, GET_SHIPMENT_PROVIDER_LIST_SUCCESS, GET_SHIPMENT_PROVIDER_LIST_FAILURE,
    ADD_SHIPMENT_PROVIDER_REQUEST, ADD_SHIPMENT_PROVIDER_SUCCESS, ADD_SHIPMENT_PROVIDER_FAILURE,
    UPDATE_SHIPMENT_PROVIDER_REQUEST, UPDATE_SHIPMENT_PROVIDER_SUCCESS, UPDATE_SHIPMENT_PROVIDER_FAILURE,
    DELETE_SHIPMENT_PROVIDER_REQUEST, DELETE_SHIPMENT_PROVIDER_SUCCESS, DELETE_SHIPMENT_PROVIDER_FAILURE, TOGGLE_ADD_SHIPMENT_PROVIDER_DIALOG
} from "../actions/ActionsTypes";

const initialState = {
    shipmentProviderListLoader: false,
    shipmentProviderList: [],
    shipmentProviderListCount: 0,
    addShipmentProviderLoader: false,
    addShipmentProviderData: null,
    updateShipmentProviderLoader: false,
    updateShipmentProviderData: null,
    deleteShipmentProviderLoader: false,
    deleteShipmentProviderData: null,
    addShipmentProviderDialog: false

};

const shipmentProviderReducer = (state = initialState, action = {}) => {
    switch (action.type) {

        // ======= Get Shipment Provider List ====== //
        case GET_SHIPMENT_PROVIDER_LIST_REQUEST:
            state = {
                ...state,
                shipmentProviderListLoader: true,
                shipmentProviderList: []
            };
            return state;

        case GET_SHIPMENT_PROVIDER_LIST_SUCCESS:
            state = {
                ...state,
                shipmentProviderListLoader: false,
                shipmentProviderList: action.data.shipmentProviderList,
                shipmentProviderListCount: action.data.count
            };
            return state;

        case GET_SHIPMENT_PROVIDER_LIST_FAILURE:
            state = {
                ...state,
                shipmentProviderListLoader: false,
                shipmentProviderList: [],
                shipmentProviderListCount: 0
            };
            return state;

        // ======= Add Shipment Provider ====== //
        case ADD_SHIPMENT_PROVIDER_REQUEST:
            state = {
                ...state,
                addShipmentProviderLoader: true,
                addShipmentProviderData: null
            };
            return state;

        case ADD_SHIPMENT_PROVIDER_SUCCESS:
            state = {
                ...state,
                addShipmentProviderLoader: false,
                addShipmentProviderDialog: false,
                addShipmentProviderData: action.data
            };
            return state;

        case ADD_SHIPMENT_PROVIDER_FAILURE:
            state = {
                ...state,
                addShipmentProviderLoader: false,
                addShipmentProviderDialog: true,
                addShipmentProviderData: null
            };
            return state;

        // ======= Update Shipment Provider ====== //
        case UPDATE_SHIPMENT_PROVIDER_REQUEST:
            state = {
                ...state,
                addShipmentProviderLoader: true,
                updateShipmentProviderData: null
            };
            return state;

        case UPDATE_SHIPMENT_PROVIDER_SUCCESS:
            state = {
                ...state,
                addShipmentProviderLoader: false,
                addShipmentProviderDialog: false,
                updateShipmentProviderData: action.data
            };
            return state;

        case UPDATE_SHIPMENT_PROVIDER_FAILURE:
            state = {
                ...state,
                addShipmentProviderLoader: false,
                addShipmentProviderDialog: true,
                updateShipmentProviderData: null
            };
            return state;

        // ======= Delete Shipment Provider ====== //
        case DELETE_SHIPMENT_PROVIDER_REQUEST:
            state = {
                ...state,
                deleteShipmentProviderLoader: true,
                deleteShipmentProviderData: null
            };
            return state;

        case DELETE_SHIPMENT_PROVIDER_SUCCESS:
            state = {
                ...state,
                deleteShipmentProviderLoader: false,
                deleteShipmentProviderData: action.data
            };
            return state;

        case DELETE_SHIPMENT_PROVIDER_FAILURE:
            state = {
                ...state,
                deleteShipmentProviderLoader: false,
                deleteShipmentProviderData: null
            };
            return state;

        // --------- Toggle Dialog's --------- //
        case TOGGLE_ADD_SHIPMENT_PROVIDER_DIALOG:
            state = {
                ...state,
                addShipmentProviderDialog: action.data
            };
            return state;

        default:
            return state;
    }
};

export default shipmentProviderReducer;
