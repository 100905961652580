import { ForgotPasswordService } from '../../containers/Password/ForgotPassword/ForgotPassword.service.js';
import {
    SEND_OTP_REQUEST, SEND_OTP_SUCCESS, SEND_OTP_FAILURE, VALIDATE_OTP_REQUEST, VALIDATE_OTP_SUCCESS,
    VALIDATE_OTP_FAILURE, PASSWORD_CONFIRM_REQUEST, PASSWORD_CONFIRM_SUCCESS, PASSWORD_CONFIRM_FAILURE
} from './ActionsTypes';
import { popToast } from "../../components/Toast/Toast";


// <=========================Action function for send OTP=====>
export const sendOtpAction = (mailId) => {
    return dispatch => {
        dispatch(request());
        ForgotPasswordService.sendOtp(mailId)
            .then(response => {
                if (response.result === 200) {
                    dispatch(success(response));
                    popToast.show('OTP Sended!');
                    window.location.href = "/#/otp-verification/" + mailId.Email;
                }
                else {
                    dispatch(failure(response.message));
                    popToast.show('Send OTP Error : ' + response.message.value || 'Something went wrong while sending OTP!');

                }
            },
                error => {
                    if (error && error.status_message) {
                        popToast.show(error.status_message);
                    }
                    else {
                        popToast.show('Something went wrong while sending OTP!');
                    }
                    console.log('error dispatched for API error!', error);
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: SEND_OTP_REQUEST } }
    function success(data) { return { type: SEND_OTP_SUCCESS, data } }
    function failure(error) { return { type: SEND_OTP_FAILURE, error } }
}

// <=========================Action function for validate OTP=====>
export const validateOtpAction = (otpInput) => {
    return dispatch => {
        dispatch(request());
        ForgotPasswordService.validateOtp(otpInput)
            .then(response => {
                if (response.result === 200) {
                    dispatch(success(response));
                    window.location.href = "/#/change-password/" + otpInput.Email;
                }
                else if (response.result === 400) {
                    dispatch(success(response));
                    popToast.show('OTP Value Error')
                }
                else {
                    dispatch(failure(response.message));
                    popToast.show('Otp Error : ' + response.message || 'Something went wrong while submitting otp!');
                }
            },
                error => {
                    if (error && error.status_message) {
                        popToast.show(error.status_message);
                    }
                    else {
                        popToast.show('Something went wrong while submitting otp!');
                    }
                    console.log('error dispatched for API error!', error);
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: VALIDATE_OTP_REQUEST } }
    function success(data) { return { type: VALIDATE_OTP_SUCCESS, data } }
    function failure(error) { return { type: VALIDATE_OTP_FAILURE, error } }
}

// <=========================Action function for reset password=====>
export const resetPasswordAction = (resetInput) => {
    return dispatch => {
        dispatch(request());
        ForgotPasswordService.resetPassword(resetInput)
            .then(response => {
                if (response.result === 200) {
                    dispatch(success(response));
                    window.location.href = "/#/login";
                }
                else if (response.result === 400) {
                    dispatch(success(response));
                    popToast.show(response.message)
                }
                else {
                    dispatch(failure(response.message));
                    popToast.show('Reset Password Error : ' + response.message || 'Something went wrong while resetting password!');
                }
            },
                error => {
                    if (error && error.status_message) {
                        popToast.show(error.status_message);
                    }
                    else {
                        popToast.show('Something went wrong while resetting password!');
                    }
                    console.log('error dispatched for API error!', error);
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: PASSWORD_CONFIRM_REQUEST } }
    function success(data) { return { type: PASSWORD_CONFIRM_SUCCESS, data } }
    function failure(error) { return { type: PASSWORD_CONFIRM_FAILURE, error } }
}
