import { APIUrlConstants } from "../../util/apiUrl.constant";
import { APIService } from "../../util/api.service";

export class LoginService {

    // On Login
    static onLogin(credentials) {
        let url = APIUrlConstants.getApiUrl("login");
        return APIService.doPost(url, credentials, {}, false)
            .then((resp) => {
                if (resp) {
                    return resp;
                }
                throw resp;
            });
    }
    static validate2FAOTP(data) {
        let url = APIUrlConstants.getApiUrl("validate2FAOTP") + "?UserName=" + data.username + "&OTP=" + data.otp;
        return APIService.doPost(url, {}, {}, false)
            .then((resp) => {
                if (resp) {
                    return resp;
                }
                throw resp;
            });
    }
}
