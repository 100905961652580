
import { Button, Form } from "antd";
import styled, { css } from "styled-components";
import ReactHlsPlayer from "react-hls-player";

export const FormGroup = styled.div` 
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;

   input {
        border: .5px solid #000;
        border-radius: 3px;
        height: 30px;
   }

   textarea{
        border: .5px solid #000;
        border-radius: 3px;
   }

   .rti--container > input {
         border: none;

        &: focus  {
            border: none;

        }
   }
    
`
export const ORText = styled.div` 
    text-align: center;
    font-weight: 600;
    font-size: 15px;
`
export const UploadVideoDiv = styled.div` 
    height: 157px;
    border: 0.5px dashed rgba(0, 0, 0, 0.6);

    display: flex;
    justify-content: center;
    align-items: center;
     position: relative;
`;

export const TagInfo = styled.span` 
    font-size: 12px;
    font-style:italic
    
`

export const UploadVideoHereDiv = styled.div` 
    font-size: 10px;
    line-height: 120%;
    text-decoration-line: underline;
    color: #000000;
    opacity: 0.3;
    cursor: pointer;
`

export const UploadedVideoDiv = styled.div` 
    height: 157px;
    width: 105px;   
   
`;

export const VideoContent = styled(ReactHlsPlayer)` 
    height: 100%;
    width: 105px;
    object-fit: cover;
`;

export const VideoAddContent = styled.video` 
    height: 100%;
    width: 105px;
    object-fit: cover;
`;

export const RemoveButton = styled.span` 
    font-style: normal;
    font-weight: 500 !important;
    font-size: 10px !important;
    line-height: 127.69%;
    text-decoration-line: underline;
    color: #FF1D1D;
    cursor:pointer;

    position: absolute;
    right: 0;
    bottom: 0;
    padding: 10px;
`;

export const ButtonContainerDiv = styled.div` 
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
`;

export const SaveButton = styled(Button)` 
    border-radius: 0 !important;
    background-color: #000000 !important;
    color: #ffffff !important;

    &:hover, &:focus {
        background-color: #000000 !important;
        color: #ffffff !important;
    }
  
`;

export const CancelButton = styled(Button)` 
    border-radius: 0 !important;

      &: hover {
        background: #fff !important;
        color:#000 !important;
    }
`;
/* Summary */
export const ReelsSummaryContainer = styled.div` 
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding-bottom: 1rem;

   
`;

export const ReelsTitle = styled.h2` 
    font-size: 1rem !important;
    font-weight: 500;
`;

export const ReelsDescription = styled.p` 
    font-size: 12px !important;
`;

export const DateDiv = styled.div` 
    display: flex;
    gap: 3rem;
`;

export const ColumFlexDiv = styled.div` 
    display: flex;
    flex-direction: column;

    label{
        font-size: 12px;
        line-height: 15px;
        color: #000000;
        opacity: 0.6;
    }

    span {
        font-weight: 700 !important;
        font-size: 12px !important;
        line-height: 15px;
        color: #000000;
    }
`;

export const CountFlexDiv = styled.div` 
    display: flex;
    flex-direction: column;
    align-items: center;

    label{
        font-size: 12px;
        line-height: 15px;
        color: #000000;
        opacity: 0.6;
    }

    span {
        font-weight: 700 !important;
        font-size: 12px !important;
        line-height: 15px;
        color: #000000;
    }
`;


export const LinksDiv = styled.div` 
`;

export const TagsDiv = styled.div` 

`;

export const UpperPart = styled.div` 
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const SecondPart = styled.div` 
    display: flex;
    flex-direction: column;
    gap: 3rem;
`;

export const ButtonContainer = styled.div` 
    display: flex;
    gap: 0.5rem;

    &>* {
        flex: 1;
    }
`;

export const ViewCountContainer = styled.div` 
    display: flex;
    justify-content: space-around;
    align-items: center;
`;

export const EditButton = styled(Button)` 
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 0.5rem !important;
    
     border-radius: 0 !important;
     font-size: 12px !important;
     height: 30px !important

      &: hover {
        background: #fff !important;
        color:#000 !important;
        opacity: .8;
    }
`;

export const DeleteButton = styled(Button)` 

    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 0.5rem !important;

    border-radius: 0 !important;
    font-size: 12px !important;
    height: 30px !important

      &: hover {
        background: #fff !important;
        color:#000 !important;
        opacity: .8;
    }
`;

export const ConfirmationContainerDiv = styled.div` 

`;

export const ConfirmationMessage = styled.p` 

`;

export const ConfirmationButtonWrapperDiv = styled.div` 
    display: flex;
    gap: 10px;

    &>*{
        flex: 1;
    }
`