import {
	GET_ORDERS_LIST_REQUEST,
	GET_ORDERS_LIST_SUCCESS,
	GET_ORDERS_LIST_FAILURE,
	GET_ORDER_DETAILS_REQUEST,
	GET_ORDER_DETAILS_SUCCESS,
	GET_ORDER_DETAILS_FAILURE,
	UPDATE_ORDER_STATUS_REQUEST,
	UPDATE_ORDER_STATUS_SUCCESS,
	UPDATE_ORDER_STATUS_FAILURE,
	GET_ORDER_INVOICE_REQUEST,
	GET_ORDER_INVOICE_SUCCESS,
	GET_ORDER_INVOICE_FAILURE,
	REFUND_ORDER_AMOUNT_REQUEST,
	REFUND_ORDER_AMOUNT_SUCCESS,
	REFUND_ORDER_AMOUNT_FAILURE,
	GET_ORDER_WAYBILL_REQUEST,
	GET_ORDER_WAYBILL_SUCCESS,
	GET_ORDER_WAYBILL_FAILURE,
	GET_PICKUP_DETAILS_REQUEST,
	GET_PICKUP_DETAILS_SUCCESS,
	GET_PICKUP_DETAILS_FAILURE
} from "../actions/ActionsTypes";

const initialState = {
	ordersListLoader: false,
	ordersList: [],
	ordersListCount: 0,
	orderDetailsLoader: false,
	orderDetailsData: null,
	updateStatusLoader: false,
	updateStatusData: null,
	orderInvoiceLoader: false,
	orderInvoiceData: null,
	amountRefundLoader: false,
	amountRefundData: null,
	wayBillLoader: false,
	wayBillData: null,
	pickUpDataLoader: false,
	pickUpData: null
};

const ordersReducer = (state = initialState, action = {}) => {
	switch (action.type) {
		case GET_ORDERS_LIST_REQUEST:
			return {
				...state,
				ordersListLoader: true
			};

		case GET_ORDERS_LIST_SUCCESS:
			state = {
				...state,
				ordersListLoader: false,
				ordersList: action.data.orderList,
				ordersListCount: action.data.count
			};
			return state;

		case GET_ORDERS_LIST_FAILURE:
			return {
				...state,
				ordersListLoader: false,
				ordersList: [],
				ordersListCount: 0
			};

		case GET_ORDER_DETAILS_REQUEST:
			return {
				...state,
				orderDetailsLoader: true,
				orderDetailsData: null
			};

		case GET_ORDER_DETAILS_SUCCESS:
			state = {
				...state,
				orderDetailsLoader: false,
				orderDetailsData: action.data
			};
			return state;

		case GET_ORDER_DETAILS_FAILURE:
			return {
				...state,
				orderDetailsLoader: false,
				orderDetailsData: null
			};

		case UPDATE_ORDER_STATUS_REQUEST:
			return {
				...state,
				updateStatusLoader: true
			};

		case UPDATE_ORDER_STATUS_SUCCESS:
			state = {
				...state,
				updateStatusLoader: false,
				updateStatusData: action.data
			};
			return state;

		case UPDATE_ORDER_STATUS_FAILURE:
			return {
				...state,
				updateStatusLoader: false,
				updateStatusData: null
			};

		case GET_ORDER_INVOICE_REQUEST:
			return {
				...state,
				orderInvoiceLoader: true
			};

		case GET_ORDER_INVOICE_SUCCESS:
			state = {
				...state,
				orderInvoiceLoader: false,
				orderInvoiceData: action.data
			};
			return state;

		case GET_ORDER_INVOICE_FAILURE:
			return {
				...state,
				orderInvoiceLoader: false,
				orderInvoiceData: null
			};

		case REFUND_ORDER_AMOUNT_REQUEST:
			return {
				...state,
				amountRefundLoader: true
			};

		case REFUND_ORDER_AMOUNT_SUCCESS:
			state = {
				...state,
				amountRefundLoader: false,
				amountRefundData: action.data
			};
			return state;

		case REFUND_ORDER_AMOUNT_FAILURE:
			return {
				...state,
				amountRefundLoader: false,
				amountRefundData: null
			};

		case GET_ORDER_WAYBILL_REQUEST:
			return {
				...state,
				wayBillLoader: true
			};

		case GET_ORDER_WAYBILL_SUCCESS:
			state = {
				...state,
				wayBillLoader: false,
				wayBillData: action.data
			};
			return state;

		case GET_ORDER_WAYBILL_FAILURE:
			return {
				...state,
				wayBillLoader: false,
				wayBillData: null
			};

		case GET_PICKUP_DETAILS_REQUEST:
			return {
				...state,
				pickUpDataLoader: true
			};

		case GET_PICKUP_DETAILS_SUCCESS:
			state = {
				...state,
				pickUpDataLoader: false,
				pickUpData: action.data
			};
			return state;

		case GET_PICKUP_DETAILS_FAILURE:
			return {
				...state,
				pickUpDataLoader: false,
				pickUpData: null
			};

		default:
			return state;
	}
};

export default ordersReducer;
