import {
	GET_COLLECTIONS_LIST_REQUEST,
	GET_COLLECTIONS_LIST_SUCCESS,
	GET_COLLECTIONS_LIST_FAILURE,
	DELETE_COLLECTION_REQUEST,
	DELETE_COLLECTION_SUCCESS,
	DELETE_COLLECTION_FAILURE
} from "../actions/ActionsTypes";
import * as types from "../actions/ActionsTypes";

const initialState = {
	collectionListLoader: false,
	collectionsList: [],

	getCollectionItemsByIdLoader: false,
	collectionsItemsByIdList: [],

	deleteCollectionLoader: false,
	deleteCollectionData: null,

	categoryList: [],
	categoryLoader: false,
	parentArrayList: [],

	artistsList: [],
	categoryPropertyList: [],

	filteredArtworksLoader: false,
	filteredArtworks: [],

	deletedItemFromCollection: null,

	addingCollectionLoader: false
};

const collectionReducer = (state = initialState, action = {}) => {
	switch (action.type) {
		case GET_COLLECTIONS_LIST_REQUEST:
			return {
				...state,
				collectionListLoader: true
			};

		case GET_COLLECTIONS_LIST_SUCCESS:
			state = {
				...state,
				collectionListLoader: false,
				collectionsList: action.data
			};
			return state;

		case GET_COLLECTIONS_LIST_FAILURE:
			return {
				...state,
				collectionListLoader: false,
				collectionsList: []
			};

		case types.GET_COLLECTIONS_LIST_BY_ID_REQUEST:
			return {
				...state,
				getCollectionItemsByIdLoader: true
			};

		case types.GET_COLLECTIONS_LIST_BY_ID_SUCCESS:
			state = {
				...state,
				getCollectionItemsByIdLoader: false,
				collectionsItemsByIdList: action.data
			};
			return state;

		case types.GET_COLLECTIONS_LIST_BY_ID_FAILURE:
			return {
				...state,
				getCollectionItemsByIdLoader: false,
				collectionsItemsByIdList: []
			};

		case DELETE_COLLECTION_REQUEST:
			return {
				...state,
				deleteCollectionLoader: true
			};

		case DELETE_COLLECTION_SUCCESS:
			state = {
				...state,
				deleteCollectionLoader: false,
				deleteCollectionData: action.data
			};
			return state;

		case DELETE_COLLECTION_FAILURE:
			return {
				...state,
				deleteCollectionLoader: false,
				deleteCollectionData: null
			};

		// Delete item from collection
		case types.DELETE_COLLECTION_ITEM_REQUEST:
			return {
				...state,
				deletedItemFromCollection: null
			};

		case types.DELETE_COLLECTION_ITEM_SUCCESS:
			state = {
				...state,
				deletedItemFromCollection: action.data
			};
			return state;

		case types.DELETE_COLLECTION_ITEM_FAILURE:
			return {
				...state,
				deletedItemFromCollection: null
			};

		// ========== Get Category [cascade menu - add slider] ========== //
		case types.CASCADE_MENU_REQUEST:
			state = {
				...state,
				categoryList: [],
				categoryLoader: true
			};
			return state;
		case types.CASCADE_MENU_SUCCESS:
			state = {
				...state,
				categoryList: action.response,
				categoryLoader: false
			};
			return state;
		case types.CASCADE_MENU_FAILURE:
			state = {
				...state,
				categoryList: [],
				categoryLoader: false
			};
			return state;

		// ======================== Get Artists ======================= //
		case types.GET_ARTISTS_REQUEST:
			state = {
				...state,
				artistsList: []
			};
			return state;
		case types.GET_ARTISTS_SUCCESS:
			state = {
				...state,
				artistsList: action.data
			};
			return state;
		case types.GET_ARTISTS_FAILURE:
			state = {
				...state,
				artistsList: []
			};
			return state;
		//  ======================= Filters ===================== //
		case types.GET_FILTER_CATEGORY_PROPERTIES_REQUEST:
			state = {
				...state,
				categoryPropertyList: []
			};
			return state;
		case types.GET_FILTER_CATEGORY_PROPERTIES_SUCCESS:
			state = {
				...state,
				categoryPropertyList: action.data
			};
			return state;
		case types.GET_FILTER_CATEGORY_PROPERTIES_FAILURE:
			state = {
				...state,
				categoryPropertyList: []
			};
			return state;
		// ================ Search ================
		case types.SEARCH_ARTWORKS_REQUEST:
			state = {
				...state,
				filteredArtworksLoader: true
			};
			return state;
		case types.SEARCH_ARTWORKS_SUCCESS:
			state = {
				...state,
				filteredArtworksLoader: false,
				filteredArtworks: action.data || []
			};
			return state;
		case types.SEARCH_ARTWORKS_FAILURE:
			state = {
				...state,
				filteredArtworksLoader: false,
				filteredArtworks: []
			};
			return state;

		// ============= Add Collection ============= //
		case types.ADD_COLLECTION_REQUEST:
			state = {
				...state,
				addingCollectionLoader: true
			};
			return state;
		case types.ADD_COLLECTION_SUCCESS:
			state = {
				...state,
				addingCollectionLoader: false
			};
			return state;
		case types.ADD_COLLECTION_FAILURE:
			state = {
				...state,
				addingCollectionLoader: false
			};
			return state;

		// ========== Access reducer states ========== //
		// Accessing reducer states through a single action
		case types.SET_COLLECTIONS_REDUCER_STATES:
			state = {
				...state,
				[action.data.state]: action.data.value
			};
			return state;

		case types.SET_COLLECTIONS_INITIALIZE_FILTER_STATES:
			state = {
				...state,
				parentArrayList: [],
				artistsList: [],
				categoryPropertyList: [],
				filteredArtworks: []
			};
			return state;

		default:
			return state;
	}
};

export default collectionReducer;
