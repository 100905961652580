import { APIUrlConstants } from "../../util/apiUrl.constant";
import { APIService } from "../../util/api.service";

export class LookupService {
	static getAllPropertyValues(body) {
		let url = APIUrlConstants.getApiUrl("getAllLookUp");

		return APIService.doPost(url, body).then((resp) => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	static createProperty(body, propertyType) {
		let url = "";
		if (propertyType === "subject") {
			url = APIUrlConstants.getApiUrl("insertSubject");
		} else if (propertyType === "style") {
			url = APIUrlConstants.getApiUrl("insertStyle");
		} else if (propertyType === "materials") {
			url = APIUrlConstants.getApiUrl("insertMaterials");
		} else if (propertyType === "medium") {
			url = APIUrlConstants.getApiUrl("insertMedium");
		} else if (propertyType === "collection_category") {
			url = `${APIUrlConstants.getApiUrl("lookUpCollectionCategory")}?Name=${body.name}`;
		}

		return APIService.doPost(url, body).then((resp) => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	static updateProperty(body, propertyType) {
		let url = "";
		if (propertyType === "subject") {
			url = APIUrlConstants.getApiUrl("updateSubject");
		} else if (propertyType === "style") {
			url = APIUrlConstants.getApiUrl("updateStyle");
		} else if (propertyType === "materials") {
			url = APIUrlConstants.getApiUrl("updateMaterials");
		} else if (propertyType === "medium") {
			url = APIUrlConstants.getApiUrl("updateMedium");
		} else if (propertyType === "collection_category") {
			url = `${APIUrlConstants.getApiUrl("lookUpCollectionCategory")}?Name=${body.name}&SlugId=${body.slugId}&_id=${body._id}`;
		}

		return APIService.doPut(url, body).then((resp) => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}
	static deleteProperty(id, propertyType) {
		let url = "";
		if (propertyType === "subject") {
			url = `${APIUrlConstants.getApiUrl("deleteSubject")}/${id}`;
		} else if (propertyType === "style") {
			url = `${APIUrlConstants.getApiUrl("deleteStyle")}/${id}`;
		} else if (propertyType === "materials") {
			url = `${APIUrlConstants.getApiUrl("deleteMaterials")}/${id}`;
		} else if (propertyType === "medium") {
			url = `${APIUrlConstants.getApiUrl("deleteMedium")}/${id}`;
		} else if (propertyType === "collection_category") {
			url = `${APIUrlConstants.getApiUrl("lookUpCollectionCategory")}/${id}`;
		}
		return APIService.doDelete(url).then((resp) => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}
}
