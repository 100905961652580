import React from "react";
import { size } from "lodash";
import styled from "styled-components";
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";

const ViewMoreLess = ({ arr = [], viewSize = 5, showView = false, setShowView }) => {

	const YearEventTableViewMoreWrapper = styled.div`
		display: flex;
		justify-content: center;
		margin-top: 1rem;
		margin-bottom: 1.5rem;
		p {
			font-size: 13px;
			font-weight: 500;
			color: #58514b;
			cursor: pointer;
			margin: 0;
		}
	`;

	return (
		size(arr) > viewSize && (
			<YearEventTableViewMoreWrapper>
				<p
					onClick={() => {
						setShowView(!showView);
					}}
				>
					{showView ? "View Less" : "View More"}
					{showView ? <AiOutlineArrowUp style={{ marginleft: "5px" }} /> : <AiOutlineArrowDown style={{ marginleft: "5px" }} />}
				</p>
			</YearEventTableViewMoreWrapper>
		)
	);
};

export default ViewMoreLess;
