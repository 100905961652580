/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import { SelectInput, SpanFloater } from "../../ArtistProfile.styled";
import { authorizePost, authorizePut } from "../../../../../util/apiConfig";
import { popToast } from "../../../../../components/Toast/Toast";
import FloatingLabeledInput from "../../../../../components/FloatingLabeledInput/FloatingLabeledInput";
import StyledButton from "../../../../../components/StyledButton/StyledButton";
import { AddNewEventBody, AddNewEventFooter } from "../EventContainer/AddNewEventModal/AddNewEventModal.styled";

const ArtCollectionModel = ({ onLoad, onToggle, type, data, userId }) => {
	const [values, setValues] = useState();
	const [isLoading, setIsLoading] = useState(false);

	const year = new Date().getFullYear();
	const yearList = Array.from(new Array(121), (val, index) => year - index);
	const [setYearObj, setSetYearObj] = useState();

	// On change inputs
	const updateValues = (newValue) => {
		setValues({ ...values, ...newValue });
	};

	useEffect(() => {
		if (type === "edit") {
			updateValues({ title: data.collection, location: data.country, year: data.year });
		} else {
			updateValues({ title: "", location: "", year: "" });
		}

		if (yearList) {
			var yearOption = [];
			yearList.map((year, index) => {
				yearOption.push({
					name: year,
					value: year,
				});
			});
			setSetYearObj(yearOption);
		}
	}, []);

	const onClickSave = () => {
		// values.eventImage = media.media;

		if (values.year !== "" && values.title !== "" && values.location !== "") {
			setIsLoading(true);
			var objData = {
				Year: values.year,
				Collection: values.title,
				Country: values.location,
			};
			let token = localStorage.getItem('authToken');
			if (type === "edit") {
				authorizePut(`UserManagment/api/Artists/UpdateArtCollections?CollectionID=${data._id}`, objData, token).then((responseJson) => {
					if (responseJson.resultCode === 1) {

						popToast.show("Updated Successfully", "success");
						onLoad();
						onToggle();
						updateValues({ title: "" });
						updateValues({ year: "" });
						updateValues({ location: "" });
					} else {
						popToast.show(responseJson.error.message, "error");
					}
					setIsLoading(false);
				});
			} else {
				authorizePost(`UserManagment/api/Artists/AddArtCollections?ManagedArtistId=${userId}`, objData, token).then((responseJson) => {
					if (responseJson.resultCode === 1) {

						popToast.show("Added Successfully", "success");
						onLoad();
						onToggle();
						updateValues({ title: "" });
						updateValues({ year: "" });
						updateValues({ location: "" });
					} else {
						popToast.show(responseJson.error.message, "error");
					}
					setIsLoading(false);
				});
			}
		} else {
			popToast.show("Please fill the all fields", "error");
		}
	};

	return (
		<>
			{values && <AddNewEventBody>
				<FloatingLabeledInput
					label={"Collection"}
					value={values.title}
					onChange={(e) => updateValues({ title: e.target.value })}
				/>

				<div style={{ position: "relative", width: "100%" }}>
					<SpanFloater>{"Year"}</SpanFloater>
					<SelectInput
						style={{
							height: 40,
							width: "100%",
							marginTop: 15,
						}}
						showSearch
						optionFilterProp="children"
						value={values.year}
						onChange={(e) => {
							updateValues({ year: e });
						}}
					>
						{yearList.map((year, index) => {
							return (
								<option key={`year${index}`} value={year}>
									{year}
								</option>
							);
						})}
					</SelectInput>
				</div>

				<FloatingLabeledInput
					label={"Location"}
					value={values.location}
					onChange={(e) => updateValues({ location: e.target.value })}
				/>
			</AddNewEventBody>}
			<AddNewEventFooter>
				<StyledButton
					loading={isLoading}
					disabled={isLoading}
					variant="invert"
					height="40px"
					width="10rem"
					fontSize="14px"
					margin="auto 0"
					onClick={() => onClickSave()}
				>
					{isLoading ? "Saving" : "Save"}
				</StyledButton>
			</AddNewEventFooter>
		</>
	);
};
export default ArtCollectionModel;
