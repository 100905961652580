import React from "react";
import Topbar from "../../components/Topbar/Topbar";
import Root from "../Root/Root";
import { ExportExcelButton, SearchHistoryPagination, UserSearchHistoryTable, UserSearchHistoryTableWrapper, UtilityButtonWrapper } from "./UserSearchHistory.styled";
import useUserSearchHistory from "./useUserSearchHistory";
import { Pagination } from "antd";
import { isEmpty } from "lodash";
import moment from "moment";
const UserSearchHistory = () => {
    const { exportUserSearchHistoryList, onSelectPageNumber, onChangeSearch, totalCount, downLoadUserSearchHistoryLoader, getUserSearchHistoryLoader, userSearchObjects, filter } = useUserSearchHistory();

    const columns = [
        { title: 'User Name', dataIndex: 'userName', key: 'userName' },
        { title: 'Email', dataIndex: 'email', key: 'email' },
        { title: 'Keyword', dataIndex: 'keyword', key: 'keyword' },
        {
            title: 'Search Date', dataIndex: 'searchDate', key: 'searchDate',
            render: (text) => (moment(text).format("DD MMM YYYY"))
        },

    ];

    return (
        <>
            <Root>
                <Topbar title={"User Search History"} showSearch searchValue={filter.keyword} onChangeSearch={onChangeSearch} showBreadCrumbs showHead />
                <UserSearchHistoryTableWrapper>
                    <UtilityButtonWrapper>
                        <ExportExcelButton loading={downLoadUserSearchHistoryLoader} onClick={exportUserSearchHistoryList}>
                            Export To Excel
                        </ExportExcelButton>
                    </UtilityButtonWrapper>
                    <UserSearchHistoryTable
                        rowKey={(record) => record.artworkId}
                        columns={columns}
                        dataSource={userSearchObjects}
                        bordered
                        pagination={false}
                        sticky
                        loading={getUserSearchHistoryLoader}
                    />
                    {!isEmpty(userSearchObjects) && <SearchHistoryPagination
                        current={parseInt(filter.pageNum)}
                        total={totalCount}
                        pageSize={filter.pageSize}
                        onChange={onSelectPageNumber}

                    />}
                </UserSearchHistoryTableWrapper>
            </Root>
        </>
    )
}

export default UserSearchHistory;