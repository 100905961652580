import React, { useEffect } from "react";
import matchSorter from 'match-sorter';
import moment from 'moment';
import { useFilters, usePagination, useTable } from "react-table";
import { PageNextButtonWrapper, PageSelect, PaginationSection, ReactTableWrapper, TableFilterDiv } from "./ReactTable.styled";

const ReactTable = ({
    columns,
    data,
    filterState,
    updateFilterState
    // nextPage,
    // pageIndex,
    // pageCount,
    // pageSize,
    // setPageSize,
    //pageCount
}) => {

    const DefaultColumnFilter = ({
        column: { filterValue, preFilteredRows, setFilter, id }, ...props
    }) => {
        const count = preFilteredRows.length;
        let searchText = filterState && filterState[id];
        //setFilter(searchText)

        return (
            <input
                value={filterValue || ''}
                onChange={e => {
                    setFilter(e.target.value || undefined);
                    updateFilterState(id, e.target.value) // Set undefined to remove the filter entirely
                }}
                placeholder={`Search ${count} records...`}
            />
        )
    }

    const fuzzyTextFilterFn = (rows, id, filterValue) => {
        return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
    }

    const filterTypes = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true
                })
            },
            topicSearch: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id] && row.values[id].topic;
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true
                })
            },
            date: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = moment(row.values[id]).startOf("day");
                    const filterDate = moment(filterValue).startOf("day");
                    return moment(rowValue).isSame(filterDate);
                })
            },
        }),
        []
    )

    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    )
    // Use the state and functions returned from useTable to build your UI
    const {

        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,

        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },

    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: filterState && filterState.PageNo || 0 }, // Pass our hoisted table state
            manualPagination: false,
            defaultColumn, // Be sure to pass the defaultColumn option
            filterTypes,

        },
        useFilters,
        usePagination
    )

    useEffect(() => { updateFilterState("PageNo", pageIndex) }, [pageIndex])


    // Render the UI for your table
    return (
        <ReactTableWrapper>
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}>
                                    {column.render('Header')}
                                    {/* Render the columns filter UI */}
                                    <TableFilterDiv>{column.filterable === true ? column.render('Filter') : null}</TableFilterDiv>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>

            </table>

            <PaginationSection>
                <PageNextButtonWrapper>
                    <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                        {'<<'}
                    </button>{' '}
                    <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                        {'<'}
                    </button>{' '}
                    <button onClick={() => nextPage()} disabled={!canNextPage}>
                        {'>'}
                    </button>{' '}
                    <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                        {'>>'}
                    </button>{' '}
                </PageNextButtonWrapper>
                <span>
                    Page{' '}
                    <strong>
                        {pageIndex + 1} of {pageOptions && pageOptions.length}
                    </strong>{' '}
                </span>
                <span>
                    | Go to page:{' '}
                    <input
                        type="number"
                        defaultValue={pageIndex + 1}
                        onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                            gotoPage(page)
                        }}
                        style={{
                            width: '100px',
                            borderRadius: '4px',
                            padding: '4px 6px'
                        }}
                    />
                </span>{' '}
                <PageSelect
                    value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value))
                    }}
                >
                    {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </PageSelect>
            </PaginationSection>
        </ReactTableWrapper >
    )
};

export default ReactTable;
