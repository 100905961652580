import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import { DialogTitle, DialogContent, CircularProgress } from "@material-ui/core";

// Components
import { popToast } from "../../../components/Toast/Toast";
// Redux
import { connect } from "react-redux";
// SCSS
import "./AddShipmentProviderDialog.scss";
import { addShipmentProviderAction, updateShipmentProviderAction } from "../../../redux/actions/ShipmentProviderAction";

class AddShipmentProviderDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fromCountry: "",
            toCountry: "",
            shipmentProvider: ""
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevProps.open && this.props.open) {
            const updateData = this.props.updateData;
            this.setState({
                fromCountry: (updateData && updateData.fromCountryName) || "",
                toCountry: (updateData && updateData.toCountryName) || "",
                shipmentProvider: (updateData && updateData.shipmentProvider) || ""
            });
        }
    }

    render() {
        const { addShipmentProviderLoader, updateShipmentProviderLoader } = this.props.shipmentProviderReducer;
        const { countriesList } = this.props.countriesTaxReducer;
        const providerList = [
            { label: "RTISTIQ", value: "RTISTIQ" },
            { label: "DHL", value: "DHL" },
            { label: "FEDEX", value: "FEDEX" },
            { label: "ARAMAX", value: "ARAMAX" },
            { label: "NINJAVAN", value: "NINJAVAN" }
        ];
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.handleClose}
                BackdropProps={{ classes: { root: "dialog-backdrop" } }}
                className="add-shipment-provider-dialog"
            >
                <img className="close-btn" onClick={this.props.handleClose} src={require("../../../assets/images/common/Close_h.svg")} alt="" />
                <DialogTitle className="dialog-title">Add Shipment Provider</DialogTitle>
                <DialogContent className="add-shipment-provider-content">
                    <div className="add-shipment-provider-body">
                        <div className="form-control">
                            <label className="form-label">From Country</label>
                            <select
                                className="form-input"
                                value={this.state.fromCountry}
                                disabled={addShipmentProviderLoader || updateShipmentProviderLoader}
                                onChange={this.onHandleInput("fromCountry")}
                            >
                                <option value="" disabled>
                                    Select
							</option>
                                <option value="ANY">ANY</option>
                                {countriesList && countriesList.length !== 0 &&
                                    countriesList.map((item, index) => (
                                        <option key={index} value={item.name}>
                                            {item.name}
                                        </option>
                                    ))}
                            </select>
                        </div>
                        <div className="form-control">
                            <label className="form-label">To Country</label>
                            <select
                                className="form-input"
                                value={this.state.toCountry}
                                disabled={addShipmentProviderLoader || updateShipmentProviderLoader}
                                onChange={this.onHandleInput("toCountry")}
                            >
                                <option value="" disabled>
                                    Select
							</option>
                                <option value="ANY">ANY</option>
                                {countriesList && countriesList.length !== 0 &&
                                    countriesList.map((item, index) => (
                                        <option key={index} value={item.name}>
                                            {item.name}
                                        </option>
                                    ))}
                            </select>
                        </div>
                        <div className="form-control">
                            <label className="form-label">Shipment Provider</label>
                            <select
                                className="form-input"
                                value={this.state.shipmentProvider}
                                disabled={addShipmentProviderLoader || updateShipmentProviderLoader}
                                onChange={this.onHandleInput("shipmentProvider")}
                            >
                                <option value="" disabled>
                                    Select
							</option>
                                {providerList && providerList.length !== 0 &&
                                    providerList.map((item, index) => (
                                        <option key={index} value={item.value}>
                                            {item.label}
                                        </option>
                                    ))}
                            </select>
                        </div>
                    </div>

                    <div className="add-shipment-provider-action">
                        <button className="btn-cancel" onClick={this.onClickCancel.bind(this)}>
                            Cancel
						</button>
                        <button className="btn-orange" onClick={this.onClickCreate.bind(this)} disabled={addShipmentProviderLoader || updateShipmentProviderLoader}>
                            {this.props.updateData ? "Update" : "Save"}
                            {(addShipmentProviderLoader || updateShipmentProviderLoader) && <CircularProgress size={24} className="circular-loader modal-button-loader" />}
                        </button>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }

    // Handle inputs
    onHandleInput = name => event => {
        this.setState({
            [name]: event.target.value
        });
    };

    isFormValid() {
        if (!this.state.fromCountry.trim()) {
            popToast.show("Please enter from country name");
            return false;
        }
        if (!this.state.toCountry.trim()) {
            popToast.show("Please enter to country name");
            return false;
        }
        if (!this.state.shipmentProvider) {
            popToast.show("Please enter shipmentProvider");
            return false;
        }
        return true;
    }

    onClickCreate() {
        if (this.isFormValid()) {
            let request = {
                FromCountry: this.state.fromCountry,
                ToCountry: this.state.toCountry,
                ShipmentProvider: this.state.shipmentProvider
            }
            this.props.updateData ? this.props.updateShipmentProviderAction(request, this.props.updateData._id) : this.props.addShipmentProviderAction(request);
        }
    }

    onClickCancel() {
        this.props.handleClose();
    }
}

const mapStateToProps = state => {
    return {
        countriesTaxReducer: state.countriesTaxReducer,
        shipmentProviderReducer: state.shipmentProviderReducer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addShipmentProviderAction: (request) => {
            dispatch(addShipmentProviderAction(request));
        },
        updateShipmentProviderAction: (request, id) => {
            dispatch(updateShipmentProviderAction(request, id));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddShipmentProviderDialog);
