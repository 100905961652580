import React, { Component } from "react";
import { connect } from "react-redux";
import Root from "../../Root/Root";
import Topbar from "../../../components/Topbar/Topbar";
import { CircularProgress } from "@material-ui/core";
import { Select, Tooltip, Icon, Table, Popover, Skeleton, Affix } from "antd";
import "./UserProfile.scss";
import SkillChip from "../../../components/SkillChip/SkillChip";
import OrderHistory from "../../../components/OrderHistory/OrderHistory";
import OwnedArtworks from "../../../components/OwnedArtworks/OwnedArtworks";
import { getArtistByIdAction, setArtistFeaturedAction } from "../../../redux/actions/ManageArtistsAction";
import { getGalleryByIdAction, setGalleryFeaturedAction } from "../../../redux/actions/ManageGalleryAction";
import { getOrderHistory } from "../../../redux/actions/ManageusersAction";
import { getProductList, getOwnedProducts, getGalleryListedInventory } from "../../../redux/actions/ManagesellersAction";
import GalleryListedArtworks from "../../../components/OwnedArtworks/GalleryListedArtworks";
import propertiesList from "../../../properties.json";

const Option = Select.Option;

class UserProfile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			// isArtwork: false,
			tabType: "artwork",
			dataSource: [],
		};
	}

	componentDidMount() {
		this.onLoadFunction();
		//Call for Order History
		let orderRequest = {
			PageNum: 1,
			PageSize: 10,
			UserId: this.props.match.params.id,
		};
		this.props.getOrderHistory(orderRequest);

		//Call for Owned Products
		// let requestData = {
		//     OwnerId: this.props.match.params.id
		// };
		// this.props.getOwnedProducts(requestData);
		let requestBody = {
			SellerId: this.props.match.params.id,
		};
		this.props.getOwnedProducts(requestBody);

		this.props.getGalleryListedInventory(orderRequest);
	}

	onLoadFunction() {
		if (this.props.match.path === "/manage-artists/user-profile/:id") {
			this.props.getArtistProfile(this.props.match.params.id);
		} else {
			this.props.getGalleryProfile(this.props.match.params.id);
		}
	}

	render() {
		const artworks = this.props.ownedArtWorkList && this.props.ownedArtWorkList.length !== 0 ? this.props.ownedArtWorkList : [];
		const ownedWorks =
			artworks.length !== 0 &&
			artworks.map((artwork, i) => {
				return {
					key: i,
					_id: artwork._id,
					image: artwork.displayPicture,
					description: artwork.description || "NIL",
					status: artwork.status || "NIL",
					category: artwork.categoryName || "NIL",
					price: artwork.sellingPrice || "unknown",
					rfid: artwork.rfid || "unknown",
					productName: artwork.productName || "NIL",
					slugId: artwork.slugId,
					mediumSlug: artwork && artwork.medium && artwork.medium.slugId,
				};
			});

		//gallery listed inventory
		const galleryListed =
			this.props.galleryListedInventory && this.props.galleryListedInventory.length !== 0 ? this.props.galleryListedInventory : [];
		const ownedGalleryListed =
			galleryListed.length !== 0 &&
			galleryListed.map((artwork, i) => {
				return {
					key: i,
					_id: artwork._id,
					image: artwork.displayPicture,
					description: artwork.description || "NIL",
					status: artwork.status || "NIL",
					category: artwork.categoryName || "NIL",
					price: artwork.sellingPrice || "unknown",
					rfid: artwork.rfid || "unknown",
					productName: artwork.productName || "NIL",
					slugId: artwork.slugId,
					mediumSlug: artwork && artwork.medium && artwork.medium.slugId,
				};
			});
		// Order History
		const data = this.props.orderhistoryList && this.props.orderhistoryList.length !== 0 ? this.props.orderhistoryList.data : [];
		const dataSource =
			data.length !== 0 &&
			data.map((order, i) => {
				return {
					key: i,
					_id: order._id,
					image: order.productImage,
					description: order.oneLineDescription,
					orderId: order.orderId,
					orderDate: new Date(order.orderDate).toDateString(),
					price: order.amount,
					status: order.orderCurrentStatus,
					productName: order.productName,
				};
			});
		const UserInfo = this.props.match.path === "/manage-artists/user-profile/:id" ? this.props.getArtistInfo : this.props.getGalleryInfo;
		return (
			<Root>
				<Topbar
					title={this.props.match.path === "/manage-artists/user-profile/:id" ? "Artist Profile" : "Gallery Profile"}
					showBreadCrumbs
					showHead
				/>
				{/* {this.props.getArtistLoader || this.props.getGalleryLoader ? (
                    <div className="manageusers-wrapper">
                        <CircularProgress className="circular-loader" size={100} />
                    </div>
                ) : ( */}
				<div className="user-profile-main">
					<div className="user-profile-main-profile">
						<Affix>
							{this.props.getArtistLoader || this.props.getGalleryLoader ? (
								<Skeleton active paragraph={{ rows: 18 }} title={false} />
							) : (
								<>
									<a
										href={
											this.props.match.path === "/manage-artists/user-profile/:id"
												? `${propertiesList.fontEndUrl}artist/${UserInfo.slugId}?isAdminCheck=true`
												: `${propertiesList.fontEndUrl}galleries/${UserInfo.slugId}?isAdminCheck=true`
										}
										rel="noopener noreferrer"
										target="_blank"
									>
										<div className="user-profile-main-profile-view">
											<div className="profile-img">
												<img
													src={UserInfo.profilePhoto || require("../../../assets/images/common/profile-pic.svg")}
													alt="profile"
												/>
											</div>
										</div>
										<Tooltip title={UserInfo.name}>
											<p className="name">{UserInfo.name || "NIL"}</p>
										</Tooltip>
									</a>
									<p className="mb-0">{UserInfo.place || "Unknown"} </p>
									<div className="skillContainer">
										{UserInfo.artistUserTypes &&
											UserInfo.artistUserTypes.length !== 0 &&
											UserInfo.artistUserTypes.map((types, i) => {
												return <SkillChip skillName={types} key={i} />;
											})}
									</div>

									<div className="featured">
										<p className="text-black mb-0">Featured</p>
										{UserInfo.isFeatured ? (
											<Icon type="star" theme="filled" className="star" onClick={this.onUnFeatured.bind(this)} />
										) : (
											<Icon type="star" className="star" onClick={this.onFeatured.bind(this)} />
										)}
									</div>

									<div className="countContainer">
										<div className="countContainer-count">
											<p className="text-black mb-0">
												Total products:{" "}
												<span className="text-bold">{UserInfo.ownedArtWorksCount || UserInfo.ownedArtWorks || 0}</span>
											</p>
											{/* <p className="text-bold text-black text-center mb-0">{UserInfo.ownedArtWorksCount || 0}</p> */}
										</div>
										{/* <div className="countContainer-count">
                                <p className="text-black mb-0">Buy</p>
                                <p className="text-bold text-black text-center mb-0">0</p>
                            </div>
                            <div className="countContainer-count-noBorder">
                                <p className="text-black mb-0">Stock</p>
                                <p className="text-bold text-black text-center mb-0">0</p>
                            </div> */}
									</div>
									<div className="contact">
										<div className="contact-rows">
											<p>Phone</p>
											<Tooltip title={UserInfo.phoneNumber}>
												<p className="text-bold marginPhone">{UserInfo.phoneNumber || "Nil"}</p>
											</Tooltip>
										</div>
										<div className="contact-rows">
											<p>Email</p>

											<Tooltip title={UserInfo.email}>
												<p className="text-bold marginEmail">{UserInfo.email || "Nil"}</p>
											</Tooltip>
										</div>
										<div className="contact-rows">
											<p>Join Date</p>
											<Tooltip title={new Date(UserInfo.joiningDate).toDateString()}>
												<p className="text-bold marginDate">{new Date(UserInfo.joiningDate).toDateString()}</p>
											</Tooltip>
										</div>
										{UserInfo.yearofIncorporation && (
											<div className="contact-rows">
												<p>Year Of Incorporation</p>
												<Tooltip title={UserInfo.yearofIncorporation}>
													<p className="text-bold marginDate">{UserInfo.yearofIncorporation}</p>
												</Tooltip>
											</div>
										)}
										{UserInfo.birthYear && (
											<div className="contact-rows">
												<p>Birth year</p>
												<Tooltip title={UserInfo.birthYear}>
													<p className="text-bold marginDate">{UserInfo.birthYear}</p>
												</Tooltip>
											</div>
										)}
										{UserInfo.website && (
											<div className="contact-rows">
												<p>Website</p>
												<Tooltip title={UserInfo.website}>
													<p className="text-bold marginDate">{UserInfo.website}</p>
												</Tooltip>
											</div>
										)}
										{UserInfo.cvv && (
											<div className="contact-rows">
												<p>CV</p>
												<div className="first-imagebutton" onClick={this.downLoadCV.bind(this, UserInfo)}>
													<img
														className="first-iconimage"
														src={require("../../../assets/images/common/Invoice.svg")}
														height={"18px"}
														width={"18px"}
														alt="invoice"
													/>
													<p className="title">Download</p>
												</div>
											</div>
										)}
										{UserInfo.pickUpAddress && (
											<div className="sub-wrapper">
												<ul>
													<li className="greylist">PickUp Address</li>
												</ul>
												<ul className="detail-values">
													<li>
														<div className="addressform">
															<p className="nameText">{UserInfo.pickUpAddress ? UserInfo.pickUpAddress.name : "Nil"}</p>
															<p className="addressData">
																{UserInfo.pickUpAddress ? UserInfo.pickUpAddress.addressLine1 : "Nil"}&#44;
																{UserInfo.pickUpAddress ? UserInfo.pickUpAddress.addressLine2 : "Nil"}&#44;
															</p>
															<p className="addressData">
																{UserInfo.pickUpAddress.city}&#44;{UserInfo.pickUpAddress.state}&#44;
																{UserInfo.pickUpAddress.country}&#46;
															</p>
															<p className="addressData">
																{"Pin"}&nbsp;{UserInfo.pickUpAddress ? UserInfo.pickUpAddress.postalCode : "Nil"}
															</p>
														</div>
													</li>
												</ul>
											</div>
										)}
									</div>
								</>
							)}

							<p className="view-more">{/* View more details<i className="material-icons text-black">arrow_right_alt</i> */}</p>
						</Affix>{" "}
					</div>
					{/* ---------------------------Tabs------------------------------*/}
					<div className="tabs-container">
						<div className="tabs">
							<div
								className={this.state.tabType === "artwork" ? "tabs-switch tabs-switch-dark" : "tabs-switch tabs-switch-white"}
								onClick={this.switchTabs.bind(this, "artwork")}
							>
								<p className="mb-0">Artist Inventory</p>
							</div>
							{UserInfo.isGalleryAdded === true && (
								<div
									className={this.state.tabType === "gallery" ? "tabs-switch tabs-switch-dark" : "tabs-switch tabs-switch-white"}
									onClick={this.switchTabs.bind(this, "gallery")}
								>
									<p className="mb-0">Gallery Artworks</p>
								</div>
							)}
							<div
								className={this.state.tabType === "history" ? "tabs-switch tabs-switch-dark" : "tabs-switch tabs-switch-white"}
								onClick={this.switchTabs.bind(this, "history")}
							>
								<p className="mb-0">Order History</p>
							</div>
						</div>
						{this.props.isLoading || this.props.ownedArtworkLoader ? (
							<div className="manageusers-wrapper">
								<CircularProgress className="circular-loader" size={50} />
							</div>
						) : (
							<div className="history-container">
								{this.state.tabType === "artwork" && <OwnedArtworks data={ownedWorks} />}
								{this.state.tabType === "gallery" && <GalleryListedArtworks data={ownedGalleryListed} />}
								{this.state.tabType === "history" && <OrderHistory data={dataSource} />}
							</div>
						)}
					</div>
					{/*------------------------------------------------------------- */}
				</div>
				{/* )} */}
			</Root>
		);
	}
	switchTabs(type) {
		this.setState({
			tabType: type,
		});
	}

	downLoadCV(record) {
		const raw = window.atob(record.cvDownloadedFile);
		const rawLength = raw.length;
		const blobArray = new Uint8Array(new ArrayBuffer(rawLength));

		for (let i = 0; i < rawLength; i++) {
			blobArray[i] = raw.charCodeAt(i);
		}

		if (record.cvFileExtension == ".docx") {
			var blob = new Blob([blobArray], { type: "application/octet-stream" });
			var link = document.createElement("a");
			link.href = URL.createObjectURL(blob);
			link.download = record.cvFileName;
			link.click();
		} else if (record.cvFileExtension == ".pdf") {
			const blob = new Blob([blobArray], { type: "application/pdf" });
			let url = window.URL.createObjectURL(blob);
			window.open(url, "_blank");
		}
	}

	//Set Featured User
	onFeatured() {
		let role = this.props.match.path === "/manage-artists/user-profile/:id" ? "artist" : "gallery";
		let request = {
			userId: this.props.match.params.id,
			featureStatus: true,
		};
		if (role === "artist") this.props.setArtistFeaturedAction(request, this.props.match.params.id);
		else this.props.setGalleryFeaturedAction(request, this.props.match.params.id);
	}
	//Set Featured User
	onUnFeatured() {
		let role = this.props.match.path === "/manage-artists/user-profile/:id" ? "artist" : "gallery";
		let request = {
			userId: this.props.match.params.id,
			featureStatus: false,
		};
		if (role === "artist") this.props.setArtistFeaturedAction(request, this.props.match.params.id);
		else this.props.setGalleryFeaturedAction(request, this.props.match.params.id);
	}
}

const mapStateToProps = (state) => {
	return {
		getArtistInfo: state.manageArtistsReducer.getArtistProfile,
		getArtistLoader: state.manageArtistsReducer.getArtistProfileLoader,
		getGalleryInfo: state.manageGalleryReducer.getGalleryProfile,
		getGalleryLoader: state.manageGalleryReducer.getGalleryProfileLoader,
		orderhistoryList: state.manageUsersReducer.orderhistoryList,
		isLoading: state.manageUsersReducer.orderhistoryLoader,
		// ownedArtworkLoader: state.manageSellersReducer.productListLoader,
		// ownedArtWorkList: state.manageSellersReducer.savedProductsList,
		ownedArtWorkList: state.manageSellersReducer.getOwnedProduct,
		ownedArtworkLoader: state.manageSellersReducer.getOwnedProductLoader,
		featureReducer: state.manageArtistsReducer.featureData,
		featureLoader: state.manageArtistsReducer.featureLoader,
		galleryListedInventory: state.manageSellersReducer.getGalleryListInventory,
		getGalleryListedInventoryLoader: state.manageSellersReducer.getGalleryListedInventoryLoader,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getArtistProfile: (userId) => {
			dispatch(getArtistByIdAction(userId));
		},
		getGalleryProfile: (userId) => {
			dispatch(getGalleryByIdAction(userId));
		},
		getOrderHistory: (orderRequest) => {
			dispatch(getOrderHistory(orderRequest));
		},
		// getOwnedProducts: requestData => {
		//     dispatch(getProductList(requestData));
		// },
		getOwnedProducts: (requestData) => {
			dispatch(getOwnedProducts(requestData));
		},
		getGalleryListedInventory: (requestData) => {
			dispatch(getGalleryListedInventory(requestData));
		},
		setArtistFeaturedAction: (featureData, userData) => {
			dispatch(setArtistFeaturedAction(featureData, null, userData));
		},
		setGalleryFeaturedAction: (featureData, galleryinput) => {
			dispatch(setGalleryFeaturedAction(featureData, null, galleryinput));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
