import React, { Component } from 'react';
import { connect } from "react-redux";
import { CircularProgress } from '@material-ui/core';

import { popToast, Toast } from '../../../components/Toast/Toast';
import { resetPasswordAction } from '../../../redux/actions/ForgotPasswordAction';
import './ChangePassword.scss';

class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newPassword: "",
            confirmPassword: "",
            mailId: this.props.match.params.id,
            resetToken: this.props.forgotPasswordReducer.validateOtpResult.data
        };
    }
    render() {
        return (
            <div className="change-wrapper">
                <Toast />
                <div className="app-logo">
                    <img src={require("../../../assets/images/common/Logo.svg")} alt="logo" />
                </div>
                <div className="change-contents">
                    <p className="page-title">Change Password</p>
                    <div className="form-control">
                        <label className="form-label">New Password</label>
                        <input
                            type="password"
                            autoFocus
                            className="form-input"
                            value={this.state.newPassword}
                            onChange={this.onHandleInput('newPassword')}
                        />
                    </div>
                    <div className="form-control">
                        <label className="form-label">Confirm Password</label>
                        <input
                            type="password"
                            className="form-input"
                            value={this.state.confirmPassword}
                            onChange={this.onHandleInput('confirmPassword')}
                        />
                    </div>
                    <div className="end-btn">
                        <button className="btn-orange"
                            onClick={this.onsubmitPassword.bind(this)}
                            disabled={this.props.forgotPasswordReducer.confirmPasswordLoader}>
                            SUBMIT
                            {this.props.forgotPasswordReducer.confirmPasswordLoader && <CircularProgress size={24} className="otp-progress" />}
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    onHandleInput = (name) => (event) => {
        this.setState({
            [name]: event.target.value
        });
    }

    onsubmitPassword() {
        if (this.state.newPassword.length < 6 || this.state.confirmPassword.length < 6) {
            popToast.show("Password should contain atleast 6 characters");
        }
        else if (this.state.newPassword !== this.state.confirmPassword) {
            popToast.show("Password does not match");
        }
        else if (this.state.newPassword && this.state.confirmPassword) {
            let resetData = {
                Email: this.state.mailId,
                Password: this.state.newPassword,
                resetPasswordToken: this.state.resetToken
            }
            this.props.resetPasswordAction(resetData);
        }
    }
}
// --------------------------react-redux connection-------------------------
//mapStateToProps is used to update the component if react's store is changed
const mapStateToProps = state => {
    return {
        forgotPasswordReducer: state.forgotPasswordReducer
    };
};

//mapDispatchToProps is used to inject dispatch function within the component
const mapDispatchToProps = dispatch => {
    return {
        resetPasswordAction: (resetInput) => {
            dispatch(resetPasswordAction(resetInput));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);

