import {
	GET_NOTIFICATION_LIST_REQUEST, GET_NOTIFICATION_LIST_SUCCESS, GET_NOTIFICATION_LIST_FAILURE
} from "../actions/ActionsTypes";

const initialState = {
	notificationListLoader: false,
	notificationList: []
};

const notificationReducer = (state = initialState, action = {}) => {
	switch (action.type) {
		case GET_NOTIFICATION_LIST_REQUEST:
			return {
				...state,
				notificationListLoader: true
			};

		case GET_NOTIFICATION_LIST_SUCCESS:
			state = {
				...state,
				notificationListLoader: false,
				notificationList: action.data,
			};
			return state;

		case GET_NOTIFICATION_LIST_FAILURE:
			return {
				...state,
				notificationListLoader: false,
				notificationList: [],
			};

		default:
			return state;
	}
};

export default notificationReducer;
