
import { Dropdown, Select } from "antd";
import styled from "styled-components"
import ReactHlsPlayer from "react-hls-player";

const ReelsVideosContainer = styled.div` 
    display: flex;
    flex-direction: column;
    padding: 2% 4%;
    overflow-x: hidden;
    overflow-y: scroll;   
    height: calc(100% - 4rem);
` ;

const ReelsButtonWrapper = styled.div` 
    display: flex;
    justify-content: space-between;

    @media only screen and (max-device-width: 525px) {
        display: grid;
		grid-template-columns: 100%
        gap: 10px;
	}
    
    
`;

const FilterInputDiv = styled.div` 
    width: 315px;
`

const ReelsFilterDiv = styled.div` 
    display: flex;
    gap: 1rem;

    @media only screen and (max-device-width: 525px) {
		width: 100%;
	}
`;

const SortByButton = styled(Select)` 
   
`

const AddNewButton = styled.div` 
    border: 0.5px solid #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    height: 30px;
    cursor: pointer;
    width: max-content;
`;

const VideoListingContainer = styled.div` 
    padding: 1rem 0;
    display: grid;
    gap: 1rem 1rem;
    grid-template-columns: repeat(auto-fit,215px);

    @media only screen and (max-device-width: 525px) {
		grid-template-columns: repeat(auto-fit,180px);
	}
`;

const VideoElements = styled(ReactHlsPlayer)` 
    max-width: 100%;
    width: 100%;
    height: 300px;
    object-fit: cover;
    
`;

const TitleContainer = styled.div` 
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 5px;
    height: 50px;
    background: rgb(0,0,0,.1);

    label{
        font-weight: 400;
        font-size: 12px;
        line-height: 135%;
        color: #FFFFFF;
    }

    img {
        height: 18px;
        width: 18px;
    }
`;

const MoreInfo = styled.div` 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: .325rem;
    cursor:  pointer;

    span {
        color:  #ffffff;
        font-size: 8px !important;
    }
`

const ItemContainer = styled.div` 
    position: relative;
`;

const NoRecordsFound = styled.div` 
    display: flex;
    justify-content: center;
    align-items: center;
    height: 15rem;

    label{
        font-size: 14px;
        color: #000;
        opacity: .6;
    }
`;

const UploadVideoWarningBox = styled.div`
	background: #FFEFD6;
	border: 0.5px solid #FF9900;
	display: flex;
    justify-content: space-between;
    align-items: center;
	padding: 10px;
    width: 450px;
    margin-top: 1rem;
`;
const WarningMessageContent = styled.div`
`;

const AlertIcon = styled.img`
`;

const AlertText = styled.span`
`;
const WarningMessageCloseButton = styled.div`
`;
const CloseButton = styled.img`
`;

export {
    FilterInputDiv,
    ReelsVideosContainer,
    ReelsButtonWrapper,
    ReelsFilterDiv,
    AddNewButton,
    VideoListingContainer,
    VideoElements,
    SortByButton,
    TitleContainer,
    ItemContainer,
    MoreInfo,
    NoRecordsFound,
    UploadVideoWarningBox,
    WarningMessageContent,
    AlertIcon,
    AlertText,
    WarningMessageCloseButton,
    CloseButton
}