import React, { useState } from "react";
import { Pagination, Table, Icon, Tooltip, Popover, Select } from "antd";
import { isArray, isEmpty, map } from "lodash";

import Topbar from "../../components/Topbar/Topbar";
import Root from "../Root/Root";
import { AbandonedCartReportTable, AbandonedTableWrapper, ExportExcelButton, UtilityButtonWrapper } from "./AbandonedCartReports.styled";
import useAbandonedCartReports from "./useAbandonedCartReports";
import { DataGrid } from "@mui/x-data-grid";
import { CircularProgress } from "@material-ui/core";

const AbandonedCartReports = () => {
	const {
		cartObjects,
		getCartObjectsLoader,
		downLoadAbandonedCarts,
		downLoadCartObjectsLoader,
		onSelectPageNumber,
		pageNum,
	} = useAbandonedCartReports();
	const [pageSize, setPageSize] = React.useState(20);

	const columns = [
		{ headerName: "Artwork Name", field: "artworkName", width: 200 },
		{ headerName: "Artist", field: "artistname", width: 150 },
		{ headerName: "User Name", field: "userName", width: 150 },
		{ headerName: "User Email", field: "userEmail", width: 200 },
		{ headerName: "Added Date", field: "addedDate", width: 150 },
		{ headerName: "Artwork Status", field: "artworkStatus", width: 150 },
		{ headerName: "Price", field: "price", width: 100 },
		{ headerName: "Selling Price", field: "sellingPrice", width: 100 },
		{ headerName: "Artwork SlugId", field: "artworkSlugId", width: 150 },
	];

	return (
		<>
			<Root>
				<Topbar title={"Abandoned Carts"} showBreadCrumbs showHead />
				<AbandonedTableWrapper>
					<UtilityButtonWrapper>
						<ExportExcelButton loading={downLoadCartObjectsLoader} onClick={downLoadAbandonedCarts}>
							Export To Excel
						</ExportExcelButton>
					</UtilityButtonWrapper>
					{getCartObjectsLoader && (
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<CircularProgress className="circular-loader" size={30} />
						</div>
					)}
					{!isEmpty(cartObjects) && (
						<DataGrid
							pageSize={pageSize}
							onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
							pagination
							loading={getCartObjectsLoader}
							getRowId={(row) => row._id}
							columns={columns}
							rows={cartObjects}
							rowsPerPageOptions={[20, 50, 100]}
						/>
					)}
				</AbandonedTableWrapper>
			</Root>
		</>
	);
};

export default AbandonedCartReports;
