import { GET_NOTIFICATION_LIST_REQUEST, GET_NOTIFICATION_LIST_SUCCESS, GET_NOTIFICATION_LIST_FAILURE } from "./ActionsTypes";
import { NotificationService } from "../../containers/Notification/Notification.service";
import { popToast } from "../../components/Toast/Toast";

export function getNotificationsListAction() {
	return dispatch => {
		dispatch(request());
		NotificationService.getNotificationsList().then(
			response => {
				if (response && response.message === "Success") {
					dispatch(success(response.data));
				} else if (response && response.message === "Failure") {
					dispatch(failure(response));
					popToast.show("Get notifications list Error : " + response.Message || "Something went wrong while loading notifications list!");
				}
			},
			error => {
				if (error && error.status_message) {
					popToast.show(error.status_message);
				} else {
					popToast.show("Something went wrong while loading notifications list!");
				}
				console.log("error dispatched for API error!", error);
				dispatch(failure(error));
			}
		);
	};

	function request() {
		return { type: GET_NOTIFICATION_LIST_REQUEST };
	}
	function success(data) {
		return { type: GET_NOTIFICATION_LIST_SUCCESS, data };
	}
	function failure(error) {
		return { type: GET_NOTIFICATION_LIST_FAILURE, error };
	}
}
