import React, { Component } from "react";
import "./OwnedArtworks.scss";
import { Table, Tooltip, Popover } from "antd";
import propertiesList from "../../properties.json";

export default class GalleryListedArtworks extends Component {
    render() {


        const columns = [
            {
                title: "Products",
                dataIndex: "products",
                key: "products",
                width: "40%",
                render: (text, record) => (
                    <a
                        href={`${propertiesList.fontEndUrl}art/${record.mediumSlug}/${record.slugId}?isAdminCheck=true`}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        <span className="colWrapper">
                            <img
                                className="colImage"
                                src={record.image || require("../../assets/images/common/profile-pic.svg")}
                                alt="productImage"
                            />
                            <div className="ml-1">
                                <Tooltip title={record.productName} placement="left">
                                    <p className="colText text-bold text-black overflow">{record.productName || "Unknown"}</p>
                                </Tooltip>
                                <Popover content={record.description}>
                                    <p className="overflow">{record.description}</p>
                                </Popover>
                            </div>
                        </span>
                    </a>
                ),
            },
            {
                title: "RFID",
                dataIndex: "rfid",
                key: "rfid",
            },
            {
                title: "Medium",
                dataIndex: "mediumSlug",
                key: "mediumSlug",
            },
            {
                title: "Price",
                dataIndex: "price",
                key: "price",
            },
            {
                title: "Status",
                dataIndex: "status",
                key: "status",
                render: (text) => {
                    console.log("text", text);
                    return (
                        <div>
                            {text === 1 && <p className="textBlue">Available</p>}
                            {text === 2 && <p className="textBlue">Available Resell</p>}
                            {text === 3 && <p className="textGreen">Sold out</p>}
                            {text === 4 && <p className="textDarkBlue">Pending With Artist</p>}
                            {text === 5 && <p className="textDarkBlue">Pending With Admin</p>}
                            {text === 6 && <p className="textDarkBlue">BlockChain Pending</p>}
                            {text === 7 && <p className="textBrown">Placed For Resell</p>}
                            {text === 8 && <p className="textRed">ReSell Rejected</p>}
                            {text === 9 && <p className="textRed">Deleted</p>}
                            {text === 10 && <p className="textRed">Blocked</p>}
                            {text === 11 && <p className="textRed">Sold out externally</p>}
                            {text === 12 && <p className="textRed">Order Processing</p>}
                            {text === 13 && <p className="textRed">NFC Pending</p>}
                            {text === 15 && <p className="textRed">Pending With Gallery</p>}
                            {text === 16 && <p className="textRed">Reserve For Auction</p>}
                            {text === 17 && <p className="textRed">Auction Visible</p>}
                            {text === 18 && <p className="textRed">Auction Live</p>}
                        </div>
                    );
                },
            },
            // {
            //     title: "Action",
            //     key: "action",
            //     render: () => (
            //         <Popover placement="bottom" content={content} overlayClassName="manage-artworks-action-popover">
            //             <img className="action-image" src={require("../../assets/images/common/artworksActionIcon.svg")} alt="action" />
            //         </Popover>
            //     )
            // }
        ];
        // const content = (
        //     <div>
        //         <span className="action-span">
        //             <img src={require("../../assets/images/common/Edit_New.svg")} alt="edit" />
        //             <p className="pop-item">Edit</p>
        //         </span>
        //         <span className="action-span">
        //             <img src={require("../../assets/images/common/delete.svg")} alt="delete" />
        //             <p className="pop-item">Delete</p>
        //         </span>
        //         <span className="action-span">
        //             <img src={require("../../assets/images/common/Block.svg")} alt="block" />
        //             <p className="pop-item">Block</p>
        //         </span>
        //     </div>
        // );
        return (
            <div className="main">
                <Table rowKey={(record) => record._id} columns={columns} dataSource={this.props.data} pagination={this.props.data.length > 10} />
            </div>
        );
    }
}
