import styled, { css } from "styled-components";

const ArtistTabSwitchWrapper = styled.div`
    display: flex;

`;

const ArtistTabs = styled.div`
    flex: 1;
    border: .5px solid #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
    cursor: pointer;

    ${props => props.active ? css`
        background: #000
        font-weight: 700 !important;
        color: #fff;
    `: css` 
        background: #fff;       
        font-weight: 500 !important;
    `}

    &:hover {
        opacity: .8;
    }
`;

export {
    ArtistTabSwitchWrapper,
    ArtistTabs
}