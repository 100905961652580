import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { Util } from "../../../../util/util";
import { BlogService } from "../Blog.service";

const useBlogBanner = (location, history) => {
    const [bannerList, setBannerList] = useState([]);
    const [loading, setLoading] = useState(false)
    const [pageCount, setPageCount] = useState(0);
    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [openNewBlogModal, setOpenNewBlogModal] = useState(false);
    const fetchIdRef = useRef(0);
    const [addBannerLoader, setAddBannerLoader] = useState(false);

    const bannerObj = {
        media: "",
        mediaThumb: "",
        title: "",
        isActive: false,
    }
    const [banner, setBanner] = useState(bannerObj);

    useEffect(() => {
        getBanners();
    }, [])

    const [filterState, setFilterState] = useState({
        content: "",
        isActive: "",
        PageNo: "",
        pageSize: "",

    })

    useEffect(() => {
        let parsedQuery = Util.queryStringHandling(location.search, "PARSE");
        if (parsedQuery) {
            setFilterState({
                content: parsedQuery.content,
                isActive: parsedQuery.isActive,
                PageNo: parseInt(parsedQuery.PageNo),
                pageSize: parsedQuery.pageSize
            })
        }
    }, [location]);

    const updateFilterState = (name, value) => {
        let newFilterState = { ...filterState };
        newFilterState[name] = value;
        setFilterState(filterState => [{ ...filterState, [name]: value }])
        const queryString = Util.queryStringHandling(newFilterState, "STR");
        history.push('/blog/2?' + queryString);
    }

    const getBanners = async (pageInd, pageSiz) => {
        try {
            let bannerResp = await BlogService.getBlogBanner(0, 0);
            if (bannerResp && bannerResp.data) {
                setBannerList(bannerResp.data.topicList);
                setPageCount(Math.ceil(bannerResp.data.count / pageSize))
            }
        } catch (error) {

        }
    }

    const AddBanners = async () => {
        try {
            setAddBannerLoader(true);
            let bannerResp = await BlogService.addBlogBanner({
                BannerImage: banner.media,
                Content: banner.title,
                IsActive: banner.isActive,
            });
            if (bannerResp && bannerResp.data) {
                let banners = await getBanners(pageIndex, pageSize);
                onClickCancelAddBanner();
            }
            setAddBannerLoader(false);
        } catch (error) {
            setAddBannerLoader(false);
        }
    }

    const UpdateBanners = async () => {
        try {
            setAddBannerLoader(true);
            let bannerResp = await BlogService.updateBlogBanner({
                BannerImage: banner.media,
                Content: banner.title,
                IsActive: banner.isActive,
                Id: banner._id
            });
            if (bannerResp && bannerResp.data) {
                let banners = await getBanners(pageIndex, pageSize);
                onClickCancelAddBanner();
            }
            setAddBannerLoader(false);
        } catch (error) {
            setAddBannerLoader(false);
        }
    }

    const DeleteBanners = async (record) => {
        try {
            let bannerResp = await BlogService.deleteBlogBanner(record && record._id);
            if (bannerResp && bannerResp.data) {
                let banners = await getBanners(pageIndex, pageSize);
            }
        } catch (error) {

        }
    }

    const onImageSelect = (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        let image = "";
        if (file) {
            reader.onloadend = () => {
                setBanner(banner => ({
                    ...banner,
                    media: file,
                    mediaThumb: reader.result,
                }));
                image = reader.result;
            };
            reader.readAsDataURL(file);
        }
    }

    const onClickCancelAddBanner = () => {
        setBanner(bannerObj);
        setOpenNewBlogModal(false);
    }

    const onClickAddBanner = () => {
        setOpenNewBlogModal(true);
    }

    const onClickEditBanner = (record) => {
        setBanner(banner => ({
            ...banner,
            title: record.content,
            mediaThumb: record.imageUC,
            _id: record._id,
            isActive: record.isActive
        }));
        setOpenNewBlogModal(true);
    }

    const onHandleInput = (e) => {
        const { value, name } = e.target;
        setBanner(banner => ({
            ...banner,
            title: value,
        }));
    }

    const UpdateBannerActiveStatus = async (isActive, record) => {
        try {
            setAddBannerLoader(true);
            let bannerResp = await BlogService.updateBlogBanner({
                BannerImage: null,
                Content: record.content,
                IsActive: isActive,
                Id: record._id
            });
            if (bannerResp && bannerResp.data) {
                let banners = await getBanners(pageIndex, pageSize);
                onClickCancelAddBanner();
            }
            setAddBannerLoader(false);
        } catch (error) {
            setAddBannerLoader(false);
        }
    }

    const BoolenColumnFilter = ({
        column: { filterValue, setFilter, preFilteredRows, id },
    }) => {
        // Calculate the options for filtering
        // using the preFilteredRows
        const options = React.useMemo(() => {
            const options = new Set()
            preFilteredRows.forEach(row => {
                options.add(row.values[id])
            })
            return [...options.values()]
        }, [id, preFilteredRows])

        // Render a multi-select box
        return (
            <select
                value={filterValue}
                onChange={e => {
                    setFilter(e.target.value || undefined)
                }}
            >
                <option value="">All</option>
                {options.map((option, i) => (
                    <option key={i} value={option}>
                        {`${option}`}
                    </option>
                ))}
            </select>
        )
    }

    return {
        loading,
        pageCount,
        pageIndex,
        pageSize,
        bannerList,
        addBannerLoader,
        filterState,

        updateFilterState,
        setPageSize,
        UpdateBanners,
        onImageSelect,
        banner,
        openNewBlogModal,
        onClickAddBanner,
        onClickCancelAddBanner,
        onHandleInput,
        AddBanners,
        onClickEditBanner,
        DeleteBanners,
        UpdateBannerActiveStatus,

        BoolenColumnFilter
    }
}

export default useBlogBanner;