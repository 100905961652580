import React from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const FormikEditor = props => {
	return (
		<Editor
			editorState={props.defaultEditorState}
			value={props.value}
			onEditorStateChange={props.onEditorStateChange}
			onContentStateChange={props.onContentStateChange}
			editorStyle={props.editorStyle}
		/>
	);
};

export default FormikEditor;
