import React from "react";
import "./ArtworkCard.scss";
import { Icon, Tooltip, Popconfirm } from "antd";

const ArtworkCard = ({ data , ...props}) => {
	const { isSelected, onClickArtwork, showDelete = false, confirmDeleteAction } = props;
	const { artistName, artistId, displayPicture, oneLineDescription, productName, sellingPrice, price, currency, discountPercentage } = data;
	return (
		<div className="artwork-card-wrapper" onClick={() => !showDelete && onClickArtwork(data)}>
			<div className="artwork-card-image-wrapper">
				{/* Artwork Image */}
				<img className={isSelected ? "artwork-card-image isSelected" : "artwork-card-image"} src={displayPicture} alt="art" />

				{/* Artwork Check */}
				{isSelected && (
					<div className="artwork-card-selection">
						<Icon type="check-circle" theme="filled" />
					</div>
				)}

				{showDelete && (
					<div className="artwork-card-actions">
						<Tooltip title={"Delete"}>
							<Popconfirm
								overlayClassName="confirm-popup-overlay"
								title={"Are you sure you want to remove this artwork from the collection?"}
								okText={"Delete"}
								onConfirm={() => confirmDeleteAction(data)}
								icon={null}
							>
								<img src={require("../../../assets/images/common/delete.svg")} alt="delete" />
							</Popconfirm>
						</Tooltip>
					</div>
				)}
			</div>

			<p className="artwork-card-title">{productName || oneLineDescription || "Artwork"}</p>

			{/* Footer */}
			<div className="artwork-card-footer">
				<div className="artwork-card-artist">
					{/* <img src={artistIcon} alt="Profile" /> */}
					<p>{artistName || "Unknown"}</p>
				</div>
				<p className="artwork-card-price">
					{/* TODO : check isHidePrice/ disclose price each to show price */}
					{(sellingPrice || price) && `${currency} ${sellingPrice || price}`}
				</p>
			</div>
		</div>
	);
};

export default ArtworkCard;
