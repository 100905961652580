import React from "react";
import styled from "styled-components";
import { useTable, useExpanded, usePagination } from "react-table";

const Styles = styled.div`
	padding: 1rem;

	table {
		border-spacing: 0;
		border: 1px solid black;

		tr {
			:last-child {
				td {
					border-bottom: 0;
				}
			}
		}

		th,
		td {
			margin: 0;
			padding: 0.5rem;
			border-bottom: 1px solid black;
			border-right: 1px solid black;

			:last-child {
				border-right: 0;
			}
		}
	}
`;
// A simple way to support a renderRowSubComponent is to make a render prop
// This is NOT part of the React Table API, it's merely a rendering
// option we are creating for ourselves in our table renderer
function AnswerTable({ columns: userColumns, data, renderRowSubComponent, fetchData, pageCount: controlledPageCount }) {
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
		visibleColumns,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		state: { expanded, pageIndex, pageSize },
	} = useTable(
		{
			columns: userColumns,
			data,
			initialState: { pageIndex: 1 },
			manualPagination: true,
			pageCount: controlledPageCount,
		},
		useExpanded,
		usePagination
		// We can useExpanded to track the expanded state
		// for sub components too!
	);
	React.useEffect(() => {
		fetchData({ pageIndex, pageSize });
	}, [fetchData, pageIndex, pageSize]);

	return (
		<>
			<table {...getTableProps()}>
				<thead>
					{headerGroups.map((headerGroup) => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => (
								<th {...column.getHeaderProps()}>{column.render("Header")}</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{rows.map((row, i) => {
						let answ = row.original.answers;

						prepareRow(row);
						return (
							// Use a React.Fragment here so the table markup is still valid
							<React.Fragment {...row.getRowProps()}>
								<tr>
									{row.cells.map((cell) => {
										return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
									})}
								</tr>
								{/*
                    If the row is in an expanded state, render a row with a
                    column that fills the entire length of the table.
                  */}
								{row.isExpanded ? (
									<tr>
										<td colSpan={visibleColumns.length}>
											{/*
                          Inside it, call our renderRowSubComponent function. In reality,
                          you could pass whatever you want as props to
                          a component like this, including the entire
                          table instance. But for this example, we'll just
                          pass the row
                        */}
											{renderRowSubComponent({ answ })}
										</td>
									</tr>
								) : null}
							</React.Fragment>
						);
					})}
				</tbody>
			</table>
			<div className="survey-answer-pagination">
				<button onClick={() => gotoPage(1)} disabled={!canPreviousPage}>
					{"<<"}
				</button>{" "}
				<button onClick={() => previousPage()} disabled={!canPreviousPage}>
					{"<"}
				</button>{" "}
				<button onClick={() => nextPage()} disabled={!canNextPage}>
					{">"}
				</button>{" "}
				<button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
					{">>"}
				</button>{" "}
				<span>
					Page {"  "}
					<strong>
						{pageIndex + 1} of {pageOptions.length}
					</strong>{" "}
				</span>
			</div>
			<br />
			{/* <div>Showing the first 20 results of {rows.length} rows</div> */}
		</>
	);
}

export default AnswerTable;
