import React from "react";
import Snackbar from '@material-ui/core/Snackbar';
import { Util } from "../../util/util";

const toast = {
    show: (msg) => {
        const context = Util.getToastContext();
        Toast.handleToast(true, msg, context);
    },
    hide: () => {
        const context = Util.getToastContext();
        Toast.handleToast(false, "", context);
    }
}

export const popToast = toast;
export class Toast extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            toastMessage: "",
            duration: 3000
        };
    }

    componentDidMount() {
        Util.setToastContext(this);
    }

    render() {
        return (
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={this.state.open}
                autoHideDuration={this.state.duration || 3000}
                onClose={toast.hide}
                snackbarcontentprops={{
                    'aria-describedby': 'message-id',
                }}
                message={<span id="message-id">{this.state.toastMessage}</span>}
            />
        )
    }

    // Show/Hide Toast
    static handleToast(isShow, msg, context) {
        if (context) {
            context.setState({
                open: isShow,
                toastMessage: msg,
                duration: 3000
            });
        }
    }

}