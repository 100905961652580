import React, { Component } from "react";
import "./MessageContainer.scss";
import { Util } from "../../util/util";

class MessageContainer extends Component {
	render() {
		let message = this.props.match.params.message || "";
		return (
			<div className="message-container-wrapper">
				<div id="notfound">
					<div className="logo-box">
						<img src={require("../../assets/images/common/Logo.svg")} alt="rtistiq" className="logo-text" />
					</div>

					<div className="notfound">
						<div className="notfound-404">
							<h1>Oops!</h1>
							<h2>{message === "access-denied" ? "403 - Access Forbidden" : "404 - The Page can't be found"}</h2>
						</div>
						{Util.getAuthToken() && Util.getValueFromLocal("role") === "Seller" ? (
							<a href="/#/dashboard">Go to Dashboard</a>
						) : (
							<a href="/#/login">Login</a>
						)}
					</div>
				</div>
			</div>
		);
	}
}

export default MessageContainer;
