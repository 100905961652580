import {
    GET_SELLERSLIST_REQUEST,
    GET_SELLERSLIST_SUCCESS,
    GET_SELLERSLIST_FAILURE,
    GET_PRODUCT_LIST_REQUEST,
    GET_PRODUCT_LIST_SUCCESS,
    GET_PRODUCT_LIST_FAILURE,
    BLOCK_SELLER_REQUEST,
    BLOCK_SELLER_SUCCESS,
    BLOCK_SELLER_FAILURE,
    UNBLOCK_SELLER_REQUEST,
    UNBLOCK_SELLER_SUCCESS,
    UNBLOCK_SELLER_FAILURE,
    GET_SELLER_DETAILS_REQUEST,
    GET_SELLER_DETAILS_SUCCESS,
    GET_SELLER_DETAILS_FAILURE,
    SELLER_PROFILE_DETAILS,
    SET_MANAGESELLER_REDUCER_STATES,
    GET_OWNED_PRODUCTS_REQUEST,
    GET_OWNED_PRODUCTS_SUCCESS,
    GET_OWNED_PRODUCTS_FAILURE,
    GET_GALLERY_LISTED_INVENTORY_REQUEST,
    GET_GALLERY_LISTED_INVENTORY_SUCCESS,
    GET_GALLERY_LISTED_INVENTORY_FAILURE
} from "./ActionsTypes";
import { ManageSellerService } from '../../containers/ManageSellers/Managesellers.sevice';
import { popToast } from '../../components/Toast/Toast';

export function getSellersList(sellerdata) {
    return dispatch => {
        dispatch(request());
        ManageSellerService.getSellersList(sellerdata)
            .then(
                (response) => {
                    if (response && response.message === 'Success') {
                        dispatch(success(response.data));
                    }
                    else if (response && response.message === 'Failure') {
                        dispatch(failure(response));
                        popToast.show('Get sellers list Error : ' + response.Message || 'Something went wrong while loading sellers list!');
                    }
                },
                error => {
                    if (error && error.status_message) {
                        popToast.show(error.status_message);
                    }
                    else {
                        popToast.show('Something went wrong while loading sellers list!');
                    }
                    console.log('error dispatched for API error!', error);
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: GET_SELLERSLIST_REQUEST } }
    function success(data) { return { type: GET_SELLERSLIST_SUCCESS, data } }
    function failure(error) { return { type: GET_SELLERSLIST_FAILURE, error } }
}
export function getProductList(productdata) {
    return dispatch => {
        dispatch(request());
        ManageSellerService.getProductList(productdata)
            .then(
                (response) => {
                    if (response && response.message === 'Success') {
                        dispatch(success(response.data));
                    }
                    else if (response && response.message === 'Failure') {
                        dispatch(failure(response));
                        popToast.show('Get products list Error : ' + response.Message || 'Something went wrong while loading products list!');
                    }
                },
                error => {
                    if (error && error.status_message) {
                        popToast.show(error.status_message);
                    }
                    else {
                        popToast.show('Something went wrong while loading products list!');
                    }
                    console.log('error dispatched for API error!', error);
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: GET_PRODUCT_LIST_REQUEST } }
    function success(data) { return { type: GET_PRODUCT_LIST_SUCCESS, data } }
    function failure(error) { return { type: GET_PRODUCT_LIST_FAILURE, error } }
}
export function blockSeller(sellerid) {
    return dispatch => {
        dispatch(request());
        ManageSellerService.blockSeller(sellerid)
            .then(
                (response) => {
                    if (response && response.message === 'Success') {
                        dispatch(success(response));
                        popToast.show('Seller blocked successfully!');
                    }
                    else if (response && response.message === 'Failure') {
                        dispatch(failure(response));
                        popToast.show('Blocking seller Error : ' + response.Message || 'Something went wrong while blocking seller!');
                    }
                },
                error => {
                    if (error && error.status_message) {
                        popToast.show(error.status_message);
                    }
                    else {
                        popToast.show('Something went wrong while blocking seller !');
                    }
                    console.log('error dispatched for API error!', error);
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: BLOCK_SELLER_REQUEST } }
    function success(data) { return { type: BLOCK_SELLER_SUCCESS, data } }
    function failure(error) { return { type: BLOCK_SELLER_FAILURE, error } }
}

export function unblockSeller(sellerid) {
    return dispatch => {
        dispatch(request());
        ManageSellerService.unblockSeller(sellerid)
            .then(
                (response) => {
                    if (response && response.message === 'Success') {
                        dispatch(success(response));
                        popToast.show('Seller unblocked sucessfully');
                    }
                    else if (response && response.message === 'Failure') {
                        dispatch(failure(response));
                        popToast.show('Unblocking seller Error : ' + response.Message || 'Something went wrong while unblocking seller!');
                    }
                },
                error => {
                    if (error && error.status_message) {
                        popToast.show(error.status_message);
                    }
                    else {
                        popToast.show('Something went wrong while unblocking seller !');
                    }
                    console.log('error dispatched for API error!', error);
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: UNBLOCK_SELLER_REQUEST } }
    function success(data) { return { type: UNBLOCK_SELLER_SUCCESS, data } }
    function failure(error) { return { type: UNBLOCK_SELLER_FAILURE, error } }
}

export function getSellerDetails(sellerid) {
    return dispatch => {
        dispatch(request());
        ManageSellerService.getSellerDetails(sellerid)
            .then(
                (response) => {
                    if (response && response.message === 'Success') {
                        dispatch(success(response));
                    }
                    else if (response && response.message === 'Failure') {
                        dispatch(failure(response));
                        popToast.show('Get seller details Error : ' + response.Message || 'Something went wrong while loading seller details!');
                    }
                },
                error => {
                    if (error && error.status_message) {
                        popToast.show(error.status_message);
                    }
                    else {
                        popToast.show('Something went wrong while loading seller details!');
                    }
                    console.log('error dispatched for API error!', error);
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: GET_SELLER_DETAILS_REQUEST } }
    function success(data) { return { type: GET_SELLER_DETAILS_SUCCESS, data } }
    function failure(error) { return { type: GET_SELLER_DETAILS_FAILURE, error } }
}

export const setSellerProfile = (data) => (
    {
        type: SELLER_PROFILE_DETAILS,
        data
    }
)

// ============================= Access Reducer states through this action ============================= //
export const setManageSellerReducerStates = (stateName, value) => {
    return dispatch => {
        let data = {
            state: stateName,
            value: value
        }
        dispatch({ type: SET_MANAGESELLER_REDUCER_STATES, data });
    }
}


// =================================================================================================== //
export const getOwnedProducts = data => {
    return dispatch => {
        dispatch(request());
        ManageSellerService.getOwnedArtWorks(data).then(
            response => {
                if (response && response.message === "Success") {
                    dispatch(success(response));
                } else if (response && response.message === "Failure") {
                    dispatch(failure(response));
                    popToast.show("Get seller details Error : " + response.Message || "Something went wrong while loading seller details!");
                }
            },
            error => {
                if (error && error.status_message) {
                    popToast.show(error.status_message);
                } else {
                    popToast.show("Something went wrong while loading seller details!");
                }
                console.log("error dispatched for API error!", error);
                dispatch(failure(error));
            }
        );
    };

    function request() {
        return { type: GET_OWNED_PRODUCTS_REQUEST };
    }
    function success(data) {
        return { type: GET_OWNED_PRODUCTS_SUCCESS, data };
    }
    function failure(error) {
        return { type: GET_OWNED_PRODUCTS_FAILURE, error };
    }
};

// =================================================================================================== //
export const getGalleryListedInventory = data => {
    return dispatch => {
        dispatch(request());
        ManageSellerService.getGalleryListedInventory(data).then(
            response => {
                if (response && response.message === "Success") {
                    dispatch(success(response));
                } else if (response && response.message === "Failure") {
                    dispatch(failure(response));
                    popToast.show("Get seller details Error : " + response.Message || "Something went wrong while loading seller details!");
                }
            },
            error => {
                if (error && error.status_message) {
                    popToast.show(error.status_message);
                } else {
                    popToast.show("Something went wrong while loading seller details!");
                }
                console.log("error dispatched for API error!", error);
                dispatch(failure(error));
            }
        );
    };

    function request() {
        return { type: GET_GALLERY_LISTED_INVENTORY_REQUEST };
    }
    function success(data) {
        return { type: GET_GALLERY_LISTED_INVENTORY_SUCCESS, data };
    }
    function failure(error) {
        return { type: GET_GALLERY_LISTED_INVENTORY_FAILURE, error };
    }
};
