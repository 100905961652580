import { APIUrlConstants } from "../../util/apiUrl.constant";
import { APIService } from "../../util/api.service";

export class ManageHomeService {
	// Home Banner List
	static getHomeScreenBannerList() {
		let url = APIUrlConstants.getApiUrl("getHomeScreenBannerList");
		return APIService.doGet(url).then(resp => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	// Create Home Banner
	static createHomeScreenBanner(body) {
		let url = APIUrlConstants.getApiUrl("createHomeScreenBanner");
		return APIService.doUploadMultipart(url, body).then(resp => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	// Update Home Banner
	static updateHomeScreenBanner(bannerId, body) {
		let url = APIUrlConstants.getApiUrl("updateHomeScreenBanner");
		url = url + bannerId;
		return APIService.doPutMultipart(url, body).then(resp => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	// Delete Home Banner
	static deleteHomeScreenBanner(bannerItem) {
		let url = APIUrlConstants.getApiUrl("deleteHomeScreenBanner");
		url = url + bannerItem._id;
		return APIService.doDelete(url).then(resp => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}
	// Product List
	static async getParentsByCategoryId(id = "") {
		let url = APIUrlConstants.getApiUrl("getParentsByCategoryId");
		url = url + id;
		const resp = await APIService.doGet(url);
		if (resp) {
			return resp;
		}
		throw resp;
	}
}
