import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AuctionService } from "./AuctionService";
import * as types from "../../redux/actions/ActionsTypes";
import history from "../../util/history";
import { popToast } from "../../components/Toast/Toast";

const useAuctionListAction = () => {
	const dispatch = useDispatch();
	const [isPageLoading, setIsPageLoading] = useState(false);
	const [openAuctionDrawer, setOpenAuctionDrawer] = useState(false);
	const [openAuctionSummaryDrawer, setOpenAuctionSummaryDrawer] = useState(false);
	const { auctionList } = useSelector((s) => s.auctionReducer);

	const auctionObject = {
		name: "",
		description: "",
		startDate: "",
		endDate: "",
		timeGap: "",
		seoTitle: "",
		seoDescription: "",
		keywords: "",
		slugId: "",
		artworksType: "",
	};

	const auctionState = {
		searchValue: "",
	};

	useEffect(() => {
		getAuctionList();
	}, []);

	const getAuctionList = () => {
		setIsPageLoading(true);
		AuctionService.getAuctionList(1, 200000, "", "", "", "")
			.then((resp) => {
				if (resp && resp.result === 200) {
					dispatch(success(types.GET_ALL_AUCTION_SUCCESS, resp.data.auctionList));
				}
				setIsPageLoading(false);
			})
			.catch((error) => {
				setIsPageLoading(false);
			});
	};

	const openAuctionDetailsModal = () => {
		history.push("/auctions/details");
	};

	const closeAuctionDetailsModal = () => {
		setOpenAuctionDrawer(false);
	};

	const onChangeSearch = () => {};
	const addNewAucation = () => {
		history.push("/auctions/details/");
	};

	const onClickEditAuction = (data) => {
		dispatch(success(types.ADD_AUCTION_SUCCESS, data));
		history.push(`/auctions/details/${data._id}`);
	};

	const onClickDeleteAuction = (item) => {
		AuctionService.deleteAuction(item._id)
			.then((resp) => {
				if (resp && resp.result === 200) {
					getAuctionList();
				} else if (resp && resp.result === 400) {
					popToast.show(resp.message);
					setIsPageLoading(false);
				}
			})
			.catch((error) => {
				setIsPageLoading(false);
			});
	};

	const onClickViewAuction = (item) => {
		history.push(`/auctions/summary/${item._id}`);
	};

	return {
		/* states */
		auctionObject,
		isPageLoading,
		auctionList,
		auctionState,
		openAuctionDrawer,
		openAuctionSummaryDrawer,

		/* functions */
		onChangeSearch,
		addNewAucation,
		openAuctionDetailsModal,
		closeAuctionDetailsModal,
		setOpenAuctionSummaryDrawer,
		onClickEditAuction,
		onClickDeleteAuction,
		onClickViewAuction,
	};

	function request(type) {
		return { type };
	}
	function success(type, data) {
		return { type, data };
	}
	function failure(type, error) {
		return { type, error };
	}
};

export default useAuctionListAction;
