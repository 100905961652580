import * as types from "./ActionsTypes";
import { reverse } from "lodash";
import { popToast } from "../../components/Toast/Toast";
import { LookupService } from "../../containers/Lookup/Lookup.service";

// isNewListing value only passed when expanding a category to view its subcategories
export const getAllLookUpProperty = (body) => {
	return (dispatch, getState) => {
		dispatch(request());
		LookupService.getAllPropertyValues(body).then(
			(response) => {
				if (response && response.result === 200) {
					dispatch(success(response.data));
				} else {
					dispatch(failure(response));

					let errorMessage = "Something went wrong while getting categories!";
					if (response && response.message) {
						switch (response.message) {
							default:
								errorMessage = "Something went wrong while getting categories! " + response.message;
								break;
						}
					}
					popToast.show(errorMessage);
				}
			},
			(error) => {
				if (error && error.status_message) {
					popToast.show(error.status_message);
				} else {
					popToast.show("Something went wrong while getting categories!");
				}
				console.log("error dispatched for API error!", error);
				dispatch(failure(error));
			}
		);
	};

	function request() {
		return { type: types.GET_ALL_LOOKUP };
	}
	function success(response) {
		console.log("🚀 ~ file: LookupAction.js ~ line 44 ~ success ~ response", response);
		return { type: types.GET_ALL_LOOKUP_SUCCESS, response };
	}
	function failure(error) {
		return { type: types.GET_ALL_LOOKUP_FAILURE, error };
	}
};
export const insertPropertyValues = (propertyType, body) => {
	return (dispatch) => {
		LookupService.createProperty(body, propertyType).then(
			(response) => {
				if (response && response.result === 200) {
					// Success : Close add category modal
					dispatch(success(response.data));

					// Refresh the current list
					let params = {
						PropertyNames: ["STYLE", "SUBJECT", "MATERIALS", "MEDIUM", "COLLECTIONCATEGORY"],
					};
					dispatch(getAllLookUpProperty(params));
					popToast.show(`successfully added new ${body.name}`);
				} else {
					dispatch(failure(response));

					let errorMessage = "Something went wrong !";
					if (response && response.message) {
						switch (response.message) {
							default:
								errorMessage = response.message;
								break;
						}
					}
					popToast.show(errorMessage);
				}
			},
			(error) => {
				if (error && error.status_message) {
					popToast.show(error.status_message);
				} else {
					popToast.show("Something went wrong while creating category!");
				}
				console.log("error dispatched for API error!", error);
				dispatch(failure(error));
			}
		);
	};

	function request() {
		return { type: types.CREATE_LOOKUP_REQUEST };
	}
	function success(data) {
		return { type: types.CREATE_LOOKUP_SUCCESS, data };
	}
	function failure(error) {
		return { type: types.CREATE_LOOKUP_FAILURE, error };
	}
};

export const updatePropertyValues = (propertyType, body) => {
	return (dispatch) => {
		LookupService.updateProperty(body, propertyType).then(
			(response) => {
				if (response && response.result === 200) {
					// Success : Close add category modal
					dispatch(success(response.data));

					// Refresh the current list
					let params = {
						PropertyNames: ["STYLE", "SUBJECT", "MATERIALS", "MEDIUM", "COLLECTIONCATEGORY"],
					};
					dispatch(getAllLookUpProperty(params));
					popToast.show(`successfully updated ${body.name}`);
				} else {
					dispatch(failure(response));

					let errorMessage = "Something went wrong !";
					if (response && response.message) {
						switch (response.message) {
							default:
								errorMessage = response.message;
								break;
						}
					}
					popToast.show(errorMessage);
				}
			},
			(error) => {
				if (error && error.status_message) {
					popToast.show(error.status_message);
				} else {
					popToast.show("Something went wrong while creating category!");
				}
				console.log("error dispatched for API error!", error);
				dispatch(failure(error));
			}
		);
	};

	function request() {
		return { type: types.UPDATE_LOOKUP_REQUEST };
	}
	function success(data) {
		return { type: types.UPDATE_LOOKUP_SUCCESS, data };
	}
	function failure(error) {
		return { type: types.UPDATE_LOOKUP_FAILURE, error };
	}
};

export const deletePropertyValues = (propertyType, id) => {
	return (dispatch) => {
		LookupService.deleteProperty(id, propertyType).then(
			(response) => {
				if (response && response.result === 200) {
					// Success : Close add category modal
					dispatch(success(response.data));

					// Refresh the current list
					let params = {
						PropertyNames: ["STYLE", "SUBJECT", "MATERIALS", "MEDIUM", "COLLECTIONCATEGORY"],
					};
					dispatch(getAllLookUpProperty(params));
					popToast.show("Data deleted successfully!");
				} else {
					dispatch(failure(response));

					let errorMessage = "Something went wrong !";
					if (response && response.message) {
						switch (response.message) {
							default:
								errorMessage = response.message;
								break;
						}
					}
					popToast.show(errorMessage);
				}
			},
			(error) => {
				if (error && error.status_message) {
					popToast.show(error.status_message);
				} else {
					popToast.show("Something went wrong while creating category!");
				}
				console.log("error dispatched for API error!", error);
				dispatch(failure(error));
			}
		);
	};

	function request() {
		return { type: types.DELETE_LOOKUP_REQUEST };
	}
	function success(data) {
		return { type: types.DELETE_LOOKUP_SUCCESS, data };
	}
	function failure(error) {
		return { type: types.DELETE_LOOKUP_FAILURE, error };
	}
};
