import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import { DialogTitle, DialogContent, CircularProgress } from "@material-ui/core";
import { ManageArtistsService } from "./ManageArtists.service";
import { popToast } from "../../components/Toast/Toast";

const UpdateArtistRank = ({ open, handleClose, record }) => {
    const [updateArtistRankLoader, setUpdateArtistRankLoader] = useState(false);
    const [artistRank, setArtistRank] = useState();

    useEffect(() => {
        if (record) {
            setArtistRank(record.popularityIndex)
        }
    }, [record])

    const onHandleInput = (e) => {
        const { value } = e.target;
        setArtistRank(value);
    }
    const updateRank = async () => {
        try {
            setUpdateArtistRankLoader(true);
            const updateResult = await ManageArtistsService.updateArtistRank(record.userId, artistRank);
            if (updateResult && updateResult.result === 200) {
                handleClose();
                setUpdateArtistRankLoader(false);
            } else if (updateResult && updateResult.result === 400) {
                popToast.show(updateResult.message);
            }
        } catch (error) {
            setUpdateArtistRankLoader(false);
        }

    }
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            BackdropProps={{ classes: { root: "dialog-backdrop" } }}
            className="add-carrer-level-dialog"
        >
            <img className="close-btn" onClick={handleClose} src={require("../../assets/images/common/Close_h.svg")} alt="" />
            <DialogTitle className="dialog-title">Update Artist Rank</DialogTitle>
            <DialogContent className="add-carrer-level-content">
                <div className="add-carrer-level-body">
                    <div className="form-control">
                        <label className="form-label">Rank</label>
                        <input
                            type="text"
                            autoFocus
                            className="form-input"
                            value={artistRank}
                            onChange={onHandleInput}
                        />
                    </div>
                </div>

                <div className="add-carrer-level-action">
                    <button className="btn-cancel" onClick={handleClose}>
                        Cancel
                    </button>
                    <button className="btn-orange" onClick={updateRank}>
                        Update
                        {(updateArtistRankLoader) && <CircularProgress size={24} className="circular-loader modal-button-loader" />}
                    </button>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default UpdateArtistRank;