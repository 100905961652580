import {
    GET_SHIPPING_CHARGES_LIST_REQUEST, GET_SHIPPING_CHARGES_LIST_SUCCESS, GET_SHIPPING_CHARGES_LIST_FAILURE,
    ADD_SHIPPING_CHARGES_REQUEST, ADD_SHIPPING_CHARGES_SUCCESS, ADD_SHIPPING_CHARGES_FAILURE,
    UPDATE_SHIPPING_CHARGES_REQUEST, UPDATE_SHIPPING_CHARGES_SUCCESS, UPDATE_SHIPPING_CHARGES_FAILURE,
    DELETE_SHIPPING_CHARGES_REQUEST, DELETE_SHIPPING_CHARGES_SUCCESS, DELETE_SHIPPING_CHARGES_FAILURE, TOGGLE_ADD_SHIPPING_CHARGES_DIALOG
} from "./ActionsTypes";
import { popToast } from "../../components/Toast/Toast";
import { ShippingChargesService } from "../../containers/ShippingCharges/ShippingCharges.service";

// ============================= Get Shipping Charges List ============================= //
export const getShippingChargesListAction = (pageNum, pageSize, countryName) => {
    return dispatch => {
        dispatch(request());
        ShippingChargesService.getShippingChargesList(pageNum, pageSize, countryName).then(
            response => {
                if (response && response.message === "Success") {
                    dispatch(success(response.data));
                } else if (response && response.message === "Failure") {
                    dispatch(failure(response));
                    popToast.show("Get shipping charges list Error : " + response.message || "Something went wrong while loading shipping charges list!");
                }
            },
            error => {
                if (error && error.status_message) {
                    popToast.show(error.status_message);
                } else {
                    popToast.show("Something went wrong while loading shipping charges list!");
                }
                console.log("error dispatched for API error!", error);
                dispatch(failure(error));
            }
        );
    };

    function request() {
        return { type: GET_SHIPPING_CHARGES_LIST_REQUEST };
    }
    function success(data) {
        return { type: GET_SHIPPING_CHARGES_LIST_SUCCESS, data };
    }
    function failure(error) {
        return { type: GET_SHIPPING_CHARGES_LIST_FAILURE, error };
    }
};

// ============================= Add Shipping Charges ============================= //
export const addShippingChargesAction = (shippingData) => {
    return dispatch => {
        dispatch(request());
        ShippingChargesService.addShippingCharges(shippingData).then(
            response => {
                if (response && response.result === 200 && response.message === "Success") {
                    dispatch(success(response.data));
                    popToast.show("Added Successfully");
                    dispatch(getShippingChargesListAction());
                } else if (response && response.message) {
                    dispatch(failure(response));
                    popToast.show(response.message || "Something went wrong while adding shipping charge!");
                }
            },
            error => {
                if (error && error.status_message) {
                    popToast.show(error.status_message);
                } else {
                    popToast.show("Something went wrong while adding shipping charge!");
                }
                console.log("error dispatched for API error!", error);
                dispatch(failure(error));
            }
        );
    };

    function request() {
        return { type: ADD_SHIPPING_CHARGES_REQUEST };
    }
    function success(data) {
        return { type: ADD_SHIPPING_CHARGES_SUCCESS, data };
    }
    function failure(error) {
        return { type: ADD_SHIPPING_CHARGES_FAILURE, error };
    }
};

// ============================= Update Shipping Charges ============================= //
export const updateShippingChargesAction = (shippingData, shippingId) => {
    return dispatch => {
        dispatch(request());
        ShippingChargesService.updateShippingCharges(shippingData, shippingId).then(
            response => {
                if (response && response.message === "Success") {
                    dispatch(success(response.data));
                    popToast.show("Updated Successfully");
                    dispatch(getShippingChargesListAction());
                } else if (response && response.message === "Failure") {
                    dispatch(failure(response));
                    popToast.show("Update Shipping Charge Error : " + response.message || "Something went wrong while updating shipping charge!");
                }
            },
            error => {
                if (error && error.status_message) {
                    popToast.show(error.status_message);
                } else {
                    popToast.show("Something went wrong while updating shipping charge!");
                }
                console.log("error dispatched for API error!", error);
                dispatch(failure(error));
            }
        );
    };

    function request() {
        return { type: UPDATE_SHIPPING_CHARGES_REQUEST };
    }
    function success(data) {
        return { type: UPDATE_SHIPPING_CHARGES_SUCCESS, data };
    }
    function failure(error) {
        return { type: UPDATE_SHIPPING_CHARGES_FAILURE, error };
    }
};

// ============================= Delete Shipping Charges ============================= //
export const deleteShippingChargesAction = shippingId => {
    return dispatch => {
        dispatch(request());
        ShippingChargesService.deleteShippingCharges(shippingId).then(
            response => {
                if (response && response.message === "Success") {
                    dispatch(success(response.data));
                    popToast.show("Deleted Successfully");
                    dispatch(getShippingChargesListAction());
                } else if (response && response.message === "Failure") {
                    dispatch(failure(response));
                    popToast.show("Delete Shipping Charges Error : " + response.message || "Something went wrong while deleting shipping charges!");
                }
            },
            error => {
                if (error && error.status_message) {
                    popToast.show(error.status_message);
                } else {
                    popToast.show("Something went wrong while deleting shipping charges!");
                }
                console.log("error dispatched for API error!", error);
                dispatch(failure(error));
            }
        );
    };

    function request() {
        return { type: DELETE_SHIPPING_CHARGES_REQUEST };
    }
    function success(data) {
        return { type: DELETE_SHIPPING_CHARGES_SUCCESS, data };
    }
    function failure(error) {
        return { type: DELETE_SHIPPING_CHARGES_FAILURE, error };
    }
};

// ============================= Toggle Add Shipping Charges Dialog ============================= //
export const toggleAddShippingChargesDialog = data => ({
    type: TOGGLE_ADD_SHIPPING_CHARGES_DIALOG,
    data
});
