/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import assets from "../../../../assets";
import {
	Wrapper,
	CameraIconOnBanner,
	CameraIconOnPicture,
	BackgroundBanner,
	SaveDiv,
	SaveButton,
	StyledRow,
	ProfileImageRow,
	NormalDiv,
	NormalImg,
	Hr
} from "./DisplayHeader.styled";
import { Col, Modal, Skeleton } from "antd";
import { useSelector } from "react-redux";
import { putFormData } from "../../../../util/apiConfig";
import { popToast } from "../../../../components/Toast/Toast";
import { Dialog, DialogContent, DialogTitle, Slide } from "@material-ui/core";
import CropImage from "../../../../components/CropImage/CropImage";
import AddBackgroundDropDialog from "./AddBackgroundDropDialog";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const DisplayHeader = ({ userId, getArtistDetails }) => {
	const { getArtistProfile, getArtistLoader, featureReducer, featureLoader } = useSelector(state => state.manageArtistsReducer)

	const [bgEditorShown, setBgEditorShown] = useState(false);
	const [dpEditorShown, setDpEditorShown] = useState(false);
	const [media, setMedia] = useState(null);
	const [mediaThumb, setMediaThumb] = useState(null);
	const [profileMedia, setprofileMedia] = useState(null);
	const [, setprofileMediaThumb] = useState(null);
	const [, setBannerCameraIcon] = useState();
	const [updatingProfileImages, setUpdatingProfileImages] = useState(false);
	const nonWhiteSpacesRegex = /.*\S.*/;

	useEffect(() => {
		setprofileMediaThumb(false);
		setprofileMedia(false);
	}, [dpEditorShown]);

	useEffect(() => {
		setMedia(false);
		setMediaThumb(false);
	}, [bgEditorShown]);

	const handleBackgroundBanner = () => {
		setBgEditorShown(true);
	};
	const handleProfilePicture = () => {
		setDpEditorShown(true);
	};

	const handleBgEditorClose = () => {
		setBgEditorShown(false);
		setMediaThumb(null);
	};
	const handleDpEditorClose = () => {
		setDpEditorShown(false);
		setprofileMediaThumb(null);
	};


	useEffect(() => {

		setMediaThumb(getArtistProfile.coverPhoto);
		setprofileMediaThumb(getArtistProfile.profilePhoto);

	}, [getArtistProfile]);



	// On cover image select
	const onImageSelect = (file) => {
		// CROP_IMAGE
		// save file before calling api
		setMedia(file);
	};

	//Update cover photo
	const updateCoverPhoto = () => {
		if (!media) {
			popToast.show('Please upload an image');
		} else {
			setUpdatingProfileImages(true);
			let token = localStorage.getItem('authToken')
			let formBody = new FormData();
			formBody.append("coverPhoto", media);
			putFormData(`UserManagment/api/Artists/UpdateArtistProfileImage?ManagedArtistId=${userId}`, formBody, token).then(resp => {
				if (resp && resp.response && resp.response.data) {
					popToast.show('Successfully updated');
					setMedia(null);
					setMediaThumb(null);
					setprofileMedia(null);
					setprofileMediaThumb(false);
					handleDpEditorClose();
					handleBgEditorClose();
					getArtistDetails();
				} else if (resp && resp.error) {
					popToast.show(resp.error.message)
				}
				setUpdatingProfileImages(false);
			}).catch(error => {
				setUpdatingProfileImages(false);
			})
		}
	};

	// On profile image select
	const onProfileImageSelect = (file) => {
		// e.preventDefault();
		let reader = new FileReader();
		// let file = e.target.files[0];
		let image = "";
		if (file) {
			reader.onloadend = () => {
				setprofileMedia(file);
				setprofileMediaThumb(reader.result);
				image = reader.result;
			};
			reader.readAsDataURL(file);
		} else {
			setprofileMedia(null);
			setprofileMediaThumb(null);
		}
	};

	//Update Profile Image
	const updateProfilePhoto = () => {
		if (!profileMedia) {
			popToast.show('Please upload an image');
		} else {
			setUpdatingProfileImages(true);
			let token = localStorage.getItem('authToken');
			let formBody = new FormData();
			formBody.append("profilePhoto", profileMedia);
			putFormData(`UserManagment/api/Artists/UpdateArtistProfileImage?ManagedArtistId=${userId}`, formBody, token).then(resp => {
				if (resp && resp.response && resp.response.data) {
					getArtistDetails();
					popToast.show('Successfully updated');
					setMedia(null);
					setMediaThumb(null);
					setprofileMedia(null);
					setprofileMediaThumb(false);
					handleDpEditorClose();
					handleBgEditorClose();

				} else if (resp && resp.error) {
					popToast.show(resp.error.message)
				}
				setUpdatingProfileImages(false);
			}).catch(error => {
				setUpdatingProfileImages(false);
			})
		}
	};



	return (
		<>
			<Skeleton loading={getArtistLoader} paragraph={{ rows: 8 }} active>
				<Wrapper>
					<BackgroundBanner
						srcWebp={
							getArtistProfile && getArtistProfile.coverPhoto
								? getArtistProfile.coverPhoto
								: assets.images.emptyBanner

						}
						fallback={
							getArtistProfile && getArtistProfile.coverPhotoUC
								? getArtistProfile.coverPhotoUC
								: assets.images.emptyBanner

						}
						onMouseEnter={() => {
							setBannerCameraIcon(true);
						}}
						onMouseLeave={() => {
							setBannerCameraIcon(false);
						}}
						onClick={() => {
							handleBackgroundBanner();
						}}
					/>

					<CameraIconOnBanner
						src={assets.images.cameraIcon}
						onClick={() => {
							handleBackgroundBanner();
						}}
					/>

					<ProfileImageRow
						type="flex"
						align="middle"

						paddingtop={document.documentMode || /Edge/.test(navigator.userAgent) ? "75px" : "85px"}
					>

						<div className="profile-pic-wrapper">
							<NormalDiv>
								<NormalImg
									srcWebp={
										getArtistProfile && getArtistProfile.profilePhoto
											? getArtistProfile.profilePhoto
											: assets.images.emptyArtistDp

									}
									fallback={
										getArtistProfile && getArtistProfile.profilePhotoUC
											? getArtistProfile.profilePhotoUC
											: assets.images.emptyArtistDp

									}
									alt={
										`RtistiQ Artist:${getArtistProfile && getArtistProfile.name}${getArtistProfile && getArtistProfile.place
										}`
									}
									onClick={() => {
										handleProfilePicture();
									}}
								/>

								<div className="camera-icon-wrapper">
									<CameraIconOnPicture
										top={document.documentMode || /Edge/.test(navigator.userAgent) ? "60%" : "60%"}
										left={document.documentMode || /Edge/.test(navigator.userAgent) ? "59%" : "59%"}
										src={assets.images.cameraIcon}
										onClick={() => {
											handleProfilePicture();
										}}
									/>
								</div>

							</NormalDiv>
						</div>

						<Col xs={0} sm={2} md={1} lg={2} xl={1} xxl={1}></Col>
					</ProfileImageRow>
				</Wrapper>
			</Skeleton>


			<AddBackgroundDropDialog
				open={bgEditorShown}
				mediaThumb={mediaThumb}
				onImageSelect={onImageSelect}
				updateCoverPhoto={updateCoverPhoto}
				loading={updatingProfileImages}
				handleBgEditorClose={handleBgEditorClose}
				imgType="artistBd"
			/>


			<Dialog
				open={dpEditorShown}
				onClose={handleDpEditorClose}
				BackdropProps={{ classes: { root: "dialog-backdrop" } }}
				TransitionComponent={Transition}
				width="25rem"
			>
				<img className="close-btn" onClick={handleDpEditorClose} src={require("../../../../assets/images/common/Close_h.svg")} alt="" />
				<DialogTitle>
					Update your Profile Picture
				</DialogTitle>
				<DialogContent>
					<StyledRow>
						<Col>
							<CropImage onSelectCroppedImage={onProfileImageSelect} imgType="artistDp" />
						</Col>
					</StyledRow>
					<Hr width="2px" />
					<SaveDiv>
						<SaveButton
							variant="invert"
							htmlType="submit"
							onClick={() => {
								updateProfilePhoto();
							}}
							loading={updatingProfileImages}
						>
							Save
						</SaveButton>
					</SaveDiv>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default DisplayHeader;
