import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import { DialogTitle, DialogContent, Slide, CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// SCSS
import "./EditorModalDialog.scss";

//Components
import { popToast } from "../Toast/Toast";
import { APIUrlConstants } from "../../util/apiUrl.constant";
import { Util } from "../../util/util";
import { APIService } from "../../util/api.service";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

class EditorModalDialog extends Component {
	constructor(props) {
		super(props);
		this.state = {
			title: "",
			slugId: "",
			seoDescription: "",
			editorState: EditorState.createEmpty(),
			mediaThumb: "",
			media: "",
			uploadedImages: [],
			auctionId: "",
		};
		this._uploadImageCallBack = this._uploadImageCallBack.bind(this);
	}

	componentDidUpdate(prevProps, prevState) {
		if (!prevProps.open && this.props.open) {
			const updateData = this.props.data;
			this.setState({
				title: (updateData && updateData.title) || "",
				slugId: (updateData && updateData.slugId) || "",
				seoDescription: (updateData && updateData.seoDescription) || "",
				editorState:
					(updateData &&
						updateData.body &&
						EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(updateData.body)))) ||
					EditorState.createEmpty(),
				mediaThumb: (updateData && updateData.image) || "",
			});
		}
	}
	onChangeAuction(e) {
		console.log("🚀 ~ file: EditorModalDialog.js ~ line 59 ~ EditorModalDialog ~ onChangeAuction ~ e.target.value", e.target.value);
		this.setState({
			auctionId: e.target.value,
		});
	}

	render() {
		const { editorState } = this.state;
		return (
			<Dialog
				fullScreen
				open={this.props.open}
				onClose={this.props.handleClose}
				TransitionComponent={Transition}
				BackdropProps={{ classes: { root: "dialog-backdrop" } }}
				className="editor-modal-dialog"
			>
				<DialogTitle className="dialog-title">Add {this.props.type}</DialogTitle>
				<DialogContent className="editor-modal-content">
					<div className="editor-main-wrapper">
						{this.state.mediaThumb ? (
							<label className="upload-box bg-white" onClick={(e) => this.attach_media.click()}>
								<img className="media-thumb" src={this.state.mediaThumb} alt="Attach media" />
							</label>
						) : (
							<div className="upload-box" onClick={(e) => this.attach_media.click()}>
								<img className="image-upload" src={require("../../assets/images/common/AddPhoto.svg")} alt="Upload" />
								Upload Image
							</div>
						)}
						<input
							type="file"
							style={{ display: "none" }}
							accept=".bmp, .gif, .ico, .jpeg, .jpg, .png, .svg, .tif, .tiff, .jiff, .jfif, .pjpeg, .pjp"
							onChange={(e) => this.onImageSelect(e)}
							ref={(ref) => (this.attach_media = ref)}
						/>
						<div className="form-control">
							<label className="form-label">Title</label>
							<input type="text" autoFocus className="form-input" value={this.state.title} onChange={this.onHandleInput("title")} />
						</div>
						<div className="form-control">
							<label className="form-label">SlugId</label>
							<input type="text" className="form-input" value={this.state.slugId} onChange={this.onHandleInput("slugId")} />
						</div>
						<div className="form-control">
							<label className="form-label">SEO Description</label>
							<input
								type="text"
								className="form-input"
								value={this.state.seoDescription}
								onChange={this.onHandleInput("seoDescription")}
							/>
						</div>

						<Editor
							toolbar={{
								options: ["blockType", "inline", "list", "image"],
								inline: {
									visible: true,
									inDropdown: false,
									options: ["bold", "italic", "underline"],
								},
								image: {
									urlEnabled: true,
									uploadEnabled: false,
									alignmentEnabled: true,
									uploadCallback: this._uploadImageCallBack,
									previewImage: true,
									inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
									alt: { present: false, mandatory: false },
									defaultSize: {
										height: "auto",
										width: "auto",
									},
								},
								inputAccept:
									"application/pdf,text/plain,application/vnd.openxmlformatsofficedocument.wordprocessingml.document,application/msword,application/vnd.ms-excel",
							}}
							editorState={editorState}
							onEditorStateChange={this.onEditorStateChange()}
							editorStyle={{
								height: "300px",
								border: "1px solid #E5E5E5",
								padding: "10px",
								fontFamily: "Times New Roman",
								color: "black",
								fontWeight: "100",
								width: "100%",
							}}
							placeholder="Add some contents here"
						/>
						<div className="ButtonsWrapper">
							<button className="CancelButton" onClick={this.onClickCancel.bind(this)}>
								CANCEL
							</button>
							<button disabled={this.props.loader} className="PublishButton" onClick={this.onClickCreate.bind(this)}>
								PUBLISH
								{this.props.loader && <CircularProgress size={24} className="circular-loader modal-button-loader" />}
							</button>
						</div>
					</div>
				</DialogContent>
			</Dialog>
		);
	}

	// async _uploadImageCallBack(file) {
	//     console.log('file', file)
	//     let request = {
	//         "Image": file,
	//         "AlternateText": "blogImage"
	//     };
	//     let uploadedImages = this.state.uploadedImages;
	//     // static addBlog(request) {
	//     // let url = APIUrlConstants.getApiUrl("createImageGallery");
	//     // let formBodyRequest = Util.objectToFormData(request);
	//     // let data = await APIService.doUploadMultipart(url, formBodyRequest).then(resp => {
	//     //     if (resp && resp.data && resp.data.imageUC) {
	//     //         return resp;
	//     //     }
	//     //     throw resp;
	//     // });
	//     // console.log('data', data)
	//     // }
	//     //imageUC
	//     const imageObject = {
	//         file: file,
	//         localSrc: URL.createObjectURL(file),
	//     }

	//     uploadedImages.push(imageObject);

	//     this.setState({ uploadedImages: uploadedImages })
	//     // We need to return a promise with the image src
	//     // the img src we will use here will be what's needed
	//     // to preview it in the browser. This will be different than what
	//     // we will see in the index.md file we generate.
	//     return new Promise(
	//         (resolve, reject) => {
	//             resolve({ data: { link: data && data.data && data.data.imageUC } });
	//         }
	//     );
	// }
	_uploadImageCallBack(file) {
		return new Promise(async (resolve, reject) => {
			let request = {
				Image: file,
				AlternateText: "blogImage",
			};
			let url = APIUrlConstants.getApiUrl("createImageGallery");
			let formBodyRequest = Util.objectToFormData(request);
			let response = await APIService.doUploadMultipart(url, formBodyRequest).then((resp) => {
				if (resp && resp.result === 200 && resp.message === "Success") {
					return resp;
				}
				throw resp;
			});
			if (response && response.data && response.data.imageUC) {
				resolve({ data: { link: response.data.imageUC } });
			} else {
				console.log(response);
				reject(response);
			}

			// const xhr = new XMLHttpRequest();
			// xhr.open('POST', 'https://api.imgur.com/3/image');
			// xhr.setRequestHeader('Authorization', 'Client-ID ##clientid##');
			// const data = new FormData();
			// data.append('image', file);
			// xhr.send(data);
			// xhr.addEventListener('load', () => {
			//     const response = JSON.parse(xhr.responseText);
			//     console.log(response)
			//     resolve(response);
			// });
			// xhr.addEventListener('error', () => {
			//     const error = JSON.parse(xhr.responseText);
			//     console.log(error)
			//     reject(error);
			// });
		});
	}
	convertToSlug = (Text) => {
		return Text.toLowerCase()
			.replace(/[^\w ]+/g, "")
			.replace(/ +/g, "-");
	};
	// Handle inputs
	onHandleInput = (name) => (event) => {
		if (name === "title") {
			this.setState({
				slugId: this.convertToSlug(event.target.value),
			});
		}
		this.setState({
			[name]: event.target.value,
		});
	};
	// On select image
	onImageSelect(e) {
		e.preventDefault();
		let reader = new FileReader();
		let file = e.target.files[0];
		let image = "";
		if (file) {
			reader.onloadend = () => {
				this.setState({
					media: file,
					mediaThumb: reader.result,
				});
				image = reader.result;
			};
			reader.readAsDataURL(file);
		}
	}

	isFormValid() {
		if (!this.state.title.trim()) {
			popToast.show("Please enter title");
			return false;
		}
		if (!this.state.slugId.trim()) {
			popToast.show("Please enter slugId");
			return false;
		}
		if (!this.state.seoDescription.trim()) {
			popToast.show("Please enter SEO description");
			return false;
		}
		if (this.state.editorState) {
			let content = this.state.editorState.getCurrentContent();
			let currentPlainText = content.getPlainText();
			let lengthOfTrimmedContent = currentPlainText.trim().length;
			if (lengthOfTrimmedContent < 1) {
				popToast.show("Please enter contents");
				return false;
			} else if (lengthOfTrimmedContent < 10) {
				popToast.show("Content is too short");
				return false;
			}
		}
		if (!this.state.mediaThumb) {
			if (!this.state.media) {
				popToast.show("Please choose an image");
				return false;
			}
			if (this.state.media.type && !this.state.media.type.startsWith("image/")) {
				popToast.show("Please choose a valid image file");
				return false;
			}
		}
		return true;
	}
	onEditorStateChange = () => (editorState) => {
		this.setState({
			editorState,
		});
	};

	onClickCreate() {
		if (this.isFormValid()) {
			let request = {};
			if (this.state.media) {
				request = {
					Title: this.state.title,
					SlugId: this.state.slugId,
					SeoDescription: this.state.seoDescription,
					Body: draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())),
					BlogImages: this.state.media,
					AuctionId: this.state.auctionId,
				};
			} else {
				request = {
					Title: this.state.title,
					SlugId: this.state.slugId,
					SeoDescription: this.state.seoDescription,
					Body: draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())),
					AuctionId: this.state.auctionId,
				};
			}

			if (this.props.update) {
				if (this.props.type === "News") {
					this.props.updateNews(request, this.props.data._id);
				} else {
					this.props.updateBlogs(request, this.props.data._id);
				}
			} else {
				if (this.props.type === "News") {
					this.props.addNews(request);
				} else {
					this.props.addBlog(request);
				}
			}
		}
	}

	onClickCancel() {
		this.setState({
			...this.state,
			title: "",
			slugId: "",
			seoDescription: "",
			editorState: EditorState.createEmpty(),
			mediaThumb: "",
			media: "",
			auctionId: "",
		});
		this.props.handleClose();
	}
}

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(EditorModalDialog);
