import React, { Component } from "react";
import "./TileComponent.scss";
import { connect } from "react-redux";
import { deleteBlogsAction, updateBlogsAction } from "../../redux/actions/BlogAction";
import ConfirmationMessageDialog from "../ConfirmationMessageDialog/ConfirmationMessageDialog";
import { Empty } from "antd";
import { deleteNewsAction, updateNewsAction } from "../../redux/actions/NewsAction";
import SafeHtmlRenderer from "../SafeHTMLRender/SafeHTMLRender";
import EditorModalDialog from "../EditorModalDialog/EditorModalDialog";
import EditorModal from "../EditorModal/EditorModal";

class TileComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			toggleDelete: false,
			deleteId: "",
			isAddClicked: false,
			isAddNewsClicked: false,
			isTitleClicked: false,
		};
	}

	render() {
		const blogData = this.props.BlogData || null;
		const newsData = this.props.newsData || null;
		const editData = {
			title: this.props.BlogData ? blogData.title : newsData.title,
			slugId: this.props.BlogData ? blogData.slugId : "",
			seoDescription: this.props.BlogData ? blogData.seoDescription : "",
			image: this.props.BlogData ? blogData.image : newsData.image,
			body: this.props.BlogData ? blogData.body : newsData.content,
			_id: this.props.BlogData ? blogData._id : newsData._id,
			website: this.props.newsData ? newsData.website : "",
			newsDate: this.props.newsData ? newsData.newsDate : "",
			content: this.props.newsData ? newsData.content : "",
			publication: this.props.newsData ? newsData.publication : "",
			author: this.props.newsData ? newsData.author : "",
			externalImageURL: this.props.newsData ? newsData.externalImageURL : "",
			link: this.props.newsData ? newsData.link : "",
		};
		return blogData || newsData ? (
			<div className="tile">
				{/* ------------- Image Section ----------------- */}
				<div className="imgContainer">
					{blogData && (
						<img
							src={blogData.image || require("../../assets/images/common/No_Image.png")}
							onClick={() => {
								this.setState({ isTitleClicked: !this.state.isTitleClicked });
							}}
							alt=""
							className="imgContainer-image"
						/>
					)}
					{newsData && (
						<img
							src={newsData.image || newsData.externalImageURL || require("../../assets/images/common/No_Image.png")}
							onClick={() => {
								this.setState({ isTitleClicked: !this.state.isTitleClicked });
							}}
							alt=""
							className="imgContainer-image"
						/>
					)}
				</div>
				{/* ------------------ Content Section ------------------- */}
				<div className="content">
					{blogData && (
						<p
							className="title pl-1"
							onClick={() => {
								this.setState({ isTitleClicked: !this.state.isTitleClicked });
							}}
						>
							{blogData.title || "Unknown"}
						</p>
					)}
					{newsData && (
						<p
							className="title pl-1"
							onClick={() => {
								this.setState({ isTitleClicked: !this.state.isTitleClicked });
							}}
						>
							{newsData.title || "Unknown"}
						</p>
					)}
					{this.state.isTitleClicked && blogData && <SafeHtmlRenderer className="pl-1" body={blogData.body} />}
					{this.state.isTitleClicked && newsData && <SafeHtmlRenderer className="pl-1" body={newsData.content} />}
					<p className="text-semibold">
						<span className="text-black pl-1">Date</span>{" "}
						{blogData && <span className="text-red">{new Date(blogData.blogDate).toLocaleDateString()}</span>}
						{newsData && <span className="text-red">{new Date(newsData.newsDate).toLocaleDateString()}</span>}
					</p>
				</div>

				{/* ------------------------------- Edit/Delete ------------------------------- */}
				<div className="action">
					{newsData && (
						<span className="row" onClick={this.toggleNewsPopup.bind(this)}>
							<img src={require("../../assets/images/common/Edit.svg")} alt="" />
							<h4>Edit</h4>
						</span>
					)}
					{blogData && blogData.userRole === 2 && (
						<span className="row" onClick={this.togglePopup.bind(this)}>
							<img src={require("../../assets/images/common/Edit.svg")} alt="" />
							<h4>Edit</h4>
						</span>
					)}
					<span
						className="row"
						onClick={blogData ? this.toggleDeletePopup.bind(this, blogData._id) : this.toggleDeletePopup.bind(this, newsData._id)}
					>
						<img src={require("../../assets/images/common/delete.svg")} alt="" />
						<h4>Delete</h4>
					</span>
				</div>
				<ConfirmationMessageDialog
					open={this.state.toggleDelete}
					handleClose={this.toggleDeletePopup.bind(this)}
					confirmMessage={"Are you sure you want to delete?"}
					confirmButton={"Delete"}
					loader={this.props.deleteBlogLoader || this.props.deleteNewsLoader}
					onConfirm={this.onDelete.bind(this)}
				/>
				<EditorModal
					type={this.props.BlogData ? "Blog" : "News"}
					open={this.state.isAddNewsClicked}
					data={editData}
					update={true}
					handleClose={this.toggleNewsPopup.bind(this)}
					updateNews={this.updateNews.bind(this)}
					updateBlogs={this.updateBlogs.bind(this)}
					loader={this.props.updateNewsLoader || this.props.updateBlogLoader}
				/>

				<EditorModalDialog
					type={this.props.BlogData ? "Blog" : "News"}
					open={this.state.isAddClicked}
					data={editData}
					update={true}
					handleClose={this.togglePopup.bind(this)}
					updateNews={this.updateNews.bind(this)}
					updateBlogs={this.updateBlogs.bind(this)}
					loader={this.props.updateNewsLoader || this.props.updateBlogLoader}
				/>
			</div>
		) : (
			<div className="tile">
				<Empty />
			</div>
		);
	}

	toggleDeletePopup() {
		this.setState({
			toggleDelete: !this.state.toggleDelete,
		});
	}

	onDelete() {
		if (this.props.BlogData) {
			this.props.deleteBlog(this.props.BlogData._id);
		} else {
			this.props.deleteNews(this.props.newsData._id);
		}
		this.toggleDeletePopup();
	}

	updateNews(data, id) {
		let newsId = id;
		let body = {
			Title: data.Title,
			NewsImage: data.BlogImages,
			Content: data.Body,
			Website: data.Website,
			NewsDate: data.NewsDate,
			Publication: data.Publication,
			Author: data.Author,
			ExternalImageURL: data.ExternalImageURL,
			Link: data.Link,
		};
		this.props.updateNews(newsId, body);
	}

	updateBlogs(data, id) {
		let blogId = id;
		let body = {
			Title: data.Title,
			SlugId: data.SlugId,
			SeoDescription: data.SeoDescription,
			BlogImages: data.BlogImages,
			Body: data.Body,
			AuctionId: data.AuctionId,
		};
		this.props.updateBlog(blogId, body);
	}

	togglePopup() {
		this.setState({
			isAddClicked: !this.state.isAddClicked,
		});
	}
	toggleNewsPopup() {
		this.setState({
			isAddNewsClicked: !this.state.isAddNewsClicked,
		});
	}
}

const mapStateToProps = (state) => {
	return {
		deleteBlogResult: state.blogReducer.deleteBlogResult,
		deleteBlogLoader: state.blogReducer.deleteBlogLoader,
		deleteNewsResult: state.newsReducer.deleteNewsResult,
		deleteNewsLoader: state.newsReducer.deleteNewsLoader,
		updateNewsLoader: state.newsReducer.updateNewsLoader,
		updateBlogLoader: state.blogReducer.updateBlogLoader,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		deleteBlog: (blogId) => {
			dispatch(deleteBlogsAction(blogId));
		},
		deleteNews: (newsId) => {
			dispatch(deleteNewsAction(newsId));
		},
		updateNews: (newsId, requestBody) => {
			dispatch(updateNewsAction(newsId, requestBody));
		},
		updateBlog: (blogId, requestBody) => {
			dispatch(updateBlogsAction(blogId, requestBody));
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(TileComponent);
