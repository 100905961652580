import React from "react";
import { Dialog, Slide, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import { Table, Tooltip } from "antd";
import { AddNftCollectionWrapper, AddNftInputWrapper, CancelButton, ContractInputWrapperDiv, NextButton, SaveButtonWrapper } from "../AuctionDetails.styled";
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const AddNftCollection = ({ openNftCollectionDrawer, onCloseNftCollectionDrawer, contractObj, onHandleInputChange, onClickSubmit }) => {




    return (
        <Dialog
            fullWidth
            open={openNftCollectionDrawer}
            onClose={() => onCloseNftCollectionDrawer(false)}
            TransitionComponent={Transition}
            BackdropProps={{ classes: { root: "dialog-backdrop" } }}
            className="add-coupon-dialog"
        >
            <img className="close-btn" onClick={() => onCloseNftCollectionDrawer(false)} src={require("../../../../assets/images/common/Close_h.svg")} alt="" />

            <DialogContent className="add-coupon-content">
                <AddNftCollectionWrapper>
                    <h3>
                        Create New Contract
                    </h3>
                    {contractObj && <AddNftInputWrapper>
                        <div className="form-control">
                            <ContractInputWrapperDiv>
                                <label className="form-label">NAME</label>
                                <Tooltip placement="topLeft" title={`A descriptive name for a collection of NFTs in this contract. For E.g. "Raja Ravi Varma Phygital NFT"`}>
                                    <img src={require("../../../../assets/images/common/infobutton.svg")} />
                                </Tooltip>
                            </ContractInputWrapperDiv>
                            <input
                                type="text"
                                autoFocus
                                className="form-input"
                                name="ContractName"
                                value={contractObj.ContractName}
                                onChange={onHandleInputChange}
                                maxLength={50}
                            />

                        </div>
                        <div className="form-control">
                            <ContractInputWrapperDiv>
                                <label className="form-label">SYMBOL</label>
                                <Tooltip placement="topLeft" title={`An abbreviated name for NFTs in this contract. For E.g. "RRVPN"`}>
                                    <img src={require("../../../../assets/images/common/infobutton.svg")} />
                                </Tooltip>

                            </ContractInputWrapperDiv>
                            <input
                                type="text"
                                className="form-input"
                                name="ContractSymbol"
                                value={contractObj.ContractSymbol}
                                onChange={onHandleInputChange}
                                maxLength={10}
                            />

                        </div>
                    </AddNftInputWrapper>}
                    <SaveButtonWrapper>
                        <CancelButton onClick={() => onCloseNftCollectionDrawer(false)}>CANCEL</CancelButton>
                        <NextButton onClick={() => onClickSubmit(true)}>SUBMIT</NextButton>
                    </SaveButtonWrapper>

                </AddNftCollectionWrapper>


            </DialogContent>
        </Dialog>

    )
}

export default AddNftCollection;
