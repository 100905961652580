import { Button, Col, Divider, Empty, Icon, Input, Pagination, Row, Select, Switch, Table } from "antd";
import { isEmpty } from "lodash";
import React from "react";
import { Util } from "../../../../util/util";
import { AuctionLotTable, ContentTabButtonWrapper, EditButton, FilterSelectDropDown, SortByDropdownWrapper, SortSelectDropDown, SummaryContentWrapper, SummaryDetailsHeading, TabButtons, TotalBidCount } from "../AuctionSummary.styled";
import useSummaryContent from "./useSummaryContent";
import moment from "moment";
import BiddingHistory from "../BiddingHistory";

const { Option } = Select;

const SummaryContent = ({ auctionId, auctionObject, onClickEditAuctionLot, onClickAddAuctionSlot }) => {
    const { auctionListCount, auctionLotObjects, activeTab, deleteLotLoader,
        updateLotLoader, swapLoader, searchLotFilterRef, auctionLotFilter, getAuctionBiddingHistoryLoader,
        getAuctionLotLoader, auctionBiddingHistory, openBiddingHistoryDrawer, totalBidCount,

        setCurrentTab, searchAuctionLots, onClickDeleteAuctionLot, onSelectPageNumber, swapAuctionLot,
        updateAuctionLot, onClickViewBiddingHistory, setOpenBiddingHistoryDrawer, onSelectSortOption,
        onSelectBidResult } = useSummaryContent(auctionId);



    const SummaryTableFormat = () => {
        const columns = [
            {
                title: 'LOT NO.',
                render: (item, record, index) => {
                    return (
                        index + 1
                    )
                }
            },
            { title: 'LATEST BID', dataIndex: 'highestBid', key: 'highestBid' },
            { title: 'NO. OF BIDS RECEIVED', dataIndex: 'bidCount', key: 'bidCount' },
            { title: 'ARTIST', dataIndex: 'artistName', key: 'artistName' },
            { title: 'TITLE', dataIndex: 'name', key: 'name' },
            { title: 'RANK', dataIndex: 'rank', key: 'rank' },
            { title: 'RESERVE PRICE', dataIndex: 'reservePrice', key: 'reservePrice' },
            { title: 'STARTING BID', dataIndex: 'startBidPrice', key: 'startBidPrice' },
            {
                title: 'STATUS',
                dataIndex: 'lotResult',
                key: 'lotResult',
                render: (text) => {
                    return (
                        <div>
                            {text === 0 && <p className="textBlue">No Bids</p>}
                            {text === 1 && <p className="textBlue">Reserve Price Met</p>}
                            {text === 2 && <p className="textGreen">Reserve Price Not Met</p>}
                            {text === 3 && <p className="textDarkBlue">Below Reserve Price Seller Rejected</p>}
                            {text === 4 && <p className="textDarkBlue">Below Reserve Price Seller Accepted </p>}
                            {text === 5 && <p className="textDarkBlue">Escrow Signature Pending </p>}
                        </div>
                    );
                },
            },
            {
                title: 'Action',
                dataIndex: '',
                key: 'x',
                render: (record) => (
                    (record.status < 1) ? <EditButton onClick={() => onClickEditAuctionLot(record)}>Edit</EditButton> :
                        <EditButton onClick={() => onClickViewBiddingHistory(record)}>View Bid History</EditButton>
                ),
            },
        ];

        const content = (record) => (
            <div>
                {(record.status < 1) && (
                    <span
                        className="action-span"
                        onClick={() => onClickEditAuctionLot(record)}
                    >
                        <Icon type="check" />
                        <p className="pop-item">Edit</p>
                    </span>
                )}
                {(record.status > 1) && (
                    <span
                        className="action-span"
                        onClick={() => onClickViewBiddingHistory(record)}
                    >
                        <Icon type="close" />
                        <p className="pop-item">View Bid History</p>
                    </span>
                )}

            </div>
        );

        return (
            <>
                <AuctionLotTable
                    rowKey={(record) => record.rfid}
                    columns={columns}
                    dataSource={auctionLotObjects}
                    bordered
                    pagination={false}
                    loading={getAuctionLotLoader}
                />
                {!isEmpty(auctionLotObjects) && <Pagination
                    current={parseInt(auctionLotFilter.pageNum)}
                    total={auctionListCount}
                    pageSize={10}
                    onChange={onSelectPageNumber}

                />}
            </>
        );
    };

    const SummaryDetails = () => {
        return (
            <div>
                {!isEmpty(auctionLotObjects) && auctionLotObjects.map((lot, index, arr) => {
                    return (

                        <article className="art-items">
                            <Row className="flx-grw-row" gutter={32} align="middle">
                                <Col span={2} className="gutter-box art-select-order" >{lot.rank}</Col>
                                <Col span={7} className="row-img" >
                                    <img src={lot.image} className="img-thumb" />
                                </Col>
                                <Col span={13} style={{ position: "relative" }} className="flx-grw-colum">
                                    <span>
                                        <h2>{lot.name}</h2>
                                        {lot.dimensions && <h3>{`${lot.dimensions.height}H X ${lot.dimensions.width}W`}  {lot.productType === 1 ? "Inches" : "Pixels"}</h3>}
                                        {/* <span>USD { lot.}</span> */}
                                    </span>
                                    <span className="lot-date-details">
                                        <div className="price-item">
                                            <label >START DATE ({Util.getCurrentTimeZoneName()})</label>
                                            <span style={{ fontWeight: "bold" }}>{moment(lot.startDate).format("DD MMM YYYY h:mm:ss a")}</span>
                                        </div>
                                        <div className="price-item">
                                            <label >END DATE ({Util.getCurrentTimeZoneName()})</label>
                                            <span style={{ fontWeight: "bold" }}>{moment(lot.endDate).format("DD MMM YYYY h:mm:ss a")}</span>
                                        </div>

                                        {/* {showSaveButton[index] === true && <Button className="summary-ant-btn" title="save" icon="save"></Button>} */}

                                    </span>
                                    <span className="reserved-price">
                                        <div className="price-item">
                                            <label>START BID PRICE(USD)</label>
                                            <Input readOnly suffix="USD" name="startBidPrice" value={lot.startBidPrice} />
                                        </div>
                                        <div className="price-item">
                                            <label>BID LIMIT(STEP UP PRICE)(USD)</label>
                                            <Input readOnly suffix="USD" name="bidLimit" value={lot.bidLimit} />
                                        </div>

                                        {/* {showSaveButton[index] === true && <Button className="summary-ant-btn" title="save" icon="save"></Button>} */}

                                    </span>
                                    <Row align="bottom" className="toggle_rank_btn-grp">
                                        <span>
                                            <Button loading={swapLoader} disabled={index === 0} onClick={() => swapAuctionLot(index, true)} style={{ borderRadius: "6px 0 0 6px", border: "2px solid rgba(0, 0, 0, 0.34)", background: "#F3F3F3" }} icon="arrow-up" placeholder="arrow-up"></Button>
                                            <Button loading={swapLoader} disabled={index === arr.length - 1} onClick={() => swapAuctionLot(index, false)} style={{ borderRadius: "0 6px 6px 0", border: "2px solid rgba(0, 0, 0, 0.34)", background: "#F3F3F3" }} icon="arrow-down"></Button>
                                        </span>
                                    </Row>

                                </Col>
                            </Row>

                            <Row className="gutter-box btn-wrap">
                                {/* Edit button is available only auction with draft status(0) */}
                                <Col span={8} style={{ textAlign: "center" }}>
                                    {(auctionObject && (auctionObject.status === 0)) ?
                                        <>
                                            <Button disabled={auctionObject.status === 3} className="summary-ant-btn" onClick={() => onClickEditAuctionLot(lot)}>EDIT</Button>
                                            <Button className="summary-ant-btn" loading={deleteLotLoader} style={{ marginLeft: ".5rem" }} onClick={() => onClickDeleteAuctionLot(lot)}>DELETE</Button>
                                        </> :
                                        <Button loading={getAuctionBiddingHistoryLoader} className="summary-ant-btn" onClick={() => onClickViewBiddingHistory(lot)}>VIEW BID HISTORY</Button>
                                    }
                                </Col>
                                <Col span={15} style={{ textAlign: "right" }}>
                                    <span>MARK AS FEATURED <Switch disabled={auctionObject.status === 3} checked={lot.isFeatured} onClick={(checked, event) => updateAuctionLot(checked, event, lot)} loading={updateLotLoader} /></span>
                                </Col>
                            </Row>


                        </article>
                    )
                })}
                {!isEmpty(auctionLotObjects) && <Pagination
                    current={parseInt(auctionLotFilter.pageNum)}
                    total={auctionListCount}
                    pageSize={10}
                    onChange={onSelectPageNumber}

                />}
                {isEmpty(auctionLotObjects) &&
                    <Empty className="empty-artwork" description="No artworks Added"                    >
                        <Button className="summary-ant-btn" onClick={onClickAddAuctionSlot} type="link" icon="plus" >Add Artworks</Button>
                    </Empty>
                }

                < div >
                    <Divider />
                </div>
            </div >
        )
    };

    return (
        <>
            <SummaryContentWrapper>
                <SummaryDetailsHeading>
                    <label className="summary-label">Selected Artwork List for Auction</label>
                    {!isEmpty(auctionLotObjects) && (auctionObject && (auctionObject.status === 0)) && <Button className="summary-ant-btn" onClick={onClickAddAuctionSlot} >EDIT</Button>}
                    {/* <div className="lot-search">
                    <input
                        type="text"
                        className="search-box-field"
                        placeholder="Search Lot"
                        onChange={searchAuctionLots}
                        value={auctionLotFilter.searchText}
                        ref={searchLotFilterRef}
                    />
                </div> */}
                    <ContentTabButtonWrapper>
                        <TabButtons isActive={activeTab === "artwork"} onClick={() => setCurrentTab("artwork")}>
                            <span>ARTWORK DETAILS </span>
                        </TabButtons>
                        <TabButtons isActive={activeTab === "lot"} onClick={() => setCurrentTab("lot")}>
                            <span>LOT DETAILS</span>
                        </TabButtons>

                    </ContentTabButtonWrapper>
                </SummaryDetailsHeading>

                {activeTab === "lot" && (
                    <>
                        <SortByDropdownWrapper>
                            <div>
                                <label>SORT BY</label>

                                <SortSelectDropDown value={auctionLotFilter.sortOrder} onChange={onSelectSortOption}>
                                    <Option key={"index1"} value={0}>
                                        {"LOT NO."}
                                    </Option>
                                    <Option key={"index1"} value={1}>
                                        {"LOWEST BID"}
                                    </Option>
                                    <Option key={"index2"} value={2}>
                                        {"HIGHEST BID"}
                                    </Option>
                                    <Option key={"index2"} value={3}>
                                        {"BID COUNT"}
                                    </Option>
                                    <Option key={"index5"} value={4}>
                                        {"BID AMOUNT"}
                                    </Option>
                                </SortSelectDropDown>
                            </div>
                            <div>
                                <label>FILTER BY</label>

                                <FilterSelectDropDown
                                    mode="multiple"
                                    placeholder="Filter By Bid Results"
                                    value={auctionLotFilter.LotResult}
                                    onChange={onSelectBidResult}>

                                    <Option key={"index1"} value={0}>
                                        {"NO BIDS"}
                                    </Option>
                                    <Option key={"index1"} value={1}>
                                        {"RESERVE PRICE MET"}
                                    </Option>
                                    <Option key={"index2"} value={2}>
                                        {"RESERVE PRICE NOT MET"}
                                    </Option>
                                    <Option key={"index2"} value={3}>
                                        {"BELOW RESERVE PRICE SELLER REJECTED"}
                                    </Option>
                                    <Option key={"index5"} value={4}>
                                        {"BELOW RESERVE PRICE SELLER ACCEPTED"}
                                    </Option>
                                </FilterSelectDropDown>
                            </div>
                        </SortByDropdownWrapper>
                        <TotalBidCount>
                            <label>Total Bid Count:</label> {totalBidCount}
                        </TotalBidCount>
                    </>)
                }



                {(() => {
                    switch (activeTab) {
                        case "artwork":
                            return <SummaryDetails />;
                        case "lot":
                            return < SummaryTableFormat />;
                        case "bid":

                            break;

                        default:
                            break;
                    }
                })()}

            </SummaryContentWrapper>
            <BiddingHistory biddingHistoryDataSource={auctionBiddingHistory} openBiddingHistoryDrawer={openBiddingHistoryDrawer} onCloseBiddingHistoryDrawer={setOpenBiddingHistoryDrawer} />
        </>
    )
}

export default SummaryContent;