import React from "react";
import { StyledRow, SaveDiv, SaveButton, Hr } from "./DisplayHeader.styled";
import { Dialog, DialogContent, DialogTitle, Slide } from "@material-ui/core";
import CropImage from "../../../../components/CropImage/CropImage";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const AddBackgroundDropDialog = ({ open, mediaThumb, onImageSelect, updateCoverPhoto, loading, handleBgEditorClose, imgType }) => {


	return (
		<Dialog
			open={open}
			onClose={handleBgEditorClose}
			BackdropProps={{ classes: { root: "dialog-backdrop" } }}
			className="add-collection-dialog"
			TransitionComponent={Transition}

		>
			<img className="close-btn" onClick={handleBgEditorClose} src={require("../../../../assets/images/common/Close_h.svg")} alt="" />
			<DialogTitle>
				Update your Background Drop
			</DialogTitle>
			<DialogContent>
				<StyledRow>
					{/* CROP_IMAGE */}
					<CropImage onSelectCroppedImage={onImageSelect} imgType={imgType} />
				</StyledRow>

				<Hr width="2px" />
				<SaveDiv>
					<SaveButton
						variant="invert"
						htmlType="submit"
						onClick={() => {
							updateCoverPhoto();
						}}
						loading={loading}
						disabled={loading}
					>
						Save
					</SaveButton>
				</SaveDiv>
			</DialogContent>
		</Dialog>
	);
};

export default AddBackgroundDropDialog;
