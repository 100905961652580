import { APIUrlConstants } from "../../util/apiUrl.constant";
import { APIService } from "../../util/api.service";

export class EditProductService {
	// List all category
	static getCategory(parentId = "") {
		let url = APIUrlConstants.getApiUrl("getCategory") + "?parentId=" + parentId;
		return APIService.doGet(url).then(resp => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	// Get original artworks
	static getOriginalArtworks() {
		let url = APIUrlConstants.getApiUrl("getOriginalArtworks");
		return APIService.doGet(url).then(resp => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	// Get product
	static getProductBySlug(SlugId = "", StockId = "") {
		let url = APIUrlConstants.getApiUrl("getProductBySlugId") + `?SlugId=${SlugId}`;
		return APIService.doGet(url).then(resp => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	// Get Categories
	static getCategoryProperty(CategoryId) {
		let url = APIUrlConstants.getApiUrl("getCategoryProperty") + `?CategoryId=${CategoryId}`;
		return APIService.doGet(url).then(resp => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	// Get Category parents list
	static getParentsByCategoryId(categoryId) {
		let url = APIUrlConstants.getApiUrl("getParentsByCategoryId") + categoryId;
		return APIService.doGet(url).then(resp => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	static downloadDisplayPicture = id => {
		let url = APIUrlConstants.getApiUrl("downloadDisplayPicture") + id;
		return APIService.doDownload(url).then(resp => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	};

	// Update Product
	static updateProduct(id, body) {
		let url = APIUrlConstants.getApiUrl("updateProduct") + `/${id}`;
		return APIService.doPut(url, body).then(resp => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	// Update Images/Videos
	static updateImagesAndVideos(body) {
		let url = APIUrlConstants.getApiUrl("uploadProductImages");
		return APIService.doUploadMultipart(url, body).then(resp => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	static metaDataArtworkStatusEdit(status, id) {
		let url = APIUrlConstants.getApiUrl("updateMetaDataStatus");
		url = url + "?status=" + status + "&artworkId=" + id
		return APIService.doPut(url).then(resp => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}
}
