import React, { useState } from "react";
import Root from "../Root/Root";
import Topbar from "../../components/Topbar/Topbar";
import { isEmpty, isNil } from "lodash";
import { Button, Input, Radio, DatePicker, Typography, Icon, Tooltip, Checkbox } from "antd";
import useSurveyForm from "./useSurveyForm";
import Select from "react-select";
import Dialog from "@material-ui/core/Dialog";
import { DialogTitle, DialogContent, Slide, CircularProgress } from "@material-ui/core";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import "./SurveyForms.style.scss";
const { TextArea } = Input;
const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});
function CreateNewSurvey({ history }) {
	const {
		//Functions
		onHandleInputChange,
		questionModalClose,
		showModal,
		onChangeControlType,
		onChangeYesOrNoDropdown,
		insertQuestion,
		addOptions,
		setErrorMsg,
		setSelectedInputType,
		saveSurvey,
		deleteQuestion,
		deleteOptions,
		//Variables
		surveyFormObj,
		selectedQuestion,
		questionModal,
		isItsChoice,
		selectedControl,
		controlType,
		yesOrNoOption,
		isRequired,
		allowOther,
		singleInputTypes,
		options,
		errorMsg,
		selectedInputType,
		questionIds,
	} = useSurveyForm();

	return (
		<>
			<Root>
				<Topbar title={"Add New Survey"} />
				<div className="new-survey-main-wrapper common-question-wrapper">
					<div className="form-control">
						<label className="form-label">Survey Main Title *</label>
						<Input required type="text" autoFocus name="title" value={surveyFormObj.title} onChange={onHandleInputChange} />
						<span className="survey-error-msg">{errorMsg.mainTitleError}</span>
					</div>
					<div className="form-control">
						<label className="form-label">Sub Title</label>
						<Input required type="text" autoFocus name="description" value={surveyFormObj.description} onChange={onHandleInputChange} />
					</div>
					<div className="form-control">
						<label className="form-label">Survey Slug</label>
						<Input required type="text" autoFocus name="surveySlug" value={surveyFormObj.surveySlug} onChange={onHandleInputChange} />
						<span className="survey-error-msg">{errorMsg.surveySlugError}</span>
					</div>
					<div className="form-control">
						<label className="form-label">Survey Success Message</label>
						<Input
							required
							type="text"
							autoFocus
							name="surveySuccessMessage"
							value={surveyFormObj.surveySuccessMessage}
							onChange={onHandleInputChange}
						/>
					</div>
					<div className="hr-line" />
					<div className="add-question-btn-wrapper">
						<div className="add-question-btn" onClick={showModal}>
							Click here to add questions
						</div>
					</div>

					<div className="form-row btn-container">
						<div className="add-btn" onClick={saveSurvey}>
							Save
						</div>
						<div className="cancel-btn" onClick={() => history.push("/survey-forms")}>
							Go Back
						</div>
					</div>
				</div>
			</Root>
			<Dialog
				fullScreen
				open={questionModal}
				TransitionComponent={Transition}
				onClose={questionModalClose}
				BackdropProps={{ classes: { root: "dialog-backdrop" } }}
				className="editor-modal-dialog"
			>
				<img className="close-btn" onClick={questionModalClose} src={require("../../assets/images/common/Close_h.svg")} alt="" />

				<DialogTitle className="dialog-title">Add Questions </DialogTitle>

				<DialogContent className="survey-modal-content ">
					{/* <div className="preview-link-wrapper">
						<span>Survey Preview</span>
					</div> */}
					<div className="question-main-wrapper-container">
						<div className="new-survey-main-wrapper questions-wrapper">
							<div className="form-control">
								<label className="form-label">Question</label>
								<TextArea
									rows={2}
									type="text"
									value={surveyFormObj.question}
									name="question"
									onChange={onHandleInputChange}
									maxLength={550}
								/>
								{/* <Input required type="text" autoFocus name="question" value={surveyFormObj.question} onChange={onHandleInputChange} /> */}
								<span className="survey-error-msg">{errorMsg.questionError}</span>
							</div>
							<div className="form-row">
								<div className="form-control">
									<label className="form-label">Control Type</label>
									<Select value={selectedControl} options={controlType} onChange={(values) => onChangeControlType(values)} />
									<span className="survey-error-msg">{errorMsg.inputTypeError}</span>
								</div>
								<div className="form-control">
									<label className="form-label"> Is Required</label>
									<Select
										value={isRequired}
										options={yesOrNoOption}
										onChange={(item) => onChangeYesOrNoDropdown(item, "isRequired")}
									/>
								</div>
								<div className="form-control">
									<label className="form-label">Allow Other</label>
									<Select
										value={allowOther}
										options={yesOrNoOption}
										onChange={(item) => onChangeYesOrNoDropdown(item, "allowOther")}
									/>
								</div>
							</div>
							{selectedControl.value === "text" && (
								<div className="form-control">
									<label className="form-label">Input Type</label>
									<Select value={selectedInputType} options={singleInputTypes} onChange={(item) => setSelectedInputType(item)} />
								</div>
							)}

							{(selectedControl.value === "radiogroup" ||
								selectedControl.value === "dropdown" ||
								selectedControl.value === "checkbox") && (
								<>
									<div className="form-row">
										<div className="form-control">
											<label className="form-label">Enter your options</label>
											<div className="form-row">
												<Input
													required
													type="text"
													value={surveyFormObj.questionChoice}
													autoFocus
													name="questionChoice"
													onChange={onHandleInputChange}
												/>
												<button className="choice-add-btn" onClick={addOptions}>
													+
												</button>
											</div>
											<span className="survey-error-msg">{errorMsg.optionsError}</span>
										</div>
									</div>
									<div className="form-row">
										<ul className="choice-list-ul">
											{options.map((opt, index) => (
												<li key={index}>
													<p>{`${parseInt(index + 1)}: ${opt}`} </p>
													<span onClick={()=>deleteOptions(opt)}>delete</span>
												</li>
											))}
										</ul>
									</div>
								</>
							)}
							<div className="form-row btn-container">
								<div className="add-btn" onClick={insertQuestion}>
									Save
								</div>
								<div className="cancel-btn" onClick={questionModalClose}>
									Cancel
								</div>
								<div className="preview-btn" onClick={questionModalClose}>
									Done
								</div>
							</div>
						</div>
						{/* Addedd Question list  */}

						<div className="survey-table-container">
							<label
								className="form-label"
								style={{
									paddingBottom: 10,
								}}
							>
								Added Questions List
							</label>
							<Paper>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell>Question Title</TableCell>
											<TableCell align="right">Answer Type</TableCell>
											<TableCell align="right">Is Required</TableCell>
											<TableCell align="right">Action</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{questionIds &&
											questionIds.length > 0 &&
											questionIds.map((row) => (
												<TableRow key={row._id}>
													<TableCell component="th" scope="row">
														{row.title}
													</TableCell>
													<TableCell align="right">{row.type}</TableCell>
													<TableCell align="right">{row.isRequired ? "Yes" : "No"}</TableCell>
													<TableCell align="right">
														<span className="action-span" onClick={()=>deleteQuestion(row._id)}>
															<img src={require("../../assets/images/common/delete.svg")} alt="delete" />
														</span>
													</TableCell>
												</TableRow>
											))}
									</TableBody>
								</Table>
							</Paper>
						</div>
					</div>
				</DialogContent>
			</Dialog>
		</>
	);
}

export default CreateNewSurvey;
