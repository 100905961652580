/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import ProfileSectionTab from "../ProfileSectionTab/ProfileSectionTab";
import { isEmpty } from "lodash";
import { Modal, Button } from "antd";
import AddNewAwards from "./AddYearEventModal/AddNewAwards";
import { MainSubmitButton } from "../../ArtistProfile.styled";
import AddYearEventModal from "./AddYearEventModal/AddYearEventModal";
import { popToast } from "../../../../../components/Toast/Toast";
import YearEventTable from "./YearEventTable/YearEventTable";
import { authorizedGet } from "../../../../../util/apiConfig";

const YearEvent = ({ isMyProfile, userId, isGallery = false, isHistoric = false, onTabChange, fromSource, token, onLoad }) => {

	const [isUpdate, setUpdate] = useState(false);
	const [editItemID, setEditItemID] = useState(null);

	// const [getHistoricalDataList, historicalDataListResult, , historicalLoader] = useGetHistoricalDataList();
	// const [addNewHistoricalData, addHistoricalDataResult, addHistoricalDataError, addHistoricalDataLoading] = useAddHistoricalData();
	// const [updateHistoricalData, updateHistoricalDataResult, updateHistoricalDataError, updateHistoricalDataLoading] = useUpdateHistoricalData();
	// const [deleteHistoricalData, deleteHistoricalDataResult, deleteHistoricalDataError] = useDeleteHistoricalData();

	// const [getAwardList, awardListResult, , awardLoader] = useGetAwardList();
	// const [addNewAward, addAwardResult, addAwardError, addAwardLoading] = useAddAward();
	// const [updateAward, updateAwardResult, updateAwardError, updateAwardLoading] = useUpdateAward();
	// const [deleteAward, deleteAwardResult, deleteAwardError] = useDeleteAward();

	const [editData, setEditData] = useState();
	const [historicalDataListResult, sethistoricalDataListResult] = useState();
	const [awardListResult, setawardListResult] = useState();
	const [historicalLoader, sethistoricalLoader] = useState(false);
	const [addHistoricalDataLoading, setaddHistoricalDataLoading] = useState(false);
	const [updateHistoricalDataLoading, setupdateHistoricalDataLoading] = useState(false);
	const [awardLoader, setawardLoader] = useState(false);
	const [addAwardLoading, setaddAwardLoading] = useState(false);
	const [updateAwardLoading, setupdateAwardLoading] = useState(false);

	const getHistoricalDataList = () => { }
	const addNewHistoricalData = () => { }
	const updateHistoricalData = () => { }
	const deleteHistoricalData = () => { }

	const getAwardList = () => {
		authorizedGet(`/UserManagment/api/Artists/GetAwardListByUserId?pageNum=1&pageSize=10&userId=${userId}`).then((responseJson) => {
			if (responseJson.resultCode === 1) {
				setawardListResult(responseJson.response.data);
				setaddAwardLoading(false);
			}
		});
	}
	const addNewAward = () => { }
	const updateAward = () => { }
	const deleteAward = () => { }


	// ---------------- GET ---------------- //
	useEffect(() => {
		getAwardList(userId);
	}, [isHistoric, userId]);

	// ---------------- ADD ---------------- //
	const onSaveInfo = (showLabel, data) => {
		if (isHistoric) addNewHistoricalData(data.year, data.textValue);
		else addNewAward(data.year, data.textValue);
	};

	// ---------------- UPDATE ---------------- //
	const onEditItem = (data) => {
		setActionType("edit");
		setEditData(data);
		onToggleModel();
	};
	const onUpdateInfo = (showLabel, data) => {
		if (isHistoric) updateHistoricalData(data.year, data.textValue, editItemID);
		else updateAward(data.year, data.textValue, editItemID);
	};

	// Common response actions
	const doAfterAPIResponse = (msg, type = "success") => {
		if (type === "error") popToast(msg);
		else popToast(msg, type);
		isHistoric ? getHistoricalDataList() : getAwardList(userId);
		setUpdate(null);
		setEditItemID(null);
	};

	// Handling api responses
	// useEffect(() => {
	// 	if (addHistoricalDataResult || addAwardResult) doAfterAPIResponse("Successfully added");
	// 	else if (updateHistoricalDataResult || updateAwardResult) doAfterAPIResponse("Successfully uploaded");
	// 	else if (deleteHistoricalDataResult || deleteAwardResult) doAfterAPIResponse("Successfully deleted");
	// 	else if (
	// 		addHistoricalDataError ||
	// 		updateHistoricalDataError ||
	// 		deleteHistoricalDataError ||
	// 		addAwardError ||
	// 		updateAwardError ||
	// 		deleteAwardError
	// 	)
	// 		doAfterAPIResponse(
	// 			addHistoricalDataError ||
	// 			updateHistoricalDataError ||
	// 			deleteHistoricalDataError ||
	// 			addAwardError ||
	// 			updateAwardError ||
	// 			deleteAwardError,
	// 			"error"
	// 		);
	// }, [
	// 	addHistoricalDataResult,
	// 	addHistoricalDataError,
	// 	updateHistoricalDataResult,
	// 	updateHistoricalDataError,
	// 	deleteHistoricalDataResult,
	// 	deleteHistoricalDataError,
	// 	addAwardResult,
	// 	addAwardError,
	// 	updateAwardResult,
	// 	updateAwardError,
	// 	deleteAwardResult,
	// 	deleteAwardError,
	// ]);

	// Different constants for awards/historical pages
	const getValue = (val) => {
		const historicalObj = {
			title: "Historical",
			fetchLoader: historicalLoader,
			list: historicalDataListResult,
			tableHead: "Events",
			tableValue: "events",
			addModalTitle: "Historical Events",
		};
		const awardsObj = {
			title: "Awards & Recognitions",
			fetchLoader: awardLoader,
			list: awardListResult,
			tableHead: "Awards",
			tableValue: "award",
			addModalTitle: "Awards & Recognitions",
		};
		return isHistoric ? historicalObj[val] : awardsObj[val];
	};

	const [actionType, setActionType] = useState("add");

	const [isModalVisible, setIsModalVisible] = useState(false);
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	const onToggleModel = () => {
		setIsModalVisible(!isModalVisible);
		getAwardList(userId);
	};
	const handleOk = () => {
		setIsModalVisible(false);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	return (
		<>
			{fromSource === "profile-edit" ? (
				<>
					<div className="table-top-wrapper">
						<MainSubmitButton
							onClick={() => {
								setActionType("add");
								onToggleModel();
							}}
						>
							Add Awards & Recognitions
						</MainSubmitButton>
					</div>
					<YearEventTable
						title={getValue("tableHead")}
						isMyProfile={isMyProfile}
						listValue={getValue("tableValue")}
						list={getValue("list")}
						onEditItem={onEditItem}
						onTabChange={onTabChange}
						fromSource={fromSource}
						onDeleteItem={(data) => (isHistoric ? deleteHistoricalData(data._id) : deleteAward(data._id))}
					/>
				</>
			) : (
				<ProfileSectionTab
					title={getValue("title")}
					loader={getValue("fetchLoader")}
					notEmpty={!isEmpty(getValue("list"))}
					showAddButton={false}
					onClickAddNew={() => setIsModalVisible(true)}
				>
					<YearEventTable
						title={getValue("tableHead")}
						isMyProfile={isMyProfile}
						listValue={getValue("tableValue")}
						list={getValue("list")}
						onEditItem={onEditItem}
						onTabChange={onTabChange}
						onDeleteItem={(data) => (isHistoric ? deleteHistoricalData(data._id) : deleteAward(data._id))}
					/>
				</ProfileSectionTab>
			)}

			<Modal title="Awards & Recognitions" footer={null} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} destroyOnClose={true}>
				<AddNewAwards userId={userId} token={token} onLoad={onLoad} onToggleModel={onToggleModel} type={actionType} data={editData} />
			</Modal>

			<AddYearEventModal
				headTitle={getValue("addModalTitle")}
				onSaveInfo={onSaveInfo}
				onUpdateInfo={onUpdateInfo}
				isUpdate={isUpdate}
				showLabel={isHistoric}
				userId={userId}
				loading={addHistoricalDataLoading || updateHistoricalDataLoading || addAwardLoading || updateAwardLoading}
			/>
		</>
	);
};

export default YearEvent;
