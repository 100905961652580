import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
    getAllArtists
} from "../../../redux/actions/CollectionAction";
import { AuctionService } from "../AuctionService";
import * as types from "../../../redux/actions/ActionsTypes";
import history from "../../../util/history";
import { isArray, isEmpty, isNaN, isNil, isNumber } from "lodash";
import { popToast } from "../../../components/Toast/Toast";
import { getArtworksListAction } from "../../../redux/actions/ManageArtworksAction";

const useAuctionLot = (match) => {
    const dispatch = useDispatch();
    const priceMin = 0;
    const priceMax = 10000000;

    const [auctionLotObj, setAuctionLotObj] = useState({
        name: "",
        auctionId: "",
        reservePrice: "",
        bidLimit: "",
        rank: 0,
        stockId: "",
        slugId: "test3",
        _id: "",
        startDate: "",
        endDate: "",
        description: "",
        startBidPrice: "",
    });
    const [filterObject, setFilterObject] = useState({
        CategoryId: "",
        ProductName: "",
        Sort: + 0,
        Satus: 0,
        PageNum: 1,
        PageSize: 9,
        ArtistId: [],
        PriceMin: 0,
        PriceMax: 9999999,
        Filter: [],
        status: [],
    });
    const [newLotObj, setNewLotObj] = useState({
        auctionId: "",
        bidLimit: 50,
        stockIds: [],
    })
    const { artistsList, categoryList, collectionsList,
        categoryLoader, collectionListLoader,
    } = useSelector(s => s.collectionReducer);

    const { artworksListLoader, savedArtworksList, savedArtworkListCount, } = useSelector(s => s.manageArtworksReducer);

    const { auctionData } = useSelector(s => s.auctionReducer);

    const [selectedArtworkStockId, setSelectedArtworkStockId] = useState([]);
    const [isApiCalling, setIsApiCalling] = useState(false);
    const [auctionStartDate, setAuctionStartDate] = useState();
    const [auctionEndDate, setAuctionEndDate] = useState();

    useEffect(() => {
        dispatch(getAllArtists());
    }, [])

    useEffect(() => {
        if (!isNil(match)) {
            const params = match.params;

            if (params) {
                const auctionId = params.auctionId;

                if (!isEmpty(auctionId)) {
                    AuctionService.getAuctionLotIdsByAuctionId(auctionId).then(resp => {
                        if (resp && resp.result === 200) {

                            setNewLotObj(s => ({ ...s, auctionId: match.params.auctionId, stockIds: resp.data }));
                            setSelectedArtworkStockId(resp.data);
                        } else if (resp && resp.result === 400) {
                            popToast.show(resp.message);
                        }
                    })
                    getSearchFilteredProducts();
                }
            }
        }

    }, [match]);



    useEffect(() => {
        getSearchFilteredProducts();
    }, [filterObject.PageNum])


    const onSelectArtwork = (item) => {
        if (!isNil(item)) {
            if (selectedArtworkStockId.includes(item.stockId)) {
                let newList = selectedArtworkStockId.filter(s => s !== item.stockId);
                setSelectedArtworkStockId(newList);
                setNewLotObj(s => ({ ...s, stockIds: newList }));
            }
            else {
                let newList = [...selectedArtworkStockId, item.stockId];
                setSelectedArtworkStockId(newList);
                setNewLotObj(s => ({ ...s, stockIds: newList }));
                // setAuctionLotObj(s => ({ ...s, stockId: item.stockId, reservePrice: item.sellingPrice, description: item.description }));
            }
        }
    }

    const onSelectPageNumber = (event) => {
        setFilterObject(s => ({ ...s, PageNum: event }))
    };

    const getSearchFilteredProducts = () => {
        // TODO : Pagination
        let request = {
            PageNum: filterObject.PageNum,
            PageSize: filterObject.PageSize,
            SortOrder: filterObject.Sort || + 0,
            ArtistIds: filterObject.ArtistId || [],
            MinPrice: filterObject.PriceMin || 0,
            MaxPrice: filterObject.PriceMax || 9999999,
            OfferMinPercentage: 0,
            OfferMaxPercentage: 100,
            OfferMinAmount: 0,
            OfferMaxAmount: 9999999,
            ArtistName: "",
            IsFeatured: null,
            Status: filterObject.status.length === 0 ? ["1", "2", "16"] : filterObject.status,
            ProductName: filterObject.ProductName,
            ProductType: match.params.artworkType === "null" ? null : match.params.artworkType,
        };
        dispatch(getArtworksListAction(request));
    }


    const onHandleInputChange = (event) => {
        const { name, value } = event.target;
        setAuctionLotObj(s => ({ ...s, [name]: value }));
    }

    const onChangeArtistSelect = (values, name) => {
        if (name === "PriceRange") {
            setFilterObject(s => ({
                ...s,
                PriceMin: values[0],
                PriceMax: values[1]
            }));
        }
        else {
            setFilterObject(s => ({ ...s, [name]: values }));
        }
    }


    const onChangeFilterInput = (event) => {
        const { name, value } = event.target;
        setFilterObject(s => ({ ...s, [name]: value }));
    }

    const onClickSaveAuctionLot = () => {
        try {

            if (IsValidLot(auctionLotObj)) {
                if (!isNil(auctionLotObj) && isEmpty(auctionLotObj._id)) {
                    setIsApiCalling(true);
                    AuctionService.addAuctionLot(auctionLotObj).then(resp => {
                        saveLotSuccess(resp);
                    })
                } else {
                    AuctionService.updateAuctionLot(auctionLotObj._id, auctionLotObj).then(resp => {
                        saveLotSuccess(resp);
                    })
                }
            }
        } catch (error) {
            setIsApiCalling(false);
        }
    }

    const onClickNewSaveAuctionLot = () => {
        try {
            if (!isNil(newLotObj) && isEmpty(newLotObj._id)) {
                setIsApiCalling(true);
                AuctionService.addAuctionLot(newLotObj).then(resp => {
                    saveLotSuccess(resp);
                })
            } else {
                AuctionService.updateAuctionLot(newLotObj._id, newLotObj).then(resp => {
                    saveLotSuccess(resp);
                })
            }

        } catch (error) {
            setIsApiCalling(false);
        }
    }

    const IsValidLot = (lot) => {
        if (isNullOrEmpty(lot.stockId)) {
            popToast.show("Please select an artwork")
            return false;
        }
        if (isNullOrEmpty(lot.reservePrice)) {
            popToast.show("Please enter Reserve Price");
            return false;
        } else if (isNaN(parseFloat(lot.reservePrice))) {
            popToast.show("Please enter valid Reserve Price");
            return false;
        }
        if (isNullOrEmpty(lot.startBidPrice)) {
            popToast.show("Please enter Start Bid");
            return false;
        } else if (lot.startBidPrice > lot.reservePrice) {
            popToast.show("Please enter Start Bid Price less than Reserve Price");
            return false;
        }
        if (isNullOrEmpty(lot.bidLimit)) {
            popToast.show("Please enter Bid Limit")
            return false;
        } else if (isNaN(parseFloat(lot.bidLimit))) {
            popToast.show("Please enter a valid Bid Limit")
            return false;
        }

        if (!moment(lot.startDate).isValid()) {
            popToast.show("Please enter a valid Start Date");
            return false;
        }
        if (!moment(lot.endDate).isValid()) {
            popToast.show("Please enter a valid End Date");
            return false;
        } else {
            if (moment(lot.endDate).isBefore(moment(lot.startDate))) {
                popToast.show("Please enter a valid future End Date");
                return false;
            }
        }

        return true;
    }

    const isNullOrEmpty = (obj) => {
        if (obj === "" || obj === undefined || obj === null) {
            return true;
        }
        return false
    }

    const saveLotSuccess = (response) => {
        if (response && response.result === 200) {
            popToast.show("Atwork added successfully.");
            dispatch(success(types.ADD_AUCTIONLOT_SUCCESS, response.data));
            history.push(`/auctions/summary/${newLotObj.auctionId}`);
        } else if (response && response.result === 400) {
            popToast.show(response.message);
        }
        setIsApiCalling(false);
    }

    const onClickCancelAuctionLot = () => {
        history.push(`/auctions/summary/${newLotObj.auctionId}`);
    }

    const onDateChange = (date, dateString) => {
        console.log(date, dateString);
        if (isEmpty(date)) {
            setAuctionLotObj(s => ({ ...s, startDate: moment(), endDate: moment() }));
        } else {
            setAuctionLotObj(s => ({ ...s, startDate: !isNil(date[0]) ? date[0].toDate() : null, endDate: !isNil(date[1]) ? date[1].toDate() : null }));
        }
    }

    return {
        auctionLotObj, auctionData, filterObject, priceMin, priceMax, selectedArtworkStockId, isApiCalling,
        auctionStartDate, auctionEndDate,

        artistsList, categoryList, collectionsList,
        categoryLoader, collectionListLoader, artworksListLoader, savedArtworksList, savedArtworkListCount,

        onSelectArtwork, onHandleInputChange, onChangeArtistSelect, onChangeFilterInput, getSearchFilteredProducts, onClickSaveAuctionLot,
        onClickCancelAuctionLot, onSelectPageNumber, onDateChange, onClickNewSaveAuctionLot
    }

    function request(type) {
        return { type };
    }
    function success(type, data) {
        return { type, data };
    }
    function failure(type, error) {
        return { type, error };
    }
}

export default useAuctionLot;