import { APIService } from "../../util/api.service";
import { APIUrlConstants } from "../../util/apiUrl.constant";
import { Util } from "../../util/util";

export class ReelsVideoService {
    static async getReelsVideos(sortOption, search) {
        let url = APIUrlConstants.getApiUrl("getReelsVideos") + `?pageNum=1&pageSize=20&SortBy=${sortOption}&Search=${search}`;
        return APIService.doGet(url).then((resp) => {
            if (resp) {
                return resp;
            }
            throw resp;
        });
    }

    static async SearchProducts(request) {
        let url = APIUrlConstants.getApiUrl("searchProductV2");
        return APIService.doPost(url, request).then((resp) => {
            if (resp) {
                return resp;
            }
            throw resp;
        });
    }

    static async AddReelsVideo(request) {
        let url = APIUrlConstants.getApiUrl("reelsVideos");

        let formBodyRequest = Util.objectToFormData(request);
        return APIService.doUploadMultipart(url, formBodyRequest).then((resp) => {
            if (resp) {
                console.log("resp", resp)
                return resp;
            }
            throw resp;
        });
    }

    static async UpdateReelsVideo(id, request) {
        let url = APIUrlConstants.getApiUrl("reelsVideos") + "?reelId=" + id;;
        let formBodyRequest = Util.objectToFormData(request);
        return APIService.doPutMultipart(url, formBodyRequest).then((resp) => {
            if (resp) {
                return resp;
            }
            throw resp;
        });
    }

    static async deleteReelsVideo(id) {
        let url = APIUrlConstants.getApiUrl("reelsVideos") + "?reelId=" + id;
        return APIService.doDelete(url).then((resp) => {
            if (resp) {
                return resp;
            }
            throw resp;
        });
    }
}