import * as types from "../actions/ActionsTypes";
const initialState = {
    auctionList: [],
    auctionData: null,
}

const auctionReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case types.GET_ALL_AUCTION_SUCCESS:
            return {
                auctionList: action.data,
                auctionData: null
            }
        case types.ADD_AUCTION_SUCCESS:
            return {
                auctionData: action.data
            }
        default:
            return state;
    }
};

export default auctionReducer;