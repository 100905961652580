import {
    LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT, TOGGLE_LOGOUT_DIALOG, TWO_FACTOR_REQUEST, TWO_FACTOR_SUCCESS, TWO_FACTOR_FAILURE
} from '../actions/ActionsTypes';
import { Util } from '../../util/util';

const initialState = {
    redirectToReferrer: false,
    userData: {},
    userToken: Util.getAuthToken(),
    loginRequest: false,
    // logout
    logoutDialog: false
}

const loginReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case LOGIN_REQUEST:
            state = {
                ...state,
                userData: {},
                userToken: '',
                loginRequest: true
            }
            return state;

        case LOGIN_SUCCESS:
            state = {
                ...state,
                redirectToReferrer: true,
                userData: action.data,
                userToken: action.data.Token,
                loginRequest: false
            }
            return state;

        case LOGIN_FAILURE:
            return {
                ...state,
                userToken: '',
                loginRequest: false
            };

        case TWO_FACTOR_REQUEST:
            state = {
                ...state,
                userData: {},
                userToken: '',
                twoFactorRequest: true
            }
            return state;

        case TWO_FACTOR_SUCCESS:
            state = {
                ...state,
                redirectToReferrer: true,
                userData: action.data,
                userToken: action.data.Token,
                twoFactorRequest: false
            }
            return state;

        case TWO_FACTOR_FAILURE:
            return {
                ...state,
                userToken: '',
                twoFactorRequest: false
            };

        case TOGGLE_LOGOUT_DIALOG:
            state = {
                ...state,
                logoutDialog: action.value
            }
            return state;

        case LOGOUT:
            return {};

        default:
            return state;
    }
}

export default loginReducer;
