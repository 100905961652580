import { Popover, Table, Tooltip } from "antd";
import React from "react";
import moment from "moment";
import Topbar from "../../components/Topbar/Topbar";
import Root from "../Root/Root";
import AuctionDetails from "./AuctionDetails/AuctionDetails";
import AuctionLot from "./AuctionLot/AuctionLot";
import AuctionSummary from "./AuctionSummary/AuctionSummary";
import useAuctionListAction from "./useAuctionListAction";
import "./auction.scss";
import { Util } from "../../util/util";
import { DataGrid } from "@mui/x-data-grid";
import { CircularProgress } from "@material-ui/core";

const AuctionListing = () => {
	const [pageSize, setPageSize] = React.useState(20);

	const {
		auctionObject,
		isPageLoading,
		auctionList,
		auctionState,
		openAuctionDrawer,
		openAuctionSummaryDrawer,
		onChangeSearch,
		addNewAucation,
		openAuctionDetailsModal,
		setOpenAuctionSummaryDrawer,
		closeAuctionDetailsModal,
		onClickEditAuction,
		onClickDeleteAuction,
		onClickViewAuction,
	} = useAuctionListAction();
	const content = (record) => (
		<div>
			<div className="action-span" onClick={() => onClickViewAuction(record)}>
				<img src={require("../../assets/images/common/Viewa&Edit.svg")} alt="edit" />
				<p className="pop-item">View</p>
			</div>
			{record.status !== 3 && (
				<div className="action-span" onClick={() => onClickEditAuction(record)}>
					<img src={require("../../assets/images/common/Edit_New.svg")} alt="edit" />
					<p className="pop-item">Edit</p>
				</div>
			)}
			<div className="action-span" onClick={() => onClickDeleteAuction(record)}>
				<img src={require("../../assets/images/common/delete_active.svg")} alt="edit" />
				<p className="pop-item">Delete</p>
			</div>
		</div>
	);
	const columns = [
		{
			headerName: "Name",
			field: "name",
			width: 350,
		},
		{
			headerName: `Start Date(${Util.getCurrentTimeZoneName()})`,
			// headerName: `Start Date`,
			key: "startDate",
			field: "startDate",
			width: 250,
			renderCell: (text) => <span>{moment(text.row.startDate).format("DD MMM YYYY h:mm:ss a")}</span>,
		},
		{
			headerName: `End Date(${Util.getCurrentTimeZoneName()})`,

			field: "endDate",
			width: 250,
			renderCell: (text) => <span>{moment(text.row.endDate).format("DD MMM YYYY h:mm:ss a")}</span>,
		},
		{
			headerName: "Status",

			field: "status",
			type: "singleSelect",
			valueOptions: [
				{ value: 0, label: "Draft" },
				{ value: 1, label: "Visible" },
				{ value: 2, label: "In Progress" },
				{ value: 3, label: "Completed" },
				{ value: 4, label: "Pause" },
			],
			width: 150,
			renderCell: (text) => {
				return (
					<div>
						{text.row.status === 0 && <p className="textBlue">Draft</p>}

						{text.row.status === 1 && <p className="textDarkBlue">Visible</p>}

						{text.row.status === 2 && <p className="textDarkBlue">In Progress</p>}

						{text.row.status === 3 && <p className="textDarkBlue">Completed</p>}

						{text.row.status === 4 && <p className="textDarkBlue">Pause</p>}
					</div>
				);
			},
		},
		{
			headerName: "Artwork Type",
			type: "singleSelect",
			valueOptions: [
				{ value: 1, label: "Physical" },
				{ value: 2, label: "Digital" },
			],
			field: "artworksType",
			width: 150,
			renderCell: (text) => {
				return (
					<div>
						{text.row.artworksType === 1 && <p className="textDarkBlue">Physical</p>}
						{text.row.artworksType === 2 && <p className="textBlue">Digital</p>}
					</div>
				);
			},
		},
		{
			headerName: "Action",

			renderCell: (record) => (
				<Popover placement="bottom" content={content(record.row)} overlayClassName="manage-artworks-action-popover">
					<img className="action-image" src={require("../../assets/images/common/artworksActionIcon.svg")} alt="action" />
				</Popover>
			),
		},
	];

	return (
		<Root>
			<Topbar title={"Auctions"} showBreadCrumbs showHead />
			<div id="auctions">
				<div className="auctions-list">
					{auctionList && <DataGrid
						pageSize={pageSize}
						onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
						rowsPerPageOptions={[20, 50, 100]}
						pagination
						getRowId={(row) => row._id}
						columns={columns}
						rows={auctionList}
					/>}
					{!isPageLoading && (
						<div className="add-button-box add-btn-fixed" onClick={addNewAucation}>
							<Tooltip placement="top" title={"Add New Coupon"}>
								<span className="add-btn">
									<p>+</p> {`Add Auction`}
								</span>
							</Tooltip>
						</div>
					)}
				</div>
			</div>
		</Root>
	);
};

export default AuctionListing;
