import React, { useEffect, useState } from "react";
import { popToast } from "../../components/Toast/Toast";
import { AbandonedCartService } from "./AbandonedCartService";

const useAbandonedCartReports = () => {
	const [cartObjects, setCartObjects] = useState([]);
	const [getCartObjectsLoader, setGetCartObjectLoader] = useState(false);
	const [downLoadCartObjectsLoader, setDownLoadCartObjectLoader] = useState(false);
	const [pageNum, setpageNum] = useState(1);

	useEffect(() => {
		getAbandonedCartObjects();
	}, [pageNum]);

	const getAbandonedCartObjects = async () => {
		try {
			setGetCartObjectLoader(true);
			let cartObjectResult = await AbandonedCartService.getAbandonedCartObjects(pageNum);
			if (cartObjectResult && cartObjectResult.result === 200) {
				setCartObjects(cartObjectResult.data);
			} else if (cartObjectResult && cartObjectResult.result === 400) {
				popToast.show(cartObjectResult.message);
			}
			setGetCartObjectLoader(false);
		} catch (error) {
			setGetCartObjectLoader(false);
		}
	};
	const onSelectPageNumber = (current, pageSize) => {
		setpageNum(current);

		return {};
	};

	const downLoadAbandonedCarts = () => {
		setDownLoadCartObjectLoader(true);
		AbandonedCartService.exportAbandonedCarts()
			.then((downLoadresponse) => {
				if (downLoadresponse) {
					const url = window.URL.createObjectURL(new Blob([downLoadresponse]));
					const link = document.createElement("a");
					link.href = url;
					link.download = `AbandonedCarts_${new Date().toLocaleString()}.xlsx`;
					// link.setAttribute("download", "file.pdf");
					document.body.appendChild(link); // we need to append the element to the dom -> otherwise it will not work in firefox
					link.click();
					link.remove(); //afterwards we remove the element again
				}
				setDownLoadCartObjectLoader(false);
			})
			.catch((err) => {
				console.log(err);
				popToast.show("Something went wrong!");
				setDownLoadCartObjectLoader(false);
			});
	};

	return {
		cartObjects,
		downLoadCartObjectsLoader,
		getCartObjectsLoader,
		onSelectPageNumber,
		pageNum,
		downLoadAbandonedCarts,
	};
};

export default useAbandonedCartReports;
