import React, { Component } from 'react';
import { Tooltip } from 'antd';
// Redux
import { connect } from 'react-redux';
import { setAddBrandsReducerStates, getAllBrands, deleteBrand } from '../../redux/actions/AddBrandsAction';
// Components
import Root from '../Root/Root';
import Topbar from '../../components/Topbar/Topbar';
import ConfirmationMessageDialog from '../../components/ConfirmationMessageDialog/ConfirmationMessageDialog';
import AddBrandsDialog from './AddBrandsDialog/AddBrandsDialog';
// SCSS
import './AddBrands.scss';

class AddBrands extends Component {

    constructor(props) {
        super(props);
        this.state = {
            brandToDelete: '',
            isUpdatingBrand: false,
            brandToEdit: {}
        }

        this.props.getAllBrands();
    }

    render() {
        const {
            brandsListCount, brandsList,
            addBrandsDialog, deleteBrandsDialog,
            modalLoader } = this.props.addBrandsReducer;
            
        const brandsTableList = brandsList.map((brand, index) => (
            <li key={brand._id}>
                <div className="add-brands-table-list-body">
                    <div className="add-brands-table-list-body-item cell-one">
                        <div className="image-box"><img src={brand.brandLogo || require('../../assets/images/junk/apple.svg')} alt="Icon" /></div>
                        {brand.brandName}
                    </div>
                    <div className="add-brands-table-list-body-item cell-four">
                        <span>{brand.createdUserName || 'NIL'}</span>
                    </div>
                    <div className="add-brands-table-list-body-item cell-two">
                        <span className="text-pending">{brand.status ? 'Approved' : 'Pending'}</span>
                    </div>
                    <div className="add-brands-table-list-body-item cell-three">
                        {/* Edit */}
                        <Tooltip placement="top" title={'Edit Brand'}>
                            <img className="edit-icon" src={require('../../assets/images/common/Edit.svg')} onClick={this.onEditBrandClick.bind(this, brand)} alt="edit" />
                        </Tooltip>
                        {/* Delete */}
                        <Tooltip placement="top" title={'Delete Brand'}>
                            <img src={require('../../assets/images/common/delete.svg')} onClick={this.onDeleteBrandsClick.bind(this, brand)} alt="Delete" />
                        </Tooltip>
                    </div>
                </div>
            </li>
        ));

        return (
            <Root>
                <Topbar title={'AddBrands'}  showBrandFilters showSearch onSearchFilterBrands={this.onSearchFilterBrands.bind(this)} />
                <div className="add-brands-wrapper">
                    <div className="add-brands-table">

                        {/* ---------- Table Headings ---------- */}
                        <ul className="add-brands-table-list">
                            <li className="add-brands-table-list-header">
                                <div className="add-brands-table-list-header-item cell-one">Brands</div>
                                <div className="add-brands-table-list-header-item cell-four">Created By</div>
                                <div className="add-brands-table-list-header-item cell-two">Status</div>
                                <div className="add-brands-table-list-header-item cell-three">Action</div>
                            </li>
                        </ul>
                        <ul className="add-brands-table-list bodyTable">

                            {/* Table Body */}
                            {brandsTableList}

                        </ul>
                    </div>

                    <div className="add-button-box">
                        <Tooltip placement="top" title={'Add Brand'}>
                            <img src={require('../../assets/images/common/Add.svg')} alt="Add" onClick={this.onAddBrandClick.bind(this)} />
                        </Tooltip>
                    </div>

                    <AddBrandsDialog
                        open={addBrandsDialog}
                        isUpdatingBrand={this.state.isUpdatingBrand || false}
                        brandDetails={this.state.brandToEdit || {}}
                        handleClose={this.toggleAddBrandsDialog.bind(this, false)} />

                    {/* Delete Brand */}
                    <ConfirmationMessageDialog
                        open={deleteBrandsDialog}
                        handleClose={this.toggleDeleteBrandDialog.bind(this, false)}
                        confirmMessage={'Are you sure you want to delete this brand?'}
                        confirmButton={'Delete'}
                        loader={modalLoader}
                        onConfirm={this.onDeleteBrand.bind(this)} />
                </div>
            </Root>
        );
    }

    // =================== List Brands / Search / Filter =================== //
    onSearchFilterBrands(categoryId, priority, searchValue) {

        // Category Id might return false, when values are cleared from input
        categoryId = categoryId === false ? '' : categoryId;

        // TODO : Filter by status pending
        this.props.getAllBrands(categoryId, '', priority, searchValue);
    }

    // ====================== Add Brands ====================== //
    onAddBrandClick() {
        this.setState({
            isUpdatingBrand: false,
            brandToEdit: {}
        }, () => {
            this.toggleAddBrandsDialog(true);
        })
    }

    toggleAddBrandsDialog(value = false) {
        this.props.handleAddBrandReducerStates('addBrandsDialog', value);
    }

    // ====================== Delete Brands ====================== //
    // Open delete brands confirmation
    onDeleteBrandsClick(data = '') {
        this.setState({
            brandToDelete: data._id
        }, () => {
            this.toggleDeleteBrandDialog(true);
        })
    }
    // Close confirmation modal
    toggleDeleteBrandDialog(value = false) {
        this.props.handleAddBrandReducerStates('deleteBrandsDialog', value);
    }
    // Delete brand
    onDeleteBrand() {
        this.props.deleteBrand(this.state.brandToDelete);
    }

    // ===================== Edit Brand ======================== //
    onEditBrandClick(data = {}) {
        this.setState({
            isUpdatingBrand: true,
            brandToEdit: data
        }, () => {
            this.toggleAddBrandsDialog(true);
        })
    }
}

const mapStateToProps = state => {
    return {
        addBrandsReducer: state.addBrandsReducer
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleAddBrandReducerStates: (state, value) => {
            dispatch(setAddBrandsReducerStates(state, value))
        },
        getAllBrands: (categoryId, status, priority, searchValue) => {
            dispatch(getAllBrands(categoryId, status, priority, searchValue))
        },
        deleteBrand: (brandId) => {
            dispatch(deleteBrand(brandId))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddBrands);