export const BaseTheme = {
  primary: "#58514B", // grey tinted black
  secondary: "#D2502C", // white
  buttonWhite: {
    bg: "#FFFFFF",
    fg: "#000000",
    border: "0.5px solid #707070"
  },
  buttonGrey: {
    bg: "black",
    fg: "#FFFFFF",
    border: "1px solid black"
  },
  header: {
    textAlign: "center",
    font: "Bold SF Pro Display",
    color: "#58514B",
    opacity: "1"
  },
  socialLink: {
    bg: "#4B4641",
    fg: "#FFFFFF",
    borderRadius: "8px",
    opacity: "1"
  },
  letterDim: {
    font: " SF Pro Display",
    color: "#707070",
    opacity: "1"
  },
  letterBlack: {
    font: " SF Pro Display",
    color: "#000000",
    opacity: "1"
  },
  letterGrey: {
    font: " SF Pro Display",
    color: "#58514B",
    opacity: "1"
  },
  dropDownMenu: {
    bg: "#58514B",
    fg: "#FFFFFF",
    font: "Regular SF Pro Display",
    border: "1px solid #000000",
    opacity: "1",
    textAlign: "left",
    boxShadowColor: "#00000029",
    selectedBg: "#E8E8E8",
    selectedFg: "#000000"
  },
  popUp: {
    fg: "#000000",
    bg: "#FFFFFF",
    boxShadow: "0px 6px 12px #00000029",
    opacity: "1"
  },
  navBar: {
    bg: "#EFEFEF",
    fg: "#58514B",
    opacity: "1"
  },
  footer: {
    bg: "#58514B",
    fg: "#9E9E9E",
    headersFg: "#FFFFFF",
    emailBg: "#535353",
    emailFg: "#9E9E9E"
  },
  shipment: {
    bg: "#F6F6F6",
    fg: "#000000"
  },
  menu: {
    fg: "#000000",
    bg: "#FFFFFF",
    selectedBg: "#58514B",
    selectedfg: "#FFFFFF"
  }
};
