import { APIService } from "../../util/api.service";
import { APIUrlConstants } from "../../util/apiUrl.constant";



export class UserSearchHistoryService {

    static async getUserSearchHistoryList(pageNum, pageSize, userId, keyword, date) {
        let url = APIUrlConstants.getApiUrl("keyWordSearches");
        return APIService.doGet(url + `?pageNum=${pageNum}&pageSize=${pageSize}&userID=${userId}&keyword=${keyword}&date=${date}`).then((resp) => {
            if (resp) {
                return resp;
            }
            throw resp;
        });
    }

    static async exportUserSearchHistoryList(pageNum, pageSize, userId, keyword, date) {
        let url = APIUrlConstants.getApiUrl("downLoadSearchHistory");
        return APIService.doDownload(url + `?pageNum=${pageNum}&pageSize=${pageSize}&userID=${userId}&keyword=${keyword}&date=${date}`).then((resp) => {
            if (resp) {
                return resp;
            }
            throw resp;
        });
    }
}