import { isEmpty, isNil } from "lodash";
import { useEffect, useState } from "react";
import { popToast } from "../../../../components/Toast/Toast";
import { AuctionService } from "../../AuctionService";
import moment from "moment";
import { useDispatch } from "react-redux";
import * as types from "../../../../redux/actions/ActionsTypes";
import history from "../../../../util/history";

const useEditAuctionLot = (match) => {
    const dispatch = useDispatch();
    const [auctionLotObj, setAuctionLotObj] = useState({
        name: "",
        auctionId: "",
        reservePrice: "",
        bidLimit: "",
        rank: 0,
        stockId: "",
        slugId: "test3",
        _id: "",
        startDate: "",
        endDate: "",
        description: "",
        startBidPrice: "",
    });

    const [isApiCalling, setIsApiCalling] = useState(false);
    const [auctionStartDate, setAuctionStartDate] = useState();
    const [auctionEndDate, setAuctionEndDate] = useState();

    useEffect(() => {
        if (!isNil(match)) {
            const params = match.params;

            if (params) {

                const lotId = params.lotId;
                setAuctionStartDate(params.startDate);
                setAuctionEndDate(params.endDate);

                if (!isEmpty(lotId)) {
                    AuctionService.getAuctionLotById(lotId).then(resp => {
                        if (resp && resp.result === 200) {
                            setAuctionLotObj({ ...resp.data })
                        } else if (resp && resp.result === 400) {
                            popToast.show(resp.message);
                        }
                    })
                }

            }
        }

    }, [match]);

    const onHandleInputChange = (event) => {
        const { name, value } = event.target;
        setAuctionLotObj(s => ({ ...s, [name]: value }));
    }

    const onDateChange = (date, dateString) => {
        console.log(date, dateString);
        if (isEmpty(date)) {
            setAuctionLotObj(s => ({ ...s, startDate: moment(), endDate: moment() }));
        } else {
            setAuctionLotObj(s => ({ ...s, startDate: !isNil(date[0]) ? date[0].toDate() : null, endDate: !isNil(date[1]) ? date[1].toDate() : null }));
        }
    }

    const onClickSaveAuctionLot = () => {
        try {

            if (IsValidLot(auctionLotObj)) {
                if (!isNil(auctionLotObj) && isEmpty(auctionLotObj._id)) {
                    setIsApiCalling(true);
                    AuctionService.addAuctionLot(auctionLotObj).then(resp => {
                        saveLotSuccess(resp);
                    })
                } else {
                    AuctionService.updateAuctionLot(auctionLotObj._id, auctionLotObj).then(resp => {
                        saveLotSuccess(resp);
                    })
                }
            }
        } catch (error) {
            setIsApiCalling(false);
        }
    }

    const saveLotSuccess = (response) => {
        if (response && response.result === 200) {
            popToast.show("Atwork added successfully.");
            dispatch(success(types.ADD_AUCTIONLOT_SUCCESS, response.data));
            history.push(`/auctions/summary/${auctionLotObj.auctionId}`);
        } else if (response && response.result === 400) {
            popToast.show(response.message);
        }
        setIsApiCalling(false);
    }

    const IsValidLot = (lot) => {
        if (isNullOrEmpty(lot.stockId)) {
            popToast.show("Please select an artwork")
            return false;
        }
        if (isNullOrEmpty(lot.reservePrice)) {
            popToast.show("Please enter Reserve Price");
            return false;
        } else if (isNaN(parseFloat(lot.reservePrice))) {
            popToast.show("Please enter valid Reserve Price");
            return false;
        }
        if (isNullOrEmpty(lot.startBidPrice)) {
            popToast.show("Please enter Start Bid");
            return false;
        } else if (lot.startBidPrice > lot.reservePrice) {
            popToast.show("Please enter Start Bid Price less than Reserve Price");
            return false;
        }
        if (isNullOrEmpty(lot.bidLimit)) {
            popToast.show("Please enter Bid Limit")
            return false;
        } else if (isNaN(parseFloat(lot.bidLimit))) {
            popToast.show("Please enter a valid Bid Limit")
            return false;
        }

        if (!moment(lot.startDate).isValid()) {
            popToast.show("Please enter a valid Start Date");
            return false;
        }
        if (!moment(lot.endDate).isValid()) {
            popToast.show("Please enter a valid End Date");
            return false;
        } else {
            if (moment(lot.endDate).isBefore(moment(lot.startDate))) {
                popToast.show("Please enter a valid future End Date");
                return false;
            }
        }

        return true;
    }

    const isNullOrEmpty = (obj) => {
        if (obj === "" || obj === undefined || obj === null) {
            return true;
        }
        return false
    }

    const onClickCancelAuctionLot = () => {
        history.push(`/auctions/summary/${auctionLotObj.auctionId}`);
    }

    return {
        auctionLotObj,
        isApiCalling,
        auctionStartDate,
        auctionEndDate,

        onHandleInputChange,
        onDateChange,
        onClickSaveAuctionLot,
        onClickCancelAuctionLot
    }

    function request(type) {
        return { type };
    }
    function success(type, data) {
        return { type, data };
    }
    function failure(type, error) {
        return { type, error };
    }
}

export default useEditAuctionLot;