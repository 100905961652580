import { Button, Input, Radio, DatePicker, Typography, Icon, Tooltip, Checkbox } from "antd";
import React, { useRef, useState, useEffect } from "react";
import moment from "moment";
import Topbar from "../../../components/Topbar/Topbar";
import Root from "../../Root/Root";
import "./auctionDetails.scss";
import useAuctionDetails from "./useAuctionDetails";
import { isEmpty, isNil } from "lodash";
import { Util } from "../../../util/util";
import { useDispatch, useSelector, useStore } from "react-redux";
import { getBlogsAction, createBlogsAction } from "../../../redux/actions/BlogAction";

import Select from "react-select";

const { TextArea } = Input;
const { Title } = Typography;

const AuctionDetails = ({ match }) => {
	const {
		auctionObj,
		apiLoading,
		mediaObj,
		imageMediaObj,
		nftCalenderMediaObj1,
		nftCalenderMediaObj2,
		nftCalenderMediaObj3,
		selectedBlogList,

		setImageMediaObject,
		onHandleInputChange,
		onDateChange,
		onSaveAuction,
		onclickCancel,
		onImageSelect,
		onSetBlogList,
		onRemoveBlogList,
		onHandleIsDeclared
	} = useAuctionDetails(match);
	const dispatch = useDispatch();
	const state = useSelector((state) => state);
	const [blogList, setBlogList] = useState([]);
	console.log("🚀 ~ file: AuctionDetails.js ~ line 42 ~ AuctionDetails ~ blogList", blogList);
	const [blogDefaultList, setBlogDefaultList] = useState([]);
	console.log("🚀 ~ file: AuctionDetails.js ~ line 43 ~ AuctionDetails ~ blogDefaultList", blogDefaultList);
	useEffect(() => {
		dispatch(getBlogsAction(1, 100));
	}, []);

	useEffect(() => {
		if (state.blogReducer && state.blogReducer.blogList) {
			let options = [];
			state.blogReducer.blogList.map((data, index) => {
				let optionObj = {
					label: data.title,
					value: data._id,
				};
				options.push(optionObj);
			});
			setBlogList(options);
			//Get selected blogs
			if (auctionObj.blogIds && auctionObj.blogIds.length > 0) {
				let selectedBlogArray = [];
				// setBlogDefaultList(auctionObj.blogIds);
				auctionObj.blogIds.map((blog) => {
					let blogObj = state.blogReducer.blogList.filter((item) => item._id === blog);
					if (blogObj && blogObj.length > 0) {
						let optionObj = {
							label: blogObj[0].title,
							value: blogObj[0]._id,
						};
						selectedBlogArray.push(optionObj);
					}
				});
				onSetBlogList(selectedBlogArray && selectedBlogArray.length > 0 ? selectedBlogArray : []);
			}
		}
	}, [state.blogReducer.blogList]);

	const banner_image = useRef(null);
	const auct_image = useRef(null);
	const calender_banner_image_1 = useRef(null);
	const calender_banner_image_2 = useRef(null);
	const calender_banner_image_3 = useRef(null);

	return (
		<>
			<Root>
				<Topbar title={"Auctions"} showBreadCrumbs showHead />

				<div id="auction-details-id">
					<h2>Auction Details</h2>
					<div className="form-control">
						<label className="form-label">NAME*</label>
						<Input required type="text" autoFocus value={auctionObj.name} name="name" onChange={onHandleInputChange} />
					</div>
					<div className="form-control">
						<label className="form-label">DESCRIPTION</label>
						<TextArea
							rows={4}
							type="text"
							value={auctionObj.description}
							name="description"
							onChange={onHandleInputChange}
							maxLength={250}
						/>
					</div>
					<div className="form-date-group">
						<div className="form-control-date">
							<label className="form-label">START DATE ({Util.getCurrentTimeZoneName()})*</label>
							<DatePicker
								showTime={true}
								onOk={(date) => onDateChange(date, "", "startDate")}
								showToday={false}
								value={moment(auctionObj.startDate)}
								name="startDate"
								onChange={(date, dateString) => onDateChange(date, dateString, "startDate")}
							/>
						</div>
						<div className="form-control-date">
							<label className="form-label">END DATE ({Util.getCurrentTimeZoneName()})*</label>
							<DatePicker
								showTime={true}
								onOk={(date) => onDateChange(date, "", "endDate")}
								showToday={false}
								value={moment(auctionObj.endDate)}
								name="endDate"
								onChange={(date, dateString) => onDateChange(date, dateString, "endDate")}
							/>
						</div>
					</div>
					<div className="form-control">
						<label className="form-label">PUBLISH AUCTION DATE</label>
						<Checkbox checked={auctionObj.isDeclared} name="isDeclared" onChange={onHandleIsDeclared} />
					</div>
					<div className="form-control">
						<label className="form-label">TIME GAP*</label>
						<Input type="text" value={auctionObj.timeGap} name="timeGap" onChange={onHandleInputChange} />
					</div>
					<div className="form-control">
						<label className="form-label">SEO TITLE*</label>
						<Input type="text" value={auctionObj.seoTitle} name="seoTitle" onChange={onHandleInputChange} />
					</div>
					<div className="form-control">
						<label className="form-label">SEO DESCRIPTION</label>
						<Input type="text" value={auctionObj.seoDescription} name="seoDescription" onChange={onHandleInputChange} />
					</div>
					<div className="form-control">
						<label className="form-label">KEYWORDS</label>
						<Input type="text" value={auctionObj.keywords} name="keywords" onChange={onHandleInputChange} />
					</div>
					<div className="form-control">
						<label className="form-label">SLUG ID*</label>
						<Input type="text" value={auctionObj.slugId} name="slugId" onChange={onHandleInputChange} />
					</div>

					<div className="form-control">
						<label className="form-label">Banner Label</label>
						<Input type="text" value={auctionObj.bannerLabel} name="bannerLabel" onChange={onHandleInputChange} />
					</div>

					<div className="form-control">
						<label className="form-label">Banner HashTag</label>
						<Input type="text" value={auctionObj.bannerHashtag} name="bannerHashtag" onChange={onHandleInputChange} />
					</div>

					<div className="form-control">
						<label className="form-label">Flash Message</label>
						<Input type="text" value={auctionObj.flashMessage} name="flashMessage" onChange={onHandleInputChange} />
					</div>

					<div className="form-control">
						<label className="form-label">Flash Message Redirection Link</label>
						<Input type="text" value={auctionObj.redirectionLink} name="redirectionLink" onChange={onHandleInputChange} />
					</div>

					<div className="form-control">
						<label className="form-label">Over View</label>
						<TextArea rows={4} type="text" value={auctionObj.overView} name="overView" onChange={onHandleInputChange} />
					</div>
					<div className="form-control artwork-type">
						<label className="form-label">ARTWORK TYPE*</label>
						<Radio.Group onChange={onHandleInputChange} name="artworksType" value={auctionObj.artworksType}>
							<Radio value={1}>PHYSICAL</Radio>
							<Radio value={2}>DIGITAL</Radio>
						</Radio.Group>
					</div>
					<p className="product-iv-title">Auction Blogs</p>
					<div className="form-control">
						<label className="form-label">Choose the auction blogs</label>
						{/* <MultiSelectDropdown
							data={blogList}
							showCheckbox={true}
							selectedCoutry={selectedBlogList} // Preselected value to persist in dropdown
							onSetCountryArray={onSetBlogList} // Function will trigger on select event
							onRemoveCountryArray={onRemoveBlogList} // Function will trigger on remove event
							displayValue={"title"} // Function will trigger on remove event
							width={"100%"}
						/> */}
						<Select value={selectedBlogList} options={blogList} isMulti onChange={onSetBlogList} />
					</div>

					<p className="product-iv-title">Banner Image</p>
					<div className={`product-iv-contents `}>
						{auctionObj.auctionBanner && (
							<div className="product-iv-item">
								<img src={auctionObj.auctionBanner} alt="Artwork" />

								<div className="display-hover-screen">
									<Icon className="edit-icon" type="edit" onClick={(e) => banner_image.current.click()} />
								</div>
							</div>
						)}
						{mediaObj && mediaObj.mediaResult && (
							<div className="product-iv-item">
								<img src={mediaObj.mediaResult} alt="Artwork" />

								<div className="display-hover-screen">
									<Icon className="edit-icon" type="edit" onClick={(e) => banner_image.current.click()} />
								</div>
							</div>
						)}

						{isEmpty(auctionObj.auctionBanner) && isNil(mediaObj) && (
							<div className="product-iv-item" onClick={(e) => banner_image.current.click()}>
								<img src={require("../../../assets/images/common/Add_Images.svg")} alt="Artwork" />
							</div>
						)}
						<input
							type="file"
							style={{ display: "none" }}
							accept=".bmp, .gif, .ico, .jpeg, .jpg, .png, .svg, .tif, .tiff, .jiff, .jfif, .pjpeg, .pjp"
							onChange={(e) => onImageSelect(e, "disp")}
							ref={banner_image}
						/>
					</div>

					{/* --------------- Images --------------- */}
					<p className="product-iv-title">Image</p>
					<div className="product-iv-contents">
						{auctionObj.auctionImage && (
							<div className="product-iv-item">
								<img src={auctionObj.auctionImage} alt="Artwork" />
								<div className="display-hover-screen">
									<Icon className="edit-icon" type="edit" onClick={(e) => auct_image.current.click()} />
								</div>
							</div>
						)}
						{imageMediaObj && imageMediaObj.mediaResult && (
							<div className="product-iv-item">
								<img src={imageMediaObj.mediaResult} alt="Artwork" />
								<div className="display-hover-screen">
									<Icon className="edit-icon" type="edit" onClick={(e) => auct_image.current.click()} />
								</div>
							</div>
						)}
						{isEmpty(auctionObj.auctionImage) && isNil(imageMediaObj) && (
							<div className="product-iv-item" onClick={(e) => auct_image.current.click()}>
								<img src={require("../../../assets/images/common/Add_Images.svg")} alt="Artwork" />
							</div>
						)}
						<input
							type="file"
							style={{ display: "none" }}
							accept=".bmp, .gif, .ico, .jpeg, .jpg, .png, .svg, .tif, .tiff, .jiff, .jfif, .pjpeg, .pjp"
							onChange={(e) => onImageSelect(e, "image")}
							ref={auct_image}
						/>
					</div>

					<div className="form-control artwork-type">
						<label className="form-label">NFT Auction Calendar </label>
						<div className="nft-calender-main-container">
							<div className="nft-calender-section-block-img">
								<label className="form-label">Calender Image </label>
								<div className={`product-iv-contents `}>
									{auctionObj.nftCalendarImgFormFile_1 && isNil(nftCalenderMediaObj1) && (
										<div className="product-iv-item">
											<img src={auctionObj.nftCalendarImgFormFile_1} alt="Artwork" />

											<div className="display-hover-screen">
												<Icon className="edit-icon" type="edit" onClick={(e) => calender_banner_image_1.current.click()} />
											</div>
										</div>
									)}
									{nftCalenderMediaObj1 && nftCalenderMediaObj1.mediaResult && (
										<div className="product-iv-item">
											<img src={nftCalenderMediaObj1.mediaResult} alt="Artwork" />
											<div className="display-hover-screen">
												<Icon className="edit-icon" type="edit" onClick={(e) => calender_banner_image_1.current.click()} />
											</div>
										</div>
									)}
									{isEmpty(auctionObj.nftCalendarImgFormFile_1) && isNil(nftCalenderMediaObj1) && (
										<div className="product-iv-item" onClick={(e) => calender_banner_image_1.current.click()}>
											<img src={require("../../../assets/images/common/Add_Images.svg")} alt="Artwork" />
										</div>
									)}
									<input
										type="file"
										style={{ display: "none" }}
										accept=".bmp, .gif, .ico, .jpeg, .jpg, .png, .svg, .tif, .tiff, .jiff, .jfif, .pjpeg, .pjp"
										onChange={(e) => onImageSelect(e, "nft_1")}
										ref={calender_banner_image_1}
									/>
								</div>
							</div>
							<div className="nft-calender-section-block">
								<label className="form-label">Calender Title </label>
								<Input type="text" value={auctionObj.nftCalenderTitle_1} name="nftCalenderTitle_1" onChange={onHandleInputChange} />
							</div>
							<div className="nft-calender-section-block">
								<label className="form-label">Calender URL </label>
								<Input type="text" value={auctionObj.nftCalenderUrl_1} name="nftCalenderUrl_1" onChange={onHandleInputChange} />
							</div>
						</div>
						<div className="nft-calender-main-container">
							<div className="nft-calender-section-block-img">
								<label className="form-label">Calender Image </label>
								<div className={`product-iv-contents `}>
									{auctionObj.nftCalendarImgFormFile_2 && isNil(nftCalenderMediaObj2) && (
										<div className="product-iv-item">
											<img src={auctionObj.nftCalendarImgFormFile_2} alt="Artwork" />

											<div className="display-hover-screen">
												<Icon className="edit-icon" type="edit" onClick={(e) => calender_banner_image_2.current.click()} />
											</div>
										</div>
									)}
									{nftCalenderMediaObj2 && nftCalenderMediaObj2.mediaResult && (
										<div className="product-iv-item">
											<img src={nftCalenderMediaObj2.mediaResult} alt="Artwork" />
											<div className="display-hover-screen">
												<Icon className="edit-icon" type="edit" onClick={(e) => calender_banner_image_2.current.click()} />
											</div>
										</div>
									)}
									{isEmpty(auctionObj.nftCalendarImgFormFile_2) && isNil(nftCalenderMediaObj2) && (
										<div className="product-iv-item" onClick={(e) => calender_banner_image_2.current.click()}>
											<img src={require("../../../assets/images/common/Add_Images.svg")} alt="Artwork" />
										</div>
									)}
									<input
										type="file"
										style={{ display: "none" }}
										accept=".bmp, .gif, .ico, .jpeg, .jpg, .png, .svg, .tif, .tiff, .jiff, .jfif, .pjpeg, .pjp"
										onChange={(e) => onImageSelect(e, "nft_2")}
										ref={calender_banner_image_2}
									/>
								</div>
							</div>
							<div className="nft-calender-section-block">
								<label className="form-label">Calender Title </label>
								<Input type="text" value={auctionObj.nftCalenderTitle_2} name="nftCalenderTitle_2" onChange={onHandleInputChange} />
							</div>
							<div className="nft-calender-section-block">
								<label className="form-label">Calender URL </label>
								<Input type="text" value={auctionObj.nftCalenderUrl_2} name="nftCalenderUrl_2" onChange={onHandleInputChange} />
							</div>
						</div>
						<div className="nft-calender-main-container">
							<div className="nft-calender-section-block-img">
								<label className="form-label">Calender Image </label>
								<div className={`product-iv-contents `}>
									{auctionObj.nftCalendarImgFormFile_3 && isNil(nftCalenderMediaObj3) && (
										<div className="product-iv-item">
											<img src={auctionObj.nftCalendarImgFormFile_3} alt="Artwork" />

											<div className="display-hover-screen">
												<Icon className="edit-icon" type="edit" onClick={(e) => calender_banner_image_3.current.click()} />
											</div>
										</div>
									)}
									{nftCalenderMediaObj3 && nftCalenderMediaObj3.mediaResult && (
										<div className="product-iv-item">
											<img src={nftCalenderMediaObj3.mediaResult} alt="Artwork" />
											<div className="display-hover-screen">
												<Icon className="edit-icon" type="edit" onClick={(e) => calender_banner_image_3.current.click()} />
											</div>
										</div>
									)}
									{isEmpty(auctionObj.nftCalendarImgFormFile_3) && isNil(nftCalenderMediaObj3) && (
										<div className="product-iv-item" onClick={(e) => calender_banner_image_3.current.click()}>
											<img src={require("../../../assets/images/common/Add_Images.svg")} alt="Artwork" />
										</div>
									)}
									<input
										type="file"
										style={{ display: "none" }}
										accept=".bmp, .gif, .ico, .jpeg, .jpg, .png, .svg, .tif, .tiff, .jiff, .jfif, .pjpeg, .pjp"
										onChange={(e) => onImageSelect(e, "nft_3")}
										ref={calender_banner_image_3}
									/>
								</div>
							</div>
							<div className="nft-calender-section-block">
								<label className="form-label">Calender Title </label>
								<Input type="text" value={auctionObj.nftCalenderTitle_3} name="nftCalenderTitle_3" onChange={onHandleInputChange} />
							</div>
							<div className="nft-calender-section-block">
								<label className="form-label">Calender URL </label>
								<Input type="text" value={auctionObj.nftCalenderUrl_3} name="nftCalenderUrl_3" onChange={onHandleInputChange} />
							</div>
						</div>
					</div>
					<hr />
					<div className="auction-details-action">
						<Button onClick={onclickCancel}>Cancel</Button>
						<Button onClick={onSaveAuction} loading={apiLoading}>
							{"NEXT"}
						</Button>
					</div>
				</div>
			</Root>
		</>
	);
};

export default AuctionDetails;
