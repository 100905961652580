import React, { useEffect, useState } from "react";
import { Select } from "antd";
import lodash from "lodash";
import { FilterSelect } from "./FormikYearSelect.styled";
const { Option } = Select;

const FormikYearSelect = ({ field, form, isSelect, ...props }) => {
	const { options, placeholder = "Please select" } = props;
	const [, setDefaultValue] = useState(null);


	useEffect(() => {
		setDefaultValue(field.value);
	}, []);

	const onChange = value => {
		setDefaultValue(value);
		if (value) {
			form.setFieldValue(field.name, value);
		} else {
			form.setFieldValue(field.name, null);
		}
	};
	const onDropdownVisibleChange = event => {
		if (!event) {
			isSelect(true);
		} else {
			isSelect(false);
		}
	};
	const children = lodash.range(1919, options + 1).map(value => (
		<Option key={value} value={value}>
			{value}
		</Option>
	));

	return (
		<FilterSelect
			mode="default"
			style={{ width: 80 }}
			placeholder={placeholder}
			value={form.values.year ? form.values.year : undefined}
			onChange={onChange}
			onDropdownVisibleChange={onDropdownVisibleChange}
		>
			{children}
		</FilterSelect>
	);
};

export default FormikYearSelect;
