import React from "react";
import { Dialog, Slide, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import { Table } from "antd";
import TimeAgo from 'react-timeago'
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const BiddingHistory = ({ openBiddingHistoryDrawer, onCloseBiddingHistoryDrawer, biddingHistoryDataSource }) => {

    const columns = [
        {
            title: "BIDDING PRICE",
            dataIndex: "bidAmount",
            key: "bidAmount",
        },
        {
            title: "DATE & TIME",
            dataIndex: "date",
            key: "date",
            render: (text) => (
                <TimeAgo date={text} />

            ),

        },
        {
            title: "COLLECTOR",
            dataIndex: "bidderName",
            key: "bidderName",
        },

    ];


    return (
        <Dialog
            fullWidth
            open={openBiddingHistoryDrawer}
            onClose={() => onCloseBiddingHistoryDrawer(false)}
            TransitionComponent={Transition}
            BackdropProps={{ classes: { root: "dialog-backdrop" } }}
            className="add-coupon-dialog"
        >
            <img className="close-btn" onClick={() => onCloseBiddingHistoryDrawer(false)} src={require("../../../assets/images/common/Close_h.svg")} alt="" />
            <DialogTitle className="dialog-title">Bidding History </DialogTitle>
            <DialogContent className="add-coupon-content">

                <Table fullScreen={false} rowKey={(record) => record.rfid}
                    columns={columns}
                    dataSource={biddingHistoryDataSource}
                    pagination={{ pageSize: 5 }}
                />
            </DialogContent>
        </Dialog>

    )
}

export default BiddingHistory;
