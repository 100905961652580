import React, { useEffect, useState } from "react";
import { popToast } from "../../components/Toast/Toast";
import { UserSearchHistoryService } from "./UserSearchHistoryService";

const useUserSearchHistory = () => {
    const [filter, setFilter] = useState({
        pageNum: 1,
        pageSize: 10,
        userId: "",
        keyword: "",
        date: "",
    });
    const [totalCount, setTotalCount] = useState();
    const [userSearchObjects, setUserSearchObjects] = useState([]);
    const [getUserSearchHistoryLoader, setGetUserSearchHistoryLoader] = useState(false);
    const [downLoadUserSearchHistoryLoader, setDownLoadUserSearchHistoryLoader] = useState(false);

    useEffect(() => {
        getUserSearchHistoryObjects();
    }, []);

    useEffect(() => {
        getUserSearchHistoryObjects();
    }, [filter.pageNum, filter.keyword]);

    const getUserSearchHistoryObjects = async () => {
        try {
            setGetUserSearchHistoryLoader(true);
            let cartObjectResult = await UserSearchHistoryService.getUserSearchHistoryList(filter.pageNum, filter.pageSize, filter.userId, filter.keyword, filter.date);
            if (cartObjectResult && cartObjectResult.result === 200) {
                setUserSearchObjects(cartObjectResult.data.searches);
                setTotalCount(cartObjectResult.data.count);
            } else if (cartObjectResult && cartObjectResult.result === 400) {
                popToast.show(cartObjectResult.message);
            }
            setGetUserSearchHistoryLoader(false);
        } catch (error) {
            setGetUserSearchHistoryLoader(false);
        }

    }

    const exportUserSearchHistoryList = () => {

        setDownLoadUserSearchHistoryLoader(true);
        UserSearchHistoryService.exportUserSearchHistoryList(filter.pageNum, filter.pageSize, filter.userId, filter.keyword, filter.date).then(downLoadresponse => {
            if (downLoadresponse) {
                const url = window.URL.createObjectURL(new Blob([downLoadresponse]));
                const link = document.createElement("a");
                link.href = url;
                link.download = `UserSearchHistory_${new Date().toLocaleString()}.xlsx`;
                // link.setAttribute("download", "file.pdf");
                document.body.appendChild(link); // we need to append the element to the dom -> otherwise it will not work in firefox
                link.click();
                link.remove(); //afterwards we remove the element again

            }
            setDownLoadUserSearchHistoryLoader(false);
        }).catch(err => {
            console.log(err);
            popToast.show("Something went wrong!");
            setDownLoadUserSearchHistoryLoader(false);
        })
    }

    const onSelectPageNumber = (eve) => {
        setFilter(s => ({ ...s, pageNum: eve }));
    }

    const onChangeSearch = (eve) => {
        setFilter(s => ({ ...s, pageNum: 1, keyword: eve }));
    }

    return {
        userSearchObjects,
        getUserSearchHistoryLoader,
        downLoadUserSearchHistoryLoader,
        filter,
        totalCount,

        exportUserSearchHistoryList,
        onSelectPageNumber,
        onChangeSearch
    }
}

export default useUserSearchHistory;