import React, { useEffect, useState } from 'react';
import { Icon, Popover, Table, Modal, } from 'antd';
import moment from 'moment';
import { ManageArtworkService } from './ManageArtworks.service';
import { popToast } from '../../components/Toast/Toast';
import ConfirmationMessageDialog from '../../components/ConfirmationMessageDialog/ConfirmationMessageDialog';
import { blockArtworkOriginal, onApproveNFTRedeemable, onMarkNFTRedeemable, submitArtworktoBlockchain } from '../../redux/actions/ManageArtworksAction';
import Lightbox from "react-image-lightbox";

const ViewEditions = ({ show, onCloseCouponDrawer, artworkId }) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const [editionList, setEditionList] = useState([]);
    const [openMarsSoldPopup, setOpenMarsSoldPopup] = useState(false);
    const [markAsSoldApiLoading, setMarkAsSoldApiLoading] = useState(false);
    const [mintTokenApiLoading, setMintTokenApiLoading] = useState(false);
    const [reserveForAuctionApiLoading, setReserveForAuctionApiLoading] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState();
    const [openApproveConfirmPopup, setOpenApproveConfirmPopup] = useState(false);
    const [openNftRedeemableConfirmPopup, setOpenNftRedeemableConfirmPopup] = useState(false);
    const [openApproveNftRedeemConfirmPopup, setOpenApproveNftRedeemConfirmPopup] = useState(false);
    const [openUnHoldConfirmPopup, setOpenUnHoldConfirmPopup] = useState(false);
    const [openHoldConfirmPopup, setOpenHoldConfirmPopup] = useState(false);
    const [deleteconfirmationDialog, setDeleteconfirmationDialog] = useState(false);
    const [openReserveForAuctionConfirmPopup, setOpenReserveForAuctionConfirmPopup] = useState(false);
    const [rejectconfirmationDialog, setRejectconfirmationDialog] = useState(false);
    const [mintTokenConfirmationDialog, setMintTokenConfirmationDialog] = useState(false);
    const [newStatus, setNewStatus] = useState();
    const [nfcUrl, setNfcUrl] = useState();
    const [isNfcOpen, setIsNfcOpen] = useState(false);

    useEffect(() => {
        if (artworkId) {
            getEditionStocks(artworkId);
        }
    }, [artworkId]);

    const getEditionStocks = async (artworkId) => {
        const response = await ManageArtworkService.getArtworkStockListByArtworkId(artworkId);
        if (response && response.result === 200) {
            setEditionList(response.data)
        } else if (response && response.result === 400) {
            popToast.show(response.message);
        }
    }

    const toggleMarkAsSoldConfirmationDialog = (record, status) => {
        setOpenMarsSoldPopup(!openMarsSoldPopup);
        setSelectedRecord(record);
        setNewStatus(status);
    }

    const toggleApproveConfirmationDialog = (record, status) => {
        setOpenApproveConfirmPopup(!openApproveConfirmPopup);
        setSelectedRecord(record);
        setNewStatus(status)
    }

    const toggleNftRedeemableConfirmationDialog = (record) => {
        setOpenNftRedeemableConfirmPopup(!openNftRedeemableConfirmPopup);
        setSelectedRecord(record);
    }

    const toggleApproveNftRedeemConfirmationDialog = (record) => {
        setOpenApproveNftRedeemConfirmPopup(!openApproveNftRedeemConfirmPopup);
        setSelectedRecord(record);
    }

    const toggleUnHoldConfirmationDialog = (record, status) => {
        setOpenUnHoldConfirmPopup(!openUnHoldConfirmPopup);
        setSelectedRecord(record);
        setNewStatus(status)
    }

    const toggleHoldConfirmationDialog = (record, status) => {
        setOpenHoldConfirmPopup(!openHoldConfirmPopup);
        setSelectedRecord(record);
        setNewStatus(status)
    }

    const toggleDeleteConfirmationDialog = (record, status) => {
        setDeleteconfirmationDialog(!deleteconfirmationDialog);
        setSelectedRecord(record);
        setNewStatus(status)
    }

    const toggleReserveForAuctionConfirmationDialog = (record, status) => {
        setOpenReserveForAuctionConfirmPopup(!openReserveForAuctionConfirmPopup);
        setSelectedRecord(record);
        setNewStatus(status)
    }

    const toggleRejectConfirmationDialog = (record, status) => {
        setRejectconfirmationDialog(!rejectconfirmationDialog);
        setSelectedRecord(record);
        setNewStatus(status)
    }

    const toggleMintTokenConfirmationDialog = (record) => {
        setMintTokenConfirmationDialog(true);
        setSelectedRecord(record);
    }

    const resetState = () => {
        getEditionStocks(artworkId);
        setMarkAsSoldApiLoading(false);
        setOpenMarsSoldPopup(false);
        setSelectedRecord();
        setOpenApproveConfirmPopup(false);
        setOpenReserveForAuctionConfirmPopup(false);
        setOpenUnHoldConfirmPopup(false);
        setDeleteconfirmationDialog(false);
        setRejectconfirmationDialog(false);
        setNewStatus();
        setMintTokenConfirmationDialog(false);
        setMintTokenApiLoading(false);
        setOpenHoldConfirmPopup(false);
        setOpenNftRedeemableConfirmPopup(false);
        setOpenApproveNftRedeemConfirmPopup(false);
    }

    const onMintToken = (item) => {
        setMintTokenApiLoading(true);
        submitArtworktoBlockchain(null, selectedRecord._id).then(resp => {
            resetState();
        }).catch(err => {
            setMintTokenApiLoading(false);
        })

    }

    const onMarkNftRedeemable = () => {
        setMintTokenApiLoading(true);
        onMarkNFTRedeemable(selectedRecord._id).then(resp => {
            resetState();
        }).catch(err => {
            setMintTokenApiLoading(false);
        })

    }

    const onApproveNftRedeem = () => {
        setMintTokenApiLoading(true);
        onApproveNFTRedeemable(selectedRecord._id).then(resp => {
            resetState();
        }).catch(err => {
            setMintTokenApiLoading(false);
        })

    }

    const onUpdateArtworkStatus = () => {
        setMarkAsSoldApiLoading(true);
        let request = {
            stockId: selectedRecord._id,
            status: newStatus,
        };
        ManageArtworkService.updateProductStatus(request).then(
            (response) => {
                if (response && response.result === 200 && response.message === "Success") {
                    popToast.show("Artwork status update successfully ");
                } else if (response) {
                    popToast.show(response.message || "Something went wrong while Update status!");
                }
                resetState();
            },
            (error) => {
                if (error && error.status_message) {
                    popToast.show(error.status_message);
                } else {
                    popToast.show("Something went wrong while Update status!");
                }
                resetState();
            }
        );
    }

    const onUpdateReserveForAuctionStatus = () => {
        setMarkAsSoldApiLoading(true);
        let request = {
            stockId: selectedRecord._id,
            status: 16,
        };
        ManageArtworkService.updateProductStatus(request).then(
            (response) => {
                if (response && response.result === 200 && response.message === "Success") {
                    popToast.show("Artwork status update successfully ");
                } else if (response) {
                    popToast.show(response.message || "Something went wrong while Update status!");
                }
                resetState();
            },
            (error) => {
                if (error && error.status_message) {
                    popToast.show(error.status_message);
                } else {
                    popToast.show("Something went wrong while Update status!");
                }
                resetState();
            }
        );
    }

    const onUnHoldArtwork = () => {
        ManageArtworkService.unHoldProduct(selectedRecord._id, "").then(
            (response) => {
                if (response && response.message === "Success") {
                    popToast.show("Artwork Update Successfully");
                } else if (response && response.message === "Failure") {
                    popToast.show("Update Artwork Error : " + response.Message || "Something went wrong while deleting artwork!");
                }
                resetState();
            },
            (error) => {
                if (error && error.status_message) {
                    popToast.show(error.status_message);
                } else {
                    popToast.show("Something went wrong while deleting artwork!");
                }
                resetState();
            }
        );
    }

    const onNfcClick = (url) => {
        setNfcUrl(url);
        setIsNfcOpen(true);
    };
    const onCloseNfcClick = () => {
        setNfcUrl(null);
        setIsNfcOpen(false);
    }


    const columns = [

        {
            title: "Product Name",
            dataIndex: "productName",
            key: "productName",
        },
        {
            title: "NFC Image",
            dataIndex: "nfcImage",
            key: "nfcImage",
            render: (text) => (
                <span className="colWrapper">
                    {text ? (
                        <img className="colImage" src={text} onClick={() => onNfcClick(text)} alt="productImage" />
                    ) : (
                        "NFC image not added"
                    )}
                </span>
            ),
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (text) => {
                return (
                    <div>
                        {text === 1 && <p className="textBlue">Available</p>}
                        {text === 2 && <p className="textBlue">Available Resell</p>}
                        {text === 3 && <p className="textGreen">Sold out</p>}
                        {text === 4 && <p className="textDarkBlue">Pending With Artist</p>}
                        {text === 5 && <p className="textDarkBlue">Pending With Admin</p>}
                        {text === 6 && <p className="textDarkBlue">BlockChain Pending</p>}
                        {text === 7 && <p className="textBrown">Placed For Resell</p>}
                        {text === 8 && <p className="textRed">ReSell Rejected</p>}
                        {text === 9 && <p className="textRed">Deleted</p>}
                        {text === 10 && <p className="textRed">Blocked</p>}
                        {text === 11 && <p className="textRed">Sold out externally</p>}
                        {text === 12 && <p className="textRed">Order Processing</p>}
                        {text === 13 && <p className="textRed">NFC Pending</p>}
                        {text === 14 && <p className="textRed">Artwork On Hold</p>}
                        {text === 15 && <p className="textRed">Pending With Gallery</p>}
                        {text === 16 && <p className="textRed">Reserved For Auction</p>}
                        {text === 17 && <p className="textRed">Auction Visible</p>}
                        {text === 18 && <p className="textRed">Auction Live</p>}
                        {text === 19 && <p className="textRed">PrototypeVersion</p>}
                    </div>
                );
            },
        },
        {
            title: "Previous Status",
            dataIndex: "previousStatus",
            key: "previousStatus",
            render: (text) => {
                return (
                    <div>
                        {text === 1 && <p className="textBlue">Available</p>}
                        {text === 2 && <p className="textBlue">Available Resell</p>}
                        {text === 3 && <p className="textGreen">Sold out</p>}
                        {text === 4 && <p className="textDarkBlue">Pending With Artist</p>}
                        {text === 5 && <p className="textDarkBlue">Pending With Admin</p>}
                        {text === 6 && <p className="textDarkBlue">BlockChain Pending</p>}
                        {text === 7 && <p className="textBrown">Placed For Resell</p>}
                        {text === 8 && <p className="textRed">ReSell Rejected</p>}
                        {text === 9 && <p className="textRed">Deleted</p>}
                        {text === 10 && <p className="textRed">Blocked</p>}
                        {text === 11 && <p className="textRed">Sold out externally</p>}
                        {text === 12 && <p className="textRed">Order Processing</p>}
                        {text === 13 && <p className="textRed">NFC Pending</p>}
                        {text === 14 && <p className="textRed">Artwork On Hold</p>}
                        {text === 15 && <p className="textRed">Pending With Gallery</p>}
                        {text === 16 && <p className="textRed">Reserved For Auction</p>}
                        {text === 17 && <p className="textRed">Auction Visible</p>}
                        {text === 18 && <p className="textRed">Auction Live</p>}
                        {text === 19 && <p className="textRed">PrototypeVersion</p>}
                    </div>
                );
            },
        },
        {
            title: "Redeem Status",
            dataIndex: "nftRedemption",
            key: "nftRedemption",
            render: (text) => {
                return (
                    <div>
                        {text === 0 && <p className="textBlue">No</p>}
                        {text === 1 && <p className="textDarkBlue">Redeemable</p>}
                        {text === 2 && <p className="textDarkBlue">Pending approval</p>}
                        {text === 3 && <p className="textDarkBlue">Redeemed</p>}
                    </div>
                );
            },
        },
        {
            title: "Sub Status",
            dataIndex: "productSubStatus",
            key: "productSubStatus",
            render: (text) => {
                return (
                    <div>
                        {text === 0 && <p className="textBlue">None</p>}
                        {text === 1 && <p className="textDarkBlue">BlockChain Pending</p>}
                        {text === 2 && <p className="textDarkBlue">Ready To Go Blockchain</p>}
                        {text === 3 && <p className="textDarkBlue">Added To Blockchain</p>}
                    </div>
                );
            },
        },
        {
            title: "Edition",
            dataIndex: "edition",
            key: "edition",
        },
        {
            title: "BlockChain Listed Date",
            dataIndex: "blockchainAdditionDate",
            key: "blockchainAdditionDate",
            render: (text, record) => <p className="">{text ? new Date(text).toLocaleDateString("en-IN", options) : "Nill"}</p>,

        },
        {
            title: "Action",
            key: "action",
            render: (record) =>
                record.status !== 9 && (
                    <Popover
                        placement="bottom"
                        content={content(record)}
                        overlayClassName="manage-artworks-action-popover"
                    >
                        <img className="action-image" src={require("../../assets/images/common/artworksActionIcon.svg")} alt="action" />
                    </Popover>
                ),
        },



    ];

    const content = (record) => (
        <div>
            {(record.status === 5 || record.status === 7 || record.status === 10 || record.status === 8) && (
                <span
                    className="action-span"
                    onClick={() => {
                        record.status === 5 || record.status === 10
                            ? toggleApproveConfirmationDialog(record, 1)
                            : toggleApproveConfirmationDialog(record, 2)
                    }
                    }
                >
                    {/* <img src={require("../../assets/images/common/Edit_New.svg")} alt="edit" /> */}
                    <Icon type="check" />
                    <p className="pop-item">Approve</p>
                </span>
            )}
            {(record.status === 1 && record.nftRedemption === 0) && (
                <span
                    className="action-span"
                    onClick={() => toggleNftRedeemableConfirmationDialog(record)}
                >
                    <Icon type="check" />
                    <p className="pop-item">Mark as redeemable</p>
                </span>
            )}
            {(record.nftRedemption === 2) && (
                <span
                    className="action-span"
                    onClick={() => toggleApproveNftRedeemConfirmationDialog(record)}
                >
                    <Icon type="check" />
                    <p className="pop-item">Approve Nft redeem</p>
                </span>
            )}
            {record.status !== 9 && record.status !== 11 && record.status !== 11 && record.status !== 3 && record.status !== 10 &&
                record.status !== 16 && record.status !== 17 && record.status !== 18 && record.status === 1 && (
                    <div className="action-span" onClick={() => toggleMarkAsSoldConfirmationDialog(record, 11)}>
                        <Icon type="check" />
                        <p className="pop-item">Mark As Sold</p>
                    </div>
                )}
            {record.status !== 9 && (
                <span className="action-span" onClick={() => toggleDeleteConfirmationDialog(record, 9)}>
                    <img src={require("../../assets/images/common/delete.svg")} alt="delete" />
                    <p className="pop-item">Delete</p>
                </span>
            )}
            {(record.status === 5 || record.status === 7 || record.status === 1 || record.status === 16) && (
                <span
                    className="action-span"
                    onClick={() => {
                        record.status === 7 ?
                            toggleRejectConfirmationDialog(record, 8) :
                            toggleRejectConfirmationDialog(record, 10)

                    }}
                >
                    {/* <img src={require("../../assets/images/common/Edit_New.svg")} alt="edit" /> */}
                    <Icon type="close" />
                    <p className="pop-item">Block</p>
                </span>
            )}

            {(record.status === 1 || record.status === 2 || record.status === 4 || record.status === 5 ||
                record.status === 7 || record.status === 8 || record.status === 13 || record.status === 15 || record.status === 16) && (
                    <span
                        className="action-span"
                        onClick={() =>
                            toggleHoldConfirmationDialog(record, 14)
                        }
                    >
                        <Icon type="check" />
                        <p className="pop-item">Hold</p>
                    </span>
                )}

            {
                record.status === 14 && (
                    <span className="action-span" onClick={() => toggleUnHoldConfirmationDialog(record)}>
                        <Icon type="wallet" />
                        <p className="pop-item">UnHold Artwork</p>
                    </span>
                )}
            {
                record.status === 1 && (
                    <span className="action-span" onClick={() => toggleReserveForAuctionConfirmationDialog(record, 16)}>
                        <Icon type="wallet" />
                        <p className="pop-item">Reserve For Auction</p>
                    </span>
                )
            }
            {
                record.productSubStatus === 1 && (
                    <span className="action-span" onClick={() => toggleMintTokenConfirmationDialog(record)}>
                        <Icon type="wallet" />
                        <p className="pop-item">Mint token</p>
                    </span>
                )
            }
        </div >
    );

    return (
        <>
            <Modal
                title="Artwork Editions"
                centered={true}
                visible={show}
                onCancel={onCloseCouponDrawer}
                footer={false}
            >
                <Table
                    rowKey={(record) => record.rfid}
                    columns={columns}
                    dataSource={editionList}
                    pagination={true}
                    showSorterTooltip

                />

            </Modal>

            {nfcUrl && isNfcOpen && (
                <Lightbox mainSrc={nfcUrl} onCloseRequest={onCloseNfcClick} />
            )}

            <ConfirmationMessageDialog
                open={openApproveConfirmPopup}
                handleClose={toggleApproveConfirmationDialog}
                confirmMessage={
                    "Choose 'Reserved for Auction' if this artwork is to list in the auction, else Choose 'Make Available' to make this artwork available for purchase."
                }
                onConfirm={onUpdateArtworkStatus}
                confirmButton={"Make Available"}
                additionalButton={"Reserve for Auction"}
                onClickAdditionButton={onUpdateReserveForAuctionStatus}
                loader={markAsSoldApiLoading}
            />

            <ConfirmationMessageDialog
                open={openMarsSoldPopup}
                handleClose={toggleMarkAsSoldConfirmationDialog}
                confirmMessage={"Are you sure you want to mark as sold this artwork?"}
                onConfirm={onUpdateArtworkStatus}
                loader={markAsSoldApiLoading}
            />

            <ConfirmationMessageDialog
                open={openHoldConfirmPopup}
                handleClose={toggleHoldConfirmationDialog}
                confirmMessage={"Are you sure you want to Hold this artwork?"}
                onConfirm={onUpdateArtworkStatus}
                loader={markAsSoldApiLoading}
            />

            <ConfirmationMessageDialog
                open={openUnHoldConfirmPopup}
                handleClose={toggleUnHoldConfirmationDialog}
                confirmMessage={"Are you sure you want to UnHold this artwork?"}
                onConfirm={onUnHoldArtwork}
            />

            <ConfirmationMessageDialog
                open={openReserveForAuctionConfirmPopup}
                handleClose={toggleMarkAsSoldConfirmationDialog}
                confirmMessage={"Are you sure you want to make this artwork Reserve For Auction?"}
                onConfirm={onUpdateArtworkStatus}
                loader={markAsSoldApiLoading}
            />

            <ConfirmationMessageDialog
                open={deleteconfirmationDialog}
                handleClose={toggleDeleteConfirmationDialog}
                confirmMessage={"Are you sure you want to delete this artwork?"}
                onConfirm={onUpdateArtworkStatus}
                loader={markAsSoldApiLoading}
            />

            <ConfirmationMessageDialog
                open={rejectconfirmationDialog}
                handleClose={toggleRejectConfirmationDialog}
                confirmMessage={"Are you sure you want to block this artwork?"}
                onConfirm={onUpdateArtworkStatus}
                loader={markAsSoldApiLoading}
            />

            <ConfirmationMessageDialog
                open={mintTokenConfirmationDialog}
                handleClose={toggleMintTokenConfirmationDialog}
                confirmMessage={"Are you sure you want to push to block chain?"}
                onConfirm={onMintToken}
                loader={mintTokenApiLoading}
            />

            <ConfirmationMessageDialog
                open={openNftRedeemableConfirmPopup}
                handleClose={toggleNftRedeemableConfirmationDialog}
                confirmMessage={"Are you sure you want to redeem this artwork?"}
                onConfirm={onMarkNftRedeemable}
                loader={mintTokenApiLoading}
            />

            <ConfirmationMessageDialog
                open={openApproveNftRedeemConfirmPopup}
                handleClose={toggleApproveNftRedeemConfirmationDialog}
                confirmMessage={"Are you sure you want to approve nft redeem ?"}
                onConfirm={onApproveNftRedeem}
                loader={mintTokenApiLoading}
            />
        </>
    )
}

export default ViewEditions;