import { APIUrlConstants } from "../../util/apiUrl.constant";
import { APIService } from "../../util/api.service";
import { Util } from "../../util/util";

export class AddBrandsService {

    // List all category
    static getCategory(parentId) {
        let url = APIUrlConstants.getApiUrl("getCategory");
        url = parentId ?
            url + '?parentId=' + parentId :
            url + '?parentId=5d661043af0cc435f44861a5';
        return APIService.doGet(url)
            .then((resp) => {
                if (resp) {
                    return resp;
                }
                throw resp;
            });
    }

    // Get the brands list
    static getBrands(categoryId = "", status = "", priority = "", searchValue = "") {
        let url = APIUrlConstants.getApiUrl("getBrands");
        url = Util.beautifyUrl(url, [categoryId, status, priority, searchValue]);
        return APIService.doGet(url)
            .then((resp) => {
                if (resp) {
                    return resp;
                }
                throw resp;
            });
    }

    // Update/Create brand
    static createBrand(request, isUpdating, id = '') {
        if (isUpdating) {
            let url = APIUrlConstants.getApiUrl("updateBrand");
            url = url + id;
            return APIService.doPutMultipart(url, request)
                .then((resp) => {
                    if (resp) {
                        return resp;
                    }
                    throw resp;
                });
        }
        else {
            let url = APIUrlConstants.getApiUrl("createBrand");
            return APIService.doUploadMultipart(url, request)
                .then((resp) => {
                    if (resp) {
                        return resp;
                    }
                    throw resp;
                });
        }
    }

    // Delete Brand
    static deleteBrand(id) {
        let url = APIUrlConstants.getApiUrl("deleteBrand");
        url = url + id;
        return APIService.doDelete(url)
            .then((resp) => {
                if (resp) {
                    return resp;
                }
                throw resp;
            });
    }

    // Get Category Details except subcategory data
    static getCategoryDetails(id) {
        let url = APIUrlConstants.getApiUrl("getCategoryDetails");
        url = url + id;
        return APIService.doGet(url)
            .then((resp) => {
                if (resp) {
                    return resp;
                }
                throw resp;
            });
    }

    // get Brand
    static getProductBrand(categoryId) {
        const url = APIUrlConstants.getApiUrl("getProductBrand") + '?categoryId=' + categoryId;
        return APIService.doGet(url)
            .then(response => {
                if (response) {
                    return response;
                }
                throw response;
            });
    }
}
