import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { BlogService } from "../../Blog.service";
import { APIService } from "../../../../../util/api.service";
import { Util } from "../../../../../util/util";
import { APIUrlConstants } from "../../../../../util/apiUrl.constant";
import htmlToDraft from "html-to-draftjs";
import { popToast } from "../../../../../components/Toast/Toast";

const useAddBlogArticles = (getBlogs, editRecord, onClickCancelAddArticle, pageIndex, pageSize) => {
    const obj = {
        title: "",
        topic: "",
        topicSlugId: "",
        blogDate: "",
        bloggerName: "",
        isFeatured: false,
        media: "",
        thumbnail: "",
        editorState: EditorState.createEmpty(),
        seoDescription: "",
        slugId: "",
        _id: ""
    };

    const [articleState, setArticleState] = useState({ ...obj });

    useEffect(() => {
        getTopics();
        if (editRecord) {
            setArticleState({
                ...editRecord, editorState: EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(editRecord.body)))
            });
        }
    }, [])

    const [topicValues, setTopicValues] = useState([]);
    const [addArticleLoader, setAddArticleLoader] = useState(false);
    const [authorSearchData, setAuthorSearchData] = useState([]);
    const [fetchingAuthorSearch, setFetchAuthorSearch] = useState(false);


    const onHandleChangeInput = (ev) => {
        const { name, value } = ev.target;
        setArticleState(articleState => ({ ...articleState, [name]: value }));
        if (name === "title") {
            setArticleState(articleState => ({ ...articleState, slugId: convertToSlug(value) }));
        }

    }

    const onHandleAuthorChange = (value) => {
        setArticleState(articleState => ({ ...articleState, bloggerName: value }));
    }

    const convertToSlug = (Text) => {
        return Text.toLowerCase()
            .replace(/[^\w ]+/g, "")
            .replace(/ +/g, "-");
    };

    const getTopics = async () => {
        let topicResult = await BlogService.getBlogTopics(0, 0);
        if (topicResult && topicResult.data) {
            setTopicValues([...topicResult.data.topicList]);
        }
    }

    const onImageSelect = (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        let image = "";
        if (file) {
            reader.onloadend = () => {
                setArticleState(articleState => ({
                    ...articleState,
                    media: file,
                    thumbnail: reader.result
                }));

                image = reader.result;
            };
            reader.readAsDataURL(file);
        }
    }

    const fetchUser = async (value) => {
        try {
            setFetchAuthorSearch(true);
            let topicResult = await BlogService.searchAuthorName(value);
            if (topicResult && topicResult.data) {
                setAuthorSearchData(topicResult.data);
            }
            setFetchAuthorSearch(false);
        } catch (error) {
            setFetchAuthorSearch(false);
        }
    }

    const uploadImageCallBack = (file) => {
        return new Promise(async (resolve, reject) => {
            let request = {
                Image: file,
                AlternateText: "blogImage",
            };
            let url = APIUrlConstants.getApiUrl("createImageGallery");
            let formBodyRequest = Util.objectToFormData(request);
            let response = await APIService.doUploadMultipart(url, formBodyRequest).then((resp) => {
                if (resp && resp.result === 200 && resp.message === "Success") {
                    return resp;
                }
                throw resp;
            });
            if (response && response.data && response.data.imageUC) {
                resolve({ data: { link: response.data.imageUC } });
            } else {
                console.log(response);
                reject(response);
            }
        });
    }

    const AddArticle = async () => {
        try {

            if (isValidFormField()) {
                if (articleState._id) {
                    setAddArticleLoader(true);
                    let updateBannerResp = await BlogService.updateBlog(articleState._id, {
                        Title: articleState.title,
                        SlugId: articleState.slugId,
                        TopicSlugId: articleState.topicSlugId,
                        SeoDescription: articleState.seoDescription,
                        Body: draftToHtml(convertToRaw(articleState.editorState.getCurrentContent())),
                        BlogImages: articleState.media,
                        Id: articleState._id,
                        BloggerName: articleState.bloggerName,
                        BlogDate: articleState.blogDate,
                        IsFeatured: articleState.isFeatured,


                    });
                    if (updateBannerResp && updateBannerResp.result === 200) {
                        let banners = await getBlogs(pageIndex, pageSize);
                        onCancelArticle();
                    }
                    else if (updateBannerResp && updateBannerResp.result === 400) {
                        popToast.show(updateBannerResp.message);
                    }
                }
                else {
                    if (isNullOrEmpty(articleState.media)) {
                        popToast.show("Please upload a Blog Image");
                        return false;
                    }
                    else {
                        setAddArticleLoader(true);
                        let addArticleResp = await BlogService.addBlog({
                            Title: articleState.title,
                            SlugId: articleState.slugId,
                            TopicSlugId: articleState.topicSlugId,
                            SeoDescription: articleState.seoDescription,
                            Body: draftToHtml(convertToRaw(articleState.editorState.getCurrentContent())),
                            BlogImages: articleState.media,
                            BloggerName: articleState.bloggerName,
                        });
                        if (addArticleResp && addArticleResp.result === 200) {
                            let banners = await getBlogs(pageIndex, pageSize);
                            onCancelArticle();
                        }
                        else if (addArticleResp && addArticleResp.result === 400) {
                            popToast.show(addArticleResp.message);
                        } else if (addArticleResp && addArticleResp.result === 500) {
                            popToast.show(addArticleResp.message);
                        }
                    }
                }

                setAddArticleLoader(false);
            }
        } catch (error) {
            setAddArticleLoader(false);
        }
    }

    const isValidFormField = () => {
        if (isNullOrEmpty(articleState.title)) {
            popToast.show("Please enter a valid Title");
            return false;
        } else if (isNullOrEmpty(articleState.bloggerName)) {
            popToast.show("Please enter a valid Author name");
            return false;
        } else if (isNullOrEmpty(articleState.topicSlugId)) {
            popToast.show("Please select Topic");
            return false;
        } else if (isNullOrEmpty(articleState.slugId)) {
            popToast.show("Please enter a valid slugId");
            return false;
        }
        return true;
    }

    const isNullOrEmpty = (obj) => {
        if (obj === "" || obj === undefined || obj === null) {
            return true;
        }
        return false;
    };

    const onEditorStateChange = () => (editorState) => {
        setArticleState(articleState => ({
            ...articleState,
            editorState
        }));
    };

    const onCancelArticle = () => {
        onClickCancelAddArticle();
        setArticleState({ ...obj });
    }

    return {
        articleState,
        topicValues,
        addArticleLoader,
        authorSearchData,
        fetchingAuthorSearch,

        fetchUser,
        onHandleChangeInput,
        onImageSelect,
        AddArticle,
        uploadImageCallBack,
        onEditorStateChange,
        onHandleAuthorChange,
        onCancelArticle,
    }
}

export default useAddBlogArticles;