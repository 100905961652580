import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import MediaPublicationModal from "./MediaPublicationModal";
import { Popover } from "antd";
import assets from "../../../../../assets";

import ConfirmPopup from '../../../../../components/ConfirmPopup/ConfirmPopup'
import { useHistory } from "react-router-dom";
import { ActionImg, ArtistListGalleryViewTable, ColImage, ColSubText, ColWrapper, MainSubmitButton } from "../../ArtistProfile.styled";
import { authorizedGet, deleteAPi } from "../../../../../util/apiConfig";
import { popToast } from "../../../../../components/Toast/Toast";

export const MediaPublication = ({ onTabChange, artistId }) => {
	const history = useHistory();

	const [isModalVisible, setIsModalVisible] = useState(false);
	const [actionType, setActionType] = useState("add");
	const [editData, setEditData] = useState();
	const [mediaPublicationData, setMediaPublicationData] = useState([]);
	const [apiLoading, setApiLoading] = useState(false);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (artistId) {
			onLoad();
		}
	}, [artistId])


	const onLoad = () => {
		setApiLoading(true);
		authorizedGet(`/UserManagment/api/Artists/GetArtistPublications?UserId=${artistId}`).then((responseJson) => {
			if (responseJson.resultCode === 1) {
				setMediaPublicationData(responseJson.response.data);
				setApiLoading(false);
			}
		});
	}

	const onToggleModel = () => {
		setIsModalVisible(!isModalVisible);
	};
	const handleOk = () => {
		setIsModalVisible(false);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};
	const onClickAction = (btn) => {
		if (btn === "next") {
			onTabChange("additional");
		} else {
			// history.push(RouteConstants.ArtistDashboard);
		}
	};
	const onClickDeleteButton = (data) => {
		let token = localStorage.getItem('authToken');
		deleteAPi(`UserManagment/api/Artists/DeletePublications?PublicationId=${data._id}`, token).then((responseJson) => {
			if (responseJson.resultCode === 1) {
				popToast.show("Deleted Successfully");
				onLoad();
			} else {
				popToast.show("Failed to delete");
			}
		});
		console.log("🚀 ~ file: Exhibitions.js ~ line 46 ~ onClickDeleteButton ~ data", data);
	};
	const onClickEditButton = (data) => {
		setActionType("edit");
		setEditData(data);
		onToggleModel();
		console.log("🚀 ~ file: Exhibitions.js ~ line 50 ~ onClickEditButton ~ data", data);
	};
	const columns = [
		{
			title: "Image",
			dataIndex: "image",
			key: "image",
			render: (text, record) => (
				<ColWrapper>
					<ColImage
						shouldAddExtraWrap
						extraWrapClassName="artworks-listing-empty-image"
						src={record.imageURL != null ? record.imageURL : assets.images.artworkNoImage}
						fallback={record.imageURL != null ? record.imageURL : assets.images.artworkNoImage}
						alt="Image"
					/>
				</ColWrapper>
			),
		},
		{
			title: "Year",
			dataIndex: "year",
			key: "year",
		},
		{
			title: "Type",
			dataIndex: "type",
			key: "type",
		},
		{
			title: "Title",
			dataIndex: "title",
			key: "title",
		},

		{
			title: "Author",
			dataIndex: "author",
			key: "author",
		},
		{
			title: "Published By",
			dataIndex: "publishedBy",
			key: "publishedBy",
		},
		{
			title: "Link to article /Pages",
			dataIndex: "reference",
			key: "reference",
		},

		{
			title: "Action",
			key: "action",
			render: (record) => (
				<React.Fragment>
					<Popover placement="bottom" content={setContent(record)}>
						<ActionImg src={assets.images.artworksActionIcon} alt="action" />
					</Popover>
				</React.Fragment>
			),
		},
	];
	const setContent = (record) => {
		return (
			<div>
				<ColSubText
					onClick={() => {
						onClickEditButton(record);
					}}
					textcolor={"#000000"}
					style={{ cursor: "pointer" }}
				>
					Edit
				</ColSubText>
				<ConfirmPopup
					text={"Confirm Deletion"}
					image={assets.images.deleteIcon}
					buttonText={"Delete"}
					confirmAction={() => onClickDeleteButton(record)}
				>
					<ColSubText textcolor={"#000000"} style={{ cursor: "pointer" }}>
						Delete
					</ColSubText>
				</ConfirmPopup>
			</div>
		);
	};
	return (
		<>
			<div className="table-top-wrapper">
				<MainSubmitButton
					onClick={() => {
						setActionType("add");
						onToggleModel();
					}}
				>
					Add Media and Publications
				</MainSubmitButton>
			</div>
			<ArtistListGalleryViewTable loading={apiLoading} rowKey={(record) => record.artistId} columns={columns} dataSource={mediaPublicationData} pagination={false} />

			<Modal
				title={`${actionType === "edit" ? "Edit" : "Add New"} Media and Publications`}
				footer={null}
				visible={isModalVisible}
				onOk={handleOk}
				onCancel={handleCancel}
				destroyOnClose={true}
			>
				<MediaPublicationModal
					artistId={artistId}
					onLoad={onLoad}
					type={actionType}
					data={editData}
					onToggleModel={onToggleModel}
				/>
			</Modal>
		</>
	);
};

export default MediaPublication;
