/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import ReactHtmlParser from 'react-html-parser';
import './SafeHTMLRender.scss'

const SafeHtmlRenderer = ({ body }) => {
	return (
		<div className="framewrapper">{ReactHtmlParser(body)}</div>
	);
};

export default SafeHtmlRenderer;
