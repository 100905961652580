import styled, { css } from "styled-components";
import { Table, Select } from "antd";
import Image from "../../../components/Image/Image";

const TabContentContainer = styled.div`
	justify-content: center;
	display: flex;
	flex-direction: column;
`;

const AdditionalInforWrapper = styled.div` 
	margin-bottom: 4rem ;
`

const TitleHeading = styled.h1`
	text-align: left;
	font: normal normal bold 16px/26px !important;
	font-size: 16px !important;
	font-weight: 600 !important;
	letter-spacing: 0.4px;
	opacity: 1;
	a {
		text-align: left;
		font: normal normal bold 16px/26px !important;
		letter-spacing: 0.4px;
		color: #0090ff;
	}
`;

const SocialMediaItem = styled.div`
	display: flex;
	margin-top: 10px;
	margin-bottom: 10px;
	align-items: center;
	div {
		margin-right: 10px;
	}
	img {
		object-fit: fill;
		width: 25px;

		height: 25px;
	}
	input {
		width: 100%;
		height: 45px;
		background: #ffffff 0% 0% no-repeat padding-box;
		border: 1px solid #e5e5e5;
		opacity: 1;
		padding-left: 10px;
	}
	span {
		text-align: left;
		font: normal normal normal 14px/35px;
		letter-spacing: 0.35px;
		color: #616161;
		opacity: 1;
		margin-right: 35px;
		width: 100px;
	}
`;

const MainSubmitButton = styled.button`
	border: 1px solid black;
	background: black 0% 0% no-repeat;
	border: 1px solid black;
	opacity: 1;
	width: auto;
	padding: 8px 18px;
	margin-left: 5px;
	/* margin-right: 5px; */
	height: 45px;
	text-align: center;
	font-weight: normal !important;
	font: normal normal normal 18px/25px;
	letter-spacing: 0.45px;
	color: #ffffff;
	@media screen and (max-width: 786px) {
		width: 170px;
		font-size: 14px;
	}
`;
const MainPlusButton = styled.button`
	border: 1px solid black;
	background: black 0% 0% no-repeat;
	border: 1px solid black;
	opacity: 1;
	width: 45px;
	height: 45px;
	text-align: center;
	font-weight: normal !important;
	font: normal normal normal 18px/25px;
	letter-spacing: 0.45px;
	color: #ffffff;
`;

const MainDeleteButton = styled.button`
	border: 1px solid black;
	background: black 0% 0% no-repeat;
	border: 1px solid black;
	opacity: 1;
	width: 45px;
	height: 45px;
	text-align: center;
	font-weight: normal !important;
	font: normal normal normal 18px/25px;
	letter-spacing: 0.45px;
	color: #ffffff;
`;
const YesNoButton = styled.button`
	border: 1px solid black;
	border: 1px solid black;
	opacity: 1;
	width: 90px;
	height: 40px;
	text-align: center;
	font-weight: normal !important;
	font: normal normal normal 18px/25px;
	letter-spacing: 0.45px;
	margin-left: 15px;
	margin-right: 15px;
	color: ${(props) => (props.sel === "active" ? "white" : "black")};
	background-color: ${(props) => (props.sel === "active" ? "black" : "#FFFFFF")};
`;
const ArtistListGalleryViewTable = styled(Table)`
	&.ant-table-wrapper {
		width: 100%;
	}
	.ant-table {
		color: #000000;
		font-weight: 500;
	}
	.ant-table-thead > tr > th {
		padding: 10px 10px;
		background-color: #f5f5f5;
		color: #000000;
	}
	.ant-table-tbody > tr > td {
		padding: 10px 10px;
		box-shadow: none !important;
	}
	.ant-table-tbody > tr:last-of-type td {
		border: none;
	}
	.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
		background: #f5f5f5;
		box-shadow: 0px 5px 0px 0px #f5f5f5;
	}
`;

const SelectInput = styled(Select)`
	&& {
		.ant-select-selection {
			border: none;
			color: #58514b;
		}
		.ant-select-selection__placeholder {
			display: block !important;
			font-weight: normal !important;
			font-family: "Lato" !important;
			line-height: 28px !important;
			color: #858585;
		}

		.ant-select-selection--single .ant-select-selection__rendered {
			/* margin-right: 2.5px;
			margin-left: 2.5px; */
		}
		.ant-select-selection:focus,
		.ant-select-selection:active {
			/* border-color: #d9d9d9; */
			/* box-shadow: 0 0 0 2px #d9d9d9; */
			border: none !important;
			box-shadow: none;
		}
		.ant-select-selection:hover {
			border: none !important;
			box-shadow: none;
			/* border-color: #d9d9d9; */
			/* box-shadow: 0 0 0 2px #d9d9d9; */
		}
		.ant-select-arrow {
			right: 4.5px;
		}
		.ant-select-selection-selected-value {
			/* margin-right: 1rem; */
		}
		.ant-select-dropdown-menu-item {
			text-align: left !important;
		}

		.ant-select-selector {
			border: none;
			color: #58514b;
		}
		font-size: 15px;
		font-weight: 500;
		color: #58514b;
		position: relative;
		/* margin: 2.5px 10px 2.5px; */
		border: 1px solid #e5e5e5;
		background: #ffffff 0% 0% no-repeat padding-box;
		/* border:antiquewhite solid 1px; */
		outline: none;
		&:focus,
		&:active {
			/* border-color: 0px solid rgb(0, 156, 38); */
			/* -webkit-box-shadow: 0 0 0 2px rgba(255, 0, 0, 0); */
			/* box-shadow: 0 0 0 2px rgba(255, 0, 0, 0); */
			/* outline: 0; */
		}
	}
`;

const AddNewEventImage = styled.img`
	${props =>
		props.notPlaceholder
			? css`
					width: 100%;
					height: 100%;
			  `
			: css`
					width: 50px;
					height: 50px;
			  `};
`;

const SpanFloater = styled.span`
	position: absolute;
	top: 8px;
	left: 9px;
	height: 16px;
	font-size: 13px;
	text-align: left;
	padding: 0px 7px;
	-webkit-transition: 0.3s;
	-webkit-transition: 0.3s;
	transition: 0.3s;
	pointer-events: none;
	background-color: #fff;
	color: #878787;
	letter-spacing: 0.35px;
	line-height: 1;
	opacity: 1;
	z-index: 1;
`;

export const AddNewProfileEventImageWrap = styled.div`
	cursor: pointer;
	padding: 0px;
	background: #ffffff;
	border: 1px solid #cdcdcd;
	border-radius: 5px;
	height: 100px;
	width: 100px;
	margin-bottom: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const ActionImg = styled.img`
	height: 25px;
	width: 25px;
	object-fit: contain;
	${(props) =>
		props.actionEnabled === true
			? css`
					&:hover {
						cursor: pointer;
					}
			  `
			: css`
					&:hover {
						cursor: not-allowed;
					}
			  `}
`;

const ColWrapper = styled.span`
	display: flex;
`;

const ColDiv = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-left: 20px;
`;

const ColImage = styled(Image)`
	height: 60px;
	width: 60px;
	object-fit: contain;
	cursor: pointer;
`;

const ColText = styled.p`
	color: #000000;
	margin: 0;
	font-size: 14px;
	font-weight: 600;
	cursor: pointer;
	padding-bottom: ${(props) => {
		return props.paddingbottom;
	}};
	padding-top: ${(props) => {
		return props.paddingtop;
	}};
`;

const ColSubText = styled.span`
	/* Earlier it was styled(Link) */
	display: block;
	margin: 0;
	font-size: 14px;
	font-weight: 500;
	color: ${(props) => {
		return props.textcolor;
	}};
`;

const ColGreyText = styled.p`
	color: #727272;
	margin: 0;
	font-size: 12px;
	padding-bottom: ${(props) => {
		return props.paddingbottom;
	}};
`;

export {
	TabContentContainer,
	TitleHeading,
	SocialMediaItem,
	MainSubmitButton,
	ArtistListGalleryViewTable,
	MainPlusButton,
	SelectInput,
	YesNoButton,
	MainDeleteButton,
	AddNewEventImage,
	SpanFloater,
	AdditionalInforWrapper,
	ActionImg,
	ColDiv,
	ColGreyText,
	ColImage,
	ColSubText,
	ColText,
	ColWrapper,

};
