import { useEffect, useRef, useState } from "react";
import { popToast } from "../../../components/Toast/Toast";
import history from "../../../util/history";
import { AuctionService } from "../AuctionService";

const useAuctionSummary = (match) => {
    const [auctionObject, setAuctionObj] = useState({
        name: "",
        description: "",
        startDate: "",
        endDate: "",
        timeGap: "",
        seoTitle: "",
        seoDescription: "",
        keywords: "",
        slugId: "",
        artworksType: 1,
        status: 0,
    });



    const [auctionLotObjects, setAuctionLotObjects] = useState([]);

    const [updateAuctionLoader, setUpdateAuctionLoader] = useState(false);
    const [showSaveButton, setShowSaveButton] = useState([]);


    useEffect(() => {

        const auctionId = match.params.auctionId;
        getAuctionDetails(auctionId);
    }, []);

    const getAuctionDetails = (auctionId) => {
        AuctionService.getAuctionById(auctionId).then(resp => {
            if (resp && resp.result === 200) {
                setAuctionObj(resp.data);

            } else if (resp && resp.result === 400) {
                popToast.show(resp.message);
            }
        })
    }

    const onClickAddAuctionSlot = () => {
        let rank = auctionLotObjects.length + 1;
        history.push(`/auctions/lot/${auctionObject._id}/${rank}/${auctionObject.artworksType}`)
    }

    const onClickEditAuctionLot = (lot) => {
        history.push(`/auctions/edit-lot/${lot._id}/${auctionObject.startDate}/${auctionObject.endDate}`)
    }

    const onClickEditAuctionDetails = () => {
        history.push(`/auctions/details/${auctionObject._id}`)
    }

    const onClickCancel = () => {
        history.push(`/auctions`)
    }

    const onClickLaunchAuction = (status) => {
        if (auctionObject.isDeclared === true) {
            let auc = { ...auctionObject };
            auc.status = status;
            setUpdateAuctionLoader(true);
            AuctionService.updateAuction(auc._id, auc).then(resp => {
                if (resp && resp.result === 200) {
                    getAuctionDetails(auctionObject._id);

                } else if (resp && resp.result === 400) {
                    popToast.show(resp.message);
                }
                setUpdateAuctionLoader(false);
            }).catch(error => {
                setUpdateAuctionLoader(false);
            })
        } else {
            popToast.show("Auction Date is not published");
        }

    }

    const onChangeLotData = (event, index) => {
        const { name, value } = event.target;
        let xmm = showSaveButton;
        xmm[index] = true;
        setShowSaveButton(xmm)
        let aucObjects = [...auctionLotObjects];
        aucObjects[index] = { ...aucObjects[index], [name]: value };
        setAuctionLotObjects(aucObjects);
    };

    return {
        auctionObject,
        auctionLotObjects,
        updateAuctionLoader,
        showSaveButton,

        onClickAddAuctionSlot,
        onClickEditAuctionLot,
        onClickEditAuctionDetails,
        onClickCancel,
        onClickLaunchAuction,
        onChangeLotData
    }
}

export default useAuctionSummary;