import { APIService } from "../../util/api.service";
import { APIUrlConstants } from "../../util/apiUrl.constant";

export class AbandonedCartService {
	static async getAbandonedCartObjects(pageNum = 1, pageSize = 12) {
		let url = `${APIUrlConstants.getApiUrl("abandonedCarts")}?pageNum=${pageNum}&pageSize=100000`;
		return APIService.doGet(url).then((resp) => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	static async exportAbandonedCarts() {
		let url = APIUrlConstants.getApiUrl("downLoadAbandonedCarts");
		return APIService.doDownload(url).then((resp) => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}
}
