import * as types from "./ActionsTypes";
import { reverse } from "lodash";
import { popToast } from "../../components/Toast/Toast";
import { FilterRuleService } from "../../containers/FilterRules/FilterRules.service"
import { LookupService } from "../../containers/Lookup/Lookup.service"


// isNewListing value only passed when expanding a category to view its subcategories
export const getAllFilterRules = (searchKey) => {
    return (dispatch, getState) => {
        dispatch(request());
        FilterRuleService.getAllFilterRules(searchKey).then(
            response => {
                if (response && response.result === 200) {
                    dispatch(success(response.data));
                } else {
                    dispatch(failure(response));

                    let errorMessage = "Something went wrong while getting categories!";
                    if (response && response.message) {
                        switch (response.message) {
                            default:
                                errorMessage = "Something went wrong while getting categories! " + response.message;
                                break;
                        }
                    }
                    popToast.show(errorMessage);
                }
            },
            error => {
                if (error && error.status_message) {
                    popToast.show(error.status_message);
                } else {
                    popToast.show("Something went wrong while getting categories!");
                }
                console.log("error dispatched for API error!", error);
                dispatch(failure(error));
            }
        )
    }

    function request() {
        return { type: types.GET_ALL_FILTER_RULE };
    }
    function success(response) {
        console.log("🚀 ~ file: FilterRuleAction.js ~ line 46 ~ success ~ response", response)
        return { type: types.GET_ALL_FILTER_RULE_SUCCESS, response };
    }
    function failure(error) {
        return { type: types.GET_ALL_FILTER_RULE_FAILURE, error };
    }
}
export const getAllFilterRulesById = (id) => {
    return (dispatch, getState) => {
        dispatch(request());
        FilterRuleService.getFilterRuleById(id).then(
            response => {
                if (response && response.result === 200) {
                    dispatch(success(response.data));
                } else {
                    dispatch(failure(response));

                    let errorMessage = "Something went wrong while getting categories!";
                    if (response && response.message) {
                        switch (response.message) {
                            default:
                                errorMessage = "Something went wrong while getting categories! " + response.message;
                                break;
                        }
                    }
                    popToast.show(errorMessage);
                }
            },
            error => {
                if (error && error.status_message) {
                    popToast.show(error.status_message);
                } else {
                    popToast.show("Something went wrong while getting categories!");
                }
                console.log("error dispatched for API error!", error);
                dispatch(failure(error));
            }
        )
    }

    function request() {
        return { type: types.GET_FILTER_RULE_BY_ID };
    }
    function success(response) {
        return { type: types.GET_FILTER_RULE_BY_ID_SUCCESS, response };
    }
    function failure(error) {
        return { type: types.GET_FILTER_RULE_BY_ID_FAILURE, error };
    }
}
export const insertFilterRule = (body) => {
    return dispatch => {
        FilterRuleService.createFilterRule(body).then(
            response => {
                if (response && response.result === 200) {
                    // Success : Close add category modal
                    dispatch(success(response.data));
                    popToast.show('Rule created successfully!');
                } else {
                    dispatch(failure(response));

                    let errorMessage = "Something went wrong !";
                    if (response && response.message) {
                        switch (response.message) {
                            default:
                                errorMessage = response.message;
                                break;
                        }
                    }
                    popToast.show(errorMessage);
                }
            },
            error => {
                if (error && error.status_message) {
                    popToast.show(error.status_message);
                } else {
                    popToast.show("Something went wrong while creating category!");
                }
                console.log("error dispatched for API error!", error);
                dispatch(failure(error));
            }

        )
    }

    function request() {
        return { type: types.CREATE_FILTER_RULE_REQUEST };
    }
    function success(data) {
        return { type: types.CREATE_FILTER_RULE_SUCCESS, data };
    }
    function failure(error) {
        return { type: types.CREATE_FILTER_RULE_FAILURE, error };
    }
}


export const updateFilterRule = (body, id) => {
    return dispatch => {
        FilterRuleService.updateFilterRule(body, id).then(
            response => {
                if (response && response.result === 200) {
                    // Success : Close add category modal
                    dispatch(success(response.data));
                    popToast.show("Successfully updated rule details.");
                } else {
                    dispatch(failure(response));

                    let errorMessage = "Something went wrong !";
                    if (response && response.message) {
                        switch (response.message) {
                            default:
                                errorMessage = response.message;
                                break;
                        }
                    }
                    popToast.show(errorMessage);
                }
            },
            error => {
                if (error && error.status_message) {
                    popToast.show(error.status_message);
                } else {
                    popToast.show("Something went wrong while creating category!");
                }
                console.log("error dispatched for API error!", error);
                dispatch(failure(error));
            }

        )
    }

    function request() {
        return { type: types.UPDATE_FILTER_RULE_REQUEST };
    }
    function success(data) {
        return { type: types.UPDATE_FILTER_RULE_SUCCESS, data };
    }
    function failure(error) {
        return { type: types.UPDATE_FILTER_RULE_FAILURE, error };
    }
}

export const updatePropertyValues = (propertyType, body) => {
    return dispatch => {
        LookupService.updateProperty(body, propertyType).then(
            response => {
                if (response && response.result === 200) {
                    // Success : Close add category modal
                    dispatch(success(response.data));

                    // Refresh the current list

                    popToast.show("Data updated successfully!");
                } else {
                    dispatch(failure(response));

                    let errorMessage = "Something went wrong !";
                    if (response && response.message) {
                        switch (response.message) {
                            default:
                                errorMessage = response.message;
                                break;
                        }
                    }
                    popToast.show(errorMessage);
                }
            },
            error => {
                if (error && error.status_message) {
                    popToast.show(error.status_message);
                } else {
                    popToast.show("Something went wrong while creating category!");
                }
                console.log("error dispatched for API error!", error);
                dispatch(failure(error));
            }

        )
    }

    function request() {
        return { type: types.UPDATE_LOOKUP_REQUEST };
    }
    function success(data) {
        return { type: types.UPDATE_LOOKUP_SUCCESS, data };
    }
    function failure(error) {
        return { type: types.UPDATE_LOOKUP_FAILURE, error };
    }
}


export const deleteFilterRule = (id) => {
    return dispatch => {
        FilterRuleService.deleteFilterRule(id).then(
            response => {
                if (response && response.result === 200) {
                    // Success : Close add category modal
                    dispatch(success(response.data));
                    dispatch(getAllFilterRules())
                    // Refresh the current list

                    popToast.show("Data deleted successfully!");
                } else {
                    dispatch(failure(response));

                    let errorMessage = "Something went wrong !";
                    if (response && response.message) {
                        switch (response.message) {
                            default:
                                errorMessage = response.message;
                                break;
                        }
                    }
                    popToast.show(errorMessage);
                }
            },
            error => {
                if (error && error.status_message) {
                    popToast.show(error.status_message);
                } else {
                    popToast.show("Something went wrong while creating category!");
                }
                console.log("error dispatched for API error!", error);
                dispatch(failure(error));
            }

        )
    }

    function request() {
        return { type: types.DELETE_FILTER_RULE_REQUEST };
    }
    function success(data) {
        return { type: types.DELETE_FILTER_RULE_SUCCESS, data };
    }
    function failure(error) {
        return { type: types.DELETE_FILTER_RULE_FAILURE, error };
    }
}
