import React, { Component } from "react";
import { AppRoutes } from "./util/route.js";
//For Localization
import { IntlProvider } from "react-intl";
import { addLocaleData } from "react-intl";
import locale_en from "react-intl/locale-data/en";
import locale_de from "react-intl/locale-data/de";
import messages_de from "./i18n/de/de.json";
import messages_en from "./i18n/en/en.json";
// Redux
import { connect } from "react-redux";
// Common SCSS
import "./Styles.scss";
import "antd/dist/antd.css";
import "react-image-lightbox/style.css";
import { ThemeProvider } from "styled-components";
import { BaseTheme } from "./util/theme.js";

// load the locale data for languages we want to supportt
// The locale data is added by calling addLocaleData()
//Sumith Test
addLocaleData([...locale_en, ...locale_de]);
const messages = {
	de: messages_de,
	en: messages_en,
};

class App extends Component {
	constructor(props) {
		super(props);

		// 'en' is loaded by default from actionLocale.js
		this.state = {
			selectedLanguage: this.props.languageStore.language,
		};
	}

	componentWillReceiveProps(newProps) {
		// This lifecycle hook is called whenever the redux state change is detected
		// newProps value contain the new state value from redux - which is assigned our state in component
		// So when state change happens in this component - The whole component is re-rendered and New language values gets loaded.
		this.setState({ selectedLanguage: newProps.languageStore.language });
	}

	render() {
		return (
			// To make the internationalization functions visible in all our child components
			// we have to wrap the AppRoutes component with <IntlProvider>
			<IntlProvider locale={this.state.selectedLanguage} messages={messages[this.state.selectedLanguage]}>
				<ThemeProvider theme={BaseTheme}>
					<AppRoutes />
				</ThemeProvider>
			</IntlProvider>
		);
	}
}

// languageStore is the store value fetched from localeReducer
const mapStateToProps = (state) => {
	return {
		languageStore: state.localeReducer,
	};
};

export default connect(mapStateToProps)(App);
