import React, { useEffect, useState } from "react";
import Topbar from "../../components/Topbar/Topbar";
import Root from "../Root/Root";
import './Coupon.scss'
import { Popover, Table, Tooltip } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { getAllCoupons, toggleOpenAddCoupenDailoge, deleteCoupon, toggleDeleteCoupenConfirmationDailoge, updateCoupon } from '../../redux/actions/ManageCouponsAction';
import AddCoupon from "./AddCoupon/AddCoupon";
import ConfirmationMessageDialog from "../../components/ConfirmationMessageDialog/ConfirmationMessageDialog";

const Coupons = () => {
    const obj = {
        couponCode: "",
        couponName: "",
        couponPercentage: 0,
        couponStatus: 0,
    }
    const dispatch = useDispatch();
    const [couponObject, setCouponObject] = useState(obj);

    const [coupenState, setCoupenState] = useState({
        searchValue: '',
        coupenId: null,
        isUpdating: false
    });
    useEffect(() => {
        dispatch(getAllCoupons())
    }, [])

    const onCloseCouponDrawer = () => {
        handleOpenCoupenDialog(false);
        resetCouponForm();
    }

    const resetCouponForm = () => {
        setCouponObject(obj);
        setCoupenState(state => ({
            ...state,
            searchValue: '',
            coupenId: null,
            isUpdating: false
        }));
    }
    const onAddNewCoupon = () => {
        setCouponObject(obj);
        handleOpenCoupenDialog(true);
    }

    const handleOpenCoupenDialog = (value) => {
        dispatch(toggleOpenAddCoupenDailoge(value));
    }

    const onHandleInputChange = (event) => {
        const { name, value } = event.target;
        setCouponObject(prevState => ({ ...prevState, [name]: value }));
    }

    const { couponList, isPageLoading, isLoading, deleteCoupenDialoge, addCouponDailog } = useSelector(state => state.manageCouponReducer);


    const onChangeSearch = () => {

    }

    const onClickDelete = (record) => {
        toggleDeletePropertyDialog(true, record._id);
    }
    const onClickEdit = (record) => {
        setCouponObject({
            couponCode: record.couponCode,
            couponName: record.couponName,
            couponPercentage: record.couponPercentage,
            couponStatus: record.couponStatus
        });
        setCoupenState(state => ({
            ...state,
            isUpdating: true,
            coupenId: record._id
        }));
        handleOpenCoupenDialog(true);
    }

    const toggleDeletePropertyDialog = (value, coupenId = "") => {
        setCoupenState(state => ({ ...state, coupenId }));
        dispatch(toggleDeleteCoupenConfirmationDailoge(value))
    }

    const onDeleteProperty = () => {
        dispatch(deleteCoupon(coupenState.coupenId))
    }

    const onClickActiveOrInactivateCoupon = (coupon, couponStatus) => {
        let request = {
            couponCode: coupon.couponCode,
            couponName: coupon.couponName,
            couponPercentage: coupon.couponPercentage,
            couponStatus: couponStatus
        }
        dispatch(updateCoupon(request, coupon._id));
    }
    const renderCouponList = (data) => {

        const content = record => (
            <div>

                <div className="action-span" onClick={() => onClickEdit(record)}>
                    <img src={require("../../assets/images/common/Edit_New.svg")} alt="edit" />
                    <p className="pop-item">Edit</p>
                </div>
                <div className="action-span" onClick={() => onClickDelete(record)}>
                    <img src={require("../../assets/images/common/delete_active.svg")} alt="edit" />
                    <p className="pop-item">Delete</p>
                </div>
                {record.couponStatus === 0 ? (<div className="action-span" onClick={() => onClickActiveOrInactivateCoupon(record, 1)}>
                    <img src={require("../../assets/images/common/Edit_New.svg")} alt="edit" />
                    <p className="pop-item">Deactivate</p>
                </div>) : (
                    <div className="action-span" onClick={() => onClickActiveOrInactivateCoupon(record, 0)}>
                        <img src={require("../../assets/images/common/Edit_New.svg")} alt="edit" />
                        <p className="pop-item">Activate</p>
                    </div>
                )}
            </div>
        );
        const columns = [
            {
                title: "Coupon Name",
                //className: "sorter-no-tooltip",
                dataIndex: "couponName",
                key: "couponName",
            },
            {
                title: "Coupon Code",
                //className: "button-box-wrapper",
                dataIndex: "couponCode",
                key: "couponCode",

            },

            {
                title: "Coupon Percentage",
                key: "couponPercentage",
                dataIndex: "couponPercentage"

            },
            {
                title: "Coupon Status",
                key: "action",
                dataIndex: "couponStatus",
                render: (text) => {
                    return (
                        <div>
                            {text === 0 && <p className="textBlue">Active</p>}

                            {text === 1 && <p className="textDarkBlue">InActive</p>}
                        </div>
                    );
                },
            },
            {
                title: "Action",
                key: "action",
                render: record =>
                    <Popover
                        placement="bottom"
                        content={content(record)}
                        overlayClassName="manage-artworks-action-popover"
                    >
                        <img className="action-image" src={require("../../assets/images/common/artworksActionIcon.svg")} alt="action" />
                    </Popover>
            }


        ]
        return <Table loading={isPageLoading || isLoading} rowKey={record => record._id
        } columns={columns} dataSource={data} pagination={{ pageSize: 5 }} showSorterTooltip sticky />


    }
    return (
        <Root>
            <Topbar title={"Manage Coupons"} showBreadCrumbs showHead showSearch searchValue={coupenState.searchValue} onChangeSearch={onChangeSearch} />
            <div id="manage-coupons">
                <div className="coupon-list">
                    {renderCouponList(couponList)}
                    {/* {isPageLoading ?
                        <div className="category-list-wrapper">
                            <CircularProgress className="circular-loader" size={20} />
                            Fetching Coupons ...
                        </div>

                        : renderCouponList(couponList)
                    } */}
                    {!isPageLoading &&
                        <div className="add-button-box add-btn-fixed" onClick={onAddNewCoupon}>
                            <Tooltip placement="top" title={"Add New Coupon"}>

                                <span className="add-btn">
                                    <p>+</p> {`Add Coupon`}
                                </span>
                            </Tooltip>
                        </div>
                    }
                </div>
                <AddCoupon couponObject={couponObject} openCouponDrawer={addCouponDailog} onCloseCouponDrawer={onCloseCouponDrawer}
                    isLoading={isLoading} onHandleInputChange={onHandleInputChange} isUpdating={coupenState.isUpdating} coupenId={coupenState.coupenId} />

                <ConfirmationMessageDialog
                    open={deleteCoupenDialoge}
                    handleClose={() => toggleDeletePropertyDialog(false)}
                    confirmMessage={"Are you sure you want to delete this coupen?"}
                    confirmButton={"Delete"}
                    loader={isLoading}
                    onConfirm={onDeleteProperty}
                />
            </div>
        </Root>
    );
};

export default Coupons;