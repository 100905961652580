import { APIUrlConstants } from "../../../util/apiUrl.constant";
import { APIService } from "../../../util/api.service";


export class UserProfileService {
           static getArtistProfile(userId) {
               let url = APIUrlConstants.getApiUrl("getArtistById") + "?UserId=" + userId;
               return APIService.doGet(url).then(resp => {
                   if (resp) {
                       return resp;
                   }
                   throw resp;
               });
           }
           static getGalleryProfile(userId) {
               let url = APIUrlConstants.getApiUrl("getGalleryById") + "?UserId=" + userId;
               return APIService.doGet(url).then(resp => {
                   if (resp) {
                       return resp;
                   }
                   throw resp;
               });
           }
       }
