import React, { Component } from "react";
import { connect } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import { Pagination, Table, Tooltip } from "antd";
//Files
import { getArtworksListAction } from "../../redux/actions/ManageArtworksAction";
import Root from "../Root/Root";
import Topbar from "../../components/Topbar/Topbar";
import { Util } from "../../util/util";
// SCSS
import "../ManageArtworks/ManageArtworks.scss";

class OfflineServices extends Component {
	constructor(props) {
		super(props);
		this.state = {
			searchValue: "",
			pageNumValue: 1,
		};
	}

	componentDidMount() {
		// List Sellers by fetching details from URL - When Page Loads
		this.setSearchParams();
	}

	componentDidUpdate(prevProps) {
		// List Sellers by fetching details from URL - When URL change occur
		const locationChanged = this.props.location !== prevProps.location;
		if (locationChanged) {
			this.setSearchParams();
		}
	}

	render() {
		const columns = [
			{
				title: "Products",
				dataIndex: "productName",
				key: "productName",
				render: (text, record) => (
					<span className="colWrapper">
						{record.status !== 3 && new Date(record.creationDate).toLocaleDateString() === new Date().toLocaleDateString() && (
							<img className="labelImage" src={require("../../assets/images/common/New_Label.svg")} alt="label" />
						)}
						<img
							className="colImage"
							src={record.displayPicture ? record.displayPicture : require("../../assets/images/common/No_Image.png")}
							alt="productImage"
						/>
						<div className="colDiv">
							<Tooltip title={text ? text : "Nil"}>
								<p className="colText">{text ? text : "Nil"}</p>
							</Tooltip>
							<p className="colGreyText">{record.oneLineDescription ? record.oneLineDescription : "Nil"}</p>
						</div>
					</span>
				)
			},
			{
				title: "RFID",
				dataIndex: "rfid",
				key: "rfId",
				render: text => (
					<Tooltip title={text ? text : "Nil"}>
						<p className="rftext">{text ? text : "Nil"}</p>
					</Tooltip>
				)
			},
			{
				title: "Artist",
				dataIndex: "artistName",
				key: "artistName",
				render: text => (
					<div className="artistDiv">
						<img src={require("../../assets/images/common/User.svg")} alt="user" />
						<p>{text ? text : "Nil"}</p>
					</div>
				)
			},
			{
				title: "Stock",
				dataIndex: "stock",
				key: "stock",
				render: text => <p className="textCenterBlack">{text}</p>
			},
			{
				title: "Price",
				dataIndex: "price",
				key: "price",
				render: text => <p className="textBlack">&#36;{text.toLocaleString("en-IN")}</p>
			},
			{
				title: "Offer",
				dataIndex: "discountPercentage",
				key: "discountPercentage",
				render: text => <p className="textOrange">{text}&#37;</p>
			},
			{
				title: "Offer Price",
				dataIndex: "sellingPrice",
				key: "sellingPrice",
				render: text => <p className="textBlack">&#36;{text.toLocaleString("en-IN")}</p>
			}
		];

		const { artworksListLoader, savedArtworksList, savedArtworkListCount } = this.props.manageArtworksReducer;
		return (
			<Root>
				<Topbar title={"Offline Services"} showSearch searchValue={this.state.searchValue} onChangeSearch={this.onChangeSearch.bind(this)} />
				<div className="manageartworks-wrapper">
					<div className="filter-wrapper">Artworks sold outside the rtistIQ platform</div>
					<div className="sub-wrapper">
						{savedArtworksList && savedArtworksList.length !== 0 ? (
							<Table rowKey={record => record.rfid} columns={columns} dataSource={savedArtworksList} pagination={false} />
						) : artworksListLoader ? (
							<CircularProgress className="circular-loader" size={30} />
						) : (
							<div className="empty-table">No artworks Found.</div>
						)}
						{!artworksListLoader && (
							<Pagination
								current={parseInt(this.state.pageNumValue)}
								total={savedArtworkListCount}
								pageSize={10}
								onChange={this.onSelectPageNumber(this)}
							/>
						)}
					</div>
				</div>
			</Root>
		);
	}

	// ------------------------ When Page loads/ URL changes ------------------------- //
	// Call API by fetching details from URL
	setSearchParams() {
		const { location } = this.props;

		// When filter/Search params exist
		if (location.search) {
			let parsedQuery = Util.queryStringHandling(location.search, "PARSE");
			this.setState(
				{
					searchValue: parsedQuery.Search,
					pageNumValue: this.props.match.params.pageNum
				},
				() => {
					this.doSearchFilterSellers(parsedQuery);
				}
			);
		}

		// When there is no filter/Search params
		else {
			this.setState({ pageNumValue: this.props.match.params.pageNum }, () => {
				this.doSearchFilterSellers();
			});
		}
    }

	//Pagination
	onSelectPageNumber = name => event => {
		this.setState({ pageNumValue: event }, () => {
			this.doChangeUrlWithQuery();
		});
	};

	// Search
	onChangeSearch(search) {
		this.setState(
			{
				searchValue: search,
				pageNumValue: 1
			},
			() => {
				this.doChangeUrlWithQuery();
			}
		);
	}

	// Change URL based on filter/search/Page values
	doChangeUrlWithQuery() {
		let searchCriteria = {
			Search: this.state.searchValue
		};
		const queryString = Util.queryStringHandling(searchCriteria, "STR");
		this.props.history.push("/offline-services/" + this.state.pageNumValue + "?" + queryString);
	}

	// ----------------------- API - Fetch Sellers ----------------------------- //
	doSearchFilterSellers() {
		let request = {
			PageNum: this.state.pageNumValue,
			PageSize: 10,
			Status: [11],
			ProductName: this.state.searchValue || ""
		};
		this.props.getArtworksListAction(request);
	}
}

const mapStateToProps = state => {
	return {
		manageArtworksReducer: state.manageArtworksReducer
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getArtworksListAction: inputData => {
			dispatch(getArtworksListAction(inputData));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(OfflineServices);
