import * as types from "../actions/ActionsTypes";

const initialState = {
    blogList: [],
    blogCount: 0,
    getBlogLoader: false,
    upadteBlogResult: [],
    updateBlogLoader: false,
    deleteBlogResult: [],
    deleteBlogLoader: false,
    addBlogResult: [],
    addBlogLoader: false
};

const blogReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case types.GET_BLOGS_REQUEST:
            state = {
                ...state,
                blogList: [],
                getBlogLoader: true
            };
            return state;
        case types.GET_BLOGS_SUCCESS:
            state = {
                ...state,
                blogList: action.data.blogs,
                blogCount: action.data.count,
                getBlogLoader: false
            };
            return state;
        case types.GET_BLOGS_FAILURE:
            state = {
                ...state,
                blogList: [],
                getBlogLoader: false
            };
            return state;

        case types.UPDATE_BLOGS_REQUEST:
            state = {
                ...state,
                upadteBlogResult: [],
                updateBlogLoader: true
            };
            return state;
        case types.UPDATE_BLOGS_SUCCESS:
            state = {
                ...state,
                upadteBlogResult: action.response,
                updateBlogLoader: false
            };
            return state;
        case types.UPDATE_BLOGS_FAILURE:
            state = {
                ...state,
                upadteBlogResult: [],
                updateBlogLoader: false
            };
            return state;

        case types.DELETE_BLOGS_REQUEST:
            state = {
                ...state,
                deleteBlogResult: [],
                deleteBlogLoader: true
            };
            return state;
        case types.DELETE_BLOGS_SUCCESS:
            state = {
                ...state,
                deleteBlogResult: action.response,
                deleteBlogLoader: false
            };
            return state;
        case types.DELETE_BLOGS_FAILURE:
            state = {
                ...state,
                deleteBlogResult: [],
                deleteBlogLoader: false
            };
            return state;

        case types.ADD_BLOGS_REQUEST:
            state = {
                ...state,
                addBlogResult: [],
                addBlogLoader: true
            };
            return state;
        case types.ADD_BLOGS_SUCCESS:
            state = {
                ...state,
                addBlogResult: action.response,
                addBlogLoader: false
            };
            return state;
        case types.ADD_BLOGS_FAILURE:
            state = {
                ...state,
                addBlogResult: [],
                addBlogLoader: false
            };
            return state;

        default:
            return state;
    }
};
export default blogReducer;
