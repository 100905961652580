import React, { useState, useEffect } from "react";

import { Modal } from "antd";
import ExhibitionsNewModal from "./ExhibitionsNewModal";
import { Popover } from "antd";
import assets from "../../../../../assets";

import ConfirmPopup from '../../../../../components/ConfirmPopup/ConfirmPopup'
import { useHistory } from "react-router-dom";
import { ActionImg, ArtistListGalleryViewTable, ColImage, ColSubText, ColWrapper, MainSubmitButton } from "../../ArtistProfile.styled";
import { authorizedGet, deleteAPi } from "../../../../../util/apiConfig";
import { popToast } from "../../../../../components/Toast/Toast";

export const Exhibitions = ({ onTabChange, userId }) => {
	const [isModalVisible, setIsModalVisible] = useState(false);
	const history = useHistory();
	const [profileExhibition, setProfileExhibition] = useState([]);
	const [apiLoading, setApiLoading] = useState(false);
	const [actionType, setActionType] = useState("add");
	const [editData, setEditData] = useState();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (userId) {
			onLoad();
		}
	}, [userId])

	const onLoad = () => {
		setApiLoading(true);
		authorizedGet(`/UserManagment/api/Artists/GetArtistExhibitions?userId=${userId}`).then((responseJson) => {
			if (responseJson.resultCode === 1) {
				setProfileExhibition(responseJson.response.data);
				setApiLoading(false);
			}
		});
	}

	const onToggleModel = () => {
		setIsModalVisible(!isModalVisible);
	};

	const handleOk = () => {
		setIsModalVisible(false);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};
	const onClickDeleteButton = (data) => {
		let token = localStorage.getItem('authToken');
		deleteAPi(`UserManagment/api/Artists/DeleteExhibitions?ExhibitionId=${data._id}`, token).then((responseJson) => {
			if (responseJson.resultCode === 1) {
				popToast.show("Deleted Successfully");
				onLoad();
			} else {
				popToast.show("Failed to delete");
			}
		});
		console.log("🚀 ~ file: Exhibitions.js ~ line 46 ~ onClickDeleteButton ~ data", data);
	};
	const onClickEditButton = (data) => {
		setActionType("edit");
		setEditData(data);
		onToggleModel();
		console.log("🚀 ~ file: Exhibitions.js ~ line 50 ~ onClickEditButton ~ data", data);
	};
	const onClickAction = (btn) => {
		if (btn === "next") {
			onTabChange("art");
		} else {
			// history.push(RouteConstants.ArtistDashboard);
		}
	};
	const columns = [
		{
			title: "Type",
			dataIndex: "type",
			key: "type",
		},

		{
			title: "Year",
			dataIndex: "year",
			key: "year",
		},
		{
			title: "Title",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Institution",
			dataIndex: "institution",
			key: "institution",
		},
		{
			title: "Location",
			dataIndex: "location",
			key: "location",
		},
		{
			title: "Image",
			dataIndex: "attachments",
			key: "attachments",
			render: (text, record) => (
				<ColWrapper>
					<ColImage
						shouldAddExtraWrap
						extraWrapClassName="artworks-listing-empty-image"
						src={record.attachmentsCount > 0 ? record.attachments[0] : assets.images.artworkNoImage}
						fallback={record.attachmentsCount > 0 ? record.attachments[0] : assets.images.artworkNoImage}
						alt="Image"
					/>
				</ColWrapper>
			),
		},
		{
			title: "Action",
			key: "action",
			render: (record) => (
				<React.Fragment>
					<Popover placement="bottom" content={setContent(record)}>
						<ActionImg src={assets.images.artworksActionIcon} alt="action" />
					</Popover>
				</React.Fragment>
			),
		},
	];
	const setContent = (record) => {
		return (
			<div>
				<ColSubText
					onClick={() => {
						onClickEditButton(record);
					}}
					textcolor={"#000000"}
					style={{ cursor: "pointer" }}
				>
					Edit
				</ColSubText>
				<ConfirmPopup
					text={"Confirm Deletion"}
					image={assets.images.deleteIcon}
					buttonText={"Delete"}
					confirmAction={() => onClickDeleteButton(record)}
				>
					<ColSubText textcolor={"#000000"} style={{ cursor: "pointer" }}>
						Delete
					</ColSubText>
				</ConfirmPopup>
			</div>
		);
	};

	return (
		<div>
			<div className="table-top-wrapper-with-content">
				<p>
					Add your solo shows and group exhibition. You do not have the enter the exhibitions in a particular order, they are by default
					chronologically displayed on the platform.
				</p>
				<MainSubmitButton
					onClick={() => {
						setActionType("add");
						onToggleModel();
					}}
				>
					Add Exhibitions
				</MainSubmitButton>
			</div>
			<ArtistListGalleryViewTable loading={apiLoading} rowKey={(record) => record.artistId} columns={columns} dataSource={profileExhibition} pagination={false} />

			<Modal
				title={`${actionType === "edit" ? "Edit" : "Add New"} Exhibitions`}
				footer={null}
				visible={isModalVisible}
				onOk={handleOk}
				onCancel={handleCancel}
				destroyOnClose={true}
				style={{ width: "400px !important" }}
			>
				<ExhibitionsNewModal onLoad={onLoad} userId={userId} type={actionType} data={editData} onToggleModel={onToggleModel} />
			</Modal>
		</div>
	);
};

export default Exhibitions;
