import React from "react";
import { Badge, Icon } from "antd";
// Redux
import { connect } from "react-redux";
import { logout, toggleLogoutDialog } from "../../redux/actions/CommonAction";
import ConfirmationMessageDialog from "../ConfirmationMessageDialog/ConfirmationMessageDialog";
// SCSS
import "./Navbar.scss";
class Navbar extends React.Component {
	state = {
		open: false,
		currentItems: [],
		isMore:
			window.location.href.endsWith("news") || window.location.href.endsWith("blog") || window.location.href.endsWith("report") ? true : false,
		isArtManagement:
			window.location.href.includes("manage-artworks") ||
				window.location.href.includes("manage-artists") ||
				window.location.href.includes("manage-gallery") ||
				window.location.href.endsWith("bulk-artworks-upload") ||
				window.location.href.endsWith("collections") ||
				window.location.href.endsWith("auctions")
				? true
				: false,
		isSiteUsersItem:
			window.location.href.includes("manage-users") ||
				window.location.href.endsWith("search-history") ||
				window.location.href.endsWith("abandoned-carts") ||
				window.location.href.includes("user-requests")
				? true
				: false,
		isOrderShippingItem:
			window.location.href.includes("quotes") ||
				window.location.href.includes("orders") ||
				window.location.href.includes("shipment-providers") ||
				window.location.href.includes("shipping-charges") ||
				window.location.href.includes("countries-taxes") ||
				window.location.href.includes("offline-services")
				? true
				: false,
		isSiteContentItem:
			window.location.href.endsWith("manage-home") ||
				window.location.href.endsWith("categories") ||
				window.location.href.endsWith("lookup") ||
				window.location.href.endsWith("filter")
				? true
				: false,
		isContentManagementItem:
			window.location.href.endsWith("news") ||
				window.location.href.endsWith("blog") ||
				window.location.href.endsWith("survey-forms") ||
				window.location.href.endsWith("coupons")
				? true
				: false,
		isSystemItem: window.location.href.endsWith("notification") || window.location.href.endsWith("analytics") ? true : false,
	};

	componentDidMount() {
		let list = [
			"manage-users",
			"manage-artists",
			"manage-gallery",
			"countries-taxes",
			"quotes",
			"collections",
			"orders",
			"shipment-providers",
			"shipping-charges",
			"news",
			"blog",
			"report",
		];
		let location = list.find((item) => window.location.href.includes(item));
		switch (location) {
			// case "manage-users":
			// 	this.scrollToView("manage-users");
			// 	break;

			// case "manage-artists":
			// 	this.scrollToView("manage-artists");
			// 	break;

			case "manage-gallery":
				this.scrollToView("manage-gallery");
				break;

			case "countries-taxes":
				this.scrollToView("countries-taxes");
				break;

			// case "quotes":
			// 	this.scrollToView("quotes");
			// 	break;

			// case "collections":
			// 	this.scrollToView("collections");
			// 	break;

			// case "orders":
			// 	this.scrollToView("orders");
			// 	break;

			// case "shipment-providers":
			// 	this.scrollToView("shipment-providers");
			// 	break;

			// case "shipping-charges":
			// 	this.scrollToView("shipping-charges");
			// 	break;

			// case "news":
			// 	this.scrollToView("moreItem");
			// 	break;

			// case "blog":
			// 	this.scrollToView("moreItem");
			// 	break;

			// case "report":
			// 	this.scrollToView("moreItem");
			// 	break;

			default:
				// this.scrollToView("notification");
				break;
		}
	}

	// Function lets you scroll to the element in the div, based on id
	scrollToView = (id) => {
		const element = document.getElementById(id);
		element.scrollIntoView({ behavior: "smooth" });
	};

	render() {
		// const userData = Util.getUserDetails();

		const { logoutDialog } = this.props.loginReducer;

		return (
			<div className="navbar-wrapper">
				<div className="logo-box">
					<img onClick={() => (window.location.href = "/#/dashboard")} src={require("../../assets/images/common/Logo.svg")} alt="" />
				</div>

				<div className="navbar-list">
					<ul>
						<li
							className={"navbar-list-item" + (window.location.href.endsWith("dashboard") ? " active" : "")}
							onClick={() => this.nextPath("dashboard", window.location.hash)}
						>
							<div className="list-image">
								<img
									alt=""
									src={
										window.location.href.endsWith("dashboard")
											? require("../../assets/images/sidenav/dashboard_active.svg")
											: require("../../assets/images/sidenav/dashboard.svg")
									}
								/>
							</div>
							Dashboard
						</li>

						<li
							id="artManagementItem"
							className={
								"navbar-list-item" +
								(this.state.isArtManagement ||
									window.location.href.endsWith("manage-artworks") ||
									window.location.href.endsWith("manage-artists") ||
									window.location.href.endsWith("manage-gallery") ||
									window.location.href.endsWith("bulk-artworks-upload") ||
									window.location.href.endsWith("collections") ||
									window.location.href.endsWith("auctions")
									? " active"
									: "")
							}
							onClick={this.handleMenuClick.bind(this, "artManagement")}
						>
							<div className="list-image">
								<img
									alt=""
									src={
										this.state.isArtManagement ||
											window.location.href.endsWith("manage-artworks") ||
											window.location.href.endsWith("manage-artists") ||
											window.location.href.endsWith("manage-gallery") ||
											window.location.href.endsWith("bulk-artworks-upload") ||
											window.location.href.endsWith("collections") ||
											window.location.href.endsWith("auctions")
											? require("../../assets/images/sidenav/categories_active.svg")
											: require("../../assets/images/sidenav/categories.svg")
									}
								/>
							</div>
							<div className="menu-text">
								<p>Art Management</p>

								<img alt="downArrow" src={require("../../assets/images/sidenav/Arrow_down.svg")} style={{ marginLeft: "50px" }} />
							</div>
						</li>
						{this.state.isArtManagement && (
							<div style={{ marginLeft: "50px" }}>
								<li
									className={
										"navbar-list-item" +
										(window.location.href.endsWith("manage-artworks") ||
											window.location.href.includes("seller-profile") ||
											window.location.href.includes("manage-artworks")
											? " active"
											: "")
									}
									onClick={() => this.nextPath("manage-artworks", window.location.hash)}
								>
									Manage Artworks
								</li>
								<li
									id="manage-artists"
									className={
										"navbar-list-item" +
										(window.location.href.endsWith("manage-artists") ||
											window.location.href.includes("manage-artists/user-profile") ||
											window.location.href.includes("manage-artists")
											? " active"
											: "")
									}
									onClick={() => this.nextPath("manage-artists", window.location.hash)}
								>
									Manage Artists
								</li>
								<li
									id="manage-gallery"
									className={
										"navbar-list-item" +
										(window.location.href.endsWith("manage-gallery") ||
											window.location.href.includes("manage-galler/user-profile") ||
											window.location.href.includes("manage-gallery")
											? " active"
											: "")
									}
									onClick={() => this.nextPath("manage-gallery", window.location.hash)}
								>
									Manage Gallery
								</li>
								<li
									id="orders"
									className={
										"navbar-list-item" +
										(window.location.href.endsWith("bulk-artworks-upload") ||
											window.location.href.includes("bulk-artworks-upload")
											? " active"
											: "")
									}
									onClick={() => this.nextPath("bulk-artworks-upload", window.location.hash)}
								>
									Bulk Upload
								</li>
								<li
									id="collections"
									className={"navbar-list-item" + (window.location.href.endsWith("collections") ? " active" : "")}
									onClick={() => this.nextPath("collections", window.location.hash)}
								>
									Collections
								</li>
								<li
									className={"navbar-list-item" + (window.location.href.endsWith("auctions") ? " active" : "")}
									onClick={() => this.nextPath("auctions", window.location.hash)}
								>
									Auctions
								</li>

								{/* <li
                                    className={window.location.href.endsWith("report") ? "navbar-list-item active" : "navbar-list-item"}
                                    onClick={() => this.nextPath("report", window.location.hash)}
                                >
                                    Report
                                </li> */}
							</div>
						)}
						<li
							id="siteUsersItem"
							className={
								"navbar-list-item" +
								(this.state.isSiteUsersItem ||
									window.location.href.endsWith("manage-users") ||
									window.location.href.endsWith("search-history") ||
									window.location.href.endsWith("abandoned-carts")
									? " active"
									: "")
							}
							onClick={this.handleMenuClick.bind(this, "siteUsersItem")}
						>
							<div className="list-image">
								<img
									alt=""
									src={
										this.state.isSiteUsersItem ||
											window.location.href.endsWith("manage-users") ||
											window.location.href.endsWith("search-history") ||
											window.location.href.endsWith("abandoned-carts") ||
											window.location.href.includes("user-requests")
											? require("../../assets/images/sidenav/Manage Users_active.svg")
											: require("../../assets/images/sidenav/Manage Users.svg")
									}
								/>
							</div>
							<div className="menu-text">
								<p>Site Users</p>

								<img alt="downArrow" src={require("../../assets/images/sidenav/Arrow_down.svg")} style={{ marginLeft: "50px" }} />
							</div>
						</li>
						{this.state.isSiteUsersItem && (
							<div style={{ marginLeft: "50px" }}>
								<li
									id="manage-users"
									className={
										"navbar-list-item" +
										(window.location.href.endsWith("manage-users") ||
										window.location.href.includes("manage-users/user-profile") ||
										window.location.href.includes("manage-users") ||
										window.location.href.includes("change-role")
											? " active"
											: "")
									}
									onClick={() => this.nextPath("manage-users", window.location.hash)}
								>
									Manage Users
								</li>
								<li
									className={"navbar-list-item" + (window.location.href.endsWith("search-history") ? " active" : "")}
									onClick={() => this.nextPath("search-history", window.location.hash)}
								>
									Search History
								</li>
								<li
									className={"navbar-list-item" + (window.location.href.endsWith("abandoned-carts") ? " active" : "")}
									onClick={() => this.nextPath("abandoned-carts", window.location.hash)}
								>
									Abandoned Cart
								</li>
								<li
									className={window.location.href.endsWith("user-requests") ? "navbar-list-item active" : "navbar-list-item"}
									onClick={() => this.nextPath("user-requests", window.location.hash)}
								>
									Requests
								</li>
							</div>
						)}
						<li
							id="orderShippingItem"
							className={
								"navbar-list-item" +
								(this.state.isOrderShippingItem ||
									window.location.href.includes("quotes") ||
									window.location.href.includes("orders") ||
									window.location.href.includes("shipment-providers") ||
									window.location.href.includes("shipment-charges") ||
									window.location.href.includes("countries-taxes") ||
									window.location.href.includes("offline-services")
									? " active"
									: "")
							}
							onClick={this.handleMenuClick.bind(this, "orderShippingItem")}
						>
							<div className="list-image">
								<img
									alt=""
									src={
										this.state.isOrderShippingItem ||
											window.location.href.includes("quotes") ||
											window.location.href.includes("orders") ||
											window.location.href.includes("shipment-providers") ||
											window.location.href.includes("shipment-charges") ||
											window.location.href.includes("countries-taxes") ||
											window.location.href.includes("offline-services")
											? require("../../assets/images/sidenav/Orders_active.svg")
											: require("../../assets/images/sidenav/Orders.svg")
									}
								/>
							</div>
							<div className="menu-text">
								<p>Orders and Shipping</p>

								<img alt="downArrow" src={require("../../assets/images/sidenav/Arrow_down.svg")} style={{ marginLeft: "50px" }} />
							</div>
						</li>
						{this.state.isOrderShippingItem && (
							<div style={{ marginLeft: "50px" }}>
								<li
									id="quotes"
									className={
										"navbar-list-item" +
										(window.location.href.endsWith("quotes") || window.location.href.includes("quotes") ? " active" : "")
									}
									onClick={() => this.nextPath("quotes", window.location.hash)}
								>
									Offers
								</li>
								<li
									id="orders"
									className={
										"navbar-list-item" +
										(window.location.href.endsWith("orders") || window.location.href.includes("orders") ? " active" : "")
									}
									onClick={() => this.nextPath("orders", window.location.hash)}
								>
									Orders
								</li>
								<li
									id="shipment-providers"
									className={
										"navbar-list-item" +
										(window.location.href.endsWith("shipment-providers") || window.location.href.includes("shipment-providers")
											? " active"
											: "")
									}
									onClick={() => this.nextPath("shipment-providers", window.location.hash)}
								>
									Shipment Providers
								</li>
								<li
									id="shipping-charges"
									className={
										"navbar-list-item" +
										(window.location.href.endsWith("shipping-charges") || window.location.href.includes("shipping-charges")
											? " active"
											: "")
									}
									onClick={() => this.nextPath("shipping-charges", window.location.hash)}
								>
									{" "}
									Shipping Charges
								</li>
								<li
									id="countries-taxes"
									className={
										"navbar-list-item" +
										(window.location.href.endsWith("countries-taxes") || window.location.href.includes("countries-taxes")
											? " active"
											: "")
									}
									onClick={() => this.nextPath("countries-taxes", window.location.hash)}
								>
									Countries & Taxes
								</li>
								<li
									className={
										"navbar-list-item" +
										(window.location.href.endsWith("offline-services") || window.location.href.includes("offline-services")
											? " active"
											: "")
									}
									onClick={() => this.nextPath("offline-services", window.location.hash)}
								>
									External Sales
								</li>
							</div>
						)}
						<li
							id="siteContentItem"
							className={
								"navbar-list-item" +
								(this.state.isSiteContentItem ||
									window.location.href.endsWith("manage-home") ||
									window.location.href.endsWith("categories") ||
									window.location.href.endsWith("lookup") ||
									window.location.href.endsWith("filter")
									? " active"
									: "")
							}
							onClick={this.handleMenuClick.bind(this, "siteContentItem")}
						>
							<div className="list-image">
								<img
									alt=""
									src={
										this.state.isSiteContentItem ||
											window.location.href.endsWith("manage-home") ||
											window.location.href.endsWith("categories") ||
											window.location.href.endsWith("lookup") ||
											window.location.href.endsWith("filter")
											? require("../../assets/images/sidenav/Home View_active.svg")
											: require("../../assets/images/sidenav/Home View.svg")
									}
								/>
							</div>
							<div className="menu-text">
								<p>Site Content</p>
								<img alt="downArrow" src={require("../../assets/images/sidenav/Arrow_down.svg")} style={{ marginLeft: "50px" }} />
							</div>
						</li>
						{this.state.isSiteContentItem && (
							<div style={{ marginLeft: "50px" }}>
								<li
									className={"navbar-list-item" + (window.location.href.endsWith("manage-home") ? " active" : "")}
									onClick={() => this.nextPath("manage-home", window.location.hash)}
								>
									Home View
								</li>
								<li
									className={"navbar-list-item" + (window.location.href.includes("categories") ? " active" : "")}
									onClick={() => this.nextPath("categories", window.location.hash)}
								>
									Categories
								</li>
								<li
									className={"navbar-list-item" + (window.location.href.includes("lookup") ? " active" : "")}
									onClick={() => this.nextPath("lookup", window.location.hash)}
								>
									Lookups
								</li>
								<li
									className={"navbar-list-item" + (window.location.href.includes("filter") ? " active" : "")}
									onClick={() => this.nextPath("filter", window.location.hash)}
								>
									{" "}
									Filter Rules
								</li>
							</div>
						)}

						<li
							id="contentManagementItem"
							className={
								"navbar-list-item" +
								(this.state.isContentManagementItem ||
									window.location.href.endsWith("news") ||
									window.location.href.endsWith("blog") ||
									window.location.href.endsWith("survey-forms") ||
									window.location.href.endsWith("coupons")
									? " active"
									: "")
							}
							onClick={this.handleMenuClick.bind(this, "contentManagementItem")}
						>
							<div className="list-image">
								<img
									alt=""
									src={
										this.state.isContentManagementItem ||
											window.location.href.endsWith("news") ||
											window.location.href.endsWith("blog") ||
											window.location.href.endsWith("survey-forms") ||
											window.location.href.endsWith("coupons")
											? require("../../assets/images/sidenav/Manage Gallery_active.svg")
											: require("../../assets/images/sidenav/Manage Gallery.svg")
									}
								/>
							</div>
							<div className="menu-text">
								<p>Content Management</p>

								<img alt="downArrow" src={require("../../assets/images/sidenav/Arrow_down.svg")} style={{ marginLeft: "50px" }} />
							</div>
						</li>
						{this.state.isContentManagementItem && (
							<div style={{ marginLeft: "50px" }}>
								<li
									className={window.location.href.endsWith("blog") ? "navbar-list-item active" : "navbar-list-item"}
									onClick={() => this.nextPath("blog/1", window.location.hash)}
								>
									Blog Articles
								</li>
								<li
									className={window.location.href.endsWith("news") ? "navbar-list-item active" : "navbar-list-item"}
									onClick={() => this.nextPath("news", window.location.hash)}
								>
									News
								</li>
								<li
									id="survey-forms"
									className={
										"navbar-list-item" +
										(window.location.href.endsWith("survey-forms") ||
											window.location.href.includes("preview-survey") | window.location.href.includes("new-survey") ||
											window.location.href.includes("survey-forms")
											? " active"
											: "")
									}
									onClick={() => this.nextPath("survey-forms", window.location.hash)}
								>
									Manage Survey
								</li>
								<li
									className={"navbar-list-item" + (window.location.href.includes("coupons") ? " active" : "")}
									onClick={() => this.nextPath("coupons", window.location.hash)}
								>
									Manage Coupons
								</li>
								<li
									className={"navbar-list-item" + (window.location.href.includes("reels-video") ? " active" : "")}
									onClick={() => this.nextPath("reels-video", window.location.hash)}
								>
									Manage Reels
								</li>
							</div>
						)}

						<li
							id="systemItem"
							className={
								"navbar-list-item" +
								(this.state.isSystemItem ||
									window.location.href.endsWith("notification") ||
									window.location.href.endsWith("analytics")
									? " active"
									: "")
							}
							onClick={this.handleMenuClick.bind(this, "systemItem")}
						>
							<div className="list-image">
								<img
									alt=""
									src={
										this.state.isSystemItem ||
											window.location.href.endsWith("notification") ||
											window.location.href.endsWith("analytics")
											? require("../../assets/images/sidenav/Collection.svg")
											: require("../../assets/images/sidenav/Collection_active.svg")
									}
								/>
							</div>
							<div className="menu-text">
								<p>System</p>
								<img alt="downArrow" src={require("../../assets/images/sidenav/Arrow_down.svg")} style={{ marginLeft: "50px" }} />
							</div>
						</li>
						{this.state.isSystemItem && (
							<div style={{ marginLeft: "50px" }}>
								<li
									className={window.location.href.endsWith("analytics") ? "navbar-list-item active" : "navbar-list-item"}
									onClick={() => this.nextPath("analytics", window.location.hash)}
								>
									Analytics
								</li>
								<li
									id="notification"
									className={"navbar-list-item" + (window.location.href.endsWith("notification") ? " active" : "")}
									onClick={() => this.nextPath("notification", window.location.hash)}
								>
									Notification
								</li>
							</div>
						)}

						<li className="navbar-list-item" onClick={this.onLogout.bind(this, true)}>
							<div className="list-image">
								<Icon className="logout" type="poweroff" />
							</div>
							Logout
						</li>
					</ul>
				</div>

				{/* <div className="navbar-bottom">
                    <p className="animated-border" onClick={this.onLogout.bind(this, true)}><span>Logout</span></p>
                </div> */}

				{/* Logout Confirmation */}
				<ConfirmationMessageDialog
					open={logoutDialog}
					handleClose={this.onLogout.bind(this, false)}
					confirmTitle={"Log Out"}
					confirmMessage={"Are you sure you want to logout?"}
					confirmButton={"Log Out"}
					onConfirm={this.onConfirmLogout.bind(this)}
				/>
			</div>
		);
	}

	// Routing to component
	nextPath(path, currentPath = "") {
		// window.location.href = "/#/" + path;
		this.props.history.push(`/${path}`);
		// TODO : If user is clicking in a tab staying on same tab itself
		// Then refresh the page contents
	}

	handleMenuClick(menu) {
		if (menu === "artManagement") {
			this.setState({
				isArtManagement: !this.state.isArtManagement,
				isSiteUsersItem: false,
				isOrderShippingItem: false,
				isSiteContentItem: false,
				isContentManagementItem: false,
				isSystemItem: false,
				isRequestItem: false,
			});
		} else if (menu === "siteUsersItem") {
			this.setState({
				isSiteUsersItem: !this.state.isSiteUsersItem,
				isArtManagement: false,
				isOrderShippingItem: false,
				isSiteContentItem: false,
				isContentManagementItem: false,
				isSystemItem: false,
				isRequestItem: false,
			});
		} else if (menu === "orderShippingItem") {
			this.setState({
				isOrderShippingItem: !this.state.isOrderShippingItem,
				isArtManagement: false,
				isSiteUsersItem: false,
				isSiteContentItem: false,
				isContentManagementItem: false,
				isRequestItem: false,
			});
		} else if (menu === "siteContentItem") {
			this.setState({
				isSiteContentItem: !this.state.isSiteContentItem,
				isOrderShippingItem: false,
				isArtManagement: false,
				isSiteUsersItem: false,
				isContentManagementItem: false,
				isSystemItem: false,
				isRequestItem: false,
			});
		} else if (menu === "contentManagementItem") {
			this.setState({
				isContentManagementItem: !this.state.isContentManagementItem,
				isSiteContentItem: false,
				isOrderShippingItem: false,
				isArtManagement: false,
				isSiteUsersItem: false,
				isSystemItem: false,
				isRequestItem: false,
			});
		} else if (menu === "systemItem") {
			this.setState({
				isSystemItem: !this.state.isSystemItem,
				isContentManagementItem: false,
				isSiteContentItem: false,
				isOrderShippingItem: false,
				isArtManagement: false,
				isSiteUsersItem: false,
				isRequestItem: false,
			});
		} else if (menu === "requestsItem") {
			this.setState({
				isRequestItem: !this.state.isRequestItem,
				isContentManagementItem: false,
				isSiteContentItem: false,
				isOrderShippingItem: false,
				isArtManagement: false,
			});
		}
	}
	// Logout
	onLogout(value) {
		this.props.toggleLogoutDialog(value);
	}
	onConfirmLogout() {
		this.props.logout();
	}
}

const mapStateToProps = (state) => {
	return {
		loginReducer: state.loginReducer,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		logout: () => {
			dispatch(logout());
		},
		toggleLogoutDialog: (value) => {
			dispatch(toggleLogoutDialog(value));
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Navbar);
