import React, { Component } from "react";
import { connect } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import { Tooltip } from "antd";
import { Tag, Divider } from "antd";

//Files
import Root from "../Root/Root";
import Topbar from "../../components/Topbar/Topbar";
import { getCollectionsAction, deleteCollectionAction, initializeFilters } from "../../redux/actions/CollectionAction";
import ConfirmationMessageDialog from "../../components/ConfirmationMessageDialog/ConfirmationMessageDialog";
import AddCollectionDialog from "./AddCollectionDialog/AddCollectionDialog";
// SCSS
import "./Collections.scss";
import propertiesList from "../../properties.json";
import { APIService } from "../../util/api.service";
import { APIUrlConstants } from "../../util/apiUrl.constant";

class Collections extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showAction: false,
			showIndex: null,
			deleteconfirmationDialog: false,
			collectionId: "",
			addCollectionDialog: false,
			isUpdating: false,
			isViewing: false,
			updatingCollection: null,
		};
	}
	componentDidMount() {
		let request = { pageNum: 1, pageSize: 100 };
		this.props.getCollectionsAction(request);
	}
	renderStatus = (status) => {
		if (status === 0) {
			return <Tag color="#2db7f5">Draft</Tag>;
		} else if (status === 1) {
			return <Tag color="#f50">Exclusive</Tag>;
		} else if (status === 2) {
			return <Tag color="#87d068">Published</Tag>;
		}
	};
	//Set Featured Artwork
	async onFeatured(item, status) {
		console.log("🚀 ~ file: Collections.js ~ line 48 ~ Collections ~ onFeatured ~ item", status);

		let url = `${APIUrlConstants.getApiUrl("updateCollectionIsFeatured")}/?CollectionId=${item._id}&IsFeatured=${status}`;
		APIService.doPut(url, {}).then((resp) => {
			if (resp) {
				if (resp.result === 200) {
					let request = { pageNum: 1, pageSize: 100 };
					this.props.getCollectionsAction(request);
				}
				console.log("🚀 ~ file: Collections.js ~ line 54 ~ Collections ~ APIService.doPut ~ resp", resp);
			}
			throw resp;
		});
	}
	render() {
		const { collectionListLoader, deleteCollectionLoader, addingCollectionLoader, collectionsList } = this.props.collectionReducer;
		return (
			<Root>
				<Topbar title={"Collections"} />
				{collectionsList.collectionList && collectionsList.collectionList.length !== 0 ? (
					<div
						className={
							collectionListLoader || deleteCollectionLoader || addingCollectionLoader
								? "collectons-wrapper collection-wrapper-disabled"
								: "collectons-wrapper"
						}
					>
						{collectionsList.collectionList &&
							collectionsList.collectionList.length !== 0 &&
							collectionsList.collectionList.map((cardItem, cardIndex) => (
								<div
									key={cardIndex}
									className="collection-card"
									onMouseEnter={() => {
										this.setState({ showAction: true, showIndex: cardIndex });
									}}
									onMouseLeave={() => {
										this.setState({ showAction: false });
									}}
								>
									<img
										src={
											cardItem.collectionBanner ? cardItem.collectionBanner : require("../../assets/images/common/No_Image.png")
										}
										alt="collection"
										onClick={this.onClickViewCollection.bind(this, true, cardItem)}
									/>
									<div className="card-title">
										<Tooltip placement="topLeft" title={cardItem.collectionName ? cardItem.collectionName : "Nil"}>
											<div className="title-wrapper">
												<p>{cardItem.collectionName ? cardItem.collectionName : "Nil"}</p>
												{cardItem.isFeatured ? (
													<img
														className="star-image"
														src={require("../../assets/images/common/Star_Red.svg")}
														onClick={this.onFeatured.bind(this, cardItem, false)}
														alt="star"
													/>
												) : (
													<img
														className="star-image"
														src={require("../../assets/images/common/Star.svg")}
														onClick={this.onFeatured.bind(this, cardItem, true)}
														alt="star"
													/>
												)}
											</div>

											{this.renderStatus(cardItem.collectionStatus)}
										</Tooltip>
										{this.state.showAction && this.state.showIndex === cardIndex && (
											<div className="action-pill">
												<Tooltip placement="top" title={"Edit"}>
													<img
														src={require("../../assets/images/common/Edit_New.svg")}
														onClick={this.onClickUpdateCollection.bind(this, true, cardItem)}
														alt="edit"
													/>
												</Tooltip>
												<span>|</span>
												<Tooltip placement="top" title={"Delete"}>
													<img
														src={require("../../assets/images/common/delete.svg")}
														onClick={this.toggleDeleteConfirmationDialog.bind(this, cardItem)}
														alt="delete"
													/>
												</Tooltip>
											</div>
										)}
									</div>
								</div>
							))}

						<div className="add-button-box">
							<Tooltip placement="top" title={"Add Collection"}>
								<div className="add-btn" onClick={this.onClickAddCollection.bind(this, true)}>
									+ Add Collection
								</div>
							</Tooltip>
						</div>
					</div>
				) : collectionListLoader || deleteCollectionLoader || addingCollectionLoader ? (
					<div className="collectons-wrapper">
						<CircularProgress className="circular-loader" size={30} />
					</div>
				) : (
					<div className="collectons-wrapper">
						<div className="empty-collectons">No Collections Found.</div>
						<div className="add-button-box">
							<Tooltip placement="top" title={"Add Collection"}>
								<div className="add-btn" onClick={this.onClickAddCollection.bind(this, true)}>
									+ Add Collection
								</div>
							</Tooltip>
						</div>
					</div>
				)}
				<ConfirmationMessageDialog
					open={this.state.deleteconfirmationDialog}
					handleClose={this.toggleDeleteConfirmationDialog.bind(this)}
					confirmMessage={"Are you sure you want to delete this collection?"}
					onConfirm={this.onDeleteCollection.bind(this)}
				/>
				<AddCollectionDialog
					open={this.state.addCollectionDialog}
					isUpdating={this.state.isUpdating}
					isViewing={this.state.isViewing}
					updatingCollection={this.state.updatingCollection}
					handleClose={this.onClickAddCollection.bind(this, false)}
				/>
			</Root>
		);
	}

	//Dialog for deleting user
	toggleDeleteConfirmationDialog(clickItem) {
		this.setState(
			{
				deleteconfirmationDialog: !this.state.deleteconfirmationDialog,
			},
			() => {
				if (this.state.deleteconfirmationDialog) {
					// set id of rule only when dialog opens
					this.setState({
						collectionId: clickItem._id,
					});
				}
			}
		);
	}

	// Add Collection Modal
	onClickAddCollection(value) {
		this.props.initializeFilters();
		this.setState({
			isUpdating: false,
			isViewing: false,
			updatingCollection: null,
			addCollectionDialog: value,
		});
	}

	onClickUpdateCollection = (value, item) => {
		this.setState({
			isUpdating: true,
			isViewing: false,
			updatingCollection: item,
			addCollectionDialog: value,
		});
	};

	onClickViewCollection = (value, item) => {
		let url = `${propertiesList.fontEndUrl}collections/${item.slugId}`;
		window.open(url, "_blank", "noopener,noreferrer");
		// this.setState({
		// 	isUpdating: false,
		// 	isViewing: true,
		// 	updatingCollection: item,
		// 	addCollectionDialog: value
		// });
	};

	// Delete Collection Confirm
	async onDeleteCollection() {
		await this.props.deleteCollectionAction(this.state.collectionId);
		await this.handleReset();
	}

	//Reset fields
	handleReset() {
		this.setState({
			...this.state,
			deleteconfirmationDialog: false,
		});
	}
}
const mapStateToProps = (state) => {
	return {
		collectionReducer: state.collectionReducer,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getCollectionsAction: (inputData) => {
			dispatch(getCollectionsAction(inputData));
		},
		deleteCollectionAction: (collectionId) => {
			dispatch(deleteCollectionAction(collectionId));
		},
		initializeFilters: () => {
			dispatch(initializeFilters());
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Collections);
