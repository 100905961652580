import { Checkbox, Popover } from "antd";
import React from "react";
import ReactTable from "../ReactTable/ReactTable";
import AddNewBlogBanner from "./AddNewBlogBanner";
import { AddBannerButton, BlogBannerButtonContainer, BlogBannerContainer, IsActiveCheckBoxWrapper } from "./BlogBanner.styled";
import useBlogBanner from "./useBlogBanner";

const BlogBanner = ({ location, history }) => {
    const { loading, pageCount, pageIndex, pageSize, bannerList, openNewBlogModal, banner, addBannerLoader, filterState,
        updateFilterState,
        setPageSize, nextPage, onClickAddBanner, onClickCancelAddBanner, onImageSelect, onHandleInput,
        AddBanners, onClickEditBanner, UpdateBanners, DeleteBanners, UpdateBannerActiveStatus,
        BoolenColumnFilter } = useBlogBanner(location, history);

    const content = (record) => (
        <div>
            <div className="action-span" onClick={() => onClickEditBanner(record)}>
                <img src={require("../../../../assets/images/common/Edit_New.svg")} alt="edit" />
                <p className="pop-item">Edit</p>
            </div>
            <div className="action-span" onClick={() => DeleteBanners(record)} >
                <img src={require("../../../../assets/images/common/delete_active.svg")} alt="edit" />
                <p className="pop-item">Delete</p>
            </div>
        </div>
    );

    const columns = React.useMemo(
        () => [

            {
                Header: 'BANNER IMAGE',
                accessor: 'imageUC',
                maxWidth: 170,
                minWidth: 170,
                filterable: false,
                Cell: ({ cell: { value } }) => (
                    <img
                        src={value}
                        style={{ width: "100%", height: "90px", minWidth: "180px" }}
                    />
                ),

            },
            {
                Header: 'CONTENT',
                accessor: 'content',
                // Use our custom `fuzzyText` filter on this column
                filter: 'fuzzyText',
                filterable: true,
            },
            {
                Header: 'ACTIVE',
                accessor: 'isActive',
                filterable: true,
                Filter: BoolenColumnFilter,
                Cell: ({ cell: { value, row } }) => (
                    <IsActiveCheckBoxWrapper>
                        <Checkbox checked={value} onChange={() => UpdateBannerActiveStatus(true, row.original)} />
                    </IsActiveCheckBoxWrapper>
                )
            },
            {
                Header: 'ACTION',
                filterable: false,
                Cell: ({ cell: { row } }) => (
                    < Popover placement="bottom" content={content(row && row.original)} overlayClassName="manage-artworks-action-popover" >
                        <img className="action-image" src={require("../../../../assets/images/common/artworksActionIcon.svg")} alt="action" />
                    </Popover >
                )

            }
        ]
    );
    return (
        <>
            <BlogBannerContainer>
                <BlogBannerButtonContainer>
                    <AddBannerButton onClick={onClickAddBanner}>ADD BANNER</AddBannerButton>
                </BlogBannerButtonContainer>
                <ReactTable
                    columns={columns}
                    data={bannerList}
                    updateFilterState={updateFilterState}
                    loading={loading}
                    filterState={filterState}

                />
            </BlogBannerContainer>

            <AddNewBlogBanner openNewBlogModal={openNewBlogModal} onClickCancelAddBanner={onClickCancelAddBanner}
                onImageSelect={onImageSelect} banner={banner} addBannerLoader={addBannerLoader}
                onHandleInput={onHandleInput} AddBanners={AddBanners} UpdateBanners={UpdateBanners}
            />

        </>
    )
}

export default BlogBanner;