import { DatePicker } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import { BlogService } from "../Blog.service";
import moment from "moment";
import { popToast } from "../../../../components/Toast/Toast";
import { Util } from "../../../../util/util";

const useBlogArticles = (location, history) => {

    const [blogList, setBlogList] = useState([]);
    const [loading, setLoading] = React.useState(false)
    const [pageCount, setPageCount] = React.useState(0);
    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const fetchIdRef = React.useRef(0);
    const [openAddArticleModal, setOpenAddArticleModal] = useState(false);
    const [editRecord, setEditRecord] = useState();
    const [filterState, setFilterState] = useState({
        topicInfo: "",
        slugId: "",
        seoTitle: "",
        seoDescription: "",
        PageNo: "",
        pageSize: "",
        title: "",
        isFeatured: "",
        blogDate: "",
    })

    useEffect(() => {
        let parsedQuery = Util.queryStringHandling(location.search, "PARSE");
        if (parsedQuery) {
            setFilterState({
                topicInfo: parsedQuery.topicInfo,
                title: parsedQuery.title,
                slugId: parsedQuery.slugId,
                seoTitle: parsedQuery.seoTitle,
                seoDescription: parsedQuery.seoDescription,
                isFeatured: parsedQuery.isFeatured,
                PageNo: parseInt(parsedQuery.PageNo),
                pageSize: parsedQuery.pageSize,
                blogDate: parsedQuery.blogDate
            })
        }
    }, [location]);


    React.useEffect(() => {
        if (pageIndex > 0) {
            fetchData({ pageIndex, pageSize })
        }
    }, [pageIndex, pageSize])

    const updateFilterState = (name, value) => {
        let newFilterState = { ...filterState };
        newFilterState[name] = value;
        setFilterState(filterState => [{ ...filterState, [name]: value }])
        const queryString = Util.queryStringHandling(newFilterState, "STR");
        history.push('/blog/1?' + queryString);
    }

    const getBlogs = async (pageIndex, pageSize) => {
        try {
            setLoading(true);
            let blogListResp = await BlogService.getBlogList(0, 0);
            if (blogListResp && blogListResp.result === 200) {
                setBlogList(blogListResp.data.blogs);
            }
            else if (blogListResp && blogListResp.result === 400) {
                popToast.show(blogListResp.message);
            } else if (blogListResp && blogListResp.result === 500) {
                popToast.show(blogListResp.message);
            }

            setLoading(false);
        } catch (error) {
            setLoading(false);
        }

    }

    // We'll start our table without any data


    const fetchData = React.useCallback(async ({ pageSize, pageIndex }) => {

        const fetchId = ++fetchIdRef.current

        // Set the loading state
        setLoading(true)

        if (fetchId === fetchIdRef.current) {
            const startRow = pageSize * pageIndex
            const endRow = startRow + pageSize;
            let blogListResp = await getBlogs(pageIndex, pageSize);

            setLoading(false)
        }

    }, [])

    const nextPage = (index) => {
        setPageIndex(index)
    }

    const onClickCancelAddArticle = () => {
        setOpenAddArticleModal(false);
        setEditRecord();
    }

    const onClickAddArticelButton = () => {
        setOpenAddArticleModal(true);
    }

    const onClickEditArticelButton = (record) => {
        setEditRecord(record);
        setOpenAddArticleModal(true);
    }

    const onClickAddBanner = () => {
        setOpenAddArticleModal(true);
    }

    const DeleteArticle = async (record) => {
        try {
            let bannerResp = await BlogService.deleteBlog(record && record._id);
            if (bannerResp && bannerResp.data) {
                let banners = await getBlogs(pageIndex, pageSize);
            }
        } catch (error) {

        }
    }

    const UpdateArticle = async (isFeatured, record) => {
        try {

            record.isFeatured = isFeatured;
            let bannerResp = await BlogService.updateBlog(record._id, record);
            if (bannerResp && bannerResp.data) {
                let banners = await getBlogs(pageIndex, pageSize);
            }

        } catch (error) {

        }
    }

    const BoolenColumnFilter = ({
        column: { filterValue, setFilter, preFilteredRows, id },
    }) => {
        // Calculate the options for filtering
        // using the preFilteredRows
        const options = React.useMemo(() => {
            const options = new Set()
            preFilteredRows.forEach(row => {
                options.add(row.values[id])
            })
            return [...options.values()]
        }, [id, preFilteredRows])

        // Render a multi-select box
        return (
            <select
                value={filterValue}
                onChange={e => {
                    setFilter(e.target.value || undefined)
                }}
            >
                <option value="">All</option>
                {options.map((option, i) => (
                    <option key={i} value={option}>
                        {`${option}`}
                    </option>
                ))}
            </select>
        )
    }

    const DateColumnFilter = ({
        column: { filterValue, setFilter, preFilteredRows, id },
    }) => {

        // Render a multi-select box
        return (
            <DatePicker style={{ minWidth: "100%" }} value={filterValue} onChange={(date, dateString) => {
                setFilter(date || undefined)
            }} />

        )
    }

    return {
        blogList,
        loading,
        pageCount,
        pageIndex,
        pageSize,
        openAddArticleModal,
        editRecord,
        filterState,

        updateFilterState,
        fetchData,
        nextPage,
        setPageSize,
        onClickAddArticelButton,
        onClickCancelAddArticle,
        onClickAddBanner,
        getBlogs,
        onClickEditArticelButton,
        DeleteArticle,
        UpdateArticle,

        BoolenColumnFilter,
        DateColumnFilter
    }
}

export default useBlogArticles;