import * as types from "./ActionsTypes";
import { popToast } from "../../components/Toast/Toast";
import { AddBrandsService } from "../../containers/AddBrands/AddBrands.service";

// ============================= Access Reducer states through this action ============================= //
export const setAddBrandsReducerStates = (stateName, value) => {
    return dispatch => {
        let data = {
            state: stateName,
            value: value
        }
        dispatch({ type: types.SET_ADD_BRAND_REDUCER_STATES, data });
    }
}

// ================================ Get all brands ================================ //
export const getAllBrands = (categoryId, status, priority, searchValue) => {
    return dispatch => {
        dispatch(request());
        AddBrandsService.getBrands(categoryId, status, priority, searchValue).then(
            response => {
                if (response.result === 200) {
                    dispatch(success(response.data));
                } else {
                    dispatch(failure(response.message));
                    popToast.show(
                        "Get Brands Error : " + response.Message ||
                        "Something went wrong while loading brands!"
                    );
                }
            },
            error => {
                if (error && error.status_message) {
                    popToast.show(error.status_message);
                } else {
                    popToast.show(
                        "Something went wrong while loading brands!"
                    );
                }
                console.log("error dispatched for API error!", error);
                dispatch(failure(error));
            }
        );
    };

    function request() { return { type: types.GET_BRANDS_REQUEST }; }
    function success(data) { return { type: types.GET_BRANDS_SUCCESS, data }; }
    function failure(error) { return { type: types.GET_BRANDS_FAILURE, error }; }
};

// ============================= Get All Categories ============================= //
// isNewListing value only passed when expanding a category to view its subcategories
export const getAllCategories = (parentId) => {
    return (dispatch, getState) => {
        dispatch(request());
        AddBrandsService.getCategory(parentId)
            .then(
                (response) => {
                    if (response && response.result === 200) {
                        dispatch(success(response.data));
                    }
                    else {
                        dispatch(failure(response));

                        let errorMessage = 'Something went wrong while getting categories!';
                        if (response && response.message) {
                            switch (response.message) {
                                default:
                                    errorMessage = 'Something went wrong while getting categories! ' + response.message;
                                    break;
                            }
                        }
                        popToast.show(errorMessage);
                    }
                },
                error => {
                    if (error && error.status_message) {
                        popToast.show(error.status_message);
                    }
                    else {
                        popToast.show('Something went wrong while getting categories!');
                    }
                    console.log('error dispatched for API error!', error);
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: types.GET_CATEGORY_REQUEST } }
    function success(response) { return { type: types.GET_CATEGORY_SUCCESS, response } }
    function failure(error) { return { type: types.GET_CATEGORY_FAILURE, error } }
}

// ============================= Create Brand ============================= //
export const createBrand = (requestBody) => {
    return dispatch => {
        dispatch(request());
        AddBrandsService.createBrand(requestBody)
            .then(
                (response) => {
                    if (response && response.result === 200) {

                        // Success : Close create property dialog
                        dispatch(success(response.data));

                        popToast.show('Brand created successfully!');

                        // TODO : Refresh by filter presets
                        // To refresh the list
                        dispatch(getAllBrands());
                    }
                    else {
                        dispatch(failure(response));

                        let errorMessage = 'Something went wrong while creating brands!';
                        if (response && response.message) {
                            switch (response.message) {
                                default:
                                    errorMessage = response.message;
                                    break;
                            }
                        }
                        popToast.show(errorMessage);
                    }
                },
                error => {
                    if (error && error.status_message) {
                        popToast.show(error.status_message);
                    }
                    else {
                        popToast.show('Something went wrong while creating brand!');
                    }
                    console.log('error dispatched for API error!', error);
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: types.CREATE_BRAND_REQUEST } }
    function success(data) { return { type: types.CREATE_BRAND_SUCCESS, data } }
    function failure(error) { return { type: types.CREATE_BRAND_FAILURE, error } }
}

// ============================= Delete Brand ============================= //
export const deleteBrand = (id) => {
    return dispatch => {
        dispatch(request());
        AddBrandsService.deleteBrand(id)
            .then(
                (response) => {
                    if (response && response.result === 200) {

                        // Success : Close create property dialog
                        dispatch(success(response.data));

                        popToast.show('Brand deleted successfully!');

                        // TODO : Refresh by filter presets
                        // To refresh the list
                        dispatch(getAllBrands());
                    }
                    else {
                        dispatch(failure(response));

                        let errorMessage = 'Something went wrong while creating brands!';
                        if (response && response.message) {
                            switch (response.message) {
                                default:
                                    errorMessage = response.message;
                                    break;
                            }
                        }
                        popToast.show(errorMessage);
                    }
                },
                error => {
                    if (error && error.status_message) {
                        popToast.show(error.status_message);
                    }
                    else {
                        popToast.show('Something went wrong while creating brand!');
                    }
                    console.log('error dispatched for API error!', error);
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: types.DELETE_BRAND_REQUEST } }
    function success(data) { return { type: types.DELETE_BRAND_SUCCESS, data } }
    function failure(error) { return { type: types.DELETE_BRAND_FAILURE, error } }
}

// ============================= Update Brand ============================= //
export const updateBrand = (requestBody, id) => {
    return dispatch => {
        dispatch(request());
        AddBrandsService.createBrand(requestBody, true, id)
            .then(
                (response) => {
                    if (response && response.result === 200) {

                        // Success : Close create property dialog
                        dispatch(success(response.data));

                        popToast.show('Brand updated successfully!');

                        // TODO : Refresh by filter presets
                        // To refresh the list
                        dispatch(getAllBrands());
                    }
                    else {
                        dispatch(failure(response));

                        let errorMessage = 'Something went wrong while updating brand!';
                        if (response && response.message) {
                            switch (response.message) {
                                default:
                                    errorMessage = response.message;
                                    break;
                            }
                        }
                        popToast.show(errorMessage);
                    }
                },
                error => {
                    if (error && error.status_message) {
                        popToast.show(error.status_message);
                    }
                    else {
                        popToast.show('Something went wrong while creating brand!');
                    }
                    console.log('error dispatched for API error!', error);
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: types.CREATE_BRAND_REQUEST } }
    function success(data) { return { type: types.CREATE_BRAND_SUCCESS, data } }
    function failure(error) { return { type: types.CREATE_BRAND_FAILURE, error } }
}