import React from "react";
import { Route, Redirect } from "react-router-dom";
import { Util } from "./util";

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      Util.getAuthToken() ? (
        getRedirectionComponent(props, Component)
      ) : (
        <Redirect
          to={{ pathname: "/login", state: { from: props.location } }}
        />
      )
    }
  />
);

function getRedirectionComponent(props, Component) {
  let routeElement;
  // Only admin have access
  if (
    Util.getValueFromLocal("role") === "ADMIN" ||
    Util.getValueFromLocal("role").indexOf("ADMIN") >= 0
  ) {
    routeElement = <Component {...props} />;
  } else {
    routeElement = <Redirect to="/access-denied" />;
  }
  return routeElement;
}
