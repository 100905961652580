import React, { memo } from "react";
import { Slide } from '@material-ui/core';
import { Button, Col, DatePicker, Divider, Empty, Input, Pagination, Popover, Row, Select, Slider, Typography } from 'antd';

import moment from "moment";
import "./auctionlot.scss";
import ArtworkCard from "../../Collections/ArtworkCard/ArtworkCard";
import useAuctionLot from "./useAuctionLot";
import { includes, isEmpty, isNil } from "lodash";
import Root from "../../Root/Root";
import Topbar from "../../../components/Topbar/Topbar";

const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;
const { Title } = Typography;
const AuctionLot = ({ match }) => {

    const { auctionLotObj, filterObject, priceMin, priceMax, selectedArtworkStockId,
        artworksListLoader, savedArtworksList, savedArtworkListCount, isApiCalling,

        artistsList, onSelectArtwork, onChangeArtistSelect, onChangeFilterInput, getSearchFilteredProducts,
        onClickCancelAuctionLot, onSelectPageNumber, onClickNewSaveAuctionLot
    } = useAuctionLot(match);

    const ArtworkList = memo(() => {
        return (
            <>
                <Title level={4}>Select Artwork</Title>
                <div className={`filter-artworks-wrapper ${artworksListLoader ? "filterWrapper-loader" : ""}`}>
                    {!isEmpty(savedArtworksList) ? (
                        savedArtworksList.map((art, index) => (
                            <ArtworkCard
                                data={art}
                                key={index}
                                onClickArtwork={() => onSelectArtwork(art)}
                                isSelected={includes(selectedArtworkStockId, art.stockId)}
                            />
                        ))
                    ) : (
                        <Empty description="No artworks found" />
                    )}
                </div>
                {
                    !artworksListLoader && (
                        <Pagination
                            current={parseInt(filterObject.PageNum)}
                            total={savedArtworkListCount}
                            pageSize={10}
                            onChange={onSelectPageNumber}

                        />
                    )
                }
            </>
        )
    }, [savedArtworksList]);

    return (
        <Root>
            <Topbar title={"Auction lot"} showBreadCrumbs showHead />
            <div id="add-auction-lot-id">
                <Title level={4}>Auction Lot</Title>

                {!isNil(auctionLotObj) && isEmpty(auctionLotObj._id) &&
                    (<>
                        <div className="auction-search-artwork">
                            <Title level={4}>Search artworks</Title>
                            <Row gutter={[16, 32]}>
                                <Col span={18}>
                                    <Input
                                        height={5}
                                        placeholder="WHAT YOU ARE LOOKING FOR?"
                                        type="text"
                                        value={filterObject.ProductName}
                                        name="ProductName"
                                        onChange={onChangeFilterInput}
                                    />
                                </Col>
                                <Col span={4}>
                                    <Button className="searchButton" loading={artworksListLoader} onClick={getSearchFilteredProducts} block>SEARCH</Button>
                                </Col>
                            </Row>
                            <Row className="" gutter={[16, 32]}>
                                <Col className="gutter-row" span={6}>
                                    <Select
                                        mode="multiple"
                                        showSearch
                                        placeholder="Select Artwork Status"
                                        optionFilterProp="children"
                                        style={{ width: "100%" }}
                                        value={filterObject.status}
                                        filterOption={(input, option) => {
                                            return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }}
                                        onChange={(values) => onChangeArtistSelect(values, "status")}
                                    >

                                        {/* <Option value={0}>ALL</Option> */}
                                        <Option value={1}>Available</Option>
                                        <Option value={2}>Available Resell</Option>
                                        <Option value={16}>Reserved For Auction</Option>

                                    </Select>

                                </Col>

                                <Col className="gutter-row" span={6}>
                                    <Select
                                        mode="multiple"
                                        showSearch
                                        placeholder="Select a Artist"
                                        style={{ width: "100%" }}
                                        maxTagCount={"responsive"}
                                        value={filterObject.ArtistId}
                                        optionFilterProp="children"
                                        filterOption={(input, option) => {
                                            return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        }
                                        onChange={(values) => onChangeArtistSelect(values, "ArtistId")}
                                    >
                                        {artistsList && artistsList.artistList && artistsList.artistList.map(s => {
                                            return (
                                                <Option value={s.userId}>{s.name}</Option>
                                            )
                                        })}
                                    </Select>
                                </Col>

                                <Col className="gutter-row" span={4}>
                                    <Popover placement="bottom" title={"Price"}
                                        content={
                                            <Slider onChange={(values) => onChangeArtistSelect(values, "PriceRange")} value={[filterObject.PriceMin, filterObject.PriceMax]} range min={priceMin} max={priceMax} defaultValue={[priceMin, priceMax]} />
                                        }
                                        trigger="click">
                                        <Button style={{ width: "100%" }}>Price</Button>
                                    </Popover>
                                </Col>

                                <Col className="gutter-row" span={6}>
                                    <Select
                                        showSearch
                                        placeholder="Select a sort option"
                                        optionFilterProp="children"
                                        style={{ width: "100%" }}
                                        onChange={(values) => onChangeArtistSelect(values, "Sort")}
                                    >
                                        <Option value="0">Price (Low to High)</Option>
                                        <Option value="1">Price (High to Low)</Option>
                                        <Option value="2">Popularity</Option>
                                    </Select>
                                </Col>

                            </Row>
                        </div>
                        <div className="search-artwork-result">
                            <ArtworkList />
                        </div>
                    </>
                    )}

                <div className="add-lot-id">
                    <Row className="flx-grw-row save-lot-btn">
                        <Button onClick={onClickCancelAuctionLot}>Cancel</Button>
                        <Button loading={isApiCalling} className="searchButton" onClick={onClickNewSaveAuctionLot}>Submit</Button>
                    </Row>
                </div>


            </div>
        </Root>
    )
}

export default AuctionLot;