import { APIUrlConstants } from "../../util/apiUrl.constant";
import { APIService } from "../../util/api.service";

export class CountriesTaxService {
    // All Country List
    static getAllCountryList() {
        let url = APIUrlConstants.getApiUrl("getCountriesList");
        return APIService.doGet(url).then(resp => {
            if (resp) {
                return resp;
            }
            throw resp;
        });
    }

    // Country Tax List
    static getCountryTaxList(pageNum, pageSize, countryName) {
        if (pageNum && pageSize && countryName) {
            let url = APIUrlConstants.getApiUrl("getAllTaxList") + "?PageNum=" + pageNum + "&PageSize=" + pageSize + "&CountryName=" + countryName;
            return APIService.doGet(url).then(resp => {
                if (resp) {
                    return resp;
                }
                throw resp;
            });
        }
        else {
            let url = APIUrlConstants.getApiUrl("getAllTaxList") + "?PageNum=" + pageNum + "&PageSize=" + pageSize;
            return APIService.doGet(url).then(resp => {
                if (resp) {
                    return resp;
                }
                throw resp;
            });
        }
    }

    // Add Country Tax
    static addCountryTax(body) {
        let url = APIUrlConstants.getApiUrl("addTax");
        return APIService.doPost(url, body).then(resp => {
            if (resp) {
                return resp;
            }
            throw resp;
        });
    }

    // Update Country Tax
    static updateCountryTax(body, id) {
        let url = APIUrlConstants.getApiUrl("updateTax") + "/" + id;
        return APIService.doPut(url, body).then(resp => {
            if (resp) {
                return resp;
            }
            throw resp;
        });
    }

    // Delete Country Tax
    static deleteCountryTax(id) {
        let url = APIUrlConstants.getApiUrl("deleteTax") + "/" + id;
        return APIService.doDelete(url).then(resp => {
            if (resp) {
                return resp;
            }
            throw resp;
        });
    }
}
