import { Popover } from "antd";
import React from "react";
import ReactTable from "../ReactTable/ReactTable";
import AddBlogTopics from "./AddBlogTopics/AddBlogTopics";
import { AddTopicButton, BlogTopicButtonContainer, BlogTopicInput, BlogTopicItem, BlogTopicsItemContainer, BlogTopicWrapper, CancelButton, TopicButtonContainer, TopicLable } from "./BlogTopics.styled";
import useBlogTopics from "./useBlogTopics";
const BlogTopics = ({ location, history }) => {
    const { openAddTopicModal, topicList, addTopicLoader, loader, topicState, filterState, updateFilterState,
        setOpenAddTopicModal, addBlogTopics, onHandleChangeInput, onClickEditTopic,
        deleteBlogTopics, onClickCancelAddTopic } = useBlogTopics(location, history);

    const content = (record) => (
        <div>
            <div className="action-span" onClick={() => onClickEditTopic(record)}>
                <img src={require("../../../../assets/images/common/Edit_New.svg")} alt="edit" />
                <p className="pop-item">Edit</p>
            </div>
            <div className="action-span" onClick={() => deleteBlogTopics(record)} >
                <img src={require("../../../../assets/images/common/delete_active.svg")} alt="edit" />
                <p className="pop-item">Delete</p>
            </div>
        </div>
    );

    const columns = React.useMemo(
        () => [
            {
                Header: 'TOPIC',
                accessor: 'topic',
                filter: 'fuzzyText',
                filterable: true,
            },
            {
                Header: 'SLUG_ID',
                accessor: 'slugId',
                // Use our custom `fuzzyText` filter on this column
                filter: 'fuzzyText',
                filterable: true,
            },
            {
                Header: 'SEO TITLE',
                accessor: 'seoTitle',
                // Use our custom `fuzzyText` filter on this column
                filter: 'fuzzyText',
                filterable: true,
            },
            {
                Header: 'SEO DESCRIPTION',
                accessor: 'seoDescription',
                // Use our custom `fuzzyText` filter on this column
                filter: 'fuzzyText',
                filterable: true,
            },
            {
                Header: 'ACTION',
                filterable: false,
                Cell: ({ cell: { row } }) => (
                    < Popover placement="bottom" content={content(row && row.original)} overlayClassName="manage-artworks-action-popover" >
                        <img className="action-image" src={require("../../../../assets/images/common/artworksActionIcon.svg")} alt="action" />
                    </Popover >
                )
            },
        ],
        []
    );

    return (
        <>
            <BlogTopicWrapper>
                <BlogTopicButtonContainer>
                    <AddTopicButton onClick={() => setOpenAddTopicModal(!openAddTopicModal)}>ADD TOPIC</AddTopicButton>
                </BlogTopicButtonContainer>
                {loader === false &&
                    <ReactTable
                        columns={columns}
                        data={topicList}
                        loading={loader}
                        filterState={filterState}
                        updateFilterState={updateFilterState}
                    />
                }

            </BlogTopicWrapper>
            <AddBlogTopics SaveTopic={addBlogTopics} onClickCancelAddTopic={onClickCancelAddTopic}
                onHandleChangeInput={onHandleChangeInput} openAddTopicModal={openAddTopicModal}
                addTopicLoader={addTopicLoader} topicState={topicState} />
        </>
    )
}

export default BlogTopics;