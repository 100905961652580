import { APIService } from "../../util/api.service";
import React, { useState } from "react";
import { APIUrlConstants } from "../../util/apiUrl.constant";

const usePageAnalytics = () => {
	const [analyticsData, setAnalyticsData] = useState([]);
	const [totalViewCount, setTotalViewCount] = useState(0);
	const pageNameFilter = [
		{
			text: "HOME-PAGE",
			value: "HOME-PAGE",
		},
		{
			text: "SURVEY",
			value: "SURVEY",
		},
		{
			text: "ARTIST-LIST-PAGE",
			value: "ARTIST-LIST-PAGE",
		},
		{
			text: "ARTIST-DETAIL-PAGE",
			value: "ARTIST-DETAIL-PAGE",
		},
		{
			text: "ARTWORK-DISCOVER-PAGE",
			value: "ARTWORK-DISCOVER-PAGE",
		},
		{
			text: "COLLECTION-LIST",
			value: "COLLECTION-LIST",
		},
		{
			text: "COLLECTION-DETAILS",
			value: "COLLECTION-DETAILS",
		},
		{
			text: "ARTWORK-DETAILS",
			value: "ARTWORK-DETAILS",
		},
		{
			text: "BLOG-LIST",
			value: "BLOG-LIST",
		},
		{
			text: "SURVEY",
			value: "SURVEY",
		},
		{
			text: "AUCTION-LIS",
			value: "AUCTION-LIS",
		},
		{
			text: "AUCTION-DETAILS",
			value: "AUCTION-DETAILS",
		},
		{
			text: "OTHER-PAGES",
			value: "OTHER-PAGES",
		},
	];
	const getAnalyticsDetails = () => {
		let url = APIUrlConstants.getApiUrl("getAnalytics");
		let dataObj = {
			isCount: false,
			PageNum: 1,
			PageSize: 1000000000,
		};
		APIService.doPost(url, dataObj).then((res) => {
			if (res && res.result === 200) {
				setAnalyticsData(res.data && res.data.logs);
				setTotalViewCount(res.data && res.data.count);
			}
		});
	};
	return {
		analyticsData,
		totalViewCount,
		pageNameFilter,
		getAnalyticsDetails,
	};
};
export default usePageAnalytics;
