import React from "react";
import { ProfileSectionTabWrapper, ProfileSectionTabHeader, ProfileSectionTabBody, ProfileSectionTabButton } from "./ProfileSectionTab.styled";
import { Empty } from "antd";
import assets from "../../../../../assets";
import { AiOutlineEdit, AiOutlinePlus } from "react-icons/ai";

/**
 * @param title String
 * @param loader Boolean For loader
 * @param notEmpty Boolean The empty case check [If false, will show no data found]
 * @param showAddButton Boolean Add Button
 * @function onClickAddNew Add button click function
 **/
const ProfileSectionTab = ({
	title,
	children,
	loader = false,
	notEmpty = true,
	showAddButton,
	onClickAddNew,
	isEdit = false,
	wrapperStyles = {},
	bodyWrapperStyles = {},
	customButton = null,
}) => {


	return (
		<ProfileSectionTabWrapper wrapperStyles={wrapperStyles}>
			{/* Title */}
			<ProfileSectionTabHeader>{title}</ProfileSectionTabHeader>

			{/* Add Button */}
			{showAddButton &&
				(customButton ? (
					customButton()
				) : (
					<ProfileSectionTabButton variant="invert" onClick={onClickAddNew}>
						{isEdit ? <AiOutlineEdit /> : <AiOutlinePlus />}
						{"Add"} {title}
					</ProfileSectionTabButton>
				))}

			{/* Body */}
			{loader ? (
				//    LOAD
				<Empty description="Fetching data..." className="no-search-image" image={assets.images.searchNoFound} />
			) : notEmpty ? (
				//    CONTENT
				<ProfileSectionTabBody bodyWrapperStyles={bodyWrapperStyles}>{children}</ProfileSectionTabBody>
			) : (
				//    EMPTY
				<Empty description={`No ${title || "data"} found.`} className="no-search-image" image={assets.images.searchNoFound} />
			)}
		</ProfileSectionTabWrapper>
	);
};

export default ProfileSectionTab;
