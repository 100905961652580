import React, { useState } from "react";
import { Popover, Slider } from "antd";
import "./FilterSlider.scss";

const FilterSlider = ({ filterFor, title, defaultMinValue, defaultMaxValue, minValue, maxValue, returnBackPriceObj }) => {
	const onChangeSliderValue = e => {
		returnBackPriceObj(e[0], e[1]);
	};

	const popoverSliderContent = (
		<div className="popover-slider">
			<div className="slider-title">
				<span>{title}</span>
				<p>{`${(+minValue).toLocaleString()} - ${(+maxValue).toLocaleString()}`}</p>
			</div>
			<Slider className="psuedo-dropdown-slider" range min={defaultMinValue} max={defaultMaxValue} value={[+minValue, +maxValue]} onChange={e => onChangeSliderValue(e)} />
		</div>
	);

	const [visible, setPopoverVisibility] = useState(false);

	return (
		<Popover
			content={popoverSliderContent}
			trigger="click"
			placement="bottom"
			visible={visible}
			overlayClassName="filter-dropdown-overlay"
			onVisibleChange={value => setPopoverVisibility(value)}
		>
			<div className="psuedo-dropdown psuedo-dropdown-slider-wrap">
				{filterFor}
				<div className="psuedo-dropdown-icon" type="down" />
			</div>
		</Popover>
	);
};

export default FilterSlider;
