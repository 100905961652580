import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { popToast } from "../../../../components/Toast/Toast";
import { Util } from "../../../../util/util";
import { BlogService } from "../Blog.service";

const useBlogTopics = (location, history) => {

    const [openAddTopicModal, setOpenAddTopicModal] = useState(false);
    const [topicList, setTopicList] = useState([]);
    const [addTopicLoader, setAddTopicLoader] = useState(false);
    const [loader, setLoader] = useState(false);

    const [topicState, setTopicState] = useState({
        topic: "",
        slugId: "",
        seoTitle: "",
        seoDescription: "",
        _id: ""
    })

    const [filterState, setFilterState] = useState({
        topic: "",
        slugId: "",
        seoTitle: "",
        seoDescription: "",
        PageNo: "",
        pageSize: "",

    })

    useEffect(() => {
        let parsedQuery = Util.queryStringHandling(location.search, "PARSE");
        if (parsedQuery) {
            setFilterState({
                topic: parsedQuery.topic,
                slugId: parsedQuery.slugId,
                seoTitle: parsedQuery.seoTitle,
                seoDescription: parsedQuery.seoDescription,
                PageNo: parseInt(parsedQuery.PageNo),
                pageSize: parsedQuery.pageSize
            })
        }
    }, [location]);

    const updateFilterState = (name, value) => {
        let newFilterState = { ...filterState };
        newFilterState[name] = value;
        setFilterState(filterState => [{ ...filterState, [name]: value }])
        const queryString = Util.queryStringHandling(newFilterState, "STR");
        history.push('/blog/3?' + queryString);
    }

    useEffect(() => {
        getBlogTopics();
    }, []);

    const onHandleChangeInput = (e) => {
        const { value, name } = e.target;
        setTopicState(topicState => ({ ...topicState, [name]: value }));
        if (name === "topic") {
            setTopicState(topicState => ({ ...topicState, slugId: convertToSlug(value) }));
        }
    }

    const convertToSlug = (Text) => {
        return Text.toLowerCase()
            .replace(/[^\w ]+/g, "")
            .replace(/ +/g, "-");
    };

    const getBlogTopics = async () => {
        setLoader(true);
        const topicsResp = await BlogService.getBlogTopics(0, 0);
        if (topicsResp && topicsResp.data) {
            setTopicList(topicsResp.data.topicList);
        }
        setLoader(false);
    }

    const addBlogTopics = async (topic) => {
        try {
            if (isValidFormField()) {
                setAddTopicLoader(true);
                let topicsResp;
                if (isNullOrEmpty(topic._id)) {
                    topicsResp = await BlogService.addBlogTopic(topic);
                }
                else {
                    topicsResp = await BlogService.updateBlogTopic(topic, topic._id);
                }
                if (topicsResp && topicsResp.result === 200) {
                    setOpenAddTopicModal(false);
                    await getBlogTopics();
                    onClickCancelAddTopic();
                } else if (topicsResp && topicsResp.result === 400) {
                    popToast.show(topicsResp.message);
                } else if (topicsResp && topicsResp.result === 500) {
                    popToast.show(topicsResp.message);
                }
                setAddTopicLoader(false);
            }
        } catch (error) {
            setAddTopicLoader(false);
        }

    }

    const isValidFormField = () => {
        if (isNullOrEmpty(topicState.topic)) {
            popToast.show("Please enter Topic Name");
            return false;
        } else if (isNullOrEmpty(topicState.slugId)) {
            popToast.show("Slug Id cannot be empty");
            return false;
        } else if (isNullOrEmpty(topicState.seoTitle)) {
            popToast.show("SEO Title cannot be empty");
            return false;
        }
        return true;
    }

    const isNullOrEmpty = (obj) => {
        if (obj === "" || obj === undefined || obj === null) {
            return true;
        }
        return false;
    };

    const deleteBlogTopics = async (topic) => {
        const topicsResp = await BlogService.deleteBlogTopic(topic._id);
        if (topicsResp && topicsResp.result === 200) {
            await getBlogTopics();
        } else if (topicsResp && topicsResp.result === 400) {
            popToast.show(topicsResp.message);
        } else if (topicsResp && topicsResp.result === 500) {
            popToast.show(topicsResp.message);
        }
    }

    const onClickCancelAddTopic = () => {
        setOpenAddTopicModal(false);
        setTopicState({
            topic: "",
            slugId: "",
            seoTitle: "",
            seoDescription: ""
        })
    }

    const onClickEditTopic = (record) => {
        setOpenAddTopicModal(true);
        setTopicState(record)
    }

    return {
        openAddTopicModal,
        topicList,
        addTopicLoader,
        setOpenAddTopicModal,
        onClickEditTopic,
        filterState,
        updateFilterState,
        addBlogTopics,
        deleteBlogTopics,
        loader,
        topicState,
        onClickCancelAddTopic,
        onHandleChangeInput

    }
}

export default useBlogTopics;