import React from 'react';
import { Popconfirm } from 'antd';
import './ConfirmPopup.css';

/**
 * HOW TO USE: Wrap this functional component around the element you want a popup for
 * @param text string for the confirmation text
 * @param image string url of image
 * @param buttonText string for text in button
 * @param data some data to pass through delete confirm function
 * @param confirmAction function that triggers when clicked ok button
 */
const ConfirmPopup = ({
    text = "Are you sure?",
    image = null,
    buttonText = 'OK',
    data = null,
    confirmAction,
    children
}) => {

    return (
        <Popconfirm
            overlayClassName="confirm-popup-overlay"
            title={text}
            okText={buttonText}
            icon={image ? <img src={image} alt="pop" /> : null}
            onConfirm={() => confirmAction(data)}
        >
            {children}
        </Popconfirm>

    );
}

export default ConfirmPopup;

