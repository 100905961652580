import { APIUrlConstants } from "../../util/apiUrl.constant";
import { APIService } from "../../util/api.service";

export class NotificationService {
	static getNotificationsList(updateData) {
		let url = APIUrlConstants.getApiUrl("getNotifications") + "?IsAdmin=true";
		return APIService.doGet(url).then(resp => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}
}
