import { APIUrlConstants } from "../../util/apiUrl.constant";
import { APIService } from "../../util/api.service";

export class ShipmentProviderService {

    // Shipment Provider List
    static getShipmentProviderList(pageNum, pageSize, providerName) {
        if (providerName) {
            let url = APIUrlConstants.getApiUrl("getAllShipmentProviders") + "?PageNum=" + pageNum + "&PageSize=" + pageSize + "&SearchString=" + providerName;
            return APIService.doGet(url).then(resp => {
                if (resp) {
                    return resp;
                }
                throw resp;
            });
        }
        else {
            let url = APIUrlConstants.getApiUrl("getAllShipmentProviders") + "?PageNum=" + pageNum + "&PageSize=" + pageSize;
            return APIService.doGet(url).then(resp => {
                if (resp) {
                    return resp;
                }
                throw resp;
            });
        }
    }

    // Add Shipment Provider
    static addShipmentProvider(body) {
        let url = APIUrlConstants.getApiUrl("addShipmentProvider");
        return APIService.doPost(url, body).then(resp => {
            if (resp) {
                return resp;
            }
            throw resp;
        });
    }

    // Update Shipment Provider
    static updateShipmentProvider(body, id) {
        let url = APIUrlConstants.getApiUrl("updateShipmentProvider") + "/" + id;
        return APIService.doPut(url, body).then(resp => {
            if (resp) {
                return resp;
            }
            throw resp;
        });
    }

    // Delete Shipment Provider
    static deleteShipmentProvider(id) {
        let url = APIUrlConstants.getApiUrl("deleteShipmentProvider") + "/" + id;
        return APIService.doDelete(url).then(resp => {
            if (resp) {
                return resp;
            }
            throw resp;
        });
    }
}
