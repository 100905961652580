import React, { useMemo, useRef } from "react";
import useAddBlogArticles from "./useAddBlogArticles";
import { Editor } from "react-draft-wysiwyg";
import { SelectTopic } from "./AddBlogArticles.styled";
import { AutoComplete, Button, Modal, Select, Spin } from "antd";

const { Option } = Select;

const AddBlogArticles = ({ openAddArticleModal, onClickCancelAddArticle, getBlogs, editRecord, pageIndex, pageSize }) => {
	const attach_media = useRef();
	const {
		articleState,
		topicValues,
		addArticleLoader,
		authorSearchData,
		fetchingAuthorSearch,
		fetchUser,
		onHandleChangeInput,
		onImageSelect,
		AddArticle,
		uploadImageCallBack,
		onEditorStateChange,
		onCancelArticle,
		onHandleAuthorChange,
	} = useAddBlogArticles(getBlogs, editRecord, onClickCancelAddArticle, pageIndex, pageSize);

	return (
		<Modal title={"Add Blog Articles"} visible={openAddArticleModal} className="editor-modal-dialog" footer={false} onCancel={onCancelArticle}>
			<div className="editor-modal-content">
				{articleState && (
					<div className="editor-main-wrapper">
						{articleState.thumbnail ? (
							<label className="upload-box bg-white" onClick={(e) => attach_media.current.click()}>
								<img className="media-thumb" src={articleState.thumbnail} alt="Attach media" />
							</label>
						) : (
							<div className="upload-box" onClick={(e) => attach_media.current.click()}>
								<img className="image-upload" src={require("../../../../../assets/images/common/AddPhoto.svg")} alt="Upload" />
								Upload Image
							</div>
						)}
						<input
							type="file"
							style={{ display: "none" }}
							accept=".bmp, .gif, .ico, .jpeg, .jpg, .png, .svg, .tif, .tiff, .jiff, .jfif, .pjpeg, .pjp"
							onChange={(e) => onImageSelect(e)}
							ref={attach_media}
						/>
						<div className="form-control">
							<label className="form-label">Title*</label>
							<input
								type="text"
								name="title"
								autoFocus
								className="form-input"
								value={articleState.title}
								onChange={onHandleChangeInput}
							/>
						</div>

						<div className="form-control">
							<label className="form-label">Author*</label>
							{/* <input type="text" name="bloggerName" className="form-input" value={articleState.bloggerName} onChange={onHandleChangeInput} /> */}
							<AutoComplete
								dataSource={authorSearchData}
								style={{ width: "100%" }}
								onSelect={onHandleAuthorChange}
								onSearch={fetchUser}
								placeholder="Search author name"
							/>
						</div>

						<div className="form-control">
							<label className="form-label">Topic*</label>
							<SelectTopic
								value={articleState.topicSlugId}
								name="topicSlugId"
								onChange={onHandleChangeInput}
								placeholder="Select Topic"
							>
								<option>Select Topic</option>
								{topicValues &&
									topicValues.map((s, index) => {
										return (
											<option key={s.slugId} value={s.slugId}>
												{s.topic}
											</option>
										);
									})}
							</SelectTopic>
						</div>

						<div className="form-control">
							<label className="form-label">SlugId*</label>
							<input
								disabled={articleState._id !== ""}
								type="text"
								name="slugId"
								className="form-input"
								value={articleState.slugId}
								onChange={onHandleChangeInput}
							/>
						</div>

						<div className="form-control">
							<label className="form-label">Seo Description</label>
							<input
								type="text"
								name="seoDescription"
								className="form-input"
								value={articleState.seoDescription}
								onChange={onHandleChangeInput}
							/>
						</div>

						<Editor
							toolbar={{
								options: ["blockType", "inline", "list", "image"],
								inline: {
									visible: true,
									inDropdown: false,
									options: ["bold", "italic", "underline"],
								},
								image: {
									urlEnabled: true,
									uploadEnabled: false,
									alignmentEnabled: true,
									uploadCallback: uploadImageCallBack,
									previewImage: true,
									inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
									alt: { present: false, mandatory: false },
									defaultSize: {
										height: "auto",
										width: "auto",
									},
								},
								inputAccept:
									"application/pdf,text/plain,application/vnd.openxmlformatsofficedocument.wordprocessingml.document,application/msword,application/vnd.ms-excel",
							}}
							editorState={articleState.editorState}
							onEditorStateChange={onEditorStateChange()}
							editorStyle={{
								height: "800px",
								border: "1px solid #E5E5E5",
								padding: "10px",
								fontFamily: "Times New Roman",
								color: "black",
								fontWeight: "100",
								width: "100%",
							}}
							placeholder="Add some contents here"
						/>

						<div className="ButtonsWrapper">
							<button className="CancelButton" onClick={onCancelArticle}>
								CANCEL
							</button>
							<Button
								loading={addArticleLoader}
								className="PublishButton"
								onClick={() => {
									AddArticle(articleState);
								}}
							>
								PUBLISH
								{/* {addArticleLoader === true && <CircularProgress size={24} className="circular-loader modal-button-loader" />} */}
							</Button>
						</div>
					</div>
				)}
			</div>
		</Modal>
	);
};

export default AddBlogArticles;
