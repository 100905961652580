
import styled, { css } from "styled-components";
import ImgNextGen from "../NextGenImage/ImgNextGen";

const MainImage = styled(ImgNextGen)`
	${props => props.hideimage === "true" && css`display: none;`}
`;

const ImageLoading = styled.img`
	width: 45px;
`;

const ImageError = styled.img`
	width: 45px;
`;

export { MainImage, ImageLoading, ImageError };
