import { APIService } from "../../util/api.service";
import { APIUrlConstants } from "../../util/apiUrl.constant";


export class ManageCouponService {

    static getAllCoupons(searchKey) {
        let url = `${APIUrlConstants.getApiUrl("getAllCoupons")}?FIlterName=${searchKey}&PageNum=1&PageSize=10&CouponCode&CouponName&CouponStatus&CouponStatus`;

        return APIService.doGet(url).then(resp => {
            if (resp) {
                return resp;
            }
            throw resp;
        });
    };

    static addNewCoupons(couponObject) {
        let url = `${APIUrlConstants.getApiUrl("addCoupon")}`;

        return APIService.doPost(url, couponObject).then(resp => {
            if (resp) {
                return resp;
            }
            throw resp;
        });
    };

    static updateCoupons(request, couponId) {
        let url = `${APIUrlConstants.getApiUrl("updateCoupon")}?CouponId=${couponId}`;

        return APIService.doPut(url, request).then(resp => {
            if (resp) {
                return resp;
            }
            throw resp;
        });
    };

    static deleteCoupons(couponId) {
        let url = `${APIUrlConstants.getApiUrl("deleteCoupon")}?CouponId=${couponId}`;

        return APIService.doDelete(url).then(resp => {
            if (resp) {
                return resp;
            }
            throw resp;
        });
    };
}