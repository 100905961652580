import { APIUrlConstants } from "../../util/apiUrl.constant";
import { APIService } from "../../util/api.service";

export class ManageGalleryService {
	static getGalleryList(gallerydata) {

		let url =
			APIUrlConstants.getApiUrl("getGalleryList");
		return APIService.doPost(url, gallerydata).then(resp => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	static setGalleryFeatured(gallerydata) {
		let url = APIUrlConstants.getApiUrl("setGalleryFeatured") + "?galleryId=" + gallerydata.userId + "&IsFeatured=" + gallerydata.featureStatus;
		return APIService.doPut(url).then(resp => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}
}
