import {
	GET_HOME_BANNER_LIST_REQUEST,
	GET_HOME_BANNER_LIST_SUCCESS,
	GET_HOME_BANNER_LIST_FAILURE,
	CREATE_HOME_BANNER_REQUEST,
	CREATE_HOME_BANNER_SUCCESS,
	CREATE_HOME_BANNER_FAILURE,
	DELETE_HOME_BANNER_REQUEST,
	DELETE_HOME_BANNER_SUCCESS,
	DELETE_HOME_BANNER_FAILURE,
	TOGGLE_ADDBANNER_DIALOG,
	UPDATE_HOME_BANNER_REQUEST,
	UPDATE_HOME_BANNER_SUCCESS,
	UPDATE_HOME_BANNER_FAILURE
} from "../actions/ActionsTypes";

const initialState = {
	homeBannerListLoader: false,
	homeBannerList: [],
	createBannerLoader: false,
	createBannerData: null,
	deleteBannerLoader: false,
	deleteBannerData: null,
	addBannerDialog: false,
	updateBannerLoader: false,
	updateBannerData: null
};

const manageHomeReducer = (state = initialState, action = {}) => {
	switch (action.type) {
		// ======= Get Home Banner List ====== //
		case GET_HOME_BANNER_LIST_REQUEST:
			state = {
				...state,
				homeBannerListLoader: true,
				homeBannerList: []
			};
			return state;

		case GET_HOME_BANNER_LIST_SUCCESS:
			state = {
				...state,
				homeBannerListLoader: false,
				homeBannerList: action.data
			};
			return state;

		case GET_HOME_BANNER_LIST_FAILURE:
			state = {
				...state,
				homeBannerListLoader: false,
				homeBannerList: []
			};
			return state;

		// ======= Add Home Banner ====== //
		case CREATE_HOME_BANNER_REQUEST:
			state = {
				...state,
				createBannerLoader: true
			};
			return state;

		case CREATE_HOME_BANNER_SUCCESS:
			state = {
				...state,
				createBannerLoader: false,
				addBannerDialog: false,
				createBannerData: action.data
			};
			return state;

		case CREATE_HOME_BANNER_FAILURE:
			state = {
				...state,
				createBannerLoader: false,
				addBannerDialog: true,
				createBannerData: null
			};
			return state;

		// ======= Update Home Banner ====== //
		case UPDATE_HOME_BANNER_REQUEST:
			state = {
				...state,
				updateBannerLoader: true
			};
			return state;

		case UPDATE_HOME_BANNER_SUCCESS:
			state = {
				...state,
				updateBannerLoader: false,
				addBannerDialog: false,
				updateBannerData: action.data
			};
			return state;

		case UPDATE_HOME_BANNER_FAILURE:
			state = {
				...state,
				updateBannerLoader: false,
				addBannerDialog: true,
				updateBannerData: null
			};
			return state;

		// ======= Delete Home Banner ====== //
		case DELETE_HOME_BANNER_REQUEST:
			state = {
				...state,
				deleteBannerLoader: true
			};
			return state;

		case DELETE_HOME_BANNER_SUCCESS:
			state = {
				...state,
				deleteBannerLoader: false,
				deleteBannerData: action.data
			};
			return state;

		case DELETE_HOME_BANNER_FAILURE:
			state = {
				...state,
				deleteBannerLoader: false,
				deleteBannerData: null
			};
			return state;
		// --------- Toggle Dialog's --------- //
		case TOGGLE_ADDBANNER_DIALOG:
			state = {
				...state,
				addBannerDialog: action.data
			};
			return state;

		default:
			return state;
	}
};

export default manageHomeReducer;
