import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import { DialogTitle, DialogContent, CircularProgress } from "@material-ui/core";

// Components
import { popToast } from "../../../components/Toast/Toast";
// Redux
import { connect } from "react-redux";
// SCSS
import "./AddCountryTaxDialog.scss";
import { addCountryTaxAction, updateCountryTaxAction } from "../../../redux/actions/CountriesTaxAction";
import { get } from "lodash";

class AddCountryTaxDialog extends Component {
	constructor(props) {
		super(props);
		this.state = {
			countryName: "",
			tax: '0',
			customs: '0',
			otherTax: '0',
			vat: '0'
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if (!prevProps.open && this.props.open) {
			const updateData = this.props.updateData;
			this.setState({
				countryName: (updateData && updateData.country) || "",
				tax: (updateData && updateData.taxPercentage) || '0',
				customs: (updateData && updateData.importTax && get(updateData.importTax, "customsPercentage")) || '0',
				otherTax: (updateData && updateData.importTax && get(updateData.importTax, "otherTaxpercentage")) || '0',
				vat: (updateData && updateData.importTax && get(updateData.importTax, "vatPercentage")) || '0'
			});
		}
	}

	render() {
		const { addCountryTaxLoader, countriesList } = this.props.countriesTaxReducer;

		return (
			<Dialog
				open={this.props.open}
				onClose={this.props.handleClose}
				BackdropProps={{ classes: { root: "dialog-backdrop" } }}
				className="add-country-tax-dialog"
			>
				<img className="close-btn" onClick={this.props.handleClose} src={require("../../../assets/images/common/Close_h.svg")} alt="" />
				<DialogTitle className="dialog-title">Add Country Tax</DialogTitle>
				<DialogContent className="add-country-tax-content">
					<div className="add-country-tax-body">
						<div className="form-control">
							<label className="form-label">Country Name</label>
							<select
								className="form-input"
								value={this.state.countryName}
								disabled={addCountryTaxLoader}
								onChange={this.onHandleInput("countryName")}
							>
								<option value="" disabled>
									Select
							</option>
								{countriesList && countriesList.length !== 0 &&
									countriesList.map((item, index) => (
										<option key={index} value={item.name}>
											{item.name}
										</option>
									))}
							</select>
						</div>
						<div className="form-control">
							<label className="form-label">Tax(%)</label>
							<input
								type="number"
								min="0"
								autoFocus
								className="form-input"
								value={this.state.tax}
								disabled={addCountryTaxLoader}
								onChange={this.onHandleInput("tax")}
							/>
						</div>
						<div className="form-control">
							<label className="form-label">Customs(%)</label>
							<input
								type="number"
								min="0"
								className="form-input"
								value={this.state.customs}
								disabled={addCountryTaxLoader}
								onChange={this.onHandleInput("customs")}
							/>
						</div>
						<div className="form-control">
							<label className="form-label">Other Tax(%)</label>
							<input
								type="number"
								min="0"
								className="form-input"
								value={this.state.otherTax}
								disabled={addCountryTaxLoader}
								onChange={this.onHandleInput("otherTax")}
							/>
						</div>
						<div className="form-control">
							<label className="form-label">VAT(%)</label>
							<input
								type="number"
								min="0"
								className="form-input"
								value={this.state.vat}
								disabled={addCountryTaxLoader}
								onChange={this.onHandleInput("vat")}
							/>
						</div>
					</div>

					<div className="add-country-tax-action">
						<button className="btn-cancel" onClick={this.onClickCancel.bind(this)}>
							Cancel
						</button>
						<button className="btn-orange" onClick={this.onClickCreate.bind(this)} disabled={addCountryTaxLoader}>
							{this.props.updateData ? "Update" : "Save"}
							{addCountryTaxLoader && <CircularProgress size={24} className="circular-loader modal-button-loader" />}
						</button>
					</div>
				</DialogContent>
			</Dialog>
		);
	}

	// Handle inputs
	onHandleInput = name => event => {
		this.setState({
			[name]: event.target.value
		});
	};

	isFormValid() {
		if (!this.state.countryName.trim()) {
			popToast.show("Please enter a country name");
			return false;
		}
		if (!this.state.tax || +this.state.tax < 0) {
			popToast.show("Please enter tax");
			return false;
		}
		if (!this.state.customs || +this.state.customs < 0) {
			popToast.show("Please enter customs tax");
			return false;
		}
		if (!this.state.otherTax || +this.state.otherTax < 0) {
			popToast.show("Please enter other tax");
			return false;
		}
		if (!this.state.vat || +this.state.vat < 0) {
			popToast.show("Please enter VAT");
			return false;
		}
		return true;
	}

	onClickCreate() {
		// For main category, parentId is hardcoded
		if (this.isFormValid()) {
			let request = {
				Country: this.state.countryName,
				TaxPercentage: this.state.tax,
				ImportTax: {
					CustomsPercentage: this.state.customs,
					OtherTaxpercentage: this.state.otherTax,
					VATPercentage: this.state.vat
				}
			}
			this.props.updateData ? this.props.updateCountryTaxAction(request, this.props.updateData._id) : this.props.addCountryTaxAction(request);
		}
	}

	onClickCancel() {
		this.props.handleClose();
	}
}

const mapStateToProps = state => {
	return {
		countriesTaxReducer: state.countriesTaxReducer
	};
};

const mapDispatchToProps = dispatch => {
	return {
		addCountryTaxAction: (request) => {
			dispatch(addCountryTaxAction(request));
		},
		updateCountryTaxAction: (request, id) => {
			dispatch(updateCountryTaxAction(request, id));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCountryTaxDialog);
