
import React, { useEffect, useRef } from "react";
import { useMemo } from "react";
import { ButtonContainerDiv, CancelButton, FormGroup, ORText, RemoveButton, SaveButton, TagInfo, UploadedVideoDiv, UploadVideoDiv, UploadVideoHereDiv, VideoAddContent, VideoContent } from "./AddReelsVideos.styled";
import AsyncSelect from 'react-select/async';
import { useState } from "react";
import { includes, isEmpty } from "lodash";
import { ReelsVideoService } from "../ReelsVideoService";
import { Dialog, DialogContent, DialogTitle, Slide } from "@material-ui/core";
import { Form, Tag } from "antd";
import { TagsInput } from "react-tag-input-component";
import { CollectionService } from "../../Collections/Collection.service";
import { popToast } from "../../../components/Toast/Toast";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const AddReelsVideos = ({ show, handleClose, editData, artistSlugId, setShowUploadProcessingWarning }) => {
    const attach_video_thumbnail = useRef(null);
    const videoEl = useRef(null);
    const [, setImageLoader] = useState(false);
    const [imagesArray, setImgArr] = useState();
    const [saveLoader, setSaveLoader] = useState(false);
    const [validated, setValidated] = useState(false);
    const [artistList, setArtistList] = useState([]);
    const [videoObj, setVideoObj] = useState({
        title: "",
        description: "",
        tags: [],
        video: "",
        artworkLink: "",
        videoDuration: "",
        redirectionLabel: "",
        redirectionPageName: "",//Values : ARTIST_PROFILE, ARTWORK_DETAIL
        redirectionSlugId: ""

    })

    useEffect(() => {
        getArtistList();
    }, [])

    useEffect(() => {
        if (editData) {
            setVideoObj({ ...editData, tags: editData.tags ? editData.tags.split(',') : [] });
        }
        else {
            setVideoObj({
                title: "",
                description: "",
                tags: [],
                video: "",
                videoDuration: "",
                redirectionLabel: "",
                redirectionPageName: "",
                redirectionSlugId: ""

            });
            setImgArr();
        }
    }, [editData, show]);

    const getArtistList = async () => {

        let artistResponse = await CollectionService.getArtists();
        if (artistResponse && artistResponse.data) {
            setArtistList(artistResponse.data.artistList);
        }
    }

    const body = () => {

        const handleLoadedMetadata = () => {
            const video = videoEl.current;
            if (!video) return;
            setVideoObj(videoObj => ({ ...videoObj, videoDuration: video.duration.toFixed() }))
        };

        const loadOptions = async (inputValue) => {
            if (inputValue) {
                let request = {
                    "productName": inputValue,
                    "ArtistSlugId": [],
                    "productSize": [],
                    "orientation": [],
                    "style": [],
                    "subject": [],
                    "packaging": [],
                    "materialsUsed": [],
                    "medium": [],
                    "panel": [],
                    "framed": [],
                    "limitedEdition": [],
                    "Sort": 2,
                    "PageNum": 1,
                    "IsFeatured": false,
                    "IsLatest": false,
                    "CategorySlugId": "",
                    "Satus": 0,
                    "PageSize": 50
                }

                const searchResponse = await ReelsVideoService.SearchProducts(request);
                if (searchResponse && searchResponse.data && !isEmpty(searchResponse.data.productList)) {
                    return searchResponse.data.productList.map((s, index) => ({ value: `${s.medium && s.medium.slugId}/${s.slugId}`, label: s.productName }))
                }
            }
        };

        const generateKey = (pre = "media") => {
            return `${pre}_${new Date().getTime()}_${Math.random().toString(36).substr(2, 9)}`;
        };

        const onImageSelect = async (e, type) => {
            e.preventDefault();
            let reader = new FileReader();
            let file = e.target.files[0];
            e.target.value = null;
            let image = "";
            let img = new Image();
            img.src = window.URL.createObjectURL(file);

            if (file && includes(file.type, "video")) {
                // img.src = assets.images.imageGreyPlace;

                setImageLoader(true);
                image = reader.result;
                reader.onloadend = () => {
                    // Creating a media object
                    let mediaObj = {
                        index: generateKey(),
                        media: file,
                        mediaResult: reader.result,
                    };


                    setImgArr(mediaObj);
                    // setVideoObj(videoObj => ({ ...videoObj, video: mediaObj }))
                    setImageLoader(false);

                };

                reader.readAsDataURL(file);
                // toast(toastMessage.videoFileAdd, "sucess");

            }
        };

        const handleChange = (event) => {
            const { name, value } = event.target;
            setVideoObj(videoObj => ({ ...videoObj, [name]: value }))
        }

        const handleSelectArtwork = (value, action) => {
            if (action.action === "select-option") {
                setVideoObj(videoObj => ({
                    ...videoObj,
                    redirectionSlugId: value.value,
                    redirectionLabel: value.label,
                    redirectionPageName: "ARTWORK_DETAIL"
                }))
            }
        }

        const handleSelectTag = (values) => {
            setVideoObj(videoObj => ({ ...videoObj, tags: values }))
        }

        const isNil = (obj) => obj === "" || obj === undefined || obj === null;

        const isValidForm = () => {
            if (!videoObj.title.trim()) {
                popToast.show('Enter caption.');
                return false;
            }
            if (!videoObj.description.trim()) {
                popToast.show('Enter description.');
                return false;
            }
            if (isNil(imagesArray) && isNil(videoObj.videoClip)) {
                popToast.show('Please upload video file.');
                return false;
            }
            if (videoObj.videoDuration > 90) {
                popToast.show('Video should be less than 60 sec.');
                return false;
            }
            if (!videoObj.redirectionSlugId.trim()) {
                popToast.show('Linking Artist or Artwork is mandatory');
                return false;
            }


            return true;
        }

        const saveReelsVideo = async () => {
            try {
                if (isValidForm()) {
                    let request = {
                        title: videoObj.title,
                        description: videoObj.description,
                        tags: videoObj.tags.join(','),
                        videoDuration: videoObj.videoDuration,
                        video: imagesArray && imagesArray.media,
                        redirectionSlugId: videoObj.redirectionSlugId,
                        redirectionLabel: videoObj.redirectionLabel,
                        redirectionPageName: videoObj.redirectionPageName
                    }

                    if (videoObj._id) {
                        setSaveLoader(true);
                        ReelsVideoService.UpdateReelsVideo(videoObj._id, request).then(updateResponse => {
                            if (updateResponse && updateResponse.result === 200) {
                                handleClose();
                                setVideoObj();
                                setShowUploadProcessingWarning(true)
                            }
                            else if (updateResponse && updateResponse.result === 400) {
                                popToast.show(updateResponse.message);
                            }
                            setSaveLoader(false);
                        }).catch(err => {
                            setSaveLoader(false);
                        })

                    } else {
                        setSaveLoader(true);
                        ReelsVideoService.AddReelsVideo(request).then(saveResponse => {
                            if (saveResponse && saveResponse.result === 200) {
                                handleClose();
                                setVideoObj();
                                setShowUploadProcessingWarning(true);
                            }
                            else if (saveResponse && saveResponse.result === 400) {
                                popToast.show(saveResponse.message);
                            }
                            setSaveLoader(false);
                        }).catch(err => {
                            setSaveLoader(false);
                        })
                    }

                }
            } catch (error) {
                setSaveLoader(false);

            }
        }

        const removeVideo = async () => {
            setVideoObj(videoObj => ({ ...videoObj, video: "", videoClip: "" }));
            setImgArr();
        }

        const handleLinkArtist = (ev) => {
            const { value, name } = ev.target;
            setVideoObj(videoObj => ({
                ...videoObj,
                redirectionSlugId: value,
                redirectionLabel: name,
                redirectionPageName: "ARTIST_PROFILE",
            }));
        }


        return (
            <>
                {videoObj &&
                    <>
                        <Form>
                            <FormGroup>
                                <label>Caption*</label>
                                <input

                                    type="text"
                                    name="title"
                                    value={videoObj.title}
                                    onChange={handleChange}
                                />
                            </FormGroup>
                            <FormGroup >
                                <label>Description*</label>
                                <textarea onChange={handleChange} name="description" maxLength={150} value={videoObj.description} rows={4} />
                            </FormGroup>
                            <FormGroup>
                                <label className="form-label">Link To Artist</label>
                                <select value={videoObj.redirectionSlugId} className="form-input" onChange={handleLinkArtist}>
                                    <option value="">Choose Artist</option>
                                    {artistList && artistList.map((data, index) =>
                                        <option value={data.slugId}>{data.name}</option>
                                    )}

                                </select>
                            </FormGroup>

                            <FormGroup >
                                <ORText>OR</ORText>
                                <label>Link To Artwork</label>
                                <AsyncSelect
                                    // isDisabled={linkToArtist}
                                    isClearable={true} cacheOptions value={{ label: videoObj.redirectionPageName === "ARTWORK_DETAIL" && videoObj.redirectionLabel }} loadOptions={loadOptions} placeholder={"Search artwork"} onChange={handleSelectArtwork} />
                            </FormGroup>

                            <FormGroup >
                                <label>Upload Video*</label>
                                <input
                                    type="file"
                                    style={{ display: "none" }}
                                    accept=".mp4"
                                    onChange={(e) => onImageSelect(e)}
                                    ref={attach_video_thumbnail}
                                />
                                <UploadVideoDiv >
                                    {imagesArray ?
                                        <UploadedVideoDiv >
                                            <VideoAddContent ref={videoEl} onLoadedMetadata={handleLoadedMetadata} src={imagesArray.mediaResult} onClick={(e) => attach_video_thumbnail.current.click()} />
                                            <RemoveButton onClick={removeVideo}>
                                                REMOVE
                                            </RemoveButton>
                                        </UploadedVideoDiv> :
                                        <>
                                            {videoObj.videoClip ?
                                                <UploadedVideoDiv >
                                                    <VideoContent ref={videoEl} onLoadedMetadata={handleLoadedMetadata} src={videoObj.videoClip} onClick={(e) => attach_video_thumbnail.current.click()} />
                                                    <RemoveButton onClick={removeVideo}>
                                                        REMOVE
                                                    </RemoveButton>
                                                </UploadedVideoDiv>

                                                : <UploadVideoHereDiv onClick={(e) => attach_video_thumbnail.current.click()}>
                                                    Upload your video here
                                                </UploadVideoHereDiv>
                                            }
                                        </>
                                    }

                                </UploadVideoDiv>

                            </FormGroup>
                            <FormGroup >
                                <label>Tags</label>

                                <TagsInput
                                    value={videoObj.tags}
                                    onChange={handleSelectTag}
                                    name="tags"
                                    placeHolder="enter tags"
                                />
                                <TagInfo>
                                    Press enter to add new tag
                                </TagInfo>
                            </FormGroup>
                            <ButtonContainerDiv>
                                <CancelButton onClick={() => { handleClose(); setVideoObj(); }} variant="outline-dark">CANCEL</CancelButton>
                                <SaveButton loading={saveLoader} onClick={saveReelsVideo} >
                                    {videoObj._id ? "SAVE CHANGES" : "ADD NEW"}
                                </SaveButton>
                            </ButtonContainerDiv>
                        </Form>

                    </>
                }

            </>

        )
    };

    return (
        <>
            <Dialog
                fullWidth
                open={show}
                scroll="body"
                onClose={handleClose}
                TransitionComponent={Transition}
                BackdropProps={{ classes: { root: "dialog-backdrop" } }}
                className="add-coupon-dialog"
            >
                <img className="close-btn" onClick={() => handleClose(false)} src={require("../../../assets/images/common/Close_h.svg")} alt="" />
                <DialogTitle className="dialog-title">Add Reels</DialogTitle>
                <DialogContent>
                    {body()}
                </DialogContent>
            </Dialog>
        </>
    )
}

export default AddReelsVideos;