import { combineReducers } from "redux";
import localeReducer from "./LocaleReducer";
import loginReducer from "./LoginReducer";
import forgotPasswordReducer from "./ForgotPasswordReducer";
import categoryReducer from "./CategoryReducer";
import manageUsersReducer from "./ManageUsersReducer";
import manageHomeReducer from "./ManageHomeReducer";
import dashboardReducer from "./DashboardReducer";
import addBrandsReducer from "./AddBrandsReducer";
import manageArtworksReducer from "./ManageArtworksReducer";
import manageArtistsReducer from "./ManageArtistsReducer";
import manageGalleryReducer from "./ManageGalleryReducer";
import collectionReducer from "./CollectionReducer";
import ordersReducer from "./OrdersReducer";
import manageSellersReducer from "./ManageSellersReducer";
import notificationReducer from "./NotificationReducer";
import blogReducer from './BlogReducer';
import newsReducer from './NewsReducer';
import editProductReducer from "./EditProductReducer";
import countriesTaxReducer from "./CountriesTaxReducer";
import quotesReducer from "./QuotesReducer";
import shipmentProviderReducer from "./ShipmentProviderReducer";
import shippingChargesReducer from "./ShippingChargesReducer";
import lookupReducer from "./LookupReducer";
import filterRuleReducer from "./FilterReducer";
import manageCouponReducer from "./ManageCouponsReducer";
import auctionReducer from "./AuctionReducer";

export default combineReducers({
	localeReducer,
	loginReducer,
	forgotPasswordReducer,
	categoryReducer,
	manageArtworksReducer,
	manageUsersReducer,
	manageArtistsReducer,
	manageGalleryReducer,
	manageSellersReducer,
	collectionReducer,
	ordersReducer,
	notificationReducer,
	manageHomeReducer,
	dashboardReducer,
	addBrandsReducer,
	blogReducer,
	newsReducer,
	editProductReducer,
	countriesTaxReducer,
	quotesReducer,
	shipmentProviderReducer,
	shippingChargesReducer,
	lookupReducer,
	filterRuleReducer,
	manageCouponReducer,
	auctionReducer,
});
