import {
    GET_ARTISTSLIST_REQUEST,
    GET_ARTISTSLIST_SUCCESS,
    GET_ARTISTSLIST_FAILURE,
    GET_ARTIST_PROFILE_REQUEST,
    GET_ARTIST_PROFILE_SUCCESS,
    GET_ARTIST_PROFILE_FAILURE,
    SET_ARTIST_FEATURED_REQUEST,
    SET_ARTIST_FEATURED_SUCCESS,
    SET_ARTIST_FEATURED_FAILURE,
    GET_ARTIST_DOWNLOAD_REQUEST,
    GET_ARTIST_DOWNLOAD_SUCCESS,
    GET_ARTIST_DOWNLOAD_FAILURE,
    UPDATE_ARTIST_INFO_REQUEST,
    UPDATE_ARTIST_INFO_SUCCESS,
    UPDATE_ARTIST_INFO_FAILURE,
    TOGGLE_ARTIST_INFO_DIALOG
} from "../actions/ActionsTypes";

const initialState = {
    artistsListLoader: false,
    savedArtistsListCount: 0,
    savedArtistsList: [],
    getArtistProfileLoader: false,
    getArtistProfile: [],
    featureLoader: false,
    featureData: null,
    artistsDownloadLoader: false,
    artistsDownloadData: null,
    updateArtistInfoLoader: false,
    updateArtistInfoData: null,
    artistEditDialog: false
};

const manageArtistsReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case GET_ARTISTSLIST_REQUEST:
            return {
                ...state,
                artistsListLoader: true
            };

        case GET_ARTISTSLIST_SUCCESS:
            state = {
                ...state,
                artistsListLoader: false,
                savedArtistsListCount: action.data.count,
                savedArtistsList: action.data.artistList
            };
            return state;

        case GET_ARTISTSLIST_FAILURE:
            return {
                ...state,
                artistsListLoader: false,
                savedArtistsListCount: 0,
                savedArtistsList: []
            };

        case GET_ARTIST_PROFILE_REQUEST:
            return {
                ...state,
                getArtistProfileLoader: true,
                getArtistProfile: []
            };

        case GET_ARTIST_PROFILE_SUCCESS:
            state = {
                ...state,
                getArtistProfileLoader: false,
                getArtistProfile: action.data
            };
            return state;

        case GET_ARTIST_PROFILE_FAILURE:
            return {
                ...state,
                getArtistProfileLoader: false,
                getArtistProfile: []
            };
        case SET_ARTIST_FEATURED_REQUEST:
            return {
                ...state,
                featureLoader: true
            };

        case SET_ARTIST_FEATURED_SUCCESS:
            state = {
                ...state,
                featureLoader: false,
                featureData: action.data
            };
            return state;

        case SET_ARTIST_FEATURED_FAILURE:
            return {
                ...state,
                featureLoader: false,
                featureData: null
            };

        case GET_ARTIST_DOWNLOAD_REQUEST:
            return {
                ...state,
                artistsDownloadLoader: true
            };

        case GET_ARTIST_DOWNLOAD_SUCCESS:
            state = {
                ...state,
                artistsDownloadLoader: false,
                artistsDownloadData: action.data
            };
            return state;

        case GET_ARTIST_DOWNLOAD_FAILURE:
            return {
                ...state,
                artistsDownloadLoader: false,
                artistsDownloadData: null
            };

        case UPDATE_ARTIST_INFO_REQUEST:
            return {
                ...state,
                updateArtistInfoLoader: true
            };

        case UPDATE_ARTIST_INFO_SUCCESS:
            state = {
                ...state,
                updateArtistInfoLoader: false,
                updateArtistInfoData: action.data
            };
            return state;

        case UPDATE_ARTIST_INFO_FAILURE:
            return {
                ...state,
                updateArtistInfoLoader: false,
                updateArtistInfoData: null
            };
        /* falls through */
        // --------- Toggle Dialog's --------- //
        case TOGGLE_ARTIST_INFO_DIALOG:
            if (action.data === false) {
                state = {
                    ...state,
                    artistEditDialog: action.data,
                };
                return state;
            }
            else {
                state = {
                    ...state,
                    artistEditDialog: action.data
                };
                return state;
            }
        default:
            return state;
    }
};

export default manageArtistsReducer;
