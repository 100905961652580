import React from "react";
import { Typography } from "antd";
import { InputWrapper, FloatingLabeledInputField, FloatingLabeledInputLabel, ErrorDiv, ErrorText } from "./FloatingLabeledInput.styled";

const FloatingLabeledInput = ({ ...props }) => {
	const { label, required, error, errorMessage, touched, ...fields } = props; //hasError
	return (
		<InputWrapper >
			<FloatingLabeledInputField size="large" {...fields} />
			{required ? (
				<FloatingLabeledInputLabel>
					{label}
					<span style={{ color: "red" }}>*</span>
				</FloatingLabeledInputLabel>
			) : (
				<FloatingLabeledInputLabel>{label}</FloatingLabeledInputLabel>
			)}
			{touched && error && props.name === "registerpassword" ? (
				<ErrorDiv>
					<ErrorText type="danger">{errorMessage}</ErrorText>
				</ErrorDiv>
			) : (
				touched && error && props.name !== "registerpassword" && <ErrorText type="danger">{errorMessage}</ErrorText>
			)}
		</InputWrapper>
	);
};
export default FloatingLabeledInput;
