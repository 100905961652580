import {
	GET_HOME_BANNER_LIST_REQUEST,
	GET_HOME_BANNER_LIST_SUCCESS,
	GET_HOME_BANNER_LIST_FAILURE,
	CREATE_HOME_BANNER_REQUEST,
	CREATE_HOME_BANNER_SUCCESS,
	CREATE_HOME_BANNER_FAILURE,
	DELETE_HOME_BANNER_REQUEST,
	DELETE_HOME_BANNER_SUCCESS,
	DELETE_HOME_BANNER_FAILURE,
	TOGGLE_ADDBANNER_DIALOG,
	UPDATE_HOME_BANNER_REQUEST,
	UPDATE_HOME_BANNER_SUCCESS,
	UPDATE_HOME_BANNER_FAILURE
} from "./ActionsTypes";
import { popToast } from "../../components/Toast/Toast";
import { ManageHomeService } from "../../containers/ManageHome/ManageHome.service";

// ============================= Get Home Banner List ============================= //
export const getHomeBannerListAction = () => {
	return dispatch => {
		dispatch(request());
		ManageHomeService.getHomeScreenBannerList().then(
			response => {
				if (response && response.message === "Success") {
					dispatch(success(response.data));
				} else if (response && response.message === "Failure") {
					dispatch(failure(response));
					popToast.show("Get banner list Error : " + response.Message || "Something went wrong while loading banner list!");
				}
			},
			error => {
				if (error && error.status_message) {
					popToast.show(error.status_message);
				} else {
					popToast.show("Something went wrong while loading banner list!");
				}
				console.log("error dispatched for API error!", error);
				dispatch(failure(error));
			}
		);
	};

	function request() {
		return { type: GET_HOME_BANNER_LIST_REQUEST };
	}
	function success(data) {
		return { type: GET_HOME_BANNER_LIST_SUCCESS, data };
	}
	function failure(error) {
		return { type: GET_HOME_BANNER_LIST_FAILURE, error };
	}
};

// ============================= Create Home Banner List ============================= //
export const createHomeBannerAction = bannerData => {
	return dispatch => {
		dispatch(request());
		ManageHomeService.createHomeScreenBanner(bannerData).then(
			response => {
				if (response && response.message === "Success") {
					dispatch(success(response.data));
					dispatch(getHomeBannerListAction());
				} else if (response && response.message === "Failure") {
					dispatch(failure(response));
					popToast.show("Create Banner Error : " + response.Message || "Something went wrong while creating banner!");
				}
			},
			error => {
				if (error && error.status_message) {
					popToast.show(error.status_message);
				} else {
					popToast.show("Something went wrong while creating banner!");
				}
				console.log("error dispatched for API error!", error);
				dispatch(failure(error));
			}
		);
	};

	function request() {
		return { type: CREATE_HOME_BANNER_REQUEST };
	}
	function success(data) {
		return { type: CREATE_HOME_BANNER_SUCCESS, data };
	}
	function failure(error) {
		return { type: CREATE_HOME_BANNER_FAILURE, error };
	}
};

// ============================= Update Home Banner List ============================= //
export const updateHomeBannerAction = (bannerId, bannerData) => {
	return dispatch => {
		dispatch(request());
		ManageHomeService.updateHomeScreenBanner(bannerId, bannerData).then(
			response => {
				if (response && response.message === "Success") {
					dispatch(success(response.data));
					dispatch(getHomeBannerListAction());
				} else if (response && response.message === "Failure") {
					dispatch(failure(response));
					popToast.show("Update Banner Error : " + response.Message || "Something went wrong while updating banner!");
				}
			},
			error => {
				if (error && error.status_message) {
					popToast.show(error.status_message);
				} else {
					popToast.show("Something went wrong while updating banner!");
				}
				console.log("error dispatched for API error!", error);
				dispatch(failure(error));
			}
		);
	};

	function request() {
		return { type: UPDATE_HOME_BANNER_REQUEST };
	}
	function success(data) {
		return { type: UPDATE_HOME_BANNER_SUCCESS, data };
	}
	function failure(error) {
		return { type: UPDATE_HOME_BANNER_FAILURE, error };
	}
};

// ============================= Delete Banner ============================= //
export const deleteHomeBannerAction = bannerItem => {
	return dispatch => {
		dispatch(request());
		ManageHomeService.deleteHomeScreenBanner(bannerItem).then(
			response => {
				if (response && response.message === "Success") {
					dispatch(success(response.data));
					popToast.show("Deleted Successfully");
					dispatch(getHomeBannerListAction());
				} else if (response && response.message === "Failure") {
					dispatch(failure(response));
					popToast.show("Delete Banner Error : " + response.Message || "Something went wrong while deleting banner!");
				}
			},
			error => {
				if (error && error.status_message) {
					popToast.show(error.status_message);
				} else {
					popToast.show("Something went wrong while deleting banner!");
				}
				console.log("error dispatched for API error!", error);
				dispatch(failure(error));
			}
		);
	};

	function request() {
		return { type: DELETE_HOME_BANNER_REQUEST };
	}
	function success(data) {
		return { type: DELETE_HOME_BANNER_SUCCESS, data };
	}
	function failure(error) {
		return { type: DELETE_HOME_BANNER_FAILURE, error };
	}
};

// ============================= Toggle Add Category Dialog ============================= //
export const toggleAddBannerDialog = data => ({
	type: TOGGLE_ADDBANNER_DIALOG,
	data
});
