import { Button, Table } from "antd";
import styled from "styled-components";

export const AbandonedCartReportTable = styled(Table)`
	margin-bottom: 20px;
`;

export const AbandonedTableWrapper = styled.div`
	overflow-x: scroll;
	overflow-y: scroll;
	display: flex;
	flex-direction: column;
	height: calc(100% - 4rem);

	.ant-pagination-item.ant-pagination-item-active a {
		color: black !important;
		margin-left: 5px;
	}
	.ant-table-pagination.ant-pagination {
		float: none;
	}
`;

export const UtilityButtonWrapper = styled.div`
	display: flex;
	justify-content: left;
	margin: 1rem 0;
`;

export const ExportExcelButton = styled(Button)`
	height: 45px !important;
	padding: 3px 10px !important;
	border-radius: 3px !important;
	background-color: #58514b !important;
	border: 1px solid #bababa !important;
	color: #fff !important;
	font-size: 13px !important;
	font-weight: 500 !important;
	text-transform: uppercase;
	transition: background-color 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
`;
