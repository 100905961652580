import React, { Component } from "react";
import "./OrderHistory.scss";
import { Table } from "antd";
import "./OrderHistory.scss";
import { Tooltip, Popover } from "@material-ui/core";

export default class OrderHistory extends Component {
    render() {

        const statusArr = [
            "Payment Pending",
            "Pending Confirmation",
            "Order Confirmed",
            "Packed",
            "Collected For Shipping",
            "Shipped",
            "Delivered",
            "Return Requested",
            "Shipped Return",
            "Returned",
            "Cancelled",
            "Payment Failed"
        ];
        const columns = [
            {
                title: "Products",
                dataIndex: "products",
                key: "products",
                render: (text, record) => (
                    <span className="colWrapper">
                        <img className="colImage" src={record.image || require("../../assets/images/common/profile-pic.svg")} alt="productImage" />
                        <div className="ml-1 ">
                            <Tooltip title={record.productName}>
                                <p className="colText text-bold text-black overflow"> {record.productName}</p>
                            </Tooltip>
                            <Tooltip title={record.description}>
                                <p className="overflow-ls">{record.description}</p>
                            </Tooltip>
                        </div>
                    </span>
                )
            },
            {
                title: "Order Id",
                dataIndex: "orderId",
                key: "orderId"
            },
            {
                title: "Order Date",
                dataIndex: "orderDate",
                key: "orderDate",
                width: "200px"
            },
            {
                title: "Price",
                dataIndex: "price",
                key: "price",
                render: text => <p className="countText">${text}</p>
            },
            {
                title: "Status",
                dataIndex: "status",
                key: "status",
                render: text => <p className={statusArr[text]}>{statusArr[text]}</p>
            }
        ];
        return (
            <div className="main">
                <Table
                    rowKey={record => record._id}
                    columns={columns}
                    dataSource={this.props.data || null}
                    pagination={this.props.data.length > 10}
                />
            </div>
        );
    }
}
