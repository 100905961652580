import styled, { css, keyframes } from "styled-components";

const HoverScreen = styled.div`
	position: absolute;
	display: flex;
	align-items: center;
	background-color: rgba(255, 255, 255, 0.97);
	bottom: 0;
	border-radius: 3px;
	font-size: 13px;
	padding: 0 1rem;
	text-align: center;
	height: 4rem;
`;

const DisplayHoverScreen = styled.div`
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: rgba(255, 255, 255, 0.97);
	bottom: 0;
	border-radius: 3px;
	font-size: 13px;
	padding: 0 1rem;
	text-align: center;
	height: 100%;
	width: calc(100% - 1px);
	left: 1px;
`;

const SlideIn = css`
	& {
		animation: ${SlideInBottom} 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
	}
`;

const SlideInBottom = keyframes`
    0%{transform:translateY(-10px)}100%{transform:translateY(0px)}
`;

const ZoomInKey = keyframes`
    0% {transform: scale(0.5);}100% {transform: scale(1);}
`;
const ZoomIn = css`
	& {
		animation: ${ZoomInKey} 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
	}
`;

export const CropImageWrapper = styled.div`
	/* max-width: 45rem;
	max-height: 45rem; */
`;

export const ProductImgItem = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid #cdcdcd;
	border-radius: 5px;
	height: 10rem;
	width: 10rem;
	margin: 5px;
	cursor: pointer;
	img {
		max-width: calc(100% - 1px);
		max-height: 100%;
	}
	.close-icon {
		position: absolute;
		top: -7px;
		right: -7px;
	}
	.edit-icon {
		font-size: 27px;
		padding: 15px;
		background-color: rgba(218, 218, 218, 0.56);
		border-radius: 50%;
	}
	${HoverScreen}, ${DisplayHoverScreen} {
		display: none;
	}
	&:hover {
		${HoverScreen} {
			display: flex;
			${SlideIn}
		}
		${DisplayHoverScreen} {
			display: flex;
			.edit-icon {
				${ZoomIn}
			}
		}
	}
`;

export const CropImageNewButton = styled.button`
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 30px;
	width: 30px;
	right: 3px;
	bottom: 8px;
	border: none;
	background-color: #fff;
	border-radius: 50%;
	box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.45);
	outline: none;
	padding: 6px 7px 8px;
	img {
		max-width: 100%;
		max-height: 100%;
	}
`;

export const CropImageContainer = styled.div`
	position: relative;
	.ReactCrop {
		img {
			/* max-width: 50rem;
			max-height: 50rem; */
			
		}
	}
`;
