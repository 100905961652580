import { useEffect, useState } from "react";
import { AuctionService } from "../AuctionService";
import * as types from "../../../redux/actions/ActionsTypes";
import history from "../../../util/history";
import { useDispatch, useSelector } from "react-redux";
import { isDate, isEmpty, isInteger, isNaN, isNil, isNumber } from "lodash";
import { popToast } from "../../../components/Toast/Toast";
import moment from "moment";

const obj = {
	name: "",
	description: "",
	startDate: new Date(),
	endDate: new Date(),
	timeGap: "",
	seoTitle: "",
	seoDescription: "",
	keywords: "",
	slugId: "",
	isDeclared: false,
	artworksType: 1,
	banner: "",
	bannerObj: "",
	auctionBanner: "",
	auctionBannerUc: "",
	auctionImage: "",
	auctionImageUC: "",
	overView: "",
	bannerLabel: "",
	bannerHashtag: "",
	flashMessage: "",
	redirectionLink: "",
	nftCalendarImgFormFile_1: "",
	nftCalenderUrl_1: "",
	nftCalenderTitle_1: "",
	nftCalendarImgFormFile_2: "",
	nftCalenderUrl_2: "",
	nftCalenderTitle_2: "",
	nftCalendarImgFormFile_3: "",
	nftCalenderUrl_3: "",
	nftCalenderTitle_3: "",
	blogIds: [],
};
const useAuctionDetails = (match) => {
	const dispatch = useDispatch();
	const { auctionData } = useSelector((s) => s.auctionReducer);
	const [auctionObj, setAuctionObj] = useState(obj);
	const [, setImageLoader] = useState(false);
	const [totalSize, setTotalSize] = useState(0);
	const [displaySize, setDisplaySize] = useState(0);
	const [mediaObj, setMediaObj] = useState();
	const [imageMediaObj, setImageMediaObject] = useState();

	//For NFT Calender
	const [nftCalenderMediaObj1, setNftCalenderMediaObj1] = useState();
	const [nftCalenderMediaObj2, setNftCalenderMediaObj2] = useState();
	const [nftCalenderMediaObj3, setNftCalenderMediaObj3] = useState();
	const [selectedBlogList, setSelectedBlogList] = useState([]);

	useEffect(() => {


		if (!isEmpty(match.params.id)) {
			AuctionService.getAuctionById(match.params.id).then((resp) => {
				if (resp && resp.result === 200) {
					let data = {};
					if (resp.data && resp.data.nftCalendar && resp.data.nftCalendar.length > 0) {
						data = {
							nftCalenderTitle_1: resp.data && resp.data.nftCalendar[0] && resp.data.nftCalendar[0].title,
							nftCalenderUrl_1: resp.data && resp.data.nftCalendar[0] && resp.data.nftCalendar[0].url,
							nftCalendarImgFormFile_1: resp.data && resp.data.nftCalendar[0] && resp.data.nftCalendar[0].nftCalendarImage,
							nftCalenderTitle_2: resp.data && resp.data.nftCalendar[1] && resp.data.nftCalendar[1].title,
							nftCalenderUrl_2: resp.data && resp.data.nftCalendar[1] && resp.data.nftCalendar[1].url,
							nftCalendarImgFormFile_2: resp.data && resp.data.nftCalendar[2] && resp.data.nftCalendar[2].nftCalendarImage,
							nftCalenderTitle_3: resp.data && resp.data.nftCalendar[2] && resp.data.nftCalendar[2].title,
							nftCalenderUrl_3: resp.data && resp.data.nftCalendar[2] && resp.data.nftCalendar[2].url,
							nftCalendarImgFormFile_3: resp.data && resp.data.nftCalendar[2] && resp.data.nftCalendar[2].nftCalendarImage,
						};
					}
					let auctObj = {
						...data,
						...resp.data,
					};
					setAuctionObj(auctObj);
					console.log("🚀 ~ file: useAuctionDetails.js ~ line 81 ~ AuctionService.getAuctionById ~ auctObj", auctObj);
				} else if (resp && resp.result === 400) {
					popToast.show(resp.message);
				}
			});
		} else {
			setAuctionObj(obj);
		}

	}, []);

	// useEffect(() => {
	// 	if (!isNil(auctionData)) {
	// 		setAuctionObj(auctionData);
	// 	}
	// }, [auctionData]);
	const [apiLoading, setApiLoading] = useState(false);

	const onHandleInputChange = (e) => {
		let x = auctionObj;
		const { name, value } = e.target;
		setAuctionObj((s) => ({ ...s, [name]: value }));
	};

	const onHandleIsDeclared = (e) => {
		const { name, value, checked } = e.target;
		setAuctionObj((s) => ({ ...s, isDeclared: checked }));
	}

	const onDateChange = (date, dateString, name) => {
		console.log(date, dateString);
		setAuctionObj((s) => ({ ...s, [name]: !isNil(date) ? date.toDate() : null }));
	};

	const onclickCancel = () => {
		history.push("/auctions");
	};
	const onSetBlogList = (list) => {
		console.log("🚀 ~ file: AuctionDetails.js ~ line 68 ~ onSetBlogList ~ list", list);
		setSelectedBlogList(list);
	};
	const onRemoveBlogList = (list) => {
		setSelectedBlogList(list);
		console.log("🚀 ~ file: AuctionDetails.js ~ line 73 ~ onRemoveBlogList ~ list", list);
	};

	const isValidForm = () => {
		if (isEmpty(auctionObj.name)) {
			popToast.show("Please enter Auction Name");
			return false;
		}
		if (auctionObj.isDeclared === true && !moment(auctionObj.startDate).isValid()) {
			popToast.show("Please enter a valid Start Date");
			return false;
		}
		if (auctionObj.isDeclared === true && !moment(auctionObj.endDate).isValid()) {
			popToast.show("Please enter a valid End Date");
			return false;
		} else {
			if (auctionObj.isDeclared === true && moment(auctionObj.endDate).isBefore(moment(auctionObj.startDate))) {
				popToast.show("Please enter a valid future End Date");
				return false;
			}
		}
		if (!isInteger(parseInt(auctionObj.timeGap))) {
			popToast.show("Please enter a valid integer Time Gap");
			return false;
		}
		if (isEmpty(auctionObj.seoTitle)) {
			popToast.show("Please enter a valid SEO Title");
			return false;
		}
		if (isEmpty(auctionObj.slugId)) {
			popToast.show("Please enter a valid Slug Id");
			return false;
		}
		if (!isNumber(auctionObj.artworksType)) {
			popToast.show("Please select Artwork Type");
			return false;
		}
		if (isEmpty(auctionObj._id)) {
			if (isNil(mediaObj)) {
				popToast.show("Please upload the banner image");
				return false;
			}
			if (isNil(imageMediaObj)) {
				popToast.show("Please upload the auction image");
				return false;
			}
		}

		return true;
	};

	const onSaveAuction = () => {
		const blogList = [];
		if (selectedBlogList && selectedBlogList.length > 0) {
			selectedBlogList.map((data) => {
				blogList.push(data.value);
			});
		}
		if (isValidForm()) {
			if (isEmpty(auctionObj._id)) {
				let nftCalenderObj = {
					"nftCalendar[0].NFTCalendarImgFormFile": nftCalenderMediaObj1 && nftCalenderMediaObj1.media,
					"nftCalendar[0].Url": auctionObj.nftCalenderUrl_1 || "",
					"nftCalendar[0].Title": auctionObj.nftCalenderTitle_1 || "",

					"nftCalendar[1].NFTCalendarImgFormFile": nftCalenderMediaObj2 && nftCalenderMediaObj2.media,
					"nftCalendar[1].Url": auctionObj.nftCalenderUrl_2 || "",
					"nftCalendar[1].Title": auctionObj.nftCalenderTitle_2 || "",

					"nftCalendar[2].NFTCalendarImgFormFile": nftCalenderMediaObj3 && nftCalenderMediaObj3.media,
					"nftCalendar[2].Url": auctionObj.nftCalenderUrl_3 || "",
					"nftCalendar[2].Title": auctionObj.nftCalenderTitle_3 || "",
					blogIds: blogList,
				};
				let auctObj = {
					...auctionObj,
					...nftCalenderObj,
				};

				setApiLoading(true);
				if (mediaObj) {
					auctObj.banner = mediaObj.media;
				}
				if (imageMediaObj) {
					auctObj.auctionImage = imageMediaObj.media;
				}
				AuctionService.addAuction(auctObj)
					.then((resp) => {
						if (resp && resp.result === 200) {
							dispatch(success(types.ADD_AUCTION_SUCCESS, resp.data));
							history.push(`/auctions/nft/${resp.data._id}/${resp.data.artworksType}/${resp.data.contractAddress}`);
						} else if (resp && resp.result === 400) {
							popToast.show(resp.message);
						}
						setApiLoading(false);
					})
					.catch((error) => {
						console.log(error);
						setApiLoading(false);
					});
			} else {
				setApiLoading(true);
				let updateAuctObj = {
					_id: auctionObj._id,
					"isDeclared": auctionObj.isDeclared,
					name: auctionObj.name,
					description: auctionObj.description,
					startDate: auctionObj.startDate,
					endDate: auctionObj.endDate,
					timeGap: auctionObj.timeGap,
					seoTitle: auctionObj.seoTitle,
					seoDescription: auctionObj.seoDescription,
					keywords: auctionObj.keywords,
					slugId: auctionObj.slugId,
					artworksType: auctionObj.artworksType,
					banner: auctionObj.banner,
					bannerObj: auctionObj.bannerObj,
					auctionBanner: auctionObj.auctionBanner,
					auctionBannerUc: auctionObj.auctionBannerUc,
					auctionImage: auctionObj.auctionImage,
					auctionImageUC: auctionObj.auctionImageUC,
					overView: auctionObj.overView,
					bannerLabel: auctionObj.bannerLabel,
					bannerHashtag: auctionObj.bannerHashtag,
					flashMessage: auctionObj.flashMessage,
					redirectionLink: auctionObj.redirectionLink,
					status: auctionObj.status,
					contractAddress: auctionObj.contractAddress,
					"nftCalendar[0].NFTCalendarImgFormFile": nftCalenderMediaObj1 ? nftCalenderMediaObj1.media : null,
					"nftCalendar[0].Url": auctionObj.nftCalenderUrl_1 || "",
					"nftCalendar[0][Title]": auctionObj.nftCalenderTitle_1 || "",

					"nftCalendar[1].NFTCalendarImgFormFile": nftCalenderMediaObj2 ? nftCalenderMediaObj2.media : null,
					"nftCalendar[1].Url": auctionObj.nftCalenderUrl_2 || "",
					"nftCalendar[1][Title]": auctionObj.nftCalenderTitle_2 || "",

					"nftCalendar[2].NFTCalendarImgFormFile": nftCalenderMediaObj3 ? nftCalenderMediaObj3.media : null,
					"nftCalendar[2].Url": auctionObj.nftCalenderUrl_3 || "",
					"nftCalendar[2][Title]": auctionObj.nftCalenderTitle_3 || "",
					blogIds: blogList,

				};

				console.log("🚀 ~ file: useAuctionDetails.js ~ line 165 ~ onSaveAuction ~ auctObj", JSON.stringify(updateAuctObj));

				if (mediaObj) {
					updateAuctObj.banner = mediaObj.media;
				}
				if (imageMediaObj) {
					updateAuctObj.auctionImage = imageMediaObj.media;
				}
				AuctionService.updateAuction(updateAuctObj._id, updateAuctObj)
					.then((resp) => {
						if (resp && resp.result === 200) {
							dispatch(success(types.ADD_AUCTION_SUCCESS, resp.data));
							if (updateAuctObj.status === 0) {
								history.push(`/auctions/nft/${updateAuctObj._id}/${updateAuctObj.artworksType}/${updateAuctObj.contractAddress}`);
							} else {
								history.push(`/auctions/summary/${updateAuctObj._id}`);
							}
						} else if (resp && resp.result === 400) {
							popToast.show(resp.message);
						}
						setApiLoading(false);
					})
					.catch((error) => {
						console.log(error);
						setApiLoading(false);
					});
			}
		}
	};

	const onImageSelect = async (e, type) => {
		e.preventDefault();
		let reader = new FileReader();
		let file = e.target.files[0];
		e.target.value = null;
		let image = "";
		let img = new Image();
		img.src = window.URL.createObjectURL(file);

		if (file && isFileValid(file, type)) {
			// let compressedImage = await Util.compressImage(file);
			setImageLoader(true);
			image = reader.result;
			reader.onloadend = () => {
				// Creating a media object
				let mediaObj = {
					index: generateKey(),
					media: file,
					mediaResult: reader.result,
				};
				if (type === "disp") {
					setMediaObj(mediaObj);
					setAuctionObj((s) => ({ ...s, auctionBanner: "", auctionBannerUc: "" }));
				} else if (type === "image") {
					setImageMediaObject(mediaObj);
					setAuctionObj((s) => ({ ...s, auctionImage: "", auctionImageUC: "" }));
				} else if (type === "nft_1") {
					setNftCalenderMediaObj1(mediaObj);
				} else if (type === "nft_2") {
					setNftCalenderMediaObj2(mediaObj);
				} else if (type === "nft_3") {
					setNftCalenderMediaObj3(mediaObj);
				}

				setImageLoader(false);
			};

			reader.readAsDataURL(file);
		}
	};

	const generateKey = (pre = "MEDIA") => {
		return `${pre}_${new Date().getTime()}`;
	};

	// Checking if uploading file is valid
	const isFileValid = (file, type) => {
		// TODO : Validation?
		if (type === "disp") {
			let total = totalSize;
			let demoSize = displaySize;
			let display = total - demoSize;
			let size = display + file.size;
			if (size > 220200960) {
				popToast.show("Total media size should be below 210MB. Try choosing again", "warning");
				return false;
			} else {
				setTotalSize(size);
				setDisplaySize(file.size);
			}
		}
		if (type === "image") {
			let total = totalSize;
			let size = total + file.size;
			if (size > 220200960) {
				popToast.show("Total media size should be below 210MB. Try choosing again", "warning");
				return false;
			} else {
				setTotalSize(size);
			}
		}
		// if (file.size > 104857600) {
		//     popToast.show("Media size should be below 100MB. Try choosing again!", "warning");
		//     return false;
		// }
		// if (!file.type.startsWith("image/")) {
		//     popToast.show("Try uploading a valid image file!", "warning");
		//     return false;
		// }
		return true;
	};

	return {
		auctionObj,
		apiLoading,
		mediaObj,
		imageMediaObj,
		nftCalenderMediaObj1,
		nftCalenderMediaObj2,
		nftCalenderMediaObj3,
		selectedBlogList,

		setImageMediaObject,
		onHandleInputChange,
		onDateChange,
		onSaveAuction,
		onclickCancel,
		onImageSelect,
		onSetBlogList,
		onRemoveBlogList,
		onHandleIsDeclared,
	};

	function request(type) {
		return { type };
	}
	function success(type, data) {
		return { type, data };
	}
	function failure(type, error) {
		return { type, error };
	}
};

export default useAuctionDetails;
