import React from "react";
import { Button, Col, DatePicker, Divider, Empty, Input, Pagination, Popover, Row, Select, Slider, Typography } from 'antd';

import useEditAuctionLot from "./useEditAuctionLot";
import moment from "moment";
import Root from "../../../Root/Root";
import Topbar from "../../../../components/Topbar/Topbar";
import "../auctionlot.scss"

const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { Title } = Typography;

const EditAuctionLot = ({ match }) => {
    const { auctionLotObj, isApiCalling, auctionStartDate, auctionEndDate,
        onHandleInputChange, onDateChange, onClickCancelAuctionLot, onClickSaveAuctionLot } = useEditAuctionLot(match);

    const disabledDate = (current) => {
        // Can not select days before today and today
        return current < moment(auctionStartDate) || current > moment(auctionEndDate);
    }

    return (
        <Root>
            <Topbar title={"Edit Auction lot"} showBreadCrumbs showHead />
            <div id="add-auction-lot-id">
                <Title level={4}>Edit Auction Lot</Title>
                <div className="add-lot-id">
                    <div className="form-control">
                        <label className="form-label">RESERVE PRICE</label>
                        <Input
                            rows={4}
                            type="text"
                            value={auctionLotObj.reservePrice}
                            name="reservePrice"
                            onChange={onHandleInputChange}
                            disabled
                        />
                    </div>
                    <div className="form-control">
                        <label className="form-label">START BID PRICE</label>
                        <Input
                            rows={4}
                            type="text"
                            value={auctionLotObj.startBidPrice}
                            name="startBidPrice"
                            onChange={onHandleInputChange}
                        />
                    </div>
                    <div className="form-control">
                        <label className="form-label">STEP UP PRICE</label>
                        <Input
                            rows={4}
                            type="text"
                            value={auctionLotObj.bidLimit}
                            name="bidLimit"
                            onChange={onHandleInputChange}
                        />
                    </div>
                    <div className="form-control">
                        <label className="form-label">START DATE ~ END DATE</label>
                        <RangePicker onOk={(date) => onDateChange(date, "")} onChange={(date, dateString) => onDateChange(date, dateString)} disabledDate={disabledDate} value={[moment(auctionLotObj.startDate), moment(auctionLotObj.endDate)]} showTime={true} />
                    </div>
                    {/* <div className='form-date-group'>
                <div className="form-control-date">
                    <label className="form-label">START DATE*</label>
                    <DatePicker disabledDate={disabledDate} showTime={true} onOk={(date) => onDateChange(date, "", "startDate")} showToday={false} value={moment(auctionLotObj.startDate)} name="startDate" onChange={(date, dateString) => onDateChange(date, dateString, "startDate")} />

                </div>
                <div className="form-control-date">
                    <label className="form-label">END DATE*</label>
                    <DatePicker showTime={true} onOk={(date) => onDateChange(date, "", "endDate")} showToday={false} value={moment(auctionLotObj.endDate)} name="endDate" onChange={(date, dateString) => onDateChange(date, dateString, "endDate")} />

                </div>
            </div> */}
                    <div className="form-control">
                        <label className="form-label">DESCRIPTION</label>
                        <TextArea
                            rows={4}
                            type="text"
                            value={auctionLotObj.description}
                            name="description"
                            onChange={onHandleInputChange}
                        />
                    </div>
                    <Divider style={{ 'backgroundColor': "black" }} />
                    <Row className="flx-grw-row save-lot-btn">
                        <Button onClick={onClickCancelAuctionLot}>Cancel</Button>
                        <Button loading={isApiCalling} className="searchButton" onClick={onClickSaveAuctionLot}>Submit</Button>
                    </Row>
                </div>
            </div>
        </Root>
    )
}

export default EditAuctionLot;