import {
    GET_SHIPMENT_PROVIDER_LIST_REQUEST, GET_SHIPMENT_PROVIDER_LIST_SUCCESS, GET_SHIPMENT_PROVIDER_LIST_FAILURE,
    ADD_SHIPMENT_PROVIDER_REQUEST, ADD_SHIPMENT_PROVIDER_SUCCESS, ADD_SHIPMENT_PROVIDER_FAILURE,
    UPDATE_SHIPMENT_PROVIDER_REQUEST, UPDATE_SHIPMENT_PROVIDER_SUCCESS, UPDATE_SHIPMENT_PROVIDER_FAILURE,
    DELETE_SHIPMENT_PROVIDER_REQUEST, DELETE_SHIPMENT_PROVIDER_SUCCESS, DELETE_SHIPMENT_PROVIDER_FAILURE, TOGGLE_ADD_SHIPMENT_PROVIDER_DIALOG
} from "./ActionsTypes";
import { popToast } from "../../components/Toast/Toast";
import { ShipmentProviderService } from "../../containers/ShipmentProvider/ShipmentProvider.service";

// ============================= Get Shipment Provider List ============================= //
export const getShipmentProviderListAction = (pageNum, pageSize, providerName) => {
    return dispatch => {
        dispatch(request());
        ShipmentProviderService.getShipmentProviderList(pageNum, pageSize, providerName).then(
            response => {
                if (response && response.message === "Success") {
                    dispatch(success(response.data));
                } else if (response && response.message === "Failure") {
                    dispatch(failure(response));
                    popToast.show("Get shipment provider list Error : " + response.message || "Something went wrong while loading shipment provider list!");
                }
            },
            error => {
                if (error && error.status_message) {
                    popToast.show(error.status_message);
                } else {
                    popToast.show("Something went wrong while loading shipment provider list!");
                }
                console.log("error dispatched for API error!", error);
                dispatch(failure(error));
            }
        );
    };

    function request() {
        return { type: GET_SHIPMENT_PROVIDER_LIST_REQUEST };
    }
    function success(data) {
        return { type: GET_SHIPMENT_PROVIDER_LIST_SUCCESS, data };
    }
    function failure(error) {
        return { type: GET_SHIPMENT_PROVIDER_LIST_FAILURE, error };
    }
};

// ============================= Add Shipment Provider ============================= //
export const addShipmentProviderAction = (providerData) => {
    return dispatch => {
        dispatch(request());
        ShipmentProviderService.addShipmentProvider(providerData).then(
            response => {
                if (response && response.result === 200 && response.message === "Success") {
                    dispatch(success(response.data));
                    popToast.show("Added Successfully");
                    dispatch(getShipmentProviderListAction());
                } else if (response && response.message) {
                    dispatch(failure(response));
                    popToast.show(response.message || "Something went wrong while adding shipment provider!");
                }
            },
            error => {
                if (error && error.status_message) {
                    popToast.show(error.status_message);
                } else {
                    popToast.show("Something went wrong while adding shipment provider!");
                }
                console.log("error dispatched for API error!", error);
                dispatch(failure(error));
            }
        );
    };

    function request() {
        return { type: ADD_SHIPMENT_PROVIDER_REQUEST };
    }
    function success(data) {
        return { type: ADD_SHIPMENT_PROVIDER_SUCCESS, data };
    }
    function failure(error) {
        return { type: ADD_SHIPMENT_PROVIDER_FAILURE, error };
    }
};

// ============================= Update Shipment Provider ============================= //
export const updateShipmentProviderAction = (providerData, providerId) => {
    return dispatch => {
        dispatch(request());
        ShipmentProviderService.updateShipmentProvider(providerData, providerId).then(
            response => {
                if (response && response.message === "Success") {
                    dispatch(success(response.data));
                    popToast.show("Updated Successfully");
                    dispatch(getShipmentProviderListAction());
                } else if (response && response.message === "Failure") {
                    dispatch(failure(response));
                    popToast.show("Update Shipment Provider Error : " + response.message || "Something went wrong while updating shipment provider!");
                }
            },
            error => {
                if (error && error.status_message) {
                    popToast.show(error.status_message);
                } else {
                    popToast.show("Something went wrong while updating shipment provider!");
                }
                console.log("error dispatched for API error!", error);
                dispatch(failure(error));
            }
        );
    };

    function request() {
        return { type: UPDATE_SHIPMENT_PROVIDER_REQUEST };
    }
    function success(data) {
        return { type: UPDATE_SHIPMENT_PROVIDER_SUCCESS, data };
    }
    function failure(error) {
        return { type: UPDATE_SHIPMENT_PROVIDER_FAILURE, error };
    }
};

// ============================= Delete Shipment Provider ============================= //
export const deleteShipmentProviderAction = providerId => {
    return dispatch => {
        dispatch(request());
        ShipmentProviderService.deleteShipmentProvider(providerId).then(
            response => {
                if (response && response.message === "Success") {
                    dispatch(success(response.data));
                    popToast.show("Deleted Successfully");
                    dispatch(getShipmentProviderListAction());
                } else if (response && response.message === "Failure") {
                    dispatch(failure(response));
                    popToast.show("Delete Shipment Provider Error : " + response.message || "Something went wrong while deleting shipment provider!");
                }
            },
            error => {
                if (error && error.status_message) {
                    popToast.show(error.status_message);
                } else {
                    popToast.show("Something went wrong while deleting shipment provider!");
                }
                console.log("error dispatched for API error!", error);
                dispatch(failure(error));
            }
        );
    };

    function request() {
        return { type: DELETE_SHIPMENT_PROVIDER_REQUEST };
    }
    function success(data) {
        return { type: DELETE_SHIPMENT_PROVIDER_SUCCESS, data };
    }
    function failure(error) {
        return { type: DELETE_SHIPMENT_PROVIDER_FAILURE, error };
    }
};

// ============================= Toggle Add Shipment Provider Dialog ============================= //
export const toggleAddShipmentProviderDialog = data => ({
    type: TOGGLE_ADD_SHIPMENT_PROVIDER_DIALOG,
    data
});
