import { APIUrlConstants } from "../../util/apiUrl.constant";
import { APIService } from "../../util/api.service";
export class DashboardService {
	static getDashboardDetails(year) {
		let url = APIUrlConstants.getApiUrl("getDashboardDetails") + "?Year=" + year;
		return APIService.doGet(url).then(resp => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}
}
