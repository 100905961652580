import React from 'react';
import { Select } from 'antd';
import { compact, isArray, last } from 'lodash';

const { Option } = Select;

const PropertySelect = ({ item, onHandlePropertyInput }) => {

    const { propertySuggestions, value, dataType, chooseFromSuggestion, propertyName, isMandatory } = item;

    const renderSuggestionChildren = () => {
        if (isArray(propertySuggestions)) {
            return propertySuggestions.map((sugg, index) => <Option key={sugg || index}>{sugg}</Option>);
        }
        else {
            let parsedArray = JSON.parse(item.value) || [];
            return parsedArray.map((sugg, index) => <Option key={sugg || index}>{sugg}</Option>);
        }
    }

    const onHandleSingleTagInput = (e) => {
        const val = last(e);
        item.value = [val];
        onHandlePropertyInput(item, val);
    }

    const renderValue = (value) => {
        if (value) {
            if (isArray(value)) return value
            else return JSON.parse(value)
        }
        else return [];
    }

    if (chooseFromSuggestion) {
        // MULTI VALUE - ARRAY
        if (dataType === 4) {
            return (
				<div className="form-control">
					<label className="form-label">
						{propertyName || "<Property name>"}
						<span className="mandatory">{isMandatory && " *"}</span>
					</label>
					<Select
						value={renderValue(value)}
						mode="multiple"
						placeholder="Please select one or more"
						style={{ width: "100%" }}
						onChange={e => onHandlePropertyInput(item, e)}
					>
						{renderSuggestionChildren(item)}
					</Select>
				</div>
			);
        }
        // SINGLE VALUE - NOT ARRAY
        else {
            return (
				<div className="form-control">
					<label className="form-label">
						{propertyName || "<Property name>"}
						<span className="mandatory">{isMandatory && " *"}</span>
					</label>
					<Select
						mode="default"
						value={value}
						placeholder="Please select one"
						style={{ width: "100%" }}
						onChange={e => onHandlePropertyInput(item, e)}
					>
						{renderSuggestionChildren(item)}
					</Select>
				</div>
			);
        }
    }
    else {
        // MUTLI VALUE - ARRAY - MANUAL ADD
        if (dataType === 4) {
            return (
				<div className="form-control">
					<label className="form-label">
						{propertyName || "<Property name>"}
						<span className="mandatory">{isMandatory && " *"}</span>
					</label>
					<Select
						mode="tags"
						value={renderValue(value)}
						placeholder="Please select or type here"
						style={{ width: "100%" }}
						onChange={e => onHandlePropertyInput(item, e)}
					>
						{renderSuggestionChildren(item)}
					</Select>
				</div>
			);
        }
        // SINGLE VALUE - NOT ARRAY - MANUAL ADD
        else {
            return (
				<div className="form-control">
					<label className="form-label">
						{propertyName || "<Property name>"}
						<span className="mandatory">{isMandatory && " *"}</span>
					</label>
					<Select
						value={isArray(item.value) ? compact(item.value) : compact([item.value])}
						mode="tags"
						placeholder="Please select or type here"
						style={{ width: "100%" }}
						onChange={e => onHandleSingleTagInput(e)}
					>
						{renderSuggestionChildren(item)}
					</Select>
				</div>
			);
        }
    }

    // --------------------------
    // SINGLE VALUE - SELECT
    // chooseFromSuggestion - true
    // Datatype - 0

    // --------------------------
    // MULTI VALUE - SELECT
    // chooseFromSuggestion - true
    // Datatype - 4[array]

    // --------------------------
    // SINGLE VALUE - SELECT - MANUAL ADD
    // chooseFromSuggestion - false
    // Datatype - 0

    // --------------------------
    // MUTLI VALUE - SELECT - MANUAL ADD
    // chooseFromSuggestion - false
    // Datatype - 4[array]
}

export default PropertySelect;
