import styled from "styled-components";
import { Button } from "antd";

const StyledButton = styled(Button)`
	&& {
		background-color: ${props => {
		if (props.bg) return props.bg;
		else {
			switch (props.variant) {
				case "primary":
					return props.theme.buttonWhite.bg;
				case "invert":
					return props.theme.buttonGrey.bg;
				default:
					return props.theme.buttonWhite.bg;
			}
		}
	}};
		color: ${props => {
		if (props.color) return props.color;
		else
			switch (props.variant) {
				case "primary":
					return props.theme.buttonGrey.bg;
				case "invert":
					return props.theme.buttonWhite.bg;
				default:
					return props.theme.buttonGrey.bg;
			}
	}};
		width: ${props => {
		return props.width;
	}};
		height: ${props => (props.height ? props.height : "35px")};
		padding: ${props => (props.padding ? props.padding : "3px 20px")};
		margin: ${props => (props.margin ? props.margin : "15px 0px 7.5px 0px")};
		border: ${props => (props.border ? props.border : "0.5px solid #58514B")};
		font-size: ${props => (props.fontSize ? props.fontSize : "13px")};
		font-weight: ${props => (props.fontWeight ? props.fontWeight : "500")};
		border-radius: 4px;
		text-align: center;
		transition: background-color 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
		box-shadow: 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
		&:hover {
			border-color: ${props => props.theme.primary};
			box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 3px 0 rgba(0, 0, 0, 0.19);
		}
		&:active,
		&:focus {
			outline-color: ${props => props.theme.primary};
			border-color: ${props => props.theme.primary};
			outline: none;
		}
	}
`;
export default StyledButton;
