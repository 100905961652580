import { Tabs } from "antd";
import styled from "styled-components";

const { TabPane } = Tabs;
const BlogTabs = styled(Tabs)` 
    margin: .5rem 1rem !important;
`;

const BlogTabPane = styled(TabPane)` 

`;

const BlogWrapper = styled.div` 
    margin-bottom: 1rem;   
    height: calc(100% - 2rem);
    overflow: scroll;

`

export {
    BlogTabs,
    BlogTabPane,
    BlogWrapper
}