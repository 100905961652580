import { Button, Input } from "antd";
import styled from "styled-components";

const BlogTopicWrapper = styled.div` 

`

const BlogTopicButtonContainer = styled.div` 
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
    margin-bottom: 1rem;
`;

const AddTopicButton = styled(Button)`
    background-color: #000000 !important;
    color: #ffffff !important;
    height: 2rem;
    font-size: 11px;
    width: 8.06rem;

    &:focus, &:hover {
        background-color: #000000 !important;
        color: #ffffff !important;
    }

    span {        
        font-size: .7rem;
        font-weight: 600 !important;
    }
`;

const CancelButton = styled(Button)`    
    height: 2rem;
    font-size: 11px;
    width: 8.06rem;  

    span {        
        font-size: .7rem;
        font-weight: 600 !important;
    }
`

const BlogTopicsItemContainer = styled.div` 
    display: flex;
    flex-direction: column;

`;

const TopicLable = styled.label` 
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: justify;

    color: #000000;
`;

const TopicButtonContainer = styled.div` 
    display: flex;
    gap: 1rem;
    align-items: flex-end;

    span {
        display: flex;
        
    }
`;

const BlogTopicItem = styled.div`     
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-bottom: 0.7px solid #000000;
`;

const BlogTopicInput = styled(Input)` 
    background-color: #E6E6E6 !important;
    width: 60% !important;

`

export {
    BlogTopicButtonContainer,
    BlogTopicItem,
    BlogTopicWrapper,
    BlogTopicsItemContainer,
    TopicLable,
    TopicButtonContainer,
    BlogTopicInput,
    CancelButton,
    AddTopicButton
}