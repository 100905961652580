import { APIUrlConstants } from "../../util/apiUrl.constant";
import { APIService } from "../../util/api.service";

export class CollectionService {
	static getSelectedCollections(inputData) {
		let url = APIUrlConstants.getApiUrl("getSelectedCollections") + "?pageNum=" + inputData.pageNum + "&pageSize=" + inputData.pageSize;
		// TODO : Enable pagenum/size url after doing pagination
		// let url = APIUrlConstants.getApiUrl("getSelectedCollections");
		return APIService.doGet(url).then(resp => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	static getCollectionsById(id) {
		let url = APIUrlConstants.getApiUrl("getCollectionById") + "?SlugId=" + id;
		return APIService.doGet(url).then(resp => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	static deleteCollection(collectionId) {
		let url = APIUrlConstants.getApiUrl("deleteCollection") + "/" + collectionId;
		return APIService.doDelete(url).then(resp => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	// List all category
	static getCategory(parentId) {
		let url = APIUrlConstants.getApiUrl("getCategory");
		url = parentId ? url + "?parentId=" + parentId : url + "?parentId=";
		return APIService.doGet(url).then(resp => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	// List all Artists
	static getArtists() {
		let url = APIUrlConstants.getApiUrl("getArtists");
		return APIService.doGet(url).then(resp => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	// Get Category Property [Search artworks/Filter]
	static getCategoryPropertyForFilters(CategoryId) {
		let url = APIUrlConstants.getApiUrl("getCategoryProperty");
		url = url + `?CategoryId=${CategoryId}`;
		return APIService.doGet(url).then(response => {
			if (response && response.data) {
				let newPropArray = response.data.map((item, i) => {
					return {
						...item,
						filters: item.filters.map((fil, index) => {
							return {
								filterId: this.generateFilterKey(item._id, fil.value, fil.condition),
								propertyId: item._id,
								...fil
							};
						})
					};
				});
				return newPropArray;
			}
			throw response;
		});
	}

	static searchArtworks(body) {
		let url = APIUrlConstants.getApiUrl("searchArtwork");
		return APIService.doPost(url, body).then(resp => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	static addCollection(body) {
		let url = APIUrlConstants.getApiUrl("addCollection");
		return APIService.doUploadMultipart(url, body).then(resp => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	static updateCollection(body, id) {
		let url = APIUrlConstants.getApiUrl("updateCollection") + `/${id}`;
		return APIService.doPutMultipart(url, body).then(resp => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	static deleteItemFromCollection(collectionId, collectionItemId) {
		let url = APIUrlConstants.getApiUrl("deleteItemFromCollection") + `/${collectionId}?CollectionItemId=${collectionItemId}`;
		return APIService.doPut(url).then(resp => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	// Generate Unique Key
	static generateFilterKey(id, value, condition) {
		return `${id}_${value}_${condition}`;
	}
}
