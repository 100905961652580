import {
    SEND_OTP_REQUEST, SEND_OTP_SUCCESS, SEND_OTP_FAILURE, VALIDATE_OTP_REQUEST, VALIDATE_OTP_SUCCESS,
    VALIDATE_OTP_FAILURE, PASSWORD_CONFIRM_REQUEST, PASSWORD_CONFIRM_SUCCESS, PASSWORD_CONFIRM_FAILURE

} from "../actions/ActionsTypes";


const initialState = {
    sendOtpLoader: false,
    sendOtpResult: {},
    validateOtpLoader: false,
    validateOtpResult: {},
    confirmPasswordLoader: false,
    confirmPasswordResult: {}
}

const forgotPasswordReducer = (state = initialState, action = {}) => {
    switch (action.type) {

        case SEND_OTP_REQUEST:
            return {
                ...state,
                sendOtpLoader: true
            }

        case SEND_OTP_SUCCESS:
            state = {
                ...state,
                sendOtpLoader: false,
                sendOtpResult: action.data
            }
            return state;

        case SEND_OTP_FAILURE:
            return {
                ...state,
                sendOtpLoader: false
            };

        case VALIDATE_OTP_REQUEST:
            return {
                ...state,
                validateOtpLoader: true
            }

        case VALIDATE_OTP_SUCCESS:
            state = {
                ...state,
                validateOtpLoader: false,
                validateOtpResult: action.data
            }
            return state;

        case VALIDATE_OTP_FAILURE:
            return {
                ...state,
                validateOtpLoader: false
            };

        case PASSWORD_CONFIRM_REQUEST:
            return {
                ...state,
                confirmPasswordLoader: true
            }

        case PASSWORD_CONFIRM_SUCCESS:
            state = {
                ...state,
                confirmPasswordLoader: false,
                confirmPasswordResult: action.data
            }
            return state;

        case PASSWORD_CONFIRM_FAILURE:
            return {
                ...state,
                confirmPasswordLoader: false
            };

        default:
            return state;
    }
}

export default forgotPasswordReducer;