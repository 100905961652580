import React, { useEffect, useState } from "react";
import Topbar from "../../../components/Topbar/Topbar";
import Root from "../../Root/Root";
import BlogArticles from "./BlogArticles/BlogArticles";
import BlogBanner from "./BlogBanner/BlogBanner";
import { BlogTabPane, BlogTabs, BlogWrapper } from "./BlogIndex.styled";
import BlogTopics from "./BlogTopics/BlogTopics";

const BlogIndex = ({ match, location, history }) => {

    const [tabKey, setTabKey] = useState(match && match.params && match.params.slugId);

    useEffect(() => {
        if (match.params) {
            setTabKey(match.params.slugId);
        }
    }, [match.params])

    const onTabChange = (tab) => {
        history.push(`/blog/${tab}`);
    }

    return (
        <Root>
            <Topbar title={"Blogs"} />
            <BlogWrapper>
                <BlogTabs activeKey={tabKey} onChange={onTabChange}>
                    <BlogTabPane tab="Articles" key="1">
                        <BlogArticles location={location} history={history} />
                    </BlogTabPane>
                    <BlogTabPane tab="Banner" key="2">
                        <BlogBanner location={location} history={history} />
                    </BlogTabPane>
                    <BlogTabPane tab="Topics" key="3">
                        <BlogTopics location={location} history={history} />
                    </BlogTabPane>
                </BlogTabs>
            </BlogWrapper>
        </Root>
    )
}

export default BlogIndex;