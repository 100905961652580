import { APIUrlConstants } from "../../util/apiUrl.constant";
import { APIService } from "../../util/api.service";

export class CategorySevice {
	// List all category
	static getCategory(parentId) {
		let url = APIUrlConstants.getApiUrl("getCategory");
		url = parentId ? url + "?parentId=" + parentId : url;
		return APIService.doGet(url).then(resp => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	// List all property
	static getCategoryProperties(parentId) {
		let url = APIUrlConstants.getApiUrl("getProperty");
		url = url + parentId;
		return APIService.doGet(url).then(resp => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	// Create Category
	static createCategory(request) {
		let url = APIUrlConstants.getApiUrl("createCategory");
		return APIService.doUploadMultipart(url, request).then(resp => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	// Update/Create property
	static createProperty(isUpdatingProperty, currentParentId, request) {
		if (isUpdatingProperty) {
			let url = APIUrlConstants.getApiUrl("updateProperty");
			url = url + currentParentId;
			console.log("url", url);
			return APIService.doPost(url, request).then(resp => {
				if (resp) {
					return resp;
				}
				throw resp;
			});
		} else {
			let url = APIUrlConstants.getApiUrl("createProperty");
			return APIService.doPost(url, request).then(resp => {
				if (resp) {
					return resp;
				}
				throw resp;
			});
		}
	}

	// Delete Category
	static deleteCategory(isForceDelete, data) {
		let url = APIUrlConstants.getApiUrl("deleteCategory");
		isForceDelete ? (url = url + "categoryId=" + data._id + "&IsForceDelete=" + isForceDelete) : (url = url + "categoryId=" + data._id);

		return APIService.doDelete(url).then(resp => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	// Update Category
	static updateCategory(request) {
		let url = APIUrlConstants.getApiUrl("updateCategory");
		return APIService.doPutMultipart(url, request).then(resp => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	// Delete Property
	static deleteProperty(request) {
		let url = APIUrlConstants.getApiUrl("deleteProperty");
		url = url + request._id;
		return APIService.doDelete(url).then(resp => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	// List Category Rules
	static getCategoryRules(subCategoryId) {
		let url = APIUrlConstants.getApiUrl("getCategoryRules");
		url = url + subCategoryId;
		return APIService.doGet(url).then(resp => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	// Create Category Rules
	static createCategoryRules(request) {
		let url = APIUrlConstants.getApiUrl("createCategoryRules");
		return APIService.doPost(url, request).then(resp => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	// Update Category Rules
	static updateCategoryRules(ruleId, request) {
		let url = APIUrlConstants.getApiUrl("updateCategoryRules");
		url = url + ruleId;
		return APIService.doPut(url, request).then(resp => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	// Delete Category Rules
	static deleteCategoryRules(ruleId) {
		let url = APIUrlConstants.getApiUrl("deleteCategoryRules");
		url = url + ruleId;
		return APIService.doDelete(url).then(resp => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}
}
