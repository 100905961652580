import {
    GET_ALL_QUOTES_LIST_REQUEST, GET_ALL_QUOTES_LIST_SUCCESS, GET_ALL_QUOTES_LIST_FAILURE,
    UPDATE_QUOTE_PRICE_STATUS_REQUEST, UPDATE_QUOTE_PRICE_STATUS_SUCCESS, UPDATE_QUOTE_PRICE_STATUS_FAILURE
} from "../actions/ActionsTypes";

const initialState = {
    quotesListLoader: false,
    quotesList: [],
    quotesListCount: 0,
    updateQuotePriceStatusLoader: false,
    updateQuotePriceStatusData: null,
};

const quotesReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        // ======= Get all countries List ====== //
        case GET_ALL_QUOTES_LIST_REQUEST:
            state = {
                ...state,
                quotesListLoader: true,
                quotesList: []
            };
            return state;

        case GET_ALL_QUOTES_LIST_SUCCESS:
            state = {
                ...state,
                quotesListLoader: false,
                quotesList: action.data.quoteList,
                quotesListCount: action.data.count

            };
            return state;

        case GET_ALL_QUOTES_LIST_FAILURE:
            state = {
                ...state,
                quotesListLoader: false,
                quotesList: [],
                quotesListCount: 0
            };
            return state;

        // ======= Update Quote Price Status ====== //
        case UPDATE_QUOTE_PRICE_STATUS_REQUEST:
            state = {
                ...state,
                updateQuotePriceStatusLoader: true,
                updateQuotePriceStatusData: null
            };
            return state;

        case UPDATE_QUOTE_PRICE_STATUS_SUCCESS:
            state = {
                ...state,
                updateQuotePriceStatusLoader: false,
                updateQuotePriceStatusData: action.data
            };
            return state;

        case UPDATE_QUOTE_PRICE_STATUS_FAILURE:
            state = {
                ...state,
                updateQuotePriceStatusLoader: false,
                updateQuotePriceStatusData: null
            };
            return state;

        default:
            return state;
    }
};

export default quotesReducer;
