/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, memo } from "react";
import { ArtistInfoContainer } from "./Artist.styled";
import { TabContentContainer } from "./ArtistProfile.styled";

import { isNull } from "lodash";
import { Skeleton, Tabs } from "antd";


import "./ArtistProfile.css";

//Tab Components
import ProfileTab from "./Tabs/ProfileTab/ProfileTab";
import AboutUs from "./Tabs/About/AboutUs";
import AdditionalInfo from "./Tabs/AdditionalInfo/AdditionalInfo";
import ArtCollections from "./Tabs/ArtCollections/ArtCollections";
import ArtFair from "./Tabs/ArtFair/ArtFair";
import YearEvent from "./Tabs/YearEvent/YearEvent";
import MediaAndPublication from "./Tabs/MediaPublication/MediaPublication";

import Exhibitions from "./Tabs/Exhibitions/Exhibitions";
import { getArtistByIdAction } from "../../../redux/actions/ManageArtistsAction";
import { useDispatch, useSelector } from "react-redux";
import { authorizedGet } from "../../../util/apiConfig";
import { getAllCountryListAction } from "../../../redux/actions/CountriesTaxAction";
import Root from "../../Root/Root";
import Topbar from "../../../components/Topbar/Topbar";
import { ManageArtistsService } from "../ManageArtists.service";

const { TabPane } = Tabs;

const ArtistProfile = ({ match }) => {
	const dispatch = useDispatch();
	const { getArtistProfile, getArtistLoader, featureReducer, featureLoader } = useSelector(state => state.manageArtistsReducer)


	const [profileInfo, setProfileInfo] = useState();
	const [additionalInfo, setAdditionalInfo] = useState([]);

	const [profileArtFairs, setProfileArtFairs] = useState([]);
	const [profileCollections, setProfileCollections] = useState([]);
	const [profileMedia, setProfileMedia] = useState([]);
	const [activeTab, setActiveTab] = useState("profile");

	const [isLoadAdditionInfo, setIsLoadAdditionInfo] = useState(false);
	const [isLoadPersonalTab, setIsLoadPersonalTab] = useState(false);

	useEffect(() => {
		getArtistDetails();
		onLoadData();
	}, [match.params.id]);

	const getArtistDetails = () => {
		dispatch(getArtistByIdAction(match.params.id));
	}

	const onLoadData = () => {
		dispatch(getAllCountryListAction());
		setIsLoadAdditionInfo(true);
		setIsLoadPersonalTab(true);

		//Get Info
		authorizedGet(`/UserManagment/api/Artists/GetArtistsAbout?userId=${match.params.id}`).then((responseJson) => {
			if (responseJson.resultCode === 1) {
				setProfileInfo(responseJson.response.data);
				setIsLoadPersonalTab(false);
			}
		});

		//Get Additional Info
		authorizedGet(`/UserManagment/api/Artists/GetArtistsAdditionalInfo?userId=${match.params.id}`).then((responseJson) => {
			if (responseJson.resultCode === 1) {
				setAdditionalInfo(responseJson.response.data);
				setIsLoadAdditionInfo(false);
			}
		});
	};


	const callback = (key) => {
		setActiveTab(key);
		console.log(key);
	};

	return (
		<Root>
			<Topbar title={"Artist Edit Profile"} />

			<ArtistInfoContainer>
				<Tabs defaultActiveKey="profile" onChange={callback} activeKey={activeTab}>
					<TabPane
						tab={
							<>
								<span>Profile</span>
								<span className="required-start-span">*</span>
							</>
						}
						key="profile"
					>
						<TabContentContainer>
							<ProfileTab
								getArtistDetails={getArtistDetails}
								onTabChange={callback}
								userId={match.params.id}
								onLoad={onLoadData}
								artistDetails={getArtistProfile}
							/>

						</TabContentContainer>
					</TabPane>
					<TabPane
						tab={
							<>
								<span>Personal Info</span>
								<span className="required-start-span">*</span>
							</>
						}
						key="about"
					>
						<Skeleton loading={isLoadPersonalTab} paragraph={{ rows: 8 }} active>
							<TabContentContainer>
								<AboutUs

									profileInfo={profileInfo}
									onTabChange={callback}
									onLoad={onLoadData}
									artistDetails={getArtistProfile}
								/>
							</TabContentContainer>
						</Skeleton>
					</TabPane>
					<TabPane tab="Additional Info" key="additional">
						<Skeleton loading={isLoadAdditionInfo} paragraph={{ rows: 8 }} active>
							<TabContentContainer>
								<AdditionalInfo data={additionalInfo} onTabChange={callback} onLoad={onLoadData} />
							</TabContentContainer>
						</Skeleton>
					</TabPane>
					<TabPane tab="Exhibitions" key="exhibitions">
						<Exhibitions
							userId={match.params.id}
							onTabChange={callback}
						/>
					</TabPane>
					<TabPane tab="Art Fairs" key="art">
						<ArtFair

							userId={match.params.id}
							onTabChange={callback}
							data={profileArtFairs}
							onLoad={onLoadData}
						/>
					</TabPane>
					<TabPane tab="Art Collections" key="art_collections">
						<ArtCollections
							data={profileCollections}
							userId={match.params.id}
							onTabChange={callback}
							onLoad={onLoadData}
						/>
					</TabPane>
					<TabPane tab="Awards & Recognitions" key="awards_recognitions">
						<YearEvent

							userId={match.params.id}
							onTabChange={callback}
							isGallery={false}
							onLoad={onLoadData}
							fromSource="profile-edit"
						/>
					</TabPane>
					<TabPane tab="Media & Publication" key="media_publication">
						<MediaAndPublication artistId={match.params.id} data={profileMedia} onLoad={onLoadData} />
					</TabPane>
				</Tabs>

				{/* <Skeleton loading={getLoading} title={{ width: "100%" }} paragraph={{ rows: 15, width: "100%" }} active></Skeleton> */}
			</ArtistInfoContainer>
		</Root>
	);
};

export default ArtistProfile;
