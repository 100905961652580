import React, { Component } from "react";
import { connect } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import { Pagination, Table, Popover, Tooltip } from "antd";
//Files
import { getAllCountryListAction } from "../../redux/actions/CountriesTaxAction";
import { getShipmentProviderListAction, deleteShipmentProviderAction, toggleAddShipmentProviderDialog } from "../../redux/actions/ShipmentProviderAction";
import Root from "../Root/Root";
import Topbar from "../../components/Topbar/Topbar";
import { Util } from "../../util/util";
// SCSS
import "./ShipmentProvider.scss";
import ConfirmationMessageDialog from "../../components/ConfirmationMessageDialog/ConfirmationMessageDialog";
import AddShipmentProviderDialog from "./AddShipmentProviderDialog/AddShipmentProviderDialog";

class ShipmentProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deleteconfirmationDialog: false,
            searchValue: "",
            pageNumValue: 1,
            providerId: "",
            selectedRecordToPerformAction: null,
        };
    }

    componentDidMount() {
        // List Sellers by fetching details from URL - When Page Loads
        this.setSearchParams();
        this.props.getAllCountryListAction();
    }

    componentDidUpdate(prevProps) {
        // List Sellers by fetching details from URL - When URL change occur
        const locationChanged = this.props.location !== prevProps.location;
        if (locationChanged) {
            this.setSearchParams();
        }
    }

    render() {
        const options = { year: "numeric", month: "long", day: "numeric" };
        const columns = [
            {
                title: "From Country",
                dataIndex: "fromCountryName",
                key: "fromCountryName",
                render: (text) => (
                    <div className="colDiv">
                        <Tooltip title={text ? text : "Nil"}>
                            <p className="colText">{text ? text : "Nil"}</p>
                        </Tooltip>
                    </div>
                )
            },
            {
                title: "To Country",
                dataIndex: "toCountryName",
                key: "toCountryName",
                render: (text) => (
                    <div className="colDiv">
                        <Tooltip title={text ? text : "Nil"}>
                            <p className="colText">{text ? text : "Nil"}</p>
                        </Tooltip>
                    </div>
                )
            },
            {
                title: "Shipment Provider",
                dataIndex: "shipmentProvider",
                key: "shipmentProvider",
                render: text => <p className="textBlack">{text}</p>
            },
            {
                title: "Last updated date",
                dataIndex: "updatedDate",
                key: "updatedDate",
                render: text => <p className="blackText">{new Date(text).toLocaleDateString("en-IN", options)}</p>
            },
            {
                title: "Action",
                key: "action",
                render: record =>
                    <Popover
                        placement="bottom"
                        content={content(record)}
                        overlayClassName="shipment-provider-action-popover"
                    // onVisibleChange={this.onVisibleChangeOfCoutryTaxRecord.bind(this, record)}
                    >
                        <img className="action-image" src={require("../../assets/images/common/artworksActionIcon.svg")} alt="action" />
                    </Popover>
            }
        ];
        const content = record => (
            <div>
                <div className="action-span" onClick={this.onClickEditShipmentProvider.bind(this, true, record)}>
                    <img src={require("../../assets/images/common/Edit_New.svg")} alt="edit" />
                    <p className="pop-item">Edit</p>
                </div>
                <span className="action-span" onClick={this.toggleDeleteConfirmationDialog.bind(this, record)}>
                    <img src={require("../../assets/images/common/delete.svg")} alt="delete" />
                    <p className="pop-item">Delete</p>
                </span>
            </div>
        );
        const { shipmentProviderListLoader, shipmentProviderList, shipmentProviderListCount, addShipmentProviderDialog } = this.props.shipmentProviderReducer;

        return (
            <Root>
                <Topbar title={"Shipment Providers"} showSearch searchValue={this.state.searchValue} onChangeSearch={this.onChangeSearch.bind(this)} />
                <div className="shipmentprovider-wrapper">
                    <div className="sub-wrapper">
                        {shipmentProviderList && shipmentProviderList.length !== 0 ? (
                            <Table rowKey={record => record._id} columns={columns} dataSource={shipmentProviderList} pagination={false} />
                        ) : shipmentProviderListLoader ? (
                            <CircularProgress className="circular-loader" size={30} />
                        ) : (
                                    <div className="empty-table">No Shipment Providers Found.</div>
                                )}
                        {!shipmentProviderListLoader && (
                            <Pagination
                                current={parseInt(this.state.pageNumValue)}
                                total={shipmentProviderListCount}
                                pageSize={10}
                                onChange={this.onSelectPageNumber(this)}
                            />
                        )}
                    </div>
                    <div className="add-button-box">
                        <Tooltip placement="top" title={"Add Shipment Provider"}>
                            <div className="add-btn" onClick={this.toggleAddShipmentProviderDialog.bind(this, true)}>
                                + Add Shipment Provider
								</div>
                        </Tooltip>
                    </div>
                </div>
                {/* Create Shipment Provider */}
                <AddShipmentProviderDialog
                    open={addShipmentProviderDialog}
                    updateData={this.state.selectedRecordToPerformAction}
                    handleClose={this.toggleAddShipmentProviderDialog.bind(this, false)}
                />
                <ConfirmationMessageDialog
                    open={this.state.deleteconfirmationDialog}
                    handleClose={this.toggleDeleteConfirmationDialog.bind(this)}
                    confirmMessage={"Are you sure you want to delete this shipment provider?"}
                    onConfirm={this.onDeleteShipmentProvider.bind(this)}
                />
            </Root>
        );
    }

    // ------------------------ When Page loads/ URL changes ------------------------- //
    // Call API by fetching details from URL
    setSearchParams() {
        const { location } = this.props;

        // When filter/Search params exist
        if (location.search) {
            let parsedQuery = Util.queryStringHandling(location.search, "PARSE");
            this.setState(
                {
                    searchValue: parsedQuery.Search,
                    pageNumValue: this.props.match.params.pageNum
                },
                () => {
                    this.doSearchFilterSellers(parsedQuery);
                }
            );
        }

        // When there is no filter/Search params
        else {
            this.setState({ pageNumValue: this.props.match.params.pageNum }, () => {
                this.doSearchFilterSellers();
            });
        }
    }

    // --------------------- When filter/search/pagination events trigger --------------------- //

    //Pagination
    onSelectPageNumber = name => event => {
        this.setState({ pageNumValue: event }, () => {
            this.doChangeUrlWithQuery();
        });
    };

    // Search
    onChangeSearch(search) {
        this.setState(
            {
                searchValue: search,
                pageNumValue: 1
            },
            () => {
                this.doChangeUrlWithQuery();
            }
        );
    }

    // Change URL based on filter/search/Page values
    doChangeUrlWithQuery() {
        let searchCriteria = {
            Search: this.state.searchValue
        };
        const queryString = Util.queryStringHandling(searchCriteria, "STR");
        this.props.history.push("/shipment-providers/" + this.state.pageNumValue + "?" + queryString);
    }

    // ----------------------- API - Fetch Sellers ----------------------------- //
    doSearchFilterSellers() {
        this.props.getShipmentProviderListAction(this.state.pageNumValue, 10, this.state.searchValue);

    }

    // ====================== CREATE Shipment Provider [TOGGLE] ====================== //
    async toggleAddShipmentProviderDialog(value) {
        // Toggle dialog
        this.setState({
            selectedRecordToPerformAction: null
        }, () => {
            // Toggle dialog
            this.props.toggleAddShipmentProviderDialog(value);
        })
    }

    //-------------Dialog for delete shipment provider---------------------//
    toggleDeleteConfirmationDialog(item) {
        this.setState(
            {
                deleteconfirmationDialog: !this.state.deleteconfirmationDialog
            },
            () => {
                this.setState({
                    providerId: item._id
                });
            }
        );
    }

    //-------------Delete Artwork Confirm---------------------//
    async onDeleteShipmentProvider() {
        await this.props.deleteShipmentProviderAction(this.state.providerId);
        await this.doSearchFilterSellers();
        await this.handleReset();
    }

    //Reset fields
    handleReset() {
        this.setState({
            ...this.state,
            deleteconfirmationDialog: false,
            providerId: "",
        });
    }

    // ----------------------- Edit Artwork ------------------------ //
    onVisibleChangeOfCoutryTaxRecord = (data, visibility) => {
        this.setState({
            selectedRecordToPerformAction: visibility ? data : null
        });
    };

    onClickEditShipmentProvider = (value, item) => {
        this.setState({
            selectedRecordToPerformAction: item
        }, () => {
            // Toggle dialog
            this.props.toggleAddShipmentProviderDialog(value);
        })
    };
}

const mapStateToProps = state => {
    return {
        shipmentProviderReducer: state.shipmentProviderReducer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getAllCountryListAction: () => {
            dispatch(getAllCountryListAction());
        },
        getShipmentProviderListAction: (pageNum, pageSize, providerName) => {
            dispatch(getShipmentProviderListAction(pageNum, pageSize, providerName));
        },
        deleteShipmentProviderAction: providerId => {
            dispatch(deleteShipmentProviderAction(providerId));
        },
        toggleAddShipmentProviderDialog: value => {
            dispatch(toggleAddShipmentProviderDialog(value));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentProvider);
