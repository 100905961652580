const queryString = require('query-string');
const moments = require("moment-timezone");

// Util.js contains the basic localstorage functions
export class Util {

    headerContext = null;
    toastContext = null;

    static setLoginResponse(response) {
        localStorage.clear();
        localStorage.setItem("authToken", response.data.access_token);
        localStorage.setItem("role", response.data.roles);
        // localStorage.setItem("USER_DETAILS", JSON.stringify(response.UserDetails));
    }

    static setAuthToken(token) {
        localStorage.setItem("authToken", token);
    }

    static getAuthToken() {
        return localStorage.getItem("authToken");
    }

    static removeAuthToken() {
        localStorage.removeItem("authToken");
    }

    static setUserDetails(userData) {
        return localStorage.setItem("USER_DETAILS", JSON.stringify(userData));
    }

    static getUserDetails() {
        return JSON.parse(localStorage.getItem("USER_DETAILS"));
    }

    // General
    static setValueToLocal(name, value) {
        localStorage.setItem(name, value);
    }
    static getValueFromLocal(name) {
        return localStorage.getItem(name);
    }

    // clear() clears the localstorage completely
    static clearLocal() {
        localStorage.clear();
    }

    static beautifyUrl(urlString, params) {
        for (let value of params) {
            urlString = urlString.replace('{?}', value);
        }
        return urlString;
    }

    // React loader
    static setHeaderContext(context) {
        this.headerContext = context;
    }
    static getHeaderContext(context) {
        return this.headerContext;
    }
    static toggleHeaderLoader(value) {
        if (this.headerContext) {
            this.headerContext.setState({ showLoader: value });
        }
    }

    // Toast Notification
    static setToastContext(context) {
        this.toastContext = context;
    }
    static getToastContext() {
        return this.toastContext;
    }

    // Saving language selected to local storage
    static setLanguage(lang) {
        localStorage.selectedLanguage = lang;
    }
    static getLanguage() {
        return localStorage.selectedLanguage;
    }

    // To convert an JSON Object into formdata
    static objectToFormData = function (obj, form, namespace) {

        var fd = form || new FormData();
        let formKey;
        for (let property in obj) {
            if (obj.hasOwnProperty(property) && obj[property]) {
                if (namespace) {
                    formKey = namespace + '[' + property + ']';
                } else {
                    formKey = property;
                }

                // if the property is an object, but not a File, use recursivity.
                if (obj[property] instanceof Date) {
                    fd.append(formKey, obj[property].toISOString());
                }
                else if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
                    Util.objectToFormData(obj[property], fd, formKey);
                } else { // if it's a string or a File object
                    fd.append(formKey, obj[property]);
                }
            }
        }

        return fd;

    };
    // Parse/Stringify the Query String
    static queryStringHandling(query, type) {
        switch (type) {

            case 'STR':
                let stringified = queryString.stringify(query, { arrayFormat: 'comma', sort: false })
                return stringified;
                break;

            case 'PARSE':
                let parsedObj = queryString.parse(query, { arrayFormat: 'comma' });
                return parsedObj;
                break;

            default:
                return undefined;
        }
    }

    static getCurrentTimeZoneName = () => {
        try {
            var time = new Date();
            var timeZoneOffset = time.getTimezoneOffset();
            var timeZone = moments.tz.guess();

            return moments.tz.zone(timeZone).abbr(timeZoneOffset);
        } catch (e) {
            return "";
        }
    };
}