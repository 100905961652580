
import React, { memo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import AddReelsVideos from "./AddReelsVideos/AddReelsVideos";
import { AddNewButton, AlertIcon, AlertText, CloseButton, FilterInputDiv, ItemContainer, MoreInfo, NoRecordsFound, ReelsButtonWrapper, ReelsFilterDiv, ReelsVideosContainer, SortByButton, TitleContainer, UploadVideoWarningBox, VideoElements, VideoListingContainer, WarningMessageCloseButton, WarningMessageContent } from "./ReelsVideos.styled";

import { isEmpty } from "lodash";
import ViewReelsSummary from "./AddReelsVideos/ViewReelsSummary";
import { ReelsVideoService } from "./ReelsVideoService";
import Root from "../Root/Root";
import Topbar from "../../components/Topbar/Topbar";
import { Input, Select } from 'antd';
const { Option } = Select;
const { Search } = Input;

const ReelsVideos = () => {
    const [showModal, setShowModal] = useState(false);
    const [reelsList, setReelsList] = useState();
    const [showViewSummary, setShowViewSummary] = useState(false);
    const [summaryContent, setSummaryContent] = useState();
    const [editData, setEditData] = useState();
    const [reelsApiLoading, setReelsApiLoading] = useState(false);
    const [artistId, setArtistId] = useState("");
    const [showUploadProcessingWarning, setShowUploadProcessingWarning] = useState(false);

    useEffect(() => {
        getReelsVideos();
    }, [])

    const getReelsVideos = async (sortOption = 0, search = "") => {
        try {

            setReelsApiLoading(true);
            let getReelsResponse = await ReelsVideoService.getReelsVideos(sortOption, search);
            if (getReelsResponse && getReelsResponse.data) {
                setReelsList(getReelsResponse.data)
            }
            setReelsApiLoading(false);

        } catch (error) {
            setReelsApiLoading(false);
        }

    }

    const [dropdownText, setdropdownText] = useState("LATEST");
    const [dropdownValue, setdropdownValue] = useState(0);

    // useEffect(() => {
    //     getReelsVideos(dropdownValue)
    // }, [dropdownValue]);

    const handleSearchVideoByText = (text) => {
        const { value } = text.target;
        getReelsVideos(dropdownValue, value);
    }

    const handleFilterChange = (text) => {

        setdropdownValue(text);
        getReelsVideos(text)
    }

    const AddNewVideo = () => {
        setShowModal(true);
    }
    const handleCloseModal = () => {
        getReelsVideos();
        setShowModal(false);
        setEditData();
    }
    const handleCloseSummaryModal = () => {
        getReelsVideos();
        setShowViewSummary(false);
        setSummaryContent();
    }
    const viewMoreInfo = (data) => {
        setShowViewSummary(true);
        setSummaryContent(data)
    }
    const editReels = (data) => {
        setShowViewSummary(false);
        setShowModal(true);
        setEditData(data)
    }
    return (
        <Root>
            <Topbar title={"Reels Videos"} showBreadCrumbs showHead />
            <ReelsVideosContainer>
                <ReelsButtonWrapper>
                    <ReelsFilterDiv>
                        <FilterInputDiv>
                            <Search placeholder={"Search videos"} onChange={handleSearchVideoByText} />
                        </FilterInputDiv>
                        <SortByButton value={dropdownValue} style={{ width: 120 }} onChange={handleFilterChange}>
                            <Option value={0}>Latest</Option>
                            <Option value={1}>Most Viewed</Option>
                            <Option value={2}>Title A-Z</Option>
                        </SortByButton>
                    </ReelsFilterDiv>
                    <AddNewButton onClick={AddNewVideo}>
                        <span>
                            Add New Video
                        </span>
                    </AddNewButton>

                </ReelsButtonWrapper>
                {showUploadProcessingWarning === true && <UploadVideoWarningBox>
                    <WarningMessageContent>
                        <AlertIcon src={require("../../assets/images/common/caution_icon.svg")} />
                        <AlertText>The uploaded video will take few minutes to process.</AlertText>
                    </WarningMessageContent>
                    <WarningMessageCloseButton onClick={() => setShowUploadProcessingWarning(false)}>
                        <CloseButton src={require("../../assets/images/common/Close_h.svg")} />
                    </WarningMessageCloseButton>

                </UploadVideoWarningBox>}
                {
                    <>
                        {reelsList && !isEmpty(reelsList.reels) ?
                            <VideoListingContainer>

                                {reelsList.reels.map((item, index) => {
                                    return (
                                        <ItemContainer>
                                            <VideoElements
                                                key={`${index}_index`}
                                                onMouseOver={e => e.target.play()}
                                                onMouseOut={e => e.target.pause()}
                                                src={item.videoClip}
                                            />
                                            <TitleContainer
                                                onMouseOver={e => e.stopPropagation()}
                                                onMouseOut={e => e.stopPropagation()}
                                            >
                                                <label>{item.title}</label>
                                                <MoreInfo onClick={() => viewMoreInfo(item)}>
                                                    <img src={require("../../assets/images/common/more_info_videoicon.svg")} />
                                                    <span>More Info</span>
                                                </MoreInfo>
                                            </TitleContainer>
                                        </ItemContainer>
                                    )
                                })}
                            </VideoListingContainer> :

                            <NoRecordsFound>
                                <label>No Records found</label>
                            </NoRecordsFound>
                        }
                    </>
                }

                <AddReelsVideos show={showModal} handleClose={handleCloseModal} editData={editData} artistSlugId={artistId} setShowUploadProcessingWarning={setShowUploadProcessingWarning} />
                <ViewReelsSummary show={showViewSummary} handleClose={handleCloseSummaryModal} data={summaryContent} editReels={editReels} setShowViewSummary={setShowViewSummary} />
            </ReelsVideosContainer>
        </Root>
    )

};

export default ReelsVideos;