import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import { DialogTitle, DialogContent, CircularProgress } from "@material-ui/core";
import { Checkbox } from "antd";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
// Redux
import { connect } from "react-redux";
// SCSS
import "./AddCareerLevelDialog.scss";

const carearLevel = [
    {
        label: "Remove",
        value: "Remove"
    },
    {
        label: "Unknown",
        value: "Unknown"
    },
    {
        label: "Emerging",
        value: "Emerging"
    },
    {
        label: "Mid-Career",
        value: "Mid-Career"
    },
    {
        label: "Established",
        value: "Established"
    },
    {
        label: "Master",
        value: "Master"
    },
]

const liaisons = [
    {
        label: "aude@rtistiq.com",
        value: "aude@rtistiq.com"
    },
    {
        label: "christine@rtistiq.com",
        value: "christine@rtistiq.com"
    },
    {
        label: "amna@rtistiq.com",
        value: "amna@rtistiq.com"
    },
    {
        label: "monalisa@rtistiq.com",
        value: "monalisa@rtistiq.com"
    },
    {
        label: "aryani@rtistiq.com",
        value: "aryani@rtistiq.com"
    },
    {
        label: "jothi.menon@rtistiq.com",
        value: "jothi.menon@rtistiq.com"
    },
]

class AddCareerLevelDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            carrerLevel: "",
            liaison: "",
            isSocialMediaFeatured: false,
            lastFeaturedDate: ""
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevProps.open && this.props.open) {
            const updateData = this.props.updateData;
            this.setState({
                carrerLevel: (updateData && updateData.careerLevel) || "",
                liaison: (updateData && updateData.liaison) || "",
                isSocialMediaFeatured: (updateData && updateData.isSocialMediaFeatured) || false,
                lastFeaturedDate: (updateData && updateData.lastFeaturedDate) || ""
            });
        }
    }

    render() {
        const { updateArtistInfoLoader } = this.props.manageArtistsReducer;

        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.handleClose}
                BackdropProps={{ classes: { root: "dialog-backdrop" } }}
                className="add-carrer-level-dialog"
            >
                <img className="close-btn" onClick={this.props.handleClose} src={require("../../../assets/images/common/Close_h.svg")} alt="" />
                <DialogTitle className="dialog-title">Add Artist Info</DialogTitle>
                <DialogContent className="add-carrer-level-content">
                    <div className="add-carrer-level-body">
                        <div className="form-control">
                            <label className="form-label">Carrer Level</label>
                            <select value={this.state.carrerLevel} onChange={this.onHandleInput("carrerLevel")}>
                                <option aria-label="None" value="" />
                                {carearLevel.map((item, index) => (
                                    <option key={index} value={item.value}>
                                        {item.label}
                                    </option>
                                ))}

                            </select>

                        </div>
                        <div className="form-control">
                            <label className="form-label">Liaison </label>
                            <select value={this.state.liaison} onChange={this.onHandleInput("liaison")}>
                                <option aria-label="None" value="" />
                                {liaisons.map((item, index) => (
                                    <option key={index} value={item.value}>
                                        {item.label}
                                    </option>
                                ))}

                            </select>

                        </div>
                        <div className="add-carrer-level-featured-box">
                            <Checkbox checked={this.state.isSocialMediaFeatured} value={this.state.isSocialMediaFeatured} onChange={this.onHandleInput('isSocialMediaFeatured')} />
                            <span>
                                Featured Social Media
                            </span>
                        </div>
                        <div className="form-control">
                            <label className="form-label">Last Featured Date</label>

                            <DatePicker
                                dateFormat="yyyy-MM-dd"
                                selected={this.state.lastFeaturedDate ? new Date(this.state.lastFeaturedDate) : null}
                                className="form-input"
                                onChange={(date) => this.onHandleDateInput(date)} />
                        </div>
                    </div>


                    <div className="add-carrer-level-action">
                        <button className="btn-cancel" onClick={this.onClickCancel.bind(this)}>
                            Cancel
                        </button>
                        <button className="btn-orange" onClick={this.onClickCreate.bind(this)} disabled={updateArtistInfoLoader}>
                            {this.props.updateData ? "Update" : "Save"}
                            {(updateArtistInfoLoader) && <CircularProgress size={24} className="circular-loader modal-button-loader" />}
                        </button>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }

    // Handle inputs
    onHandleInput = name => (event, date) => {
        if (name === "isSocialMediaFeatured")
            this.setState({
                [name]: event.target.checked
            });
        else if (name === "lastFeaturedDate")
            this.setState({
                [name]: new Date(date)
            });
        else
            this.setState({
                [name]: event.target.value
            });
    };

    onHandleDateInput = (date) => {
        this.setState({
            lastFeaturedDate: new Date(date)
        });
    }

    onClickCreate() {
        if (this.props.updateData && this.props.updateData._id) {
            let request = {
                CareerLevel: this.state.carrerLevel,
                Liaison: this.state.liaison,
                IsSocialMediaFeatured: this.state.isSocialMediaFeatured,
                LastFeaturedDate: this.state.lastFeaturedDate
            }
            this.props.updateArtistInfo(this.props.updateData.userId, request)
        }
    }

    onClickCancel() {
        this.props.handleClose();
    }
}

const mapStateToProps = state => {
    return {
        manageArtistsReducer: state.manageArtistsReducer
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCareerLevelDialog);
