/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from "react";

import assets from "../../../../../assets";


import { SelectInput, SpanFloater } from "../../ArtistProfile.styled";
import { postFormData, putFormData } from "../../../../../util/apiConfig";
import { popToast } from "../../../../../components/Toast/Toast";
import StyledButton from "../../../../../components/StyledButton/StyledButton";
import FloatingLabeledInput from "../../../../../components/FloatingLabeledInput/FloatingLabeledInput";
import { AddNewEventBody, AddNewEventFooter, AddNewEventImage, AddNewEventImageWrap } from "../EventContainer/AddNewEventModal/AddNewEventModal.styled";

const ArtFairModel = ({ onLoad, type, data, onToggleModel, userId }) => {
	const [values, setValues] = useState();
	const [media, setMedia] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const year = new Date().getFullYear();
	const yearList = Array.from(new Array(121), (val, index) => year - index);
	const [, setSetYearObj] = useState();
	// On change inputs
	const updateValues = (newValue) => {
		setValues({ ...values, ...newValue });
	};

	useEffect(() => {
		if (type === "edit") {
			updateValues({ title: data.name, location: data.location, year: data.year, institution: data.institution, type: "" });
			let mediaObj = {
				media: "",
				mediaResult: data.attachments[0],
			};
			setMedia(mediaObj);
		} else {
			updateValues({ title: "", location: "", year: "", institution: "", type: "" });
		}

		if (yearList) {
			var yearOption = [];
			yearList.map((year, index) => {
				yearOption.push({
					name: year,
					value: year,
				});
			});
			setSetYearObj(yearOption);
		}
	}, []);

	const attach_image = useRef(null);

	// Checking if uploading file is valid
	const isFileValid = (file) => {
		// TODO : Validation?
		if (file.size > 104857600) {
			popToast.show("Media size should be below 200MB. Try choosing again");
			return false;
		}
		if (!file.type.startsWith("image/")) {
			popToast.show("Try uploading a valid image file");
			return false;
		}
		return true;
	};

	// On select image
	const onImageSelect = (e, type) => {
		if (values && values.eventImage) values.eventImage = null;
		e.preventDefault();
		let file = e.target.files[0];
		e.target.value = null;
		if (file && isFileValid(file)) {
			let reader = new FileReader();

			reader.onloadend = () => {
				let mediaObj = {
					media: file,
					mediaResult: reader.result,
				};
				setMedia(mediaObj);
			};
			reader.readAsDataURL(file);
		}
	};

	const onClickSave = () => {
		setIsLoading(true);
		// values.eventImage = media.media;
		console.log(values);
		var formdata = new FormData();
		formdata.append("FairImages", media ? media.media : null);
		formdata.append("Year", values.year ? values.year : "");
		formdata.append("Location", values.location);
		formdata.append("Name", values.title);
		let token = localStorage.getItem('authToken');
		if (type === "edit") {
			putFormData(`UserManagment/api/Artists/UpdateFairs?FairId=${data._id}`, formdata, token).then((responseJson) => {
				if (responseJson.resultCode === 1) {

					popToast.show("Updated Successfully", "success");
					onLoad();
					onToggleModel();
				} else {
					popToast.show(responseJson.error.message, "error");
				}
				setIsLoading(false);
			});
		} else {
			postFormData(`UserManagment/api/Artists/AddFairs?ManagedArtistId=${userId}`, formdata, token).then((responseJson) => {
				if (responseJson.resultCode === 1) {

					popToast.show("Added Successfully", "success");
					onLoad();
					onToggleModel();
				} else {
					popToast.show(responseJson.error.message, "error");
				}
				setIsLoading(false);
			});
		}
	};

	return (
		<>
			{values && <AddNewEventBody>
				<AddNewEventImageWrap onClick={(e) => attach_image.current.click()}>
					<AddNewEventImage notPlaceholder={media} src={media ? media.mediaResult : assets.images.addPhoto} alt="add" />
					<input
						type="file"
						style={{ display: "none" }}
						accept=".bmp, .gif, .ico, .jpeg, .jpg, .png, .svg, .tif, .tiff, .jiff, .jfif, .pjpeg, .pjp"
						onChange={(e) => onImageSelect(e, "image")}
						ref={attach_image}
					/>
				</AddNewEventImageWrap>
				<FloatingLabeledInput
					label={"Art Fair"}
					value={values.title}
					onChange={(e) => updateValues({ title: e.target.value })}
				/>

				<div style={{ position: "relative", width: "100%" }}>
					<SpanFloater>{"Year"}</SpanFloater>
					<SelectInput
						style={{
							height: 40,
							width: "100%",
							marginTop: 15,
						}}
						showSearch
						optionFilterProp="children"
						value={values.year}
						onChange={(e) => {
							updateValues({ year: e });
						}}
					>
						{yearList.map((year, index) => {
							return (
								<option key={`year${index}`} value={year}>
									{year}
								</option>
							);
						})}
					</SelectInput>
				</div>

				<FloatingLabeledInput
					label={"Location"}
					value={values.location}
					onChange={(e) => updateValues({ location: e.target.value })}
				/>
			</AddNewEventBody>}
			<AddNewEventFooter>
				<StyledButton
					loading={isLoading}
					disabled={isLoading}
					variant="invert"
					height="40px"
					width="10rem"
					fontSize="14px"
					margin="auto 0"
					onClick={() => onClickSave()}
				>
					{isLoading ? "Saving" : "Save"}
				</StyledButton>
			</AddNewEventFooter>
		</>
	);
};
export default ArtFairModel;
