import styled from "styled-components";
import { Row, Col } from "antd";
import StyledButton from "../../../../components/StyledButton/StyledButton";
import ImgNextGen from '../../../../components/NextGenImage/ImgNextGen'

const ProfileImageRow = styled(Row)`
	@media screen and (min-width: 576px) {
		margin-left: 25px;
	}
	/* padding-top: 85px; */
	padding-top: ${props => {
		return props.paddingtop;
	}};
`;

const ArtistNameCol = styled(Col)`
	/* margin-bottom:30px; */
	z-index: 5;
	@media screen and (max-width: 576px) {
		background: #58514b;
		padding-left: 5px;
	}
`;
const Wrapper = styled.div`
	position: relative;
	width:80%;
	align-self:center;
`;

const BackgroundBanner = styled(ImgNextGen)`
	position: absolute;
	width: 100%;
	height: 240px;
	object-fit: cover;
	&:hover {
		cursor: pointer;
	}
`;

const CameraIconOnBanner = styled.img`
	width: 25px;
	float: right;
	position: absolute;
	z-index: 6;
	right: 15px;
	top: 209px;
	cursor:pointer;
`;

const ProfilePicture = styled.img`
	width: 100px;
	position: relative;
	top: -80px;
	left: 50px;
	:hover {
		cursor: pointer;
	}
`;
const CameraIconOnPicture = styled.img`
	z-index: 2;
	width: 18px;
	/* position: absolute; */
	/* top: 152px;
	left: 157px; */
	/* top: ${props => {
		return props.top;
	}};
	left: ${props => {
		return props.left;
	}}; */
	cursor:pointer;
`;
const ArtistImageNamePlaceWrapper = styled.div`
	display: flex;
	color: #ffffff;
	position: absolute;
	top: 42%;
	align-items: flex-end;
`;
const ArtistNamePlaceWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width:fit-content;
	max-width: 85%;
	cursor: pointer;
`;
const H2 = styled.h1`
	color: #ffffff;
	margin-bottom: 5px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	text-shadow: 2px 2px #607d8b;
	/* white-space: initial;
	word-break: break-word;
	max-height:40px; */
`;
const Text = styled.p`
	color: #ffffff;
	font-size: 14px;
	margin-bottom: 10px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	text-shadow: 2px 2px #607d8b;
	@media screen and (min-width: 576px) {
		padding-right: 40px;
	}
`;
const SaveDiv = styled.div`
	text-align: center;
`;
const SaveButton = styled(StyledButton)`
	padding-left: 3rem !important;
	padding-right: 3rem !important;
`;
const StyledRow = styled.div`
	display: flex;
	justify-content: center;
	padding: 8px;
	margin-bottom: 40px;
`;

const Border = styled.div`
	cursor: pointer;
	background: #ffffff;
	border: 1px solid #cdcdcd;
	border-radius: 5px;
	height: 150px;
	width: 150px;
	display: flex;
	justify-content: center;
	align-items: center;
`;
const AddPhotoImg = styled.img`
	width: -webkit-fill-available;
	object-fit: cover;
	min-height: 150px;
	min-width: 150px;
	max-height: 150px;
	max-width: 150px;
`;
const ImageDiv = styled.div`
	position: absolute;
`;
export const NormalDiv = styled.div`
	display: flex;
    /* position: absolute; */
    /* left: 45%; */
	position: relative;
    justify-content: center;
    align-items: center;
	width: 200px;
	height: 200px;
	background: #ffffff;
	box-sizing: border-box;
	border-radius: 100px;
`;
export const NormalImg = styled(ImgNextGen)`
	z-index: 1;
	/* position: absolute; */
	object-fit: cover;
	top: 7px; /* equal to border thickness */
	left: 7px; /* equal to border thickness */
	width: 186px; /* container height - (border thickness * 2) */
	height: 186px; /* container height - (border thickness * 2) */
	cursor: pointer;
	border-radius: 100px;
`;
const HexaDiv = styled.div`
	/* top: 36px; */
	/* left: 25px; */
	display: inline-block;
	position: relative;
	width: 200px;
	height: 200px;
	background: #ffffff;
	box-sizing: border-box;
	-webkit-clip-path: polygon(
		92.32051% 40%,
		93.79385% 43.1596%,
		94.69616% 46.52704%,
		95% 50%,
		94.69616% 53.47296%,
		93.79385% 56.8404%,
		92.32051% 60%,
		79.82051% 81.65064%,
		77.82089% 84.50639%,
		75.35575% 86.97152%,
		72.5% 88.97114%,
		69.3404% 90.44449%,
		65.97296% 91.34679%,
		62.5% 91.65064%,
		37.5% 91.65064%,
		34.02704% 91.34679%,
		30.6596% 90.44449%,
		27.5% 88.97114%,
		24.64425% 86.97152%,
		22.17911% 84.50639%,
		20.17949% 81.65064%,
		7.67949% 60%,
		6.20615% 56.8404%,
		5.30384% 53.47296%,
		5% 50%,
		5.30384% 46.52704%,
		6.20615% 43.1596%,
		7.67949% 40%,
		20.17949% 18.34936%,
		22.17911% 15.49361%,
		24.64425% 13.02848%,
		27.5% 11.02886%,
		30.6596% 9.55551%,
		34.02704% 8.65321%,
		37.5% 8.34936%,
		62.5% 8.34936%,
		65.97296% 8.65321%,
		69.3404% 9.55551%,
		72.5% 11.02886%,
		75.35575% 13.02848%,
		77.82089% 15.49361%,
		79.82051% 18.34936%
	);
	clip-path: polygon(
		92.32051% 40%,
		93.79385% 43.1596%,
		94.69616% 46.52704%,
		95% 50%,
		94.69616% 53.47296%,
		93.79385% 56.8404%,
		92.32051% 60%,
		79.82051% 81.65064%,
		77.82089% 84.50639%,
		75.35575% 86.97152%,
		72.5% 88.97114%,
		69.3404% 90.44449%,
		65.97296% 91.34679%,
		62.5% 91.65064%,
		37.5% 91.65064%,
		34.02704% 91.34679%,
		30.6596% 90.44449%,
		27.5% 88.97114%,
		24.64425% 86.97152%,
		22.17911% 84.50639%,
		20.17949% 81.65064%,
		7.67949% 60%,
		6.20615% 56.8404%,
		5.30384% 53.47296%,
		5% 50%,
		5.30384% 46.52704%,
		6.20615% 43.1596%,
		7.67949% 40%,
		20.17949% 18.34936%,
		22.17911% 15.49361%,
		24.64425% 13.02848%,
		27.5% 11.02886%,
		30.6596% 9.55551%,
		34.02704% 8.65321%,
		37.5% 8.34936%,
		62.5% 8.34936%,
		65.97296% 8.65321%,
		69.3404% 9.55551%,
		72.5% 11.02886%,
		75.35575% 13.02848%,
		77.82089% 15.49361%,
		79.82051% 18.34936%
	);
	transform: rotate(90deg);
`;
const HexaImg = styled(ImgNextGen)`
	z-index: 1;
	position: absolute;
	object-fit: cover;
	top: 2px; /* equal to border thickness */
	left: 2px; /* equal to border thickness */
	width: 200px; /* container height - (border thickness * 2) */
	height: 200px; /* container height - (border thickness * 2) */
	/* -webkit-clip-path: polygon(92.32051% 40%, 93.79385% 43.1596%, 94.69616% 46.52704%, 95% 50%, 94.69616% 53.47296%, 93.79385% 56.8404%, 92.32051% 60%, 79.82051% 81.65064%, 77.82089% 84.50639%, 75.35575% 86.97152%, 72.5% 88.97114%, 69.3404% 90.44449%, 65.97296% 91.34679%, 62.5% 91.65064%, 37.5% 91.65064%, 34.02704% 91.34679%, 30.6596% 90.44449%, 27.5% 88.97114%, 24.64425% 86.97152%, 22.17911% 84.50639%, 20.17949% 81.65064%, 7.67949% 60%, 6.20615% 56.8404%, 5.30384% 53.47296%, 5% 50%, 5.30384% 46.52704%, 6.20615% 43.1596%, 7.67949% 40%, 20.17949% 18.34936%, 22.17911% 15.49361%, 24.64425% 13.02848%, 27.5% 11.02886%, 30.6596% 9.55551%, 34.02704% 8.65321%, 37.5% 8.34936%, 62.5% 8.34936%, 65.97296% 8.65321%, 69.3404% 9.55551%, 72.5% 11.02886%, 75.35575% 13.02848%, 77.82089% 15.49361%, 79.82051% 18.34936%);
	 clip-path: polygon(92.32051% 40%, 93.79385% 43.1596%, 94.69616% 46.52704%, 95% 50%, 94.69616% 53.47296%, 93.79385% 56.8404%, 92.32051% 60%, 79.82051% 81.65064%, 77.82089% 84.50639%, 75.35575% 86.97152%, 72.5% 88.97114%, 69.3404% 90.44449%, 65.97296% 91.34679%, 62.5% 91.65064%, 37.5% 91.65064%, 34.02704% 91.34679%, 30.6596% 90.44449%, 27.5% 88.97114%, 24.64425% 86.97152%, 22.17911% 84.50639%, 20.17949% 81.65064%, 7.67949% 60%, 6.20615% 56.8404%, 5.30384% 53.47296%, 5% 50%, 5.30384% 46.52704%, 6.20615% 43.1596%, 7.67949% 40%, 20.17949% 18.34936%, 22.17911% 15.49361%, 24.64425% 13.02848%, 27.5% 11.02886%, 30.6596% 9.55551%, 34.02704% 8.65321%, 37.5% 8.34936%, 62.5% 8.34936%, 65.97296% 8.65321%, 69.3404% 9.55551%, 72.5% 11.02886%, 75.35575% 13.02848%, 77.82089% 15.49361%, 79.82051% 18.34936%); */
	transform: rotate(-90deg);
	cursor: pointer;
`;
const EditButton = styled.div`
	display: flex;
	background-color: #ffffff;
	justify-content: center;
	width: 35px;
	height: 25px;
	border-radius: 15px;
	margin-left: 10px;
	box-shadow: 0px 2px 4px 1px #d9d9d9;
`;
const SubWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const Hr = styled.hr`
	width: 100%;
	margin-top: ${(props) => {
		return props.top || "20px";
	}};
	margin-bottom: ${(props) => {
		return props.bottom;
	}};
	border-color: ${(props) => {
		return props.color;
	}};
	border-top: 0;
	border-left: 0;
	border-width: ${(props) => {
		return props.width || "1px";
	}};
	@-moz-document url-prefix() {
		border-width: 1px;
	}
`;

export {
	Wrapper,
	ArtistNameCol,
	CameraIconOnBanner,
	ProfilePicture,
	CameraIconOnPicture,
	BackgroundBanner,
	ArtistImageNamePlaceWrapper,
	ArtistNamePlaceWrapper,
	H2,
	SaveDiv,
	SaveButton,
	StyledRow,
	Border,
	AddPhotoImg,
	HexaDiv,
	HexaImg,
	Text,
	EditButton,
	SubWrapper,
	ImageDiv,
	ProfileImageRow,
	Hr
};
