import { APIUrlConstants } from "../../../util/apiUrl.constant";
import { APIService } from "../../../util/api.service";
import { Util } from "../../../util/util";

export class NewsService {
    static getNewsList(pageNum, pageSize) {
        let url = APIUrlConstants.getApiUrl("getNews") + "?pageNum=" + pageNum + "&pageSize=" + pageSize;
        return APIService.doGet(url).then(resp => {
            if (resp) {
                return resp;
            }
            throw resp;
        });
    }

    static updateNews(newsId, request) {
        let url = APIUrlConstants.getApiUrl("updateNews") + "?NewsId=" + newsId;
        let formBodyRequest = Util.objectToFormData(request);
        return APIService.doPutMultipart(url, formBodyRequest).then(resp => {
            if (resp) {
                return resp;
            }
            throw resp;
        });
    }

    static deleteNews(newsId) {
        let url = APIUrlConstants.getApiUrl("deleteNews") + "?NewsId=" + newsId;
        return APIService.doDelete(url).then(resp => {
            if (resp) {
                return resp;
            }
            throw resp;
        });
    }

    static addNews(request) {
        let url = APIUrlConstants.getApiUrl("addNews");
        let formBodyRequest = Util.objectToFormData(request);
        return APIService.doUploadMultipart(url, formBodyRequest).then(resp => {
            if (resp) {
                return resp;
            }
            throw resp;
        });
    }
}
