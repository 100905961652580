import React, { useState, useEffect } from "react";
import { TitleHeading, MainSubmitButton, MainPlusButton, SelectInput, MainDeleteButton } from "../../ArtistProfile.styled";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";

import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { Typography } from "antd";
import { FormInput, FormRow, FormTextarea } from "../../Artist.styled";
import FormikEditor from "../../../../../components/TextEditor/FormikEditor";
import { popToast } from "../../../../../components/Toast/Toast";
import { authorizePost } from "../../../../../util/apiConfig";

export const AboutUs = ({ artistDetails, profileInfo, onTabChange }) => {
	const year = new Date().getFullYear();
	const yearList = Array.from(new Array(121), (val, index) => year - index);

	const [highlightsList, setHighlightsList] = useState(profileInfo && profileInfo.artisticCareer ? profileInfo.artisticCareer : []);
	const [highlight, setHighlight] = useState("");
	const [inspireList, setInspireList] = useState(profileInfo && profileInfo.inspiredArtists ? profileInfo.inspiredArtists : []);
	const [inspire, setInspire] = useState("");

	const [educationList, setEducationList] = useState(profileInfo && profileInfo.educationHistory ? profileInfo.educationHistory : []);

	const [isLoading, setIsLoading] = useState(false);
	const [, setSetYearObj] = useState();
	const [wordCountShortPara, setWordCountShortPara] = useState(0);
	const [buttonType, setButtonType] = useState("");

	//Validation
	const [isEducationValid, setIsEducationValid] = useState(true);
	const [isResidenceValid, setIsResidenceValid] = useState(true);
	const [isInspireValid, setIsInspireValid] = useState(true);
	const [isHighlightValid, setIsHighlightValid] = useState(true);
	const [eduObjectInput, setEduObjectInput] = useState({
		degree: "",
		year: "",
		institution: "",
		location: "",
	});
	const [residenceList, setResidenceList] = useState(profileInfo && profileInfo.recidencies ? profileInfo.recidencies : []);
	const [residenceObjectInput, setResidenceObjectInput] = useState({
		year: "",
		institution: "",
		location: "",
	});


	useEffect(() => {
		if (yearList) {
			var yearOption = [];
			yearList.map((year, index) => {
				yearOption.push({
					name: year,
					value: year,
				});
			});
			setSetYearObj(yearOption);
		}

		window.scrollTo(0, 0);
	}, []);


	const addHighlights = () => {
		if (highlight !== "") {
			setIsHighlightValid(true);
			setHighlightsList([...highlightsList, highlight]);
			setHighlight("");
		} else {
			setIsHighlightValid(false);
		}
	};
	const addInspire = () => {
		if (inspire !== "") {
			setInspireList([...inspireList, inspire]);
			setInspire("");
			setIsInspireValid(true);
		} else {
			setIsInspireValid(false);
		}
	};
	const addEducation = () => {
		if (
			eduObjectInput &&
			eduObjectInput.degree !== "" &&
			eduObjectInput.institution !== "" &&
			eduObjectInput.location !== "" &&
			eduObjectInput.year !== ""
		) {
			setEducationList([...educationList, eduObjectInput].reverse());
			setEduObjectInput({
				degree: "",
				year: "",
				institution: "",
				location: "",
			});
			setIsEducationValid(true);
		} else {
			setIsEducationValid(false);
		}
	};
	const addResidence = () => {
		if (residenceObjectInput.year !== "" && residenceObjectInput.institution !== "" && residenceObjectInput.location !== "") {
			setResidenceList([...residenceList, residenceObjectInput]);
			setResidenceObjectInput({
				year: "",
				institution: "",
				location: "",
			});
			setIsResidenceValid(true);
		} else {
			setIsResidenceValid(false);
		}
	};
	const onUpdate = (values) => {
		setIsLoading(true);
		var data = {
			ArtisticCareer: highlightsList,
			InspiredArtists: inspireList,
			EducationHistory: educationList,
			Recidencies: residenceList,
		};
		let token = localStorage.getItem('authToken');
		authorizePost(`UserManagment/api/Artists/AddArtistAbout?ManagedArtistId=${artistDetails.userId}`, data, token).then((responseJson) => {
			if (responseJson.resultCode === 1) {
				setIsLoading(false);
				if (buttonType === "next") {
					onTabChange("additional");
				} else {
					popToast.show("Update Successfully");
				}
			} else {
				popToast.show(responseJson.error.message);
				setIsLoading(false);
			}
		});
	};
	const removeEducation = (year) => {
		var newArray = educationList.filter(function (obj) {
			return obj.year !== year;
		});
		setEducationList(newArray);
	};

	const removeResidencies = (year) => {
		var newArray = residenceList.filter(function (obj) {
			return obj.year !== year;
		});
		setResidenceList(newArray);
	};
	const removeInspire = (data) => {
		var newArray = inspireList.filter(function (obj) {
			return obj !== data;
		});
		setInspireList(newArray);
	};
	const removeHighlights = (highLights) => {
		var newArray = highlightsList.filter(function (obj) {
			return obj !== highLights;
		});
		setHighlightsList(newArray);
	};

	return (
		<div className="about-form-section">
			<Formik
				enableReinitialize={true}
				initialValues={{
					articleBody: "",
				}}
				validationSchema={Yup.object().shape({})}
				onSubmit={(values, actions) => {
					console.log(values);
					onUpdate(values);
				}}
			>
				{({ errors, touched, handleSubmit, isSubmitting }) => {
					return (
						<Form name="deliveryAddress" method="post">
							<TitleHeading>
								Add the Highlights of your artistic career. You have the chance to add your full Bio later on.
							</TitleHeading>
							<ul>
								<li>Creation (displayed in a museum, biennale, etc.) </li>
								<li>Top Recognition (awards)</li>
								<li> Art market achievement (art fairs, top price, etc.)</li>
							</ul>
							<FormRow>
								<FormInput
									placeholder="Enter Highlights within 60 characters or 10 words "
									name="highlights"
									value={highlight}
									maxlength="60"
									onChange={(e) => {
										setHighlight(e.target.value);
									}}
									component="input"
									type="text"
								/>

								<MainPlusButton type="button" onClick={addHighlights}>
									+
								</MainPlusButton>
							</FormRow>
							{!isHighlightValid && <Typography.Text type="danger">Field required.</Typography.Text>}
							<FormRow>
								<div className="highlight-wrapper">
									{highlightsList.map((data, index) => (
										<div className="highlight-items">
											<p>{data}</p>
											<MainDeleteButton
												type="button"
												onClick={() => {
													removeHighlights(data);
												}}
											>
												X{" "}
											</MainDeleteButton>
										</div>
									))}
								</div>
							</FormRow>

							<br />
							<TitleHeading>{"Highest Formal Art Education."}</TitleHeading>
							<FormRow>
								<SelectInput
									style={{
										height: 45,
									}}
									showSearch
									value={eduObjectInput.year}
									optionFilterProp="children"
									placeholder={eduObjectInput.year === "" ? "Year" : ""}
									defaultValue=""
									onChange={(e) => {
										setEduObjectInput({
											degree: eduObjectInput.degree,
											year: e,
											institution: eduObjectInput.institution,
											location: eduObjectInput.location,
										});
									}}
								>
									{yearList.map((year, index) => {
										return (
											<option key={`year${index}`} value={year}>
												{year}
											</option>
										);
									})}
								</SelectInput>

								<span style={{ width: 30 }}></span>
								<FormInput
									name="eduDegree"
									placeholder="Degree"
									onChange={(e) => {
										setEduObjectInput({
											degree: e.target.value,
											year: eduObjectInput.year,
											institution: eduObjectInput.institution,
											location: eduObjectInput.location,
										});
									}}
									component="input"
									type="text"
									value={eduObjectInput.degree}
								/>
								<span style={{ width: 30 }}></span>
								<FormInput
									name="eduInstitution"
									placeholder="Institution"
									onChange={(e) => {
										setEduObjectInput({
											degree: eduObjectInput.degree,
											year: eduObjectInput.year,
											institution: e.target.value,
											location: eduObjectInput.Location,
										});
									}}
									component="input"
									type="text"
									value={eduObjectInput.institution}
								/>
								<span style={{ width: 30 }}></span>
								<FormInput
									name="eduLocation"
									placeholder="Location"
									onChange={(e) => {
										setEduObjectInput({
											degree: eduObjectInput.degree,
											year: eduObjectInput.year,
											institution: eduObjectInput.institution,
											location: e.target.value,
										});
									}}
									component="input"
									type="text"
									value={eduObjectInput.location}
								/>
							</FormRow>
							{!isEducationValid && <Typography.Text type="danger">Please fill all fields.</Typography.Text>}
							<div className="plus-button-wrapper">
								<MainPlusButton onClick={addEducation} type="button">
									+
								</MainPlusButton>
								<p>{`Click + to confirm entry`}</p>
							</div>
							<FormRow>
								<div className="highlight-wrapper">
									{educationList &&
										educationList
											.sort((a, b) => (a.Year > b.Year ? 1 : -1))
											.map((data, index) => (
												<div className="highlight-items">
													{" "}
													<p key={index}>{`${data.year} , ${data.degree} , ${data.institution} , ${data.location}`}</p>
													<MainDeleteButton
														type="button"
														onClick={() => {
															removeEducation(data.year);
														}}
													>
														X{" "}
													</MainDeleteButton>
												</div>
											))}
								</div>
							</FormRow>
							<br />
							{/* <br /> */}
							<TitleHeading>{"Name the artists who inspire you"}</TitleHeading>
							<FormRow>
								<FormInput
									name="inspire"
									value={inspire}
									onChange={(e) => {
										setInspire(e.target.value);
									}}
									component="input"
									type="text"
								/>
							</FormRow>
							{!isInspireValid && <Typography.Text type="danger">Artists name required.</Typography.Text>}
							<div className="plus-button-wrapper">
								<MainPlusButton type="button" onClick={addInspire}>
									+
								</MainPlusButton>
								<p>{`Click + to confirm entry`}</p>
							</div>
							<FormRow>
								<div className="highlight-wrapper">
									{inspireList.map((data, index) => (
										<div className="highlight-items">
											<p>{data}</p>
											<MainDeleteButton
												type="button"
												onClick={() => {
													removeInspire(data);
												}}
											>
												X{" "}
											</MainDeleteButton>
										</div>
									))}
								</div>
							</FormRow>

							<br />
							{/* <br /> */}
							<TitleHeading>{"Add your Residencies"}</TitleHeading>
							<FormRow>
								<SelectInput
									style={{
										height: 45,
									}}
									showSearch
									value={residenceObjectInput.year}
									optionFilterProp="children"
									placeholder={residenceObjectInput.year === "" ? "Year" : ""}
									defaultValue=""
									onChange={(e) => {
										setResidenceObjectInput({
											year: e,
											institution: residenceObjectInput.institution,
											location: residenceObjectInput.location,
										});
									}}
								>
									{yearList.map((year, index) => {
										return (
											<option key={`year${index}`} value={year}>
												{year}
											</option>
										);
									})}
								</SelectInput>

								<span style={{ width: 30 }}></span>
								<FormInput
									name="inspire"
									value={residenceObjectInput.institution}
									placeholder="Institution"
									onChange={(e) => {
										setResidenceObjectInput({
											year: residenceObjectInput.year,
											institution: e.target.value,
											location: residenceObjectInput.location,
										});
									}}
									component="input"
									type="text"
								/>
								<span style={{ width: 30 }}></span>
								<FormInput
									name="inspire"
									value={residenceObjectInput.location}
									placeholder="Location"
									onChange={(e) => {
										setResidenceObjectInput({
											year: residenceObjectInput.year,
											institution: residenceObjectInput.institution,
											location: e.target.value,
										});
									}}
									component="input"
									type="text"
								/>
								{/* <span style={{ width: 30 }}></span> */}
							</FormRow>
							{!isResidenceValid && <Typography.Text type="danger">Please fill all fields.</Typography.Text>}
							<div className="plus-button-wrapper">
								<MainPlusButton type="button" onClick={addResidence}>
									+
								</MainPlusButton>
								<p>{`Click + to confirm entry`}</p>
							</div>

							<FormRow>
								<div className="highlight-wrapper">
									{residenceList &&
										residenceList.map((data, index) => (
											<div className="highlight-items">
												<p key={index}>{`${data.year} , ${data.institution} , ${data.location}`}</p>
												<MainDeleteButton
													type="button"
													onClick={() => {
														removeResidencies(data.year);
													}}
												>
													X{" "}
												</MainDeleteButton>
											</div>
										))}
								</div>
							</FormRow>

							<div className="submit-button-wrapper">
								<MainSubmitButton
									onClick={(e) => {
										setButtonType("save");
										handleSubmit(e);
									}}
								>
									{isLoading && buttonType === "save" ? "Please Wait..." : "Save "}
								</MainSubmitButton>

							</div>
						</Form>
					);
				}}
			</Formik>
		</div>
	);
};

export default AboutUs;
