import React, { useRef } from "react";
import { Dialog, DialogTitle, DialogContent, Slide, CircularProgress } from "@material-ui/core";
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const AddNewBlogBanner = ({ openNewBlogModal, onClickCancelAddBanner, onImageSelect, banner,
    addBannerLoader, onHandleInput, AddBanners, UpdateBanners }) => {
    const attach_media = useRef();

    return (
        <Dialog
            fullScreen
            open={openNewBlogModal}
            onClose={onClickCancelAddBanner}
            TransitionComponent={Transition}
            BackdropProps={{ classes: { root: "dialog-backdrop" } }}
            className="editor-modal-dialog"
        >
            <DialogTitle className="dialog-title">Add Blog Banner</DialogTitle>
            <DialogContent className="editor-modal-content">
                <div className="editor-main-wrapper">
                    {banner.mediaThumb ? (
                        <label className="upload-box bg-white" onClick={(e) => attach_media.current.click()}>
                            <img className="media-thumb" src={banner.mediaThumb} alt="Attach media" />
                        </label>
                    ) : (
                        <div className="upload-box" onClick={(e) => attach_media.current.click()}>
                            <img className="image-upload" src={require("../../../../assets/images/common/AddPhoto.svg")} alt="Upload" />
                            Upload Image
                        </div>
                    )}
                    <input
                        type="file"
                        style={{ display: "none" }}
                        accept=".bmp, .gif, .ico, .jpeg, .jpg, .png, .svg, .tif, .tiff, .jiff, .jfif, .pjpeg, .pjp"
                        onChange={(e) => onImageSelect(e)}
                        ref={attach_media}
                    />
                    <div className="form-control">
                        <label className="form-label">Title</label>
                        <input type="text" autoFocus className="form-input" value={banner.title} onChange={onHandleInput} />
                    </div>

                    <div className="ButtonsWrapper">
                        <button className="CancelButton" onClick={onClickCancelAddBanner}>
                            CANCEL
                        </button>
                        <button className="PublishButton" onClick={() => { banner._id ? UpdateBanners() : AddBanners() }}>
                            PUBLISH
                            {addBannerLoader === true && <CircularProgress size={24} className="circular-loader modal-button-loader" />}
                        </button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}
export default AddNewBlogBanner;