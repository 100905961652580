import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { DialogTitle, DialogContent, CircularProgress } from '@material-ui/core';
import { Checkbox } from 'antd';
import './DeleteMainCategoryDialog.scss';

export default class DeleteMainCategoryDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isForceDelete: false
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevProps.open && this.props.open) {
            this.setState({
                isForceDelete: false
            });
        }
    }

    render() {
        return (
            <Dialog open={this.props.open} onClose={this.props.handleClose} 
                BackdropProps={{ classes: { root: 'dialog-backdrop' } }} className="delete-mainCategory-dialog">
                <img className="close-btn" onClick={this.props.handleClose} src={require('../../../assets/images/common/Close_h.svg')} alt="" />
                <DialogTitle className="dialog-title">Confirmation</DialogTitle>
                <DialogContent className="delete-mainCategory-content">
                    <div className="delete-mainCategory-body">
                        <p>Are you sure you want to delete this category?</p>

                        <div className="checkboxConfirmation">
                            <Checkbox
                                checked={this.state.isForceDelete} value={this.state.isForceDelete}
                                onChange={this.onHandleInput.bind(this)}>
                                Delete all sub categories (If any) ?
                            </Checkbox>
                        </div>
                    </div>
                    <div className="confirm-action">
                        <button className="btn-orange"
                            disabled={this.props.loader}
                            onClick={this.onClickConfirm.bind(this)}>
                            {this.state.isForceDelete ? 'DELETE (Include all sub categories)' : 'DELETE'}
                            {this.props.loader && <CircularProgress size={24} className="circular-loader modal-button-loader" />}
                        </button>
                    </div>
                </DialogContent>
            </Dialog>
        )
    }

    onHandleInput(event) {
        this.setState({ isForceDelete: event.target.checked });
    }

    onClickConfirm() {
        this.props.onConfirm(this.state.isForceDelete);
    }
}
