import {
    GET_SHIPPING_CHARGES_LIST_REQUEST, GET_SHIPPING_CHARGES_LIST_SUCCESS, GET_SHIPPING_CHARGES_LIST_FAILURE,
    ADD_SHIPPING_CHARGES_REQUEST, ADD_SHIPPING_CHARGES_SUCCESS, ADD_SHIPPING_CHARGES_FAILURE,
    UPDATE_SHIPPING_CHARGES_REQUEST, UPDATE_SHIPPING_CHARGES_SUCCESS, UPDATE_SHIPPING_CHARGES_FAILURE,
    DELETE_SHIPPING_CHARGES_REQUEST, DELETE_SHIPPING_CHARGES_SUCCESS, DELETE_SHIPPING_CHARGES_FAILURE, TOGGLE_ADD_SHIPPING_CHARGES_DIALOG
} from "../actions/ActionsTypes";

const initialState = {
    shippingChargesListLoader: false,
    shippingChargesList: [],
    shippingChargesListCount: 0,
    addShippingChargesLoader: false,
    addShippingChargesData: null,
    updateShippingChargesLoader: false,
    updateShippingChargesData: null,
    deleteShippingChargesLoader: false,
    deleteShippingChargesData: null,
    addShippingChargesDialog: false

};

const shippingChargesReducer = (state = initialState, action = {}) => {
    switch (action.type) {

        // ======= Get Shipping Charges List ====== //
        case GET_SHIPPING_CHARGES_LIST_REQUEST:
            state = {
                ...state,
                shippingChargesListLoader: true,
                shippingChargesList: []
            };
            return state;

        case GET_SHIPPING_CHARGES_LIST_SUCCESS:
            state = {
                ...state,
                shippingChargesListLoader: false,
                shippingChargesList: action.data.shippingCharges,
                shippingChargesListCount: action.data.count
            };
            return state;

        case GET_SHIPPING_CHARGES_LIST_FAILURE:
            state = {
                ...state,
                shippingChargesListLoader: false,
                shippingChargesList: [],
                shippingChargesListCount: 0
            };
            return state;

        // ======= Add Shipping Charges ====== //
        case ADD_SHIPPING_CHARGES_REQUEST:
            state = {
                ...state,
                addShippingChargesLoader: true,
                addShippingChargesData: null
            };
            return state;

        case ADD_SHIPPING_CHARGES_SUCCESS:
            state = {
                ...state,
                addShippingChargesLoader: false,
                addShippingChargesDialog: false,
                addShippingChargesData: action.data
            };
            return state;

        case ADD_SHIPPING_CHARGES_FAILURE:
            state = {
                ...state,
                addShippingChargesLoader: false,
                addShippingChargesDialog: true,
                addShippingChargesData: null
            };
            return state;

        // ======= Update Shipping Charges ====== //
        case UPDATE_SHIPPING_CHARGES_REQUEST:
            state = {
                ...state,
                addShippingChargesLoader: true,
                updateShippingChargesData: null
            };
            return state;

        case UPDATE_SHIPPING_CHARGES_SUCCESS:
            state = {
                ...state,
                addShippingChargesLoader: false,
                addShippingChargesDialog: false,
                updateShippingChargesData: action.data
            };
            return state;

        case UPDATE_SHIPPING_CHARGES_FAILURE:
            state = {
                ...state,
                addShippingChargesLoader: false,
                addShippingChargesDialog: true,
                updateShippingChargesData: null
            };
            return state;

        // ======= Delete Shipping Charges ====== //
        case DELETE_SHIPPING_CHARGES_REQUEST:
            state = {
                ...state,
                deleteShippingChargesLoader: true,
                deleteShippingChargesData: null
            };
            return state;

        case DELETE_SHIPPING_CHARGES_SUCCESS:
            state = {
                ...state,
                deleteShippingChargesLoader: false,
                deleteShippingChargesData: action.data
            };
            return state;

        case DELETE_SHIPPING_CHARGES_FAILURE:
            state = {
                ...state,
                deleteShippingChargesLoader: false,
                deleteShippingChargesData: null
            };
            return state;

        // --------- Toggle Dialog's --------- //
        case TOGGLE_ADD_SHIPPING_CHARGES_DIALOG:
            state = {
                ...state,
                addShippingChargesDialog: action.data
            };
            return state;

        default:
            return state;
    }
};

export default shippingChargesReducer;
