import React, { useState } from "react";

import * as Yup from "yup";
import { Formik, FastField } from "formik";
import { Modal, Typography } from "antd";
import StyledButton from "../../../../../../components/StyledButton/StyledButton";
import { YearEventAddForm, YearEventAddFormWrap, YearEventAddInput, YearEventAddInputError, YearEventAddInputLabel, YearEventAddInputWrap, YearEventAddModalTitle } from "./AddYearEventModal.styled";
import FormikYearSelect from "../../../../../../components/FormikYearSelect/FormikYearSelect";

const AddYearEventModal = ({ headTitle, onSaveInfo, onUpdateInfo, isUpdate = false, showLabel = false, loading }) => {
	const MAX_YEAR = new Date().getFullYear();
	const [isSelect, setisSelect] = useState(null);
	const [isSubmit, setisSubmit] = useState(false);


	const onSelect = value => {
		setisSelect(value);
	};
	//----------------Set modalprops values initially------------//

	// const eventName = (modalProps ? (showLabel ? modalProps.events : modalProps.award) : "") || "";

	return (
		<Modal
			title={headTitle}
			open={false}
			handleClose={() => {
				// onCancel();
			}}
			width="25rem"
		>
			<YearEventAddModalTitle>Add your {headTitle}</YearEventAddModalTitle>
			<Formik
				enableReinitialize={true}
				initialValues={{
					// year: modalProps ? modalProps.year : "",
					// textValue: modalProps ? eventName : ""
				}}
				validationSchema={Yup.object().shape({
					textValue: Yup.string().required(showLabel ? "Please enter historical event" : "Please enter award/recoginition"),
					year: Yup.string().required("Please select year").nullable()
				})}
				onSubmit={(values, actions) => {
					if (isUpdate) {
						onUpdateInfo(showLabel, values);
					} else {
						onSaveInfo(showLabel, values);
					}
					setisSubmit(true);
					actions.resetForm();
				}}
			>
				{({ values, errors, touched, handleSubmit }) => {
					return (
						<YearEventAddForm name="awardHistory" method="post" onSubmit={handleSubmit}>
							<YearEventAddFormWrap>
								<YearEventAddInputWrap>
									<YearEventAddInputLabel>Year</YearEventAddInputLabel>
									<FastField
										name="year"
										render={field => (
											<FormikYearSelect {...field} placeholder={"YYYY"} options={MAX_YEAR} isSelect={onSelect} type="text" />
										)}
									/>
									<YearEventAddInputError>
										{errors.year && touched.year && <Typography.Text type="danger">{errors.year}</Typography.Text>}
										{!errors.year && isSelect && !values.year && !isSubmit && (
											<Typography.Text type="danger">Please select year</Typography.Text>
										)}
									</YearEventAddInputError>
								</YearEventAddInputWrap>
								<YearEventAddInputWrap>
									<YearEventAddInputLabel marginleft={"10px"}>
										{showLabel ? "Event" : "Awards / Recognitions"}
									</YearEventAddInputLabel>
									<YearEventAddInput name="textValue" component="input" type="text" />
									<YearEventAddInputError marginleft="4rem">
										{errors.textValue && touched.textValue && <Typography.Text type="danger">{errors.textValue}</Typography.Text>}
									</YearEventAddInputError>
								</YearEventAddInputWrap>
							</YearEventAddFormWrap>
							<StyledButton variant="invert" width="9rem" htmlType="submit" loading={loading} disabled={loading}>
								Save
							</StyledButton>
						</YearEventAddForm>
					);
				}}
			</Formik>
		</Modal>
	);
};

export default AddYearEventModal;
