const assets = {
	images: {
		creditcard: "https://uielement.s3.ap-southeast-1.amazonaws.com/assets/images/credit-card.svg",
		paypal: "https://uielement.s3.ap-southeast-1.amazonaws.com/assets/images/paypal.svg",
		bitcoin: "https://uielement.s3.ap-southeast-1.amazonaws.com/assets/images/bitcoin.svg",
		bitcoin_white: "https://uielement.s3.ap-southeast-1.amazonaws.com/assets/images/bitcoin_white.svg",
		bitcoin_: "https://uielement.s3.ap-southeast-1.amazonaws.com/Icon/bitcoin_.svg",

		deleteIcon: "https://uielement.s3.ap-southeast-1.amazonaws.com/assets/images/delete.svg",
		deleteRedIcon: "https://uielement.s3.ap-southeast-1.amazonaws.com/Icon/delete_icon_red.png",
		editIcon: "https://uielement.s3.ap-southeast-1.amazonaws.com/assets/images/edit.svg",
		profilePicture: "https://uielement.s3.ap-southeast-1.amazonaws.com/assets/images/profilePicture.png",
		cameraIcon: "https://uielement.s3.ap-southeast-1.amazonaws.com/assets/images/camera.svg",
		web: "https://uielement.s3.ap-southeast-1.amazonaws.com/assets/images/web.svg",
		twitterIcon: "https://uielement.s3.ap-southeast-1.amazonaws.com/assets/images/twitterIcon.svg",
		facebookIcon: "https://uielement.s3.ap-southeast-1.amazonaws.com/assets/images/facebookIcon.svg",
		instagramIcon: "https://uielement.s3.ap-southeast-1.amazonaws.com/assets/images/instagramIcon.svg",
		pinterestIcon: "https://uielement.s3.ap-southeast-1.amazonaws.com/assets/images/pinterestIcon.svg",
		linkedinIcon: "https://uielement.s3.ap-southeast-1.amazonaws.com/assets/images/linkedinIcon.svg",
		diamondBulletPoint: "https://uielement.s3.ap-southeast-1.amazonaws.com/assets/images/diamondBulletPoint.svg",
		plusIcon: "https://uielement.s3.ap-southeast-1.amazonaws.com/assets/images/plusAddIcon.svg",
		addPhoto: "https://uielement.s3.ap-southeast-1.amazonaws.com/assets/images/addPhotoImage.svg",
		artistIcon: "https://uielement.s3.ap-southeast-1.amazonaws.com/assets/images/User.svg",
		searchNoFound: "https://uielement.s3.ap-southeast-1.amazonaws.com/assets/images/not-found.svg",
		info: "https://uielement.s3.ap-southeast-1.amazonaws.com/assets/images/info.svg",

		addImageIcon: "https://uielement.s3.ap-southeast-1.amazonaws.com/assets/images/Add_Images.svg",
		cameraIcon: "https://uielement.s3.ap-southeast-1.amazonaws.com/assets/images/camera.svg",
		emptyBanner: "https://uielement.s3.ap-southeast-1.amazonaws.com/assets/images/EmptyBanner.webp",
		emptyArtistDp: "https://uielement.s3.ap-southeast-1.amazonaws.com/assets/images/User_Placehoder.svg",

		artworksActionIcon: "https://uielement.s3.ap-southeast-1.amazonaws.com/assets/images/artworksActionIcon.svg",
		artworkNoImage: "https://uielement.s3.ap-southeast-1.amazonaws.com/assets/images/No_Image.png",
		imagePlace: "https://uielement.s3.ap-southeast-1.amazonaws.com/assets/images/image-plc.svg",
		imageNoPlace: "https://uielement.s3.ap-southeast-1.amazonaws.com/assets/images/image-no-plc.svg",
		imageGreyPlace: "https://uielement.s3.ap-southeast-1.amazonaws.com/assets/images/image-placeholder-grey.svg",
	},
};

export default assets;
