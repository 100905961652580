import {
    GET_ALL_QUOTES_LIST_REQUEST, GET_ALL_QUOTES_LIST_SUCCESS, GET_ALL_QUOTES_LIST_FAILURE,
    UPDATE_QUOTE_PRICE_STATUS_REQUEST, UPDATE_QUOTE_PRICE_STATUS_SUCCESS, UPDATE_QUOTE_PRICE_STATUS_FAILURE
} from "./ActionsTypes";
import { popToast } from "../../components/Toast/Toast";
import { QuotesService } from "../../containers/Quotes/Quotes.service";

// ============================= Get All Quotes List ============================= //
export const getAllQuotesListAction = (body) => {
    return dispatch => {
        dispatch(request());
        QuotesService.getAllQuotesList(body).then(
            response => {
                if (response && response.message === "Success") {
                    dispatch(success(response.data));
                } else if (response && response.message === "Failure") {
                    dispatch(failure(response));
                    popToast.show("Get all quotes list Error : " + response.Message || "Something went wrong while loading quotes list!");
                }
            },
            error => {
                if (error && error.status_message) {
                    popToast.show(error.status_message);
                } else {
                    popToast.show("Something went wrong while loading quotes list!");
                }
                console.log("error dispatched for API error!", error);
                dispatch(failure(error));
            }
        );
    };

    function request() {
        return { type: GET_ALL_QUOTES_LIST_REQUEST };
    }
    function success(data) {
        return { type: GET_ALL_QUOTES_LIST_SUCCESS, data };
    }
    function failure(error) {
        return { type: GET_ALL_QUOTES_LIST_FAILURE, error };
    }
};

// ============================= Update Quotes Price Status ============================= //
export const updateQuotePriceStatusAction = (quoteId, status) => {
    return dispatch => {
        dispatch(request());
        QuotesService.updateQuotePriceStatus(quoteId, status).then(
            response => {
                if (response && response.message === "Success") {
                    dispatch(success(response.data));
                    popToast.show("Updated Successfully");
                } else if (response && response.message === "Failure") {
                    dispatch(failure(response));
                    popToast.show("Update Quote Price Status Error : " + response.Message || "Something went wrong while updating quote price status!");
                }
            },
            error => {
                if (error && error.status_message) {
                    popToast.show(error.status_message);
                } else {
                    popToast.show("Something went wrong while updating quote price status!");
                }
                console.log("error dispatched for API error!", error);
                dispatch(failure(error));
            }
        );
    };

    function request() {
        return { type: UPDATE_QUOTE_PRICE_STATUS_REQUEST };
    }
    function success(data) {
        return { type: UPDATE_QUOTE_PRICE_STATUS_SUCCESS, data };
    }
    function failure(error) {
        return { type: UPDATE_QUOTE_PRICE_STATUS_FAILURE, error };
    }
};
