import React, { Component } from 'react';
import { connect } from "react-redux";
import { CircularProgress } from '@material-ui/core';

import { popToast, Toast } from '../../../components/Toast/Toast';
import { sendOtpAction } from '../../../redux/actions/ForgotPasswordAction';
import './ForgotPassword.scss';

class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            emailId: ""
        };
    }

    render() {
        return (
            <div className="forgot-wrapper">
                <Toast />
                <div className="app-logo">
                    <img src={require("../../../assets/images/common/Logo.svg")} alt="logo" />
                </div>
                <div className="forgot-contents">
                    <p className="page-title">Forgot Password</p>
                    <div className="form-control">
                        <label className="form-label">Email</label>
                        <input
                            type="text"
                            autoFocus
                            className="form-input"
                            value={this.state.emailId}
                            onChange={this.validation('emailId')}
                        />
                    </div>
                    <div className="end-btn">
                        <button className="btn-orange"
                            onClick={this.onsendOtp.bind(this)}
                            disabled={this.props.forgotPasswordReducer.sendOtpLoader}>
                            SEND OTP
                            {this.props.forgotPasswordReducer.sendOtpLoader && <CircularProgress size={24} className="otp-progress" />}
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    onsendOtp() {
        if (this.state.emailId) {
            if (!this.state.emailValidate) {
                popToast.show("Please enter a valid Email");
            }
            else {
                let mailInput = {
                    Email: this.state.emailId
                }
                this.props.sendOtpAction(mailInput);
            }
        }
        else {
            if (this.state.emailId === "") {
                popToast.show("Please Enter Email");
            }
        }
    }

    // Email validation
    validation = (type) => (event) => {
        const emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (type === "emailId") {
            this.setState({ emailId: event.target.value });
            if (!emailReg.test(event.target.value)) {
                this.setState({ emailValidate: false });
            } else {
                this.setState({ emailValidate: true });
            }
        }
    }
}
// --------------------------react-redux connection-------------------------
//mapStateToProps is used to update the component if react's store is changed
const mapStateToProps = state => {
    return {
        forgotPasswordReducer: state.forgotPasswordReducer
    };
};

//mapDispatchToProps is used to inject dispatch function within the component
const mapDispatchToProps = dispatch => {
    return {
        sendOtpAction: (mailId) => {
            dispatch(sendOtpAction(mailId));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);

