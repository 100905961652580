import React, { Component } from "react";
import { Select, Breadcrumb, Cascader } from "antd";
import { connect } from "react-redux";
import "./Topbar.scss";

const Option = Select.Option;

class Topbar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filterValue: "",
			searchValue: "",
			categoryValue: "",
			orderStatus: "",

			priorityValue: "",
			selectedCategoryId: ""
		};

		// If category listing is added here // Please move its value to different reducer
		// or it gets clashed with categories page listing // two api calls at same time
	}
	render() {
		const list = [
			{ title: "Order Status", value: "Orders" },
			{ title: "Gallery Profile", value: "Manage Gallery" },
			{ title: "Artist Profile", value: "Manage Artists" },
			{ title: "Order History", value: "Manage Users" },
			{ title: "Add New Rule", value: "Filter" }
		];
		const demo = list ? list.filter(item => item.title === this.props.title) : [];
		const { categoryList, categoryLoader } = this.props.addBrandsReducer;
		const breadCrumbSeperator = <img className="breadCrumb-separator" src={require("../../assets/images/junk/doubleArrow.svg")} alt="" />;

		return (
			<div className="topbar-wrapper">
				{/* ------------------------------------- Breadcrumbs ------------------------------------- */}
				{this.props.showBreadCrumbs ? (
					<div className="topbar-title">
						{this.props.showHead ? (
							<Breadcrumb separator={breadCrumbSeperator}>
								{demo.map((item, index) => (
									<Breadcrumb.Item key={index}>
										<a
											onClick={() => {
												window.history.back();
											}}
										>
											{item.value}
										</a>
									</Breadcrumb.Item>
								))}
								<Breadcrumb.Item>
									<a>{this.props.title}</a>
								</Breadcrumb.Item>
							</Breadcrumb>
						) : (
							<Breadcrumb separator={breadCrumbSeperator}>
								{this.props.breadCrumbList.map((item, index) => (
									<Breadcrumb.Item key={item._id || index}>
										<a onClick={this.loadSubCategories.bind(this, item)}>{item.categoryName}</a>
									</Breadcrumb.Item>
								))}
							</Breadcrumb>
						)}
					</div>
				) : (
					<div className="topbar-title">{this.props.title}</div>
				)}

				{/* ------------------------------------- Status Filter ------------------------------------- */}
				{this.props.showStatusFilter && (
					<div className="topbar-filter">
						<img className="filter-img" src={require("../../assets/images/common/Filter.svg")} alt="filter" />
						<Select className="filter-field" value={this.props.filterValue.toString()} onChange={this.handleChange("filterValue")}>
							<Option value="">All</Option>
							<Option value={"true"}>Blocked</Option>
							<Option value={"false"}>Active</Option>
						</Select>
					</div>
				)}

				{/* ----------------- ADD BRANDS FILTERS ----------------- */}
				{this.props.showBrandFilters && (
					<div className="topbar-filter">
						<img className="filter-img" src={require("../../assets/images/common/Filter.svg")} alt="filter" />
						<p>
							Show by
							<br />
							Category
						</p>
						<Cascader
							className="topbar-cascader"
							popupClassName="topbar-cascader-menu"
							options={categoryList}
							onChange={this.onChangeCascaderValue.bind(this)}
							notFoundContent="No subcategories found"
							fieldNames={{ label: "categoryName", value: "_id", children: "subCategories" }}
							placeholder="Choose Categories"
						/>

						<Select
							defaultValue={this.state.priorityValue}
							className="filter-field"
							value={this.state.priorityValue}
							onChange={this.handleChange("priorityValue")}
						>
							<Option value="">All</Option>
							<Option value="0">0</Option>
							<Option value="1">1</Option>
						</Select>
					</div>
				)}

				{/* ------------------------------------- Search ------------------------------------- */}
				{this.props.showSearch && (
					<div className="topbar-search">
						<input
							type="text"
							className="search-box-field"
							placeholder="Search.."
							value={this.props.searchValue}
							onChange={this.handleChange("searchValue")}
						/>
					</div>
				)}
				{/* TODO : NOT WORKING */}
				{this.props.showManageBrandFilter && (
					<div className="topbar-brand-filter">
						<img className="filter-img" src={require("../../assets/images/common/Filter.svg")} alt="filter" />
						<p>Show by Category</p>
						<div className="category-filter" onClick={() => this.setState({ showCategoryFilter: !this.state.showCategoryFilter })}>
							<p>Mobile</p>
							<img className="drop-label" src={require("../../assets/images/common/Arrow_down.svg")} alt="drop-down" />
						</div>
						<div className="category-filter" onClick={() => this.setState({ showPriorityFilter: !this.state.showPriorityFilter })}>
							<p>Priority</p>
							<img className="drop-label" src={require("../../assets/images/common/Arrow_down.svg")} alt="drop-down" />
						</div>
					</div>
				)}

				{/* TODO : NOT WORKING */}
				{this.props.showCategoryFilter && (
					<div className="category-modal">
						<div className="category-modal-body">
							<div className="main-category">
								All
								<p>Home & Furniture</p>
								<p>TVs & Appliances</p>
								<p>Electronics</p>
								<p>Women</p>
							</div>
							<div className="sub-category">
								Sub Categories
								<p>Home & Furniture</p>
								<p>TVs & Appliances</p>
								<p>Electronics</p>
								<p>Women</p>
							</div>
							<div className="sub-category-last">
								Sub Categories
								<p>Home & Furniture</p>
								<p>TVs & Appliances</p>
								<p>Electronics</p>
								<p>Women</p>
							</div>
						</div>
					</div>
				)}

				{/* TODO : NOT WORKING */}
				{this.props.showPriorityFilter && (
					<div className="priority-modal">
						<div className="priority-checks">
							<input type="checkbox" />
							<p>All</p>
						</div>
						<div className="priority-checks">
							<input type="checkbox" />
							<p>Top 5</p>
						</div>
						<div className="priority-checks">
							<input type="checkbox" />
							<p>Secondary</p>
						</div>
						<div className="priority-Normal">
							<input type="checkbox" />
							<p>Normal</p>
						</div>
					</div>
				)}
			</div>
		);
	}

	// Handle change for filter /Search fields
	handleChange = name => event => {
		if (name === "filterValue") {
			this.props.onChangeFilter(event);
		} else if (name === "priorityValue") {
			this.setState({ [name]: event }, () => {
				this.doFilterSearchInPages();
			});
		} else {
			this.props.onChangeSearch(event.target.value);
		}
	};

	// ------------------ On pop BreadCrumbs ------------------ //
	loadSubCategories(parent) {
		this.props.loadSubCategories(parent);
	}
	// Filter function in each page after onchange occur in filter field and search field
	// Function calls only after set state : to prevent sync calls
	doFilterSearchInPages() {
		// Add Brands Page
		if (this.props.showBrandFilters) {
			this.props.onSearchFilterBrands(this.state.selectedCategoryId, this.state.priorityValue, this.state.searchValue);
		}

		//Produuct Page
		if (this.props.showProductFilters) {
			this.props.filterByStatusProps(this.state.selectedCategoryId, this.state.filterValue, this.state.searchValue);
		}
	}

	// --------------------------- ADD BRANDS --------------------------- //
	// On select categories
	onChangeCascaderValue(categoryArray) {
		let categoryId = (categoryArray && categoryArray.length > 0 && categoryArray[categoryArray.length - 1]) || "";
		this.setState({ selectedCategoryId: categoryId });
		//Category Filter in Product Screen
		if (this.props.showProductFilters) {
			this.props.filterByStatusProps(categoryId, this.state.filterValue, this.state.searchValue);
		} else {
			//Category Filter in Brands Screen
			this.props.onSearchFilterBrands(categoryId, this.state.priorityValue, this.state.searchValue);
		}
	}
}

const mapStateToProps = state => {
	return {
		manageSellersReducer: state.manageSellersReducer,
		manageUsersReducer: state.manageUsersReducer,
		addBrandsReducer: state.addBrandsReducer
	};
};

const mapDispatchToProps = dispatch => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Topbar);
