import { APIUrlConstants } from "../../../util/apiUrl.constant";
import { APIService } from "../../../util/api.service";
import { Util } from "../../../util/util";

export class BlogService {
	static getBlogList(pageNum, pageSize) {
		let url = APIUrlConstants.getApiUrl("getBlog") + "?pageNum=" + pageNum + "&pageSize=" + pageSize;
		return APIService.doGet(url).then((resp) => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	static updateBlog(blogId, request) {
		let url = APIUrlConstants.getApiUrl("updateBlog") + "?BlogId=" + blogId;
		let formBodyRequest = Util.objectToFormData(request);
		return APIService.doPutMultipart(url, formBodyRequest).then((resp) => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	static deleteBlog(blogId) {
		let url = APIUrlConstants.getApiUrl("deleteBlog") + "?BlogId=" + blogId;
		return APIService.doDelete(url).then((resp) => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	static addBlog(request) {
		let url = APIUrlConstants.getApiUrl("addBlog");
		let formBodyRequest = Util.objectToFormData(request);
		return APIService.doUploadMultipart(url, formBodyRequest).then((resp) => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	/* blog topics */

	static getBlogTopics(pageNum, pageSize) {
		let url = APIUrlConstants.getApiUrl("getBlogTopics") + "?pageNum=" + pageNum + "&pageSize=" + pageSize;
		return APIService.doGet(url).then((resp) => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	static updateBlogTopic(blogTopic, id) {
		let url = APIUrlConstants.getApiUrl("updateBlogTopic") + `?Topic=${blogTopic.topic}&Id=${id}&SeoDescription=${blogTopic.seoDescription}&SeoTitle=${blogTopic.seoTitle}&SlugId=${blogTopic.slugId}`;
		return APIService.doPut(url, "").then((resp) => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	static deleteBlogTopic(blogTopicId) {
		let url = APIUrlConstants.getApiUrl("deleteBlogTopic") + "?Id=" + blogTopicId;
		return APIService.doDelete(url).then((resp) => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	static addBlogTopic(blogTopic) {
		let url = APIUrlConstants.getApiUrl("addBlogTopic") + `?Topic=${blogTopic.topic}&SlugId=${blogTopic.slugId}&SeoDescription=${blogTopic.seoDescription}&SeoTitle=${blogTopic.seoTitle}`;
		return APIService.doPost(url, "").then((resp) => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}
	/*  */

	/* blog banner apis */
	static getBlogBanner(pageNum, pageSize) {
		let url = APIUrlConstants.getApiUrl("getBlogBanner") + "?pageNum=" + pageNum + "&pageSize=" + pageSize;
		return APIService.doGet(url).then((resp) => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	static updateBlogBanner(request) {
		let url = APIUrlConstants.getApiUrl("updateBlogBanner");
		let formBodyRequest = Util.objectToFormData(request);
		return APIService.doPutMultipart(url, formBodyRequest).then((resp) => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	static deleteBlogBanner(Id) {
		let url = APIUrlConstants.getApiUrl("deleteBlogBanner") + "?Id=" + Id;
		return APIService.doDelete(url).then((resp) => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	static addBlogBanner(request) {
		let url = APIUrlConstants.getApiUrl("addBlogBanner");
		let formBodyRequest = Util.objectToFormData(request);
		return APIService.doUploadMultipart(url, formBodyRequest).then((resp) => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	static searchAuthorName(searchText) {
		let url = APIUrlConstants.getApiUrl("authorSearch") + "?text=" + searchText;
		return APIService.doGet(url).then((resp) => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

}
