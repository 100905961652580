import { APIUrlConstants } from "../../util/apiUrl.constant";
import { APIService } from "../../util/api.service";

export class ShippingChargesService {
    // Shipping Charges List
    static getShippingChargesList(pageNum, pageSize, countryName) {
        if (countryName) {
            let url = APIUrlConstants.getApiUrl("getAllShippingCharges") + "?PageNum=" + pageNum + "&PageSize=" + pageSize + "&SearchString=" + countryName;
            return APIService.doGet(url).then(resp => {
                if (resp) {
                    return resp;
                }
                throw resp;
            });
        }
        else {
            let url = APIUrlConstants.getApiUrl("getAllShippingCharges") + "?PageNum=" + pageNum + "&PageSize=" + pageSize;
            return APIService.doGet(url).then(resp => {
                if (resp) {
                    return resp;
                }
                throw resp;
            });
        }
    }

    // Add Shipping Charges
    static addShippingCharges(body) {
        let url = APIUrlConstants.getApiUrl("addShippingCharge");
        return APIService.doPost(url, body).then(resp => {
            if (resp) {
                return resp;
            }
            throw resp;
        });
    }

    // Update Shipping Charges
    static updateShippingCharges(body, id) {
        let url = APIUrlConstants.getApiUrl("updateShippingCharge") + "/" + id;
        return APIService.doPut(url, body).then(resp => {
            if (resp) {
                return resp;
            }
            throw resp;
        });
    }

    // Delete Shipping Charges
    static deleteShippingCharges(id) {
        let url = APIUrlConstants.getApiUrl("deleteShippingCharge") + "/" + id;
        return APIService.doDelete(url).then(resp => {
            if (resp) {
                return resp;
            }
            throw resp;
        });
    }
}
