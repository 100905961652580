import * as types from "../actions/ActionsTypes";

const initialState = {
    couponList: [],
    isPageLoading: false,
    error: '',
    addCouponDailog: false,
    deleteCoupenDialoge: false,
    isLoading: false
}

const manageCouponReducer = (state = initialState, action = {}) => {
    switch (action.type) {

        // ========== Get All Filter ========== //
        case types.GET_ALL_COUPONS_REQUEST:
            state = {
                ...state,
                isPageLoading: true,
                allFilterRules: []
            };
            return state;
        case types.GET_ALL_COUPONS_SUCCESS:
            state = {
                ...state,
                isPageLoading: false,
                couponList: action.data,
            };
            return state;
        case types.GET_ALL_COUPONS_FAILURE:
            state = {
                ...state,
                isPageLoading: false,
                couponList: [],
            };
            return state;

        /* Add coupons */
        case types.ADD_COUPONS_REQUEST:
        case types.UPDATE_COUPONS_REQUEST:
            state = {
                ...state,
                isLoading: true,
            };
            return state;
        case types.ADD_COUPONS_SUCCESS:
        case types.UPDATE_COUPONS_SUCCESS:
            state = {
                ...state,
                isLoading: false,
                addCouponDailog: false
            };
            return state;
        case types.ADD_COUPONS_FAILURE:
        case types.UPDATE_COUPONS_FAILURE:
            state = {
                ...state,
                isLoading: false
            };
            return state;
        /* Open coupon dailog */
        case types.OPEN_ADDCOUPON_DIALOG:
            state = {
                ...state,
                addCouponDailog: action.data
            };
            return state;

        /* delete coupon */

        case types.OPEN_DELETECOUPON_CONFIRMATION_DIALOG:
            state = {
                ...state,
                deleteCoupenDialoge: action.data
            };
            return state;
        case types.DELETE_COUPONS_REQUEST:
            state = {
                ...state,
                isLoading: true,
            };
            return state;
        case types.DELETE_COUPONS_SUCCESS:
            state = {
                ...state,
                isLoading: false,
                deleteCoupenDialoge: false
            };
            return state;
        case types.DELETE_COUPONS_FAILURE:
            state = {
                ...state,
                isLoading: false,
                deleteCoupenDialoge: false
            };
            return state;


        default:
            return state;

    }
}

export default manageCouponReducer;