import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import { DialogTitle, DialogContent, Slide, CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import DatePicker from "react-datepicker";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import "react-datepicker/dist/react-datepicker.css";

// SCSS
import "./EditorModal.scss";

//Components
import { popToast } from "../Toast/Toast";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
class EditorModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "",
            editorState: EditorState.createEmpty(),
            mediaThumb: "",
            media: "",
            website: "",
            newsDate: "",
            content: "",
            publication: "",
            author: "",
            externalImageURL: "",
            link: ""
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevProps.open && this.props.open) {
            const updateData = this.props.data;
            this.setState({
                title: (updateData && updateData.title) || "",
                editorState: (updateData && updateData.body && EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(updateData.body)))) || EditorState.createEmpty(),
                mediaThumb: (updateData && updateData.image) || "",
                website: (updateData && updateData.website) || "",
                newsDate: (updateData && updateData.newsDate) || "",
                content: (updateData && updateData.content) || "",
                publication: (updateData && updateData.publication) || "",
                author: (updateData && updateData.author) || "",
                externalImageURL: (updateData && updateData.externalImageURL) || "",
                link: (updateData && updateData.link) || "",
            });
        }
    }

    render() {
        const { editorState } = this.state;
        return (
            <Dialog
                fullScreen
                open={this.props.open}
                onClose={this.props.handleClose}
                TransitionComponent={Transition}
                BackdropProps={{ classes: { root: "dialog-backdrop" } }}
                className="editor-modal-dialog"
            >
                <DialogTitle className="dialog-title">Add {this.props.type}</DialogTitle>
                <DialogContent className="editor-modal-content">
                    <div className="editor-main-wrapper">

                        {this.state.mediaThumb ? (
                            <label className="upload-box bg-white" onClick={e => this.attach_media.click()}>
                                <img className="media-thumb" src={this.state.mediaThumb} alt="Attach media" />
                            </label>
                        ) : (
                            <div className="upload-box" onClick={e => this.attach_media.click()}>
                                <img className="image-upload" src={require("../../assets/images/common/AddPhoto.svg")} alt="Upload" />
                                Upload Image
                            </div>
                        )}
                        <input
                            type="file"
                            style={{ display: "none" }}
                            accept=".bmp, .gif, .ico, .jpeg, .jpg, .png, .svg, .tif, .tiff, .jiff, .jfif, .pjpeg, .pjp"
                            onChange={e => this.onImageSelect(e)}
                            ref={ref => (this.attach_media = ref)}
                        />
                        <div className="form-control">
                            <label className="form-label">Title</label>
                            <input
                                type="text"
                                autoFocus
                                className="form-input"
                                value={this.state.title}
                                onChange={this.onHandleInput("title")}
                            />
                        </div>
                        {
                            this.props.type === "News" && (
                                <>
                                    <div className="form-control">
                                        <label className="form-label">Publication</label>
                                        <input
                                            type="text"
                                            autoFocus
                                            className="form-input"
                                            value={this.state.publication}
                                            onChange={this.onHandleInput("publication")}
                                        />
                                    </div>
                                    <div className="form-control">
                                        <label className="form-label">Author</label>
                                        <input
                                            type="text"
                                            autoFocus
                                            className="form-input"
                                            value={this.state.author}
                                            onChange={this.onHandleInput("author")}
                                        />
                                    </div>
                                    <div className="form-control">
                                        <label className="form-label">WebSite URL</label>
                                        <input
                                            type="text"
                                            autoFocus
                                            className="form-input"
                                            value={this.state.link}
                                            onChange={this.onHandleInput("link")}
                                        />
                                    </div>
                                    <div className="form-control">
                                        <label className="form-label">Image URL</label>
                                        <input
                                            type="text"
                                            autoFocus
                                            className="form-input"
                                            value={this.state.externalImageURL}
                                            onChange={this.onHandleInput("externalImageURL")}
                                        />
                                    </div>
                                    <div className="form-control">
                                        <label className="form-label">News Date</label>
                                        <DatePicker
                                            dateFormat="yyyy-MM-dd"
                                            selected={this.state.newsDate ? new Date(this.state.newsDate) : null}
                                            className="form-input"
                                            onChange={(date) => this.onHandleDateInput(date)} />
                                    </div>
                                </>
                            )
                        }
                        <Editor
                            toolbar={
                                {
                                    options: ['blockType', 'inline', 'list', 'image'],
                                    inline: {
                                        visible: true,
                                        inDropdown: false,
                                        options: ['bold', 'italic', 'underline'],
                                    },
                                    image: {
                                        urlEnabled: true,
                                        uploadEnabled: false,
                                        alignmentEnabled: true,
                                        previewImage: true,
                                        inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                                        alt: { present: false, mandatory: false },
                                        defaultSize: {
                                            height: 'auto',
                                            width: 'auto',
                                        },
                                    },
                                    inputAccept: 'application/pdf,text/plain,application/vnd.openxmlformatsofficedocument.wordprocessingml.document,application/msword,application/vnd.ms-excel'
                                }
                            }
                            editorState={editorState}
                            onEditorStateChange={this.onEditorStateChange()}
                            editorStyle={{
                                height: "300px",
                                border: "1px solid #E5E5E5",
                                padding: "10px",
                                fontFamily: "Times New Roman",
                                color: "black",
                                fontWeight: "100",
                                width: "100%"
                            }}
                            placeholder="Add some contents here"
                        />
                        <div className="ButtonsWrapper">
                            <button className="CancelButton" onClick={this.onClickCancel.bind(this)}>
                                CANCEL
                            </button>
                            <button disabled={this.props.loader} className="PublishButton" onClick={this.onClickCreate.bind(this)}>
                                PUBLISH
                                {this.props.loader && <CircularProgress size={24} className="circular-loader modal-button-loader" />}
                            </button>
                        </div>
                    </div>
                </DialogContent>

            </Dialog>
        );
    }

    onHandleDateInput = (date) => {
        this.setState({
            newsDate: new Date(date)
        });
    }

    // Handle inputs
    onHandleInput = name => event => {
        this.setState({
            [name]: event.target.value
        });
    };
    // On select image
    onImageSelect(e) {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        let image = "";
        if (file) {
            reader.onloadend = () => {
                this.setState({
                    media: file,
                    mediaThumb: reader.result
                });
                image = reader.result;
            };
            reader.readAsDataURL(file);
        }
    }

    isFormValid() {
        if (!this.state.title.trim()) {
            popToast.show("Please enter title");
            return false;
        }
        if (this.state.editorState) {
            let content = this.state.editorState.getCurrentContent();
            let currentPlainText = content.getPlainText();
            let lengthOfTrimmedContent = currentPlainText.trim().length;
            if (lengthOfTrimmedContent < 1) {
                popToast.show("Please enter contents");
                return false;
            }
            else if (lengthOfTrimmedContent < 10) {
                popToast.show("Content is too short");
                return false;
            }
        }

        if (this.props.type === "News") {

            if (!this.state.externalImageURL.trim() && !this.state.mediaThumb) {
                popToast.show("Please upload an image or enter an External Image URL");
                return false;
            }
            if (this.state.media) {
                if (this.state.media.type && !this.state.media.type.startsWith("image/")) {
                    popToast.show("Please choose a valid image file");
                    return false;
                }
            }
            if (!this.state.author.trim()) {
                popToast.show("Please enter author");
                return false;
            }
            if (!this.state.publication.trim()) {
                popToast.show("Please enter publication");
                return false;
            }
            if (!this.state.link.trim()) {
                popToast.show("Please enter web site link");
                return false;
            }
            if (!this.state.newsDate) {
                popToast.show("Please enter new date");
                return false;
            }

        } else {
            if (!this.state.mediaThumb) {
                if (!this.state.media) {
                    popToast.show("Please choose an image");
                    return false;
                }
                if (this.state.media.type && !this.state.media.type.startsWith("image/")) {
                    popToast.show("Please choose a valid image file");
                    return false;
                }
            }
        }
        return true;
    }
    onEditorStateChange = () => (editorState) => {
        this.setState({
            editorState,
        });
    };

    onClickCreate() {
        if (this.isFormValid()) {
            let request = {};
            if (this.state.media) {
                request = {
                    Title: this.state.title,
                    Body: draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())),
                    BlogImages: this.state.media,
                    Website: this.state.website,
                    NewsDate: this.state.newsDate,
                    Publication: this.state.publication,
                    Author: this.state.author,
                    ExternalImageURL: this.state.externalImageURL,
                    Link: this.state.link
                }
            }
            else {
                request = {
                    Title: this.state.title,
                    Body: draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())),
                    Website: this.state.website,
                    NewsDate: this.state.newsDate,
                    Publication: this.state.publication,
                    Author: this.state.author,
                    ExternalImageURL: this.state.externalImageURL,
                    Link: this.state.link
                }
            }

            if (this.props.update) {
                if (this.props.type === "News") {
                    this.props.updateNews(request, this.props.data._id);
                } else {
                    this.props.updateBlogs(request, this.props.data._id);
                }
            } else {
                if (this.props.type === "News") {
                    this.props.addNews(request);
                } else {
                    this.props.addBlog(request);
                }
            }
        }
    }

    onClickCancel() {
        this.setState({
            ...this.state,
            title: "",
            editorState: EditorState.createEmpty(),
            mediaThumb: "",
            media: "",
        });
        this.props.handleClose();
    }
}

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(EditorModal);
