import * as types from "./ActionsTypes";
import { popToast } from "../../components/Toast/Toast";
import { NewsService } from "../../containers/More/News/News.service";

export function getNewsAction(pageNum, pageSize) {
    return dispatch => {
        dispatch(request());
        NewsService.getNewsList(pageNum, pageSize).then(
            response => {
                if (response.result === 200) {
                    dispatch(success(response.data));
                } else {
                    dispatch(failure(response.message));
                    popToast.show("Error : " + response.Message || "Something went wrong");
                }
            },
            error => {
                if (error && error.status_message) {
                    popToast.show(error.status_message);
                } else {
                    popToast.show("Something went wrong !");
                }
                console.log("error dispatched for API error!", error);
                dispatch(failure(error));
            }
        );
    };

    function request() {
        return { type: types.GET_NEWS_REQUEST };
    }
    function success(data) {
        return { type: types.GET_NEWS_SUCCESS, data };
    }
    function failure(error) {
        return { type: types.GET_NEWS_FAILURE, error };
    }
}

export function updateNewsAction(newsId,body) {
    return dispatch => {
        dispatch(request());
        NewsService.updateNews(newsId, body).then(
            response => {
                if (response.result === 200) {
                    dispatch(success(response));
                    dispatch(getNewsAction(1, 10));
                } else {
                    dispatch(failure(response.message));
                    popToast.show("Error : " + response.Message || "Something went wrong");
                }
            },
            error => {
                if (error && error.status_message) {
                    popToast.show(error.status_message);
                } else {
                    popToast.show("Something went wrong !");
                }
                console.log("error dispatched for API error!", error);
                dispatch(failure(error));
            }
        );
    };

    function request() {
        return { type: types.UPDATE_NEWS_REQUEST };
    }
    function success(data) {
        return { type: types.UPDATE_NEWS_SUCCESS, data };
    }
    function failure(error) {
        return { type: types.UPDATE_NEWS_FAILURE, error };
    }
}

export function deleteNewsAction(newsId) {
    return dispatch => {
        dispatch(request());
        NewsService.deleteNews(newsId).then(
            response => {
                if (response.result === 200) {
                    dispatch(success(response));
                    dispatch(getNewsAction(1, 10));
                } else {
                    dispatch(failure(response.message));
                    popToast.show("Error : " + response.Message || "Something went wrong");
                }
            },
            error => {
                if (error && error.status_message) {
                    popToast.show(error.status_message);
                } else {
                    popToast.show("Something went wrong !");
                }
                console.log("error dispatched for API error!", error);
                dispatch(failure(error));
            }
        );
    };

    function request() {
        return { type: types.DELETE_NEWS_REQUEST };
    }
    function success(data) {
        return { type: types.DELETE_NEWS_SUCCESS, data };
    }
    function failure(error) {
        return { type: types.DELETE_NEWS_FAILURE, error };
    }
}

export function createNewsAction(newsData) {
    return dispatch => {
        dispatch(request());
        NewsService.addNews(newsData).then(
            response => {
                if (response.result === 200) {
                    dispatch(success(response));
                    dispatch(getNewsAction(1, 10));
                } else {
                    dispatch(failure(response.message));
                    popToast.show("Error : " + response.Message || "Something went wrong");
                }
            },
            error => {
                if (error && error.status_message) {
                    popToast.show(error.status_message);
                } else {
                    popToast.show("Something went wrong !");
                }
                console.log("error dispatched for API error!", error);
                dispatch(failure(error));
            }
        );
    };

    function request() {
        return { type: types.ADD_NEWS_REQUEST };
    }
    function success(data) {
        return { type: types.ADD_NEWS_SUCCESS, data };
    }
    function failure(error) {
        return { type: types.ADD_NEWS_FAILURE, error };
    }
}
