import React, { Component } from 'react'
import Root from '../../Root/Root';
import Topbar from '../../../components/Topbar/Topbar';

export default class Report extends Component {
    render() {
        return (
            <Root>
                <Topbar title={"Report"} />
                <div>Report</div>
            </Root>
        );
    }
}

