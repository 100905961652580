/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "antd";
import { includes } from "lodash";
import React, { useState, useEffect } from "react";
import lodash from "lodash";
import StyledButton from "../StyledButton/StyledButton";
import { popToast } from "../Toast/Toast";

const ButtonSet = ({
  buttons,
  CustomButton = null,
  selectedButtons,
  list,
  selectable = true,
  allowOnlySingle = false,
  allowDeselect = true,
  allowedSelectionNo = 0
}) => {
  const [selectedFilters, setSelectedFilters] = useState([]);

  function handleClick(button) {

    if (!selectable) return;
    const ifValueExist = includes(selectedFilters, button.value);
    if (ifValueExist) {
      if (!allowDeselect) {
        return
      }
      setSelectedFilters([
        ...selectedFilters.filter(eachId => eachId !== button.value)
      ]);
      selectedButtons(
        selectedFilters.filter(eachId => eachId !== button.value)
      );
    } else {
      if (allowedSelectionNo > 0 && selectedFilters && selectedFilters.length >= allowedSelectionNo) {
        popToast.show(`You can't select more than ${allowedSelectionNo} styles`);
      } else {
        if (allowOnlySingle) {
          setSelectedFilters([button.value]);
          selectedButtons(button.value);
        } else {
          setSelectedFilters(currValue => {
            return [...currValue, button.value];
          });
          selectedButtons([...selectedFilters, button.value]);
        }
      }
    }

  }
  useEffect(() => {
    if (list) {
      setSelectedFilters(list);
      if (selectedButtons) {
        if (allowOnlySingle) {
          selectedButtons(lodash.get(list, 0, ""));
        } else {
          selectedButtons(list);
        }
      }
    }
  }, [list]);


  return (
    <Button.Group>
      {buttons &&
        buttons.map(button => (
          CustomButton ?
            <CustomButton
              style={{ borderRadius: "0", marginright: 10 }}
              key={button.value}
              // isSelect={selectedFilters.includes(button.value)}
              variant={
                selectedFilters.includes(button.value) ? "invert" : "primary"
              }
              onClick={() => {
                handleClick(button);
              }}
            >
              {button.label}
            </CustomButton>
            :

            <StyledButton
              style={{ borderRadius: "0", marginright: 10 }}
              key={button.label}
              variant={
                selectedFilters.includes(button.value) ? "invert" : "primary"
              }
              onClick={() => {
                handleClick(button);
              }}
            >
              {button.label}
            </StyledButton>
        ))}
    </Button.Group>
  );
};

export default ButtonSet;
