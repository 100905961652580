
import React, { useEffect, useState } from 'react';
import { Dialog, Slide, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import { useDispatch } from 'react-redux';
import { addNewCoupon, updateCoupon } from '../../../redux/actions/ManageCouponsAction';
import { Button } from 'antd';
import './AddCoupon.scss'
import { popToast } from '../../../components/Toast/Toast';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const AddCoupon = ({ couponObject, openCouponDrawer, onCloseCouponDrawer, isLoading,
    onHandleInputChange, isUpdating, coupenId }) => {
    const dispatch = useDispatch();
    const [visible, setVisible] = useState(false);
    const [modalLoader, setmodalLoader] = useState(false);

    const isFormValid = () => {
        if (!couponObject.couponName.trim()) {
            popToast.show('Enter a Coupon name');
            return false;
        }
        if (!couponObject.couponCode.trim()) {
            popToast.show('Enter a Coupon code');
            return false;
        }
        if (isNaN(couponObject.couponPercentage) || !(parseFloat(couponObject.couponPercentage) > 0 && parseFloat(couponObject.couponPercentage) < 100)) {
            popToast.show('Enter a valid percentage');
            return false;
        }

        return true;
    }

    const onSaveCoupon = () => {
        if (isFormValid()) {
            if (isUpdating === false) {
                dispatch(addNewCoupon(couponObject));
            } else {
                dispatch(updateCoupon(couponObject, coupenId));
            }
        }
    }

    return (
        <Dialog
            fullScreen
            open={openCouponDrawer}
            onClose={onCloseCouponDrawer}
            TransitionComponent={Transition}
            BackdropProps={{ classes: { root: "dialog-backdrop" } }}
            className="add-coupon-dialog"
        >
            <img className="close-btn" onClick={() => onCloseCouponDrawer(false)} src={require("../../../assets/images/common/Close_h.svg")} alt="" />
            <DialogTitle className="dialog-title">Add Coupon</DialogTitle>
            <DialogContent className="add-coupon-content">

                {couponObject &&
                    <div className="add-coupon-body">
                        <div className="form-control">
                            <label className="form-label">Coupon Name</label>
                            <input
                                type="text"
                                autoFocus
                                className="form-input"
                                value={couponObject.couponName}
                                disabled={modalLoader}
                                name="couponName"
                                onChange={onHandleInputChange}
                            />
                        </div>
                        <div className="form-control">
                            <label className="form-label">Coupon Code</label>
                            <input
                                type="text"
                                className="form-input"
                                value={couponObject.couponCode}
                                disabled={modalLoader}
                                name="couponCode"
                                onChange={onHandleInputChange}
                            />
                        </div>
                        <div className="form-control">
                            <label className="form-label">Coupon Percentage</label>
                            <input
                                type="text"
                                className="form-input"
                                value={couponObject.couponPercentage}
                                disabled={modalLoader}
                                name="couponPercentage"
                                onChange={onHandleInputChange}
                            />
                        </div>
                        <div className="form-control">
                            <label className="form-label">Coupon Status Type</label>
                            <select
                                className="form-input"
                                value={couponObject.couponStatus}
                                onChange={onHandleInputChange}
                                name="couponStatus"
                                disabled={modalLoader}
                            >
                                <option value="" disabled>
                                    Select
                                </option>
                                <option value="0">Active</option>
                                <option value="1">InActive</option>
                            </select>
                        </div>

                    </div>}
                <hr />

                <div className="add-coupon-action">
                    <Button className="btn-cancel" onClick={onCloseCouponDrawer}>
                        Cancel
                    </Button>
                    <Button className="btn-orange" onClick={onSaveCoupon} loading={isLoading}>
                        {isUpdating !== false ? "Update" : "Save"}
                    </Button>
                </div>

            </DialogContent>
        </Dialog>
    )
}

export default AddCoupon;