import { Button, Row, Select, Table } from "antd";
import styled, { css } from "styled-components";

export const SummaryContentWrapper = styled.div`

`;

export const SummaryDetailsHeading = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 2rem 0;  

  .summary-label {
    font-size: 21px;
    line-height: 25px;
    color: black;
    font-weight: 400;
  }
`;

export const ContentTabButtonWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: end;
    gap: .3rem;
`;

export const TabButtons = styled.div`
    border: 1.5px solid #000000;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.25rem;
    cursor: pointer;
    padding: 0 1rem;
    border-radius: 5px;
    font-weight: 500 !important;
    text-transform: uppercase;

    ${props => props.isActive === true ? css`
         background-color: #000000;
         color: #ffffff;
    `: css`
        background-color: #ffffff;
         color: #000000;
    `}

    &:hover {
         background-color: #000000;
        color: #ffffff;
    }
`;

export const AuctionRow = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: space-between;    
`;

export const AuctionRowItems = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 2rem;
    width: 30%;

     label {
      font-weight: 400 !important;
      font-size: 13px;
      color: #000000;
    }
`;

export const AuctionLotTable = styled(Table)`
    margin:1rem 0;
`

export const EditButton = styled.span`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 136%;
    text-align: center;
    text-decoration-line: underline;
    color: #000000;
`;

export const SortByDropdownWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;

    label {
        font-weight: 700;
        margin-right: 1rem;
    }
`;

export const SortSelectDropDown = styled(Select)`
    width: 9rem;

    .ant-select-selection{
        border: 1.5px solid #000000;
    }
`

export const FilterSelectDropDown = styled(Select)`
    width: 20rem;

    .ant-select-selection{
        border: 1.5px solid #000000;
    }
`;

export const TotalBidCount = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 1rem 0;

    label {
        font-weight: 700;
        margin-right: 1rem;
    }
`

