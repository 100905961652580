import React from "react";
import { Button, Col, Row, Steps } from 'antd';
import "./auctionSummary.scss"
import useAuctionSummary from "./useAuctionSummary";
import moment from 'moment';
import Root from "../../Root/Root";
import Topbar from "../../../components/Topbar/Topbar";
import BiddingHistory from "./BiddingHistory";
import { Util } from "../../../util/util";
import SummaryContent from "./SummaryTableContent/SummaryContent";
import { AuctionRow, AuctionRowItems } from "./AuctionSummary.styled";

const { Step } = Steps;
const steps1 = [
    {
        id: 0,
        title: 'DRAFT',
        content: 'First-content',
    },
    {
        id: 1,
        title: 'VISIBLE',
        content: 'Second-content',
    },
    {
        id: 2,
        title: 'IN PROGRESS',
        content: 'Third-content',
    },
    {
        id: 3,
        title: 'COMPLETED',
        content: 'Fourth-content',
    },

];

const steps2 = [
    {
        id: 0,
        title: 'DRAFT',
        content: 'First-content',
    },
    {
        id: 1,
        title: 'VISIBLE',
        content: 'Second-content',
    },
    {
        id: 3,
        title: 'PAUSED',
        content: 'Last-content',
    },
    {
        id: 5,
        title: 'COMPLETED',
        content: 'Fourth-content',
    },

];

const AuctionSummary = ({ match }) => {
    const { auctionObject, updateAuctionLoader,
        auctionBiddingHistory,
        openBiddingHistoryDrawer, setOpenBiddingHistoryDrawer, onClickEditAuctionDetails, onClickAddAuctionSlot, onClickEditAuctionLot,
        onClickCancel, onClickLaunchAuction, } = useAuctionSummary(match);

    const StepperProgress = () => {
        let stepperContent = auctionObject.status === 4 ? steps2 : steps1;
        return (
            <div className="auc-stepper">
                <Steps current={auctionObject.status === 4 ? 2 : auctionObject.status} >
                    {stepperContent.map(item => (
                        <Step key={item.id} title={item.title} />
                    ))}
                </Steps>
            </div>
        )
    }

    const renderAuctionButton = () => {

        switch (auctionObject.status) {
            /* draft */
            case 0:
                return <Button loading={updateAuctionLoader} onClick={() => onClickLaunchAuction(1)} className="launch-ant-btn">LAUNCH AUCTION</Button>
            /* visible */
            case 1:
                return <Button loading={updateAuctionLoader} onClick={() => onClickLaunchAuction(0)} className="launch-ant-btn"> PAUSE AUCTION</Button>
            /* InProgress */
            case 2:
                return <Button loading={updateAuctionLoader} onClick={() => onClickLaunchAuction(4)} className="launch-ant-btn"> PAUSE AUCTION</Button>
            /* Paused */
            case 4:
                return <Button loading={updateAuctionLoader} onClick={() => onClickLaunchAuction(2)} className="launch-ant-btn"> RESUME AUCTION</Button>
            default:
                return ("");
        }
    };


    return (
        <Root>
            <Topbar title={"Auction summary"} showBreadCrumbs showHead />
            <div id="auction-summary-id">
                <div className="summary-container">
                    <div className="summary-heading">
                        <h1>{auctionObject.name}</h1>
                        {auctionObject.status === 0 && <Button onClick={onClickEditAuctionDetails} className="summary-ant-btn edit-btn">
                            EDIT
                        </Button>}
                    </div>
                    {/* <span>{auctionObject.description}</span> */}
                    <AuctionRow>
                        <Col span={24}>{auctionObject.description}</Col>
                    </AuctionRow>
                    <AuctionRow>
                        <AuctionRowItems className="auctionItem" span={6}>
                            <label>ArtworkType</label>
                            {auctionObject.artworksType === 1 && "PHYSICAL"}
                            {auctionObject.artworksType === 2 && "DIGITAL"}
                        </AuctionRowItems>
                        <AuctionRowItems className="auctionItem" span={6}>
                            <label>SEO Title</label>
                            {auctionObject.seoTitle || "--"}
                        </AuctionRowItems>
                        <AuctionRowItems className="auctionItem" span={6}>
                            <label>SEO Description</label>
                            {auctionObject.seoDescription || "--"}
                        </AuctionRowItems>
                        <AuctionRowItems className="auctionItem" span={6}>
                            <label>SlugId</label>
                            {auctionObject.slugId}
                        </AuctionRowItems>
                    </AuctionRow>
                    <AuctionRow>
                        <AuctionRowItems className="auctionItem" span={6}>
                            <label>Keywords</label>
                            {auctionObject.keywords || "--"}
                        </AuctionRowItems>
                        <AuctionRowItems className="auctionItem" span={6}>
                            <label>Smart Contract Name</label>
                            {auctionObject.contractName || "--"}
                        </AuctionRowItems>
                        <AuctionRowItems className="auctionItem" span={6}>
                            <label>Smart Contract Address</label>
                            {auctionObject.contractAddress}
                        </AuctionRowItems>
                        <AuctionRowItems className="auctionItem" span={6}>

                        </AuctionRowItems>
                    </AuctionRow>
                    <hr className="hr-divider" />

                    <section className="auc-details">
                        {/* {showDetails === false && <Divider style={{ cursor: 'pointer' }} onClick={() => setShowDetails(!showDetails)}>View detailed information <Icon type="down"></Icon></Divider>}

                        {showDetails === true && <SummaryDetails />} */}
                        <SummaryContent auctionId={match.params.auctionId} auctionObject={auctionObject} onClickAddAuctionSlot={onClickAddAuctionSlot} onClickEditAuctionLot={onClickEditAuctionLot} />


                    </section>
                    <hr className="hr-divider" />

                    <div className="auc-time-sec">
                        <div className="auc-start-date">
                            <h2>Auction Start Date and Time ({Util.getCurrentTimeZoneName()})</h2>
                            <div className="auc-date-time">
                                <span className="auc-date-day">{moment(auctionObject.startDate).format('DD')}</span>
                                <span> <span>{moment(auctionObject.startDate).format('MMMM')}</span><br /><span>{moment(auctionObject.startDate).format('YYYY')}</span> </span>
                                {auctionObject.status === 0 && <Button className="summary-ant-btn" onClick={onClickEditAuctionDetails}>CHANGE</Button>}
                            </div>
                            <h2>{moment(auctionObject.startDate).format('HH : mm : ss')}</h2>
                        </div>
                        <div className="auc-end-date">
                            <h2>Auction End Date and Time ({Util.getCurrentTimeZoneName()})</h2>
                            <div className="auc-date-time">
                                <span className="auc-date-day">{moment(auctionObject.endDate).format('DD')}</span>
                                <span> <span>{moment(auctionObject.endDate).format('MMMM')}</span><br /><span>{moment(auctionObject.endDate).format('YYYY')}</span> </span>
                                {auctionObject.status === 0 && <Button className="summary-ant-btn" onClick={onClickEditAuctionDetails}>CHANGE</Button>}
                            </div>
                            <h2>{moment(auctionObject.endDate).format('HH : mm : ss')}</h2>
                        </div>
                    </div>
                    <hr />
                    <StepperProgress />
                    <hr className="hr-divider" />
                    <div className="flx-grw-row launch-auction-btn">
                        <Button className="summary-ant-btn" onClick={onClickCancel} >CANCEL</Button>
                        {renderAuctionButton()}
                    </div>
                </div>
            </div>
        </Root>
    );
}

export default AuctionSummary;