import React, { Component } from "react";
import { Checkbox } from "antd";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import { DialogTitle, DialogContent, CircularProgress, DialogActions } from "@material-ui/core";
// Components
import { popToast } from "../../../components/Toast/Toast";
// Redux
import { connect } from "react-redux";
import { createCategoryProperty } from "../../../redux/actions/CategoryAction";
// SCSS
import "./AddPropertyDialog.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

class AddPropertyDialog extends Component {
	constructor(props) {
		super(props);
		this.state = {
			propertyName: "",
			dataType: "",
			unit: "",
			isMandatory: false,
			isSuggestion: false,
			propertyId: "",
			showFilters: true,
			showSuggestion: true,

			filters: this.generateInitialFilter(),
			suggestion: [],
			stringOptions: [
				{ value: "0", name: "Equal" },
				{ value: "4", name: "Contains" }
			],
			intOptions: [
				{ value: "0", name: "Equal" },
				{ value: "1", name: "Greater Than" },
				{ value: "2", name: "Less Than" },
				{ value: "3", name: "Greater Than Or Equal" },
				{ value: "4", name: "Less Than Or Equal" },
				{ value: "5", name: "InBetween" }
			],
			boolOptions: [{ value: "0", name: "Equal" }]
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if (!prevProps.open && this.props.open) {
			const propertyData = this.props.updatingPropertyData;
			let filtersSet = (propertyData && this.formattedFilterSet(propertyData.filters)) || this.generateInitialFilter();

			// Adding index value to all filters
			filtersSet.map((filter, index) => {
				//filter["index"] = this.generateKey();
				filter["value"] = filter.value.toString();
				filter["condition"] = filter.condition.toString();
			});
			let suggestionSet = [];
			propertyData &&
				propertyData.propertySuggestions &&
				propertyData.propertySuggestions.length !== 0 &&
				propertyData.propertySuggestions.map((item, index) => {
					let test = { value: item.toString() };
					suggestionSet.push(test);
				});
			this.setState({
				propertyName: (propertyData && propertyData.propertyName) || "",
				dataType: (propertyData && propertyData.dataType.toString()) || "",
				unit: (propertyData && propertyData.unit) || "",
				isMandatory: (propertyData && propertyData.manadatory) || false,
				isSuggestion: (propertyData && propertyData.chooseFromSuggestion) || false,
				propertyId: (propertyData && propertyData._id) || "",
				filters: filtersSet,
				suggestion: suggestionSet
			});
		}
	}

	// To remove null rangeValue params, if there are any!
	formattedFilterSet(filterArray) {
		filterArray.map((item, index) => {
			if (!item.rangeValue) {
				delete item["rangeValue"];
			}
		});
		return filterArray;
	}

	render() {
		const { modalLoader } = this.props.categoryReducer;

		// ---------------------------- Filter Form  ---------------------------------- //
		const filterForm = this.state.filters.map((filterBody, index) => {
			return (
				<div className="add-property-body" key={index}>
					<div className="form-control">
						<label className="form-label">Display Text</label>
						<input
							type="text"
							className="form-input"
							value={filterBody.displayText}
							disabled={modalLoader}
							onChange={this.onUpdateText.bind(this, index, "displayText")}
						/>
					</div>
					<div className="form-control">
						<label className="form-label">Filter Condition</label>
						<select
							className="form-input"
							value={filterBody.condition}
							disabled={modalLoader}
							onChange={this.onUpdateText.bind(this, index, "condition")}
						>
							<option value="" disabled>
								Select
							</option>
							{this.state.dataType === "0" &&
								this.state.stringOptions.map((item, index) => (
									<option key={index} value={item.value}>
										{item.name}
									</option>
								))}

							{(+this.state.dataType === 1 || +this.state.dataType === 2) &&
								this.state.intOptions.map((item, index) => (
									<option key={index} value={item.value}>
										{item.name}
									</option>
								))}

							{this.state.dataType === "3" &&
								this.state.boolOptions.map((item, index) => (
									<option key={index} value={item.value}>
										{item.name}
									</option>
								))}
						</select>
					</div>
					<div className="form-control">
						<label className="form-label">{filterBody.condition.toString() === "5" ? "Minimum value" : "Filter Value"}</label>
						<input
							type="text"
							className="form-input"
							value={filterBody.value}
							disabled={modalLoader}
							onChange={this.onUpdateText.bind(this, index, "value")}
						/>
					</div>
					{filterBody.condition.toString() === "5" && (
						<div className="form-control">
							<label className="form-label">Maximum Value</label>
							<input
								type="text"
								className="form-input"
								value={filterBody.rangeValue}
								disabled={modalLoader}
								onChange={this.onUpdateText.bind(this, index, "rangeValue")}
							/>
						</div>
					)}
					<img
						className="filter-close-btn"
						onClick={this.onClickAddFilter.bind(this, index, "POP")}
						src={require("../../../assets/images/common/Close_h.svg")}
						alt=""
					/>
				</div>
			);
		});

		const suggestionBox = this.state.suggestion.map((filterBody, index) => {
			return (
				<div className="add-property-body" key={index}>
					<div className="form-control">
						<input
							type="text"
							className="form-input"
							value={filterBody.value}
							disabled={modalLoader}
							onChange={this.onAddSuggestion("suggestionText", index)}
						/>
					</div>
					<img
						className="close-btn"
						onClick={this.onClickAddSuggestion.bind(this, index, "POP")}
						src={require("../../../assets/images/common/Close_h.svg")}
						alt=""
					/>
				</div>
			);
		});
		return (
			<Dialog
				fullScreen
				open={this.props.open}
				onClose={this.props.handleClose}
				TransitionComponent={Transition}
				BackdropProps={{ classes: { root: "dialog-backdrop" } }}
				className="add-property-dialog"
			>
				<img className="close-btn" onClick={this.props.handleClose} src={require("../../../assets/images/common/Close_h.svg")} alt="" />
				<DialogTitle className="dialog-title">Add Property</DialogTitle>
				<DialogContent className="add-property-content">
					<div className="add-property-content-wrapper">
						<div className="dialog-sub-title">Properties Details</div>

						<div className="add-property-body">
							<div className="form-control">
								<label className="form-label">Property Name</label>
								<input
									type="text"
									autoFocus
									className="form-input"
									value={this.state.propertyName}
									disabled={modalLoader}
									onChange={this.onHandleInput("propertyName")}
								/>
							</div>
							<div className="form-control">
								<label className="form-label">Data Type</label>
								<select
									className="form-input"
									value={this.state.dataType}
									onChange={this.onHandleInput("dataType")}
									disabled={modalLoader}
								>
									<option value="" disabled>
										Select
									</option>
									<option value="0">String</option>
									<option value="1">Integer</option>
									<option value="2">Double</option>
									<option value="3">Bool</option>
									<option value="4">StringList</option>
									{/* <option value="5">HTMLContent</option> */}
								</select>
							</div>
							<div className="form-control">
								<label className="form-label">Unit</label>
								<input
									type="text"
									className="form-input"
									value={this.state.unit}
									disabled={modalLoader}
									onChange={this.onHandleInput("unit")}
								/>
							</div>

							<div className="add-property-featured-box">
								<Checkbox
									checked={this.state.isMandatory}
									value={this.state.isMandatory}
									onChange={this.onHandleInput("isMandatory")}
								/>
								Mandatory ?
							</div>
						</div>
						<hr />
					</div>
					{this.state.showSuggestion && (
						<div className="add-suggestion-wrapper">
							<div className="suggestion-details">
								<div className="dialog-sub-title">
									<span>Suggestions</span>
									<button className="add-filter-button" onClick={this.onClickAddSuggestion.bind(this, null, "PUSH")}>
										+ Add
									</button>
								</div>
								<div className="suggestion-list">{suggestionBox}</div>
								{this.state.suggestion.length > 0 && (
									<div className="add-property-featured-box">
										<Checkbox
											checked={this.state.isSuggestion}
											value={this.state.isSuggestion}
											onChange={this.onHandleInput("isSuggestion")}
										/>
										Choose only from Suggestions
									</div>
								)}
							</div>
						</div>
					)}
					{this.state.showFilters && (
						<div className="add-property-filter-wrapper">
							<div className="dialog-sub-title">
								<span>Add Filter</span>
								<button className="add-filter-button" onClick={this.onClickAddFilter.bind(this, null, "PUSH")}>
									Add
								</button>
							</div>

							<div className="filter-form-wrapper custom-scroll">{filterForm}</div>
						</div>
					)}
				</DialogContent>
				<DialogActions className="add-property-action">
					<button className="btn-cancel" onClick={this.onClickCancel.bind(this)}>
						Cancel
					</button>
					<button className="btn-orange" onClick={this.onClickCreate.bind(this)} disabled={modalLoader}>
						{this.props.isUpdatingProperty ? "Update" : "Save"}
						{modalLoader && <CircularProgress size={24} className="circular-loader modal-button-loader" />}
					</button>
				</DialogActions>
			</Dialog>
		);
	}

	// Handle inputs
	onHandleInput = name => event => {
		name === "isMandatory" || name === "isSuggestion"
			? this.setState({
					[name]: event.target.checked
			  })
			: this.setState(
					{
						[name]: event.target.value
					},
					() => {
						// Filters not allowed for Data type >> StringList and HTML Content
						// Suggestions not allowed for Data type >> Boolean and HTML Content
						if (+this.state.dataType === 4 || +this.state.dataType === 5) {
							this.setState({
								showFilters: false,
								filters: [],
								showSuggestion: false,
								suggestion: []
							});
						} else if (+this.state.dataType === 3) {
							this.setState({
								showSuggestion: false,
								suggestion: [],
								showFilters: true,
								filters: this.state.filters
							});
						} else {
							this.setState({
								showFilters: true,
								showSuggestion: true,
								suggestion: this.state.suggestion,
								filters: this.state.filters
							});
						}
					}
			  );
	};

	// Generate Unique Key for filters
	generateKey = (pre = "filters") => {
		return `${pre}_${new Date().getTime()}`;
	};

	generateInitialFilter() {
		return [
			{
				// index: this.generateKey(),
				displayText: "",
				value: "",
				condition: "0"
			}
		];
	}
	onClickAddSuggestion(index, task) {
		if (task === "PUSH") {
			let newSuggesstion = {
				value: ""
			};

			this.setState({
				suggestion: [...this.state.suggestion, newSuggesstion]
			});
		} else if (task === "POP") {
			// this.setState({ suggestion: this.state.suggestion.splice(index, 1) });
			this.setState({ suggestion: this.state.suggestion.filter((item, i) => i !== index) });
		}
	}
	// Add/Remove filter
	onClickAddFilter(index, task) {
		if (task === "PUSH") {
			let newFilter = {
				//index: this.generateKey(),
				displayText: "",
				value: "",
				condition: "0"
			};

			this.setState({
				filters: [...this.state.filters, newFilter]
			});
		} else if (task === "POP") {
			this.setState({
				filters: this.state.filters.filter((filterData, i) => i !== index)
			});
		}
	}

	// On change events in filters
	onUpdateText(id, inputField, event) {
		// On handle change event for inputs inside filter array
		this.state.filters.filter((obj, index) => {
			if (index === id) {
				obj[inputField] = event.target.value;
			}
		});
		// let changingFilter = this.state.filters.find(function(filterItem) {
		// 	return filterItem.index === id;
		// });
		// changingFilter[inputField] = event.target.value;

		// Adding/Removing rangeValue param to corresponding object whenever condition is 'InBetween'
		if (inputField === "condition" && event.target.value === "5") {
			this.state.filters.filter((obj, index) => {
				if (index === id) {
					obj["rangeValue"] = "";
				}
			});
			// let changingFilterObj = this.state.filters.find(function(filterItem) {
			// 	return filterItem.index === id;
			// });
			// changingFilterObj["rangeValue"] = "";
		} else if (inputField === "condition" && event.target.value !== "5") {
			this.state.filters.filter((obj, index) => {
				if (index === id) {
					delete obj["rangeValue"];
				}
			});
			// let changingFilterObj = this.state.filters.find(function(filterItem) {
			// 	return filterItem.index === id;
			// });
			// delete changingFilterObj["rangeValue"];
		}

		// State changed using setState - to update the component
		this.setState({ filters: this.state.filters });
	}

	onAddSuggestion = (name, index) => event => {
		const { suggestion } = this.state;
		if (name === "suggestionText") {
			suggestion[index].value = event.target.value;
		}
		this.setState({
			suggestion: suggestion
		});
	};

	// Sign In : Sign in form validation
	isFormValid() {
		let found = this.state.suggestion.some(item => {
			if (!item.value) {
				return true;
			} else {
				if (this.state.dataType === "1" || this.state.dataType === "2") {
					var numbers = /^[0-9]+(\.[0-9][0-9]?)?/;
					// /^[0-9]+$/;
					if (item.value.match(numbers)) {
						return false;
					} else {
						return true;
					}
				}
			}
		});
		if (!this.state.propertyName.trim()) {
			popToast.show("Enter a property name");
			return false;
		}
		if (!this.state.dataType) {
			popToast.show("Choose a data type");
			return false;
		}
		// if (!this.state.unit.trim()) {
		//     popToast.show('Choose a unit');
		//     return false;
		// }
		if (!this.props.categoryReducer.selectedCategoryId) {
			popToast.show("Choose the category for adding property");
			return false;
		}
		if (found) {
			popToast.show("Please make sure all suggestion values are valid!");
			return false;
		}
		if (this.state.filters.length > 0) {
			let found = this.state.filters.find(function(element) {
				let range = (element.rangeValue && element.rangeValue.toString()) || "";
				if (!element.displayText.trim()) {
					return element;
				} else if (!element.value.toString().trim()) {
					return element;
				} else if (element.condition === 5 && element.rangeValue !== undefined && !range.trim()) {
					return element;
				}
			});
			if (found) {
				popToast.show("Please make sure all filter values are valid!");
				return false;
			} else return true;
		}
		return true;
	}

	onClickCreate(e) {
		e.preventDefault();
		// Currently opened parent category list
		// To refresh list
		let currentParentId = this.props.categoryReducer.currentParentId;

		// ID : To which property is being added
		// To enable selection & to pass categoryId to API
		let categoryId = this.props.categoryReducer.selectedCategoryId;

		if (this.isFormValid()) {
			let demoArray = [];
			this.state.suggestion.map(item => {
				demoArray.push(item.value);
			});
			let request = {
				CategoryId: categoryId,
				PropertyName: this.state.propertyName,
				DataType: +this.state.dataType,
				Unit: this.state.unit,
				ChooseFromSuggestion: this.state.isSuggestion,
				PropertySuggestions: demoArray,
				Filters: this.state.filters,
				Manadatory: this.state.isMandatory
			};
			// isUpdatingProperty - to differentiate if we are updating or creating
			// propertyId - passed only when updating - since it is passed as param
			// currentParentId - to open the already open parent
			this.props.createCategoryProperty(this.props.isUpdatingProperty, this.state.propertyId || "", currentParentId, request);
		}
	}

	onClickCancel() {
		this.props.handleClose();
	}
}

const mapStateToProps = state => {
	return {
		categoryReducer: state.categoryReducer
	};
};

const mapDispatchToProps = dispatch => {
	return {
		// Create Prop/ Update Prop are called using same actions
		// only 'isUpdatingProperty' value is false/true differs
		createCategoryProperty: (isUpdatingProperty, propertyId, currentParentId, request) => {
			dispatch(createCategoryProperty(isUpdatingProperty, propertyId, currentParentId, request));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPropertyDialog);
