import React, { Component } from "react";
import { Select, Skeleton } from "antd";
import { connect } from "react-redux";
import Root from "../Root/Root";
import Topbar from "../../components/Topbar/Topbar";
import LineChartGraph from "../../components/LineChart/LineChart";
import { getDashboardDetails } from "../../redux/actions/DashboardAction";

import "./Dashboard.scss";
const Option = Select.Option;

class Dashboard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentYear: new Date().getFullYear(),
		};
	}
	componentDidMount() {
		this.props.getDashboardDetails(this.state.currentYear);
	}

	renderYearOption() {
		for (let i = 2015; i < this.state.currentYear; i++) {
			return <Option value={i}>{i}</Option>;
		}
	}
	render() {
		const { dashboardDetails, dashboardDetailsLoader } = this.props.dashboardReducer;

		return (
			<Root>
				<Topbar title={"Dashboard"} />
				<Skeleton loading={dashboardDetailsLoader} paragraph={{ rows: 15 }} active>
					<div className="dashboard-wrapper">
						{/* First Row */}
						<div className="dashboard-wrapper-totallist ptop">
							<div className="dashboard-wrapper-totallist-item clickable" onClick={() => this.props.history.push(`/manage-users/1`)}>
								<div className="small-box">
									<p className="title">{dashboardDetails && dashboardDetails.totalUsers ? dashboardDetails.totalUsers : 0}</p>
									<p className="title-text">Users</p>
								</div>
								<img src={require("../../assets/images/common/Buyers.svg")} alt="seller" />
							</div>
							<div className="dashboard-wrapper-totallist-item clickable" onClick={() => this.props.history.push(`/manage-artists/1`)}>
								<div className="small-box">
									<p className="title">{dashboardDetails && dashboardDetails.artistCount ? dashboardDetails.artistCount : 0}</p>
									<p className="title-text">Artists</p>
								</div>
								<img src={require("../../assets/images/common/Sellers.svg")} alt="seller" />
							</div>
							<div className="dashboard-wrapper-totallist-item">
								<div className="small-box">
									<p className="title">{dashboardDetails && dashboardDetails.galleryCount ? dashboardDetails.galleryCount : 0}</p>
									<p className="title-text">Gallery</p>
								</div>
								<img src={require("../../assets/images/common/Sellers.svg")} alt="seller" />
							</div>
							<div className="dashboard-wrapper-totallist-item clickable" onClick={() => this.props.history.push(`/manage-artworks/1`)}>
								<div className="small-box">
									<p className="title">{dashboardDetails && dashboardDetails.artworksCount ? dashboardDetails.artworksCount : 0}</p>
									<p className="title-text">Total Artworks</p>
								</div>
								<img src={require("../../assets/images/common/Product.svg")} alt="seller" />
							</div>
							<div className="dashboard-wrapper-totallist-item">
								<div className="small-box">
									<p className="title">{dashboardDetails && dashboardDetails.orderCount ? dashboardDetails.orderCount : 0}</p>
									<p className="title-text">Total Sold</p>
								</div>
								<img src={require("../../assets/images/common/Sold.svg")} alt="seller" />
							</div>
						</div>

						{/* Second Row */}
						<div className="dashboard-wrapper-totallist pbot">
							<div className="dashboard-wrapper-totallist-item clickable" onClick={() => this.props.history.push(`/user-requests`)}>
								<div className="small-box">
									<p className="title">
										{dashboardDetails && dashboardDetails.pendingArtistsRequests ? dashboardDetails.pendingArtistsRequests : 0}
									</p>
									<p className="title-text">Pending Artist</p>
								</div>
								<img src={require("../../assets/images/common/Buyers.svg")} alt="seller" />
							</div>
							<div className="dashboard-wrapper-totallist-item clickable" onClick={() => this.props.history.push(`/orders/1?Filter=3`)}>
								<div className="small-box">
									<p className="title">{dashboardDetails && dashboardDetails.pendingOrders ? dashboardDetails.pendingOrders : 0}</p>
									<p className="title-text">New Orders</p>
								</div>
								<img src={require("../../assets/images/common/Sellers.svg")} alt="seller" />
							</div>
							<div className="dashboard-wrapper-totallist-item clickable" onClick={() => this.props.history.push(`/user-requests`)}>
								<div className="small-box">
									<p className="title">
										{dashboardDetails && dashboardDetails.pendingGalleryRequests ? dashboardDetails.pendingGalleryRequests : 0}
									</p>
									<p className="title-text">Pending Galleries</p>
								</div>
								<img src={require("../../assets/images/common/Sellers.svg")} alt="seller" />
							</div>
							<div
								className="dashboard-wrapper-totallist-item clickable"
								onClick={() => this.props.history.push(`/manage-artworks/1?Filter=5&Search=`)}
							>
								<div className="small-box">
									<p className="title">
										{dashboardDetails && dashboardDetails.pendingProducts ? dashboardDetails.pendingProducts : 0}
									</p>
									<p className="title-text">Pending Artworks</p>
								</div>
								<img src={require("../../assets/images/common/Product.svg")} alt="seller" />
							</div>
							<div className="dashboard-wrapper-totallist-item clickable" onClick={() => this.props.history.push(`/quotes/1`)}>
								<div className="small-box">
									<p className="title">{dashboardDetails && dashboardDetails.recentQuotes ? dashboardDetails.recentQuotes : 0}</p>
									<p className="title-text">Recent Quotes</p>
								</div>
								<img src={require("../../assets/images/common/Product.svg")} alt="seller" />
							</div>
							{/* <div className="dashboard-wrapper-totallist-item hidden" /> */}
						</div>

						<div className="dashboard-wrapper-graph">
							<div className="dashboard-wrapper-graph-title">
								<p className="title">PRODUCT SELLING SUMMARY</p>
								<div className="filter-year">
									<p>Year</p>
									<Select
										defaultValue={this.state.currentYear}
										className="filter-field"
										value={this.state.currentYear}
										onChange={this.onYearChange("currentYear")}
									>
										<Option value={2023}>2023</Option>
										<Option value={2022}>2022</Option>
										<Option value={2021}>2021</Option>
										<Option value={2020}>2020</Option>
										<Option value={2019}>2019</Option>
										<Option value={2018}>2018</Option>
										<Option value={2017}>2017</Option>
										<Option value={2016}>2016</Option>
										<Option value={2015}>2015</Option>
									</Select>
								</div>
							</div>
							{dashboardDetails.graphDetails && <LineChartGraph graphvalue={dashboardDetails.graphDetails} />}
							<div className="product-summary">
								<div className="product-summary-info">
									<p className="product-summary-info-title">Total Amount</p>
									<p className="product-summary-info-value">
										{dashboardDetails && dashboardDetails.totalAmount ? `USD ${dashboardDetails.totalAmount}` : "-"}
									</p>
								</div>
								<div className="product-summary-info">
									<p className="product-summary-info-title">Average</p>
									<p className="product-summary-info-value">
										{dashboardDetails && dashboardDetails.average ? `USD ${dashboardDetails.average}` : "-"}
									</p>
								</div>
								<div className="product-summary-info">
									<p className="product-summary-info-title">Percentage</p>
									<p className="product-summary-info-value">{dashboardDetails && dashboardDetails.percentage}%</p>
								</div>
							</div>
						</div>
					</div>
				</Skeleton>
			</Root>
		);
	}
	onYearChange = (name) => (event) => {
		this.setState(
			{
				[name]: event,
			},
			() => {
				this.props.getDashboardDetails(this.state.currentYear);
			}
		);
	};
}

const mapStateToProps = (state) => {
	return {
		dashboardReducer: state.dashboardReducer,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getDashboardDetails: (year) => {
			dispatch(getDashboardDetails(year));
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Dashboard);
