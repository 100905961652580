import { isEmpty } from "lodash";
import { useEffect, useRef, useState } from "react";
import { popToast } from "../../../../components/Toast/Toast";
import { AuctionService } from "../../AuctionService";

const useSummaryContent = (auctionId) => {
    const [auctionLotFilter, setAuctionLotFilter] = useState({
        auctionId: "",
        auctionSlugId: null,
        pageNum: 1,
        pageSize: 10,
        sortOrder: 0,
        minPrice: null,
        maxPrice: null,
        searchText: null,
        SortType: 0,
        NoBids: false,
        ActiveBids: false,
        PopularBids: false,
        Saved: false,
        Featured: false,
        LotResult: [],
    });
    const [auctionLotObjects, setAuctionLotObjects] = useState([]);
    const [auctionListCount, setAuctionListCount] = useState(0);
    const [totalBidCount, setTotalBidCount] = useState(0);
    const [deleteLotLoader, setDeleteLotLoader] = useState(false);
    const [updateLotLoader, setUpdateLotLoader] = useState(false);
    const [swapLoader, setSwapLoader] = useState(false);
    const [activeTab, setActiveTab] = useState("artwork");
    const [getAuctionLotLoader, setGetAuctionLotLoader] = useState(false);

    const [auctionBiddingHistory, setAuctionBiddingHistory] = useState([]);
    const [getAuctionBiddingHistoryLoader, setGetAuctionBiddingHistoryLoader] = useState(false);
    const [openBiddingHistoryDrawer, setOpenBiddingHistoryDrawer] = useState(false);

    const timer = useRef(null);
    const searchLotFilterRef = useRef();

    useEffect(() => {
        getSelectedAuctionLots();
    }, [])

    useEffect(() => {
        getSelectedAuctionLots();
    }, [auctionLotFilter.pageNum, auctionLotFilter.sortOrder, auctionLotFilter.LotResult]);

    useEffect(() => {
        clearTimeout(timer.current);
        timer.current = setTimeout((value) => {
            getSelectedAuctionLots(value);
        }, 1250, auctionLotFilter.searchText);
        searchLotFilterRef && searchLotFilterRef.current && searchLotFilterRef.current.focus();

    }, [auctionLotFilter.searchText]);

    const getSelectedAuctionLots = (searchText = null) => {
        try {
            setGetAuctionLotLoader(true);
            let filter = {
                ...auctionLotFilter,
                auctionId: auctionId, searchText: searchText,
                LotResult: isEmpty(auctionLotFilter.LotResult) ? null : auctionLotFilter.LotResult
            };
            AuctionService.getAuctionLotList(filter).then(resp => {
                if (resp && resp.result === 200) {
                    setAuctionLotObjects(resp.data.auctionLotList);
                    setAuctionListCount(resp.data.count);
                    setTotalBidCount(resp.data.totalBidCount);
                    searchLotFilterRef && searchLotFilterRef.current && searchLotFilterRef.current.focus();

                } else if (resp && resp.result === 400) {
                    popToast.show(resp.message);
                }
                setGetAuctionLotLoader(false);
            })
        } catch (error) {
            setGetAuctionLotLoader(false);
        }

    };

    const searchAuctionLots = (e) => {
        const { value } = e.target;
        setAuctionLotFilter(s => ({ ...s, searchText: value }))
    };

    const onSelectPageNumber = (event) => {
        setAuctionLotFilter(s => ({ ...s, pageNum: event }))
    };

    const onSelectSortOption = (event) => {
        setAuctionLotFilter(s => ({ ...s, sortOrder: event }))
    };
    const onSelectBidResult = (event) => {
        setAuctionLotFilter(s => ({ ...s, LotResult: event }))
    };

    const onClickDeleteAuctionLot = (lot) => {
        setDeleteLotLoader(true)
        AuctionService.deleteAuctionLot(lot._id).then(resp => {
            if (resp && resp.result === 200) {
                getSelectedAuctionLots();

            } else if (resp && resp.result === 400) {
                popToast.show(resp.message);
            }
            setDeleteLotLoader(false)
        }).catch(error => {
            setDeleteLotLoader(false);
        })
    }

    const updateAuctionLot = (checked, event, lot) => {
        lot.isFeatured = checked;
        setUpdateLotLoader(true);
        AuctionService.updateAuctionLot(lot._id, lot).then(resp => {
            if (resp && resp.result === 200) {
                getSelectedAuctionLots();

            } else if (resp && resp.result === 400) {
                popToast.show(resp.message);
            }
            setUpdateLotLoader(false);
        }).catch(error => {
            setUpdateLotLoader(false);
        })
    }

    const swapAuctionLot = (index, isUp) => {
        let lot1 = "";
        let lot2 = ""
        if (isUp === true) {
            lot1 = auctionLotObjects[index]._id;
            lot2 = auctionLotObjects[index - 1]._id;
        }
        else {
            lot1 = auctionLotObjects[index + 1]._id;
            lot2 = auctionLotObjects[index]._id;
        }
        setSwapLoader(true);
        AuctionService.swapAuctionLot(lot1, lot2).then(resp => {
            if (resp && resp.result === 200) {
                getSelectedAuctionLots();

            } else if (resp && resp.result === 400) {
                popToast.show(resp.message);
            }
            setSwapLoader(false);
        }).catch(error => {
            setSwapLoader(false);
        })
    }

    const setCurrentTab = (tab) => {
        setActiveTab(tab);
    };

    const onClickViewBiddingHistory = (lot) => {
        setGetAuctionBiddingHistoryLoader(true)
        AuctionService.getAuctionBiddingHistoryByLotId(lot._id).then(resp => {
            if (resp && resp.result === 200) {
                setAuctionBiddingHistory(resp.data);
                setOpenBiddingHistoryDrawer(true);

            } else if (resp && resp.result === 400) {
                popToast.show(resp.message);
            }
            setGetAuctionBiddingHistoryLoader(false);
        }).catch(error => {
            setGetAuctionBiddingHistoryLoader(false);
        })
    };

    return {
        auctionLotObjects,
        auctionListCount,
        activeTab,
        deleteLotLoader,
        updateLotLoader,
        swapLoader,
        searchLotFilterRef,
        auctionLotFilter,
        getAuctionBiddingHistoryLoader,
        getAuctionLotLoader,
        auctionBiddingHistory,
        openBiddingHistoryDrawer,
        totalBidCount,

        onSelectPageNumber,
        searchAuctionLots,
        setCurrentTab,
        swapAuctionLot,
        updateAuctionLot,
        onClickDeleteAuctionLot,
        onClickViewBiddingHistory,
        setOpenBiddingHistoryDrawer,
        onSelectSortOption,
        onSelectBidResult
    }
}


export default useSummaryContent;