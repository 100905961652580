import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { DialogTitle, DialogContent, CircularProgress } from '@material-ui/core';
import { Tooltip } from 'antd';
import { union } from 'lodash';
// Components
import { popToast } from '../../../components/Toast/Toast';
import { Util } from '../../../util/util';
import ScrollHorizontalList from '../../../components/ScrollHorizontalList/ScrollHorizontalList';
import { AddBrandsService } from '../AddBrands.service';
// Redux
import { connect } from 'react-redux';
import { setAddBrandsReducerStates, getAllCategories, createBrand, updateBrand } from '../../../redux/actions/AddBrandsAction';
// SCSS
import './AddBrandsDialog.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class AddBrandsDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            brandName: '',
            logoMedia: '',
            logoMediaThumb: '',
            categoryIdList: [],

            // Updating
            preSelectedCategories: [],
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevProps.open && this.props.open) {

            if (this.props.isUpdatingBrand) {
                let brandDetails = this.props.brandDetails || {};
                if (brandDetails) {
                    this.setState({
                        brandName: brandDetails.brandName,
                        logoMedia: brandDetails.brandLogo,
                        logoMediaThumb: brandDetails.brandLogo,
                        categoryIdList: [],
                        preSelectedCategories: brandDetails.categories || [],
                    }, () => {
                        if (this.props.isUpdatingBrand) {
                            this.setCategoryNameToStates(this.state.preSelectedCategories);
                        }
                    });
                }
            }
            else {
                this.setState({
                    brandName: '',
                    logoMedia: '',
                    logoMediaThumb: '',
                    categoryIdList: [],
                    preSelectedCategories: []
                });
            }

            // TODO : When admin/seller is combined - Use the action from category
            this.props.getAllCategories();
        }
    }

    render() {

        const { categoryList, categoryLoader, modalLoader } = this.props.addBrandsReducer;

        return (
            <Dialog fullScreen open={this.props.open} onClose={this.props.handleClose} disableBackdropClick disableEscapeKeyDown
                TransitionComponent={Transition} BackdropProps={{ classes: { root: 'dialog-backdrop' } }} className="add-brands-dialog">
                <img className="close-btn" onClick={this.props.handleClose} src={require('../../../assets/images/common/Close_h.svg')} alt="" />
                <DialogTitle className="dialog-title">
                    {this.props.isUpdatingBrand ? 'Update Brand' : 'Add Brand'}
                    <div className="add-brands-action">
                        <button className="btn-cancel" onClick={this.onClickCancel.bind(this)}>Cancel</button>
                        <button
                            className="btn-orange"
                            onClick={this.onClickCreate.bind(this)}
                            disabled={modalLoader}>
                            {this.props.isUpdatingBrand ? 'Update' : 'Save'}
                            {modalLoader && <CircularProgress size={24} className="circular-loader modal-button-loader" />}
                        </button>
                    </div>
                </DialogTitle>
                <DialogContent className="add-brands-content">
                    <div className="add-brands-body">

                        {/* ---------------------- Brand Details ---------------------- */}
                        <div className="add-brands-body-top">
                            <div className="form-control-image-box">
                                {(this.state.logoMediaThumb) ?
                                    <label className="upload-box bg-white">
                                        <img className="media-thumb" src={this.state.logoMediaThumb} onClick={(e) => this.attach_logo_media.click()} alt="Attach media" />
                                    </label>
                                    :
                                    <div className="upload-box" onClick={(e) => this.attach_logo_media.click()}>
                                        <img className="image-upload" src={require('../../../assets/images/common/Icon.svg')} alt="Upload" />
                                        <label>Upload Logo</label>
                                    </div>
                                }
                                <input
                                    type="file"
                                    style={{ display: "none" }}
                                    accept=".bmp, .gif, .ico, .jpeg, .jpg, .png, .svg, .tif, .tiff, .jiff, .jfif, .pjpeg, .pjp"
                                    onChange={(e) => this.onImageSelect(e, 'logoMedia', 'logoMediaThumb')}
                                    disabled={modalLoader}
                                    ref={(ref) => this.attach_logo_media = ref}
                                />
                            </div>
                            <div className="form-control">
                                <label className="form-label">Brand Logo</label>
                                <input
                                    type="text"
                                    className="form-input"
                                    value={this.state.brandName}
                                    disabled={modalLoader}
                                    onChange={this.onHandleInput('brandName')} />
                            </div>
                        </div>

                        <div className="add-brands-body-bottom">
                            <div className="sub-title">Under Categories</div>
                            <div className="psuedo-inputs">
                                {this.props.isUpdatingBrand &&
                                    this.state.preSelectedCategories.map((prevCat, index) => {
                                        return <div className="psuedo-input" key={index}>
                                            <p>{this.state['catgName' + prevCat.categoryId]}</p>
                                            <Tooltip placement="top" title={'Remove Category from list'}>
                                                <img src={require('../../../assets/images/common/delete.svg')} alt="Delete" onClick={this.removeCategoryFromPrevList.bind(this, prevCat)} />
                                            </Tooltip>
                                        </div>
                                    })
                                }
                            </div>

                            <ScrollHorizontalList
                                loader={categoryLoader}
                                scrollList={categoryList}
                                onReturningCascadeMenuData={this.onReturningCascadeMenuData.bind(this)} />
                        </div>

                    </div>
                </DialogContent>
            </Dialog>
        )
    }

    // Handle inputs
    onHandleInput = name => event => {
        name === 'isFilter' ?
            this.setState({
                [name]: event.target.checked
            })
            :
            this.setState({
                [name]: event.target.value
            })
    };

    // On select image
    onImageSelect(e, media, mediaThumb) {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        let image = '';
        if (file) {
            reader.onloadend = () => {
                this.setState({
                    [media]: file,
                    [mediaThumb]: reader.result
                });
                image = reader.result
            }
            reader.readAsDataURL(file);
        } else {
            this.setState({
                [media]: '',
                [mediaThumb]: ''
            });
        }
    }

    // CategoryList gets updated with Priority everytime when a list is updated
    onReturningCascadeMenuData(categoryIdList) {
        this.setState({ categoryIdList });
    }

    // Form validation
    isFormValid() {
        if (!this.state.logoMedia) {
            popToast.show('Choose an image for brand logo');
            return false;
        }
        if (this.state.logoMedia.type && !this.state.logoMedia.type.startsWith('image/')) {
            popToast.show('Please choose a valid image file for brand logo');
            return false;
        }
        if (!this.state.brandName.trim()) {
            popToast.show('Enter a brand name');
            return false;
        }
        if (!this.props.isUpdatingBrand) {
            if (this.state.categoryIdList.length > 0) {
                let found = this.state.categoryIdList.find(function (element) {
                    if ((!element.CategoryId.trim())) { return element; }
                });
                if (found) {
                    popToast.show('Please make sure to remove invalid category input fields!');
                    return false;
                } else return true;
            }
            else if (this.state.categoryIdList.length < 1) {
                popToast.show('Please select atleast one category!');
                return false;
            }
        }
        else if (this.props.isUpdatingBrand) {
            const { preSelectedCategories, categoryIdList } = this.state;

            if ((preSelectedCategories.length <= 0) && (categoryIdList.length <= 0)) {
                popToast.show('Please select atleast one category!');
                return false;
            }

            if (((preSelectedCategories.length <= 0) && (categoryIdList.length > 0))
                || ((preSelectedCategories.length > 0) && (categoryIdList.length > 0))) {
                let found = this.state.categoryIdList.find(function (element) {
                    if ((!element.CategoryId.trim())) { return element; }
                });
                if (found) {
                    popToast.show('Please make sure to remove invalid category input fields!');
                    return false;
                } else return true;
            }
        }
        return true;
    }

    // Create Brands
    onClickCreate(e) {
        e.preventDefault();
        if (this.isFormValid()) {

            // TODO : How to add brand priority
            // To add BrandPriority value to the array objs
            let optionSet = this.state.categoryIdList || [];
            optionSet.map((item, index) => {
                item['BrandPriority'] = (index % 2) || 0;
            });
            this.setState({ categoryIdList: optionSet });

            // ------------------ Update ----------------- //
            if (this.props.isUpdatingBrand) {
                let request = {
                    BrandName: this.state.brandName,
                    logo: this.state.logoMedia,
                    Categories: union(optionSet, this.state.preSelectedCategories)
                }
                let formBodyRequest = Util.objectToFormData(request);
                this.props.updateBrand(formBodyRequest, this.props.brandDetails._id);
            }
            else {
                let request = {
                    BrandName: this.state.brandName,
                    logo: this.state.logoMedia,
                    Categories: optionSet
                }
                let formBodyRequest = Util.objectToFormData(request);
                this.props.createBrand(formBodyRequest);
            }
        }
    }

    onClickCancel() {
        this.props.handleClose();
    }

    // ---------------------------- Update Brand ----------------------------- //
    // Create List of Previously selected caategories
    setCategoryNameToStates(arr) {
        // TODO : What if, category was removed afterwards| this getId API will return error
        arr.map((prevCat, index) => {
            this.setState({ ['catgName' + prevCat.categoryId]: '' });
            if (prevCat.categoryId) {
                AddBrandsService.getCategoryDetails(prevCat.categoryId)
                    .then((response) => {
                        if (response && response.result === 200) {
                            this.setState({ ['catgName' + prevCat.categoryId]: response.data.categoryName });
                        }
                    }, error => { console.log('error') });
            }
        })
    }

    // Remove Category from previously selected category list
    removeCategoryFromPrevList(toDelete) {
        let filteredArr = this.state.preSelectedCategories.filter((item, index) => (item.categoryId !== toDelete.categoryId))

        this.setState({
            preSelectedCategories: filteredArr
        })
    }
}

const mapStateToProps = state => {
    return {
        addBrandsReducer: state.addBrandsReducer,
        productReducer: state.productReducer
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleAddBrandReducerStates: (state, value) => {
            dispatch(setAddBrandsReducerStates(state, value))
        },
        getAllCategories: (parentId) => {
            dispatch(getAllCategories(parentId))
        },
        createBrand: (request) => {
            dispatch(createBrand(request))
        },
        updateBrand: (request, id) => {
            dispatch(updateBrand(request, id))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddBrandsDialog);
