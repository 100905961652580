import { APIUrlConstants } from "../../util/apiUrl.constant";
import { APIService } from "../../util/api.service";

export class QuotesService {
    // All Quotes List
    static getAllQuotesList(body) {
        let url = APIUrlConstants.getApiUrl("filterQuotes");
        return APIService.doPost(url, body).then(resp => {
            if (resp) {
                return resp;
            }
            throw resp;
        });
    }

    // Update Quotes Price Status
    static updateQuotePriceStatus(id, status) {
        let url = APIUrlConstants.getApiUrl("updateQuotePriceStatusByAdmin") + "/" + id + "?Status=" + status;
        return APIService.doPut(url).then(resp => {
            if (resp) {
                return resp;
            }
            throw resp;
        });
    }
}
