import {
	GET_COLLECTIONS_LIST_REQUEST,
	GET_COLLECTIONS_LIST_SUCCESS,
	GET_COLLECTIONS_LIST_FAILURE,
	DELETE_COLLECTION_REQUEST,
	DELETE_COLLECTION_SUCCESS,
	DELETE_COLLECTION_FAILURE
} from "./ActionsTypes";
import * as types from "./ActionsTypes";
import { CollectionService } from "../../containers/Collections/Collection.service";
import { popToast } from "../../components/Toast/Toast";

export function getCollectionsAction(inputData) {
	return dispatch => {
		dispatch(request());
		CollectionService.getSelectedCollections(inputData).then(
			response => {
				if (response && response.result === 200) {
					dispatch(success(response.data));
				} else if (response) {
					dispatch(failure(response));
					popToast.show("Get collections list Error : " + response.message || "Something went wrong while loading collections list!");
				}
			},
			error => {
				if (error && error.status_message) {
					popToast.show(error.status_message);
				} else {
					popToast.show("Something went wrong while loading collections list!");
				}
				console.log("error dispatched for API error!", error);
				dispatch(failure(error));
			}
		);
	};

	function request() {
		return { type: GET_COLLECTIONS_LIST_REQUEST };
	}
	function success(data) {
		return { type: GET_COLLECTIONS_LIST_SUCCESS, data };
	}
	function failure(error) {
		return { type: GET_COLLECTIONS_LIST_FAILURE, error };
	}
}

// ============================= Get Collections by id ============================= //
export function getCollectionsByIdAction(id) {
	return dispatch => {
		dispatch(request());
		CollectionService.getCollectionsById(id).then(
			response => {
				if (response && response.result === 200 && response.message === "Success") {
					dispatch(success(response.data));
				} else if (response) {
					dispatch(failure(response));
					popToast.show(response.message || "Something went wrong while loading collection data!");
				}
			},
			error => {
				if (error && error.status_message) {
					popToast.show(error.status_message);
				} else {
					popToast.show("Something went wrong while loading collections list!");
				}
				console.log("error dispatched for API error!", error);
				dispatch(failure(error));
			}
		);
	};

	function request() {
		return { type: types.GET_COLLECTIONS_LIST_BY_ID_REQUEST };
	}
	function success(data) {
		return { type: types.GET_COLLECTIONS_LIST_BY_ID_SUCCESS, data };
	}
	function failure(error) {
		return { type: types.GET_COLLECTIONS_LIST_BY_ID_FAILURE, error };
	}
}

// ============================= Delete Collection ============================= //
export function deleteCollectionAction(collectionId) {
	return dispatch => {
		dispatch(request());
		CollectionService.deleteCollection(collectionId).then(
			response => {
				if (response && response.result === 200) {
					popToast.show("Successfully deleted collection.");
					let request = { pageNum: 1, pageSize: 30 };
					dispatch(getCollectionsAction(request));
					dispatch(success(response.data));
				} else if (response && response.message === "Failure") {
					dispatch(failure(response));
					popToast.show("Delete collection Error : " + response.message || "Something went wrong while deleting collection!");
				}
			},
			error => {
				if (error && error.status_message) {
					popToast.show(error.status_message);
				} else {
					popToast.show("Something went wrong while deleting collection!");
				}
				console.log("error dispatched for API error!", error);
				dispatch(failure(error));
			}
		);
	};

	function request() {
		return { type: DELETE_COLLECTION_REQUEST };
	}
	function success(data) {
		return { type: DELETE_COLLECTION_SUCCESS, data };
	}
	function failure(error) {
		return { type: DELETE_COLLECTION_FAILURE, error };
	}
}

// ============================= Get All Categories ============================= //
export const getAllCategoriesForCascadeMenu = parentId => {
	return (dispatch, getState) => {
		dispatch(request());
		CollectionService.getCategory(parentId).then(
			response => {
				if (response && response.result === 200) {
					dispatch(success(response.data));
				} else {
					dispatch(failure(response));

					let errorMessage = "Something went wrong while getting categories!";
					if (response && response.message) {
						switch (response.message) {
							default:
								errorMessage = "Something went wrong while getting categories! " + response.message;
								break;
						}
					}
					popToast.show(errorMessage);
				}
			},
			error => {
				if (error && error.status_message) {
					popToast.show(error.status_message);
				} else {
					popToast.show("Something went wrong while getting categories!");
				}
				console.log("error dispatched for API error!", error);
				dispatch(failure(error));
			}
		);
	};

	function request() {
		return { type: types.CASCADE_MENU_REQUEST };
	}
	function success(response) {
		return { type: types.CASCADE_MENU_SUCCESS, response };
	}
	function failure(error) {
		return { type: types.CASCADE_MENU_FAILURE, error };
	}
};

// ============================= Get All Artists ============================= //
export const getAllArtists = () => {
	return (dispatch, getState) => {
		dispatch(request());
		CollectionService.getArtists().then(
			response => {
				if (response && response.result === 200) {
					dispatch(success(response.data));
				} else {
					dispatch(failure(response));

					let errorMessage = "Something went wrong while getting artists!";
					if (response && response.message) {
						switch (response.message) {
							default:
								errorMessage = "Something went wrong while getting artists! " + response.message;
								break;
						}
					}
					popToast.show(errorMessage);
				}
			},
			error => {
				if (error && error.status_message) {
					popToast.show(error.status_message);
				} else {
					popToast.show("Something went wrong while getting artists!");
				}
				console.log("error dispatched for API error!", error);
				dispatch(failure(error));
			}
		);
	};
	function request() {
		return { type: types.GET_ARTISTS_REQUEST };
	}
	function success(data) {
		return { type: types.GET_ARTISTS_SUCCESS, data };
	}
	function failure(error) {
		return { type: types.GET_ARTISTS_FAILURE, error };
	}
};

// ============================= Get All Filter ============================= //
export const getFilterCategoryProperties = id => {
	return (dispatch, getState) => {
		dispatch(request());
		CollectionService.getCategoryPropertyForFilters(id).then(
			response => {
				if (response) {
					dispatch(success(response));
				} else {
					dispatch(failure());
				}
			},
			error => {
				dispatch(failure(error));
			}
		);
	};
	function request() {
		return { type: types.GET_FILTER_CATEGORY_PROPERTIES_REQUEST };
	}
	function success(data) {
		return { type: types.GET_FILTER_CATEGORY_PROPERTIES_SUCCESS, data };
	}
	function failure(error) {
		return { type: types.GET_FILTER_CATEGORY_PROPERTIES_FAILURE, error };
	}
};

// ============================= Search ============================= //
export const searchArtworks = body => {
	return (dispatch, getState) => {
		dispatch(request());
		CollectionService.searchArtworks(body).then(
			response => {
				if (response) {
					dispatch(success(response));
				} else {
					dispatch(failure());
				}
			},
			error => {
				dispatch(failure(error));
			}
		);
	};
	function request() {
		return { type: types.SEARCH_ARTWORKS_REQUEST };
	}
	function success(data) {
		return { type: types.SEARCH_ARTWORKS_SUCCESS, data };
	}
	function failure(error) {
		return { type: types.SEARCH_ARTWORKS_FAILURE, error };
	}
};

// ============================= Create Collection ============================= //
export function addCollection(body) {
	return dispatch => {
		dispatch(request());
		CollectionService.addCollection(body).then(
			response => {
				if (response && response.result === 200) {
					dispatch(success());
					popToast.show("Successfully added collection");
					let request = { pageNum: 1, pageSize: 30 };
					dispatch(getCollectionsAction(request));
				} else {
					dispatch(failure(response));
					popToast.show("Add collection Error : " + response.message || "Something went wrong while adding collection!");
				}
			},
			error => {
				if (error && error.status_message) {
					popToast.show(error.status_message);
				} else {
					popToast.show("Something went wrong while creating collection!");
				}
				console.log("error dispatched for API error!", error);
				dispatch(failure(error));
			}
		);
	};

	function request() {
		return { type: types.ADD_COLLECTION_REQUEST };
	}
	function success(data) {
		return { type: types.ADD_COLLECTION_SUCCESS, data };
	}
	function failure(error) {
		return { type: types.ADD_COLLECTION_FAILURE, error };
	}
}

// ============================= Delete item from Collection ============================= //
export function deleteItemFromCollection(collectionId, collectionItemId) {
	return dispatch => {
		dispatch(request());
		CollectionService.deleteItemFromCollection(collectionId, collectionItemId).then(
			response => {
				if (response && response.result === 200) {
					dispatch(success(collectionItemId));
					dispatch(success(null))
					popToast.show("Successfully removed the item from collection");
					let request = { pageNum: 1, pageSize: 30 };
					dispatch(getCollectionsAction(request));
				} else if (response) {
					dispatch(failure(response));
					popToast.show("Remove item from collection Error : " + response.message || "Something went wrong while removing item from collection!");
				}
			},
			error => {
				if (error && error.status_message) {
					popToast.show(error.status_message);
				} else {
					popToast.show("Something went wrong while removing item from collection!");
				}
				console.log("error dispatched for API error!", error);
				dispatch(failure(error));
			}
		);
	};

	function request() {
		return { type: types.DELETE_COLLECTION_ITEM_REQUEST };
	}
	function success(data) {
		return { type: types.DELETE_COLLECTION_ITEM_SUCCESS, data };
	}
	function failure(error) {
		return { type: types.DELETE_COLLECTION_ITEM_FAILURE, error };
	}
}

// ============================= Update Collection ============================= //
export function updateCollection(body, id) {
	return dispatch => {
		dispatch(request());
		CollectionService.updateCollection(body, id).then(
			response => {
				if (response && response.result === 200) {
					dispatch(success());
					popToast.show("Successfully updated collection");
					let request = { pageNum: 1, pageSize: 30 };
					dispatch(getCollectionsAction(request));
				} else {
					dispatch(failure(response));
					popToast.show("Update collection Error : " + response.message || "Something went wrong while updating collection!");
				}
			},
			error => {
				if (error && error.status_message) {
					popToast.show(error.status_message);
				} else {
					popToast.show("Something went wrong while updating collection!");
				}
				console.log("error dispatched for API error!", error);
				dispatch(failure(error));
			}
		);
	};

	function request() {
		return { type: types.ADD_COLLECTION_REQUEST };
	}
	function success(data) {
		return { type: types.ADD_COLLECTION_SUCCESS, data };
	}
	function failure(error) {
		return { type: types.ADD_COLLECTION_FAILURE, error };
	}
}

// ============================= Access Reducer states through this action ============================= //
export const setCollectionReducerStates = (stateName, value) => {
	return dispatch => {
		let data = {
			state: stateName,
			value: value
		};
		dispatch({ type: types.SET_COLLECTIONS_REDUCER_STATES, data });
	};
};

export const initializeFilters = () => {
	return dispatch => {
		dispatch({ type: types.SET_COLLECTIONS_INITIALIZE_FILTER_STATES, });
	};
}
