import styled from "styled-components";
import StyledButton from "../../../../../components/StyledButton/StyledButton";

export const ProfileSectionTabWrapper = styled.div`
	position: relative;
	padding: 1rem;
	${props => props.wrapperStyles}
	.ant-empty.no-search-image {
		display: flex;
		flex-direction: column;
		min-height: 13rem;
		align-items: center;
		justify-content: center;
	}
`;
export const ProfileSectionTabHeader = styled.h2`
	border-top: 1px solid #eeeeee;
	text-align: center;
	font-size: 25px;
	color: #58514b;
	padding-top: 1rem;
	font-weight: 600;
`;
export const ProfileSectionTabButton = styled(StyledButton)`
	position: absolute;
	top: 1rem;
	right: 2rem;
`;
export const ProfileSectionTabBody = styled.div`
	${props => props.bodyWrapperStyles}
`;
