import React, { Component } from 'react'
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import './LineChart.scss';

export default class LineChartGraph extends Component {
	render() {

		//Data Format
		// const data = [
		//     {
		//         "name": "Jan",
		//         "Sale": 7950,
		//     }];

		return (
			<div>
				<AreaChart width={1177} height={250} data={this.props.graphvalue} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
					<defs>
						<linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
							<stop offset="5%" stopColor="#D2502D" stopOpacity={0.3} />
							<stop offset="95%" stopColor="#D2502D" stopOpacity={0} />
						</linearGradient>
					</defs>
					<CartesianGrid />
					<XAxis dataKey="Month" tickLine={false} />
					<YAxis tickLine={false} />
					<Tooltip />
					<Area type="linear" dataKey="Sale" stroke="#D2502D" fillOpacity={1} fill="url(#colorUv)" />
				</AreaChart>
			</div>
		);
	}
}


