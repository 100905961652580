import React, { Component } from "react";
import { connect } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import { Pagination, Table, Popover, Icon, Tooltip, Select, Button } from "antd";
//Files
import Root from "../Root/Root";
import Topbar from "../../components/Topbar/Topbar";
import { Util } from "../../util/util";
import { isEmpty, isNull } from "lodash";
import moment from "moment";
import { DataGrid } from "@mui/x-data-grid";

import {
	getUsersList,
	blockUser,
	unblockUser,
	setUserProfile,
	getRoleChangeRequestsAction,
	updateRoleChangeAction,
	getUserKycUser,
	toggleKYCDialog,
	approveKYC,
	exportToExcelUserDetailsAction,
} from "../../redux/actions/ManageusersAction";
import KycDocumentDialog from "./KycDocumentDialog/KycDocumentDialog";
import ConfirmationMessageDialog from "../../components/ConfirmationMessageDialog/ConfirmationMessageDialog";
// SCSS
import "./ManageUsers.scss";
import propertiesList from "../../properties.json";

const { Option } = Select;
const baseUrl = propertiesList.fontEndUrl;

class ManageUsers extends Component {
	constructor(props) {
		super(props);
		this.state = {
			blockconfirmationDialog: false,
			unblockconfirmationDialog: false,
			searchValue: "",
			pageNumValue: 1,
			pageSize: 20,
			pageNumForUserRequest: 1,
			userId: "",
			isButtonClicked: false,
			approveconfirmationDialog: false,
			rejectconfirmationDialog: false,
			status: null,
			userKycDoc: [],
			kycFilter: null,
			artistStatus: null,
			orderSort: 1,
			orderOption: 1,
		};
	}

	componentDidMount() {
		// List Sellers by fetching details from URL - When Page Loads
		this.setSearchParams();
	}

	componentDidUpdate(prevProps) {
		// List Sellers by fetching details from URL - When URL change occur
		const locationChanged = this.props.location !== prevProps.location;
		if (locationChanged) {
			this.setSearchParams();
		}
	}

	render() {
		const {
			usersListLoader,
			savedUserListCount,
			savedusersList,
			getRolechangeRequestLoader,
			getRolechangeRequestList,
			kycDocumentResult,
			kycDialog,
			getRoleChangeRequestCount,
			exportUserLoader,
		} = this.props.manageUsersReducer;

		const options = { year: "numeric", month: "long", day: "numeric" };
		const dataGridColumns = [
			{ field: "name", headerName: "Name", width: 250 },
			{ field: "userId", headerName: "User Id", width: 150 },
			{ field: "phoneNumber", headerName: "Mobile", width: 150 },
			{ field: "email", headerName: "Mail Id", width: 150 },
			{ field: "joiningDate", headerName: "Join Date", width: 150 },
			{ field: "country", headerName: "Country", width: 150 },
			{ field: "roles", headerName: "Roles", width: 150 },
			{ field: "isSubscribed", headerName: "Subscribed", width: 150 },
			{ field: "lastLoginDate", headerName: "Last Login Date", width: 150 },
			{ field: "lastLoginType", headerName: "Last Login Type", width: 150 },
			{ field: "lastLoginDevice", headerName: "LoginDevice", width: 150 },
			{
				field: "action",
				headerName: "Action",
				sortable: false,
				renderCell: (record) => (record && record.row.blocked ? unblockContent(record.row) : blockContent(record.row)),
			},
		];

		const blockContent = (item) => (
			<div>
				<span className="action-span" onClick={this.toggleBlockConfirmationDialog.bind(this, item)}>
					<Tooltip placement="top" title="Block">
						<img src={require("../../assets/images/common/Block.svg")} alt="block" />
						{/* <p className="pop-item">Block</p> */}
					</Tooltip>
				</span>
			</div>
		);
		const unblockContent = (item) => (
			<div>
				<span className="action-span" onClick={this.toggleUnblockConfirmationDialog.bind(this, item)}>
					<Tooltip placement="top" title="Unblock">
						<img src={require("../../assets/images/common/Block_New.svg")} alt="delete" />
						{/* <p className="pop-item">Unblock</p> */}
					</Tooltip>
				</span>
			</div>
		);

		return (
			<Root>
				<Topbar title={"Manage Users"} showSearch searchValue={this.state.searchValue} onChangeSearch={this.onChangeSearch.bind(this)} />
				<div className="manageusers-wrapper">
					{savedusersList && savedusersList.length !== 0 ? (
						<>
							<div className="filter-wrapper">
								<Button
									className="export-excel-btn"
									loading={exportUserLoader}
									onClick={this.exportToExcelUserDetailsAction.bind(this)}
								>
									Export To Excel
								</Button>
							</div>

							<DataGrid
								pageSize={this.state.pageSize}
								onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
								pagination
								getRowId={(row) => row._id}
								columns={dataGridColumns}
								rows={savedusersList}
								rowsPerPageOptions={[20, 50, 100]}
							/>

							{/* <Table rowKey={(record) => record._id} columns={columns} dataSource={savedusersList} pagination={false} /> */}
							{/* {savedUserListCount > 10 && (
								<Pagination
									current={parseInt(this.state.pageNumValue)}
									total={savedUserListCount}
									pageSize={12}
									onChange={this.onSelectPageNumber(this)}
								/>
							)} */}
						</>
					) : usersListLoader ? (
						<div className="manageusers-wrapper">
							<CircularProgress className="circular-loader" size={30} />
						</div>
					) : (
						<div className="manageusers-wrapper">
							<div className="empty-table">No Users Found.</div>
						</div>
					)}
				</div>

				<ConfirmationMessageDialog
					open={this.state.blockconfirmationDialog}
					handleClose={this.toggleBlockConfirmationDialog.bind(this)}
					confirmMessage={"Are you sure you want to block this user?"}
					onConfirm={this.onBlockUser.bind(this)}
				/>
				<ConfirmationMessageDialog
					open={this.state.unblockconfirmationDialog}
					handleClose={this.toggleUnblockConfirmationDialog.bind(this)}
					confirmMessage={"Are you sure you want to unblock this user?"}
					onConfirm={this.onUnblockUser.bind(this)}
				/>
				<ConfirmationMessageDialog
					open={this.state.approveconfirmationDialog}
					handleClose={this.toggleApproveConfirmationDialog.bind(this)}
					confirmMessage={"Are you sure you want to approve this user?"}
					onConfirm={this.onApproveUser.bind(this)}
				/>
				<ConfirmationMessageDialog
					open={this.state.rejectconfirmationDialog}
					handleClose={this.toggleRejectConfirmationDialog.bind(this)}
					confirmMessage={"Are you sure you want to reject this user?"}
					onConfirm={this.onRejectUser.bind(this)}
				/>
				<KycDocumentDialog
					open={kycDialog}
					approveKYC={this.approveKYC.bind(this)}
					handleClose={this.onToggleKycModel.bind(this, false)}
					data={kycDocumentResult && kycDocumentResult}
				/>
			</Root>
		);
	}

	// ------------------------ When Page loads/ URL changes ------------------------- //
	// Call API by fetching details from URL
	setSearchParams() {
		this.setState({ pageNumValue: this.props.match.params.pageNum }, () => {
			this.doSearchFilterUsers();
		});
	}

	// --------------------- When filter/search/pagination events trigger --------------------- //

	//Pagination
	onSelectPageNumber = (name) => (event) => {
		this.setState({ pageNumValue: event }, () => {
			this.doChangeUrlWithQuery();
		});
	};

	onSelectPageNumberForUserRequest = (name) => (event) => {
		this.setState({ pageNumForUserRequest: event }, () => {
			let body = {};
			if (this.state.orderSort === null && this.state.orderOption === null) {
				body = {
					PageNum: event,
					PageSize: 12,
					KYCStatus: this.state.kycFilter,
					Status: this.state.artistStatus,
				};
			} else {
				body = {
					PageNum: event,
					PageSize: 12,
					KYCStatus: this.state.kycFilter,
					Status: this.state.artistStatus,
					sortOrder: this.state.orderSort,
					userSortOptions: this.state.orderOption,
				};
			}

			this.props.getRoleChangeRequestsAction(body);
		});
	};

	// Search
	onChangeSearch(search) {
		this.setState(
			{
				searchValue: search,
				pageNumValue: 1,
			},
			() => {
				if (this.state.isButtonClicked) {
					let body = {
						PageNum: this.state.pageNumForUserRequest,
						PageSize: 12,
						AlphabetSearch: search,
					};
					this.props.getRoleChangeRequestsAction(body);
				} else {
					this.doChangeUrlWithQuery();
				}
			}
		);
	}

	// Change URL based on filter/search/Page values
	doChangeUrlWithQuery() {
		let searchCriteria = {
			Search: this.state.searchValue,
		};
		const queryString = Util.queryStringHandling(searchCriteria, "STR");
		this.props.history.push("/manage-users/" + this.state.pageNumValue + "?" + queryString);
	}

	// ----------------------- API - Fetch Sellers ----------------------------- //
	doSearchFilterUsers() {
		// POST request body
		let request = {
			pageNum: this.state.pageNumValue,
			pageSize: 100000,
			Name: this.state.searchValue || "",
		};
		this.props.getUsersList(request);
	}

	// -------------------------- User Profile -------------------------- //
	toggleUserProfile(path, item) {
		window.location.href = "/#/" + path + "/" + item.userId;
		this.props.setUserProfile(item);
	}
	//----------------Dialog for approve artwork-----------------//
	toggleApproveConfirmationDialog(item, status) {
		this.setState(
			{
				approveconfirmationDialog: !this.state.approveconfirmationDialog,
			},
			() => {
				this.setState({
					userId: item._id,
					status: status,
				});
			}
		);
	}

	async onClickUserKyc(value, item) {
		await this.props.toggleKYCDialog(value);
		await this.props.getUserKycUser(item.userId);
	}

	async approveKYC(userId, isType) {
		await this.props.approveKYC(userId, isType);
		let body = {};
		// let body = {
		// 	PageNum: this.state.pageNumForUserRequest,
		// 	PageSize: 12,
		// }
		// await this.props.getRoleChangeRequestsAction(body);
		if (this.state.orderSort === null && this.state.orderOption === null) {
			body = {
				PageNum: this.state.pageNumForUserRequest,
				PageSize: 12,
				KYCStatus: this.state.kycFilter,
				Status: this.state.artistStatus,
			};
		} else {
			body = {
				PageNum: this.state.pageNumForUserRequest,
				PageSize: 12,
				KYCStatus: this.state.kycFilter,
				Status: this.state.artistStatus,
				sortOrder: this.state.orderSort,
				userSortOptions: this.state.orderOption,
			};
		}

		await this.props.getRoleChangeRequestsAction(body);
	}

	onToggleKycModel(value) {
		// Toggle dialog
		this.props.toggleKYCDialog(value);
	}
	onChangeFilterArtistStatus = (name) => (event) => {
		this.setState(
			{
				artistStatus: event,
			},
			() => {
				let request = {
					PageNum: parseInt(this.state.pageNumForUserRequest),
					PageSize: 12,
					KYCStatus: this.state.kycFilter,
					Status: this.state.artistStatus,
				};
				this.props.getRoleChangeRequestsAction(request);
			}
		);
	};
	onChangeFilterKyc = (name) => (event) => {
		this.setState(
			{
				kycFilter: event,
			},
			() => {
				let request = {
					PageNum: parseInt(this.state.pageNumForUserRequest),
					PageSize: 12,
					KYCStatus: this.state.kycFilter,
					Status: this.state.artistStatus,
				};
				this.props.getRoleChangeRequestsAction(request);
			}
		);
	};
	//-------------Dialog for reject artwork---------------------//
	toggleRejectConfirmationDialog(item, status) {
		this.setState(
			{
				rejectconfirmationDialog: !this.state.rejectconfirmationDialog,
			},
			() => {
				this.setState({
					userId: item._id,
					status: status,
				});
			}
		);
	}
	//-------------Approve User Confirm---------------------//
	async onApproveUser() {
		await this.props.updateRoleChangeAction(this.state.userId, this.state.status);
		await this.handleReset();
	}

	//-------------Reject User Confirm---------------------//
	async onRejectUser() {
		await this.props.updateRoleChangeAction(this.state.userId, this.state.status);
		await this.handleReset();
	}

	// -------------------------- Block / Unblock -------------------------- //
	//Dialog for blocking user
	toggleBlockConfirmationDialog(item) {
		this.setState(
			{
				blockconfirmationDialog: !this.state.blockconfirmationDialog,
			},
			() => {
				if (this.state.blockconfirmationDialog) {
					// set id of rule only when dialog opens
					this.setState({
						blockedUser: item.userId,
					});
				}
			}
		);
	}

	//Dialog for unblocking user
	toggleUnblockConfirmationDialog(item) {
		this.setState(
			{
				unblockconfirmationDialog: !this.state.unblockconfirmationDialog,
			},
			() => {
				if (this.state.unblockconfirmationDialog) {
					// set id of rule only when dialog opens
					this.setState({
						unblockedUser: item.userId,
					});
				}
			}
		);
	}

	// Block User Confirm
	onBlockUser() {
		let request = {
			pageNum: this.state.pageNumValue,
			pageSize: 12,
			Name: this.state.searchValue || "",
		};
		this.props.blockUser(this.state.blockedUser, request);
		this.handleReset();
	}

	// Unblock User Confirm
	onUnblockUser() {
		let request = {
			pageNum: this.state.pageNumValue,
			pageSize: 12,
			Name: this.state.searchValue || "",
		};
		this.props.unblockUser(this.state.unblockedUser, request);
		this.handleReset();
	}

	//Reset fields
	handleReset() {
		this.setState({
			...this.state,
			blockconfirmationDialog: false,
			unblockconfirmationDialog: false,
			approveconfirmationDialog: false,
			rejectconfirmationDialog: false,
			status: null,
			userId: "",
		});
	}

	//----------------Dropdown Visible Change-----------------//
	onVisibleChange(item) {
		this.setState({
			userId: item.userId,
		});
	}
	onButtonClick() {
		this.setState({ isButtonClicked: !this.state.isButtonClicked }, () => {
			if (this.state.isButtonClicked) {
				let body = {
					PageNum: 1,
					PageSize: 12,
					sortOrder: this.state.orderSort,
					userSortOptions: this.state.orderOption,
				};
				this.props.getRoleChangeRequestsAction(body);
			} else {
				this.doChangeUrlWithQuery();
			}
		});
	}

	exportToExcelUserDetailsAction() {
		let body = {
			pageNum: null,
			pageSize: null,
			name: this.state.searchValue,
		};
		this.props.exportToExcelUserDetailsAction(body);
	}
}
const mapStateToProps = (state) => {
	return {
		manageUsersReducer: state.manageUsersReducer,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getUsersList: (userinput) => {
			dispatch(getUsersList(userinput));
		},
		blockUser: (userId, input) => {
			dispatch(blockUser(userId, input));
		},
		unblockUser: (userId, input) => {
			dispatch(unblockUser(userId, input));
		},
		setUserProfile: (userdata) => {
			dispatch(setUserProfile(userdata));
		},
		getRoleChangeRequestsAction: (body) => {
			dispatch(getRoleChangeRequestsAction(body));
		},
		updateRoleChangeAction: (userId, status) => {
			dispatch(updateRoleChangeAction(userId, status));
		},
		getUserKycUser: (userId) => {
			dispatch(getUserKycUser(userId));
		},
		approveKYC: (userId, isType) => {
			dispatch(approveKYC(userId, isType));
		},
		toggleKYCDialog: (value) => {
			dispatch(toggleKYCDialog(value));
		},
		exportToExcelUserDetailsAction: (body) => {
			dispatch(exportToExcelUserDetailsAction(body));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageUsers);
