import React, { useState, useEffect } from "react";
import Topbar from "../../components/Topbar/Topbar";
import Root from "../Root/Root";
import { Pagination, Table, Select, Popover, Icon, Tooltip } from "antd";
import usePageAnalytics from "./usePageAnalytics";
import moment from "moment";

function PageAnalytics() {
	const { analyticsData, totalViewCount, pageNameFilter, getAnalyticsDetails } = usePageAnalytics();
	useEffect(() => {
		getAnalyticsDetails();
	}, []);

	const columns = [
		{
			title: "Page Name",
			dataIndex: "pageName",
			key: "pageName",
			filters: pageNameFilter,
			onFilter: (value, record) => record.pageName.indexOf(value) === 0,
			sorter: (a, b) => a.pageName.length - b.pageName.length,
			sortDirections: ["descend"],
		},

		{
			title: "Page URL",
			dataIndex: "source",
			key: "source",
			sorter: (a, b) => a.source.length - b.source.length,
			sortDirections: ["descend"],
		},
		{
			title: "IP",
			dataIndex: "ipAddress",
			key: "ipAddress",
			sorter: (a, b) => a.ipAddress.length - b.ipAddress.length,
			sortDirections: ["descend"],
		},
		{
			title: "User Id",
			dataIndex: "userId",
			key: "userId",
		},
		{
			title: "Additional Info",
			dataIndex: "additionalInfo",
			key: "additionalInfo",
		},
		{
			title: "Country",
			dataIndex: "countryName",
			key: "countryName",
			sorter: (a, b) => a.countryName.length - b.countryName.length,
			sortDirections: ["descend"],
		},
		{
			title: "Time Zone",
			dataIndex: "timeZone",
			key: "timeZone",
		},
		{
			title: "Log Date",
			dataIndex: "logDate",
			key: "logDate",
			sorter: (a, b) => a.logDate.length - b.logDate.length,
			sortDirections: ["descend"],
			render: (text) => moment(text).format("DD MMM YYYY HH:mm:ss"),
		},
	];
	return (
		<Root>
			<Topbar title={"Page Analytics"} />
			<div>
				<Table rowKey={(record) => record._id} columns={columns} dataSource={analyticsData} pagination={true} showSorterTooltip />
			</div>
		</Root>
	);
}

export default PageAnalytics;
