import {
	GET_ORDERS_LIST_REQUEST,
	GET_ORDERS_LIST_SUCCESS,
	GET_ORDERS_LIST_FAILURE,
	GET_ORDER_DETAILS_REQUEST,
	GET_ORDER_DETAILS_SUCCESS,
	GET_ORDER_DETAILS_FAILURE,
	UPDATE_ORDER_STATUS_REQUEST,
	UPDATE_ORDER_STATUS_SUCCESS,
	UPDATE_ORDER_STATUS_FAILURE,
	GET_ORDER_INVOICE_REQUEST,
	GET_ORDER_INVOICE_SUCCESS,
	GET_ORDER_INVOICE_FAILURE,
	REFUND_ORDER_AMOUNT_REQUEST,
	REFUND_ORDER_AMOUNT_SUCCESS,
	REFUND_ORDER_AMOUNT_FAILURE,
	GET_ORDER_WAYBILL_REQUEST,
	GET_ORDER_WAYBILL_SUCCESS,
	GET_ORDER_WAYBILL_FAILURE,
	GET_PICKUP_DETAILS_REQUEST,
	GET_PICKUP_DETAILS_SUCCESS,
	GET_PICKUP_DETAILS_FAILURE
} from "./ActionsTypes";
import { OrderService } from "../../containers/Orders/Orders.service";
import { popToast } from "../../components/Toast/Toast";

export function getOrdersListAction(orderdata) {
	return dispatch => {
		dispatch(request());
		OrderService.getOrderList(orderdata).then(
			response => {
				if (response && response.message === "Success") {
					dispatch(success(response.data));
				} else if (response && response.message === "Failure") {
					dispatch(failure(response));
					popToast.show("Get orders list Error : " + response.Message || "Something went wrong while loading orders list!");
				}
			},
			error => {
				if (error && error.status_message) {
					popToast.show(error.status_message);
				} else {
					popToast.show("Something went wrong while loading orders list!");
				}
				console.log("error dispatched for API error!", error);
				dispatch(failure(error));
			}
		);
	};

	function request() {
		return { type: GET_ORDERS_LIST_REQUEST };
	}
	function success(data) {
		return { type: GET_ORDERS_LIST_SUCCESS, data };
	}
	function failure(error) {
		return { type: GET_ORDERS_LIST_FAILURE, error };
	}
}
export function getOrderDetailsAction(orderDetailsId) {
	return dispatch => {
		dispatch(request());
		OrderService.getOrderDetails(orderDetailsId).then(
			response => {
				if (response && response.message === "Success") {
					dispatch(success(response.data));
				} else if (response && response.message === "Failure") {
					dispatch(failure(response));
					popToast.show("Get order details Error : " + response.Message || "Something went wrong while loading order details!");
				}
			},
			error => {
				if (error && error.status_message) {
					popToast.show(error.status_message);
				} else {
					popToast.show("Something went wrong while loading order details!");
				}
				console.log("error dispatched for API error!", error);
				dispatch(failure(error));
			}
		);
	};

	function request() {
		return { type: GET_ORDER_DETAILS_REQUEST };
	}
	function success(data) {
		return { type: GET_ORDER_DETAILS_SUCCESS, data };
	}
	function failure(error) {
		return { type: GET_ORDER_DETAILS_FAILURE, error };
	}
}
export function updateOrderStatusAction(updateData) {
	return dispatch => {
		dispatch(request());
		OrderService.updateOrderStatus(updateData).then(
			response => {
				if (response && response.message === "Success") {
					dispatch(success(response.data));
					popToast.show("Status Updated Sucessfully");
					dispatch(getOrderDetailsAction(updateData.orderDetailsId));
				} else if (response && response.message === "Failure") {
					dispatch(failure(response));
					popToast.show("Update Status Error : " + response.message || "Something went wrong while updating order status!");
				}
			},
			error => {
				if (error && error.status_message) {
					popToast.show(error.status_message);
				} else {
					popToast.show("Something went wrong while updating order status!");
				}
				console.log("error dispatched for API error!", error);
				dispatch(failure(error));
			}
		);
	};

	function request() {
		return { type: UPDATE_ORDER_STATUS_REQUEST };
	}
	function success(data) {
		return { type: UPDATE_ORDER_STATUS_SUCCESS, data };
	}
	function failure(error) {
		return { type: UPDATE_ORDER_STATUS_FAILURE, error };
	}
}

export function invoiceDownloadAction(orderId) {
	return dispatch => {
		dispatch(request());
		OrderService.invoiceDownload(orderId).then(
			response => {
				if (response) {
					const url = window.URL.createObjectURL(new Blob([response]));
					const link = document.createElement("a");
					link.href = url;
					link.download = `RtistIQ Invoice_${new Date().toLocaleString()}.pdf`;
					// link.setAttribute("download", "file.pdf");
					document.body.appendChild(link); // we need to append the element to the dom -> otherwise it will not work in firefox
					link.click();
					link.remove(); //afterwards we remove the element again
					dispatch(success(response.data));
				} else if (response && response.message === "Failure") {
					dispatch(failure(response));
					popToast.show("Get order invoice Error : " + response.Message || "Something went wrong while downloading order invoice!");
				}
			},
			error => {
				if (error && error.status_message) {
					popToast.show(error.status_message);
				} else {
					popToast.show("Something went wrong while downloading order invoice!");
				}
				console.log("error dispatched for API error!", error);
				dispatch(failure(error));
			}
		);
	};

	function request() {
		return { type: GET_ORDER_INVOICE_REQUEST };
	}
	function success(data) {
		return { type: GET_ORDER_INVOICE_SUCCESS, data };
	}
	function failure(error) {
		return { type: GET_ORDER_INVOICE_FAILURE, error };
	}
}

export function orderRefundAction(amount, orderDetailsId) {
	return dispatch => {
		dispatch(request());
		OrderService.amountRefund(amount, orderDetailsId).then(
			response => {
				if (response && response.data === true) {
					dispatch(success(response.data));
					popToast.show("Amount Refund Sucessfully");
					dispatch(getOrderDetailsAction(orderDetailsId));
				} else if (response && response.data === false) {
					dispatch(failure(response));
					popToast.show("Refund Amount Error : " + response.message || "Something went wrong while refund order amount!");
				}
			},
			error => {
				if (error && error.status_message) {
					popToast.show(error.status_message);
				} else {
					popToast.show("Something went wrong while refund order amount!");
				}
				console.log("error dispatched for API error!", error);
				dispatch(failure(error));
			}
		);
	};

	function request() {
		return { type: REFUND_ORDER_AMOUNT_REQUEST };
	}
	function success(data) {
		return { type: REFUND_ORDER_AMOUNT_SUCCESS, data };
	}
	function failure(error) {
		return { type: REFUND_ORDER_AMOUNT_FAILURE, error };
	}
}

export function wayBillDownloadAction(orderDetailsId) {
	return dispatch => {
		dispatch(request());
		OrderService.wayBillDownload(orderDetailsId).then(
			response => {
				if (response) {
					const url = window.URL.createObjectURL(new Blob([response]));
					const link = document.createElement("a");
					link.href = url;
					link.download = `RtistiQ WayBill_${new Date().toLocaleString()}.pdf`;
					// link.setAttribute("download", "file.pdf");
					document.body.appendChild(link); // we need to append the element to the dom -> otherwise it will not work in firefox
					link.click();
					link.remove(); //afterwards we remove the element again
					dispatch(success(response.data));
				} else if (response && response.message === "Failure") {
					dispatch(failure(response));
					popToast.show("Get order WayBill Error : " + response.Message || "Something went wrong while downloading order waybill!");
				}
			},
			error => {
				if (error && error.status_message) {
					popToast.show(error.status_message);
				} else {
					popToast.show("Something went wrong while downloading order waybill!");
				}
				console.log("error dispatched for API error!", error);
				dispatch(failure(error));
			}
		);
	};

	function request() {
		return { type: GET_ORDER_WAYBILL_REQUEST };
	}
	function success(data) {
		return { type: GET_ORDER_WAYBILL_SUCCESS, data };
	}
	function failure(error) {
		return { type: GET_ORDER_WAYBILL_FAILURE, error };
	}
}


export function getPickupDetailsAction(orderId) {
	return dispatch => {
		dispatch(request());
		OrderService.getPickupDetails(orderId).then(
			response => {
				if (response && response.result === 200 && response.message === "Success") {
					dispatch(success(response.data));
				} else if (response) {
					dispatch(failure(response));
					popToast.show(response.message || "Something went wrong while fetching pick up details!");
				}
			},
			error => {
				if (error && error.status_message) {
					popToast.show(error.status_message);
				} else {
					popToast.show("Something went wrong while fetching pick up details!");
				}
				console.log("error dispatched for API error!", error);
				dispatch(failure(error));
			}
		);
	};

	function request() {
		return { type: GET_PICKUP_DETAILS_REQUEST };
	}
	function success(data) {
		return { type: GET_PICKUP_DETAILS_SUCCESS, data };
	}
	function failure(error) {
		return { type: GET_PICKUP_DETAILS_FAILURE, error };
	}
}
