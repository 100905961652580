import React, { Component } from "react";
import { connect } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import { Pagination, Table, Popover, Icon, Tooltip, Select, Button } from "antd";
//Files
import Root from "../Root/Root";
import Topbar from "../../components/Topbar/Topbar";
import { Util } from "../../util/util";
import { isEmpty, isNull } from "lodash";
import moment from "moment";

import {
	getUsersList,
	blockUser,
	unblockUser,
	setUserProfile,
	getRoleChangeRequestsAction,
	updateRoleChangeAction,
	getUserKycUser,
	toggleKYCDialog,
	approveKYC,
	exportToExcelUserDetailsAction,
} from "../../redux/actions/ManageusersAction";
import KycDocumentDialog from "./KycDocumentDialog/KycDocumentDialog";
import ConfirmationMessageDialog from "../../components/ConfirmationMessageDialog/ConfirmationMessageDialog";
// SCSS
import "./ManageUsers.scss";
import propertiesList from "../../properties.json";
import { DataGrid } from "@mui/x-data-grid";

const { Option } = Select;
const baseUrl = propertiesList.fontEndUrl;

const kycStatusList = [
	{ label: "KYC All Status", value: null },
	{ label: "Pending", value: 0 },
	{ label: "Completed", value: 1 },
	{ label: "Approved", value: 2 },
	{ label: "Not Applicable", value: 3 },
];

class ManageUsers extends Component {
	constructor(props) {
		super(props);
		this.state = {
			blockconfirmationDialog: false,
			unblockconfirmationDialog: false,
			searchValue: "",
			pageNumValue: 1,
			pageNumForUserRequest: 1,
			userId: "",
			isButtonClicked: false,
			approveconfirmationDialog: false,
			rejectconfirmationDialog: false,
			status: null,
			userKycDoc: [],
			kycFilter: null,
			artistStatus: null,
			orderSort: 1,
			orderOption: 1,
			pageSize: 20,
		};
	}

	componentDidMount() {
		// List Sellers by fetching details from URL - When Page Loads
		this.setSearchParams();
	}

	componentDidUpdate(prevProps) {
		// List Sellers by fetching details from URL - When URL change occur
		const locationChanged = this.props.location !== prevProps.location;
		if (locationChanged) {
			this.setSearchParams();
		}
	}

	render() {
		const {
			getRolechangeRequestLoader,
			getRolechangeRequestList,
			kycDocumentResult,
			kycDialog,
			getRoleChangeRequestCount,
			exportUserLoader,
		} = this.props.manageUsersReducer;

		const options = { year: "numeric", month: "long", day: "numeric" };

		const requestcolumns = [
			{
				headerName: "Name",
				width: 250,
				field: "userName",

				renderCell: (record) => (
					<a
						href={record.row.userRole === "ARTIST" ? `${baseUrl}artist/${record.row.slugId}` : `${baseUrl}galleries/${record.row.slugId}`}
						rel="noopener noreferrer"
						target="_blank"
					>
						<span className="colWrapper">
							{record.row.profileImageUrl ? (
								<img className="colImage" src={record.row.profileImageUrl} alt="img" />
							) : (
								<Icon className="icon-image" type="user" />
							)}
							<Tooltip title={record.row.userName ? record.row.userName : "Nil"}>
								<p className="colText">{record.row.userName ? record.row.userName : "Nil"}</p>
							</Tooltip>
						</span>
					</a>
				),
			},
			{
				headerName: "Mobile",
				width: 150,
				field: "phoneNumber",
				renderCell: (record) => (
					<p onClick={() => this.props.history.push("/manage-users/user-profile/" + record.row.userId)}>
						{record.value ? record.value : "Nil"}
					</p>
				),
			},
			{
				headerName: "Mail Id",
				width: 150,
				field: "email",
				renderCell: (record) => (
					<Tooltip title={record.value ? record.value : "Nil"}>
						<p className="mailtext" onClick={() => this.props.history.push("/manage-users/user-profile/" + record.row.userId)}>
							{record.value ? record.value : "Nil"}
						</p>
					</Tooltip>
				),
			},
			{
				headerName: "Country",
				width: 150,
				field: "country",
			},
			{
				headerName: "Join Date",
				width: 150,
				field: "joiningDate",

				renderCell: (record) => (
					<p className="textBlack" onClick={() => this.props.history.push("/manage-users/user-profile/" + record.row.userId)}>
						{new Date(record.value).toLocaleDateString("en-IN", options)}
					</p>
				),
			},
			{
				headerName: "Request For",
				width: 150,
				field: "userRole",
				type: "singleSelect",
				valueOptions: [
					{ value: "ARTIST", label: "Artist" },
					{ value: "GALLERY", label: "Gallery" },
				],
				renderCell: (record) => {
					return (
						<React.Fragment>
							{record.value === "ARTIST" && (
								<p
									className="textnewBlack"
									onClick={() => this.props.history.push("/manage-users/user-profile/" + record.row.userId)}
								>
									Artist
								</p>
							)}
							{record.value === "GALLERY" && (
								<p
									className="textnewYellow"
									onClick={() => this.props.history.push("/manage-users/user-profile/" + record.row.userId)}
								>
									Gallery
								</p>
							)}
						</React.Fragment>
					);
				},
			},
			{
				headerName: "KYC",
				field: "kycStatus",
				width: 150,
				type: "singleSelect",
				valueOptions: [
					{ value: 0, label: "Pending" },
					{ value: 1, label: "Completed" },
					{ value: 2, label: "Approved" },
					{ value: 3, label: "Not Applicable" },
				],
				renderCell: (text) => {
					return (
						<div>
							{text.value === 0 && <p className="textOrange">Pending</p>}
							{text.value === 1 && <p className="textBrown">Completed</p>}
							{text.value === 2 && <p className="textGreen">Approved</p>}
							{text.value === 3 && <p className="textGreen">Not Applicable</p>}
						</div>
					);
				},
			},

			{
				headerName: "Action",
				field: "action",
				renderCell: (record) =>
					record.row.status <= 2 && (
						<Popover
							placement="bottom"
							content={content(record.row)}
							overlayClassName="manage-artworks-action-popover"
							// onVisibleChange={this.onVisibleChangeOfArtworkRecord.row.bind(this, record)}
						>
							<img className="action-image" src={require("../../assets/images/common/artworksActionIcon.svg")} alt="action" />
						</Popover>
					),
			},
		];

		const content = (record) => (
			<div>
				{record.status === 0 && (
					<span className="action-span" onClick={this.toggleApproveConfirmationDialog.bind(this, record, 1)}>
						{/* <img src={require("../../assets/images/common/Edit_New.svg")} alt="edit" /> */}
						<Icon type="check" />
						<p className="pop-item">Approve</p>
					</span>
				)}
				{record.status === 0 && (
					<span className="action-span" onClick={this.toggleRejectConfirmationDialog.bind(this, record, 2)}>
						{/* <img src={require("../../assets/images/common/Edit_New.svg")} alt="edit" /> */}
						<Icon type="close" />
						<p className="pop-item">Reject</p>
					</span>
				)}
				{record.kycStatus !== 2 && record.kycStatus !== 3 && (
					<span className="action-span" onClick={this.onClickUserKyc.bind(this, true, record)}>
						{/* <img src={require("../../assets/images/common/Edit_New.svg")} alt="edit" /> */}
						<Icon type="close" />
						<p className="pop-item">KYC clearance </p>
					</span>
				)}
				{record.kycStatus === 2 && (
					<span className="action-span" onClick={this.onClickUserKyc.bind(this, true, record)}>
						{/* <img src={require("../../assets/images/common/Edit_New.svg")} alt="edit" /> */}
						<Icon type="close" />
						<p className="pop-item">Review KYC</p>
					</span>
				)}
			</div>
		);

		return (
			<Root>
				<Topbar title={"User Request"} showSearch searchValue={this.state.searchValue} onChangeSearch={this.onChangeSearch.bind(this)} />
				<div className="manageusers-wrapper">
					<div className="top-wrapper">
						<span className={this.state.isButtonClicked ? "second-active" : "second-span"} onClick={this.onButtonClick.bind(this)}>
							Change Role
						</span>
						<span className={this.state.isButtonClicked ? "" : "active"} onClick={this.onButtonClick.bind(this)}>
							Delete Account
						</span>
					</div>

					{this.state.isButtonClicked ? (
						getRolechangeRequestList && getRolechangeRequestList.length !== 0 ? (
							<>
								<DataGrid
									pageSize={this.state.pageSize}
									onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
									pagination
									getRowId={(row) => row._id}
									columns={requestcolumns}
									rows={getRolechangeRequestList}
									rowsPerPageOptions={[20, 50, 100]}
								/>
							</>
						) : getRolechangeRequestLoader ? (
							<div className="manageusers-wrapper">
								<CircularProgress className="circular-loader" size={30} />
							</div>
						) : (
							<div className="manageusers-wrapper">
								<div className="empty-table">No Users Found.</div>
							</div>
						)
					) : (
						<></>
					)}
				</div>

				<ConfirmationMessageDialog
					open={this.state.approveconfirmationDialog}
					handleClose={this.toggleApproveConfirmationDialog.bind(this)}
					confirmMessage={"Are you sure you want to approve this user?"}
					onConfirm={this.onApproveUser.bind(this)}
				/>
				<ConfirmationMessageDialog
					open={this.state.rejectconfirmationDialog}
					handleClose={this.toggleRejectConfirmationDialog.bind(this)}
					confirmMessage={"Are you sure you want to reject this user?"}
					onConfirm={this.onRejectUser.bind(this)}
				/>
				<KycDocumentDialog
					open={kycDialog}
					approveKYC={this.approveKYC.bind(this)}
					handleClose={this.onToggleKycModel.bind(this, false)}
					data={kycDocumentResult && kycDocumentResult}
				/>
			</Root>
		);
	}

	// ------------------------ When Page loads/ URL changes ------------------------- //
	// Call API by fetching details from URL
	setSearchParams() {
		const { location } = this.props;

		// When filter/Search params exist
		if (location.search) {
			let parsedQuery = Util.queryStringHandling(location.search, "PARSE");
			if (parsedQuery.requests) {
			} else
				this.setState(
					{
						searchValue: parsedQuery.Search,
						pageNumValue: this.props.match.params.pageNum,
					},
					() => {
						this.doSearchFilterUsers(parsedQuery);
					}
				);
		}

		// When there is no filter/Search params
		else {
			this.setState({ isButtonClicked: true });
			let body = {
				PageNum: this.state.pageNumForUserRequest,
				PageSize: 10000000,
			};
			this.props.getRoleChangeRequestsAction(body);
		}
	}

	// --------------------- When filter/search/pagination events trigger --------------------- //

	//Pagination
	onSelectPageNumber = (name) => (event) => {
		this.setState({ pageNumValue: event }, () => {
			this.doChangeUrlWithQuery();
		});
	};

	onSelectPageNumberForUserRequest = (name) => (event) => {
		this.setState({ pageNumForUserRequest: event }, () => {
			let body = {};
			if (this.state.orderSort === null && this.state.orderOption === null) {
				body = {
					PageNum: event,
					PageSize: 12,
					KYCStatus: this.state.kycFilter,
					Status: this.state.artistStatus,
				};
			} else {
				body = {
					PageNum: event,
					PageSize: 12,
					KYCStatus: this.state.kycFilter,
					Status: this.state.artistStatus,
					sortOrder: this.state.orderSort,
					userSortOptions: this.state.orderOption,
				};
			}

			this.props.getRoleChangeRequestsAction(body);
		});
	};

	// Search
	onChangeSearch(search) {
		this.setState(
			{
				searchValue: search,
				pageNumValue: 1,
			},
			() => {
				if (this.state.isButtonClicked) {
					let body = {
						PageNum: this.state.pageNumForUserRequest,
						PageSize: 12,
						AlphabetSearch: search,
					};
					this.props.getRoleChangeRequestsAction(body);
				} else {
					this.doChangeUrlWithQuery();
				}
			}
		);
	}

	// Change URL based on filter/search/Page values
	doChangeUrlWithQuery() {
		let searchCriteria = {
			Search: this.state.searchValue,
		};
		const queryString = Util.queryStringHandling(searchCriteria, "STR");
	}

	// ----------------------- API - Fetch Sellers ----------------------------- //
	doSearchFilterUsers() {
		// POST request body
		let request = {
			pageNum: this.state.pageNumValue,
			pageSize: 12,
			Name: this.state.searchValue || "",
		};
		this.props.getUsersList(request);
	}

	// -------------------------- User Profile -------------------------- //
	toggleUserProfile(path, item) {
		window.location.href = "/#/" + path + "/" + item.userId;
		this.props.setUserProfile(item);
	}
	//----------------Dialog for approve artwork-----------------//
	toggleApproveConfirmationDialog(item, status) {
		this.setState(
			{
				approveconfirmationDialog: !this.state.approveconfirmationDialog,
			},
			() => {
				this.setState({
					userId: item._id,
					status: status,
				});
			}
		);
	}

	async onClickUserKyc(value, item) {
		await this.props.toggleKYCDialog(value);
		await this.props.getUserKycUser(item.userId);
	}

	async approveKYC(userId, isType) {
		await this.props.approveKYC(userId, isType);
		let body = {};
		// let body = {
		// 	PageNum: this.state.pageNumForUserRequest,
		// 	PageSize: 12,
		// }
		// await this.props.getRoleChangeRequestsAction(body);
		if (this.state.orderSort === null && this.state.orderOption === null) {
			body = {
				PageNum: this.state.pageNumForUserRequest,
				PageSize: 12,
				KYCStatus: this.state.kycFilter,
				Status: this.state.artistStatus,
			};
		} else {
			body = {
				PageNum: this.state.pageNumForUserRequest,
				PageSize: 12,
				KYCStatus: this.state.kycFilter,
				Status: this.state.artistStatus,
				sortOrder: this.state.orderSort,
				userSortOptions: this.state.orderOption,
			};
		}

		await this.props.getRoleChangeRequestsAction(body);
	}

	onToggleKycModel(value) {
		// Toggle dialog
		this.props.toggleKYCDialog(value);
	}
	onChangeFilterArtistStatus = (name) => (event) => {
		this.setState(
			{
				artistStatus: event,
			},
			() => {
				let request = {
					PageNum: parseInt(this.state.pageNumForUserRequest),
					PageSize: 12,
					KYCStatus: this.state.kycFilter,
					Status: this.state.artistStatus,
				};
				this.props.getRoleChangeRequestsAction(request);
			}
		);
	};
	onChangeFilterKyc = (name) => (event) => {
		this.setState(
			{
				kycFilter: event,
			},
			() => {
				let request = {
					PageNum: parseInt(this.state.pageNumForUserRequest),
					PageSize: 12,
					KYCStatus: this.state.kycFilter,
					Status: this.state.artistStatus,
				};
				this.props.getRoleChangeRequestsAction(request);
			}
		);
	};
	//-------------Dialog for reject artwork---------------------//
	toggleRejectConfirmationDialog(item, status) {
		this.setState(
			{
				rejectconfirmationDialog: !this.state.rejectconfirmationDialog,
			},
			() => {
				this.setState({
					userId: item._id,
					status: status,
				});
			}
		);
	}
	//-------------Approve User Confirm---------------------//
	async onApproveUser() {
		await this.props.updateRoleChangeAction(this.state.userId, this.state.status);
		await this.handleReset();
	}

	//-------------Reject User Confirm---------------------//
	async onRejectUser() {
		await this.props.updateRoleChangeAction(this.state.userId, this.state.status);
		await this.handleReset();
	}

	// -------------------------- Block / Unblock -------------------------- //
	//Dialog for blocking user
	toggleBlockConfirmationDialog(item) {
		this.setState(
			{
				blockconfirmationDialog: !this.state.blockconfirmationDialog,
			},
			() => {
				if (this.state.blockconfirmationDialog) {
					// set id of rule only when dialog opens
					this.setState({
						blockedUser: item.userId,
					});
				}
			}
		);
	}

	//Dialog for unblocking user
	toggleUnblockConfirmationDialog(item) {
		this.setState(
			{
				unblockconfirmationDialog: !this.state.unblockconfirmationDialog,
			},
			() => {
				if (this.state.unblockconfirmationDialog) {
					// set id of rule only when dialog opens
					this.setState({
						unblockedUser: item.userId,
					});
				}
			}
		);
	}

	// Block User Confirm
	onBlockUser() {
		let request = {
			pageNum: this.state.pageNumValue,
			pageSize: 12,
			Name: this.state.searchValue || "",
		};
		this.props.blockUser(this.state.blockedUser, request);
		this.handleReset();
	}

	// Unblock User Confirm
	onUnblockUser() {
		let request = {
			pageNum: this.state.pageNumValue,
			pageSize: 12,
			Name: this.state.searchValue || "",
		};
		this.props.unblockUser(this.state.unblockedUser, request);
		this.handleReset();
	}

	//Reset fields
	handleReset() {
		this.setState({
			...this.state,
			blockconfirmationDialog: false,
			unblockconfirmationDialog: false,
			approveconfirmationDialog: false,
			rejectconfirmationDialog: false,
			status: null,
			userId: "",
		});
	}

	//----------------Dropdown Visible Change-----------------//
	onVisibleChange(item) {
		this.setState({
			userId: item.userId,
		});
	}
	onButtonClick() {
		this.setState({ isButtonClicked: !this.state.isButtonClicked }, () => {
			if (this.state.isButtonClicked) {
				let body = {
					PageNum: 1,
					PageSize: 12,
					sortOrder: this.state.orderSort,
					userSortOptions: this.state.orderOption,
				};
				this.props.getRoleChangeRequestsAction(body);
			} else {
				// this.doChangeUrlWithQuery();
			}
		});
	}

	exportToExcelUserDetailsAction() {
		let body = {
			pageNum: null,
			pageSize: null,
			name: this.state.searchValue,
		};
		this.props.exportToExcelUserDetailsAction(body);
	}
}
const mapStateToProps = (state) => {
	return {
		manageUsersReducer: state.manageUsersReducer,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getUsersList: (userinput) => {
			dispatch(getUsersList(userinput));
		},
		blockUser: (userId, input) => {
			dispatch(blockUser(userId, input));
		},
		unblockUser: (userId, input) => {
			dispatch(unblockUser(userId, input));
		},
		setUserProfile: (userdata) => {
			dispatch(setUserProfile(userdata));
		},
		getRoleChangeRequestsAction: (body) => {
			dispatch(getRoleChangeRequestsAction(body));
		},
		updateRoleChangeAction: (userId, status) => {
			dispatch(updateRoleChangeAction(userId, status));
		},
		getUserKycUser: (userId) => {
			dispatch(getUserKycUser(userId));
		},
		approveKYC: (userId, isType) => {
			dispatch(approveKYC(userId, isType));
		},
		toggleKYCDialog: (value) => {
			dispatch(toggleKYCDialog(value));
		},
		exportToExcelUserDetailsAction: (body) => {
			dispatch(exportToExcelUserDetailsAction(body));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageUsers);
