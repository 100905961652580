import {
    GET_ALL_COUNTRY_LIST_REQUEST, GET_ALL_COUNTRY_LIST_SUCCESS, GET_ALL_COUNTRY_LIST_FAILURE,
    GET_COUNTRY_TAX_LIST_REQUEST, GET_COUNTRY_TAX_LIST_SUCCESS, GET_COUNTRY_TAX_LIST_FAILURE,
    ADD_COUNTRY_TAX_REQUEST, ADD_COUNTRY_TAX_SUCCESS, ADD_COUNTRY_TAX_FAILURE,
    UPDATE_COUNTRY_TAX_REQUEST, UPDATE_COUNTRY_TAX_SUCCESS, UPDATE_COUNTRY_TAX_FAILURE,
    DELETE_COUNTRY_TAX_REQUEST, DELETE_COUNTRY_TAX_SUCCESS, DELETE_COUNTRY_TAX_FAILURE, TOGGLE_ADD_COUNTRY_TAX_DIALOG
} from "../actions/ActionsTypes";

const initialState = {
    countriesListLoader: false,
    countriesList: [],
    countryTaxListLoader: false,
    countryTaxList: [],
    countryTaxListCount: 0,
    addCountryTaxLoader: false,
    addCountryTaxData: null,
    updateCountryTaxLoader: false,
    updateCountryTaxData: null,
    deleteCountryTaxLoader: false,
    deleteCountryTaxData: null,
    addCountryTaxDialog: false

};

const countriesTaxReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        // ======= Get all countries List ====== //
        case GET_ALL_COUNTRY_LIST_REQUEST:
            state = {
                ...state,
                countriesListLoader: true,
                countriesList: []
            };
            return state;

        case GET_ALL_COUNTRY_LIST_SUCCESS:
            state = {
                ...state,
                countriesListLoader: false,
                countriesList: action.data.countries
            };
            return state;

        case GET_ALL_COUNTRY_LIST_FAILURE:
            state = {
                ...state,
                countriesListLoader: false,
                countriesList: []
            };
            return state;

        // ======= Get Country Tax List ====== //
        case GET_COUNTRY_TAX_LIST_REQUEST:
            state = {
                ...state,
                countryTaxListLoader: true,
                countryTaxList: []
            };
            return state;

        case GET_COUNTRY_TAX_LIST_SUCCESS:
            state = {
                ...state,
                countryTaxListLoader: false,
                countryTaxList: action.data.taxList,
                countryTaxListCount: action.data.count
            };
            return state;

        case GET_COUNTRY_TAX_LIST_FAILURE:
            state = {
                ...state,
                countryTaxListLoader: false,
                countryTaxList: [],
                countryTaxListCount: 0
            };
            return state;

        // ======= Add Country Tax ====== //
        case ADD_COUNTRY_TAX_REQUEST:
            state = {
                ...state,
                addCountryTaxLoader: true,
                addCountryTaxData: null
            };
            return state;

        case ADD_COUNTRY_TAX_SUCCESS:
            state = {
                ...state,
                addCountryTaxLoader: false,
                addCountryTaxDialog: false,
                addCountryTaxData: action.data
            };
            return state;

        case ADD_COUNTRY_TAX_FAILURE:
            state = {
                ...state,
                addCountryTaxLoader: false,
                addCountryTaxDialog: true,
                addCountryTaxData: null
            };
            return state;

        // ======= Update Country Tax ====== //
        case UPDATE_COUNTRY_TAX_REQUEST:
            state = {
                ...state,
                addCountryTaxLoader: true,
                updateCountryTaxData: null
            };
            return state;

        case UPDATE_COUNTRY_TAX_SUCCESS:
            state = {
                ...state,
                addCountryTaxLoader: false,
                addCountryTaxDialog: false,
                updateCountryTaxData: action.data
            };
            return state;

        case UPDATE_COUNTRY_TAX_FAILURE:
            state = {
                ...state,
                addCountryTaxLoader: false,
                addCountryTaxDialog: true,
                updateCountryTaxData: null
            };
            return state;

        // ======= Delete Country Tax ====== //
        case DELETE_COUNTRY_TAX_REQUEST:
            state = {
                ...state,
                deleteCountryTaxLoader: true,
                deleteCountryTaxData: null
            };
            return state;

        case DELETE_COUNTRY_TAX_SUCCESS:
            state = {
                ...state,
                deleteCountryTaxLoader: false,
                deleteCountryTaxData: action.data
            };
            return state;

        case DELETE_COUNTRY_TAX_FAILURE:
            state = {
                ...state,
                deleteCountryTaxLoader: false,
                deleteCountryTaxData: null
            };
            return state;

        // --------- Toggle Dialog's --------- //
        case TOGGLE_ADD_COUNTRY_TAX_DIALOG:
            state = {
                ...state,
                addCountryTaxDialog: action.data
            };
            return state;

        default:
            return state;
    }
};

export default countriesTaxReducer;
