import React, { useEffect, useState } from "react";
import Root from "../Root/Root";
import Topbar from "../../components/Topbar/Topbar";
import { Pagination, Table, Popover, Tooltip } from "antd";
import styled from "styled-components";

import useSurveyForm from "./useSurveyForm";
import { isEmpty } from "lodash";
import { useTable, useExpanded } from "react-table";
import AnswerTable from "./AnswerTable";
import "./SurveyForms.style.scss";
function ViewSurveyAnswer({ match, history }) {
	const { surveyAnswers, getSurveyAnswers } = useSurveyForm();
	const [pageCount, setPageCount] = React.useState(0);

	useEffect(() => {
		if (match && match.params && match.params.surveyId) {
			let pageNum = match.params.pageNum !== undefined ? match.params.pageNum : 1;
			console.log("🚀 ~ file: ViewSurveyAnswer.js ~ line 19 ~ useEffect ~ pageNum", pageNum);
			getSurveyAnswers(match.params.surveyId, pageNum);
		}
	}, [match]);

	const nextPage = () => {};
	const renderRowSubComponent = React.useCallback(
		(row) => (
			<div>
				{row &&
					row.answ.map((data, index) => (
						<div className="view-user-answers-container">
							<p className="questions-text">{`${index + 1}: ${data.questionText}`}</p>
							<div className="ans-container">
								{data.answer.map((ans, i) => (
									<p className="ans-text">{ans}</p>
								))}
							</div>
						</div>
					))}
			</div>
		),
		[]
	);
	const surveyDefaultColumns = [
		{
			// Make an expander cell
			Header: () => null, // No header
			id: "expander", // It needs an ID
			Cell: ({ row }) => (
				// Use Cell to render an expander for each row.
				// We can use the getToggleRowExpandedProps prop-getter
				// to build the expander.
				<span {...row.getToggleRowExpandedProps()}>{row.isExpanded ? "👇" : "👉"}</span>
			),
		},
		{
			Header: "Answers",
			columns: [
				{
					Header: "Name",
					accessor: "userName",
				},
				{
					Header: "Email",
					accessor: "userEmail",
				},
				{
					Header: "Country",
					accessor: "userCountry",
				},
				{
					Header: "Phone",
					accessor: "userPhoneNumber",
				},
				{
					Header: "Date Of Birth",
					accessor: "userDateOfBirth",
				},
				{
					Header: "Created Date",
					accessor: "createdDate",
				},
			],
		},
	];
	const fetchData = React.useCallback(({ pageSize, pageIndex }) => {
		console.log("🚀 ~ file: ViewSurveyAnswer.js ~ line 85 ~ fetchData ~ pageSize", pageSize);
		history.push(`/survey-answers/${match.params.surveyId}/${pageIndex === 0 ? 1 : pageIndex}`);
	}, []);
	return (
		<Root>
			<Topbar title={"View Answers"} />
			<div className="survey-answer-main-wrapper">
				<Styles>
					<AnswerTable
						columns={surveyDefaultColumns}
						data={surveyAnswers}
						// We added this as a prop for our table component
						// Remember, this is not part of the React Table API,
						// it's merely a rendering option we created for
						// ourselves
						pageCount={100}
						fetchData={fetchData}
						renderRowSubComponent={renderRowSubComponent}
					/>
						<div className="answer-go-back-wrapper" onClick={()=>history.push('/survey-forms')}>
					<div>Go Back</div>
				</div>
				</Styles>
			
			</div>
		</Root>
	);
}

const Styles = styled.div`
	padding: 1rem;
	height: 100vh;
	overflow-y: scroll;
	table {
		border-spacing: 0;
		border: 1px solid black;
		width: 100%;
		tbody {
			height: 200px;
			overflow-y: auto; /* Trigger vertical scroll    */
			overflow-x: hidden;
		}
		tr {
			:last-child {
				td {
					border-bottom: 0;
				}
			}
		}

		th,
		td {
			margin: 0;
			padding: 0.5rem;
			border-bottom: 1px solid black;
			border-right: 1px solid black;

			:last-child {
				border-right: 0;
			}
		}
	}
`;

export default ViewSurveyAnswer;
