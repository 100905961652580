import React, { Component } from 'react';
import { connect } from "react-redux";
import { CircularProgress } from '@material-ui/core';
import { Redirect } from 'react-router-dom';

import './TwoFactor.scss';
import { popToast, Toast } from '../../components/Toast/Toast';
import { validate2FAOTPAction } from '../../redux/actions/CommonAction';

class TwoFactor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            verificationCode: "",
            mailId: this.props.match.params.id,
            redirectToReferrer: false
        };
    }

    componentWillReceiveProps(newProps) {
        this.setState({ redirectToReferrer: newProps.loginReducer.redirectToReferrer });
    }

    render() {
        const { from } = this.props.location.state || { from: { pathname: "/dashboard" } };

        if (this.state.redirectToReferrer === true) {
            return <Redirect to={from} />
        }
        return (
            <div className="two-factor-wrapper">
                <Toast />
                <div className="app-logo">
                    <img src={require("../../assets/images/common/Logo.svg")} alt="logo" />
                </div>
                <div className="two-factor-contents">
                    <p className="page-title">Two Factor Authentication</p>
                    <p className="message">Enter the verification code we just sent you on your email address</p>
                    <div className="form-control">
                        <label className="form-label">Enter Verification Code</label>
                        <input
                            type="number"
                            autoFocus
                            className="form-input"
                            value={this.state.verificationCode}
                            onChange={this.onHandleInput('verificationCode')}
                        />
                    </div>
                    <div className="end-btn">
                        <button className="btn-orange"
                            onClick={this.onsubmitOtp.bind(this)}
                            disabled={this.props.loginReducer.twoFactorRequest}
                        >Verify
                        {this.props.loginReducer.twoFactorRequest && <CircularProgress size={24} className="two-factor-progress" />}
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    onHandleInput = (name) => (event) => {
        this.setState({
            [name]: event.target.value
        });
    }

    onsubmitOtp() {
        if (this.state.verificationCode) {
            let validInput = {
                username: this.state.mailId,
                otp: this.state.verificationCode
            }
            this.props.validate2FAOTPAction(validInput);
        }
        else {
            if (this.state.verificationCode === "") {
                popToast.show("Please Enter Verification Code");
            }
        }
    }

}
// --------------------------react-redux connection-------------------------
//mapStateToProps is used to update the component if react's store is changed
const mapStateToProps = state => {
    return {
        loginReducer: state.loginReducer
    };
};

//mapDispatchToProps is used to inject dispatch function within the component
const mapDispatchToProps = dispatch => {
    return {
        validate2FAOTPAction: (credentials) => {
            dispatch(validate2FAOTPAction(credentials));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TwoFactor);

