import React, { Component } from "react";
import { CircularProgress } from "@material-ui/core";
import { Tooltip } from "antd";
// Redux
import { connect } from "react-redux";
import {
	getAllCategories,
	getCategoryProperties,
	toggleAddCategoryDialog,
	toggleAddPropertyDialog,
	deleteCategory,
	toggleDeleteMainCategoryDialog,
	toggleEditViewDialog,
	toggleDeletePropertyDialog,
	deleteProperty,
	setCategoryReducerStates,
	setBreadcrumbs,
	toggleListRuleDialog,
} from "../../redux/actions/CategoryAction";
import { getAllFilterRules } from '../../redux/actions/FilterRuleAction'

// Components
import Root from "../Root/Root";
import Topbar from "../../components/Topbar/Topbar";
import AddCategoryDialog from "./AddCategoryDialog/AddCategoryDialog";
import AddPropertyDialog from "./AddPropertyDialog/AddPropertyDialog";
import ConfirmationMessageDialog from "../../components/ConfirmationMessageDialog/ConfirmationMessageDialog";
import UpdateCategoryDialog from "./UpdateCategoryDialog/UpdateCategoryDialog";
import DeleteMainCategoryDialog from "./DeleteMainCategoryDialog/DeleteMainCategoryDialog";
// SCSS
import "./Categories.scss";
import ListRuleDialog from "./ListRuleDialog/ListRuleDialog";

class Categories extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showEditOptions: false,
			isMainCategory: true,

			isUpdatingMainCategory: true,
			updatingCategoryData: {},

			isUpdatingProperty: false,
			updatingPropertyData: {},

			isPropertyOfSubCategory: false,

			propertyToDelete: {},
			isClicked: true,
			selectedRecordToPerformAction: null
		};
	}

	componentDidMount() {
		this.routeToCategories();
		this.props.getAllFilterRules('');
	}

	componentDidUpdate(prevProps) {
		const locationChanged = this.props.location !== prevProps.location;
		if (locationChanged) {
			this.routeToCategories();
		}
	}

	routeToCategories() {
		if (this.props.match.params.id) {
			// Load Breadcrumbs
			this.props.setBreadcrumbs(this.props.match.params.id);
			// Load Categories
			this.props.getAllCategories(this.props.match.params.id);
		} else {
			// Load Breadcrumbs
			this.props.setBreadcrumbs();
			// Load Categories
			this.props.getAllCategories();
		}
	}

	render() {
		const {
			categoryList,
			categoryPropertyList,
			addCategoryDialog,
			addPropertyDialog,
			categoryLoader,
			propertyLoader,
			deleteMainCategoryDialog,
			deletePropertyDialog,
			editViewCategoryDialog,
			modalLoader,
			// for selection
			selectedPropertyId,
			selectedCategoryId,
			breadCrumbList,
			listRuleDialog,
		} = this.props.categoryReducer;
		console.log("🚀 ~ file: Categories.js ~ line 96 ~ Categories ~ render ~ breadCrumbList", breadCrumbList)
		// MAIN PRODUCT ID : 5cb707fea6bf3f1e185b5820
		// {/* ---------------- Main Category List ---------------- */ }
		const mainCategory = categoryList && categoryList.map((main, index) => {
			return (
				<li
					key={main._id}
					className={selectedCategoryId === main._id ? "active" : ""}
					onClick={this.setCurrentStates.bind(this, "selectedCategoryId", main._id)}
				>
					<div className="list-item-content">
						<img src={main.categoryImage || require("../../assets/images/common/Icon.svg")} alt="Category" />
						<Tooltip placement="topLeft" title={main.categoryName}>
							<p>{main.categoryName}</p>
						</Tooltip>
					</div>
					<div className="list-item-action">
						{/* Hover/Active */}
						<div className="edit-options">
							{this.props.match.params.id &&
								<Tooltip placement="top" title={"View Rule"}>
									<div className="image-wrapper" onClick={this.toggleListRuleDialog.bind(this, true, main)}>
										<img
											src={require("../../assets/images/common/subcaegories.svg")}
											alt="View Sub Category"
											onMouseOver={e => (e.currentTarget.src = require("../../assets/images/common/View_active.svg"))}
											onMouseOut={e => (e.currentTarget.src = require("../../assets/images/common/subcaegories.svg"))}
										/>
									</div>
								</Tooltip>
							}
							<Tooltip placement="top" title={"View Sub Category"}>
								<div className="image-wrapper" onClick={this.onClickSubCategory.bind(this, main, "PUSH_BREADCRUMB")}>
									<img
										src={require("../../assets/images/common/subcaegories.svg")}
										alt="View Sub Category"
										onMouseOver={e => (e.currentTarget.src = require("../../assets/images/common/View_active.svg"))}
										onMouseOut={e => (e.currentTarget.src = require("../../assets/images/common/subcaegories.svg"))}
									/>
								</div>
							</Tooltip>
							<Tooltip placement="top" title={"Edit Category"}>
								<div className="image-wrapper" onClick={this.onToggleEditViewDialog.bind(this, main)}>
									<img
										src={require("../../assets/images/common/Viewa&Edit.svg")}
										alt="View"
										onMouseOver={e => (e.currentTarget.src = require("../../assets/images/common/Edit_active.svg"))}
										onMouseOut={e => (e.currentTarget.src = require("../../assets/images/common/Viewa&Edit.svg"))}
									/>
								</div>
							</Tooltip>

							{/* TODO : Remove below check before deployment */}
							{main._id !== "5cb715623007be1f4011544d" && (
								<Tooltip placement="top" title={"Delete Category"}>
									<div className="image-wrapper" onClick={this.onClickDelete.bind(this, main)}>
										<img
											src={require("../../assets/images/common/delete_black.svg")}
											alt="Delete"
											onMouseOver={e => (e.currentTarget.src = require("../../assets/images/common/delete_active.svg"))}
											onMouseOut={e => (e.currentTarget.src = require("../../assets/images/common/delete_black.svg"))}
										/>
									</div>
								</Tooltip>
							)}
						</div>
					</div>
				</li>
			);
		});

		// {/* ---------------- Property List ---------------- */ }
		const subProperty = categoryPropertyList.map((prop, index) => {
			return (
				<li
					key={prop._id}
					className={selectedPropertyId === prop._id ? "active" : ""}
					onClick={this.setCurrentStates.bind(this, "selectedPropertyId", prop._id)}
				>
					<div className="list-item-content">
						<Tooltip placement="topLeft" title={prop.propertyName}>
							<p>{prop.propertyName}</p>
						</Tooltip>
					</div>
					<div className="list-item-action">
						{/* Hover/Active */}
						<div className="edit-options">
							<Tooltip placement="top" title={"Edit Property"}>
								<div className="image-wrapper" onClick={this.toggleAddPropertyDialog.bind(this, true, prop, true)}>
									<img
										src={require("../../assets/images/common/Viewa&Edit.svg")}
										alt="View"
										onMouseOver={e => (e.currentTarget.src = require("../../assets/images/common/Edit_active.svg"))}
										onMouseOut={e => (e.currentTarget.src = require("../../assets/images/common/Viewa&Edit.svg"))}
									/>
								</div>
							</Tooltip>
							<Tooltip placement="top" title={"Delete Property"}>
								<div className="image-wrapper" onClick={this.onClickDeleteProperty.bind(this, prop)}>
									<img
										src={require("../../assets/images/common/delete_black.svg")}
										alt="Delete"
										onMouseOver={e => (e.currentTarget.src = require("../../assets/images/common/delete_active.svg"))}
										onMouseOut={e => (e.currentTarget.src = require("../../assets/images/common/delete_black.svg"))}
									/>
								</div>
							</Tooltip>
						</div>
					</div>
				</li>
			);
		});

		return (
			<Root>
				<Topbar title={"Categories"} showBreadCrumbs breadCrumbList={breadCrumbList} loadSubCategories={this.onClickSubCategory.bind(this)} />

				<div className="category-wrapper">
					{/* ---------------- Main Category List ---------------- */}
					<div className="category-list">
						{categoryList.length > 0 ? (
							<ul className="category-list-wrapper">{mainCategory}</ul>
						) : categoryLoader ? (
							<div className="category-list-wrapper">
								<CircularProgress className="circular-loader" size={20} />
								Fetching categories...
							</div>
						) : (
							<div className="category-list-wrapper">No categories found.</div>
						)}
						<div className="add-button-box">
							<Tooltip placement="top" title={"Add Category"}>
								{/* <img src={require('../../assets/images/common/Add.svg')} alt="Add Category"
                                    onClick={this.toggleAddCategoryDialog.bind(this, true)} /> */}
								<span className="add-btn" onClick={this.toggleAddCategoryDialog.bind(this, true)}>
									<p>+</p> Add Category
								</span>
							</Tooltip>
						</div>
					</div>

					{/* ---------------- Properties List ---------------- */}
					<div className="category-list">
						{categoryPropertyList.length > 0 ? (
							<ul className="category-list-wrapper">
								<div className="list-head">
									<div
										className={this.state.isClicked ? "active" : "list-item"}
										onClick={() => {
											this.setState({ isClicked: true });
										}}
									>
										Properties
									</div>
									{/* <div
										className={!this.state.isClicked ? "active" : "list-item"}
										onClick={() => {
											this.setState({ isClicked: false });
										}}
									>
										Variant
									</div> */}
								</div>
								{subProperty}
							</ul>
						) : propertyLoader ? (
							<div className="category-list-wrapper">
								<CircularProgress className="circular-loader" size={20} />
								Fetching properties...
							</div>
						) : (
							<div className="category-list-wrapper">No properties found.</div>
						)}
						<div className="add-button-box">
							<Tooltip placement="top" title={"Add Property"}>
								{/* <img className={selectedCategoryId ? '' : 'disabled-image'}
                                    src={require('../../assets/images/common/Add.svg')} alt="Add property"
                                    onClick={this.toggleAddPropertyDialog.bind(this, true, null, false, false)} /> */}
								<span className="add-btn" onClick={this.toggleAddPropertyDialog.bind(this, true, null, false, false)}>
									<p>+</p> Add Properties
								</span>
							</Tooltip>
						</div>
					</div>

					{/* Create Category */}
					<AddCategoryDialog
						open={addCategoryDialog}
						isMainCategory={this.state.isMainCategory}
						handleClose={this.toggleAddCategoryDialog.bind(this, false, true)}
					/>

					{/* Create/Update Property */}
					<AddPropertyDialog
						open={addPropertyDialog}
						isPropertyOfSubCategory={this.state.isPropertyOfSubCategory}
						isUpdatingProperty={this.state.isUpdatingProperty}
						updatingPropertyData={this.state.updatingPropertyData}
						handleClose={this.toggleAddPropertyDialog.bind(this, false)}
					/>

					{/* Delete Category */}
					<DeleteMainCategoryDialog
						open={deleteMainCategoryDialog}
						loader={modalLoader}
						handleClose={this.toggleDeleteMainCategoryDialog.bind(this)}
						onConfirm={this.onDeleteMainCategory.bind(this)}
					/>

					{/* Update Category */}
					<UpdateCategoryDialog
						open={editViewCategoryDialog}
						isUpdatingMainCategory={this.state.isUpdatingMainCategory}
						updatingCategoryData={this.state.updatingCategoryData}
						handleClose={this.onCloseEditViewDialog.bind(this, false)}
					/>

					{/* Delete Property */}
					<ConfirmationMessageDialog
						open={deletePropertyDialog}
						handleClose={this.toggleDeletePropertyDialog.bind(this)}
						confirmMessage={"Are you sure you want to delete this property?"}
						confirmButton={"Delete"}
						loader={modalLoader}
						onConfirm={this.onDeleteProperty.bind(this)}
					/>
					<ListRuleDialog
						open={listRuleDialog}
						updateData={this.state.selectedRecordToPerformAction}
						handleClose={this.toggleListRuleDialog.bind(this, false, null)}
					/>
				</div>
			</Root>
		);
	}

	// To set reducer states
	setCurrentStates(state, id) {
		this.props.setCategoryReducerStates(state, id);
		if (state === "selectedCategoryId") {
			// this.props.getCategoryProperties(id);
		}
	}

	// ====================== List Rule [TOGGLE] ====================== //
	async toggleListRuleDialog(value, item) {
		// Toggle dialog
		this.setState({
			selectedRecordToPerformAction: item,
		}, async () => {
			// Toggle dialog
			await this.props.toggleListRuleDialog(value);
		})
	}

	// ====================== CREATE CATEGORY [TOGGLE] ====================== //
	async toggleAddCategoryDialog(value) {
		// Below code is to check if we are going to create main category/sub category
		// Bcz main categories doesnt have isFeatured checkbox
		(await this.props.categoryReducer.currentParentId) === "5cb707fea6bf3f1e185b5820"
			? this.setState({ isMainCategory: true })
			: this.setState({ isMainCategory: false });

		// Toggle dialog
		this.props.toggleAddCategoryDialog(value);
	}

	// ====================== CREATE/UPDATE PROPERTY ====================== //
	toggleAddPropertyDialog(value, data = {}, isUpdating = false, isSubCat = false) {
		// First Value : Toggle value
		// Second : Data (Update)
		// Third: isUpdating or not boolean
		// Fourth : If creating/updating property of subcategory or not (true / false)
		this.setState(
			{
				isUpdatingProperty: isUpdating,
				updatingPropertyData: data,
				isPropertyOfSubCategory: isSubCat
			},
			() => {
				this.props.toggleAddPropertyDialog(value);
			}
		);
	}

	// ====================== DELETE CATEGORY ====================== //

	// Open the confirmation modal
	onClickDelete = async data => {
		await this.setState({ dataToDelete: null });
		this.setState(
			{
				dataToDelete: data
			},
			() => {
				this.props.toggleDeleteMainCategoryDialog(true);
			}
		);
	};

	toggleDeleteMainCategoryDialog() {
		this.props.toggleDeleteMainCategoryDialog(false);
	}

	// On delete main category
	onDeleteMainCategory(isForceDelete) {
		let currentParentId = this.props.categoryReducer.currentParentId;
		// currentParentId :  to refresh list
		this.props.deleteCategory(currentParentId, isForceDelete, this.state.dataToDelete);
	}

	// ====================== UPDATE CATEGORY ====================== //
	// Open edit category dialog
	async onToggleEditViewDialog(data = {}) {
		// Below code is to check if we are going to create main category/sub category
		// Bcz main categories doesnt have isFeatured checkbox
		(await this.props.categoryReducer.currentParentId) === "5cb707fea6bf3f1e185b5820"
			? this.setState({ isUpdatingMainCategory: true })
			: this.setState({ isUpdatingMainCategory: false });

		this.setState(
			{
				updatingCategoryData: data
			},
			() => {
				this.props.toggleEditViewDialog(true);
			}
		);
	}
	// Close edit category dialog
	onCloseEditViewDialog(value) {
		this.props.toggleEditViewDialog(value);
	}

	// ====================== DELETE PROPERTY ====================== //
	// Open delete property confirmation
	onClickDeleteProperty(data) {
		this.setState(
			{
				propertyToDelete: data
			},
			() => {
				this.props.toggleDeletePropertyDialog(true);
			}
		);
	}
	// Close confirmation modal
	toggleDeletePropertyDialog() {
		this.props.toggleDeletePropertyDialog(false);
	}
	// Delete property
	onDeleteProperty() {
		// currentParentId to refresh list
		let currentParentId = this.props.categoryReducer.currentParentId;
		this.props.deleteProperty(currentParentId, this.state.propertyToDelete);
	}

	// ====================== DYNAMIC SUBCATEGORY LISTING ====================== //
	onClickSubCategory(parentData) {
		if (parentData._id) this.props.history.push("/categories/" + parentData._id);
		else this.props.history.push("/categories");
	}

	// Close all dialogs when route changes
	componentWillUnmount() {
		this.props.setCategoryReducerStates("deletePropertyDialog", false);
		this.props.setCategoryReducerStates("editViewCategoryDialog", false);
		this.props.setCategoryReducerStates("deleteMainCategoryDialog", false);
		this.props.setCategoryReducerStates("addPropertyDialog", false);
		this.props.setCategoryReducerStates("addCategoryDialog", false);
	}
}

const mapStateToProps = state => {
	return {
		categoryReducer: state.categoryReducer,
		filterRuleReducer: state.filterRuleReducer

	};
};

const mapDispatchToProps = dispatch => {
	return {
		getAllCategories: (parentId, isNewListing) => {
			dispatch(getAllCategories(parentId, isNewListing));
		},
		setBreadcrumbs: (parentData, task) => {
			dispatch(setBreadcrumbs(parentData, task));
		},
		getCategoryProperties: parentId => {
			dispatch(getCategoryProperties(parentId));
		},
		setCategoryReducerStates: (state, value) => {
			dispatch(setCategoryReducerStates(state, value));
		},
		toggleAddCategoryDialog: value => {
			dispatch(toggleAddCategoryDialog(value));
		},
		toggleAddPropertyDialog: value => {
			dispatch(toggleAddPropertyDialog(value));
		},
		toggleDeleteMainCategoryDialog: value => {
			dispatch(toggleDeleteMainCategoryDialog(value));
		},
		deleteCategory: (type, currentParentId, isForceDelete, data) => {
			dispatch(deleteCategory(type, currentParentId, isForceDelete, data));
		},
		toggleEditViewDialog: value => {
			dispatch(toggleEditViewDialog(value));
		},
		toggleDeletePropertyDialog: value => {
			dispatch(toggleDeletePropertyDialog(value));
		},
		deleteProperty: (currentParentId, propertyToDelete) => {
			dispatch(deleteProperty(currentParentId, propertyToDelete));
		},
		toggleListRuleDialog: value => {
			dispatch(toggleListRuleDialog(value));
		},
		getAllFilterRules: (searchValue) => {
			dispatch(getAllFilterRules(searchValue))
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Categories);
