import React, { Component } from 'react';
// Components
import Navbar from '../../components/Navbar/Navbar';
import { Toast } from '../../components/Toast/Toast';
// SCSS
import './Root.scss';

import { createHashHistory } from "history";
const history = createHashHistory();
export default class Root extends Component {
    render() {
        return (
            <div className="wrapper">
                <Toast />

                {/* ------------- Navbar ------------- */}
                <Navbar history ={history} />

                {/* ------------- Body ------------- */}
                <div className="main-body-wrapper">{this.props.children}</div>
            </div>
        );
    }
}
