import { APIUrlConstants } from "../../util/apiUrl.constant";
import { APIService } from "../../util/api.service";
import { Util } from "../../util/util";

export class AuctionService {
	/* Auction */
	static async getAuctionList(pageNum, pageSize, keyword, artworkType, startDate, endDate) {
		let url =
			APIUrlConstants.getApiUrl("getAuctionList") +
			"?pageNum=" +
			pageNum +
			"&pageSize=" +
			pageSize +
			"&Keyword=" +
			keyword +
			"&ArtworkType=" +
			artworkType +
			"&StartDate=" +
			startDate +
			"&EndDate=" +
			endDate;
		return APIService.doGet(url).then((resp) => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	static async getAuctionById(id) {
		let url = APIUrlConstants.getApiUrl("auction") + "?id=" + id;
		return APIService.doGet(url).then((resp) => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	static async updateAuction(auctionId, request) {
		let url = APIUrlConstants.getApiUrl("auction") + "?Id=" + auctionId;
		let formBodyRequest = Util.objectToFormData(request);
		return APIService.doPutMultipart(url, formBodyRequest).then((resp) => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	static async deleteAuction(auctionId) {
		let url = APIUrlConstants.getApiUrl("auction") + "?Id=" + auctionId;
		return APIService.doDelete(url).then((resp) => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	static async addAuction(request) {
		let url = APIUrlConstants.getApiUrl("auction");
		let formBodyRequest = Util.objectToFormData(request);
		return APIService.doUploadMultipart(url, formBodyRequest).then((resp) => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	/* end */

	/* Auction Lot */

	static async getAuctionLotById(auctionLotId) {
		let url = APIUrlConstants.getApiUrl("auctionLot") + "?Id=" + auctionLotId;
		return APIService.doGet(url).then((resp) => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	static async getAuctionLotList(auctionLotFilter) {
		let url = APIUrlConstants.getApiUrl("auctionLotList");
		return APIService.doPost(url, auctionLotFilter).then((resp) => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	static async swapAuctionLot(lot1, lot2) {
		let url = APIUrlConstants.getApiUrl("auctionLot") + "/Swap?Lot1=" + lot1 + "&Lot2=" + lot2;
		return APIService.doPut(url).then((resp) => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	static async updateAuctionLot(auctionLotId, request) {
		let url = APIUrlConstants.getApiUrl("auctionLot") + "?Id=" + auctionLotId;
		let formBodyRequest = Util.objectToFormData(request);
		return APIService.doPutMultipart(url, formBodyRequest).then((resp) => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	static async deleteAuctionLot(auctionLotId) {
		let url = APIUrlConstants.getApiUrl("auctionLot") + "?Id=" + auctionLotId;
		return APIService.doDelete(url).then((resp) => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	static async addAuctionLot(request) {
		// let url = APIUrlConstants.getApiUrl("auctionLot");
		let url = APIUrlConstants.getApiUrl("auctionCreate");
		//let formBodyRequest = Util.objectToFormData(request);
		return APIService.doPost(url, request).then((resp) => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	static async getAuctionBiddingHistoryByLotId(auctionLotId) {
		let url = APIUrlConstants.getApiUrl("auctionBiddingHistory") + "?PageNum=1&PageSize=100&LotId=" + auctionLotId;
		return APIService.doGet(url).then((resp) => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	static async getAuctionLotIdsByAuctionId(auctionId) {
		let url = APIUrlConstants.getApiUrl("getAuctionLotIds") + "?AuctionId=" + auctionId;
		return APIService.doGet(url).then((resp) => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	static async getSmartContracts(contractType) {
		let url = APIUrlConstants.getApiUrl("smartContractUrl") + "?ContractType=" + contractType;
		return APIService.doGet(url).then((resp) => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	static async createSmartContract(smartContractRequest) {
		let url = APIUrlConstants.getApiUrl("createSmartContract");
		return APIService.doPost(url, smartContractRequest).then((resp) => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	/* end */
}
