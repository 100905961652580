import styled from "styled-components";


const ReactTableWrapper = styled.div` 
    overflow: auto;
    width: 100%

    table {
      width:100%;
    //border-spacing: 0;
    //border: 1px solid black;

    thead > tr {
      background: #E6E6E6;
    }

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      // border-bottom: 1px solid black;
      // border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`

const PaginationSection = styled.div` 
    display: flex;
    gap: 0.8rem;
    padding: 1rem 0;
    align-items: center;

`;


const PageNextButtonWrapper = styled.div` 
    display: flex;
    gap: .5rem;
`

const PageSelect = styled.select`   
    width: 120px;
    border-radius: 4px;
    padding: 4px 6px;
`;

const TableFilterDiv = styled.div` 

  select {
    width: 80%;
    border-radius: 4px;
    padding: 0 .5rem;
    background: #fff;
  }

  input{
    width:11rem;
  }
`

export {
  ReactTableWrapper,
  PageNextButtonWrapper,
  PaginationSection,
  PageSelect,
  TableFilterDiv,
}