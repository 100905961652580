import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import { DialogTitle, DialogContent, CircularProgress } from "@material-ui/core";

// Components
import { popToast } from "../../../components/Toast/Toast";
// Redux
import { connect } from "react-redux";
// SCSS
import "./AddShippingChargesDialog.scss";
import { addShippingChargesAction, updateShippingChargesAction } from "../../../redux/actions/ShippingChargesAction";

class AddShippingChargesDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fromCountry: "",
            toCountry: "",
            shippingCharge: '0'
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevProps.open && this.props.open) {
            const updateData = this.props.updateData;
            this.setState({
                fromCountry: (updateData && updateData.fromCountryName) || "",
                toCountry: (updateData && updateData.toCountryName) || "",
                shippingCharge: (updateData && updateData.shippingCharge) || '0'
            });
        }
    }

    render() {
        const { addShippingChargesLoader, updateShippingChargesLoader } = this.props.shippingChargesReducer;
        const { countriesList } = this.props.countriesTaxReducer;

        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.handleClose}
                BackdropProps={{ classes: { root: "dialog-backdrop" } }}
                className="add-shipping-charges-dialog"
            >
                <img className="close-btn" onClick={this.props.handleClose} src={require("../../../assets/images/common/Close_h.svg")} alt="" />
                <DialogTitle className="dialog-title">Add Shipping Charge</DialogTitle>
                <DialogContent className="add-shipping-charges-content">
                    <div className="add-shipping-charges-body">
                        <div className="form-control">
                            <label className="form-label">From Country</label>
                            <select
                                className="form-input"
                                value={this.state.fromCountry}
                                disabled={addShippingChargesLoader || updateShippingChargesLoader}
                                onChange={this.onHandleInput("fromCountry")}
                            >
                                <option value="" disabled>
                                    Select
							</option>
                                {countriesList && countriesList.length !== 0 &&
                                    countriesList.map((item, index) => (
                                        <option key={index} value={item.name}>
                                            {item.name}
                                        </option>
                                    ))}
                            </select>
                        </div>
                        <div className="form-control">
                            <label className="form-label">To Country</label>
                            <select
                                className="form-input"
                                value={this.state.toCountry}
                                disabled={addShippingChargesLoader || updateShippingChargesLoader}
                                onChange={this.onHandleInput("toCountry")}
                            >
                                <option value="" disabled>
                                    Select
							</option>
                                {countriesList && countriesList.length !== 0 &&
                                    countriesList.map((item, index) => (
                                        <option key={index} value={item.name}>
                                            {item.name}
                                        </option>
                                    ))}
                            </select>
                        </div>
                        <div className="form-control">
                            <label className="form-label">Shipping Charge</label>
                            <input
                                type="number"
                                min="0"
                                className="form-input"
                                value={this.state.shippingCharge}
                                disabled={addShippingChargesLoader || updateShippingChargesLoader}
                                onChange={this.onHandleInput("shippingCharge")}
                            />
                        </div>
                    </div>

                    <div className="add-shipping-charges-action">
                        <button className="btn-cancel" onClick={this.onClickCancel.bind(this)}>
                            Cancel
						</button>
                        <button className="btn-orange" onClick={this.onClickCreate.bind(this)} disabled={addShippingChargesLoader || updateShippingChargesLoader}>
                            {this.props.updateData ? "Update" : "Save"}
                            {(addShippingChargesLoader || updateShippingChargesLoader) && <CircularProgress size={24} className="circular-loader modal-button-loader" />}
                        </button>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }

    // Handle inputs
    onHandleInput = name => event => {
        this.setState({
            [name]: event.target.value
        });
    };

    isFormValid() {
        if (!this.state.fromCountry.trim()) {
            popToast.show("Please enter from country name");
            return false;
        }
        if (!this.state.toCountry.trim()) {
            popToast.show("Please enter to country name");
            return false;
        }
        if (!this.state.shippingCharge || +this.state.shippingCharge < 0) {
            popToast.show("Please enter shippingCharge");
            return false;
        }
        return true;
    }

    onClickCreate() {
        if (this.isFormValid()) {
            let request = {
                FromCountry: this.state.fromCountry,
                ToCountry: this.state.toCountry,
                ShippingCharge: this.state.shippingCharge
            }
            this.props.updateData ? this.props.updateShippingChargesAction(request, this.props.updateData._id) : this.props.addShippingChargesAction(request);
        }
    }

    onClickCancel() {
        this.props.handleClose();
    }
}

const mapStateToProps = state => {
    return {
        countriesTaxReducer: state.countriesTaxReducer,
        shippingChargesReducer: state.shippingChargesReducer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addShippingChargesAction: (request) => {
            dispatch(addShippingChargesAction(request));
        },
        updateShippingChargesAction: (request, id) => {
            dispatch(updateShippingChargesAction(request, id));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddShippingChargesDialog);
