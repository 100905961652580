import React, { Component } from "react";
import "./SkillChip.scss";

export default class SkillChip extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }

    render() {
        return (
            <div className="chip">
                <p className="text">{this.props.skillName}</p>
            </div>
        );
    }
}
