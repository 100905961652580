import styled from "styled-components";

const BlogArticleTableWrapper = styled.div` 
    padding: 1rem;
    display: flex;
    flex-direction: column;
  }
`;

const IsActiveCheckBoxWrapper = styled.div` 
    display: flex;
    width: 150px;
    justify-content: space-around;

`

export {
  BlogArticleTableWrapper,
  IsActiveCheckBoxWrapper
}