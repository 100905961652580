import { APIUrlConstants } from "../../util/apiUrl.constant";
import { APIService } from "../../util/api.service";
import { Util } from "../../util/util";

export class ManageArtworkService {
	static getArtworksList(body) {
		let url = APIUrlConstants.getApiUrl("getArtworksList");
		return APIService.doPost(url, body).then((resp) => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	static getArtistsNames() {
		let url = APIUrlConstants.getApiUrl("getArtistsNames") + "?IsAdmin=True";
		return APIService.doGet(url).then(resp => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	static updateProductStatus(updateData) {
		let url = APIUrlConstants.getApiUrl("updateProductStatus") + "?stockId=" + updateData.stockId + "&artworkId=" + updateData._id + "&productStatus=" + updateData.status;
		return APIService.doPut(url).then((resp) => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	static deleteProduct(stockId) {
		let url = APIUrlConstants.getApiUrl("deleteProduct");
		url = Util.beautifyUrl(url, [stockId]);
		return APIService.doDelete(url).then((resp) => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	static unHoldProduct(stockId, artworkId) {
		let url = APIUrlConstants.getApiUrl("unHoldProduct") + "?stockId=" + stockId + "&artworkId=" + artworkId;
		return APIService.doPut(url).then((resp) => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	static adminDownloadProduct(body) {
		let url = APIUrlConstants.getApiUrl("adminDownloadProduct");
		return APIService.doPostDownload(url, body).then((resp) => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	static setArtworkFeatured(artworkId) {
		let url = APIUrlConstants.getApiUrl("featureArtworks") + "?artworkId=" + artworkId;
		return APIService.doPut(url).then((resp) => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}
	static uploadArtwork(body) {
		let url = APIUrlConstants.getApiUrl("bulkUpload");
		console.log("🚀 ~ file: ManageArtworks.service.js ~ line 58 ~ ManageArtworkService ~ uploadArtwork ~ url", url);
		return APIService.doUploadMultipart(url, body).then((resp) => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	static submitArtworktoBlockchain(artworkId, stockId) {
		let url;
		if (artworkId) {
			url = APIUrlConstants.getApiUrl("submitArtworktoBlockchain") + "?artworkId=" + artworkId;
		} else if (stockId) {
			url = APIUrlConstants.getApiUrl("submitArtworktoBlockchain") + "?StockId=" + stockId;
		}

		return APIService.doPut(url).then((resp) => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	static onMarkNFTRedeemable(stockId) {
		let url = APIUrlConstants.getApiUrl("onMarkNFTRedeemable") + "?stockId=" + stockId + "&isRedeemable=True";

		return APIService.doGet(url).then((resp) => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}


	static onApproveNFTRedeemable(stockId) {
		let url = APIUrlConstants.getApiUrl("onApproveNFTRedeemable") + "?stockId=" + stockId;

		return APIService.doPut(url).then((resp) => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}


	static downloadBulkuploadForm() {
		let url = APIUrlConstants.getApiUrl("downloadBulkuploadFormUrl");
		return APIService.doDownload(url).then((resp) => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	static modifyArtworkState(updateData) {
		let url = APIUrlConstants.getApiUrl("modifyArtworkState") + "?ArtworkId=" + updateData._id + "&Status=" + updateData.status;
		return APIService.doPut(url).then((resp) => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	static getArtworkStockListByArtworkId(artworkId) {
		let url = APIUrlConstants.getApiUrl("getArtworkStockListByArtworkId") + "?ArtworkId=" + artworkId;
		return APIService.doPost(url).then((resp) => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	static blockArtworkOriginal(artworkId, stockId) {
		let url = APIUrlConstants.getApiUrl("blockArtworkOriginal") + "?ArtworkId=" + artworkId + "&StockId=" + stockId;
		return APIService.doPut(url).then((resp) => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}
}
