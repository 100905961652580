import {
    GET_GALLERYLIST_REQUEST,
    GET_GALLERYLIST_SUCCESS,
    GET_GALLERYLIST_FAILURE,
    GET_GALLERY_PROFILE_REQUEST,
    GET_GALLERY_PROFILE_SUCCESS,
    GET_GALLERY_PROFILE_FAILURE,
    SET_GALLERY_FEATURED_REQUEST,
    SET_GALLERY_FEATURED_SUCCESS,
    SET_GALLERY_FEATURED_FAILURE
} from "../actions/ActionsTypes";

const initialState = {
    galleryListLoader: false,
    savedGalleryListCount: 0,
    savedGalleryList: [],
    getGalleryProfileLoader: false,
    getGalleryProfile: [],
    featureLoader: false,
    featureData: null
};

const manageGalleryReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case GET_GALLERYLIST_REQUEST:
            return {
                ...state,
                galleryListLoader: true
            };

        case GET_GALLERYLIST_SUCCESS:
            state = {
                ...state,
                galleryListLoader: false,
                savedGalleryListCount: action.data.count,
                savedGalleryList: action.data.galleryList
            };
            return state;

        case GET_GALLERYLIST_FAILURE:
            return {
                ...state,
                savedGalleryListCount: 0,
                savedGalleryList: [],
                galleryListLoader: false
            };

        case GET_GALLERY_PROFILE_REQUEST:
            return {
                ...state,
                getGalleryProfileLoader: true,
                getGalleryProfile: []
            };

        case GET_GALLERY_PROFILE_SUCCESS:
            state = {
                ...state,
                getGalleryProfileLoader: false,
                getGalleryProfile: action.data
            };
            return state;

        case GET_GALLERY_PROFILE_FAILURE:
            return {
                ...state,
                getGalleryProfileLoader: false,
                getGalleryProfile: []
            };

        case SET_GALLERY_FEATURED_REQUEST:
            return {
                ...state,
                featureLoader: true
            };

        case SET_GALLERY_FEATURED_SUCCESS:
            state = {
                ...state,
                featureLoader: false,
                featureData: action.data
            };
            return state;

        case SET_GALLERY_FEATURED_FAILURE:
            return {
                ...state,
                featureLoader: false,
                featureData: null
            };

        default:
            return state;
    }
};

export default manageGalleryReducer;
