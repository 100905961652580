import React, { useState } from "react";
import { Tooltip } from "antd";
import {
	YearEventTableWrapper,
	YearEventTableHead,
	YearEventTableBodyItem,
	YearEventTableBody,
	YearEventTableHeadItem,
	YearEventTableActionPill,
} from "./YearEventTable.styled";
import assets from "../../../../../../assets";

import ConfirmPopup from '../../../../../../components/ConfirmPopup/ConfirmPopup'
import { isEmpty } from "lodash";
import { Empty, Popover } from "antd";
import { useLocation, useHistory } from "react-router-dom";
import { ActionImg, ArtistListGalleryViewTable, ColSubText } from "../../../ArtistProfile.styled";
import ViewMoreLess from "../../../../../../components/ViewMoreLess/ViewMoreLess";

const YearEventTable = ({ title, list = [], listValue, onEditItem, onDeleteItem, isMyProfile, onTabChange, fromSource }) => {
	const history = useHistory();

	const [showButtons, setButtons] = useState(false);
	const [showIndex, setIndex] = useState(null);
	const [showView, setShowView] = useState(false);

	const onClickAction = (btn) => {
		if (btn === "next") {
			onTabChange("art_collections");
		} else {
			// history.push(RouteConstants.ArtistDashboard);
		}
	};
	const columns = [
		{
			title: "Year",
			dataIndex: "year",
			key: "year",
		},
		{
			title: "Award",
			dataIndex: "award",
			key: "award",
		},
		{
			title: "Country",
			dataIndex: "country",
			key: "country",
		},
		{
			title: "Action",
			key: "action",
			render: (record) => (
				<React.Fragment>
					<Popover placement="bottom" content={setContent(record)}>
						<ActionImg src={assets.images.artworksActionIcon} />
					</Popover>
				</React.Fragment>
			),
		},
	];

	const setContent = (record) => {
		return (
			<div>
				<ColSubText
					onClick={() => {
						onEditItem(record);
					}}
					textcolor={"#000000"}
					style={{ cursor: "pointer" }}
				>
					Edit
				</ColSubText>
				<ConfirmPopup
					text={"Confirm Deletion"}
					image={assets.images.deleteIcon}
					buttonText={"Delete"}
					confirmAction={() => onDeleteItem(record)}
				>
					<ColSubText textcolor={"#000000"} style={{ cursor: "pointer" }}>
						Delete
					</ColSubText>
				</ConfirmPopup>
			</div>
		);
	};
	const arr = showView ? list : list && list.slice(0, 5);
	return (
		<>
			{fromSource === "profile-edit" ? (
				<>
					<ArtistListGalleryViewTable rowKey={(record) => record._id} columns={columns} dataSource={list} pagination={false} />
				</>
			) : (
				!isEmpty(list) && (
					<YearEventTableWrapper>
						<YearEventTableHead>
							<YearEventTableHeadItem marginleft="10%">Year</YearEventTableHeadItem>
							<YearEventTableHeadItem marginleft="8%">{title}</YearEventTableHeadItem>
						</YearEventTableHead>
						{arr.map((item, index) => (
							<YearEventTableBody
								key={index}
								onMouseEnter={() => {
									setButtons(true);
									setIndex(index);
								}}
								onMouseLeave={() => {
									setButtons(false);
								}}
							>
								<YearEventTableBodyItem marginleft="10%">{item.year}</YearEventTableBodyItem>
								<YearEventTableBodyItem marginleft="8%" marginright="15%">
									{item[listValue]}
								</YearEventTableBodyItem>
								{isMyProfile && showButtons && showIndex === index && (
									<YearEventTableActionPill>
										<Tooltip placement="bottom" title={"Edit"}>
											<img
												src={assets.images.editIcon}
												onClick={() => {
													onEditItem(item);
												}}
												alt="edit"
											/>
										</Tooltip>
										<span>|</span>
										<ConfirmPopup
											text="Delete award from list?"
											image={assets.images.deleteIcon}
											buttonText={"Delete"}
											data={item}
											confirmAction={onDeleteItem}
										>
											<Tooltip placement="bottom" title={"Delete"}>
												<img src={assets.images.deleteIcon} alt="delete" />
											</Tooltip>
										</ConfirmPopup>
									</YearEventTableActionPill>
								)}
							</YearEventTableBody>
						))}

						<ViewMoreLess arr={list} showView={showView} setShowView={setShowView} />
					</YearEventTableWrapper>
				)
			)}
		</>
	);
};
export default YearEventTable;
