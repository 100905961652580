import React, { useState, useEffect } from "react";
import { TitleHeading, MainSubmitButton, SelectInput, YesNoButton, SpanFloater, AdditionalInforWrapper } from "../../ArtistProfile.styled";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import assets from "../../../../../assets";
import { useHistory } from "react-router-dom";
import { FormInput, FormRow } from "../../Artist.styled";
import FormikInput from "../../../../../components/FormikInput/FormikInput";
import { useSelector } from "react-redux";

export const AdditionalInfo = ({ token, data }) => {
	const history = useHistory();



	const [selectedPayment, setSelectedPayment] = useState(data ? data.paymentMethod : 0);
	const [sellArtOtherPlatforms, setSellArtOtherPlatforms] = useState(data ? data.availableOnOtherPlatforms : "");
	const [blockchainFamiliar, setBlockchainFamiliar] = useState(data ? data.blockchainFamiliar : "");
	const [nFCFamiliar, setNFCFamiliar] = useState(data ? data.nfcFamiliar : "");

	const [country1, setCountry1] = useState(data && data.country ? data.country[0] : "");
	const [country2, setCountry2] = useState(data && data.country ? data.country[1] : "");
	const [country3, setCountry3] = useState(data && data.country ? data.country[2] : "");
	const { countriesList } = useSelector(state => state.countriesTaxReducer)
	const [inputs, setInputs] = useState({
		deviceType: data ? data.deviceType : "",
		somename: data ? data.otherPlatforms : "",
	});
	const [isLoading, setIsLoading] = useState(false);


	return (
		<div className="about-form-section">
			<Formik
				enableReinitialize={true}
				initialValues={{
					priceMin: data ? data.priceRangeMin : "",
					priceMax: data ? data.priceRangeMax : "",
				}}
				validationSchema={Yup.object().shape({})}

			>
				{({ errors, touched, handleSubmit, isSubmitting }) => {
					return (
						<AdditionalInforWrapper>
							<Form name="deliveryAddress" method="post">
								<TitleHeading>{"Few more questions to get to know you better."}</TitleHeading>
								<TitleHeading>
									{"Add a price range for your artworks. We are collecting this information in order to make recommendations to our collectors, according to their budget. To make sure your artworks are recommended to the right collector, please add accurate values."
									}
								</TitleHeading>
								<FormRow>
									<Field name="priceMin" component={FormikInput} type="number" label={"Price Minimum"} />
									<span style={{ width: 30 }}></span>
									<Field name="priceMax" component={FormikInput} type="number" label={"Price Maximum"} />
								</FormRow>

								<br />
								<TitleHeading>
									{"If you have exclusive collaboration with Galleries for specific locations, we offer you option to restrict users from upto 3 countries. Please select the countries to exclude collectors from."
									}
								</TitleHeading>
								<FormRow>
									<div style={{ position: "relative", width: "100%", marginRight: 5 }}>
										<SpanFloater>{"Select Country"}</SpanFloater>
										<SelectInput
											style={{
												height: 45,
												marginTop: 15,
												width: "100%",
											}}
											optionFilterProp="children"
											defaultValue={data && data.country ? data.country[0] : ""}
											value={country1}
											showSearch
											onChange={(e) => {
												setCountry1(e);
											}}
											disabled
										>
											{countriesList.map((data, index) => {
												return (
													<option key={`year${index}`} value={data.value}>
														{data.name}
													</option>
												);
											})}
										</SelectInput>
									</div>
									<div style={{ position: "relative", width: "100%", marginRight: 5 }}>
										<SpanFloater>{"Select Country"}</SpanFloater>
										<SelectInput
											style={{
												height: 45,
												marginTop: 15,
												width: "100%",
											}}
											optionFilterProp="children"
											defaultValue={data && data.country ? data.country[1] : ""}
											value={country2}
											showSearch
											onChange={(e) => {
												setCountry2(e);
											}}
											disabled
										>
											{countriesList.map((data, index) => {
												return (
													<option key={`year${index}`} value={data.value}>
														{data.name}
													</option>
												);
											})}
										</SelectInput>
									</div>
									<div style={{ position: "relative", width: "100%" }}>
										<SpanFloater>{"Select Country"}</SpanFloater>
										<SelectInput
											style={{
												height: 45,
												marginTop: 15,
												width: "100%",
											}}
											optionFilterProp="children"
											defaultValue={data && data.country ? data.country[2] : ""}
											value={country3}
											showSearch
											onChange={(e) => {
												setCountry3(e);
											}}
											disabled
										>
											{countriesList.map((data, index) => {
												return (
													<option key={`year${index}`} value={data.value}>
														{data.name}
													</option>
												);
											})}
										</SelectInput>
									</div>
								</FormRow>
								<br />
								<TitleHeading>
									{"Preferred medium of receiving Payment (We will collect the payment details separately from you)."}
								</TitleHeading>
								<FormRow>
									<div
										className={`payment-div-wrapper ${selectedPayment === 0 ? "selected-card" : ""} `}

									>
										<img src={assets.images.creditcard} alt="card" />
										<p>Bank Credit</p>
									</div>
									<div
										className={`payment-div-wrapper ${selectedPayment === 1 ? "selected-card" : ""} `}

									>
										<img src={assets.images.paypal} alt="card" />
										<p>PayPal</p>
									</div>
									<div
										className={`payment-div-wrapper ${selectedPayment === 2 ? "selected-card" : ""} `}

									>
										<img src={selectedPayment === 2 ? assets.images.bitcoin_white : assets.images.bitcoin} alt="card" />
										<p>Crypto</p>
									</div>
								</FormRow>
								<br />

								<p className="sub-text-additional-info">
									Are you selling your art on other online platforms? (We are a non-exclusive platform. Regardless of your answer, we
									are happy to welcome you on RtistiQ).
								</p>

								<FormRow>
									<YesNoButton
										type="button"

										className={sellArtOtherPlatforms ? "selected-btn" : ""}
									>
										Yes
									</YesNoButton>
									<YesNoButton
										type="button"

										className={sellArtOtherPlatforms ? "" : "selected-btn"}
									>
										No
									</YesNoButton>
								</FormRow>

								<p className="sub-text-additional-info">Feel free to drop some names</p>
								<FormRow>
									<FormInput name="somename" value={inputs.somename} component="input" type="text" />
								</FormRow>
								<br />
								<p className="sub-text-additional-info">
									Are you familiar with how RtistiQ uses Blockchain Technology as a security feature?{" "}
									<a href="https://help.rtistiq.com/en/category/your-blockchain-account-1e0untz/" target="_blank">
										{" "}
										Find out more about Blockchain
									</a>
								</p>
								<FormRow>
									<YesNoButton
										type="button"

										className={blockchainFamiliar ? "selected-btn" : ""}
									>
										Yes
									</YesNoButton>
									<YesNoButton
										type="button"

										className={blockchainFamiliar ? "" : "selected-btn"}
									>
										No
									</YesNoButton>
								</FormRow>
								<p className="sub-text-additional-info">
									Are you familiar with the authenticity certificate provided by RtistiQ in the form of NFC tags?{" "}
									<a
										href="https://help.rtistiq.com/en/article/nfc-tagging-your-artwork-use-rtistiq-mobile-app-1esg2xx/"
										target="_blank"
									>
										Find out how NFC works.
									</a>
								</p>
								<FormRow>
									<YesNoButton type="button" className={nFCFamiliar ? "selected-btn" : ""}>
										Yes
									</YesNoButton>
									<YesNoButton type="button" className={nFCFamiliar ? "" : "selected-btn"}>
										No
									</YesNoButton>
								</FormRow>
								<p className="sub-text-additional-info">
									Not all mobile phones are compatible with the above mentioned NFC technology. What mobile phone are you currently
									using? Please specify the brand and model of your mobile phone.{" "}
								</p>
								<FormRow>
									<FormInput
										name="deviceType"

										value={inputs.deviceType}
										placeholder="eg:Android/Samsung Galaxy 10"
										component="input"
										type="text"
									/>

								</FormRow>
							</Form>
						</AdditionalInforWrapper>
					);
				}}
			</Formik>
		</div>
	);
};

export default AdditionalInfo;
