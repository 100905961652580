import React from "react";
import { Dialog, DialogTitle, DialogContent, Slide, CircularProgress } from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const AddBlogTopics = ({ topicState, openAddTopicModal, onClickCancelAddTopic, onHandleChangeInput, SaveTopic, addTopicLoader }) => {

    return (
        <Dialog
            fullScreen
            open={openAddTopicModal}
            onClose={onClickCancelAddTopic}
            TransitionComponent={Transition}
            BackdropProps={{ classes: { root: "dialog-backdrop" } }}
            className="editor-modal-dialog"
        >
            <DialogTitle className="dialog-title">Add Blog Topic</DialogTitle>
            <DialogContent className="editor-modal-content">
                {topicState && <div className="editor-main-wrapper">

                    <div className="form-control">
                        <label className="form-label">Topic*</label>
                        <input type="text" name="topic" autoFocus className="form-input" value={topicState.topic} onChange={onHandleChangeInput} />
                    </div>

                    <div className="form-control">
                        <label className="form-label">SlugId*</label>
                        <input disabled={topicState._id !== ""} type="text" name="slugId" className="form-input" value={topicState.slugId} onChange={onHandleChangeInput} />
                    </div>

                    <div className="form-control">
                        <label className="form-label">SEO Title*</label>
                        <input type="text" name="seoTitle" className="form-input" value={topicState.seoTitle} onChange={onHandleChangeInput} />
                    </div>

                    <div className="form-control">
                        <label className="form-label">SEO Description</label>
                        <input type="text" name="seoDescription" className="form-input" value={topicState.seoDescription} onChange={onHandleChangeInput} />
                    </div>



                    <div className="ButtonsWrapper">
                        <button className="CancelButton" onClick={onClickCancelAddTopic}>
                            CANCEL
                        </button>
                        <button className="PublishButton" onClick={() => { SaveTopic(topicState) }}>
                            SAVE
                            {addTopicLoader === true && <CircularProgress size={24} className="circular-loader modal-button-loader" />}
                        </button>
                    </div>
                </div>}
            </DialogContent>
        </Dialog>
    )
}

export default AddBlogTopics;