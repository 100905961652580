import { APIUrlConstants } from "../../util/apiUrl.constant";
import { APIService } from "../../util/api.service";
import { Util } from "../../util/util";

export class ManageUsersService {
    static getUsersList(userdata) {
        let url = APIUrlConstants.getApiUrl("getUsersList");
        return APIService.doPost(url, userdata).then(resp => {
            if (resp) {
                return resp;
            }
            throw resp;
        });
    }
    static getOrderHistory(orderdata) {
        if (orderdata.status) {
            let url =
                APIUrlConstants.getApiUrl("getOrderHistory") +
                "?pageNum=" +
                orderdata.PageNum +
                "&pageSize=" +
                orderdata.PageSize +
                "&UserId=" +
                orderdata.UserId +
                "&status=" +
                orderdata.status;
            return APIService.doGet(url).then(resp => {
                if (resp) {
                    return resp;
                }
                throw resp;
            });
        } else {
            let url =
                APIUrlConstants.getApiUrl("getOrderHistory") +
                "?pageNum=" +
                orderdata.PageNum +
                "&pageSize=" +
                orderdata.PageSize +
                "&UserId=" +
                orderdata.UserId;
            return APIService.doGet(url).then(resp => {
                if (resp) {
                    return resp;
                }
                throw resp;
            });
        }
    }
    static blockUser(userid) {
        let url = APIUrlConstants.getApiUrl("blockUser");
        url = Util.beautifyUrl(url, [userid]);
        return APIService.doPut(url).then(resp => {
            if (resp) {
                return resp;
            }
            throw resp;
        });
    }
    static unblockUser(userid) {
        let url = APIUrlConstants.getApiUrl("unblockUser");
        url = Util.beautifyUrl(url, [userid]);
        return APIService.doPut(url).then(resp => {
            if (resp) {
                return resp;
            }
            throw resp;
        });
    }
    static getUserDetails(userid) {
        let url = APIUrlConstants.getApiUrl("getUserDetails");
        url = Util.beautifyUrl(url, [userid]);
        return APIService.doGet(url).then(resp => {
            if (resp) {
                return resp;
            }
            throw resp;
        });
    }

    static getShipAddress(userid) {
        let url = APIUrlConstants.getApiUrl("getShipAddress");
        url = Util.beautifyUrl(url, [userid]);
        return APIService.doGet(url).then(resp => {
            if (resp) {
                return resp;
            }
            throw resp;
        });
    }

    static getRoleChangeRequests(data) {
        let url = APIUrlConstants.getApiUrl("getRoleChangeRequests");
        return APIService.doPost(url, data).then(resp => {
            if (resp) {
                return resp;
            }
            throw resp;
        });
    }

    static updateRoleChangeStatus(userId, status) {
        let url = APIUrlConstants.getApiUrl("updateRoleChangeStatus");
        url = url + "?RequestId=" + userId + "&Status=" + status;
        return APIService.doPut(url).then(resp => {
            if (resp) {
                return resp;
            }
            throw resp;
        });
    }

    static updateRoleChangeStatusFromMangeArtist(userId, status, userRole) {
        let url = APIUrlConstants.getApiUrl("updateRoleChangeStatus");
        url = url + "?Status=" + status + "&UserId=" + userId + "&UserRole=" + userRole;
        return APIService.doPut(url).then(resp => {
            if (resp) {
                return resp;
            }
            throw resp;
        });
    }
    static getUserKYC(userId) {
        let url = APIUrlConstants.getApiUrl("getKYC");
        url = url + "?UserId=" + userId
        return APIService.doGet(url).then(resp => {
            if (resp) {
                return resp;
            }
            throw resp;
        });
    }
    static approveKYC(userId, isType) {
        let url = APIUrlConstants.getApiUrl("approveKyc");
        url = `${url}?UserId=${userId}&IsApproved=${isType}`;
        return APIService.doPut(url).then(resp => {
            if (resp) {
                return resp;
            }
            throw resp;
        });
    }

    static adminDownloadKYC(userID, documentType) {
        let url = APIUrlConstants.getApiUrl("adminDownloadKYC") + "?UserID=" + userID + "&documentType=" + documentType;
        return APIService.doDownload(url).then(resp => {
            if (resp) {
                return resp;
            }
            throw resp;
        });
    }

    static exportToExcelUserDetails(body) {
        let url = APIUrlConstants.getApiUrl("exportToExcelUserList");
        return APIService.doPostDownload(url, body).then(resp => {
            if (resp) {
                return resp;
            }
            throw resp;
        });
    }
}