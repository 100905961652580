import React, { PureComponent } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import assets from "../../assets";
import { CropImageWrapper, CropImageContainer, CropImageNewButton, ProductImgItem } from "./CropImage.styled";

class CropImage extends PureComponent {
	state = {
		srcImageFile: null,
		src: null,
		sx: 0,
		sy: 0,
		crop: {
			unit: this.props.imgType === "artistBd" ? "%" : "px",
			x: 0,
			y: 0,
			width:
				this.props.imgType === "galleryDp"
					? 170
					: this.props.imgType === "artistDp"
						? 200
						: this.props.imgType === "galleryBd"
							? 456
							: this.props.imgType === "artistBd" && 100,
			height:
				this.props.imgType === "galleryDp"
					? 200
					: this.props.imgType === "artistDp"
						? 200
						: this.props.imgType === "galleryBd"
							? 154.889
							: this.props.imgType === "artistBd" && 40,
			aspect:
				this.props.imgType === "galleryDp"
					? 17 / 20
					: this.props.imgType === "artistDp"
						? 1
						: this.props.imgType === "galleryBd"
							? 942.09 / 320
							: this.props.imgType === "artistBd" && 4 / 1,
		},
	};

	onSelectFile = (e) => {
		if (e.target.files && e.target.files.length > 0) {
			this.setState({ srcImageFile: e.target.files[0] });
			const reader = new FileReader();
			reader.addEventListener("load", () => this.setState({ src: reader.result }));
			reader.readAsDataURL(e.target.files[0]);
		}
	};

	// If you setState the crop in here you should return false.
	onImageLoaded = (image) => {
		this.imageRef = image;
	};

	onCropComplete = (crop) => {
		this.makeClientCrop(crop);
	};

	onCropChange = (crop, percentCrop) => {
		// You could also use percentCrop:
		// this.setState({ crop: percentCrop });
		this.setState({ crop });
	};

	async makeClientCrop(crop) {
		if (this.imageRef && crop.width && crop.height) {
			const croppedImageUrl = await this.getCroppedImg(this.imageRef, crop, "myImage.png");

			// BLOD URL : croppedImageUrl
			// -------------------------------------- //
			// CROP_IMAGE

			let blob = await fetch(croppedImageUrl).then((r) => r.blob());
			let file = new File([blob], "uploaded_file.png", { type: "image/png", lastModified: Date.now() });

			// Save before API call
			// this.props.onSelectCroppedImage(this.props.imgType === "artistBd" ? this.state.srcImageFile : file);

			this.props.onSelectCroppedImage(file);
		}
	}

	getCroppedImg(image, crop, fileName) {
		const canvas = document.createElement("canvas");
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		var originWidth = Math.ceil(crop.width * scaleX);
		var originHeight = Math.ceil(crop.height * scaleY);

		// maximum width/height
		var maxWidth = 1200,
			maxHeight = 1200 / (16 / 9);
		var targetWidth = originWidth,
			targetHeight = originHeight;
		if (originWidth > maxWidth || originHeight > maxHeight) {
			if (originWidth / originHeight > maxWidth / maxHeight) {
				targetWidth = maxWidth;
				targetHeight = Math.round(maxWidth * (originHeight / originWidth));
			} else {
				targetHeight = maxHeight;

				targetWidth = Math.round(maxHeight * (originWidth / originHeight));
			}
		}

		// set canvas size
		canvas.width = targetWidth;
		canvas.height = targetHeight;
		const ctx = canvas.getContext("2d");
		let sx = crop.x * scaleX;
		let sy = crop.y * scaleY;
		this.setState({
			sx,
			sy,
		});

		ctx.drawImage(image, sx, sy, crop.width * scaleX, crop.height * scaleY, 0, 0, targetWidth, targetHeight);

		return new Promise((resolve, reject) => {
			canvas.toBlob(
				(blob) => {
					if (!blob) {
						//reject(new Error('Canvas is empty'));
						console.error("Canvas is empty");
						return;
					}
					blob.name = fileName;
					window.URL.revokeObjectURL(this.fileUrl);
					this.fileUrl = window.URL.createObjectURL(blob);
					resolve(this.fileUrl);
				},
				"image/png",
				1
			);
		});
	}

	render() {
		const { crop, src } = this.state;
		const { mediaThumb } = this.props;
		return (
			<CropImageWrapper>
				{src ? (
					<CropImageContainer>
						<ReactCrop
							src={src}
							crop={crop}
							ruleOfThirds
							onImageLoaded={this.onImageLoaded}
							onComplete={this.onCropComplete}
							onChange={this.onCropChange}
							keepSelection={true}
						/>
						<CropImageNewButton onClick={(e) => this.attach_image.click()}>
							<img src={assets.images.cameraIcon} alt="New" />
						</CropImageNewButton>
					</CropImageContainer>
				) : (
					<ProductImgItem onClick={(e) => this.attach_image.click()}>
						<img src={mediaThumb ? mediaThumb : assets.images.addImageIcon} alt="add" />
					</ProductImgItem>
				)}
				<input
					type="file"
					style={{ display: "none" }}
					accept=".bmp, .gif, .ico, .jpeg, .jpg, .png, .svg, .tif, .tiff, .jiff, .jfif, .pjpeg, .pjp"
					onChange={this.onSelectFile}
					ref={(ref) => (this.attach_image = ref)}
				/>
				{/* {croppedImageUrl && <img alt="Crop" style={{ maxWidth: "100%" }} src={croppedImageUrl} />} */}
			</CropImageWrapper>
		);
	}
}

export default CropImage;
