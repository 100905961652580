import {
	GET_USERSLIST_REQUEST,
	GET_USERSLIST_SUCCESS,
	GET_USERSLIST_FAILURE,
	ORDER_HISTORY_REQUEST,
	ORDER_HISTORY_SUCCESS,
	ORDER_HISTORY_FAILURE,
	BLOCK_USER_REQUEST,
	BLOCK_USER_SUCCESS,
	BLOCK_USER_FAILURE,
	UNBLOCK_USER_REQUEST,
	UNBLOCK_USER_SUCCESS,
	UNBLOCK_USER_FAILURE,
	GET_SHIPPING_ADDRESS_REQUEST,
	GET_SHIPPING_ADDRESS_SUCCESS,
	GET_SHIPPING_ADDRESS_FAILURE,
	USER_PROFILE_DETAILS,
	GET_USERS_DETAILS_REQUEST,
	GET_USERS_DETAILS_SUCCESS,
	GET_USERS_DETAILS_FAILURE,
	GET_ROLE_CHANGE_LIST_REQUEST,
	GET_ROLE_CHANGE_LIST_SUCCESS,
	GET_ROLE_CHANGE_LIST_FAILURE,
	UPDATE_ROLE_CHANGE_REQUEST,
	UPDATE_ROLE_CHANGE_SUCCESS,
	UPDATE_ROLE_CHANGE_FAILURE,
	GET_USER_KYC_DOC_REQUEST,
	GET_USER_KYC_DOC_SUCCESS,
	GET_USER_KYC_DOC_FAILURE,
	KYC_APPROVE_REQUEST,
	KYC_APPROVE_SUCCESS,
	KYC_APPROVE_FAILURE,
	GET_KYC_REQUEST,
	GET_KYC_SUCCESS,
	GET_KYC_FAILURE,
	TOGGLE_KYC_DIALOG,
	GET_EXPORTUSERSLIST_REQUEST,
	GET_EXPORTUSERSLIST_SUCCESS,
	GET_EXPORTUSERSLIST_FAILURE
} from "../actions/ActionsTypes";

const initialState = {
	savedUserListCount: 0,
	getRoleChangeRequestCount: 0,
	savedusersList: [],

	usersListLoader: false,
	orderhistoryLoader: false,
	orderhistoryList: [],
	userdetailsLoader: false,
	userDetails: {},
	blockUserLoader: false,
	blockUserResult: {},
	unblockUserLoader: false,
	unblockUserResult: {},
	getAddressInfoLoader: false,
	getAddressInfoData: [],
	getRolechangeRequestLoader: false,
	getRolechangeRequestList: [],
	updateRoleChangeLoader: false,
	updateRoleChangeData: {},
	kycDocumentResult: {},
	kycDocumentLoader: false,
	kycApproveResult: null,
	kycApproveLoader: false,
	kycMessage: '',
	KYCLoader: false,
	KYCData: null,
	kycDialog: false,
	exportUserLoader: false,


};

const manageUsersReducer = (state = initialState, action = {}) => {
	switch (action.type) {
		case GET_USERSLIST_REQUEST:
			return {
				...state,
				usersListLoader: true
			};

		case GET_USERSLIST_SUCCESS:
			state = {
				...state,
				usersListLoader: false,
				savedUserListCount: action.data.count,
				savedusersList: action.data.buyerList
			};
			return state;

		case GET_USERSLIST_FAILURE:
			return {
				...state,
				savedUserListCount: 0,
				savedusersList: [],
				usersListLoader: false
			};

		case ORDER_HISTORY_REQUEST:
			return {
				...state,
				orderhistoryLoader: true
			};

		case ORDER_HISTORY_SUCCESS:
			state = {
				...state,
				orderhistoryLoader: false,
				orderhistoryList: action.data
			};
			return state;

		case ORDER_HISTORY_FAILURE:
			return {
				...state,
				orderhistoryLoader: false
			};

		case BLOCK_USER_REQUEST:
			return {
				...state,
				blockUserLoader: true
			};

		case BLOCK_USER_SUCCESS:
			state = {
				...state,
				blockUserLoader: false,
				blockUserResult: action.data
			};
			return state;

		case BLOCK_USER_FAILURE:
			return {
				...state,
				blockUserLoader: false
			};

		case GET_EXPORTUSERSLIST_REQUEST:
			return {
				...state,
				exportUserLoader: true
			};

		case GET_EXPORTUSERSLIST_SUCCESS:
			state = {
				...state,
				exportUserLoader: false
			};
			return state;

		case GET_EXPORTUSERSLIST_FAILURE:
			return {
				...state,
				exportUserLoader: false
			};

		case UNBLOCK_USER_REQUEST:
			return {
				...state,
				unblockUserLoader: true
			};

		case UNBLOCK_USER_SUCCESS:
			state = {
				...state,
				unblockUserLoader: false,
				unblockUserResult: action.data
			};
			return state;

		case UNBLOCK_USER_FAILURE:
			return {
				...state,
				unblockUserLoader: false
			};

		case GET_USERS_DETAILS_REQUEST:
			return {
				...state,
				userdetailsLoader: true
			};

		case GET_USERS_DETAILS_SUCCESS:
			state = {
				...state,
				userdetailsLoader: false,
				userDetails: action.data.data
			};
			return state;

		case GET_USERS_DETAILS_FAILURE:
			return {
				...state,
				userdetailsLoader: false
			};

		case GET_SHIPPING_ADDRESS_REQUEST:
			return {
				...state,
				getAddressInfoLoader: true
			};

		case GET_SHIPPING_ADDRESS_SUCCESS:
			state = {
				...state,
				getAddressInfoLoader: false,
				getAddressInfoData: action.data.data
			};
			return state;

		case GET_SHIPPING_ADDRESS_FAILURE:
			return {
				...state,
				getAddressInfoLoader: false
			};

		case GET_ROLE_CHANGE_LIST_REQUEST:
			return {
				...state,
				getRolechangeRequestLoader: true
			};

		case GET_ROLE_CHANGE_LIST_SUCCESS:
			state = {
				...state,
				getRolechangeRequestLoader: false,
				getRolechangeRequestList: action.data.data.userRequestList,
				getRoleChangeRequestCount: action.data.data.count
			};
			return state;

		case GET_ROLE_CHANGE_LIST_FAILURE:
			return {
				...state,
				getRolechangeRequestLoader: false
			};

		case UPDATE_ROLE_CHANGE_REQUEST:
			return {
				...state,
				updateRoleChangeLoader: true
			};

		case UPDATE_ROLE_CHANGE_SUCCESS:
			state = {
				...state,
				updateRoleChangeLoader: false,
				updateRoleChangeData: action.data
			};
			return state;

		case UPDATE_ROLE_CHANGE_FAILURE:
			return {
				...state,
				updateRoleChangeLoader: false
			};

		case GET_USER_KYC_DOC_REQUEST:
			return {
				...state,
				kycDocumentLoader: true,

			};

		case GET_USER_KYC_DOC_SUCCESS:
			state = {
				...state,
				kycDocumentLoader: false,
				kycDocumentResult: action.data.data
			};
			return state;

		case GET_USER_KYC_DOC_FAILURE:
			return {
				...state,
				kycDocumentLoader: false,
				kycDocumentResult: null
			};


		case KYC_APPROVE_REQUEST:
			return {
				...state,
				kycApproveLoader: true,

			};

		case KYC_APPROVE_SUCCESS:
			state = {
				...state,
				kycApproveLoader: false,
				kycApproveResult: action.data,
				kycDialog: false

			};
			return state;

		case KYC_APPROVE_FAILURE:
			return {
				...state,
				kycApproveLoader: false,
				kycApproveResult: null,
				kycDialog: true
			};

		case GET_KYC_REQUEST:
			return {
				...state,
				KYCLoader: true
			};

		case GET_KYC_SUCCESS:
			state = {
				...state,
				KYCLoader: false,
				KYCData: action.data
			};
			return state;

		case GET_KYC_FAILURE:
			return {
				...state,
				KYCLoader: false,
				KYCData: null
			};
		case USER_PROFILE_DETAILS:
			state = {
				...state,
				userDetails: action.data
			};
			return state;
		/* falls through */
		// --------- Toggle Dialog's --------- //
		case TOGGLE_KYC_DIALOG:
			if (action.data === false) {
				state = {
					...state,
					kycDialog: action.data,
					kycDocumentResult: null
				};
				return state;
			}
			else {
				state = {
					...state,
					kycDialog: action.data
				};
				return state;
			}

		default:
			return state;
	}
};

export default manageUsersReducer;
