import styled from "styled-components";
import { Select } from "antd";

const FilterSelect = styled(Select)`
	&.ant-select {
		margin-bottom: 10px;
	}
	.ant-select-selection {
		border-radius: 0;
	}
	.ant-select-selection:focus,
	.ant-select-selection:active,
	.ant-select-selection:hover {
		border-color: #000000;
		-webkit-box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2);
		box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2);
	}
`;
export { FilterSelect };
