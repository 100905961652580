import styled from "styled-components";
import { Field, Form } from "formik";

export const YearEventAddModalTitle = styled.p`
	margin-top: -0.75rem;
	margin-bottom: 1.25rem;
	text-align: center;
	font-size: 11.5px;
	color: #787878;
`;

export const YearEventAddForm = styled(Form)`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const YearEventAddFormWrap = styled.div`
	display: flex;
	justify-content: flex-start;
	padding-bottom: 1rem;
	margin-bottom: 1rem;
	border-bottom: 1px solid #eee;
`;
export const YearEventAddFormNew = styled.div`
	display: flex;
	justify-content: flex-start;
	padding-bottom: 1rem;
	margin-bottom: 1rem;
	border-bottom: 1px solid #eee;
	flex-direction:column;
`;
export const YearEventAddInputWrap = styled.div`
	display: flex;
	flex-direction: column;
`;

export const YearEventAddInputLabel = styled.span`
	color: #878787;
	font-size: 12px;
	margin-bottom: 5px;
	margin-left: ${props => {
		return props.marginleft;
	}};
`;
export const YearEventAddInput = styled(Field)`
	border: 1px solid #e5e5e5;
	border-radius: 0px;
	padding: 0px 10px;
	font-size: 14px;
	font-weight: 500;
	height: 33px;
	color: #323643;
	outline: none;
	width: 16rem;
	margin-left: 10px;
	&:focus,
	&:active {
		border: 2px solid #58514b;
	}

	@media (max-width: 425px) {
		width: 100%;
	}
`;

export const YearEventAddInputError = styled.span`
	position: absolute;
	width: 13rem;
	top: 63%;
	margin-left: ${props => {
		return props.marginleft;
	}};
`;
