import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { popToast } from "../../components/Toast/Toast";
import { APIService } from "../../util/api.service";
import { APIUrlConstants } from "../../util/apiUrl.constant";
import history from "../../util/history";
const useSurveyForm = () => {
	const [surveyFormObj, setSurveyFormObj] = useState({
		questionChoice: "",
		question: "",
		description: "",
		title: "",
		surveySlug: "",
		surveySuccessMessage: "Thank you for completing the survey",
	});

	const [selectedInputType, setSelectedInputType] = useState({
		label: "text",
		value: "text",
	});
	const [isRequired, setIsRequired] = useState({
		label: "No",
		value: "no",
	});
	const [allowOther, setAllowOther] = useState({
		label: "No",
		value: "no",
	});
	const [errorMsg, setErrorMsg] = useState({
		questionError: "",
		inputTypeError: "",
		optionsError: "",
		mainTitleError: "",
		subTitleError: "",
		surveySlugError: "",
	});
	const controlType = [
		{
			label: "Text",
			value: "text",
		},
		{
			label: "DropDown (Unique answers)",
			value: "dropdown",
		},
		{
			label: "RadioGroup (Unique Answer)",
			value: "radiogroup",
		},
		{
			label: "CheckBox (Multiple answers)",
			value: "checkbox",
		},
		{
			label: "Yes/No",
			value: "boolean",
		},
	];
	const yesOrNoOption = [
		{
			label: "Yes",
			value: "yes",
		},
		{
			label: "No",
			value: "no",
		},
	];
	const singleInputTypes = [
		{
			label: "text",
			value: "text",
		},
		{
			label: "email",
			value: "email",
		},
		{
			label: "tel",
			value: "tel",
		},
		{
			label: "date",
			value: "date",
		},
		{
			label: "number",
			value: "number",
		},
		{
			label: "url",
			value: "url",
		},
		{
			label: "password",
			value: "password",
		},
	];
	const [selectedQuestion, setSelectedQuestion] = useState([]);
	const [questionModal, setQuestionModal] = useState(false);
	const [isItsChoice, setIsItsChoice] = useState(false);
	const [selectedControl, setSelectedControl] = useState("");
	const [options, setOptions] = useState([]);
	const [questionIds, setQuestionIds] = useState([]);
	const [allSurvey, setAllSurvey] = useState([]);
	const [confirmDeleteSurvey, setConfirmDeleteSurvey] = useState(false);
	const [selectedSurveyId, setSelectedSurveyId] = useState("");
	const [singleSurvey, setSingleSurvey] = useState([]);
	const [surveyAnswers, setSurveyAnswers] = useState([]);
	const [pageNo, setPageNo] = useState(1);
	const onHandleInputChange = (e) => {
		const { name, value } = e.target;

		setSurveyFormObj((s) => ({ ...s, [name]: value }));
	};
	//Question Modal
	const questionModalClose = () => {
		setQuestionModal(false);
	};
	const showModal = () => {
		setQuestionModal(true);
	};
	const handlePageChange = (event) => {
		console.log("🚀 ~ file: useSurveyForm.js:124 ~ handlePageChange ~ event:", event);
	};

	const onChangeControlType = (selectedItem) => {
		setSelectedControl(selectedItem);
		setErrorMsg((s) => ({ ...s, inputTypeError: "" }));

		if (selectedItem.value === "radiogroup" || selectedItem.value === "dropdown" || selectedItem.value === "checkbox") {
			setIsItsChoice(true);
		} else {
			setIsItsChoice(false);
		}
	};
	const onChangeYesOrNoDropdown = (selectedItem, controlType) => {
		if (controlType === "isRequired") {
			setIsRequired(selectedItem);
		} else if (controlType === "allowOther") {
			setAllowOther(selectedItem);
		}
	};
	const validateForm = () => {
		if (surveyFormObj.question === "") {
			setErrorMsg({ questionError: "Question required", inputTypeError: "", optionsError: "", mainTitleError: "", subTitleError: "" });
			return false;
		} else if (selectedControl === "") {
			setErrorMsg({ questionError: "", inputTypeError: "Control type required", optionsError: "", mainTitleError: "", subTitleError: "" });

			return false;
		} else if (selectedControl.value === "radiogroup" || selectedControl.value === "dropdown" || selectedControl.value === "checkbox") {
			if (options && options.length === 0) {
				setErrorMsg({ questionError: "", inputTypeError: "", optionsError: "Option required", mainTitleError: "", subTitleError: "" });

				return false;
			}
		}
		return true;
	};
	const clearQuestionForm = () => {
		setSurveyFormObj((s) => ({ ...s, questionChoice: "", question: "" }));
		setOptions([]);
		setSelectedControl("");
	};
	const addOptions = (option) => {
		let optionList = options;
		optionList.push(surveyFormObj.questionChoice);
		setOptions(optionList);
		setSurveyFormObj((s) => ({ ...s, questionChoice: "" }));
		setErrorMsg((s) => ({ ...s, optionsError: "" }));
	};
	const insertQuestion = () => {
		if (validateForm()) {
			let questionObj = {
				type: selectedControl.value,
				name: surveyFormObj.question,
				title: surveyFormObj.question,
				choices: options ? options : null,
				isRequired: isRequired.value === "yes" ? true : false,
				readOnly: false,
				showCommentArea: false,
				allowMultiple: false,
				inputType:
					selectedControl.value === "radiogroup" || selectedControl.value === "dropdown" || selectedControl.value === "checkbox"
						? null
						: selectedInputType.value,
				showOtherItem: allowOther.value === "yes" ? true : false,
			};
			let url = APIUrlConstants.getApiUrl("addSurveyQuestion");
			APIService.doPost(url, questionObj).then((res) => {
				if (res && res.result === 200) {
					let addedQuestions = questionIds;
					addedQuestions.push(res.data);
					setQuestionIds(addedQuestions);
					clearQuestionForm();
					popToast.show("Question Added successfully");
				} else {
					if (res.message !== "") {
						popToast.show(res.message);
					} else {
						popToast.show("Something went wrong");
					}
				}
			});
		}
	};
	const saveSurvey = () => {
		if (surveyFormObj.title === "") {
			setErrorMsg({
				questionError: "",
				inputTypeError: "",
				optionsError: "",
				mainTitleError: "Survey Title required",
				subTitleError: "",
				surveySlugError: "",
			});
		} else if (isEmpty(questionIds)) {
			popToast.show("Please add your questions also");
		}
		if (surveyFormObj.surveySlug === "") {
			setErrorMsg({
				questionError: "",
				inputTypeError: "",
				optionsError: "",
				mainTitleError: "Survey Title required",
				subTitleError: "",
				surveySlugError: "Slug Id required",
			});
		} else {
			let addedQuestions = [];
			questionIds.map((qus, index) => {
				addedQuestions.push(qus._id);
			});
			let surveyObj = {
				SurveyTitle: surveyFormObj.title,
				SurveySubTitle: surveyFormObj.description,
				SurveySubText: "",
				SurveySuccessMessage: surveyFormObj.surveySuccessMessage,
				SlugId: surveyFormObj.surveySlug,
				logoPosition: "right",
				QuestionIds: addedQuestions,
			};
			let url = APIUrlConstants.getApiUrl("addSurvey");
			APIService.doPost(url, surveyObj).then((res) => {
				if (res && res.result === 200) {
					setSurveyFormObj({
						questionChoice: "",
						question: "",
						description: "",
						title: "",
						surveySlug: "",
						surveySuccessMessage: "Thank you for completing the survey",
					});
					popToast.show("Survey Added successfully");
					history.push("/survey-forms");
				} else {
					if (res.message !== "") {
						popToast.show(res.message);
					} else {
						popToast.show("Something went wrong");
					}
				}
			});
		}
	};

	const getAllSurvey = () => {
		let url = APIUrlConstants.getApiUrl("getAllSurveys") + "?pageNum=1&pageSize=10000";
		APIService.doGet(url).then((res) => {
			if (res && res.result === 200) {
				setAllSurvey(res.data);
			} else {
				if (res.message !== "") {
					popToast.show(res.message);
				} else {
					popToast.show("Something went wrong");
				}
			}
		});
	};
	const selectedSurveyForAction = (id, actionType) => {
		setSelectedSurveyId(id);
		if (actionType === "delete") {
			setConfirmDeleteSurvey(true);
		}
	};
	const deleteSurvey = () => {
		let url = APIUrlConstants.getApiUrl("deleteSurvey") + "?SurveyId=" + selectedSurveyId;
		APIService.doDelete(url).then((res) => {
			if (res && res.result === 200) {
				popToast.show("Survey deleted successfully");
				setSelectedSurveyId("");
				setConfirmDeleteSurvey(false);
				getAllSurvey();
			} else {
				if (res.message !== "") {
					popToast.show(res.message);
				} else {
					popToast.show("Something went wrong");
				}
			}
		});
	};

	const getSurveyById = (id) => {
		let url = APIUrlConstants.getApiUrl("getSurveyById") + "?surveyId=" + id;
		APIService.doGet(url).then((res) => {
			if (res && res.result === 200) {
				setSingleSurvey(res.data);
			} else {
				if (res.message !== "") {
					popToast.show(res.message);
				} else {
					popToast.show("Something went wrong");
				}
			}
		});
	};

	const getSurveyAnswers = (id, pageNum) => {
		let url = APIUrlConstants.getApiUrl("getSurveyAnswer") + `?pageNum=${pageNum}&pageSize=10&SurveyId=${id}`;
		APIService.doGet(url).then((res) => {
			if (res && res.result === 200) {
				setSurveyAnswers(res.data);
			} else {
				if (res.message !== "") {
					popToast.show(res.message);
				} else {
					popToast.show("Something went wrong");
				}
			}
		});
	};

	const deleteQuestion = (id) => {
		let url = APIUrlConstants.getApiUrl("deleteSurveyQuestion") + "?QuestionId=" + id;
		APIService.doDelete(url).then((res) => {
			if (res && res.result === 200) {
				popToast.show("Question deleted successfully");
				let updatedQuestions = questionIds.filter((ques) => {
					return ques._id !== id;
				});
				console.log("deleted ques", updatedQuestions);
				setQuestionIds(updatedQuestions);
			} else {
				if (res.message !== "") {
					popToast.show(res.message);
				} else {
					popToast.show("Something went wrong");
				}
			}
		});
	};
	const deleteOptions = (opt) => {
		let updatedOptions = options.filter((op) => {
			return op !== opt;
		});
		setOptions(updatedOptions);
	};

	return {
		//Functions
		onHandleInputChange,
		questionModalClose,
		showModal,
		onChangeControlType,
		onChangeYesOrNoDropdown,
		insertQuestion,
		addOptions,
		setErrorMsg,
		setSelectedInputType,
		saveSurvey,
		getAllSurvey,
		setConfirmDeleteSurvey,
		deleteSurvey,
		selectedSurveyForAction,
		getSurveyById,
		getSurveyAnswers,
		deleteQuestion,
		deleteOptions,
		handlePageChange,
		//Variables
		surveyFormObj,
		selectedQuestion,
		questionModal,
		isItsChoice,
		selectedControl,
		controlType,
		yesOrNoOption,
		isRequired,
		allowOther,
		singleInputTypes,
		options,
		errorMsg,
		selectedInputType,
		questionIds,
		allSurvey,
		confirmDeleteSurvey,
		singleSurvey,
		surveyAnswers,
		pageNo,
	};
};

export default useSurveyForm;
