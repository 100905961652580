import React, { Component } from "react";
import Root from "../../Root/Root";
import Topbar from "../../../components/Topbar/Topbar";
import OrderHistory from "../../../components/OrderHistory/OrderHistory";
import { connect } from "react-redux";
import { getOrderHistory } from "../../../redux/actions/ManageusersAction";
import "./UserOrderHistory.scss";
import { CircularProgress } from "@material-ui/core";

class UserOrderHistory extends Component {
	constructor(props) {
		super(props);

		this.state = {
			dataSource: []
		};
	}

	componentDidMount() {
		if (this.props.match && this.props.match.params.id) {
			let orderRequest = {
				PageNum: 1,
				PageSize: 10,
				UserId: this.props.match.params.id
			};
			this.props.getOrderHistory(orderRequest);
		}
	}

	render() {
		if (this.props.orderhistoryList && this.props.orderhistoryList.length !== 0) {
			let data = this.props.orderhistoryList.data;
			let Obj =
				data.length !== 0 &&
				data.map((order, i) => {
					return {
						key: i,
						_id: order._id,
						image: order.productImage,
						description: order.oneLineDescription,
						orderId: order.orderId,
						orderDate: new Date(order.orderDate).toDateString(),
						price: order.amount,
						status: order.orderCurrentStatus,
						productName: order.productName
					};
				});

			if (this.state.dataSource.length === 0) {
				this.setState({
					dataSource: Obj
				});
			}
		}
		return (
			<Root>
				<Topbar title={"Order History"} showBreadCrumbs showHead />
				{this.props.isLoading ? (
					<div className="manageusers-wrapper">
						<CircularProgress className="circular-loader" size={50} />
					</div>
				) : (
					<div className="table">
						<OrderHistory data={this.state.dataSource} />
					</div>
				)}
			</Root>
		);
	}
}

const mapStateToProps = state => {
	return {
		orderhistoryList: state.manageUsersReducer.orderhistoryList,
		isLoading: state.manageUsersReducer.orderhistoryLoader
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getOrderHistory: orderRequest => {
			dispatch(getOrderHistory(orderRequest));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UserOrderHistory);
