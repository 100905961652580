import styled, { css } from "styled-components";
import { Row } from "antd";
import { Field } from "formik";
import StyledButton from "../../../components/StyledButton/StyledButton"

const UpperWrapper = styled.div`
	position: relative;
	margin-top: -86px;
	display: none;
	@media screen and (min-width: 576px) {
		margin-top: -112px;
	}
`;
const LowerWrapper = styled.div``;
const CreationStyleAndFollowersRow = styled(Row)`
	margin-left: 25px;

	@media (max-width: 576px) {
		margin-left: 0;
	}
`;
const BriefIntroRow = styled(Row)`
	margin-left: 25px;
	position: relative;
	/* padding-top:30px; */

	@media (max-width: 576px) {
		margin-left: 0;
	}
`;
const ArtistIntroWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
`;

const Line = styled.hr`
	border-top: 1px solid #efefef;
	margin: 0 1em;
`;

const ArtistInfoContainer = styled.div`
	padding: 2% 2%;
	position: relative;
	width: 100%;
	height: 100%;
	overflow-y: scroll;
	
	.ant-tabs-nav .ant-tabs-tab {
		margin-right: 2px !important;
		padding: 10px;
		@media screen and (max-width: 769px) {
			margin-left: 5px !important;
			padding: 8px;
		}
	}
	.ant-tabs-nav-scroll {
		text-align: center;
	}
	.ant-tabs-nav {
		padding-left: 12px !important;
		padding-right: 12px !important;
	}
	.ant-tabs-nav-wrap {
		justify-content: center;
	}
	.ant-tabs-tab-active {
		.ant-tabs-tab-btn {
			color: #fff !important;
		}
	}
`;
const ActionBox = styled.div`
	width: 38px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 5px 10px;
	box-shadow: 0px 2px 4px 1px #d9d9d9;
	border-radius: 6rem;
	cursor: pointer;

	&:hover {
		box-shadow: 0px 2px 4px 1px #bfbfbf;
	}
	img {
		width: 17px;
	}
`;

// ---- FollowButton ----//
const FollowButton = styled(StyledButton)`
	border-radius: 25px !important;
	padding: 0px 20px !important;
	font-weight: 200 !important;
	height: 25px !important;
	width: 6rem !important;
	margin: 8px 0px 7.5px 19px !important;
`;
const CreationStyleButton = styled(StyledButton)`
	border-color: #d6d6d6 !important;
	height: auto !important;
	font-weight: 500 !important;
	color: #000000 !important;
	font-size: 12px !important;
	white-space: normal;
	word-wrap: break-word;
`;

const ArtistIntroInnerWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
`;

const ArtistInnerWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	flex-direction: row;
`;

const ArtistIntroInnerText = styled.div`
	display: flex;
	align-items: center;
	/* margin-left: 14.5rem; */
	@media screen and (max-width: 576px) {
		padding-top: 50px;
	}
`;

const FollowerBlock = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex-wrap: wrap;

	span {
		${(props) => (props.isMyProfile ? "cursor: pointer" : null)};
		font-weight: 500;
	}
`;

// ----- MainBoxContainer -----//
const MainBoxContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 86%;
	margin-left: 16.8%;
`;

// ----- LargeBoxContainer -----//
const BoxContainer = styled.div`
	padding: 16px 0;
	min-height: 5.5rem;

	/* width: 55%; */
	height: fit-content;
	width: -webkit-fill-available;
	border: ${(props) => {
		return props.border;
	}};
	display: flex;
	flex-direction: column;
	align-content: center;
	margin-bottom: ${(props) => {
		return props.marginbottom;
	}};
	h3 {
		text-align: center;
	}

	@media (max-width: 576px) {
		margin-bottom: 1rem;
	}
`;
// ----- SmallBoxContainer -----//
const SmallBoxContainer = styled.div`
	padding: 16px 0;
	display: flex;
	flex-direction: column;
	min-height: 5.5rem;
	height: fit-content;
	/* width: 26%; */
	border: ${(props) => {
		return props.border;
	}};
	padding-left: 1rem;
	align-content: center;
	margin: 0 11% 1em 0;

	p {
		text-align: center;
	}

	@media (max-width: 576px) {
		margin-right: 0;
	}
`;
const Head = styled.h3`
	color: ${(props) => {
		return props.color;
	}};
	text-align: ${(props) => {
		return props.textalign;
	}};
	/* margin-bottom: 1.5rem; */
`;

// ----- AddButton -----//
const AddButton = styled.div`
	font-weight: bold;
	color: #58514b;
	display: flex;
	align-items: center;
	justify-content: center;

	span {
		font-size: 20px;
		padding-right: 5px;
		font-weight: 200;
	}
	&:hover {
		cursor: pointer;
	}
`;
const EditPill = styled.div`
	display: flex;
	align-items: center;
	align-self: flex-end;
	padding: 5px 10px;
	box-shadow: 0px 2px 4px 1px #d9d9d9;
	border-radius: 6rem;
	margin-left: 10px;
	width: fit-content;
	cursor: pointer;
	&:hover {
		box-shadow: 0px 2px 4px 1px #bfbfbf;
	}
	img {
		width: 17px;
	}
`;

const FormInputStyles = css`
	border: 1px solid #e5e5e5;
	border-radius: 0px;
	height: 45px;
	padding: 0px 10px;
	font-size: 14px;
	font-weight: 500;
	color: #323643;
	outline: none;
	&:focus,
	&:active {
		border: 2px solid #58514b;
	}
`;

const FormTextareaStyles = css`
	border: 1px solid #e5e5e5;
	border-radius: 0px;
	padding: 0px 10px;
	font-size: 14px;
	font-weight: 500;
	color: #323643;
	outline: none;
	&:focus,
	&:active {
		border: 2px solid #58514b;
	}
`;

const FormInput = styled(Field)`
	${FormInputStyles}
	width: 100%;
`;

const FormTextarea = styled(Field)`
	${FormTextareaStyles}
`;

const FormRow = styled.div`
	display: flex;
	margin-bottom: 1rem;
	align-items: center;
	.form-control {
		display: flex;
		flex: 1;
		&:first-of-type {
			margin-right: 1rem;
		}

		@media screen and (max-width: 786px) {
			width: 100% !important;
		}
	}
	@media screen and (max-width: 770px) {
		flex-direction: column;
		.form-control:first-of-type {
			margin-right: 0rem;
			margin-bottom: 1rem;
		}
	}
`;


export {
	ArtistIntroWrapper,
	ActionBox,
	FollowButton,
	UpperWrapper,
	LowerWrapper,
	BriefIntroRow,
	CreationStyleButton,
	ArtistIntroInnerWrapper,
	ArtistInnerWrapper,
	BoxContainer,
	MainBoxContainer,
	AddButton,
	SmallBoxContainer,
	Head,
	FollowerBlock,
	ArtistIntroInnerText,
	EditPill,
	ArtistInfoContainer,
	Line,
	CreationStyleAndFollowersRow,

	FormInput,
	FormTextarea,
	FormRow
};

// Component: ArtistProfileSectionTabs
export const ArtistProfileSectionTabsWrapper = styled.div`
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 1rem;
	width: 100%;
`;
