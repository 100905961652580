import { Select } from 'antd';
import React, { useState } from 'react';
import Topbar from '../../../../components/Topbar/Topbar';
import Root from '../../../Root/Root';
import AddNftCollection from './AddNftCollection';
import { CancelButton, CreateCollectionButton, NextButton, NftAuctionCollection, NftButtonWrapper, NftCollectionWrapper, NftDropdwnWrapper, SaveButtonWrapper, SelectNftCollection } from '../AuctionDetails.styled';
import useAuctionNftCollection from './useAuctionNftCollection';

const { Option } = Select;
const AuctionNftCollection = ({ match }) => {
    const { contracts, contractObj, onClickSubmit, onSelectContractDropdown, onGoBack, onHandleInputChange
    } = useAuctionNftCollection(match);
    const [openNftCollectionDrawer, setOpenNftCollectionDrawer] = useState(false);

    const onCloseNftCollectionDrawer = () => {
        setOpenNftCollectionDrawer(false);
    }

    const onClickCreateNftCollection = () => {
        setOpenNftCollectionDrawer(true);
    }

    return (
        <Root>
            <Topbar title={"Auctions Nft Collection"} showBreadCrumbs showHead />
            <NftAuctionCollection>
                <NftCollectionWrapper>
                    <h1>Smart Contract</h1>
                    <NftDropdwnWrapper>
                        <SelectNftCollection
                            placeholder="Select Existing Smart Contract"
                            style={{ width: "45%" }}
                            onChange={onSelectContractDropdown}
                            value={contractObj && contractObj.ContractAddress}
                        >
                            {contracts && contracts.map((s, index) => {
                                return (
                                    <Option key={`key${index}`} value={s.contractAddress}>{s.contractName}</Option>
                                )
                            })}

                        </SelectNftCollection>
                        <CreateCollectionButton onClick={onClickCreateNftCollection}>
                            Create New Contract
                        </CreateCollectionButton>
                    </NftDropdwnWrapper>

                </NftCollectionWrapper>
                <NftButtonWrapper>
                    <CancelButton onClick={onGoBack}>CANCEL</CancelButton>
                    <NextButton onClick={() => onClickSubmit(false)}>NEXT</NextButton>
                </NftButtonWrapper>
            </NftAuctionCollection>
            <AddNftCollection onClickSubmit={onClickSubmit} onHandleInputChange={onHandleInputChange} contractObj={contractObj} openNftCollectionDrawer={openNftCollectionDrawer} onCloseNftCollectionDrawer={onCloseNftCollectionDrawer} />
        </Root>
    )
}

export default AuctionNftCollection