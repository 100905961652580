import styled, { css } from "styled-components";
import { DatePicker } from "antd";

export const AddNewEventSubtitle = styled.p`
	font-size: 14px;
	color: #989898;
	text-align: center;
`;

export const AddNewEventBody = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0 2rem;
	border-bottom: 1px solid #eee;
	margin-bottom: 1rem;
	padding-bottom: 1rem;
`;

export const AddNewEventDateWrapper = styled.div`
	width: 100%;
	display: flex;
	margin-top: 1rem;
	justify-content: space-between;
`;

export const AddNewEventImageWrap = styled.div`
	cursor: pointer;
	padding: 0px;
	background: #ffffff;
	border: 1px solid #cdcdcd;
	border-radius: 5px;
	height: 150px;
	width: 150px;
	margin-bottom: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const AddNewEventImage = styled.img`
	${props =>
		props.notPlaceholder
			? css`
					width: 100%;
					height: 100%;
			  `
			: css`
					width: 50px;
					height: 50px;
			  `};
`;

export const AddNewEventDateInput = styled.div`
	position: relative;
	width: 48%;
`;

export const AddNewEventFooter = styled.div`
	display: flex;
	justify-content: center;
`;

export const CalendarIcon = styled.img`
	cursor: pointer;
	margin-top: -14px;
	width: 28px;
	height: auto;
`;
export const DatePickerCustom = styled(DatePicker)`
	width: 100%;
	.ant-input {
		border-radius: 0;
		height: 45px;
		font-weight: 500;
	}
`;
