import {
    SET_LANGUAGE
} from '../actions/ActionsTypes';

const initialState = {
    language: 'en'
}

const localeReducer = (state = initialState, action = {}) => {
    switch (action.type) {

        // Set language from payload to state
        case SET_LANGUAGE:
            state = {
                ...state,
                language: action.language
            }
            return state;

        default:
            return state;
    }
}

export default localeReducer;