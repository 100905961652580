import { GET_DASHBOARD_DETAILS_REQUEST, GET_DASHBOARD_DETAILS_SUCCESS, GET_DASHBOARD_DETAILS_FAILURE } from "./ActionsTypes";
import { DashboardService } from "../../containers/Dashboard/Dashboard.service";
import { popToast } from "../../components/Toast/Toast";

export function getDashboardDetails(year) {
	return dispatch => {
		dispatch(request());
		DashboardService.getDashboardDetails(year).then(
			response => {
				if (response && response.message === "Success") {
					let finalResponse = {};
					if (response.data) {
						let demoArray = [];
						response.data.graphDetails.map(item => {
							let xValue = getItemMonth(item.xAxisVaue);
							let input = {
								Month: xValue,
								Sale: item.yAxisValue
							};
							demoArray.push(input);
						});
						response.data.graphDetails = demoArray;
						finalResponse = response;
					}
					dispatch(success(finalResponse));
				} else if (response && response.message === "Failure") {
					dispatch(failure(response));
					popToast.show("Get dashboard details Error : " + response.Message || "Something went wrong while loading dasboard details!");
				}
			},
			error => {
				if (error && error.status_message) {
					popToast.show(error.status_message);
				} else {
					popToast.show("Something went wrong while loading dashboard details!");
				}
				console.log("error dispatched for API error!", error);
				dispatch(failure(error));
			}
		);
	};

	function request() {
		return { type: GET_DASHBOARD_DETAILS_REQUEST };
	}
	function success(data) {
		return { type: GET_DASHBOARD_DETAILS_SUCCESS, data };
	}
	function failure(error) {
		return { type: GET_DASHBOARD_DETAILS_FAILURE, error };
	}
}
function getItemMonth(num) {
	switch (num) {
		case 1:
			return "Jan";
		case 2:
			return "Feb";
		case 3:
			return "Mar";
		case 4:
			return "Apr";
		case 5:
			return "May";
		case 6:
			return "Jun";
		case 7:
			return "Jul";
		case 8:
			return "Aug";
		case 9:
			return "Sep";
		case 10:
			return "Oct";
		case 11:
			return "Nov";
		case 12:
			return "Dec";
		default:
			return "January";
	}
}
