import React, { Component } from "react";
import { CircularProgress } from "@material-ui/core";
import { Pagination, Table, Icon, Tooltip, Popover } from "antd";
import { isArray, isEmpty, map, includes } from "lodash";

// Redux
import { connect } from "react-redux";
import { getAllFilterRules, deleteFilterRule } from '../../redux/actions/FilterRuleAction'
import { getAllLookUpProperty } from '../../redux/actions/LookupAction'
import { getAllArtists } from "../../redux/actions/CollectionAction";
import { getAllCountryListAction } from "../../redux/actions/CountriesTaxAction";
import ConfirmationMessageDialog from "../../components/ConfirmationMessageDialog/ConfirmationMessageDialog";
// Components
import Root from "../Root/Root";
import Topbar from "../../components/Topbar/Topbar";

// SCSS
import "./FilterRules.scss";
import loader from "sass-loader";
const productSize = [
    { name: "Small (< 18 in)", value: 0 },
    { name: "Medium (18 - 48 in)", value: 1 },
    { name: "Large (48 - 72 in)", value: 2 },
    { name: "Extra-Large (> 72 in)", value: 3 }
];

//Orientation
const orientation = [
    { name: "Portrait", value: 0 },
    { name: "Landscape", value: 1 },
    { name: "Square", value: 2 },
];

//Packaging
const packaging = [
    { name: "Canvas Role", value: 0 },
    { name: "Boxed", value: 1 },
    { name: "Crate", value: 2 },
];
//Panel
const panel = [
    { name: "Single Panel", value: 0 },
    { name: "Multi Panel", value: 1 },
];
//Framed
const framed = [
    { name: "Framed", value: 0 },
    { name: "Not Framed", value: 1 },
];
//Limited Edition
const limitedEdition = [
    { name: "Unique", value: 0 },
    { name: "Limited Edition", value: 1 },

];
class FilterRules extends Component {
    constructor(props) {
        super(props);
        this.state = {

            isLoader: false,
            isAddDialogOpen: false,
            isEditDialogOpen: false,
            isDeleteDialogOpen: false,
            selectedItem: {},
            searchValue: ''
        };
    }

    componentDidMount() {
        let body = {
            "PropertyNames": [
                "STYLE",
                "SUBJECT",
                "MATERIALS",
                "MEDIUM"
            ]
        }
        this.props.getAllLookUpProperty(body);
        this.props.getAllArtists();
        this.props.getAllCountryListAction();
        this.props.getAllFilterRules(this.state.searchValue);
    }

    onClickEdit(value, item, type) {
        this.props.history.push(`/filter/edit-filter/${item._id}`);
    }

    onNavigateNewFilter() {
        this.props.history.push(`/filter/new-filter`);
    }

    // ====================== DELETE PROPERTY ====================== //
    // Delete property
    onDeleteProperty() {
        this.props.deleteFilterRule(this.state.selectedItem._id)
        this.toggleDeleteDialog()
    }
    onClickDelete(value, item, type) {
        console.log("🚀 ~ file: FilterRules.js ~ line 45 ~ FilterRules ~ onClickDelete ~ item", item)
        this.setState({
            selectedItem: item,

        }, () => {
            this.toggleDeleteDialog()
        })

    }

    toggleDeleteDialog() {
        this.setState({
            isDeleteDialogOpen: !this.state.isDeleteDialogOpen
        })

    }

    // Close all dialogs when route changes
    componentWillUnmount() {

    }
    stringToArray = arr => {
        return isArray(arr) ? arr : [...[], arr];
    };
    bindSelectedButtons(filterObj) {
        const { priceMin, priceMax, productSize, materialsUsed,
            medium, orientation, packaging,
            style, subject, isSinglePanel, isFramed, isLimitedEdition, artistCountry, artistId, isAbstract } = filterObj;
        const { allPropertyList } = this.props.lookupReducer;
        const { artistsList } = this.props.collectionReducer;

        let btnLists = [];
        if (allPropertyList) {
            //Price Button
            if (priceMin !== null || priceMax !== null) {
                if (priceMin !== 0 || priceMax !== 0) {
                    var obj = {
                        text: [`USD ${priceMin} - ${priceMax}`],
                        id: 'price_key',
                        name: 'Price'
                    };
                    btnLists.push(obj);
                }
            }

            //Size filter
            if (productSize) {
                var sizeText = [];
                this.stringToArray(productSize).map((Number) => {
                    if (Number === 0) {
                        sizeText = [...sizeText, 'Small']
                    } else if (Number === 1) {
                        sizeText = [...sizeText, 'Medium']
                    } else if (Number === 2) {
                        sizeText = [...sizeText, 'Large']
                    } else if (Number === 3) {
                        sizeText = [...sizeText, 'Extra-Large']
                    }
                })
                if (sizeText && sizeText.length > 0) {
                    var obj = {
                        text: sizeText,
                        id: "size_key",
                        name: 'Size'
                    };
                    btnLists.push(obj);
                }
            }

            //Selected MaterialsUsed List For button
            if (materialsUsed) {
                var materialButtonText = [];
                this.stringToArray(materialsUsed).map((value) => {
                    allPropertyList && allPropertyList.materials && allPropertyList.materials.filter(item => item.slugId === value).map((data) => {
                        materialButtonText = [...materialButtonText, data.name]
                    })
                })
                if (materialButtonText && materialButtonText.length > 0) {
                    var obj = {
                        text: materialButtonText,
                        id: "material_key",
                        name: 'Materials'
                    };
                    btnLists.push(obj);
                }
            }
            //Selected Subject List For button
            if (subject) {
                var subjectButtonText = [];
                this.stringToArray(subject).map((value) => {
                    allPropertyList && allPropertyList.artworkSubject && allPropertyList.artworkSubject.filter(item => item.slugId === value).map((data) => {
                        subjectButtonText = [...subjectButtonText, data.name]
                    })
                })
                if (subjectButtonText && subjectButtonText.length > 0) {
                    var obj = {
                        text: subjectButtonText,
                        id: "subject_key",
                        name: 'Subject'

                    };
                    btnLists.push(obj);
                }
            }

            //Selected Style List For button
            if (style) {
                var styleButtonText = [];
                this.stringToArray(style).map((value) => {

                    allPropertyList && allPropertyList.artworkStyle && allPropertyList.artworkStyle.filter(item => item.slugId === value).map((data) => {
                        styleButtonText = [...styleButtonText, data.name]
                    })
                })
                if (styleButtonText && styleButtonText.length > 0) {
                    var obj = {
                        text: styleButtonText,
                        id: "style_key",
                        name: 'Style'

                    };
                    btnLists.push(obj);
                }
            }
            //Selected Medium List For button
            if (medium) {
                var mediumButtonText = [];
                this.stringToArray(medium).map((value) => {

                    allPropertyList && allPropertyList.artworkMedium && allPropertyList.artworkMedium.filter(item => item.slugId === value).map((data) => {
                        mediumButtonText = [...mediumButtonText, data.name]
                    })
                })
                if (mediumButtonText && mediumButtonText.length > 0) {
                    var obj = {
                        text: mediumButtonText,
                        id: "medium_key",
                        name: 'Medium'

                    };
                    btnLists.push(obj);
                }
            }
            //Selected Packaging List For button
            if (packaging) {
                var packagingButtonText = [];
                this.stringToArray(packaging).map((value) => {
                    if (value === 0) {
                        packagingButtonText = [...packagingButtonText, 'Canvas Role']
                    } else if (value === 1) {
                        packagingButtonText = [...packagingButtonText, 'Boxed']
                    } else if (value === 2) {
                        packagingButtonText = [...packagingButtonText, 'Crate']
                    }
                })
                if (packagingButtonText && packagingButtonText.length > 0) {
                    var obj = {
                        text: packagingButtonText,
                        id: "packaging_key",
                        name: 'Package'

                    };
                    btnLists.push(obj);
                }
            }

            // Orientation
            if (orientation) {
                var orientationText = [];
                this.stringToArray(orientation).map((Number) => {
                    if (Number === 0) {
                        orientationText = [...orientationText, 'Portrait']
                    } else if (Number === 1) {
                        orientationText = [...orientationText, 'Landscape']
                    } else if (Number === 3) {
                        orientationText = [...orientationText, 'Square']
                    }
                })
                if (orientationText && orientationText.length > 0) {
                    var obj = {
                        text: orientationText,
                        id: "orientation_key",
                        name: 'Orientation'

                    };
                    btnLists.push(obj);
                }

            }

            //Selected Panel List For button
            if (isSinglePanel !== null) {
                var obj = {
                    text: [`${isSinglePanel ? 'Single Panel' : 'Multi Panel'}`],
                    id: "panel_key",
                    name: 'Panel'

                };
                btnLists.push(obj);
            }

            //Selected Frame List For button
            if (isFramed !== null) {
                var obj = {
                    text: [`${isFramed ? 'Framed' : 'Not Framed'}`],
                    id: "frame_key",
                    name: 'Frame'
                };
                btnLists.push(obj);

            }
            //Selected Rarity
            if (isLimitedEdition !== null) {
                var obj = {
                    text: [`${isLimitedEdition ? 'Limited Edition' : 'Unique'}`],
                    id: "limited_key",
                    name: 'RARITY'
                };
                btnLists.push(obj);

            }
            //Selected isAbstract
            if (isAbstract !== null && isAbstract !== undefined) {
                var obj = {
                    text: [`${isAbstract ? 'Abstract' : 'Figurative'}`],
                    id: "abstract_key",
                    name: 'Abstract/Figurative'
                };
                btnLists.push(obj);

            }

            if (artistCountry !== "") {

                var obj = {
                    text: [`${artistCountry}`],
                    id: "artist_country_key",
                    name: 'Artist Country'

                };
                btnLists.push(obj);
            }

            // Artilst List
            if (artistId) {
                var artistText = [];
                this.stringToArray(artistId).map((value) => {
                    artistsList && artistsList.artistList && artistsList.artistList.filter(item => item.userId === value).map((data) => {
                        artistText = [...artistText, data.name]
                    })
                })
                if (artistText && artistText.length > 0) {
                    var obj = {
                        text: artistText,
                        id: "artist_key",
                        name: 'Artist'

                    };
                    btnLists.push(obj);
                }
            }


        }

        return btnLists;


    }


    renderSubList(data) {
        console.log("🚀 ~ file: FilterRules.js ~ line 348 ~ FilterRules ~ renderSubList ~ data", data)
        const content = record => (
            <div>

                <div className="action-span" onClick={this.onClickDelete.bind(this, true, record)}>
                    <img src={require("../../assets/images/common/delete_active.svg")} alt="edit" />
                    <p className="pop-item">Delete</p>
                </div>

                <div className="action-span" onClick={this.onClickEdit.bind(this, true, record)}>
                    <img src={require("../../assets/images/common/Edit_New.svg")} alt="edit" />
                    <p className="pop-item">Edit</p>
                </div>
            </div>
        );
        const columns = [
            {
                title: "Name Of Rule",
                className: "sorter-no-tooltip",
                dataIndex: "name",
                key: "name",
            },
            {
                title: "Applied Filter",
                className: "button-box-wrapper",
                dataIndex: "filterObject",
                key: "filterObject",
                render: (text, record) => {
                    if (record.filterObject) {
                        const btnList = this.bindSelectedButtons(record.filterObject)
                        return btnList && btnList.length > 0 && btnList.map((data, key) => {
                            return (
                                <div className="button-box">
                                    <p>{`${data.name}:${data.text.join('+')}`}</p>

                                </div>
                            )
                        })



                    }

                }



            },

            {
                title: "Action",
                key: "action",
                fixed: 'right',
                render: record =>
                    <Popover
                        placement="bottom"
                        content={content(record)}
                        overlayClassName="manage-artworks-action-popover"
                    // onVisibleChange={this.onVisibleChangeOfArtworkRecord.bind(this, record)}
                    >
                        <img className="action-image" src={require("../../assets/images/common/artworksActionIcon.svg")} alt="action" />
                    </Popover>
            }

        ]
        return <Table rowKey={record => record._id
        } columns={columns} dataSource={data} pagination={{ pageSize: 5 }} showSorterTooltip sticky />


    }
    onChangeSearch(search) {
        this.setState(
            {
                searchValue: search,
                pageNumValue: 1
            },
            () => {
                this.props.getAllFilterRules(this.state.searchValue);
            }
        );
    }
    render() {

        const { selectedTab, isLoader, isAddDialogOpen, selectedItem, isEditDialogOpen, isDeleteDialogOpen } = this.state;
        const { allFilterRules, isLoading } = this.props.filterRuleReducer;
        // MAIN PRODUCT ID : 5cb707fea6bf3f1e185b5820
        return (
            <Root>
                <Topbar title={"Filter Rule"} showBreadCrumbs showHead showSearch searchValue={this.state.searchValue} onChangeSearch={this.onChangeSearch.bind(this)} />

                <div className="filter-rule-wrapper">

                    <div className="sub-list">
                        {isLoading ? <div className="category-list-wrapper">
                            <CircularProgress className="circular-loader" size={20} />
								Fetching Filter ...
                            </div>

                            :
                            this.renderSubList(allFilterRules && allFilterRules)
                        }
                        {!isLoading &&
                            <div className="add-button-box add-btn-fixed">
                                <Tooltip placement="top" title={"Add New Filter"}>

                                    <span className="add-btn" onClick={this.onNavigateNewFilter.bind(this)}>
                                        <p>+</p> {`Add Filter`}
                                    </span>
                                </Tooltip>
                            </div>
                        }
                    </div>


                    {/* Delete FILETER */}
                    <ConfirmationMessageDialog
                        open={isDeleteDialogOpen}
                        handleClose={this.toggleDeleteDialog.bind(this)}
                        confirmMessage={"Are you sure you want to delete this item?"}
                        confirmButton={"Delete"}
                        loader={isLoading}
                        onConfirm={this.onDeleteProperty.bind(this)}
                    />

                </div>
            </Root>
        );
    }














}

const mapStateToProps = state => {
    return {
        filterRuleReducer: state.filterRuleReducer,
        lookupReducer: state.lookupReducer,
        collectionReducer: state.collectionReducer,
        countriesTaxReducer: state.countriesTaxReducer,
        filterRuleReducer: state.filterRuleReducer

    };
};

const mapDispatchToProps = dispatch => {
    return {
        getAllFilterRules: (searchValue) => {
            dispatch(getAllFilterRules(searchValue))
        },
        deleteFilterRule: (id) => {
            dispatch(deleteFilterRule(id))
        },

        getAllLookUpProperty: (body) => {
            dispatch(getAllLookUpProperty(body))
        },
        getAllArtists: () => {
            dispatch(getAllArtists());
        },
        getAllCountryListAction: () => {
            dispatch(getAllCountryListAction());
        },

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterRules);
