import styled, { css } from "styled-components";
import { Input, Typography } from "antd";

const InputWrapper = styled.div`
    position: relative;
    margin-top: 18px;
    width: 100%;
`;

const FloatingLabeledInputField = styled(Input)`
    position: relative;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #e5e5e5;
    opacity: 1;
    outline: none;
    border-radius: 0px;
    padding: 6px 10px 0px;
    font-size: 14px;
    color: #323643;
    &:focus,
    &:active {
        border-color: 0px solid rgb(0, 156, 38);
        -webkit-box-shadow: 0 0 0 2px rgba(255, 0, 0, 0);
        box-shadow: 0 0 0 2px rgba(255, 0, 0, 0);
        outline: 0;
    }
`;

const FloatingLabeledInputLabel = styled.span`
    position: absolute;
    top: -5.5px;
    left: 9px;
    height: 16px;
    font-size: 13px;
    text-align: left;
    padding: 0px 7px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    pointer-events: none;
    background-color: #fff;
    color: #878787;
    letter-spacing: 0.35px;
    line-height: 1;
    opacity: 1;
`;

const ErrorDiv = styled.div`
    position: absolute;
`;
const ErrorText = styled(Typography.Text)`
    &.ant-typography.ant-typography-danger {
        font-size: 12px;
        display: flex;
    }
`;
export { InputWrapper, FloatingLabeledInputField, FloatingLabeledInputLabel, ErrorDiv, ErrorText };
